import React, { FC, useContext, useEffect, useState } from "react";
import { CV } from "adapters";
import { WButton, WIcon, WIconTypes, WTypography } from "components";
import { LocaleContext } from "context/LocaleContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { CVUpload } from "./CVUpload";

const ALLOWED_FILE_FORMAT = ".doc, .docx, .pdf";

type DetailedCV = {
  alias: string;
  extension: string;
};

const defaultDetailedCV = {
  alias: "",
  extension: "",
};

interface JobApplicationCVAccordionProps {
  cvList: CV[];
  handleSelectedCV: (cv: string | File | undefined) => void;
  handleCreatedCV: (cv: File, alias: string) => void;
  preSelectedCV: CV | null | undefined;
}

export const JobApplicationCVAccordion: FC<JobApplicationCVAccordionProps> = ({
  cvList,
  handleSelectedCV,
  handleCreatedCV,
  preSelectedCV,
}) => {
  const { localize } = useContext(LocaleContext);

  const [accordionIsExpanded, setAccordionIsExpanded] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [selectedCV, setSelectedCV] = useState<DetailedCV | undefined>(defaultDetailedCV);

  useEffect(() => {
    if (preSelectedCV) {
      setSelectedCV({ alias: preSelectedCV.alias, extension: getCVExtension(preSelectedCV) });
    }
  }, [preSelectedCV]);

  const handleExpandAccordion = (state: boolean) => {
    setAccordionIsExpanded(state);
  };

  const handleSelectedItem = (item: CV, index: number) => {
    if (index === selectedItemIndex) {
      setSelectedItemIndex(-1);
      handleSelectedCV(undefined);
      setSelectedCV(defaultDetailedCV);
    } else {
      setSelectedItemIndex(index);
      handleSelectedCV(item.id);
      setSelectedCV({ alias: item.alias, extension: getCVExtension(item) });
    }
  };

  const handleRemoveSelectedCV = () => {
    handleSelectedCV(undefined);
    setSelectedItemIndex(-1);
    setSelectedCV(defaultDetailedCV);
  };

  const getCVExtension = (cv: CV) => {
    const extension = cv.user_cv.substring(cv.user_cv.lastIndexOf(".") + 1);
    if (extension.indexOf("docx") > -1) {
      return "docx";
    } else if (extension.indexOf("doc") > -1) {
      return "doc";
    } else {
      return "pdf";
    }
  };
  const getUploadedCVExtension = (cv: File) => {
    const extension = cv.name.substring(cv.name.lastIndexOf(".") + 1);
    if (extension.indexOf("docx") > -1) {
      return "docx";
    } else if (extension.indexOf("doc") > -1) {
      return "doc";
    } else {
      return "pdf";
    }
  };

  return (
    <>
      {cvList.length > 0 ? (
        <Grid container>
          <Accordion
            disableGutters
            expanded={accordionIsExpanded}
            sx={{ boxShadow: "none", minWidth: "100%" }}
          >
            <AccordionSummary
              onClick={() => handleExpandAccordion(!accordionIsExpanded)}
              expandIcon={<WIcon icon={WIconTypes.arrowDown} />}
            >
              <Grid container direction="column" wrap="nowrap">
                <WTypography lineClamps={1} fontWeight="bold" color="primary" maxWidth="100px">
                  {localize("form.cvField")}
                </WTypography>
                <Typography variant="body2" sx={{ opacity: "0.6" }}>
                  {localize("form.addExistingCV")}
                </Typography>
              </Grid>
            </AccordionSummary>
            {cvList.map((item, index) => {
              return (
                <AccordionDetails key={item.id}>
                  <WButton
                    onClick={() => handleSelectedItem(item, index)}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0",
                      width: "100%",
                    }}
                  >
                    <Grid container wrap="nowrap" gap="1rem" alignItems="center">
                      <img
                        alt="CV Icon"
                        style={{
                          objectFit: "contain",
                          maxHeight: "60px",
                          maxWidth: "60px",
                          minHeight: "60px",
                          minWidth: "60px",
                        }}
                        src={
                          getCVExtension(item) === "pdf"
                            ? `${process.env.PUBLIC_URL}/assets/images/pdfIcon.png`
                            : `${process.env.PUBLIC_URL}/assets/images/wordIcon.png`
                        }
                      />
                      <Grid
                        container
                        wrap="nowrap"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <WTypography textAlign="start" lineClamps={1}>
                          {item.alias}
                        </WTypography>
                        <Checkbox
                          edge="end"
                          checked={index === selectedItemIndex}
                          sx={{
                            color: "text.primary",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </WButton>
                </AccordionDetails>
              );
            })}
          </Accordion>
        </Grid>
      ) : (
        <Grid container>
          <Typography fontWeight="bold" color="primary">
            {localize("form.cvField")}
          </Typography>
        </Grid>
      )}
      <Grid container direction="column" wrap="nowrap" alignItems="center" margin="1rem 0">
        <FormHelperText error>
          {localize("form.acceptedFileTypes")}
          {ALLOWED_FILE_FORMAT}
        </FormHelperText>
        <CVUpload
          disableBackendRequest
          onCVSubmit={(cv: File, alias: string) => {
            handleCreatedCV(cv, alias);
            setSelectedCV({ alias: alias, extension: getUploadedCVExtension(cv) });
          }}
        />
      </Grid>
      {selectedCV && selectedCV.alias !== "" && (
        <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <Grid container wrap="nowrap" gap="1rem" alignItems="center">
            <img
              alt="CV Icon"
              style={{
                objectFit: "contain",
                maxHeight: "60px",
                maxWidth: "60px",
                minHeight: "60px",
                minWidth: "60px",
              }}
              src={
                selectedCV.extension === "pdf"
                  ? `${process.env.PUBLIC_URL}/assets/images/pdfIcon.png`
                  : `${process.env.PUBLIC_URL}/assets/images/wordIcon.png`
              }
            />
            <Typography>{selectedCV.alias}</Typography>
          </Grid>
          <WButton onClick={() => handleRemoveSelectedCV()}>
            <WIcon icon={WIconTypes.clear} />
          </WButton>
        </Grid>
      )}
    </>
  );
};
