import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Box, Divider, Drawer, Grid, Skeleton, Switch, Tooltip } from "@mui/material";
import { Group, GroupUser } from "adapters/GroupsAdapter";
import { AvatarItem } from "components/AvatarItem";
import { WTypography } from "components/WTypography";
import { LocaleContext } from "context/LocaleContext";
import { colors, defaultStyles } from "styles/colors";
import styled from "@emotion/styled";
import { WButton } from "components/styledComponents/WButton";
import { ModalContext } from "context/ModalContext";
import { WTextField } from "components/styledComponents/WTextField";
import { WIconButton } from "components/styledComponents/WIconButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { SearchDropdown } from "components/SearchDropdown";
import { usePagination } from "hooks/usePagination";
import { getUserList } from "adapters";
import { User } from "reducers/UserReducer";
import { DrawerSection } from "components/styledComponents/WDrawerSection";

export const GroupDrawerWidth = "min(500px, 30vw)";

interface Props {
  isOpen: boolean;
  group?: Group;
  onChangeUsers: (newUsers: GroupUser[]) => void;
  onRemoveGroup: (groupId: string) => void;
}
export const GroupDrawer: FC<Props> = ({ isOpen, group, onChangeUsers, onRemoveGroup }) => {
  const { localize, currentLanguage } = useContext(LocaleContext);
  const { showModal } = useContext(ModalContext);
  const [searchText, setSearchText] = useState("");
  const [sortByRating, setSortByRating] = useState(false);
  const [isAddingCandidate, setIsAddingCandidate] = useState(false);
  const { showDialog, hideDialog } = useContext(ModalContext);

  const allUsers = (group?.users || []) as GroupUser[];

  const {
    results: searchedUsers,
    handleSearch: handleUserSearch,
    setLastElementRef,
  } = usePagination<User>(
    getUserList,
    "error.fetchUsers",
    "GET_USER_LIST",
    {
      page_size: 7,
      ordering: "email",
    },
    true
  );

  useEffect(() => {
    setSearchText("");
    setIsAddingCandidate(false);
  }, [group]);

  const { users: shownUsers, name } = useMemo(() => {
    if (!group) {
      return { users: [], name: "" };
    }

    const filteredUsers = searchText
      ? allUsers.filter(
          (u) =>
            u.email.includes(searchText) ||
            u.first_name.includes(searchText) ||
            u.last_name.includes(searchText)
        )
      : allUsers;

    const sortedUsers = sortByRating
      ? filteredUsers.sort((a, b) => b.rating - a.rating)
      : filteredUsers.sort((a, b) => a.email.localeCompare(b.email));

    return {
      users: sortedUsers,
      name: currentLanguage === "en" ? group.name_en : group.name_sv,
    };
  }, [group, currentLanguage, searchText, sortByRating]);

  const totalCount = group?.users.length ?? 0;

  const handleRemoveGroup = (groupId: string) => {
    onRemoveGroup(groupId);
    hideDialog();
  };

  const showWarningDialog = (groupId: string) => {
    showDialog("CONFIRM_DIALOG", {
      cancelAction: () => hideDialog(),
      confirmAction: () => handleRemoveGroup(groupId),
    });
  };

  let content: ReactNode = null;
  if (!group) {
    content = (
      <Box padding="20px">
        <Skeleton
          variant="rectangular"
          width="100%"
          height="400px"
          sx={{ borderRadius: defaultStyles.borderRadius }}
        />
      </Box>
    );
  } else {
    content = (
      <>
        <DrawerSection>
          <WTypography variant="h6" fontWeight="bold">
            {name}
          </WTypography>
          <Tooltip
            title={localize("groups.removeGroup")}
            placement="top-end"
            arrow
            disableInteractive
          >
            <WIconButton
              size="small"
              sx={{ minWidth: "35px", position: "absolute", right: "1px" }}
              onClick={(event) => {
                event.stopPropagation();
                showWarningDialog(group.id);
              }}
            >
              <WIcon icon={WIconTypes.trash} size="small" />
            </WIconButton>
          </Tooltip>
        </DrawerSection>

        <Divider />
        <Grid
          container
          direction="column"
          wrap="nowrap"
          width="100%"
          overflow="auto"
          flex="100px 1 0"
          gap="5px"
          padding="20px"
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="space-between"
            marginBottom="10px"
            gap="5px"
          >
            {isAddingCandidate ? (
              <>
                <SearchDropdown
                  label={localize("groups.addCandidate")}
                  items={searchedUsers.map((u) => ({
                    value: u.id,
                    text: `${u.first_name} ${u.last_name} (${u.email})`,
                    disabled: !!allUsers.find(({ id }) => id === u.id),
                  }))}
                  onSearch={(text) => handleUserSearch({ search: text })}
                  onSelect={async (value) => {
                    const user = searchedUsers.find((u) => u.id === value);
                    if (!user) {
                      return;
                    }
                    const { id, first_name, last_name, email, profile_picture, rating } = user;
                    onChangeUsers([
                      ...allUsers,
                      { id, email, first_name, last_name, profile_picture, rating: rating ?? 0 },
                    ]);
                    setIsAddingCandidate(false);
                  }}
                  setLastElementRef={setLastElementRef}
                  loaderAction="GET_USER_LIST"
                  placement="bottom"
                  dropdownWidth={`calc(${GroupDrawerWidth} - 40px)`}
                />
                <WButton
                  sx={{ width: "fit-content" }}
                  variant="text"
                  onClick={() => setIsAddingCandidate(false)}
                >
                  {localize("common.cancel")}
                </WButton>
              </>
            ) : (
              <>
                <Grid item>
                  <WTextField
                    label={localize("groups.searchCandidates")}
                    size="small"
                    fullWidth
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <WButton variant="outlined" onClick={() => setIsAddingCandidate(true)}>
                      <WIcon size="small" icon={WIconTypes.addUser} />
                    </WButton>
                    <WButton
                      variant="outlined"
                      onClick={() => {
                        showModal("SEND_EMAIL_MODAL", {
                          users: shownUsers,
                        });
                      }}
                    >
                      <WIcon size="small" icon={WIconTypes.mail} />
                    </WButton>
                  </Grid>
                  <Grid item>
                    <WTypography variant="body2">
                      {localize("common.candidates")}:{" "}
                      {totalCount !== shownUsers.length
                        ? `${shownUsers.length} / ${totalCount}`
                        : totalCount}
                    </WTypography>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center">
                  <WTypography variant="body2" marginRight="30px">
                    {localize("groups.sorting")}:{" "}
                  </WTypography>
                  <Grid item>
                    <WTypography variant="body2">{localize("groups.sortByEmail")}</WTypography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={sortByRating}
                      onChange={() => setSortByRating(!sortByRating)}
                    />
                  </Grid>
                  <Grid item>
                    <WTypography variant="body2">{localize("groups.sortByRating")}</WTypography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item container direction="column">
            {shownUsers.length === 0 ? (
              <WTypography variant="body1" fontStyle="italic">
                {localize("groups.noCandidates")}
              </WTypography>
            ) : (
              shownUsers.map(({ id, first_name, last_name, email, profile_picture, rating }) => (
                <UserRow item key={id}>
                  <UserButton
                    variant="text"
                    onClick={() => showModal("CANDIDATE_MODAL", { candidateId: id })}
                  >
                    <AvatarItem
                      imageSrc={profile_picture}
                      firstName={first_name}
                      lastName={last_name}
                      size="xtiny"
                    />
                    <WTypography
                      variant="body2"
                      fontSize="0.9rem"
                      lineClamps={1}
                      sx={{ wordBreak: "break-all", width: "100%", textAlign: "left" }}
                    >
                      {first_name} {last_name} ({email})
                    </WTypography>
                    <span>{rating || "-"}/5</span>
                    <WIcon icon={WIconTypes.star} fill customColor={colors.main} size="small" />
                  </UserButton>
                  <Tooltip
                    title={localize("groups.removeCandidate")}
                    placement="top-end"
                    arrow
                    disableInteractive
                  >
                    <WIconButton
                      size="small"
                      sx={{ minWidth: "35px" }}
                      onClick={() => {
                        const index = allUsers.findIndex((u) => u.id === id);
                        onChangeUsers([...allUsers.slice(0, index), ...allUsers.slice(index + 1)]);
                      }}
                    >
                      <WIcon icon={WIconTypes.trash} size="small" />
                    </WIconButton>
                  </Tooltip>
                </UserRow>
              ))
            )}
          </Grid>
        </Grid>
        <Divider />
        <DrawerSection></DrawerSection>
      </>
    );
  }

  return (
    <Drawer
      sx={{
        width: GroupDrawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: GroupDrawerWidth,
          boxSizing: "border-box",
          transition: "transform 0.2s !important",
          height: "100vh",
          backgroundColor: colors.white,
        },
      }}
      variant="persistent"
      anchor="right"
      open={isOpen}
    >
      {content}
    </Drawer>
  );
};

const UserRow = styled(Grid)`
  display: flex;

  .MuiIconButton-root {
    width: 0;
    padding: 0;
    min-width: 0;
  }

  :hover .MuiIconButton-root {
    width: 35px;
    padding: 5px;
    min-width: 35px;

    svg {
      stroke: ${colors.error};
    }
  }
`;

const UserButton = styled(WButton)`
  display: flex;
  width: 100%;
  gap: 5px;
`;
