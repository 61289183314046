import { API } from "./api";
import { AxiosResponse } from "axios";
import { User } from "reducers/UserReducer";

export type AuthActions = 
  | "GET_CLIENT_CONFIG"
  | "VERIFY_MAIL_INV"
  | "UNSUBSCRIBE_JOBS"
  | "RESET_PASSWORD";

export type VerifyMailBody = {
  password: string;
  re_password: string;
  language: string;
};

export const postLogin = async (data: Record<string, unknown>): Promise<AxiosResponse<User>> => {
  return API.post("/auth/login/", data);
};

export const postSocialLogin = async (path?: string): Promise<Response> => {
  return fetch(`${process.env.PUBLIC_URL}/${path}/`, {
    redirect: "manual",
  });
};

export const getSocialLoginOptions = async (): Promise<AxiosResponse<AuthMethods[]>> => {
  return API.get("/auth/auth-methods/");
};

export const logout = async (): Promise<void> => {
  await API.delete("/auth/logout/");
};

export type ClientConfig = {
  is_authorized: boolean;
  is_agent: boolean;
};

export const getClientConfig = async (): Promise<AxiosResponse<ClientConfig>> => {
  return API.get("/auth/client-configuration/");
};

type verificationTypes =
  | "create-user"
  | "set-password"
  | "validate-job-application"
  | "reset-password"
  | "check-user-information";

export type VerifyMailResponse = {
  email: string;
  user: string;
  object_id: string;
  has_password: boolean;
  new_ver_type: verificationTypes;
  org_ver_type: verificationTypes;
};

export type AuthMethods = {
  id: number;
  name: string;
  url: string;
};

export const verifyMail = async (
  mailInvUuid: string,
  body?: Partial<VerifyMailBody>
): Promise<AxiosResponse<VerifyMailResponse>> => {
  return API.post(`/auth/${mailInvUuid}/mail-verify/`, body);
};

export const resetPassword = async (email: string): Promise<void> => {
  return API.post("/auth/reset-password/", { email: email });
};


export const unsubscribeJobs = async (
  mailInvUuid: string
): Promise<AxiosResponse<VerifyMailResponse>> => {
  return API.post(`/auth/${mailInvUuid}/mail-verify/`, {});
};