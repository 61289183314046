import { Dialog, DialogContent, Grid } from "@mui/material";
import { DialogHeader } from "components";
import { LoginForm } from "components/forms/LoginForm";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { User } from "reducers/UserReducer";

interface LoginDialogProps {
  handleClose: () => void;
  onLoginSubmit: (userData: User) => void;
}

export const LoginDialog: FC<LoginDialogProps> = ({ handleClose, onLoginSubmit }) => {
  const { localize } = useContext(LocaleContext);

  const submitAndClose = (userData: User) => {
    onLoginSubmit(userData);
    handleClose();
  };
  return (
    <Dialog open onClose={handleClose}>
      <DialogHeader handleClose={handleClose} title={localize("navbar.loginText")} />
      <DialogContent sx={{ padding: "2rem 0", minHeight: "20rem" }}>
        <Grid container justifyContent="center">
          <LoginForm onSubmit={submitAndClose} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
