import React, { FC } from "react";
import { Grid, useTheme } from "@mui/material";

interface FloatingIndicatorWrapperProps {
  condition: boolean;
  size?: "small" | "medium";
}
export const NotificationIndicator: FC<FloatingIndicatorWrapperProps> = ({
  condition,
  size = "medium",
}) => {
  const theme = useTheme();
  const circleSize = size === "medium" ? 7 : 6;

  if (!condition) {
    return null;
  }
  return (
    <Grid
      container
      position="relative"
      alignItems="center"
      marginRight=".3rem"
      maxWidth="max-content"
    >
      <svg width={circleSize * 2} height={circleSize * 2}>
        <circle
          r={circleSize}
          cx={circleSize}
          cy={circleSize}
          strokeWidth="0"
          fill={theme.palette.error.main}
        />
      </svg>
    </Grid>
  );
};
