import React, { FC, useContext } from "react";
import { Grid, Tooltip } from "@mui/material";
import { WTypography } from "components";
import { LocaleContext } from "context/LocaleContext";
import { colors } from "styles/colors";

interface AvailabilityTagProps {
  date?: string;
  placement?: "top" | "bottom";
  type?: "small" | "normal";
}

export const AvailabilityTag: FC<AvailabilityTagProps> = ({
  date,
  placement = "top",
  type = "normal",
}) => {
  const { localize } = useContext(LocaleContext);

  const chipColors = {
    available: {
      bg: "#B7FFAB",
      textColor: " #00CC52",
      text: date,
    },
    unAvailable: {
      bg: "#FFABAB",
      textColor: " #CC0000",
      text: date,
    },
    notSet: {
      bg: "#FFE7AB",
      textColor: " #D29700",
      text: localize("candidateModal.dateNotSet"),
    },
  };

  const getColor = (date: string | undefined) => {
    if (!date) return chipColors["notSet"];

    const dateOneMonthAhead = new Date();

    dateOneMonthAhead.setMonth(dateOneMonthAhead.getMonth() + 1);

    if (Date.parse(dateOneMonthAhead.toString()) >= Date.parse(date)) {
      return chipColors["available"];
    }
    return chipColors["unAvailable"];
  };

  const color = getColor(date);

  return (
    <Tooltip
      title={type === "small" && date ? date : localize("candidateModal.availableFrom")}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: color.textColor,
            color: colors.siteBackground,
            fontWeight: "bold",
            "& .MuiTooltip-arrow": {
              color: color.textColor,
            },
          },
        },
      }}
      arrow
    >
      <Grid
        container
        sx={{
          visibility: !date ? "hidden" : "visible",
          cursor: "default",
          minHeight: "14px",
          minWidth: "max-content",
        }}
        padding="3px 6px"
        marginLeft="auto"
        width="max-content"
        alignItems="center"
        borderRadius="2rem"
        bgcolor={type === "normal" ? color.bg : color.textColor}
      >
        {type === "normal" && (
          <WTypography
            textAlign="center"
            lineHeight="14px"
            variant="body2"
            fontWeight="bold"
            color={color.textColor}
          >
            {color.text}
          </WTypography>
        )}
      </Grid>
    </Tooltip>
  );
};
