import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const SearchManyAssignmentsSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 256 260"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="255" height="260" transform="translate(0.5)" fill="white" />
        <mask
          id="mask0_6_937"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="256"
          height="260"
        >
          <rect x="0.5" width="255" height="260" fill="white" />
        </mask>
        <g mask="url(#mask0_6_937)">
          <rect opacity="0.02" x="0.5" width="255" height="260" fill="white" />
          <g opacity="0.06">
            <path d="M0.5 0L255.5 260" stroke="white" />
            <path d="M10.4609 -10.1562L265.461 249.844" stroke="white" />
            <path d="M20.4219 -20.3125L275.422 239.688" stroke="white" />
            <path d="M30.3828 -30.4688L285.383 229.531" stroke="white" />
            <path d="M40.3438 -40.625L295.344 219.375" stroke="white" />
            <path d="M50.3047 -50.7812L305.305 209.219" stroke="white" />
            <path d="M60.2656 -60.9375L315.266 199.062" stroke="white" />
            <path d="M70.2266 -71.0938L325.227 188.906" stroke="white" />
            <path d="M80.1875 -81.25L335.188 178.75" stroke="white" />
            <path d="M90.1484 -91.4062L345.148 168.594" stroke="white" />
            <path d="M100.109 -101.562L355.109 158.438" stroke="white" />
            <path d="M110.07 -111.719L365.07 148.281" stroke="white" />
            <path d="M120.031 -121.875L375.031 138.125" stroke="white" />
            <path d="M-9.46094 10.1562L245.539 270.156" stroke="white" />
            <path d="M-19.4219 20.3125L235.578 280.312" stroke="white" />
            <path d="M-29.3828 30.4688L225.617 290.469" stroke="white" />
            <path d="M-39.3438 40.625L215.656 300.625" stroke="white" />
            <path d="M-49.3047 50.7812L205.695 310.781" stroke="white" />
            <path d="M-59.2656 60.9375L195.734 320.938" stroke="white" />
            <path d="M-69.2266 71.0938L185.773 331.094" stroke="white" />
            <path d="M-79.1875 81.25L175.812 341.25" stroke="white" />
            <path d="M-89.1484 91.4062L165.852 351.406" stroke="white" />
            <path d="M-99.1094 101.562L155.891 361.562" stroke="white" />
            <path d="M-109.07 111.719L145.93 371.719" stroke="white" />
            <path d="M-119.031 121.875L135.969 381.875" stroke="white" />
            <path d="M-128.992 132.031L126.008 392.031" stroke="white" />
          </g>
          <g opacity="0.06">
            <path d="M5.48047 -5.07812L260.48 254.922" stroke="white" />
            <path d="M15.4414 -15.2344L270.441 244.766" stroke="white" />
            <path d="M25.4023 -25.3906L280.402 234.609" stroke="white" />
            <path d="M35.3633 -35.5469L290.363 224.453" stroke="white" />
            <path d="M45.3242 -45.7031L300.324 214.297" stroke="white" />
            <path d="M55.2852 -55.8594L310.285 204.141" stroke="white" />
            <path d="M65.2461 -66.0156L320.246 193.984" stroke="white" />
            <path d="M75.207 -76.1719L330.207 183.828" stroke="white" />
            <path d="M85.168 -86.3281L340.168 173.672" stroke="white" />
            <path d="M95.1289 -96.4844L350.129 163.516" stroke="white" />
            <path d="M105.09 -106.641L360.09 153.359" stroke="white" />
            <path d="M115.051 -116.797L370.051 143.203" stroke="white" />
            <path d="M125.012 -126.953L380.012 133.047" stroke="white" />
            <path d="M-4.48047 5.07812L250.52 265.078" stroke="white" />
            <path d="M-14.4414 15.2344L240.559 275.234" stroke="white" />
            <path d="M-24.4023 25.3906L230.598 285.391" stroke="white" />
            <path d="M-34.3633 35.5469L220.637 295.547" stroke="white" />
            <path d="M-44.3242 45.7031L210.676 305.703" stroke="white" />
            <path d="M-54.2852 55.8594L200.715 315.859" stroke="white" />
            <path d="M-64.2461 66.0156L190.754 326.016" stroke="white" />
            <path d="M-74.207 76.1719L180.793 336.172" stroke="white" />
            <path d="M-84.168 86.3281L170.832 346.328" stroke="white" />
            <path d="M-94.1289 96.4844L160.871 356.484" stroke="white" />
            <path d="M-104.09 106.641L150.91 366.641" stroke="white" />
            <path d="M-114.051 116.797L140.949 376.797" stroke="white" />
            <path d="M-124.012 126.953L130.988 386.953" stroke="white" />
          </g>
        </g>
        <g opacity="0.5">
          <path
            d="M140.167 115H116.833C114.992 115 113.5 116.492 113.5 118.333V141.667C113.5 143.508 114.992 145 116.833 145H140.167C142.008 145 143.5 143.508 143.5 141.667V118.333C143.5 116.492 142.008 115 140.167 115Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M122.667 126.667C124.047 126.667 125.167 125.547 125.167 124.167C125.167 122.786 124.047 121.667 122.667 121.667C121.286 121.667 120.167 122.786 120.167 124.167C120.167 125.547 121.286 126.667 122.667 126.667Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M143.5 135L135.167 126.667L116.833 145"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g clipPath="url(#clip0_6_937)">
          <path
            d="M231.265 46.2087H233.51C235.229 33.0313 222 23.0246 206.826 21.803C212.785 23.1678 218.239 26.1812 222.561 30.4978C226.883 34.8143 229.9 40.2593 231.265 46.2087Z"
            fill="#F2F2F2"
          />
          <path
            d="M196.341 85.5246C195.071 85.5091 193.854 85.4664 192.69 85.3963C193.898 85.5821 195.123 85.6252 196.341 85.5246Z"
            fill="#F2F2F2"
          />
          <path
            d="M168.1 53.3739C168.393 36.0165 182.248 21.8908 199.638 21.8908C202.026 21.6377 204.433 21.6082 206.826 21.803C202.078 20.7272 197.148 20.7324 192.402 21.8181C187.656 22.9038 183.215 25.0422 179.41 28.0746C175.604 31.1071 172.532 34.9558 170.419 39.3354C168.307 43.715 167.21 48.5131 167.208 53.3739C167.208 69.8324 167.798 83.8741 192.69 85.3963C180.079 83.4841 167.871 67.0033 168.1 53.3739Z"
            fill="#F2F2F2"
          />
          <path
            d="M230.893 53.881C225.193 63.6163 202.78 70.756 199.638 84.8574C198.569 85.2078 197.462 85.4317 196.341 85.5246C197.374 85.5372 198.439 85.5331 199.546 85.5077C217.258 85.5077 231.786 71.4947 232.061 53.881L230.893 53.881Z"
            fill="#F2F2F2"
          />
          <path
            d="M202.183 77.0318C198.038 68.0551 196.223 58.3806 197.494 47.7817C197.297 46.5487 197.986 45.3131 199.033 45.0197L217.799 39.7857C218.846 39.495 219.855 40.2573 220.054 41.4896L224.743 70.7397C224.939 71.9727 224.251 73.2083 223.204 73.5018L204.438 78.7357C203.39 79.0264 202.382 78.2642 202.183 77.0318Z"
            fill="#F2F2F2"
          />
          <path
            d="M211.071 43.0215C211.341 40.9317 210.144 39.0548 208.397 38.8294C206.649 38.604 205.014 40.1155 204.743 42.2053C204.473 44.2951 205.67 46.172 207.417 46.3974C209.165 46.6228 210.8 45.1113 211.071 43.0215Z"
            fill="#F2F2F2"
          />
          <path
            d="M176.753 62.7277C172.608 53.751 170.793 44.0765 172.064 33.4776C171.868 32.2446 172.556 31.009 173.603 30.7156L192.37 25.4816C193.417 25.1909 194.426 25.9532 194.624 27.1855L199.313 56.4356C199.51 57.6686 198.821 58.9043 197.774 59.1977L179.008 64.4317C177.961 64.7223 176.952 63.9601 176.753 62.7277Z"
            fill="#F2F2F2"
          />
          <path
            d="M185.641 28.7174C185.912 26.6276 184.715 24.7507 182.967 24.5253C181.22 24.2999 179.584 25.8114 179.314 27.9012C179.043 29.9911 180.24 31.8679 181.988 32.0933C183.735 32.3187 185.371 30.8073 185.641 28.7174Z"
            fill="#F2F2F2"
          />
          <path d="M202.439 159.91H13.5V160.523H202.439V159.91Z" fill="#3F3D56" />
          <path
            d="M90.3535 87.4837C90.1306 86.705 93.2467 93.8425 92.5054 93.9284C89.1292 94.3151 81.648 96.0582 79.2965 96.9973C79.1905 96.985 79.0847 96.9728 78.9819 96.9574C78.7296 96.9298 78.4835 96.8899 78.2406 96.8439C72.7651 95.8096 68.6227 91.3199 68.5106 85.9002C68.5044 85.8235 68.5044 85.7437 68.5044 85.667C68.5036 85.2928 68.5234 84.9188 68.5635 84.5468C68.6151 84.0388 68.7036 83.5352 68.8283 83.04L68.8158 83.0369C66.6356 80.993 67.5544 77.203 70.3326 75.6041C71.5398 74.9111 72.8786 74.4774 74.2633 74.3305C74.6387 74.2898 75.0162 74.2693 75.3939 74.2691H78.9414C79.6755 74.1112 80.4195 74.0035 81.1683 73.9469H81.1746C88.3163 73.4037 93.9475 77.7492 93.4212 83.0369C93.418 83.0431 93.4118 83.0492 93.4087 83.0553C93.2561 83.2824 93.1221 83.4973 93.0038 83.7029C92.8885 83.8962 92.792 84.0804 92.7079 84.2553C92.057 85.6056 93.7302 87.3047 92.6207 87.6219C91.145 88.0437 96.5018 101.908 90.6609 101.908C93.502 102.714 90.9683 89.632 90.3535 87.4837Z"
            fill="#2F2E41"
          />
          <path
            d="M122.942 221.49L127.548 221.451L129.586 203.302L122.789 203.36L122.942 221.49Z"
            fill="#FFB6B6"
          />
          <path
            d="M137.695 228.802C137.699 229.347 137.487 229.872 137.104 230.26C136.721 230.649 136.2 230.87 135.654 230.876L126.496 230.952L124.893 227.708L124.304 230.969L120.849 231L121.721 219.37L122.923 219.291L127.827 218.953L129.409 218.847L129.436 222.138L136.793 227.119C137.068 227.306 137.295 227.556 137.452 227.85C137.609 228.143 137.692 228.47 137.695 228.802Z"
            fill="#2F2E41"
          />
          <path
            d="M128.78 212.388C126.948 212.167 122.325 211.774 122.325 211.774C122.325 211.774 120.142 207.226 120.496 205.811C120.843 204.39 121.71 204.102 120.904 203.359C118.943 196.123 119.257 184.369 119.257 184.369C119.257 184.369 108.7 179.265 98.0113 173.358C87.5623 167.579 76.9872 161.033 76.0312 157.734C75.4002 155.374 74.9764 152.964 74.7646 150.531C74.6048 148.935 74.5064 147.37 74.4449 145.968C74.3004 142.758 74.3588 140.385 74.3588 140.385L87.6791 140.164L96.5019 139.962H97.4241V141.496L99.742 145.431C99.742 145.431 103.748 150.08 103.277 150.513C102.709 151.037 102.881 151.504 104.9 151.885C105.475 151.985 106.06 152.009 106.64 151.955C109.902 151.707 111.267 153.569 111.347 153.977C111.894 156.859 117.381 163.12 122.521 168.463H122.524C127.268 173.397 131.713 177.547 131.713 177.547L131.965 182.84L133.149 207.591C133.149 207.591 130.929 206.351 132.912 209.024C134.895 211.7 130.613 212.606 128.78 212.388Z"
            fill="#2F2E41"
          />
          <path
            d="M178.754 154.837L180.634 150.638L164.949 141.239L162.174 147.434L178.754 154.837Z"
            fill="#FFB6B6"
          />
          <path
            d="M191.551 144.484C192.049 144.707 192.438 145.118 192.633 145.627C192.828 146.136 192.813 146.702 192.591 147.2L188.851 155.545L185.229 155.651L187.955 157.542L186.546 160.692L176.315 155.063L176.742 153.938L178.474 149.346L179.036 147.866L182.045 149.21L189.642 144.603C189.927 144.43 190.249 144.329 190.582 144.309C190.914 144.288 191.247 144.348 191.551 144.484Z"
            fill="#2F2E41"
          />
          <path
            d="M170.686 144.676C170.675 144.7 170.662 144.722 170.651 144.741C169.794 146.311 168.388 149.945 168.3 150.169C168.298 150.173 168.296 150.177 168.296 150.182C168.296 150.182 163.655 149.943 162.469 149.094C161.281 148.238 163.079 149.591 160.339 147.801C158.922 146.871 154.07 145.418 149.723 144.232C145.679 143.128 142.076 142.259 142.076 142.259C142.076 142.259 138.89 145.153 134.276 149.153L134.274 149.155C129.987 152.861 124.464 157.522 119.111 161.695C107.651 165.06 95.5561 167.976 92.5324 166.338C90.4069 165.129 88.391 163.737 86.5076 162.178L89.6212 154.031L97.8554 145.422L106.523 141.906L109.914 140.53L114.662 138.607L140.368 128.18L168.835 138.024C168.835 138.024 166.853 139.623 170.079 138.779C173.263 137.949 171.581 142.992 170.686 144.676Z"
            fill="#2F2E41"
          />
          <path
            d="M80.8237 97.3042H90.0462L91.4982 100.504L97.6745 103.251C98.4777 103.609 99.1854 104.15 99.7406 104.831C100.296 105.512 100.683 106.314 100.871 107.172C103.095 117.321 100.281 119.43 100.191 122.776C99.8834 134.131 98.2994 136.39 98.4766 139.193C98.5296 140.032 99.0426 143.349 100.693 143.036C102.343 142.724 101.453 144.035 101.844 144.545C102.181 144.985 103.956 144.401 102.842 145.864C101.728 147.327 106.49 151.317 103.572 151.317C98.0389 151.317 86.972 154.079 82.6682 154.079C78.3644 154.079 75.0386 155.353 73.1384 155.306C71.9803 155.278 72.3424 149.887 72.8053 145.218C72.9101 144.161 74.9435 144.189 73.1187 142.229C71.2939 140.269 73.442 139.25 73.4458 138.734C73.4499 138.186 74.4803 136.806 73.1945 134.548C71.9087 132.29 73.094 133.224 73.0382 132.515C72.6078 127.051 71.991 120.084 71.991 120.084L69.2724 112.445C68.5775 110.493 68.6694 108.347 69.5287 106.461C70.3879 104.575 71.9474 103.096 73.8782 102.336L77.0672 101.08L80.8237 97.3042Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M145.594 94.4868C145.389 94.8685 145.104 95.2012 144.758 95.4619C144.411 95.7226 144.012 95.905 143.588 95.9966C143.164 96.0882 142.725 96.0868 142.302 95.9924C141.878 95.898 141.481 95.713 141.136 95.45L128.144 102.793L125.226 99.7046L140.721 91.0963C141.207 90.55 141.878 90.2009 142.605 90.115C143.332 90.0292 144.065 90.2126 144.666 90.6305C145.267 91.0484 145.693 91.6718 145.864 92.3825C146.035 93.0933 145.939 93.842 145.594 94.4868Z"
            fill="#FFB6B6"
          />
          <path
            d="M87.0992 111.819L86.9014 114.183L112.765 113.851L138.51 99.4027L134.746 93.6196L112.118 103.117L96.0356 103.443C93.7722 103.489 91.6062 104.37 89.9558 105.917C88.3053 107.464 87.2876 109.567 87.0992 111.819Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M83.9782 95.6185C88.617 95.6185 92.3774 91.8645 92.3774 87.2336C92.3774 82.6028 88.617 78.8488 83.9782 78.8488C79.3394 78.8488 75.579 82.6028 75.579 87.2336C75.579 91.8645 79.3394 95.6185 83.9782 95.6185Z"
            fill="#FFB6B6"
          />
          <path
            d="M93.4212 83.4942C93.281 83.5678 93.144 83.6354 93.0038 83.7029C92.8575 83.7735 92.7111 83.844 92.5647 83.9085C89.7522 85.1821 87.463 85.3232 86.0738 83.4942C86.0366 82.5318 85.8201 81.5847 85.4353 80.7015C85.5033 81.6885 85.2427 82.6703 84.6941 83.4942H81.8972C79.0162 85.403 77.5367 85.6025 79.5363 91.8692C80.0938 93.6154 82.3106 103.776 81.4385 105.283C81.1862 105.256 78.4836 96.8899 78.2406 96.8439C72.7651 95.8096 68.6227 91.3199 68.5106 85.9002C68.5168 85.624 68.5355 85.3478 68.5636 85.0777C68.9243 84.5364 69.3207 84.0197 69.7502 83.531C69.748 82.0823 70.0817 80.6528 70.7251 79.3542C71.539 77.7091 72.8079 76.3311 74.3816 75.3831C75.3197 74.929 76.2976 74.5623 77.3031 74.2875C77.328 74.2814 77.3499 74.2752 77.3748 74.2691C77.6831 74.1862 77.9977 74.1095 78.3123 74.0451C78.9621 73.9081 79.6193 73.8087 80.2807 73.7474C80.3274 73.7443 80.3741 73.7412 80.4177 73.7412C80.6818 73.7418 80.9409 73.8128 81.1683 73.9469C81.1715 73.9469 81.1714 73.9499 81.1746 73.9499C81.3371 74.0447 81.479 74.171 81.5919 74.3213C81.7909 74.5741 81.8984 74.8866 81.8972 75.2082H85.0118C85.127 75.2082 85.2422 75.2112 85.3575 75.2174C87.4362 75.2883 89.4136 76.131 90.9027 77.5807C92.3918 79.0304 93.2855 80.9826 93.4087 83.0553C93.4181 83.2026 93.4212 83.3469 93.4212 83.4942Z"
            fill="#2F2E41"
          />
          <path
            d="M72.5236 85.0286C75.5796 85.0286 78.057 82.5554 78.057 79.5046C78.057 76.4538 75.5796 73.9806 72.5236 73.9806C69.4675 73.9806 66.9901 76.4538 66.9901 79.5046C66.9901 82.5554 69.4675 85.0286 72.5236 85.0286Z"
            fill="#2F2E41"
          />
          <path
            d="M79.2866 75.2082C79.3923 78.2572 76.8092 80.7322 73.7532 80.7322C72.2856 80.7322 70.8782 80.1502 69.8404 79.1142C68.8027 78.0783 68.2197 76.6732 68.2197 75.2082C68.2197 72.1573 71.9208 72.1258 73.7532 69.6842C76.3453 66.2302 79.1441 71.0963 79.2866 75.2082Z"
            fill="#2F2E41"
          />
          <path
            d="M86.5215 161.622C85.6623 148.895 88.1776 136.922 95.1632 125.951C95.339 125.229 95.7943 124.606 96.4294 124.219C97.0645 123.832 97.8274 123.713 98.5508 123.886L125.537 130.402C126.26 130.577 126.883 131.032 127.271 131.666C127.659 132.3 127.779 133.061 127.605 133.783L118.963 169.455C118.787 170.177 118.332 170.799 117.697 171.186C117.062 171.573 116.299 171.693 115.576 171.519L88.5897 165.004C87.8666 164.829 87.2429 164.374 86.8551 163.74C86.4674 163.106 86.3474 162.345 86.5215 161.622Z"
            fill="#F2F2F2"
          />
          <path
            opacity="0.2"
            d="M91.8099 164.325C91.0869 164.15 90.4631 163.695 90.0754 163.062C89.6877 162.428 89.5677 161.666 89.7418 160.944C88.8826 148.216 91.3979 136.244 98.3835 125.272C98.5012 124.799 98.7421 124.365 99.0818 124.015L98.5508 123.886C97.8274 123.713 97.0645 123.832 96.4294 124.219C95.7943 124.606 95.339 125.229 95.1632 125.951C88.1776 136.922 85.6623 148.895 86.5215 161.622C86.3474 162.344 86.4674 163.106 86.8551 163.74C87.2428 164.374 87.8666 164.829 88.5896 165.004L115.575 171.519C116.055 171.633 116.557 171.619 117.029 171.477C117.501 171.336 117.927 171.072 118.265 170.713L91.8099 164.325Z"
            fill="black"
          />
          <path
            d="M116.243 149.309L98.1473 144.993C97.8596 144.924 97.611 144.744 97.4563 144.492C97.3015 144.24 97.2532 143.938 97.3219 143.65C97.3907 143.363 97.5709 143.115 97.8229 142.96C98.075 142.805 98.3782 142.757 98.6661 142.825L116.762 147.141C117.049 147.211 117.297 147.391 117.451 147.642C117.605 147.894 117.653 148.196 117.585 148.483C117.516 148.77 117.336 149.018 117.085 149.173C116.833 149.327 116.531 149.376 116.243 149.309L116.243 149.309Z"
            fill="white"
          />
          <path
            d="M103.551 139.998L99.5699 139.049C99.282 138.981 99.033 138.801 98.8775 138.55C98.7221 138.299 98.6729 137.996 98.7407 137.709C98.8085 137.421 98.9879 137.173 99.2393 137.017C99.4908 136.862 99.7938 136.812 100.082 136.879L100.089 136.881L104.07 137.831C104.357 137.9 104.605 138.08 104.759 138.332C104.913 138.583 104.961 138.886 104.892 139.172C104.824 139.459 104.644 139.707 104.393 139.862C104.141 140.017 103.838 140.066 103.551 139.998V139.998Z"
            fill="white"
          />
          <path
            d="M113.962 158.839L95.8666 154.523C95.5794 154.454 95.3315 154.274 95.1772 154.022C95.0229 153.77 94.9748 153.468 95.0435 153.181C95.1122 152.894 95.292 152.646 95.5435 152.491C95.795 152.337 96.0978 152.288 96.3854 152.355L114.481 156.671C114.624 156.705 114.759 156.766 114.878 156.851C114.997 156.937 115.099 157.045 115.176 157.169C115.253 157.294 115.305 157.432 115.329 157.577C115.352 157.722 115.347 157.869 115.314 158.012C115.28 158.155 115.218 158.289 115.133 158.408C115.047 158.527 114.938 158.628 114.814 158.705C114.689 158.782 114.55 158.833 114.405 158.857C114.26 158.88 114.112 158.875 113.969 158.841L113.962 158.839Z"
            fill="white"
          />
          <path
            d="M115.103 154.074L97.0069 149.758C96.7189 149.69 96.4698 149.51 96.3145 149.258C96.1593 149.007 96.1105 148.704 96.1789 148.416C96.2474 148.129 96.4275 147.88 96.6795 147.725C96.9316 147.57 97.2351 147.521 97.5231 147.59L97.5257 147.59L115.622 151.906C115.909 151.976 116.157 152.156 116.311 152.407C116.465 152.659 116.513 152.961 116.445 153.248C116.376 153.535 116.196 153.783 115.945 153.938C115.693 154.093 115.39 154.142 115.103 154.074L115.103 154.074Z"
            fill="white"
          />
          <path
            d="M111.277 131.691C113.848 131.691 115.932 129.61 115.932 127.044C115.932 124.478 113.848 122.397 111.277 122.397C108.707 122.397 106.623 124.478 106.623 127.044C106.623 129.61 108.707 131.691 111.277 131.691Z"
            fill="#3F3D56"
          />
          <path
            d="M87.8085 164.282C87.4288 164.073 87.0992 163.784 86.8426 163.435C86.5859 163.085 86.4083 162.685 86.322 162.26C86.2357 161.836 86.2428 161.398 86.3427 160.976C86.4427 160.555 86.6332 160.16 86.901 159.82L79.7135 146.757L82.844 143.884L91.2671 159.461C91.808 159.954 92.1491 160.627 92.2258 161.354C92.3024 162.081 92.1093 162.811 91.683 163.405C91.2567 164 90.6268 164.417 89.9127 164.579C89.1986 164.741 88.4499 164.635 87.8085 164.282H87.8085Z"
            fill="#FFB6B6"
          />
          <path
            d="M79.4135 106.46L71.683 105.716C71.3223 105.681 70.9584 105.722 70.6145 105.836C70.2707 105.95 69.9545 106.134 69.6862 106.377C69.418 106.62 69.2037 106.917 69.057 107.248C68.9104 107.578 68.8346 107.936 68.8346 108.298V131.263L82.9756 157.148L88.8165 153.465L79.5941 130.755L79.4135 106.46Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M140.443 104.597C142.235 91.9661 147.173 80.7706 156.278 71.476C156.599 70.806 157.173 70.2906 157.875 70.0428C158.576 69.795 159.348 69.8351 160.019 70.1543L185.076 82.093C185.747 82.4137 186.263 82.9871 186.511 83.6874C186.759 84.3876 186.719 85.1576 186.399 85.8283L170.564 118.949C170.243 119.619 169.669 120.134 168.967 120.382C168.266 120.63 167.494 120.59 166.823 120.271L141.767 108.332C141.095 108.011 140.579 107.438 140.331 106.737C140.083 106.037 140.123 105.267 140.443 104.597Z"
            fill="#F2F2F2"
          />
          <path
            opacity="0.2"
            d="M145.058 108.332C144.387 108.011 143.87 107.438 143.622 106.737C143.374 106.037 143.414 105.267 143.734 104.596C145.526 91.966 150.464 80.7705 159.569 71.4759C159.782 71.037 160.108 70.6622 160.513 70.3892L160.02 70.1543C159.348 69.8351 158.576 69.795 157.875 70.0427C157.174 70.2905 156.599 70.8059 156.278 71.4759C147.173 80.7705 142.235 91.966 140.443 104.596C140.123 105.267 140.083 106.037 140.331 106.737C140.579 107.438 141.095 108.011 141.767 108.332L166.823 120.27C167.268 120.481 167.762 120.57 168.253 120.529C168.745 120.488 169.217 120.317 169.621 120.036L145.058 108.332Z"
            fill="black"
          />
          <path
            d="M172.071 98.6765L155.258 90.7226C154.991 90.5961 154.784 90.3686 154.685 90.0902C154.585 89.8119 154.601 89.5055 154.727 89.2384C154.854 88.9713 155.082 88.7654 155.361 88.666C155.64 88.5667 155.947 88.582 156.214 88.7085L173.027 96.6624C173.293 96.7896 173.499 97.0171 173.597 97.295C173.696 97.5729 173.681 97.8785 173.554 98.145C173.428 98.4115 173.201 98.617 172.923 98.7166C172.645 98.8163 172.338 98.8018 172.071 98.6765L172.071 98.6765Z"
            fill="white"
          />
          <path
            d="M161.579 86.9498L157.88 85.1999C157.747 85.1378 157.628 85.0501 157.529 84.9419C157.43 84.8337 157.353 84.7071 157.304 84.5694C157.254 84.4316 157.232 84.2854 157.239 84.1392C157.245 83.9929 157.281 83.8494 157.343 83.7168C157.406 83.5843 157.494 83.4653 157.602 83.3668C157.711 83.2682 157.838 83.1919 157.976 83.1423C158.114 83.0927 158.26 83.0708 158.407 83.0777C158.553 83.0847 158.697 83.1204 158.83 83.1828L158.836 83.1858L162.535 84.9357C162.801 85.0628 163.007 85.2903 163.106 85.5682C163.205 85.8462 163.189 86.152 163.063 86.4186C162.936 86.6852 162.709 86.8908 162.431 86.9904C162.152 87.0899 161.846 87.0753 161.579 86.9498Z"
            fill="white"
          />
          <path
            d="M167.868 107.531L151.055 99.5771C150.788 99.45 150.583 99.2226 150.484 98.9446C150.385 98.6666 150.4 98.3608 150.527 98.0942C150.654 97.8276 150.881 97.622 151.159 97.5225C151.437 97.4229 151.743 97.4375 152.011 97.563L168.824 105.517C168.956 105.579 169.075 105.667 169.174 105.775C169.273 105.883 169.349 106.01 169.399 106.148C169.449 106.285 169.471 106.431 169.464 106.577C169.457 106.724 169.421 106.867 169.359 107C169.297 107.132 169.209 107.251 169.1 107.349C168.992 107.448 168.865 107.524 168.727 107.574C168.589 107.623 168.443 107.645 168.297 107.639C168.15 107.632 168.007 107.596 167.874 107.534L167.868 107.531Z"
            fill="white"
          />
          <path
            d="M169.969 103.104L153.156 95.1499C152.889 95.0236 152.682 94.7964 152.582 94.5182C152.483 94.2399 152.497 93.9335 152.624 93.6662C152.75 93.399 152.978 93.1929 153.257 93.0932C153.535 92.9935 153.842 93.0084 154.11 93.1346L154.113 93.1358L170.925 101.09C171.192 101.217 171.397 101.444 171.496 101.722C171.595 102 171.579 102.306 171.453 102.572C171.326 102.839 171.099 103.044 170.821 103.144C170.543 103.244 170.237 103.229 169.969 103.104L169.969 103.104Z"
            fill="white"
          />
          <path
            d="M171.818 80.5144C174.389 80.5144 176.473 78.434 176.473 75.8678C176.473 73.3015 174.389 71.2212 171.818 71.2212C169.248 71.2212 167.164 73.3015 167.164 75.8678C167.164 78.434 169.248 80.5144 171.818 80.5144Z"
            fill="#3F3D56"
          />
          <path
            d="M211.985 120.959C206.937 110.027 204.726 98.2447 206.274 85.337C206.035 83.8354 206.874 82.3306 208.149 81.9732L231.003 75.5991C232.278 75.2451 233.507 76.1734 233.749 77.6742L239.459 113.296C239.698 114.798 238.86 116.303 237.585 116.66L214.731 123.034C213.455 123.388 212.227 122.46 211.985 120.959Z"
            fill="#F2F2F2"
          />
          <path
            opacity="0.2"
            d="M216.956 120.978C215.68 121.332 214.452 120.404 214.209 118.903C209.161 107.971 206.951 96.189 208.499 83.2813C208.426 82.802 208.46 82.3125 208.598 81.8478L208.149 81.9732C206.874 82.3306 206.035 83.8353 206.274 85.3369C204.726 98.2447 206.937 110.027 211.985 120.959C212.227 122.46 213.455 123.388 214.731 123.034L237.585 116.66C238.02 116.514 238.411 116.261 238.722 115.923C239.032 115.586 239.252 115.175 239.36 114.73L216.956 120.978Z"
            fill="black"
          />
          <path
            d="M230.431 96.6054L215.124 100.927C214.616 101.069 214.127 100.701 214.03 100.104C213.975 99.8289 214.02 99.5437 214.155 99.2986C214.291 99.0535 214.51 98.8644 214.772 98.7649L230.079 94.4435C230.586 94.3022 231.075 94.672 231.171 95.2694C231.225 95.5435 231.181 95.8279 231.045 96.0724C230.91 96.3169 230.692 96.5057 230.431 96.6054H230.431Z"
            fill="white"
          />
          <path
            d="M217.526 94.0481L214.159 94.9988C213.652 95.1438 213.161 94.7778 213.062 94.1813C213.006 93.9064 213.05 93.6205 213.185 93.3746C213.32 93.1287 213.539 92.9387 213.801 92.8384L213.807 92.8368L217.174 91.8861C217.682 91.7448 218.171 92.1146 218.266 92.712C218.321 92.9861 218.276 93.2705 218.141 93.515C218.005 93.7595 217.788 93.9483 217.526 94.0481Z"
            fill="white"
          />
          <path
            d="M231.978 106.11L216.671 110.431C216.163 110.573 215.674 110.203 215.579 109.605C215.524 109.331 215.569 109.047 215.704 108.802C215.84 108.558 216.057 108.369 216.319 108.269L231.626 103.948C232.133 103.803 232.624 104.169 232.723 104.765C232.779 105.04 232.735 105.326 232.6 105.572C232.464 105.818 232.246 106.008 231.984 106.108L231.978 106.11Z"
            fill="white"
          />
          <path
            d="M231.204 101.358L215.897 105.679C215.39 105.823 214.9 105.456 214.802 104.859C214.746 104.584 214.79 104.298 214.926 104.053C215.062 103.807 215.281 103.617 215.543 103.518L215.545 103.517L230.852 99.1957C231.36 99.0544 231.849 99.4242 231.944 100.022C231.999 100.296 231.954 100.58 231.819 100.825C231.683 101.069 231.466 101.258 231.204 101.358L231.204 101.358Z"
            fill="white"
          />
          <path
            d="M222.809 79.5398C223.139 76.9947 221.68 74.709 219.552 74.4345C217.424 74.16 215.432 76.0007 215.103 78.5458C214.773 81.0909 216.231 83.3766 218.36 83.6511C220.488 83.9256 222.48 82.0849 222.809 79.5398Z"
            fill="#3F3D56"
          />
        </g>
        <defs>
          <clipPath id="clip0_6_937">
            <rect width="226" height="210" fill="white" transform="translate(13.5 21)" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
