import { Chip, styled } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { colors } from "styles/colors";

interface WChipProps {
  chipLabel: string;
  onDelete?: (data?: Record<string, unknown>) => void;
  onClick?: () => void;
  color?: "primary" | "secondary";
  icon?: ReactElement;
  sx?: Record<string, unknown>;
  variant?: "shadow" | "default";
}

export const WChip: FC<WChipProps> = ({
  chipLabel,
  onDelete,
  onClick,
  color = "primary",
  icon,
  variant = "default",
  sx,
}): JSX.Element => {
  return (
    <Chip
      label={
        <div
          className="ShortEllipsisText"
          style={{ ...(variant === "shadow" ? { fontWeight: "bold" } : {}) }}
        >
          {chipLabel}
        </div>
      }
      variant="outlined"
      color={color}
      size={variant === "shadow" ? "medium" : "small"}
      icon={icon}
      onClick={onClick}
      onDelete={onDelete}
      sx={{
        maxWidth: "100%",
        ...(variant === "shadow"
          ? { boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1)" }
          : {}),
        ...sx,
      }}
    />
  );
};

interface WApplicationChipProps {
  selected?: boolean;
}
export const WApplicationChip = styled(Chip)<WApplicationChipProps>`
  background-color: ${(props) => (props.selected ? "secondary" : colors.grey)};
  box-shadow: ${(props) =>
    props.selected ? "0px 4px 4px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1)" : "none"};
`;
