import React, { ChangeEvent, FC, useCallback, useContext, useState } from "react";
import { CircularProgress, MenuItem, styled } from "@mui/material";
import { WMenu } from "./styledComponents/WMenu";
import { WTextField } from "./styledComponents/WTextField";
import { WTypography } from "./WTypography";
import { LoaderActions } from "reducers/LoaderReducer";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { colors, defaultStyles } from "styles/colors";
import { WIcon, WIconTypes } from "./WIcon";

export interface SearchDropdownItem {
  value: string;
  icon?: WIconTypes;
  text: string;
  disabled?: boolean;
}

export interface SearchDropdownProps {
  label?: string;
  items: SearchDropdownItem[];
  onSearch: (text: string) => void;
  onSelect: (value: string) => void;
  setLastElementRef?: (node: Element | null) => void;
  loaderAction?: LoaderActions;
  placement?: "top" | "bottom";
  dropdownWidth?: string;
}
export const SearchDropdown: FC<SearchDropdownProps> = ({
  label,
  items,
  onSearch,
  onSelect,
  loaderAction,
  setLastElementRef,
  placement = "top",
  dropdownWidth = "300px",
}) => {
  const [text, setText] = useState("");
  const [previousText, setPreviousText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout | null>(null);
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);

  const performSearch = useCallback(
    (value: string): void => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
      setTimeoutHandle(
        setTimeout(() => {
          if (value === previousText) {
            return;
          }
          setPreviousText(value);
          onSearch(value);
        }, 400)
      );
    },
    [timeoutHandle, previousText]
  );

  const handleSearchInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const value = e.target.value;
      setText(value);
      performSearch(value);
      setAnchorEl(e.currentTarget);
    },
    [performSearch]
  );

  const loading = loaderAction && isLoading(loaderAction);

  return (
    <>
      <WTextField
        label={label}
        fullWidth
        value={text}
        onChange={handleSearchInput}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        InputProps={{
          endAdornment: loading ? (
            <CircularProgress size="16px" sx={{ position: "absolute", right: "10px" }} />
          ) : null,
        }}
      />
      <WMenu
        autoFocus={false}
        disableAutoFocus={true}
        disableAutoFocusItem={true}
        disableEnforceFocus={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        fullwidth={false}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: placement,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: placement === "top" ? "bottom" : "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            width: dropdownWidth,
            height: "150px",
            overflowY: "auto",
            borderRadius: defaultStyles.borderRadius,
            boxShadow: defaultStyles.bigCardShadow,
            backgroundColor: colors.white,
          },
        }}
      >
        {items.length === 0 ? (
          <WTypography padding="10px" variant="body1" fontStyle="italic" textAlign="center">
            {localize("tasks.noResults")}
          </WTypography>
        ) : (
          items.map(({ value, icon, text, disabled }, index) => {
            const content = (
              <>
                {icon ? (
                  <IconWrapper>
                    <WIcon icon={icon} size="small" />
                  </IconWrapper>
                ) : null}
                <WTypography variant="body2" lineClamps={1}>
                  {text}
                </WTypography>
              </>
            );

            if (index === items.length - 1) {
              return (
                <div key={value} ref={loading ? undefined : setLastElementRef}>
                  <MenuItem
                    value={value}
                    onClick={() => {
                      onSelect(value);
                      setAnchorEl(null);
                    }}
                    disabled={disabled}
                  >
                    {content}
                  </MenuItem>
                </div>
              );
            }
            return (
              <MenuItem
                key={value}
                value={value}
                onClick={() => {
                  onSelect(value);
                  setAnchorEl(null);
                }}
                disabled={disabled}
              >
                {content}
              </MenuItem>
            );
          })
        )}
      </WMenu>
    </>
  );
};

const IconWrapper = styled("div")`
  display: flex;
  margin-left: -5px;
  margin-right: 5px;
`;
