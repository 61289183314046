import { getProfile, getClientConfig, getJobApplicationList } from "adapters";
import React, { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { UserReducer, User, UserActionsType } from "reducers/UserReducer";
import { LoaderContext } from "./LoaderContext";
import { NotificationContext } from "./NotificationContext";
import { PROFILE_STEPS } from "views/Enums";
import { GTMContext } from "./GTMContext";

type UserPermissions = "add_user" | "delete_user" | "view_user" | "change_user";

export type ClientConfiguration = {
  is_authorized: boolean;
  is_agent: boolean;
  user?: UserPermissions[];
};

export type ProfileCompleteness = {
  percentage: number;
  steps: string[];
};

export const UserInitialState = {
  user: {
    id: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    availability_date: "",
    created: "",
    profile_picture: "",
    rating: undefined,
    profile_completed: "",
    profile_completeness: 0,
    completeness_steps: [],
    tax_document: "",
    linkedin_url: "",
    language: undefined,
    skills: [],
    regions: [],
    departments: [],
    referrals: [],
    cv: [],
    user_documents: [],
  } as User,
  clientConfiguration: {} as ClientConfiguration,
  profileCompleteness: { percentage: 0, steps: [] } as ProfileCompleteness,
  users: [] as User[],
};

type ProfileProgressSteps =
  | PROFILE_STEPS.skill
  | PROFILE_STEPS.availability
  | PROFILE_STEPS.cv
  | PROFILE_STEPS.jobApplication;

export const UserContext = createContext<{
  dispatch: React.Dispatch<UserActionsType>;
  isAuthenticated: boolean;
  isAgent: boolean;
  user: User;
  users: User[];
  login: () => void;
  hasPermission: (path: string, permission: UserPermissions) => boolean;
  profileCompleteness: ProfileCompleteness;
  calculateProfileProgress: (condition: boolean, stepName: ProfileProgressSteps) => void;
}>({
  dispatch: () => ({} as React.Dispatch<UserActionsType>),
  isAuthenticated: false,
  isAgent: false,
  user: {} as User,
  users: [] as User[],
  login: () => false,
  hasPermission: () => false,
  profileCompleteness: { percentage: 0, steps: [] },
  calculateProfileProgress: () => false,
});

const UserProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(UserReducer, UserInitialState);
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);

  const fetchConfigAndProfile = () => {
    getClientConfig()
      .then((clientConfRes) => {
        if (clientConfRes.data.is_authorized) {
          dispatch({ type: "GET_CLIENT_CONFIG", clientConfig: clientConfRes.data });
          getProfile()
            .then((res) => {
              dispatch({ type: "UPDATE_USER", user: res.data });
              if (!clientConfRes.data.is_agent) {
                initializeProfileProgress(res.data);
              }
            })
            .catch(() => {
              // Add push to error page when its implemented. Profile needs to succeed in order to get ID
              addMessage({ type: "error" });
            })
            .finally(() => {
              dispatchLoading({ type: "STOP_LOADING", payload: "GET_CLIENT_CONFIG" });
            });
        } else {
          dispatchLoading({ type: "STOP_LOADING", payload: "GET_CLIENT_CONFIG" });
        }
      })
      .catch(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_CLIENT_CONFIG" });
      });
  };

  // Handle Client Config and profile data when refreshing a page
  useEffect(() => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_CLIENT_CONFIG" });
    fetchConfigAndProfile();
  }, []);

  const login = () => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_CLIENT_CONFIG" });
    fetchConfigAndProfile();
  };

  const hasPermission = (path: string, permission: UserPermissions) => {
    if (!state.clientConfiguration[path]) return false;
    return !!state.clientConfiguration[path].some((perm: UserPermissions) => perm === permission);
  };

  const profileProgressSteps: ProfileProgressSteps[] = [
    PROFILE_STEPS.skill,
    PROFILE_STEPS.availability,
    PROFILE_STEPS.cv,
    PROFILE_STEPS.jobApplication,
  ];

  const handleGTMAdd = (stepName: ProfileProgressSteps, percentage: number) => {
    switch (stepName) {
      case "skill":
        gtmAddToDataLayer(
          { action: "added_competence", profile_strength: percentage },
          "profile_update"
        );
        break;
      case "availability":
        gtmAddToDataLayer({ action: "added_date", profile_strength: percentage }, "profile_update");
        break;
      case "cv":
        gtmAddToDataLayer({ action: "added_cv", profile_strength: percentage }, "profile_update");
        break;

      default:
        break;
    }
  };

  const calculateProfileProgress = useCallback(
    (isComplete: boolean, stepName: ProfileProgressSteps) => {
      let filteredSteps: string[] = [];
      let percentage;

      if (isComplete && state.profileCompleteness.steps.includes(stepName)) {
        filteredSteps = state.profileCompleteness.steps.filter((step) => step !== stepName);
        percentage = state.profileCompleteness.percentage + 100 / profileProgressSteps.length;
        handleGTMAdd(stepName, percentage);
      } else if (!isComplete && !state.profileCompleteness.steps.includes(stepName)) {
        filteredSteps = state.profileCompleteness.steps.concat([stepName]);
        percentage = state.profileCompleteness.percentage - 100 / profileProgressSteps.length;
      }

      // Only apply if percentage is changed
      if (percentage !== undefined && percentage >= 0) {
        dispatch({
          type: "SET_PROFILE_COMPLETENESS",
          profileCompleteness: {
            percentage: percentage,
            steps: filteredSteps,
          },
        });
      }
    },
    [state.profileCompleteness, handleGTMAdd, profileProgressSteps]
  );

  const initializeProfileProgress = useCallback(
    async (user: User): Promise<void> => {
      dispatchLoading({ type: "SET_LOADING", payload: "GET_PROFILE_COMPLETENESS" });
      const completedSteps: string[] = [];
      const jobApplCount = await getJobApplicationList({ page_size: 1 });

      if (jobApplCount.data.count > 0) {
        completedSteps.push(PROFILE_STEPS.jobApplication);
      }

      if (user.cv.length > 0) {
        completedSteps.push(PROFILE_STEPS.cv);
      }

      if (user.skills.length > 0) {
        completedSteps.push(PROFILE_STEPS.skill);
      }

      if (user.availability_date) {
        completedSteps.push(PROFILE_STEPS.availability);
      }

      dispatch({
        type: "SET_PROFILE_COMPLETENESS",
        profileCompleteness: {
          percentage: (completedSteps.length / profileProgressSteps.length) * 100,
          steps: profileProgressSteps.filter(
            (step) => !completedSteps.some((cStep) => cStep === step)
          ),
        },
      });
      dispatchLoading({ type: "STOP_LOADING", payload: "GET_PROFILE_COMPLETENESS" });
    },
    [profileProgressSteps]
  );

  const value = useMemo(() => {
    return {
      isAuthenticated: state.clientConfiguration.is_authorized,
      isAgent: state.clientConfiguration.is_agent,
      user: state.user,
      users: state.users,
      dispatch,
      login,
      hasPermission: hasPermission,
      profileCompleteness: state.profileCompleteness,
      calculateProfileProgress: calculateProfileProgress,
    };
  }, [
    state.clientConfiguration.is_authorized,
    state.clientConfiguration.is_agent,
    state.user,
    state.users,
    dispatch,
    login,
    hasPermission,
    state.profileCompleteness,
    calculateProfileProgress,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
