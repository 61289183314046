import React, { FC, useCallback, useContext } from "react";
import { Grid, Tooltip } from "@mui/material";
import { WIcon, WIconTypes } from "components";
import { colors, defaultStyles } from "styles/colors";
import styled from "@emotion/styled";
import { LocaleContext } from "context/LocaleContext";
import { ConditionalWrapper } from "utils/ConditionalWrapper";

interface UserRatingLabelProps {
  rating?: number;
  shadow?: boolean;
  canRate?: boolean;
  handleSetUserRating?: (rating: number) => void;
  customColor?: string;
}

export const UserRatingLabel: FC<UserRatingLabelProps> = ({
  rating = undefined,
  shadow = false,
  canRate = false,
  handleSetUserRating,
  customColor,
}) => {
  const { localize } = useContext(LocaleContext);

  const isStarFilled = useCallback(
    (index: number) => {
      if (typeof rating !== "undefined") {
        if (index + 1 <= rating) {
          return true;
        }
      }
      return false;
    },
    [rating]
  );

  return (
    <Tooltip
      title={
        rating
          ? localize("dialog.setRating", { rating: rating.toString() })
          : localize("dialog.ratingNotSet")
      }
      arrow
      disableInteractive={canRate}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: colors.mainDark,
            color: colors.siteBackground,
            fontWeight: "bold",
            "& .MuiTooltip-arrow": {
              color: colors.mainDark,
            },
          },
        },
      }}
    >
      <Grid
        container
        gap="4px"
        alignItems="center"
        justifyContent="center"
        padding=".1rem .2rem"
        sx={{
          width: "max-content",
          minWidth: "max-content",
        }}
        boxShadow={shadow ? "-1px 1px 2px rgba(0, 0, 0, 0.25)" : "none"}
        border={shadow ? `1px solid ${colors.main}` : "auto"}
        borderRadius={defaultStyles.borderRadius}
      >
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <ConditionalWrapper
              key={`wrapper_${index}`}
              condition={canRate}
              wrapper={(children) => (
                <IconSpan
                  key={`
                starIndex_${index}`}
                  onClick={() => handleSetUserRating?.(index + 1)}
                >
                  {children}
                </IconSpan>
              )}
            >
              <WIcon
                icon={WIconTypes.star}
                size="small"
                customColor={customColor || colors.main}
                fill={isStarFilled(index)}
              />
            </ConditionalWrapper>
          ))}
      </Grid>
    </Tooltip>
  );
};

const IconSpan = styled.span`
  display: flex;
  :hover {
    cursor: pointer;
    transition: fill 0.2s ease-out;
    svg {
      fill: ${colors.main} !important;
      transform: scale(1.2);
    }
  }
`;
