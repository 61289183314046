import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { WIcon, WIconButton, WIconTypes } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, ReactNode } from "react";
import { colors, defaultStyles } from "styles/colors";

interface ProfileSectionCardProps {
  title: string;
  subTitle?: string;
  icons?: ReactNode;
  infoToolTip?: string;
  isExpanded?: boolean;
  isSmall?: boolean;
  onClick?: () => void;
}

export const ProfileSectionCard: FC<ProfileSectionCardProps> = ({
  title,
  subTitle,
  infoToolTip,
  onClick,
  isExpanded = false,
  isSmall = false,
  icons = <WIcon icon={WIconTypes.arrowRight} />,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  return (
    <Grid
      container
      onClick={onClick}
      alignItems="center"
      sx={{
        cursor: "pointer",
        transition: "all 0.2s ease-out",
        "&:hover": { backgroundColor: colors.grey },
      }}
      justifyContent="space-between"
      bgcolor="common.white"
      padding={
        // prettier-ignore
        (isDesktop) 
        ? "1.5rem 1rem 1.5rem 3rem" 
        : (isSmall) 
        ? ".5rem 1rem" 
        : "1.5rem 1rem 1.5rem 3rem"
      }
      border={isDesktop ? `1px solid ${colors.grey}` : undefined}
      borderRadius={
        isDesktop ? (isExpanded ? defaultStyles.borderRadiusTop : defaultStyles.borderRadius) : 0
      }
      wrap="nowrap"
    >
      <Box>
        <Box display="flex" alignItems="flex-end">
          <Typography fontWeight={isDesktop ? `bold` : undefined} color={colors.mainDark}>
            {localize(`profileSections.${title}`)}
          </Typography>
          {infoToolTip && (
            <Tooltip
              arrow
              title={infoToolTip}
              disableFocusListener
              disableTouchListener
              PopperProps={{
                popperOptions: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -8],
                      },
                    },
                  ],
                },
              }}
            >
              <WIconButton sx={{ height: "max-content", padding: "4px", marginLeft: ".5rem" }}>
                <WIcon icon={WIconTypes.info} color="primary" size="small" />
              </WIconButton>
            </Tooltip>
          )}
        </Box>
        {subTitle && (
          <Typography variant="subtitle2" color="gray">
            {localize(`profileSections.${subTitle}`)}
          </Typography>
        )}
      </Box>
      <Grid marginLeft="auto">{icons && icons}</Grid>
    </Grid>
  );
};
