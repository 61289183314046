import React, { FC, useContext, useEffect, useState } from "react";
import { Skill } from "adapters";
import { LocaleContext } from "context/LocaleContext";
import { FormHelperText, Grid, Typography, useTheme } from "@mui/material";
import { UserContext } from "context/UserContext";
import { WApplicationChip } from "components/WChip";
import { WIcon, WIconTypes } from "components/WIcon";

interface JobApplicationSkillComponentProps {
  jobSkills: Skill[] | undefined;
  preSelectedSkills: Skill[];
  handleSelectedSkills: (skills: Skill[]) => void;
  hasRequiredSkill: {
    state: boolean;
    nrOfRequiredSkills: number;
  };
}

export const JobApplicationSkillComponent: FC<JobApplicationSkillComponentProps> = ({
  jobSkills,
  preSelectedSkills,
  handleSelectedSkills,
  hasRequiredSkill,
}) => {
  const { localize } = useContext(LocaleContext);
  const { user } = useContext(UserContext);

  const [matchingSkills, setMatchingSkills] = useState<Skill[]>([]);
  const [desiredSkills, setDesiredSkills] = useState<Skill[]>([]);
  const theme = useTheme();
  useEffect(() => {
    if (!preSelectedSkills) {
      const tempMatchingArray = user.skills.filter((userSkill) =>
        jobSkills?.some((desiredSkill) => userSkill.id === desiredSkill.id)
      );

      setMatchingSkills(tempMatchingArray);
      setDesiredSkills(
        jobSkills?.filter(
          (jobSkill) => !tempMatchingArray.some((matchingSkill) => matchingSkill.id === jobSkill.id)
        ) || []
      );
    } else {
      setMatchingSkills(preSelectedSkills);
      setDesiredSkills(
        jobSkills?.filter(
          (jobSkill) => !preSelectedSkills.some((matchingSkill) => matchingSkill.id === jobSkill.id)
        ) || []
      );
    }
  }, [preSelectedSkills]);

  const handleRemoveSkill = (skillToRemove: Skill) => {
    handleSelectedSkills(
      matchingSkills.filter((matchingSkill) => skillToRemove.id !== matchingSkill.id)
    );
  };

  const handleAddSkill = (skillToAdd: Skill) => {
    handleSelectedSkills([...matchingSkills, skillToAdd]);
  };

  return (
    <Grid container item wrap="nowrap" direction="column" gap="1.5rem">
      <Grid container item wrap="nowrap" direction="column" gap=".5rem">
        <Typography fontWeight="bold" color="primary">
          {localize("form.matchingSkills")}
        </Typography>
        {!hasRequiredSkill.state && (
          <FormHelperText error>
            {localize("form.requiredSkillError", {
              requiredSkills: (
                hasRequiredSkill.nrOfRequiredSkills - preSelectedSkills.length
              ).toString(),
            })}
          </FormHelperText>
        )}
        <Grid container gap=".4rem">
          {matchingSkills.length > 0 ? (
            matchingSkills.map((matchingSkill) => {
              return (
                <WApplicationChip
                  key={matchingSkill.id}
                  label={matchingSkill.name}
                  color="secondary"
                  icon={<WIcon icon={WIconTypes.clear} />}
                  sx={{
                    color: theme.palette.primary.dark,
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                  selected={true}
                  onClick={() => handleRemoveSkill(matchingSkill)}
                />
              );
            })
          ) : (
            <Typography variant="body2" color="primary">
              {localize("form.emptyMatchingSkills")}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container item wrap="nowrap" direction="column" gap=".5rem">
        <Typography fontWeight="bold" color="primary">
          {localize("form.desiredSkills")}
        </Typography>
        <Grid container gap=".4rem">
          {desiredSkills.length > 0 ? (
            desiredSkills.map((matchingSkill) => {
              return (
                <WApplicationChip
                  key={matchingSkill.id}
                  label={matchingSkill.name}
                  sx={{
                    color: theme.palette.primary.dark,
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                  color="secondary"
                  icon={<WIcon icon={WIconTypes.add} />}
                  onClick={() => handleAddSkill(matchingSkill)}
                />
              );
            })
          ) : (
            <Typography variant="body2" color="primary">
              {localize("form.emptyDesiredSkills")}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
