import React, { FC, useContext } from "react";
import styled from "@emotion/styled";
import { Drawer, IconButton, Divider, Tooltip } from "@mui/material";
import { WIcon, WIconTypes } from "components/WIcon";
import { TasksColumn } from "components/Tasks/TasksColumn";
import { WTypography } from "components/WTypography";
import { LocaleContext } from "context/LocaleContext";
import { WButton } from "components/styledComponents/WButton";
import { colors } from "styles/colors";
import { DrawerSection } from "components/styledComponents/WDrawerSection";

export const TaskDrawerWidth = "min(500px, 30vw)";

interface Props {
  isOpen: boolean;
  setOpen: (newValue: boolean) => void;
  openDrawerButtonY?: string;
  filterJobId?: string;
  dragGroupName?: string;
}

export const TasksDrawer: FC<Props> = ({
  isOpen,
  setOpen,
  openDrawerButtonY = "20px",
  filterJobId,
  dragGroupName,
}) => {
  const { localize } = useContext(LocaleContext);

  return (
    <>
      <Tooltip title={localize("tasks.tasks")} placement="left">
        <FloatingButton
          variant="contained"
          sx={{
            top: openDrawerButtonY,
          }}
          onClick={() => setOpen(true)}
        >
          <WIcon icon={WIconTypes.arrowLeft} />
        </FloatingButton>
      </Tooltip>
      <Drawer
        sx={{
          width: TaskDrawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: TaskDrawerWidth,
            boxSizing: "border-box",
            transition: "transform 0.2s linear !important",
            height: "100vh",
            backgroundColor: colors.lightGrey,
            overflowX: "hidden",
          },
        }}
        variant="persistent"
        anchor="right"
        open={isOpen}
      >
        <DrawerSection>
          <WTypography variant="h6" fontWeight="bold">
            {localize("tasks.tasks")}
          </WTypography>
          <IconButton sx={{ marginLeft: "auto" }} onClick={() => setOpen(false)}>
            <WIcon icon={WIconTypes.arrowRight} />
          </IconButton>
        </DrawerSection>
        <Divider />
        <TasksColumn isOpen={isOpen} filterJobId={filterJobId} dragGroupName={dragGroupName} />
      </Drawer>
    </>
  );
};

const FloatingButton = styled(WButton)`
  position: fixed;
  right: 5px;
  width: 36px;
  min-width: 36px;
  height: 36px;

  svg {
    min-width: 24px;
  }
`;
