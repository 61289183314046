import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";

import { SuperHeader } from "components/headers/SuperHeader";
import { ExplanationCard, ExplanationSVGCard } from "components/ExplanationSVGCard";
import { BreakpointsContext } from "context/BreakpointContext";
import { BackgroundWaveSVG } from "components/assets/SVGComponents";
import { Page } from "adapters";
import dompurify from "dompurify";
import { LoaderContext } from "context/LoaderContext";
import { parseJobBodyWithBlankLinks } from "utils/JobUtils";

export type DynamicViewContentProps = {
  pageContent?: Page;
  explanationCards?: ExplanationCard[];
  explanationCardsVariant?: "vertical" | "horizontal";
  pageHTMLContent?: string;
  forceSuperHeader?: boolean;
};

export const DynamicViewContent: FC<DynamicViewContentProps> = ({
  pageContent,
  explanationCards,
  pageHTMLContent,
  explanationCardsVariant = "vertical",
  forceSuperHeader = false,
}) => {
  const { isDesktop } = useContext(BreakpointsContext);
  const { isLoading } = useContext(LoaderContext);

  const [headerOffset, setHeaderOffset] = useState(0);

  const HTMLSanitizer = dompurify.sanitize;

  const content = useRef<HTMLDivElement | null>(null);
  const header = document.getElementById("SuperHeader");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!header) {
      return;
    }
    setHeaderOffset(header.getBoundingClientRect().height);
  }, [isDesktop, header]);

  useEffect(() => {
    parseJobBodyWithBlankLinks(content);
  }, [content, pageHTMLContent]);

  const isHorizontalDTVariant = explanationCardsVariant !== "horizontal";

  return (
    <Grid container position="relative">
      {(pageContent?.page_photo || forceSuperHeader) && (
        <SuperHeader headerContent={{ ...pageContent }} />
      )}

      <BackgroundWaveSVG headerOffset={headerOffset && headerOffset} />
      {isLoading("GET_PAGE_CONTENT") ? (
        <Grid container justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {pageHTMLContent && pageHTMLContent.length > 33 && (
            <Grid container position="relative">
              <div
                ref={content}
                style={{
                  wordBreak: "break-word",
                  width: "100%",
                  padding: " 2rem",
                  paddingTop: isDesktop ? "2rem" : "4rem",
                }}
                dangerouslySetInnerHTML={{ __html: HTMLSanitizer(pageHTMLContent) }}
              />
            </Grid>
          )}
        </>
      )}
      {explanationCards && (
        <Grid
          container
          id="CardContentContainer"
          justifyContent="center"
          padding="4rem 2rem"
          overflow="hidden"
          wrap={isHorizontalDTVariant && isDesktop ? "nowrap" : "wrap"}
          gap={isHorizontalDTVariant && isDesktop ? "1rem" : "3rem"}
        >
          {explanationCards.map((cardContent, index) => (
            <ExplanationSVGCard
              totalCards={explanationCards.length}
              key={`key_${cardContent.title}`}
              {...cardContent}
              variant={isDesktop ? explanationCardsVariant : "vertical"}
              direction={index % 2 === 0 ? "right_to_left" : "left_to_right"}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};
