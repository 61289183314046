import { Grid } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { colors } from "styles/colors";

interface CircularPercentageProps {
  percentage: number;
  strokeWidth?: number;
  size?: number;
  children?: ReactNode;
}

export const CircularPercentage: FC<CircularPercentageProps> = ({
  percentage,
  size = 40,
  strokeWidth = 2,
  children,
}) => {
  const clipSize = size - (percentage / 100) * size - strokeWidth;
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      position="relative"
      sx={{
        borderRadius: "100%",
        width: `${size}px`,
        height: `${size}px`,
        "&::before": {
          content: "''",
          position: "absolute",
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: "100%",
          outline: `${strokeWidth}px solid`,
          color: colors.lightBorder,
        },
        ...(percentage === 0
          ? {}
          : {
              "&::after": {
                content: "''",
                position: "absolute",
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: "100%",
                outline: `${strokeWidth}px solid`,
                color: "success.main",
                clipPath: `inset(-${strokeWidth}px ${clipSize}px -${strokeWidth}px -${strokeWidth}px)`,
              },
            }),
      }}
    >
      {children}
    </Grid>
  );
};
