import { Grid } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { WTypography } from "./WTypography";

interface ExplanationSVGCardProps {
  svg: ReactNode;
  title: string;
  subTitle?: string;
  subsubTitle?: string;
  link?: ReactNode;
  variant?: "vertical" | "horizontal";
  direction?: "left_to_right" | "right_to_left";
  callToAction?: ReactNode;
  totalCards?: number;
}

export type ExplanationCard = {
  svg: ReactNode;
  title: string;
  subTitle?: string;
  subsubTitle?: string;
  link?: ReactNode;
  callToAction?: ReactNode;
};

export const ExplanationSVGCard: FC<ExplanationSVGCardProps> = ({
  svg,
  title,
  subTitle,
  link,
  direction = "left_to_right",
  variant = "vertical",
  subsubTitle = "",
  callToAction,
  totalCards = 4,
}) => {
  if (variant === "vertical") {
    return (
      <Grid
        container
        item
        md={totalCards === 4 ? 3 : 2}
        sm={4}
        justifyContent="center"
        gap="1rem"
        position="relative"
        height="max-content"
      >
        {svg}
        <Grid container direction="column" gap=".2rem" justifyContent="center" alignItems="center">
          <WTypography fontWeight="bold">{title}</WTypography>

          <WTypography variant="body2" lineClamps={5} maxWidth="250px" textAlign="center">
            {subTitle}
          </WTypography>
          <WTypography variant="body2" lineClamps={5} maxWidth="250px" textAlign="center">
            {link}
          </WTypography>
          <WTypography variant="body2" lineClamps={4} maxWidth="250px">
            {subsubTitle}
          </WTypography>
          {callToAction}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      item
      justifyContent="space-between"
      alignItems="space-between"
      gap="1rem"
      position="relative"
      height="max-content"
      wrap="nowrap"
      md={8}
    >
      {direction === "left_to_right" && svg}
      <Grid width="max-content" container direction="column" gap="1rem" justifyContent="center">
        <WTypography fontWeight="bold" variant="h5">
          {title}
        </WTypography>
        <WTypography variant="body2" lineClamps={4} maxWidth="350px">
          {subTitle}
        </WTypography>
        <WTypography variant="body2" lineClamps={4} maxWidth="350px">
          {subsubTitle}
        </WTypography>
      </Grid>
      {direction === "right_to_left" && svg}
    </Grid>
  );
};
