import { Box, Grid, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { ProfileSettingsSections, SECTIONS } from "components/ProfileView/ProfileSettingsSections";
import {
  AvatarItem,
  DialogHeader,
  Navbar,
  WButton,
  WIcon,
  WIconTypes,
  WRedButton,
} from "components";
import { useHistory } from "react-router-dom";
import { BreakpointsContext } from "context/BreakpointContext";
import { UserContext } from "context/UserContext";
import { logout } from "adapters";
import { ModalContext } from "context/ModalContext";
import { generateWelcomeMessage } from "utils/GenerateWelcomeMessage";

export const ProfileSettingsView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { user, dispatch } = useContext(UserContext);
  const { showModal } = useContext(ModalContext);

  const history = useHistory();
  const { welcomeMessage } = generateWelcomeMessage();

  const handleLogout = () => {
    logout().finally(() => dispatch({ type: "LOGOUT" }));
  };

  return (
    <Grid paddingBottom="1rem">
      {isDesktop ? (
        <Box className="header" paddingBottom="2rem">
          <Navbar title={`${welcomeMessage} ${user.first_name}`} />
        </Box>
      ) : (
        <DialogHeader
          handleClose={() => history.goBack()}
          title={localize("profileSections.settingsHeader")}
          returnText={localize("common.goBack")}
        />
      )}
      <Grid container wrap="nowrap" className={isDesktop ? "contentWrapper" : ""} gap="2rem">
        <Grid container item md={10} direction="column">
          <Typography color="primary" padding="1rem" variant="h4" fontWeight="bold">
            {localize("profileSections.myDetails")}
          </Typography>
          <ProfileSettingsSections showSection={SECTIONS.MYDETAILS} />
          <Typography color="primary" padding="1rem" variant="h4" fontWeight="bold">
            {localize("profileSections.appSettings")}
          </Typography>
          <ProfileSettingsSections showSection={SECTIONS.SETTINGS} />
          <Typography color="primary" padding="1rem" variant="h4" fontWeight="bold">
            {localize("profileSections.about")}
          </Typography>
          <ProfileSettingsSections showSection={SECTIONS.ABOUT} />
          {isDesktop && (
            <Grid container>
              <Grid item md={5}>
                <WRedButton
                  fullWidth
                  sx={{ marginTop: "2rem" }}
                  variant="outlined"
                  onClick={handleLogout}
                  startIcon={<WIcon icon={WIconTypes.logout} color="error" />}
                >
                  {localize("navigationDialog.logout")}
                </WRedButton>
              </Grid>
            </Grid>
          )}
        </Grid>
        {isDesktop && (
          <Grid container direction="column" item sm="auto" marginTop="4rem">
            <AvatarItem
              size="medium"
              imageSrc={user.profile_picture}
              firstName={user.first_name}
              lastName={user.last_name}
            />
            <WButton onClick={() => showModal("PROFILE_IMAGE_OPTIONS_MODAL")}>
              {localize("form.changeImage")}
            </WButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
