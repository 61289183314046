import React, { useContext, useState, FC } from "react";
import { Grid, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { MyJobApplicationsTab, MySavedJobsTab, MyProfileTab } from "components";
import { ProfileHeader } from "components/headers/ProfileHeader";
import { useHistory } from "react-router-dom";
import { BreakpointsContext } from "context/BreakpointContext";

export const MY_PROFILE_TAB = 0;
export const MY_APPLICATIONS_TAB = 1;
export const MY_SAVED_TAB = 2;

const ProfileView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const [currentTab, setTab] = useState(MY_PROFILE_TAB);

  const history = useHistory();

  const RenderPageContent = (currentTab: number) => {
    switch (currentTab) {
      case MY_PROFILE_TAB:
        return <MyProfileTab />;

      case MY_APPLICATIONS_TAB:
        return <MyJobApplicationsTab />;

      case MY_SAVED_TAB:
        return (
          <Grid container paddingTop="1rem">
            {!isDesktop && (
              <Typography
                sx={{ marginBottom: "1rem", marginLeft: "1rem" }}
                color="primary"
                variant="h4"
                fontWeight="bold"
              >
                {localize("profileView.mySaved")}
              </Typography>
            )}
            <MySavedJobsTab />
          </Grid>
        );

      default:
        return <MyProfileTab />;
    }
  };

  return (
    <Grid container direction="column">
      <>
        <ProfileHeader
          handleAvatarPicker={() => {
            history.push("/settings");
          }}
          tabCallback={setTab}
          currentTab={currentTab}
        />
        <Grid>{RenderPageContent(currentTab)}</Grid>
      </>
    </Grid>
  );
};

export default ProfileView;
