import React, { FC, useCallback, useContext, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import { AgentNote, createAgentNote, deleteAgentNote, updateAgentNote } from "adapters";
import { WTypography } from "components/WTypography";
import { NoteCard } from "./NoteCard";
import { UserContext } from "context/UserContext";
import { User } from "reducers/UserReducer";
import { WButton } from "components/styledComponents/WButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { LocaleContext } from "context/LocaleContext";
import { defaultStyles } from "styles/colors";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";

interface Props {
  candidate: User;
  notes: AgentNote[];
  setNotes: (notes: AgentNote[]) => void;
  setLastElementRef: (node: Element | null) => void;
  count: number;
}
export const CandidateNotes: FC<Props> = ({
  candidate,
  notes,
  setNotes,
  setLastElementRef,
  count: count,
}) => {
  const { user } = useContext(UserContext);
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const [editNoteId, setEditNoteId] = useState("");
  const [editNoteData, setEditNoteData] = useState<AgentNote>({} as AgentNote);

  const isEditing = editNoteId !== "";

  const onFinishEdit = useCallback(
    async (note: AgentNote, index: number, doSave: boolean, data?: AgentNote) => {
      if (doSave) {
        data = data ?? editNoteData;
        const updatedNoteData: AgentNote = {} as AgentNote;
        Object.entries(data).forEach(([key, value]) => {
          updatedNoteData[key] = value || null;
        });
        if (editNoteId === "newNote") {
          const created = await createAgentNote({
            ...updatedNoteData,
            user: candidate.id,
            author: user.id,
          });
          const newNote: AgentNote = {
            ...note,
            ...data,
            user: candidate.id,
            author: {
              id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
              profile_picture: user.profile_picture,
            } as User,
            id: created.data.id,
          };
          notes[index] = newNote;
          addMessage({ content: "success.noteCreated", type: "success" });
          setTimeout(() => {
            const element = document.getElementById(newNote.id);
            element?.scrollIntoView({ block: "center", behavior: "smooth" });
          }, 500);
        } else {
          await updateAgentNote(note.id, updatedNoteData);
          notes[index] = { ...note, ...data };
        }
        setNotes([...notes]);
      } else if (editNoteId === "newNote") {
        setNotes([...notes.slice(1)]);
      }
      setEditNoteId("");
      setEditNoteData({} as AgentNote);
    },
    [notes, setNotes, editNoteData, editNoteId]
  );

  return (
    <>
      {!isEditing ? (
        <Grid container gap="5px" padding="12px" paddingBottom="0">
          <Grid item>
            <WButton
              variant="outlined"
              size="small"
              onClick={() => {
                const newNote: AgentNote = {
                  id: "newNote",
                  title: "",
                  note: "",
                  job: null,
                  user: candidate.id,
                  author: user,
                  created: new Date().toISOString(),
                  updated: new Date().toISOString(),
                } as AgentNote;
                setNotes([newNote, ...notes]);
                setEditNoteId("newNote");
                setEditNoteData({} as AgentNote);
              }}
            >
              <WIcon icon={WIconTypes.add} />
            </WButton>
          </Grid>
          <Grid item marginLeft="auto" display="flex" alignItems="center">
            <WTypography variant="body2">
              {localize("tasks.count")}: {count}
            </WTypography>
          </Grid>
        </Grid>
      ) : null}
      <Grid
        container
        direction="column"
        gap="10px"
        padding="12px"
        height="max-content"
        flexGrow={1}
      >
        {notes.length === 0 && isLoading("GET_AGENT_NOTES")
          ? Array.from(Array(4)).map((_, index) => (
              <Skeleton
                key={`note_skeleton_${index}`}
                variant="rectangular"
                width="100%"
                height="150px"
                sx={{ borderRadius: defaultStyles.borderRadius }}
              />
            ))
          : null}

        {notes.map((note, index) => {
          const content = (
            <NoteCard
              key={note.id}
              style={{ pointerEvents: isEditing && note.id !== editNoteId ? "none" : "auto" }}
              note={note.id === editNoteId ? { ...note, ...editNoteData } : note}
              isEditing={note.id === editNoteId}
              onStartEdit={() => {
                setEditNoteId(note.id);
                setEditNoteData({} as AgentNote);
              }}
              onEdit={(updatedData: Partial<AgentNote>) => {
                setEditNoteData({
                  ...editNoteData,
                  ...updatedData,
                  updated: new Date().toISOString(),
                });
              }}
              onFinishEdit={(doSave, data) => onFinishEdit(note, index, doSave, data)}
              onDelete={async () => {
                notes.splice(index, 1);
                await deleteAgentNote(note.id);
                addMessage({ type: "success", content: "success.noteDeleted" });
                setNotes([...notes]);
              }}
            />
          );

          if (index === notes.length - 1) {
            return (
              <div key={`div_${note.id}`} ref={setLastElementRef}>
                {content}
              </div>
            );
          }

          return content;
        })}
      </Grid>
    </>
  );
};
