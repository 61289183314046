import React from "react";
import { Grid } from "@mui/material";

const NotFoundView: React.FC = () => {
  return (
    <Grid>
      <h1> Page not found </h1>
    </Grid>
  );
};

export default NotFoundView;
