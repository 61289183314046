import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const SearchManyAssignmentsTwoSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 791 706"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_341_6273)">
          <path
            d="M170.964 217.275C168.843 217.275 166.808 218.118 165.307 219.619C163.807 221.119 162.964 223.154 162.964 225.275C162.964 227.397 163.807 229.432 165.307 230.932C166.808 232.433 168.843 233.275 170.964 233.275H271.524V229.785C271.524 227.305 272.14 224.864 273.316 222.68C274.492 220.497 276.193 218.639 278.264 217.275H170.964Z"
            fill="#E6E6E6"
          />
          <path
            d="M262.469 199.275H228.469C226.347 199.275 224.312 198.433 222.812 196.932C221.312 195.432 220.469 193.397 220.469 191.275C220.469 189.154 221.312 187.119 222.812 185.619C224.312 184.118 226.347 183.275 228.469 183.275H262.469C264.591 183.275 266.626 184.118 268.126 185.619C269.626 187.119 270.469 189.154 270.469 191.275C270.469 193.397 269.626 195.432 268.126 196.932C266.626 198.433 264.591 199.275 262.469 199.275Z"
            fill="#E6E6E6"
          />
          <path
            d="M186.214 142.275H54.2136C52.0919 142.275 50.0571 141.433 48.5568 139.932C47.0565 138.432 46.2136 136.397 46.2136 134.275C46.2136 132.154 47.0565 130.119 48.5568 128.619C50.0571 127.118 52.0919 126.275 54.2136 126.275H186.214C188.335 126.275 190.37 127.118 191.87 128.619C193.371 130.119 194.214 132.154 194.214 134.275C194.214 136.397 193.371 138.432 191.87 139.932C190.37 141.433 188.335 142.275 186.214 142.275Z"
            fill="#E6E6E6"
          />
          <path
            d="M128.714 108.275H111.714C109.592 108.275 107.557 107.433 106.057 105.932C104.556 104.432 103.714 102.397 103.714 100.275C103.714 98.1537 104.556 96.1188 106.057 94.6185C107.557 93.1182 109.592 92.2754 111.714 92.2754H128.714C130.835 92.2754 132.87 93.1182 134.37 94.6185C135.871 96.1188 136.714 98.1537 136.714 100.275C136.714 102.397 135.871 104.432 134.37 105.932C132.87 107.433 130.835 108.275 128.714 108.275Z"
            fill="#E6E6E6"
          />
          <path
            d="M270.52 378.17H146.082C141.575 378.165 137.254 376.372 134.067 373.185C130.88 369.998 129.087 365.677 129.082 361.17V164.33C129.087 159.823 130.88 155.502 134.067 152.315C137.254 149.128 141.575 147.335 146.082 147.33H344.856C349.363 147.335 353.684 149.128 356.871 152.315C360.058 155.502 361.851 159.823 361.856 164.33V213.118H359.856V164.33C359.851 160.353 358.27 156.541 355.458 153.728C352.646 150.916 348.833 149.335 344.856 149.33H146.082C142.105 149.335 138.293 150.916 135.481 153.728C132.669 156.541 131.087 160.353 131.082 164.33V361.17C131.087 365.147 132.669 368.959 135.481 371.772C138.293 374.584 142.105 376.165 146.082 376.17H270.52V378.17Z"
            fill="#CCCCCC"
          />
          <path
            d="M130.082 260.334H39.3728C34.8657 260.329 30.5446 258.536 27.3576 255.349C24.1706 252.162 22.3779 247.841 22.3728 243.334V83.2754C22.3779 78.7683 24.1706 74.4472 27.3576 71.2602C30.5446 68.0732 34.8657 66.2805 39.3728 66.2754H201.054C205.561 66.2805 209.882 68.0732 213.069 71.2602C216.256 74.4472 218.049 78.7683 218.054 83.2754V148.33H216.054V83.2754C216.05 79.2985 214.468 75.4858 211.656 72.6738C208.844 69.8617 205.031 68.2799 201.054 68.2754H39.3728C35.3959 68.2799 31.5833 69.8617 28.7712 72.6738C25.9591 75.4858 24.3773 79.2985 24.3728 83.2754V243.334C24.3773 247.311 25.9591 251.124 28.7712 253.936C31.5833 256.748 35.3959 258.329 39.3728 258.334H130.082V260.334Z"
            fill="#CCCCCC"
          />
          <path
            d="M534.964 217.275C537.036 218.639 538.736 220.497 539.912 222.68C541.089 224.864 541.704 227.305 541.704 229.785V233.275H642.264C644.386 233.275 646.421 232.433 647.921 230.932C649.421 229.432 650.264 227.397 650.264 225.275C650.264 223.154 649.421 221.119 647.921 219.619C646.421 218.118 644.386 217.275 642.264 217.275H534.964Z"
            fill="#E6E6E6"
          />
          <path
            d="M542.759 191.275C542.762 189.154 543.605 187.121 545.105 185.621C546.605 184.121 548.638 183.278 550.759 183.275H584.759C586.881 183.275 588.916 184.118 590.416 185.619C591.916 187.119 592.759 189.154 592.759 191.275C592.759 193.397 591.916 195.432 590.416 196.932C588.916 198.433 586.881 199.275 584.759 199.275H550.759C548.638 199.273 546.605 198.429 545.105 196.93C543.605 195.43 542.762 193.396 542.759 191.275Z"
            fill="#E6E6E6"
          />
          <path
            d="M619.015 134.275C619.017 132.154 619.861 130.121 621.36 128.621C622.86 127.121 624.894 126.278 627.015 126.275H759.015C761.136 126.275 763.171 127.118 764.672 128.619C766.172 130.119 767.015 132.154 767.015 134.275C767.015 136.397 766.172 138.432 764.672 139.932C763.171 141.433 761.136 142.275 759.015 142.275H627.015C624.894 142.273 622.86 141.429 621.36 139.93C619.861 138.43 619.017 136.396 619.015 134.275Z"
            fill="#E6E6E6"
          />
          <path
            d="M676.515 100.275C676.517 98.1544 677.361 96.121 678.86 94.6212C680.36 93.1215 682.394 92.2778 684.515 92.2754H701.515C703.636 92.2754 705.671 93.1182 707.172 94.6185C708.672 96.1188 709.515 98.1537 709.515 100.275C709.515 102.397 708.672 104.432 707.172 105.932C705.671 107.433 703.636 108.275 701.515 108.275H684.515C682.394 108.273 680.36 107.429 678.86 105.93C677.361 104.43 676.517 102.396 676.515 100.275Z"
            fill="#E6E6E6"
          />
          <path
            d="M542.708 378.17H667.146C671.653 378.165 675.974 376.372 679.161 373.185C682.349 369.998 684.141 365.677 684.146 361.17V164.33C684.141 159.823 682.349 155.502 679.161 152.315C675.974 149.128 671.653 147.335 667.146 147.33H468.373C463.865 147.335 459.544 149.128 456.357 152.315C453.17 155.502 451.378 159.823 451.373 164.33V213.118H453.373V164.33C453.377 160.353 454.959 156.541 457.771 153.728C460.583 150.916 464.396 149.335 468.373 149.33H667.146C671.123 149.335 674.936 150.916 677.748 153.728C680.56 156.541 682.142 160.353 682.146 164.33V361.17C682.142 365.147 680.56 368.959 677.748 371.772C674.936 374.584 671.123 376.165 667.146 376.17H542.708V378.17Z"
            fill="#CCCCCC"
          />
          <path
            d="M683.146 260.334H773.855C778.363 260.329 782.684 258.536 785.871 255.349C789.058 252.162 790.85 247.841 790.855 243.334V83.2754C790.85 78.7683 789.058 74.4472 785.871 71.2602C782.684 68.0732 778.363 66.2805 773.855 66.2754H612.174C607.667 66.2805 603.346 68.0732 600.159 71.2602C596.972 74.4472 595.179 78.7683 595.174 83.2754V148.33H597.174V83.2754C597.179 79.2985 598.76 75.4858 601.572 72.6738C604.385 69.8617 608.197 68.2799 612.174 68.2754H773.855C777.832 68.2799 781.645 69.8617 784.457 72.6738C787.269 75.4858 788.851 79.2985 788.855 83.2754V243.334C788.851 247.311 787.269 251.124 784.457 253.936C781.645 256.748 777.832 258.329 773.855 258.334H683.146V260.334Z"
            fill="#CCCCCC"
          />
          <path
            d="M526.284 212.785H286.524C282.018 212.794 277.7 214.588 274.513 217.774C271.327 220.96 269.533 225.279 269.524 229.785V467.275C269.529 471.782 271.322 476.104 274.509 479.291C277.696 482.478 282.017 484.27 286.524 484.275H526.284C530.791 484.27 535.113 482.478 538.3 479.291C541.487 476.104 543.279 471.782 543.284 467.275V229.785C543.279 225.278 541.487 220.957 538.3 217.77C535.113 214.582 530.791 212.79 526.284 212.785V212.785ZM541.284 467.275C541.279 471.252 539.697 475.064 536.885 477.876C534.073 480.688 530.261 482.27 526.284 482.275H286.524C282.548 482.27 278.735 480.688 275.923 477.876C273.111 475.064 271.529 471.252 271.524 467.275V229.785C271.531 225.809 273.113 221.997 275.925 219.185C278.736 216.374 282.548 214.791 286.524 214.785H526.284C530.261 214.79 534.073 216.372 536.885 219.184C539.697 221.996 541.279 225.808 541.284 229.785V467.275Z"
            fill="#3F3D56"
          />
          <path
            d="M507.403 305.275H305.403C303.282 305.275 301.247 304.433 299.746 302.932C298.246 301.432 297.403 299.397 297.403 297.275C297.403 295.154 298.246 293.119 299.746 291.619C301.247 290.118 303.282 289.275 305.403 289.275H507.403C509.525 289.275 511.56 290.118 513.06 291.619C514.56 293.119 515.403 295.154 515.403 297.275C515.403 299.397 514.56 301.432 513.06 302.932C511.56 304.433 509.525 305.275 507.403 305.275Z"
            fill="#CCCCCC"
          />
          <path
            d="M449.903 271.275H362.903C360.782 271.275 358.747 270.433 357.246 268.932C355.746 267.432 354.903 265.397 354.903 263.275C354.903 261.154 355.746 259.119 357.246 257.619C358.747 256.118 360.782 255.275 362.903 255.275H449.903C452.025 255.275 454.06 256.118 455.56 257.619C457.06 259.119 457.903 261.154 457.903 263.275C457.903 265.397 457.06 267.432 455.56 268.932C454.06 270.433 452.025 271.275 449.903 271.275Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M391.903 353.275H304.903C302.782 353.275 300.747 352.433 299.246 350.932C297.746 349.432 296.903 347.397 296.903 345.275C296.903 343.154 297.746 341.119 299.246 339.619C300.747 338.118 302.782 337.275 304.903 337.275H391.903C394.025 337.275 396.06 338.118 397.56 339.619C399.06 341.119 399.903 343.154 399.903 345.275C399.903 347.397 399.06 349.432 397.56 350.932C396.06 352.433 394.025 353.275 391.903 353.275Z"
            fill="#CCCCCC"
          />
          <path
            d="M391.903 388.275H304.903C302.782 388.275 300.747 387.433 299.246 385.932C297.746 384.432 296.903 382.397 296.903 380.275C296.903 378.154 297.746 376.119 299.246 374.619C300.747 373.118 302.782 372.275 304.903 372.275H391.903C394.025 372.275 396.06 373.118 397.56 374.619C399.06 376.119 399.903 378.154 399.903 380.275C399.903 382.397 399.06 384.432 397.56 385.932C396.06 387.433 394.025 388.275 391.903 388.275Z"
            fill="#CCCCCC"
          />
          <path
            d="M391.903 423.275H304.903C302.782 423.275 300.747 422.433 299.246 420.932C297.746 419.432 296.903 417.397 296.903 415.275C296.903 413.154 297.746 411.119 299.246 409.619C300.747 408.118 302.782 407.275 304.903 407.275H391.903C394.025 407.275 396.06 408.118 397.56 409.619C399.06 411.119 399.903 413.154 399.903 415.275C399.903 417.397 399.06 419.432 397.56 420.932C396.06 422.433 394.025 423.275 391.903 423.275Z"
            fill="#CCCCCC"
          />
          <path
            d="M500.409 360.134C508.654 360.134 515.338 353.45 515.338 345.205C515.338 336.959 508.654 330.275 500.409 330.275C492.164 330.275 485.48 336.959 485.48 345.205C485.48 353.45 492.164 360.134 500.409 360.134Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M498.93 350.9C498.594 350.9 498.267 350.792 497.999 350.59L497.982 350.578L494.473 347.894C494.145 347.642 493.93 347.271 493.876 346.861C493.822 346.451 493.933 346.036 494.185 345.708C494.436 345.38 494.808 345.165 495.218 345.111C495.628 345.056 496.042 345.167 496.371 345.419L498.643 347.162L504.013 340.156C504.138 339.993 504.293 339.857 504.47 339.755C504.648 339.652 504.843 339.586 505.046 339.559C505.249 339.532 505.455 339.545 505.653 339.598C505.851 339.651 506.036 339.743 506.198 339.867L506.199 339.867L506.166 339.914L506.2 339.867C506.527 340.119 506.742 340.491 506.796 340.901C506.85 341.31 506.739 341.725 506.488 342.053L500.172 350.29C500.025 350.48 499.838 350.633 499.622 350.739C499.407 350.844 499.171 350.899 498.931 350.898L498.93 350.9Z"
            fill="white"
          />
          <path
            d="M120.214 46.4092C115.624 46.4092 111.138 45.0482 107.322 42.4985C103.506 39.9487 100.532 36.3246 98.7754 32.0845C97.0191 27.8444 96.5596 23.1788 97.4549 18.6775C98.3503 14.1763 100.56 10.0416 103.806 6.79644C107.051 3.55123 111.185 1.34122 115.687 0.445873C120.188 -0.449471 124.854 0.0100571 129.094 1.76636C133.334 3.52267 136.958 6.49686 139.508 10.3128C142.057 14.1288 143.418 18.6152 143.418 23.2046C143.411 29.3567 140.964 35.2548 136.614 39.605C132.264 43.9552 126.366 46.4022 120.214 46.4092V46.4092ZM120.214 2.00001C116.02 2.00003 111.92 3.24368 108.433 5.57369C104.946 7.90369 102.228 11.2154 100.623 15.09C99.0182 18.9647 98.5983 23.2282 99.4165 27.3415C100.235 31.4548 102.254 35.233 105.22 38.1985C108.185 41.164 111.964 43.1836 116.077 44.0018C120.19 44.8199 124.454 44.4 128.328 42.7951C132.203 41.1901 135.515 38.4723 137.845 34.9852C140.175 31.4982 141.418 27.3985 141.418 23.2046C141.412 17.5827 139.176 12.1929 135.201 8.21767C131.225 4.24241 125.835 2.00634 120.214 2.00001V2.00001Z"
            fill="#CCCCCC"
          />
          <path
            d="M118.013 31.6749C117.514 31.6757 117.027 31.5141 116.628 31.2144L116.603 31.1958L111.384 27.2039C111.143 27.0187 110.94 26.7876 110.788 26.5239C110.635 26.2602 110.536 25.9691 110.496 25.6672C110.456 25.3653 110.476 25.0585 110.555 24.7643C110.634 24.4701 110.77 24.1943 110.955 23.9526C111.14 23.7109 111.371 23.5081 111.635 23.3557C111.899 23.2033 112.19 23.1044 112.492 23.0645C112.794 23.0246 113.1 23.0446 113.395 23.1233C113.689 23.202 113.965 23.3378 114.206 23.5231L117.586 26.1151L125.574 15.6948C125.759 15.4532 125.99 15.2505 126.253 15.0982C126.517 14.9459 126.808 14.8469 127.11 14.8071C127.412 14.7672 127.718 14.7872 128.012 14.8658C128.306 14.9445 128.582 15.0803 128.824 15.2656L128.824 15.2661L128.775 15.3349L128.826 15.2661C129.313 15.6406 129.632 16.1933 129.712 16.8027C129.793 17.4121 129.628 18.0286 129.254 18.5167L119.86 30.7678C119.642 31.05 119.363 31.2785 119.043 31.4353C118.723 31.5921 118.371 31.6732 118.015 31.6721L118.013 31.6749Z"
            fill="#CCCCCC"
          />
          <path
            d="M267.409 127.409C262.82 127.409 258.334 126.048 254.518 123.498C250.702 120.949 247.728 117.325 245.971 113.084C244.215 108.844 243.756 104.179 244.651 99.6775C245.546 95.1762 247.756 91.0416 251.002 87.7964C254.247 84.5512 258.382 82.3412 262.883 81.4459C267.384 80.5505 272.05 81.0101 276.29 82.7664C280.53 84.5227 284.154 87.4969 286.704 91.3129C289.253 95.1288 290.614 99.6152 290.614 104.205C290.607 110.357 288.16 116.255 283.81 120.605C279.46 124.955 273.562 127.402 267.409 127.409V127.409ZM267.409 83C263.216 83.0001 259.116 84.2437 255.629 86.5737C252.142 88.9038 249.424 92.2155 247.819 96.0901C246.214 99.9648 245.794 104.228 246.613 108.342C247.431 112.455 249.45 116.233 252.416 119.199C255.381 122.164 259.16 124.184 263.273 125.002C267.386 125.82 271.65 125.4 275.524 123.795C279.399 122.19 282.711 119.472 285.041 115.985C287.371 112.498 288.614 108.398 288.614 104.205C288.608 98.5827 286.372 93.1929 282.396 89.2176C278.421 85.2424 273.031 83.0063 267.409 83V83Z"
            fill="#CCCCCC"
          />
          <path
            d="M265.209 112.675C264.71 112.676 264.223 112.514 263.824 112.214L263.799 112.196L258.58 108.204C258.092 107.83 257.773 107.277 257.692 106.667C257.612 106.057 257.777 105.441 258.151 104.953C258.525 104.464 259.078 104.145 259.688 104.064C260.297 103.984 260.914 104.149 261.402 104.523L264.782 107.115L272.77 96.6948C272.955 96.4532 273.186 96.2505 273.449 96.0982C273.713 95.9459 274.004 95.8469 274.306 95.8071C274.608 95.7672 274.914 95.7872 275.208 95.8658C275.502 95.9445 275.778 96.0803 276.02 96.2656L276.02 96.2661L275.971 96.3349L276.022 96.2661C276.509 96.6407 276.828 97.1933 276.908 97.8027C276.989 98.4121 276.824 99.0286 276.45 99.5167L267.056 111.768C266.838 112.05 266.559 112.278 266.239 112.435C265.919 112.592 265.567 112.673 265.211 112.672L265.209 112.675Z"
            fill="#CCCCCC"
          />
          <path
            d="M500.409 395.134C508.654 395.134 515.338 388.45 515.338 380.205C515.338 371.959 508.654 365.275 500.409 365.275C492.164 365.275 485.48 371.959 485.48 380.205C485.48 388.45 492.164 395.134 500.409 395.134Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M498.93 385.9C498.594 385.9 498.267 385.792 497.999 385.59L497.982 385.578L494.473 382.894C494.311 382.769 494.174 382.614 494.072 382.437C493.97 382.259 493.903 382.064 493.876 381.861C493.849 381.658 493.863 381.451 493.916 381.253C493.969 381.056 494.06 380.87 494.185 380.708C494.309 380.545 494.465 380.409 494.642 380.306C494.819 380.204 495.015 380.137 495.218 380.111C495.421 380.084 495.627 380.097 495.825 380.15C496.023 380.203 496.208 380.294 496.371 380.419L498.643 382.162L504.013 375.156C504.138 374.993 504.293 374.857 504.47 374.755C504.648 374.652 504.843 374.586 505.046 374.559C505.249 374.532 505.455 374.545 505.653 374.598C505.851 374.651 506.036 374.743 506.198 374.867L506.199 374.867L506.166 374.914L506.2 374.867C506.527 375.119 506.742 375.491 506.796 375.901C506.85 376.31 506.739 376.725 506.488 377.053L500.172 385.29C500.025 385.48 499.838 385.633 499.622 385.739C499.407 385.844 499.171 385.899 498.931 385.898L498.93 385.9Z"
            fill="white"
          />
          <path
            d="M500.409 430.204C508.654 430.204 515.338 423.52 515.338 415.275C515.338 407.03 508.654 400.346 500.409 400.346C492.164 400.346 485.48 407.03 485.48 415.275C485.48 423.52 492.164 430.204 500.409 430.204Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M498.93 420.971C498.594 420.971 498.267 420.862 497.999 420.661L497.982 420.648L494.473 417.965C494.146 417.713 493.931 417.341 493.878 416.932C493.824 416.522 493.935 416.108 494.186 415.78C494.437 415.452 494.809 415.237 495.218 415.183C495.628 415.128 496.042 415.239 496.371 415.49L498.643 417.233L504.013 410.226C504.138 410.064 504.293 409.928 504.47 409.825C504.648 409.723 504.843 409.656 505.046 409.63C505.249 409.603 505.455 409.616 505.653 409.669C505.851 409.722 506.036 409.813 506.199 409.938L506.199 409.938L506.166 409.984L506.2 409.938C506.528 410.19 506.742 410.562 506.796 410.971C506.85 411.381 506.739 411.796 506.488 412.124L500.172 420.361C500.026 420.55 499.838 420.704 499.623 420.809C499.408 420.915 499.171 420.969 498.932 420.969L498.93 420.971Z"
            fill="white"
          />
          <path
            d="M406.426 192.409C390.676 192.141 383.519 180.191 383.52 169.203C383.521 158.214 390.679 146.266 406.393 146H406.427C422.142 146.268 429.3 158.217 429.299 169.206C429.298 180.195 422.14 192.143 406.426 192.409ZM406.41 148C392.058 148.251 385.521 159.165 385.52 169.203C385.519 179.245 392.061 190.164 406.426 190.409C420.755 190.166 427.298 179.248 427.299 169.206C427.299 159.168 420.762 148.254 406.41 148V148Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M411.617 170.465C415.632 177.438 404.613 180.162 400.397 175.961L401.453 173.705C408.307 179.625 414.346 169.37 403.877 170.345V167.873C413.464 168.646 408.079 158.996 401.693 164.705L400.661 162.449C405.866 157.047 417.55 163.579 409.301 168.977C410.222 169.192 411.039 169.717 411.617 170.465V170.465Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M547.409 127.409C542.82 127.409 538.334 126.048 534.518 123.498C530.702 120.949 527.728 117.325 525.971 113.084C524.215 108.844 523.756 104.179 524.651 99.6775C525.546 95.1762 527.756 91.0416 531.002 87.7964C534.247 84.5512 538.382 82.3412 542.883 81.4459C547.384 80.5505 552.05 81.0101 556.29 82.7664C560.53 84.5227 564.154 87.4969 566.704 91.3129C569.253 95.1288 570.614 99.6152 570.614 104.205C570.607 110.357 568.16 116.255 563.81 120.605C559.46 124.955 553.562 127.402 547.409 127.409V127.409ZM547.409 83C543.216 83.0001 539.116 84.2437 535.629 86.5737C532.142 88.9038 529.424 92.2155 527.819 96.0901C526.214 99.9648 525.794 104.228 526.613 108.342C527.431 112.455 529.45 116.233 532.416 119.199C535.381 122.164 539.16 124.184 543.273 125.002C547.386 125.82 551.65 125.4 555.524 123.795C559.399 122.19 562.711 119.472 565.041 115.985C567.371 112.498 568.614 108.398 568.614 104.205C568.608 98.5827 566.372 93.1929 562.396 89.2176C558.421 85.2424 553.031 83.0063 547.409 83V83Z"
            fill="#CCCCCC"
          />
          <path
            d="M553.853 109.413H551.429V112.749H548.405V109.413H540.965V107.108L548.957 95.6606H551.429V106.989H553.853V109.413ZM548.405 106.989V100.484L543.893 106.989H548.405Z"
            fill="#CCCCCC"
          />
          <path
            d="M693.015 46.4092C688.425 46.4091 683.939 45.0482 680.123 42.4984C676.307 39.9486 673.333 36.3245 671.577 32.0844C669.821 27.8444 669.361 23.1787 670.256 18.6775C671.152 14.1762 673.362 10.0416 676.607 6.79639C679.852 3.5512 683.987 1.3412 688.488 0.445861C692.99 -0.449473 697.655 0.010072 701.895 1.76638C706.135 3.52269 709.759 6.49688 712.309 10.3129C714.859 14.1288 716.22 18.6152 716.22 23.2046C716.213 29.3567 713.766 35.2549 709.415 39.6051C705.065 43.9553 699.167 46.4022 693.015 46.4092ZM693.015 2.00001C688.821 2.00006 684.721 3.24373 681.234 5.57375C677.747 7.90377 675.029 11.2155 673.425 15.0901C671.82 18.9648 671.4 23.2283 672.218 27.3416C673.036 31.4548 675.056 35.2331 678.021 38.1986C680.987 41.1641 684.765 43.1836 688.878 44.0018C692.992 44.8199 697.255 44.4 701.13 42.7951C705.004 41.1901 708.316 38.4723 710.646 34.9852C712.976 31.4981 714.22 27.3985 714.22 23.2046C714.213 17.5827 711.977 12.1929 708.002 8.21764C704.027 4.24238 698.637 2.00631 693.015 2.00001Z"
            fill="#CCCCCC"
          />
          <path
            d="M696.471 21.4165C697.274 21.8604 697.933 22.5271 698.367 23.3364C698.827 24.2124 699.058 25.1908 699.039 26.1802C699.06 27.2201 698.794 28.2457 698.271 29.1445C697.747 30.0114 696.979 30.7042 696.062 31.1362C695.02 31.6263 693.878 31.8686 692.727 31.8442C691.648 31.8433 690.577 31.6607 689.559 31.3042C688.613 30.9987 687.74 30.5048 686.991 29.8526L688.023 27.5962C689.363 28.7033 691.037 29.3288 692.775 29.3726C693.668 29.4338 694.55 29.1454 695.235 28.5684C695.788 27.9364 696.091 27.1238 696.087 26.2838C696.082 25.4438 695.771 24.6344 695.211 24.0083C694.897 23.7178 694.528 23.4928 694.127 23.3465C693.725 23.2002 693.298 23.1355 692.871 23.1563C692.22 23.1521 691.575 23.2786 690.975 23.5284C690.382 23.7764 689.85 24.1487 689.415 24.6202H687.543V14.5645H698.247V16.9644H690.567V21.6201C691.475 21.0153 692.548 20.7054 693.639 20.7324C694.626 20.7117 695.602 20.9474 696.471 21.4165V21.4165Z"
            fill="#CCCCCC"
          />
          <path
            d="M148.194 520.85C147.587 519.511 147.281 518.055 147.298 516.585C147.315 515.115 147.653 513.667 148.29 512.342C148.927 511.017 149.846 509.848 150.984 508.917C152.122 507.985 153.45 507.315 154.874 506.953L157.906 471.347L172.485 482.848L167.371 514.897C167.821 517.331 167.363 519.845 166.083 521.963C164.803 524.082 162.791 525.657 160.427 526.391C158.063 527.125 155.512 526.966 153.258 525.945C151.003 524.924 149.202 523.111 148.194 520.85H148.194Z"
            fill="#FFB8B8"
          />
          <path
            d="M157.571 503.216C156.522 503.077 155.555 502.573 154.841 501.792C154.127 501.011 153.711 500.003 153.667 498.945L151.979 458.539C151.373 444.015 154.328 429.564 160.589 416.446L175.557 385.081C176.56 383.195 177.998 381.575 179.752 380.356C181.506 379.137 183.526 378.354 185.644 378.072C187.699 377.782 189.794 377.994 191.751 378.688C193.707 379.383 195.466 380.54 196.879 382.061C197.595 382.827 198.239 383.657 198.802 384.543C199.931 386.374 200.585 388.459 200.704 390.608C200.823 392.757 200.404 394.901 199.484 396.847L176.533 459.546L174.767 500.394C174.74 501.013 174.586 501.62 174.313 502.176C174.041 502.733 173.657 503.227 173.185 503.628C172.713 504.03 172.163 504.329 171.57 504.508C170.977 504.687 170.353 504.741 169.738 504.668L157.63 503.224L157.571 503.216Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M114.63 679.762L125.856 684.69L150.204 643.734L133.636 636.462L114.63 679.762Z"
            fill="#FFB8B8"
          />
          <path
            d="M113.375 674.84L135.483 684.544L135.484 684.544C137.334 685.356 139.006 686.525 140.405 687.983C141.803 689.442 142.901 691.161 143.635 693.044C144.369 694.926 144.725 696.935 144.682 698.955C144.64 700.975 144.2 702.967 143.388 704.818L143.187 705.275L106.99 689.387L113.375 674.84Z"
            fill="#2F2E41"
          />
          <path
            d="M265.342 693.391H277.602L283.434 646.103L265.34 646.104L265.342 693.391Z"
            fill="#FFB8B8"
          />
          <path
            d="M262.215 689.389L286.359 689.388H286.36C288.38 689.388 290.381 689.786 292.248 690.559C294.115 691.332 295.811 692.465 297.24 693.894C298.668 695.323 299.802 697.019 300.575 698.886C301.348 700.753 301.746 702.753 301.746 704.774V705.274L262.216 705.275L262.215 689.389Z"
            fill="#2F2E41"
          />
          <path
            d="M141.86 679.947C141.388 679.947 140.92 679.873 140.471 679.728L119.684 673.046C118.561 672.68 117.626 671.889 117.079 670.843C116.532 669.796 116.417 668.577 116.757 667.446L185.692 447.931L237.327 437.806L237.509 438.102C271.646 493.649 283.041 578.503 290.269 668.409C290.358 669.583 289.985 670.745 289.231 671.649C288.477 672.553 287.4 673.128 286.229 673.251L263.503 675.523C262.443 675.635 261.377 675.364 260.499 674.759C259.621 674.154 258.987 673.256 258.713 672.226L217.101 525.019C217.019 524.718 216.846 524.451 216.606 524.253C216.366 524.056 216.07 523.938 215.759 523.916C215.449 523.894 215.14 523.969 214.874 524.131C214.608 524.292 214.399 524.533 214.276 524.818L145.981 677.226C145.636 678.033 145.061 678.721 144.329 679.205C143.596 679.689 142.738 679.947 141.86 679.947V679.947Z"
            fill="#2F2E41"
          />
          <path
            d="M212.37 360.03C225.935 360.03 236.931 349.034 236.931 335.469C236.931 321.905 225.935 310.908 212.37 310.908C198.806 310.908 187.809 321.905 187.809 335.469C187.809 349.034 198.806 360.03 212.37 360.03Z"
            fill="#FFB8B8"
          />
          <path
            d="M181.3 454.431L181.132 454.079C181.017 453.84 169.701 429.834 170.754 402.145C171.103 393.056 174.912 384.444 181.401 378.071C187.89 371.698 196.569 368.046 205.663 367.861V367.861C214.043 367.697 222.213 370.496 228.733 375.764C235.253 381.032 239.704 388.432 241.304 396.66C244.458 412.759 244.379 428.007 241.069 441.979L240.996 442.289L240.682 442.354L181.3 454.431Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M306.524 489.079C305.07 488.864 303.68 488.333 302.453 487.523C301.227 486.713 300.192 485.644 299.423 484.391C298.655 483.138 298.17 481.732 298.003 480.271C297.837 478.81 297.992 477.331 298.459 475.937L270.17 454.104L287.734 448.078L311.906 469.734C314.195 470.674 316.061 472.421 317.149 474.645C318.236 476.868 318.471 479.413 317.807 481.797C317.144 484.182 315.629 486.24 313.549 487.582C311.47 488.924 308.97 489.457 306.524 489.079H306.524Z"
            fill="#FFB8B8"
          />
          <path
            d="M237.101 440.947L237.035 440.842L207.212 392.543C206.169 390.679 205.586 388.593 205.512 386.458C205.437 384.323 205.873 382.201 206.783 380.269C207.652 378.383 208.965 376.737 210.608 375.468C212.252 374.2 214.178 373.348 216.221 372.984V372.984C217.253 372.796 218.3 372.705 219.348 372.712C221.5 372.754 223.607 373.334 225.477 374.397C227.348 375.461 228.924 376.975 230.061 378.802L270.338 432.055L303.719 455.662C304.225 456.02 304.652 456.478 304.972 457.008C305.293 457.538 305.501 458.129 305.582 458.743C305.664 459.357 305.618 459.982 305.447 460.577C305.276 461.173 304.984 461.727 304.589 462.204L296.818 471.6C296.138 472.427 295.186 472.984 294.132 473.169C293.079 473.355 291.993 473.158 291.072 472.614L237.101 440.947Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M207.978 333.945C209.788 330.968 213.783 330.126 217.251 330.466C220.72 330.806 224.092 332.025 227.577 331.998C237.253 331.923 244.722 320.129 240.654 311.35C237.318 304.149 228.985 300.988 221.447 298.506C205.643 293.304 187.489 288.304 173.148 296.74C168.578 299.508 164.829 303.446 162.29 308.147C159.75 312.848 158.512 318.142 158.703 323.481C159.025 330.121 161.568 336.94 159.418 343.23C156.937 350.486 149.071 354.379 141.638 356.262C128.607 359.563 114.868 359.031 101.756 361.992C88.6438 364.953 75.2074 372.815 71.6729 385.784C69.3513 394.303 71.7738 403.474 75.7856 411.339C79.8786 419.364 85.5668 426.469 92.5016 432.218C99.4365 437.968 107.472 442.241 116.116 444.777C124.76 447.312 133.831 448.056 142.773 446.963C151.715 445.871 160.339 442.964 168.118 438.422C183.97 429.166 195.414 413.522 202.237 396.481C206.106 386.541 208.829 376.192 210.355 365.635C211.883 355.752 210.586 345.766 207.819 336.156C207.688 335.797 207.634 335.415 207.662 335.034C207.689 334.653 207.797 334.282 207.978 333.945V333.945Z"
            fill="#2F2E41"
          />
          <path
            d="M382 705.638H1C0.734784 705.638 0.480429 705.532 0.292892 705.345C0.105356 705.157 0 704.903 0 704.638C0 704.372 0.105356 704.118 0.292892 703.931C0.480429 703.743 0.734784 703.638 1 703.638H382C382.265 703.638 382.52 703.743 382.707 703.931C382.895 704.118 383 704.372 383 704.638C383 704.903 382.895 705.157 382.707 705.345C382.52 705.532 382.265 705.638 382 705.638Z"
            fill="#3F3D56"
          />
        </g>
        <defs>
          <clipPath id="clip0_341_6273">
            <rect width="790.856" height="705.638" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
