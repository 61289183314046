import React, { FC, useContext } from "react";
import { Box, Grid } from "@mui/material";
import { WTypography, WButton } from "components";

import { colors } from "styles/colors";
import { BreakpointsContext } from "context/BreakpointContext";
import { BubbleBottomSVG, WaveSVG } from "components/assets/SVGComponents";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { DESKTOP_ACTION_BAR_SIZE } from "components/layout/ActionNavbar/DefaultActionNavbar";

export type SuperHeaderContent = {
  kicker?: string;
  title?: string;
  sub_title?: string;
  page_photo?: string;
  call_to_action?: string;
};

interface SuperHeaderProps {
  headerContent?: SuperHeaderContent;
}

export const SuperHeader: FC<SuperHeaderProps> = ({ headerContent }) => {
  const { isDesktop } = useContext(BreakpointsContext);

  const onActionClick = () => {
    const container = document.getElementById("CardContentContainer");
    if (!container) {
      return;
    }
    smoothScrollIntoView(container, {
      scrollMode: "if-needed",
      block: "center",
      skipOverflowHiddenElements: false,
    });
  };

  if (isDesktop)
    return <SuperHeaderDesktop headerContent={headerContent} onActionClick={onActionClick} />;

  return <SuperHeaderMobile headerContent={headerContent} onActionClick={onActionClick} />;
};

interface SuperHeaderResponsive {
  onActionClick: () => void;
}

export const SuperHeaderDesktop: FC<SuperHeaderProps & SuperHeaderResponsive> = ({
  headerContent,
  onActionClick,
}) => {
  return (
    <>
      <Grid container position="relative" maxHeight="600px" height="60vh" id="SuperHeader">
        {headerContent?.page_photo && (
          <img
            loading="eager"
            src={headerContent?.page_photo}
            alt="Header image"
            width="60%"
            height="100%"
            style={{
              position: "absolute",
              top: "0",
              left: "40%",
              right: 0,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        )}
        <Grid container position="absolute" bottom="0" width="100%" overflow="hidden" height="100%">
          <WaveSVG />
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            maxWidth="45%"
            padding="1rem 1rem 1rem 3rem"
            zIndex="2"
            color={colors.white}
          >
            <Grid container margin="auto">
              <WTypography lineClamps={4} variant="body1" fontWeight="bold">
                {headerContent?.kicker}
              </WTypography>
              <Grid container direction="column" gap="1rem">
                <WTypography lineClamps={4} variant="h3" fontWeight="bold" fontSize="2.9rem">
                  {headerContent?.title}
                </WTypography>
                <WTypography lineClamps={4} variant="h5" fontWeight="300" fontSize="1.4rem">
                  {headerContent?.sub_title}
                </WTypography>
              </Grid>
              {headerContent?.call_to_action && (
                <WButton
                  onClick={onActionClick}
                  color="primary"
                  variant="outlined"
                  sx={{ marginTop: "2rem", boxShadow: "none" }}
                >
                  {headerContent?.call_to_action}
                </WButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const SuperHeaderMobile: FC<SuperHeaderProps & SuperHeaderResponsive> = ({
  headerContent,
  onActionClick,
}) => {
  return (
    <>
      <Grid
        container
        position="relative"
        maxHeight="max-content"
        minHeight="500px"
        id="SuperHeader"
      >
        {headerContent?.page_photo && (
          <img
            src={headerContent?.page_photo}
            alt="Header image"
            width="100%"
            height="500px"
            style={{
              position: "absolute",
              top: `-${DESKTOP_ACTION_BAR_SIZE}`,
              left: 0,
              right: 0,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        )}
        <Grid
          container
          position="absolute"
          bottom="0"
          width="100%"
          overflow="hidden"
          height="max-content"
        >
          <WaveSVG />
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            maxWidth="100%"
            padding="4rem 1rem 2rem 1rem"
            zIndex="2"
            color={colors.white}
          >
            <Grid container>
              <WTypography lineClamps={4} variant="body1" fontWeight="bold">
                {headerContent?.kicker}
              </WTypography>
              <Grid container direction="column" gap="1rem">
                <WTypography lineClamps={4} variant="h5" fontWeight="bold" fontSize="1.5rem">
                  {headerContent?.title}
                </WTypography>
                <WTypography lineClamps={4} variant="body1" fontWeight="300">
                  {headerContent?.sub_title}
                </WTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {headerContent?.call_to_action && (
        <Grid container width="100%" justifyContent="center" position="relative">
          <Grid
            container
            position="relative"
            justifyContent="center"
            sx={{
              "&::before": {
                content: `"${headerContent?.call_to_action}"`,
                position: "absolute",
                top: "-1.5rem",
                color: colors.white,
                fontSize: "13px",
              },
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
              }}
              width="max-content"
              onClick={onActionClick}
            >
              <BubbleBottomSVG />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};
