import { Dialog } from "@mui/material";
import React, { FC, useContext } from "react";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";

import { DialogHeader } from "components";
import { SkillsList } from "components/ProfileView/Skills/SkillsList";

export const ProfileSkillsModal: FC = () => {
  const { hideModal } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);

  return (
    <>
      <Dialog open={true} fullScreen sx={{ height: "100%" }}>
        <DialogHeader
          handleClose={() => hideModal()}
          title={localize("profileSections.profileTitleSkill")}
          returnText={localize("profileView.headerMessage")}
        />
        <SkillsList />
      </Dialog>
    </>
  );
};
