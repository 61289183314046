import { FC } from "react";
import LoaderProvider from "./LoaderContext";
import UserProvider from "./UserContext";
import LocaleProvider from "./LocaleContext";
import NotificationProvider from "./NotificationContext";
import { combineProviders } from "utils/CombineProviders";
import ModalProvider from "./ModalContext";
import BreakpointsProvider from "./BreakpointContext";
import PushNotificationProvider from "./PushNotificationContext";
import NewsProvider from "./NewsContext";
import GTMProvider from "./GTMContext";
import StatisticsFilterProvider from "./StatisticsFilterContext";
import KanbanJobsProvider from "./KanbanJobsContext";

const providers: FC[] = [
  LoaderProvider,
  NotificationProvider,
  GTMProvider,
  UserProvider,
  LocaleProvider,
  PushNotificationProvider,
  BreakpointsProvider,
  ModalProvider,
  NewsProvider,
  StatisticsFilterProvider,
  KanbanJobsProvider,
];
export const GlobalProvider = combineProviders(...providers);
