import React, { useContext, FC, useState, useEffect } from "react";
import { Grid, Card, Typography, Skeleton } from "@mui/material";
import { deleteJobApplication, JobApplication } from "adapters";
import { ApplicationsTabContent } from "components/ApplicationsTabContent";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { LoaderContext } from "context/LoaderContext";
import { ActiveApplicationContent } from "components/ActiveApplicationContent";
import { PushNotificationContext, PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { colors, defaultStyles } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";
import { PROFILE_STEPS } from "views/Enums";
import { UserContext } from "context/UserContext";

interface ApplicationTabDTProps {
  paginationOpts: {
    updatePage: () => void;
    results: JobApplication[];
    setLastJobApplicationRef: (node: Element | null) => void;
  };
}

export const ApplicationTabDT: FC<ApplicationTabDTProps> = ({ paginationOpts }) => {
  const { isLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { hideDialog } = useContext(ModalContext);
  const { updateNotification } = useContext(PushNotificationContext);
  const { localize } = useContext(LocaleContext);
  const { calculateProfileProgress } = useContext(UserContext);

  const { updatePage, results, setLastJobApplicationRef } = paginationOpts;

  const [selectedApplication, setSelectedApplication] = useState(0);
  const [headerOffset, setHeaderOffset] = useState(300);

  const handleApplicationPressed = (index: number) => {
    setSelectedApplication(index);
  };

  useEffect(() => {
    updateNotification(PUSH_NOTIFICATION_TYPES.jobApplication);
    const header = document.getElementById("dynamicHeader");
    if (header) {
      setHeaderOffset(header.getBoundingClientRect().height);
    }
  }, []);

  const removeApplication = async (applId: string) => {
    deleteJobApplication(applId)
      .then(() => {
        setSelectedApplication(0);
        updatePage();
        hideDialog();
        calculateProfileProgress(false, PROFILE_STEPS.jobApplication);
        addMessage({ type: "success", content: "success.jobApplicationRemoved", duration: 2000 });
      })
      .catch(() => {
        addMessage({ type: "success", content: "error.removeJobApplication", duration: 2000 });
      });
  };

  if (results.length < 1 && !isLoading("GET_JOB_APPLICATION_LIST")) {
    return (
      <Grid container justifyContent="center">
        <Typography padding="1rem" variant="body2" fontWeight="bold" textAlign="center">
          {localize("common.emptyJobApplicationList")}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container wrap="nowrap" padding="0rem 1rem 1rem 1rem" gap="1rem">
      <Grid item md={4}>
        <ApplicationsTabContent
          paginationOpts={{ results, setLastJobApplicationRef, updatePage }}
          selectedApplicationCallback={handleApplicationPressed}
        />
      </Grid>
      {isLoading("GET_JOB_APPLICATION_LIST") ? (
        <Skeleton
          width="100%"
          height="800px"
          variant="rectangular"
          sx={{ borderRadius: defaultStyles.borderRadius }}
        />
      ) : (
        <Grid container item padding=".1rem 0rem">
          <Card
            sx={{
              width: "100%",
              height: "max-content",
              top: `${headerOffset + 25}px`,
              right: "0",
              // position: "sticky",
              borderRadius: defaultStyles.borderRadius,
              backgroundColor: colors.white,
              boxShadow: defaultStyles.cardShadow,
              overflow: "auto",
            }}
            elevation={4}
          >
            <ActiveApplicationContent
              job={results[selectedApplication].job}
              onRemoveApplication={removeApplication}
              isCancelled={results[selectedApplication].is_canceled}
              handleTypeChange={updatePage}
              applicationId={results[selectedApplication].id}
            />
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
