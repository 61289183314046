import React, { FC, useEffect, useContext, useState, useMemo, useCallback } from "react";
import { Grid } from "@mui/material";
import { JobItem, JobItemHeight } from "./JobItem";
import { DragAndDropHandler, isDragging } from "components/DragAndDrop/DragAndDropHandler";
import { Agent, RegionWithLocations, getAgents, getRegions } from "adapters";
import { usePagination } from "hooks/usePagination";
import {
  KanbanJobStage,
  getJobStatusChoices,
  getKanbanJobStageList,
  updateJobKanbanOrder,
} from "adapters/JobAdapter";
import { LocaleContext } from "context/LocaleContext";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { DropdownItem, MultiSelectDropdown, SelectDropdown } from "components/MultiSelectDropdown";
import { WTextField } from "components/styledComponents/WTextField";
import { FilterTitle } from "./FilterTitle";
import { CreationLimits, KanbanJobsContext } from "context/KanbanJobsContext";
import { TaskDrawerWidth, TasksDrawer } from "./TasksDrawer";
import { KanbanColumns, ProcessedJob, TKanbanColumn } from "./KanbanColumns";
import { WTypography } from "components/WTypography";

const DEFAULT_ORDER_STEP = 100000;
const INTERVAL_TIME = 60 * 1000;

export const JobsKanbanView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { jobResults, fetchAllJobs, creationLimit, setCreationLimit } =
    useContext(KanbanJobsContext);
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const [jobs, setJobs] = useState<ProcessedJob[]>([]);
  const [kanbanColumns, setKanbanColumns] = useState<TKanbanColumn<"job">[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const [selectedJobStatuses, setSelectedJobStatuses] = useState<string[]>([]);
  const [jobStatusesChoices, setJobStatusesChoices] = useState<string[]>([]);
  const [filterString, setFilterString] = useState<string>("");
  const [isTasksOpen, setIsTasksOpen] = useState(false);

  const sendUpdateJobs = (id: string, stageId: string, order: number) => {
    updateJobKanbanOrder(id, {
      kanban_job_stage: stageId,
      kanban_job_stage_order_no: order,
    });
  };

  const { results: stages } = usePagination<KanbanJobStage>(
    getKanbanJobStageList,
    "error.fetchKanbanJobStageList",
    "GET_KANBAN_STAGE_LIST",
    { page_size: 100 }
  );
  const { results: regions } = usePagination<RegionWithLocations>(
    getRegions,
    "error.fetchingRegions",
    "GET_REGIONS",
    { page_size: 100 }
  );
  const { results: agents } = usePagination<Agent>(getAgents, "error.fetchAgents", "GET_AGENTS", {
    page_size: 100,
  });

  useEffect(() => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_JOB_STATUS_CHANGES" });
    getJobStatusChoices()
      .then((res) => {
        setJobStatusesChoices(res.data.job_status_choices);
      })
      .catch(() => {
        addMessage({ content: "error.fetchingJobStatusChoices", type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_JOB_STATUS_CHANGES" });
      });
    if (localStorage.getItem("selectedJobStatuses") === null) {
      localStorage.setItem("selectedJobStatuses", JSON.stringify(["open"]));
    }
  }, []);

  useEffect(() => {
    setSelectedAgents(JSON.parse(localStorage.getItem("selectedAgents") || "[]"));
    setSelectedRegions(JSON.parse(localStorage.getItem("selectedRegions") || "[]"));
    setSelectedJobStatuses(JSON.parse(localStorage.getItem("selectedJobStatuses") || "[]"));
    fetchAllJobs(creationLimit);
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedAgents", JSON.stringify(selectedAgents));
    localStorage.setItem("selectedRegions", JSON.stringify(selectedRegions));
    localStorage.setItem("selectedJobStatuses", JSON.stringify(selectedJobStatuses));
  }, [selectedAgents, selectedRegions, selectedJobStatuses]);

  useEffect(() => {
    const intervalHandle = setInterval(async () => {
      if (isDragging) {
        return;
      }
      fetchAllJobs(creationLimit);
    }, INTERVAL_TIME);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [creationLimit]);

  useEffect(() => {
    if (stages.length === 0) {
      return;
    }
    const sortedStages = stages.slice().sort((a, b) => a.order_no - b.order_no);
    const jobs = jobResults
      .map((job, index) => {
        return {
          ...job,
          kanban_job_stage: job.kanban_job_stage ?? sortedStages[0].id,
          kanban_job_stage_order_no:
            job.kanban_job_stage_order_no ?? (index + 1) * DEFAULT_ORDER_STEP,
        } as ProcessedJob;
      })
      .filter((job) => sortedStages.find((stage) => stage.id === job.kanban_job_stage));
    setJobs(jobs);
  }, [jobResults, stages]);

  const onDrop = useCallback(
    async (elementId: string, areaId: string, neighburIds: (string | null)[]): Promise<void> => {
      const job = jobs.find((job) => job.id === elementId);
      if (!job) {
        return;
      }
      const neighburs: ProcessedJob[] = [];
      let newOrder = -1;
      neighburIds.forEach((id) => {
        if (!id) {
          return;
        }

        const job = jobs.find((job) => job.id === id);
        if (job) {
          neighburs.push(job);
        }
      });

      if (neighburs.length === 0) {
        newOrder = DEFAULT_ORDER_STEP;
      } else if (neighburs.length === 2) {
        newOrder = Math.floor(
          (neighburs[0].kanban_job_stage_order_no + neighburs[1].kanban_job_stage_order_no) / 2
        );
      } else if (neighburIds[0] === null) {
        newOrder = Math.floor(neighburs[0].kanban_job_stage_order_no / 2);
      } else if (neighburIds[1] === null) {
        newOrder = neighburs[0].kanban_job_stage_order_no + DEFAULT_ORDER_STEP;
      }

      sendUpdateJobs(elementId, areaId, newOrder);
      const jobList = [...jobs];
      jobList.splice(
        jobs.findIndex((j) => j.id === elementId),
        1
      );
      setJobs([
        ...jobList,
        { ...job, kanban_job_stage: areaId, kanban_job_stage_order_no: newOrder },
      ]);
    },
    [jobs]
  );

  useEffect(() => {
    if (stages.length === 0) {
      return;
    }

    const dragHandler = new DragAndDropHandler("kanban", onDrop, true);

    const sortedStages = stages.slice().sort((a, b) => a.order_no - b.order_no);
    const columns: Record<string, TKanbanColumn<"job">> = {};
    sortedStages.forEach((stage) => {
      columns[stage.id] = { stage, items: [] };
    });
    jobs.forEach((job) => {
      columns[job.kanban_job_stage].items.push(job);
    });
    const columnsArr = Object.values(columns);
    columnsArr.forEach((a) =>
      a.items.sort((a, b) => a.kanban_job_stage_order_no - b.kanban_job_stage_order_no)
    );
    setKanbanColumns(columnsArr);

    return () => {
      dragHandler.destroy();
    };
  }, [jobs, stages, onDrop]);

  const availableRegions = useMemo((): DropdownItem[] => {
    return regions
      .map((r) => ({
        id: r.id,
        primary: r.name,
        secondary: jobs
          .filter((job) => {
            if (job.locations.length === 0) {
              return true;
            }

            return job.locations.some((jl) => r.locations.find((rl) => rl.id === jl.id));
          })
          .length.toString(),
      }))
      .sort((a, b) => a.primary.localeCompare(b.primary));
  }, [regions, jobs]);

  const selectedLocations = useMemo((): string[] => {
    return regions
      .filter((r) => selectedRegions.includes(r.id))
      .reduce((list, r) => {
        list.push(...r.locations.map((l) => l.id));
        return list;
      }, [] as string[]);
  }, [regions, selectedRegions]);

  const availableAgents = useMemo((): DropdownItem[] => {
    return agents
      .map((a) => ({
        id: a.id,
        primary: a.full_name || a.email,
        secondary: jobs
          .filter((job) => {
            const email = typeof job.recruiter === "string" ? job.recruiter : job.recruiter.email;
            return email === a.email;
          })
          .length.toString(),
      }))
      .sort((a, b) => a.primary.localeCompare(b.primary));
  }, [agents, jobs]);

  const selectedAgentEmails = useMemo(() => {
    return agents.filter((a) => selectedAgents.includes(a.id)).map((a) => a.email);
  }, [agents, selectedAgents]);

  const availableJobStatuses = useMemo((): DropdownItem[] => {
    return jobStatusesChoices.map((j) => ({
      id: j,
      primary: j,
      secondary: jobs
        .filter((job) => {
          return job.job_status === j;
        })
        .length.toString(),
    }));
  }, [jobStatusesChoices, jobs]);

  const getVisibleItems = useCallback(
    (items: ProcessedJob[]) => {
      return items
        .filter((job) => {
          if (selectedLocations.length === 0 || job.locations.length === 0) {
            return true;
          }
          return job.locations.filter((l) => selectedLocations.includes(l.id)).length > 0;
        })
        .filter((job) => {
          if (selectedAgentEmails.length === 0) {
            return true;
          }
          const email = typeof job.recruiter === "string" ? job.recruiter : job.recruiter.email;
          return selectedAgentEmails.includes(email);
        })
        .filter((job) => {
          if (selectedJobStatuses.length === 0) {
            return true;
          }
          return selectedJobStatuses.includes(job.job_status);
        })
        .filter((job) => {
          if (!filterString) {
            return true;
          }
          return job.title.toLowerCase().includes(filterString.toLowerCase());
        })
        .map((job) => job.id);
    },
    [selectedLocations, selectedAgentEmails, filterString, selectedJobStatuses]
  );

  const creationLimitItems = useMemo((): DropdownItem[] => {
    return CreationLimits.map((value) => {
      const primary =
        value === -1 ? localize("kanbanView.noLimit") : `${value} ${localize("kanbanView.months")}`;
      return { id: value.toString(), primary };
    });
  }, [localize]);

  return (
    <>
      <Grid
        container
        direction="column"
        height="100vh"
        flexWrap="nowrap"
        paddingTop="30px"
        width={`calc(100% - ${isTasksOpen ? TaskDrawerWidth : 0}) !important`}
        sx={{ transition: "width 0.2s linear" }}
      >
        <Grid item container gap="10px 20px" padding="30px" alignItems="flex-end">
          <FilterTitle tag="kanbanView.filterRegion">
            <MultiSelectDropdown
              selectedIds={selectedRegions}
              items={availableRegions}
              onToggle={setSelectedRegions}
              noSelectionTag="kanbanView.allRegions"
              width="200px"
            />
          </FilterTitle>
          <FilterTitle tag="kanbanView.filterAgent">
            <MultiSelectDropdown
              selectedIds={selectedAgents}
              items={availableAgents}
              onToggle={setSelectedAgents}
              noSelectionTag="kanbanView.allAgents"
              width="200px"
            />
          </FilterTitle>
          <FilterTitle tag="kanbanView.filterAge">
            <SelectDropdown
              selectedId={creationLimit.toString()}
              items={creationLimitItems}
              onSelect={(newLimit) => {
                setCreationLimit(parseInt(newLimit));
                fetchAllJobs(parseInt(newLimit));
              }}
              width="200px"
            />
          </FilterTitle>
          <FilterTitle tag="kanbanView.filterName">
            <WTextField onChange={(e) => setFilterString(e.target.value)} />
          </FilterTitle>
          <FilterTitle tag="kanbanView.filterJobStatus">
            <MultiSelectDropdown
              selectedIds={selectedJobStatuses}
              items={availableJobStatuses}
              onToggle={setSelectedJobStatuses}
              noSelectionTag="kanbanView.allJobStatuses"
              width="200px"
            />
          </FilterTitle>
        </Grid>
        <KanbanColumns
          columns={kanbanColumns}
          itemHeight={JobItemHeight}
          getVisibleItems={getVisibleItems}
          secondaryTextCallback={(count) => (
            <WTypography key="secondaryTextCallback" variant="body2">
              {count}
            </WTypography>
          )}
          itemCallback={(job, visible) => (
            <JobItem key={job.id} job={job} style={{ display: !visible ? "none" : "" }} />
          )}
        />
      </Grid>
      <TasksDrawer isOpen={isTasksOpen} setOpen={setIsTasksOpen} />
    </>
  );
};
