import { Grid } from "@mui/material";
import { Navbar } from "components/layout/Navbar/Navbar";
import { UserContext } from "context/UserContext";
import React, { FC, useContext } from "react";
import { generateWelcomeMessage } from "utils/GenerateWelcomeMessage";
import { UserInfoComponent } from "components/UserInfoComponent";
import { ModalContext } from "context/ModalContext";
import { useHistory } from "react-router-dom";
import { DynamicHeader } from "../DynamicHeader";
import { WIconButton } from "components/styledComponents/WIconButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { BreakpointsContext } from "context/BreakpointContext";

export const AgentStartPageHeader: FC = () => {
  const { showModal } = useContext(ModalContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { user } = useContext(UserContext);

  const { welcomeMessage } = generateWelcomeMessage();

  const history = useHistory();
  const handleAvatarPicker = () => {
    if (window.location.pathname.includes("profile")) {
      showModal("PROFILE_IMAGE_OPTIONS_MODAL");
    }
    history.push("/profile");
  };

  if (isDesktop) {
    return (
      <DynamicHeader stopDynamicBehavior={true}>
        <Navbar title={`${welcomeMessage} ${user.first_name}!`} sx={{ paddingBottom: "2rem" }} />
      </DynamicHeader>
    );
  }

  return (
    <DynamicHeader>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        sx={{
          maxWidth: {
            xs: "100%",
            md: "500px",
          },
        }}
      ></Grid>
      <WIconButton
        sx={{ position: "absolute", top: "1rem", right: "1rem" }}
        onClick={() => history.push("/settings")}
      >
        <WIcon icon={WIconTypes.settings} color="primary" />
      </WIconButton>
      <UserInfoComponent handleAvatarPicker={handleAvatarPicker} />
    </DynamicHeader>
  );
};
//
