import { Typography, FormControlLabel, Checkbox, Divider, Box, Grid } from "@mui/material";
import { getSkillsList, PostSkills, addUserSkills, removeUserSkills, Skill } from "adapters";
import { WSearchBar, WButton, WIcon, WIconTypes } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import { usePagination } from "hooks/usePagination";
import React, { FC, useContext, useEffect, useState } from "react";
import { colors, defaultStyles } from "styles/colors";
import { PROFILE_STEPS } from "views/Enums";

export const SkillsList: FC = () => {
  const { addMessage } = useContext(NotificationContext);
  const { localize } = useContext(LocaleContext);
  const [currentSkills, setCurrentSkills] = useState<Skill[]>([]);
  const [searchText, setSearchText] = useState("");
  const { dispatch, user, calculateProfileProgress } = useContext(UserContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const { results, setLastElementRef, nextPage, handleSearch } = usePagination<Skill>(
    getSkillsList,
    "error.listingSkills",
    "GET_SKILLS"
  );

  useEffect(() => {
    // Initialize user skills
    setCurrentSkills(user.skills);
  }, []);

  useEffect(() => {
    handleSearch(searchText ? { search: searchText } : {});
  }, [searchText]);

  // Handle if all items in current pagination page is selected, force next page in pagination
  useEffect(() => {
    if (filteredSkills.length === 0) {
      if (results.every((skill) => currentSkills.some((currSkill) => currSkill.id === skill.id))) {
        nextPage();
      }
    }
  }, [results]);

  const handleCheckboxClick = (clickedSkill: Skill) => {
    if (currentSkills.some((currSkill) => currSkill.id === clickedSkill.id)) {
      const tempSkills = currentSkills.filter((currSkill) => currSkill.id !== clickedSkill.id);

      setCurrentSkills(tempSkills);
    } else {
      setCurrentSkills([...currentSkills, clickedSkill]);
    }
  };

  const handleAdditions = (itemsToAdd: Skill[], displayMessages: boolean) => {
    const formatedItems: PostSkills = { skills: itemsToAdd };
    addUserSkills(formatedItems)
      .then(() => {
        if (displayMessages) {
          const allSkills = user.skills.concat(formatedItems.skills);
          dispatch({ type: "UPDATE_USER", user: { skills: allSkills } });
          calculateProfileProgress(!!allSkills.length, PROFILE_STEPS.skill);
          addMessage({ content: "success.addingSkills", type: "success", duration: 1500 });
        }
      })
      .catch(() => {
        if (displayMessages) {
          addMessage({ content: "error.addingSkills", type: "error" });
        }
      });
  };

  const handleDeletions = (itemsToDelete: Skill[], displayMessages: boolean) => {
    const formatedItems: PostSkills = { skills: itemsToDelete };
    removeUserSkills(formatedItems)
      .then(() => {
        if (displayMessages) {
          // Update user context
          const updatedSkills = user.skills.filter(
            (userSkill) => !itemsToDelete.some((item) => item.id === userSkill.id)
          );
          dispatch({ type: "UPDATE_USER", user: { skills: updatedSkills } });
          calculateProfileProgress(!!updatedSkills.length, PROFILE_STEPS.skill);

          addMessage({ content: "success.removingSkills", type: "success", duration: 1500 });
        }
      })
      .catch(() => {
        if (displayMessages) {
          addMessage({ content: "error.deletingSkills", type: "error" });
        }
      });
  };

  const handleSaveChanges = () => {
    const userSkills = user.skills;
    const additions =
      currentSkills.filter((currSkill) => !userSkills.some((skill) => skill.id === currSkill.id)) ||
      [];
    const deletions =
      userSkills.filter((currSkill) => !currentSkills.some((skill) => skill.id === currSkill.id)) ||
      [];

    if (additions.length + deletions.length === 0) {
      return;
    }

    if (additions.length && deletions.length) {
      Promise.all([handleAdditions(additions, false), handleDeletions(deletions, false)])
        .then(() => {
          addMessage({ content: "success.updatingSkills", type: "success" });
        })
        .catch(() => {
          addMessage({ content: "error.deleteOrAddSkills", type: "error" });
        })
        .finally(() => {
          // Updated skills with removed items
          const updatedSkills = userSkills.filter(
            (userSkill) => !deletions.some((delSkill) => delSkill.id === userSkill.id)
          );

          dispatch({
            type: "UPDATE_USER",
            user: { skills: [...updatedSkills, ...additions] },
          });
        });
    } else {
      if (additions.length) {
        handleAdditions(additions, true);
      } else {
        handleDeletions(deletions, true);
      }
    }
  };

  const filteredSkills = results.filter((skill) => {
    return (
      !currentSkills.some((item) => item.id === skill.id) &&
      (searchText === "" || skill.name.toLowerCase().includes(searchText))
    );
  });

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      sx={{
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadiusBottom,
        border: `1px solid ${colors.grey}`,
      }}
    >
      <Box margin=".5rem 1rem">
        <Typography fontWeight="bold">
          {localize("common.my")} {localize("common.skills").toLowerCase()}
        </Typography>
        <Box maxHeight="8rem" overflow="auto">
          {currentSkills.length > 0 ? (
            currentSkills.map((skill) => {
              return (
                <Grid alignItems="center" key={`Skills_${skill.id}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ color: "common.black", padding: ".3rem 0 .3rem  .5rem" }}
                        defaultChecked
                        onClick={() => handleCheckboxClick(skill)}
                      />
                    }
                    label={skill.name}
                  />
                </Grid>
              );
            })
          ) : (
            <Typography margin="1rem">{localize("profileView.noSkills")}</Typography>
          )}
        </Box>
      </Box>
      <Divider />
      <Box margin=".5rem 1rem">
        <Typography marginBottom="1rem" marginTop=".5rem" fontWeight="bold">
          {localize("common.skills")}
        </Typography>
        <Box maxWidth="460px">
          <WSearchBar
            label={localize("common.skillsSearch")}
            value={searchText}
            handleSearchClick={(newValue) => setSearchText(newValue.toLowerCase())}
          />
        </Box>
        <Box marginTop=".5rem" {...(isDesktop ? { maxHeight: "8rem", overflow: "auto" } : {})}>
          {results.length > 0 ? (
            filteredSkills.map((skill, index) => {
              return (
                <Grid alignItems="center" key={`Skills_${skill.id}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={`Checkbox_${index}`}
                        checked={currentSkills.some((currSkill) => currSkill.id === skill.id)}
                        sx={{ color: "common.black", padding: " .3rem 0 .3rem .5rem" }}
                        onChange={() => handleCheckboxClick(skill)}
                      />
                    }
                    label={skill.name}
                  />
                  {index === filteredSkills.length - 1 && <div ref={setLastElementRef} />}
                </Grid>
              );
            })
          ) : (
            <Typography margin="1rem">{localize("profileView.noAvailableSkills")}</Typography>
          )}
        </Box>
      </Box>
      {isDesktop ? (
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={2} padding="1rem">
            <WButton
              fullWidth
              variant="outlined"
              startIcon={<WIcon icon={WIconTypes.check} />}
              onClick={handleSaveChanges}
            >
              {localize("form.save")}
            </WButton>
          </Grid>
        </Grid>
      ) : (
        <Box
          width="100%"
          padding="0 1rem"
          sx={{ position: "sticky", bottom: 0, backgroundColor: "background.default" }}
        >
          <WButton
            fullWidth
            variant="outlined"
            sx={{ margin: "1rem 0" }}
            startIcon={<WIcon icon={WIconTypes.check} />}
            onClick={handleSaveChanges}
          >
            {localize("form.save")}
          </WButton>
        </Box>
      )}
    </Grid>
  );
};
