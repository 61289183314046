import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";
import { User } from "reducers/UserReducer";

export type UserDocumentActions = "GET_DOCUMENT" | "DELETE_DOCUMENT" | "ADD_DOCUMENT";

export interface UserDocument {
  id: string;
  alias: string;
  user_document: string;
  user: User;
  created: string;
}

export const getUserDocument = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<UserDocument>>> => {
  return API.get("/user-documents/", { params: params });
};

export const removeUserDocument = async (id: string): Promise<void> => {
  return API.delete(`/user-documents/${id}/`);
};

export const addUserDocument = async (
  data: File,
  alias: string,
  candidate: string
): Promise<AxiosResponse<UserDocument>> => {
  const formData = new FormData();
  formData.append("user_document", data);
  formData.append("alias", alias);
  formData.append("user", candidate);
  return API.post("/user-documents/", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
