import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getJobFromExtRef, Job } from "adapters";
import { DetailedJobApplicationContentDT } from "components/modals/DetailedJobApplicationModal/DetailedJobApplicationContentDT";

interface ApplicationTTViewProps {
  jobId?: string;
}

const ApplicationTTView: FC = () => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const history = useHistory();
  const { jobId } = useParams<ApplicationTTViewProps>();
  const [currJob, setCurrJob] = useState<Job | null>(null);

  useEffect(() => {
    loadJobData();
  }, [jobId]);

  const loadJobData = () => {
    if (jobId) {
      dispatchLoading({ type: "SET_LOADING", payload: "GET_JOB" });

      getJobFromExtRef(jobId)
        .then((res) => {
          setCurrJob(res.data);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.fetchingJob", duration: 2000 });

          history.push("/");
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "GET_JOB" });
        });
      return;
    }
    addMessage({ type: "error", content: "error.fetchingJob", duration: 2000 });
  };

  if (!currJob) {
    return null;
  }

  return <DetailedJobApplicationContentDT jobId={currJob.id} job={currJob} />;
};

export default ApplicationTTView;
