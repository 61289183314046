import { WTypography } from "components/WTypography";
import React, { useContext, FC, useState, useEffect, useMemo, useCallback } from "react";
import { Typography, Grid, Skeleton } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { colors, defaultStyles } from "styles/colors";
import { getTrendInfo } from "./TrendInfo";
import { AgentsData, AgentsPeriod, getAgentsData, getAgentsPeriod } from "adapters";
import { prettifyDate } from "utils/PrettifyDate";
import { getDateFromCurrent } from "utils/TimeUnitToDate";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { StatisticsFilterContext } from "context/StatisticsFilterContext";

export const AgentTrendOverview: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { period } = useContext(StatisticsFilterContext);
  const { isLoading } = useContext(LoaderContext);

  const [agentsData, setAgentsData] = useState<AgentsData>(defaultAgentsData);
  const [agentsPeriod, setAgentsPeriod] = useState<AgentsPeriod>(defaultAgentsPeriod);
  const [previousAgentsPeriod, setPreviousAgentsPeriod] =
    useState<AgentsPeriod>(defaultAgentsPeriod);

  const periodLength = period === "week" ? 7 : 30;
  const getData = useCallback(async () => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_AGENTS_DATA" });
    getAgentsData()
      .then((res) => {
        setAgentsData(res.data);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.fetchingJob", duration: 2000 });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_AGENTS_DATA" });
      });
    dispatchLoading({ type: "SET_LOADING", payload: "GET_AGENTS_PERIOD" });
    await Promise.all([
      getAgentsPeriod({
        from_date: prettifyDate(getDateFromCurrent(-periodLength + 1).toISOString()),
        to_date: prettifyDate(new Date().toISOString()),
      })
        .then((res) => {
          setAgentsPeriod(res.data);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.fetchingJob", duration: 2000 });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "GET_AGENTS_PERIOD" });
        }),
      getAgentsPeriod({
        from_date: prettifyDate(getDateFromCurrent(-periodLength * 2 + 1).toISOString()),
        to_date: prettifyDate(getDateFromCurrent(-periodLength).toISOString()),
      })
        .then((res) => {
          setPreviousAgentsPeriod(res.data);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.fetchingJob", duration: 2000 });
        }),
    ]);
  }, [period]);

  useEffect(() => {
    getData();
  }, [period]);

  const threeTrends = useMemo(
    () => [
      {
        name: localize("statisticView.sentMessages"),
        total: agentsPeriod.total_messages_sent || 0,
        previous: previousAgentsPeriod.total_messages_sent || 0,
      },
      {
        name: localize("statisticView.ratingsChanged"),
        total: agentsPeriod.total_rating_changed || 0,
        previous: previousAgentsPeriod.total_rating_changed || 0,
      },
      {
        name: localize("statisticView.notesCreated"),
        total: agentsPeriod.total_notes_posted || 0,
        previous: previousAgentsPeriod.total_notes_posted || 0,
      },
    ],
    [agentsPeriod]
  );

  return (
    <Grid
      item
      width="100%"
      minWidth="350px"
      sx={{
        padding: "20px",
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadius,
        boxShadow: defaultStyles.cardShadow,
      }}
    >
      <Typography variant="h5" fontWeight="bold" color={colors.textDark}>
        {localize("statisticView.overview")}{" "}
        <Typography variant="body2">
          {localize("statisticView.ofXAgents", { total: agentsData.active.toString() })}
        </Typography>
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="10px"
      >
        {isLoading("GET_AGENTS")
          ? Array(3)
              .fill(1)
              .map((_, index) => (
                <Skeleton
                  key={`skeleton_message_${index}`}
                  width="33%"
                  height="90px"
                  variant="rectangular"
                  sx={{ borderRadius: defaultStyles.borderRadius, marginBottom: "1px" }}
                />
              ))
          : threeTrends.map((trend, index) => {
              return (
                <Grid
                  item
                  xs
                  key={trend.name}
                  borderLeft={index !== 0 ? defaultStyles.borderLeftMainDark : "none"}
                  paddingLeft={index !== 0 ? "20px" : "0"}
                  marginLeft={index !== 0 ? "5px" : "0"}
                >
                  <WTypography variant="h4" fontWeight="bold">
                    {trend.total}
                  </WTypography>

                  <WTypography variant="body2">{trend.name}</WTypography>

                  <Grid
                    container
                    alignItems="center"
                    width="max-content"
                    color={colors.darkGrey}
                    gap="3px"
                    fontSize="small"
                  >
                    {getTrendInfo(trend.total, trend.previous).trendOutput}
                  </Grid>
                </Grid>
              );
            })}
      </Grid>
    </Grid>
  );
};

const defaultAgentsData: AgentsData = {
  active: 0,
};

const defaultAgentsPeriod: AgentsPeriod = {
  total_messages_sent: 0,
  total_notes_posted: 0,
  total_rating_changed: 0,
};
