import { IconButton, InputAdornment } from "@mui/material";
import React, { FC, useState } from "react";
import { WTextField } from "./styledComponents/WTextField";
import { WIcon, WIconTypes } from "components";

interface WSearchBar {
  label: string;
  value: string;
  handleSearchClick: (newValue: string) => void;
  disabled?: boolean;
}

export const WSearchBar: FC<WSearchBar> = ({ label, value, handleSearchClick }) => {
  const [textValue, setTextValue] = useState(value);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSearchClick(textValue);
      }}
    >
      <WTextField
        fullWidth
        label={label}
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => handleSearchClick(textValue)}>
                <WIcon icon={WIconTypes.search} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};
