import { TextField, InputAdornment } from "@mui/material";
import React, { FC } from "react";
import { WIcon, WIconTypes } from "components";

export type Keyword = {
  label: string;
  id: string;
  type?: "region" | "role" | "skill" | "department";
};

interface SearchbarProps {
  setInputValue: (value: string) => void;
  placeholder: string;
}

export const Searchbar: FC<SearchbarProps> = ({ setInputValue, placeholder }) => {
  return (
    <TextField
      variant="standard"
      sx={{ padding: "0.5rem", width: "100%" }}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      placeholder={placeholder}
      InputProps={{
        disableUnderline: true,
        type: "text",
        startAdornment: (
          <InputAdornment position="start" sx={{ opacity: "0.45" }}>
            <WIcon icon={WIconTypes.search} />
          </InputAdornment>
        ),
      }}
    />
  );
};
