import { Typography, Stepper, Step, StepLabel, Grid, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { JobApplication } from "adapters";
import { WIcon, WIconTypes } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC } from "react";
import { colors } from "styles/colors";

interface JobStatusStepperProps {
  jobApplication: Partial<JobApplication>;
}
export const JobStatusStepper: FC<JobStatusStepperProps> = ({ jobApplication }) => {
  const { localize } = React.useContext(LocaleContext);
  const { status_progression, is_draft } = jobApplication;

  if (!status_progression) {
    return null;
  }

  return (
    <Grid container direction="column" wrap="nowrap">
      <Divider style={{ width: "100%" }} />

      <Grid container wrap="nowrap" direction="column" padding="1rem 0 1rem 1rem" gap="1rem">
        {is_draft ? (
          <Grid container gap="1rem" wrap="nowrap">
            <Box minWidth="max-content" alignItems="center">
              <WIcon icon={WIconTypes.info} color="draft" />
            </Box>
            <Typography color={colors.draftColor} fontWeight="bold">
              {localize("common.draftNoStage")}
            </Typography>
          </Grid>
        ) : (
          <>
            <Typography color="primary.main" fontWeight="bold">
              {localize("common.status")}
            </Typography>
            <Stepper orientation="vertical" color="secondary">
              {status_progression.map((status) => (
                <Step
                  active={status.active}
                  key={`Step_${status.status}`}
                  sx={{
                    span: {
                      padding: "0",
                      marginRight: "0.5rem",
                    },
                    svg: {
                      circle: {
                        height: "8px",
                        width: "8px",
                      },
                      color: status.active ? "#95DFD3 !important" : "",
                      text: {
                        display: "none",
                      },
                    },
                  }}
                >
                  <StepLabel>{status.status}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </>
        )}
      </Grid>
      <Divider style={{ width: "100%" }} />
    </Grid>
  );
};
