import React, { useContext, FC } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { LoaderContext } from "context/LoaderContext";
import { usePagination } from "hooks/usePagination";
import { getJobList, Job, removeSavedJob } from "adapters";
import { ProfileSavedJobCard } from "./ProfileSavedJobCard";
import { NotificationContext } from "context/NotificationContext";
import { BreakpointsContext } from "context/BreakpointContext";

export const MySavedJobsTab: FC = () => {
  const { isLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const {
    results: jobs,
    setLastElementRef,
    updatePage,
  } = usePagination<Job>(
    () => getJobList({ status: "active", expand: "role", user_interest: true }),
    "error.fetchJobList",
    "GET_JOB_LIST"
  );

  //TODO; Iplement remove job on new jobcard
  const removeJob = async (jobId: string) => {
    return removeSavedJob(jobId)
      .then(() => {
        setTimeout(() => {
          updatePage();
          addMessage({ type: "success", content: "success.removedSuccessful", duration: 2000 });
        }, 1000);
        return true;
      })
      .catch(() => {
        addMessage({ type: "error" });
        return false;
      });
  };

  return (
    <Grid container gap={isDesktop ? "1rem" : "2px"} className={isDesktop ? "contentWrapper" : ""}>
      {isLoading("GET_JOB_LIST") ? (
        <CircularProgress sx={{ margin: "auto" }} />
      ) : !jobs.length ? (
        <Typography padding="1rem" variant="body2" fontWeight="bold" textAlign="center">
          {localize("common.emptySavedJobs")}
        </Typography>
      ) : (
        jobs.map((job, index) => {
          if (job.job_application) {
            return null;
          }
          const isLastElement = jobs.length === index + 1;
          return isLastElement ? (
            <div ref={setLastElementRef} key={`savedJob${job.id}`} style={{ width: "100%" }}>
              <ProfileSavedJobCard job={job} onRemove={removeJob} jobIndex={index} />
            </div>
          ) : (
            <ProfileSavedJobCard
              key={`savedJob${job.id}`}
              job={job}
              onRemove={removeJob}
              jobIndex={index}
            />
          );
        })
      )}
    </Grid>
  );
};
