import { Grid } from "@mui/material";
import Grow from "@mui/material/Grow";
import { Referral, updateReferral } from "adapters";
import { WButton } from "components/styledComponents/WButton";
import { WIconButton } from "components/styledComponents/WIconButton";
import { WTextField } from "components/styledComponents/WTextField";
import { WIcon, WIconTypes } from "components/WIcon";
import { WTypography } from "components/WTypography";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, useCallback, useContext, useState } from "react";
import { colors, defaultStyles } from "styles/colors";

interface candidateReferences {
  references: Referral[];
  updateReferences: (referralID: string, newComment: string) => void;
}

export const CandidateReferences: FC<candidateReferences> = ({ references, updateReferences }) => {
  const { localize } = useContext(LocaleContext);
  const [isEditing, setIsEditing] = useState<boolean | string>(false);
  const [text, setText] = useState("");

  const { dispatchLoading } = useContext(LoaderContext);
  const { dispatch, users } = useContext(UserContext);
  const { addMessage } = useContext(NotificationContext);

  const handleSave = useCallback((changedData: Partial<Referral>, referralId: string): void => {
    dispatchLoading({ type: "SET_LOADING", payload: "UPDATE_REFERRAL" });
    updateReferral(changedData, referralId)
      .then((res) => {
        addMessage({ content: "success.updateSuccessful", type: "success", duration: 1500 });

        const updatedUserList = users.map((u) => {
          return {
            ...u,
            referrals: u.referrals.map((ref) => (ref.id === res.data.id ? res.data : ref)),
          };
        });
        dispatch({ type: "SET_USER_LIST", users: updatedUserList });

        setText(changedData.agent_comment || "");
        setIsEditing(false);
        updateReferences(referralId, changedData.agent_comment || "");
      })
      .catch(() => {
        addMessage({ type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "UPDATE_REFERRAL" });
      });
  }, []);

  return (
    <Grid container gap={3} direction="column">
      {references
        .slice(0)
        .reverse()
        .map(({ id, first_name, last_name, email, phone, comment, agent_comment }, index) => (
          <Grow key={id} in={true} {...{ timeout: 1000 + index * 200 }}>
            <Grid
              container
              gap="8px"
              sx={{
                backgroundColor: colors.mainWithOpacity,
                borderRadius: defaultStyles.borderRadius,
                padding: "12px",
              }}
            >
              <Grid container gap="15px">
                <Grid item>
                  <WTypography variant="body1" fontWeight="bold" color={colors.textDark}>
                    {first_name} {last_name}
                  </WTypography>
                </Grid>

                <Grid item gap="3px" color={colors.darkGrey} alignItems="center" display="flex">
                  <WIcon icon={WIconTypes.phone} size="tiny" customColor={colors.darkGrey} />
                  <WTypography variant="caption">{phone}</WTypography>
                </Grid>

                <Grid item color={colors.darkGrey} gap="3px" alignItems="center" display="flex">
                  <WIcon icon={WIconTypes.mail} size="tiny" customColor={colors.darkGrey} />
                  <WTypography variant="caption">{email}</WTypography>
                </Grid>
              </Grid>

              <Grid item color={colors.darkGrey}>
                <WTypography variant="body2" lineClamps={50}>
                  {comment}
                </WTypography>
              </Grid>

              <Grid container color={colors.darkGrey} direction="column">
                {isEditing !== id ? (
                  <>
                    <Grid item alignItems="center" display="flex">
                      <WTypography variant="body2" fontWeight="bold">
                        {localize("candidateModal.agentComment")}{" "}
                      </WTypography>
                      <WIconButton
                        onClick={async () => {
                          setIsEditing(id);
                          setText(agent_comment || "");
                        }}
                      >
                        <WIcon
                          icon={WIconTypes.write}
                          size="small"
                          customColor={colors.primaryGreen}
                        />
                      </WIconButton>
                    </Grid>

                    <WTypography variant="body2" fontStyle={agent_comment ? "normal" : "italic"}>
                      {agent_comment ?? localize("candidateModal.noComment")}
                    </WTypography>
                  </>
                ) : (
                  <>
                    <WTextField
                      label={localize("candidateModal.agentComment")}
                      value={text}
                      multiline={true}
                      rows={5}
                      onChange={(e) => setText(e.target.value)}
                    />
                    <Grid container gap="10px" justifyContent="flex-end" mt="10px">
                      <WButton variant="outlined" onClick={() => setIsEditing(false)}>
                        {localize("common.cancel")}
                      </WButton>
                      <WButton
                        startIcon={<WIcon icon={WIconTypes.check} />}
                        variant="contained"
                        type="submit"
                        onClick={() => {
                          handleSave({ agent_comment: text }, id);
                        }}
                      >
                        {localize("form.save")}
                      </WButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grow>
        ))}
    </Grid>
  );
};
