import React from "react";
import ReactDOM from "react-dom";
import BaseTheme from "./styles/BaseTheme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "Routes";
import Layout from "Layout";
import "styles/index.css";
import { GlobalProvider } from "context/GlobalContext";
import { NotificationComponent } from "components/NotificationComponent";
import ErrorBoundary from "ErrorBoundary";

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ErrorBoundary>
      <ThemeProvider theme={BaseTheme}>
        <BrowserRouter>
          <GlobalProvider>
            <NotificationComponent />
            <Layout>
              <Routes />
            </Layout>
          </GlobalProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
