import { Box, Typography, MenuItem } from "@mui/material";
import { getRegions, RegionWithLocations } from "adapters";
import { ExpandableListItem, WButton, WIcon, WIconTypes, WSkeleton } from "components";
import { JobFilterItem, JobFilters } from "components/dialogs/FiltersDialog";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { usePagination } from "hooks/usePagination";
import React, { FC, useContext } from "react";
import { colors } from "styles/colors";

interface RegionFilterListProps {
  expand: boolean;
  itemsToShow: number;
  filters: JobFilters;
  handleFilterClick: (key: keyof JobFilters, item: JobFilterItem) => void;
  handleExpandClick: () => void;
}

export const RegionFilterList: FC<RegionFilterListProps> = ({
  expand,
  itemsToShow,
  filters,
  handleFilterClick,
  handleExpandClick,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);

  const { results, setLastElementRef } = usePagination<RegionWithLocations>(
    getRegions,
    "error.fetchingRegions",
    "GET_REGIONS"
  );

  return (
    <Box>
      <Typography variant="h6">{localize("common.region")}</Typography>
      {isLoading("GET_REGIONS") ? (
        <WSkeleton width="100%">
          <MenuItem />
        </WSkeleton>
      ) : (
        results.slice(0, expand ? itemsToShow : undefined).map((region, index) => (
          <React.Fragment key={region.id}>
            <ExpandableListItem
              disableExpand
              key={region.id}
              label={region.name}
              value={region.id}
              isChecked={filters.regions.some((filterRegion) => filterRegion.value === region.id)}
              isExpanded={region.locations.some((location) =>
                filters.locations.some((filterLocation) => filterLocation.value === location.id)
              )}
              expandableListData={region.locations.map((location) => {
                return {
                  label: location.city,
                  value: location.id,
                  isChecked: filters.locations.some(
                    (filterLocation) => filterLocation.value === location.id
                  ),
                  isDisabled: true,
                };
              })}
              handleClick={(value) => handleFilterClick("regions", value)}
              handleSubListClick={(value) => handleFilterClick("locations", value)}
            />
            {index === results.length - 1 && <div ref={setLastElementRef} />}
          </React.Fragment>
        ))
      )}
      {expand && results.length > itemsToShow && (
        <WButton
          fullWidth
          endIcon={<WIcon icon={WIconTypes.arrowDown} color="mainDarkRed" />}
          onClick={handleExpandClick}
          sx={{ maxWidth: "max-content", color: colors.mainDarkRed }}
        >
          {localize("searchbar.showMoreRegions")}
        </WButton>
      )}
    </Box>
  );
};
