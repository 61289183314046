import React, { useContext } from "react";
import { RegisterProfileForm } from "components/forms/RegisterProfileForm";
import { Grid, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { LoginBackgroundBottomSVG, LoginBackgroundTopSVG } from "./LoginView";
import { BreakpointsContext } from "context/BreakpointContext";

const Registerview: React.FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  return (
    <Grid
      container
      className="contentWrapper"
      alignItems="center"
      position="relative"
      sx={{
        flex: "1 1 auto",
      }}
    >
      {isDesktop && (
        <>
          <LoginBackgroundTopSVG />
          <LoginBackgroundBottomSVG />
        </>
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        gap="2rem"
        zIndex="2"
        maxWidth="400px"
        margin="auto"
      >
        <Typography width="100%" variant="h5" color="primary" fontWeight="bold">
          {localize("registerView.title")}
        </Typography>
        <RegisterProfileForm />
      </Grid>
    </Grid>
  );
};

export default Registerview;
