import { Box, Dialog, CircularProgress, Grid } from "@mui/material";
import React, { FC, useEffect, useContext, useState } from "react";
import { ModalContext } from "context/ModalContext";
import { DetailedJobBody } from "./DetailedJobBody";
import { LocaleContext } from "context/LocaleContext";
import { LoaderContext } from "context/LoaderContext";
import { DetailedJobHeaderMobile, DialogHeader } from "components";
import { getJob, Job } from "adapters";
import { NotificationContext } from "context/NotificationContext";
import { JobCardTopButtons } from "components/JobCard/JobCardTopButtons";
import { ActiveJobApplication } from "adapters/JobAdapter";
import { GTMContext } from "context/GTMContext";
import { UserContext } from "context/UserContext";
import Meta from "components/Meta";

interface DetailedJobModalProps {
  jobId: string;
  onClose?: () => void;
  setIsSaved?: (state: boolean) => void;
  setJobApplicationData?: (data: ActiveJobApplication) => void;
}

export const DetailedJobModal: FC<DetailedJobModalProps> = ({
  jobId,
  onClose,
  setIsSaved,
  setJobApplicationData,
}) => {
  const { hideModal, pushModal } = useContext(ModalContext);
  const { isAuthenticated } = useContext(UserContext);
  const { localize } = useContext(LocaleContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);

  const [currJob, setCurrJob] = useState<Job | null>(null);
  const [localSaveState, setLocalSavedState] = useState(false);

  const handleSave = (state: boolean) => {
    setIsSaved?.(state);
    setLocalSavedState(state);
  };

  const onHideModal = () => {
    onClose ? onClose() : hideModal();
  };

  const handleOpenApplicationForm = () => {
    if (!currJob) {
      return;
    }

    gtmAddToDataLayer({ action: "apply_now", label: currJob?.title }, "cta_click");
    pushModal("APPLICATION_MODAL", {
      job: currJob,
      setJobApplicationData,
    });
  };

  useEffect(() => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_JOB" });
    getJob(jobId)
      .then((res) => {
        setCurrJob(res.data);
      })
      .catch(() => {
        addMessage({ type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_JOB" });
      });
  }, []);

  useEffect(() => {
    setIsSaved?.(currJob?.is_saved || false);
    if (currJob) {
      gtmAddToDataLayer(
        { dl_page: currJob.title, dl_page_path: `/${currJob.title}/` },
        "dl_pageview"
      );
      gtmAddToDataLayer({ logged_in: isAuthenticated ? "true" : "false" }, "user_status");
    }
  }, [currJob]);

  return (
    <Dialog aria-labelledby="job-details" open={true} fullScreen>
      <Meta title={currJob?.title} desc={currJob?.pitch ?? ""} />
      <DialogHeader
        title={currJob?.title}
        titleVariant="h6"
        handleClose={onHideModal}
        returnText={localize("common.goBack")}
        lineClampTitle={1}
      />
      <Box paddingBottom="5rem">
        {isLoading("GET_JOB") || !currJob ? (
          <Grid container padding="1rem" justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <DetailedJobHeaderMobile
              job={currJob}
              handleOpenApplicationForm={handleOpenApplicationForm}
              buttonContainer={
                <JobCardTopButtons
                  job={currJob}
                  iconVariant="expanded"
                  setSaveJob={handleSave}
                  isSaved={localSaveState}
                />
              }
              isExpanded={true}
            />

            <DetailedJobBody
              job={currJob}
              handleOpenApplicationForm={handleOpenApplicationForm}
              setSaveJob={handleSave}
              isSaved={localSaveState}
            />
          </>
        )}
      </Box>
    </Dialog>
  );
};
