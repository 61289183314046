import { Typography, Grid, Collapse } from "@mui/material";
import { WIconOutlinedButton, WIcon, WIconTypes } from "components";
import { LoaderContext } from "context/LoaderContext";
import React, { FC, useContext, useState } from "react";
import { ModalContext } from "context/ModalContext";
import { deleteReferral, Referral } from "adapters";
import { LocaleContext } from "context/LocaleContext";
import { UserContext } from "context/UserContext";
import { ReferralCard } from "./ReferralCard";
import { NotificationContext } from "context/NotificationContext";
import { ReferralEditForm } from "./ReferralEditForm";
import { colors } from "styles/colors";

export const ReferralList: FC = () => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { showDialog, hideDialog } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);
  const { localize } = useContext(LocaleContext);
  const { user, dispatch } = useContext(UserContext);

  const [expandedReferrals, setExpandedReferrals] = useState<string[]>([]);

  const handleRemoveReferral = (referral: Referral) => {
    dispatchLoading({ type: "SET_LOADING", payload: "DELETE_REFERRAL" });
    deleteReferral(referral.id)
      .then(() => {
        addMessage({ content: "success.updateSuccessful", type: "success", duration: 1500 });
        const updatedReferralList = user.referrals.filter((userRef) => userRef.id !== referral.id);
        dispatch({ type: "UPDATE_USER", user: { referrals: updatedReferralList } });
        hideDialog();
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.removeReferral" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "DELETE_REFERRAL" });
      });
  };

  const handleExpandReferral = (referral: Referral) => {
    if (expandedReferrals.includes(referral.id)) {
      setExpandedReferrals(expandedReferrals.filter((expReferral) => expReferral !== referral.id));
    } else {
      setExpandedReferrals([...expandedReferrals, referral.id]);
    }
  };

  const showDeleteReferralDialog = (referral: Referral) => {
    showDialog("CONFIRM_DIALOG", {
      title: localize("dialog.confirmDeleteCvTitle"),
      subtitle: localize("dialog.confirmDeleteCvSubtitle"),
      cancelAction: () => hideDialog(),
      confirmAction: () => handleRemoveReferral(referral),
    });
  };

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.grey}`,
      }}
    >
      {!user.referrals.length ? (
        <Typography margin="1rem">{localize("profileView.noReferrals")}</Typography>
      ) : (
        user.referrals.map((referral, index) => {
          const isExpanded = expandedReferrals.includes(referral.id);
          return (
            <React.Fragment key={referral.id}>
              <ReferralCard
                isLast={index === user.referrals.length - 1}
                referral={referral}
                icons={
                  <Grid container gap="1rem" wrap="nowrap">
                    <WIconOutlinedButton onClick={() => handleExpandReferral(referral)}>
                      <WIcon icon={isExpanded ? WIconTypes.clear : WIconTypes.edit} />
                    </WIconOutlinedButton>
                    <WIconOutlinedButton onClick={() => showDeleteReferralDialog(referral)}>
                      <WIcon icon={WIconTypes.trash} />
                    </WIconOutlinedButton>
                  </Grid>
                }
              />
              <Collapse in={isExpanded}>
                <ReferralEditForm
                  referral={referral}
                  handleClose={() => handleExpandReferral(referral)}
                  handleSuccessUpdate={() => handleExpandReferral(referral)}
                />
              </Collapse>
            </React.Fragment>
          );
        })
      )}
    </Grid>
  );
};
