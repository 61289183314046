import { Box, Checkbox, Grid, MenuItem } from "@mui/material";
import { getSkillsList, Skill } from "adapters";
import { WSearchBar } from "components/WSearchBar";
import { LocaleContext } from "context/LocaleContext";
import { usePagination } from "hooks/usePagination";
import React, { FC, useContext, useEffect, useState } from "react";
import { Item } from "views/AgentViews/CandidatesView";
import { FilterTextField } from "../FilterTextField";

interface FilterUserSkillListProps {
  handleClick: (value: Item, key: "roles" | "departments" | "regions" | "skills") => void;
  selectedSkills: string[];
  filterLabel: string;
  disabled?: boolean;
}

export const FilterUserSkillList: FC<FilterUserSkillListProps> = ({
  handleClick,
  selectedSkills,
  filterLabel,
  disabled = false,
}) => {
  const { localize } = useContext(LocaleContext);
  const [searchText, setSearchText] = useState("");
  const { results, setLastElementRef, handleSearch } = usePagination<Skill>(
    getSkillsList,
    "error.listingSkills",
    "GET_SKILLS"
  );

  useEffect(() => {
    handleSearch(searchText ? { search: searchText } : {});
  }, [searchText]);

  return (
    <FilterTextField
      disabled={disabled}
      value={[]}
      focused={!!selectedSkills.length}
      label={selectedSkills.length ? selectedSkills.join(", ") : filterLabel}
      SelectProps={{
        multiple: true,
      }}
      sx={{
        marginTop: ".5rem",
      }}
    >
      <Grid container maxHeight="400px" direction="column" wrap="nowrap">
        <Box maxWidth="460px" padding="1rem">
          <WSearchBar
            label={localize("common.skillsSearch")}
            value={searchText}
            handleSearchClick={(newValue) => setSearchText(newValue.toLowerCase())}
          />
        </Box>
        {results.map((data, index) => (
          <React.Fragment key={data.name}>
            <MenuItem
              value={data.name}
              onClick={() => {
                handleClick(data, "skills");
              }}
            >
              <Checkbox
                checked={selectedSkills.includes(data.name)}
                sx={{
                  color: "text.primary",
                }}
              />
              {data.name}
            </MenuItem>
            {index === results.length - 1 && <div ref={setLastElementRef} />}
          </React.Fragment>
        ))}
      </Grid>
    </FilterTextField>
  );
};
