import { Grid, Link } from "@mui/material";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { colors } from "styles/colors";
import { FooterWaveSVG } from "components/assets/SVGComponents";
import { FooterLink } from "components/FooterLink";
import { INTEGRITY_LINK } from "views/Enums";

export interface FooterProps {
  setAnchorEl?: (element: null | HTMLElement) => void; //TOdO; Ugly fix, need to find a better way to handle this.
}

export const Footer: FC<FooterProps> = ({ setAnchorEl }) => {
  const { isDesktop } = useContext(BreakpointsContext);
  const { localize } = useContext(LocaleContext);

  return (
    <Grid container direction="column" alignItems="center" marginTop="auto" overflow="hidden">
      <Grid container position="sticky">
        <FooterWaveSVG />
        <Grid
          container
          direction="column"
          gap="1rem"
          justifyContent="flex-end"
          padding={isDesktop ? "4rem 3rem 1rem 3rem" : "3rem 1rem 1rem 1rem"}
          position="relative"
          color={colors.white}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/logo/logo_black.png`}
            alt="Wise Logo"
            width="150px"
            height="50px"
            style={{ objectFit: "contain" }}
          />

          <Grid container wrap="nowrap" gap="1rem" maxWidth="max-content">
            <Grid container direction="column">
              <FooterLink
                setAnchorEl={setAnchorEl && setAnchorEl}
                text={localize("common.aboutUs")}
                route="/contactUs"
              />
              <FooterLink
                setAnchorEl={setAnchorEl && setAnchorEl}
                text={localize("footer.howItWork")}
                route="/how-it-works"
              />
            </Grid>
            <Grid container direction="column" minWidth={isDesktop ? "max-content" : undefined}>
              {/* <FooterLink text={localize("routes.contact")} route="/contactUs" /> */}
              <Link
                href={INTEGRITY_LINK}
                target="_blank"
                underline="none"
                sx={{
                  cursor: "pointer",
                  fontSize: isDesktop ? "14px" : "12px",
                  fontWeight: "bold",
                }}
              >
                {localize("common.integrityText")}
              </Link>
              <FooterLink
                setAnchorEl={setAnchorEl && setAnchorEl}
                text={localize("footer.faq")}
                route="/faq"
              />
            </Grid>
            <Grid container direction="column" minWidth="max-content">
              <FooterLink
                setAnchorEl={setAnchorEl && setAnchorEl}
                text={localize("footer.consult")}
                route="/it-consultant"
              />
              <FooterLink
                setAnchorEl={setAnchorEl && setAnchorEl}
                text={localize("footer.consultManager")}
                route="/consulting-management"
              />
              <FooterLink
                setAnchorEl={setAnchorEl && setAnchorEl}
                text={localize("footer.consultSearch")}
                route="/consulting-recruitment"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
