import { TaskUser } from "adapters/TaskAdapter";
import { User } from "reducers/UserReducer";

export function copyToClipboard(paramName: string, value: string): void {
  if (!window.navigator.clipboard) {
    return;
  }
  const url = new URL(window.location.href);
  const params = new URLSearchParams();
  params.set(paramName, value);
  url.search = params.toString();
  window.navigator.clipboard.writeText(url.toString());
}

/**
 * Adds the value to the array if not present. If present, the value is removed
 */
export function toggleArrayItem<T>(arr: T[], value: T): T[] {
  const copiedArr = [...arr];
  const index = copiedArr.indexOf(value);
  if (index === -1) {
    copiedArr.push(value);
  } else {
    copiedArr.splice(index, 1);
  }
  return copiedArr;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const cleanLinkedInLink = (link: string): string => {
  if (!link) {
    return "";
  }
  return link.replace(/^(https?:\/\/|www)/, "");
};

export const prettifyFilename = (name: string): string => {
  return name.replace(/(_|-)/g, " ");
};

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getNameOrEmail = (user: User | TaskUser): string => {
  const { first_name, last_name, email } = user;
  if (first_name || last_name) {
    return `${first_name} ${last_name}`;
  }
  return email;
};
