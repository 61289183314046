import { styled } from "@mui/material";
import { TextField } from "@mui/material";
import { defaultStyles, colors } from "styles/colors";
type CustomProps = {
  light?: boolean;
  grey?: boolean;
};

export const WTextField = styled(TextField)<CustomProps>(
  ({ theme, light, grey }) => `
  & .MuiInputBase-input {
    color: ${light ? "white" : theme.palette.text.primary};
  }
  & label,
  & .MuiInputLabel-root.Mui-focused {
    color: ${light ? "white" : theme.palette.text.primary};
  }
  & .MuiInputBase-root,
  & .MuiFilledInput-root.Mui-focused {
    background-color: ${grey ? colors.grey : theme.palette.secondary.main};
    border-radius: ${defaultStyles.borderRadius};
  }
  & .MuiSelect-select:focus {
     background-color:  ${grey ? colors.grey : theme.palette.secondary.main};
  }
  & .MuiFilledInput-root:hover {
      background-color:  ${grey ? colors.grey : theme.palette.secondary.main};
      outline: solid 2px;
      outline-color: ${theme.palette.primary.dark};
  }
  & .MuiFilledInput-root:before {
    border-bottom: none;
  }
  & fieldset {
    border-color: ${colors.lightBorder};
  }
  :focus-within {
    & label {
      color: ${theme.palette.text.primary};
    }
  }
`
);

WTextField.defaultProps = {
  size: "small",
  variant: "outlined",
  type: "text",
};
