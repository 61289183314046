import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const CreateProfileTwoSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 745 748"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_341_6351)">
          <path
            d="M71.629 629.347L65.069 603.475C53.4379 598.546 41.538 594.276 29.426 590.687L28.598 602.711L25.24 589.464C10.219 585.17 0 583.281 0 583.281C0 583.281 13.8 635.77 42.754 675.898L76.488 681.824L50.281 685.603C53.9231 689.985 57.8371 694.134 62 698.025C104.115 737.117 151.024 755.053 166.773 738.085C182.522 721.117 161.148 675.673 119.033 636.585C105.977 624.466 89.576 614.741 73.158 607.085L71.629 629.347Z"
            fill="#F2F2F2"
          />
          <path
            d="M134.015 601.245L141.773 575.707C134.365 565.475 126.385 555.669 117.873 546.336L110.949 556.201L114.921 543.125C104.28 531.689 96.5089 524.79 96.5089 524.79C96.5089 524.79 81.1939 576.857 85.2339 626.174L111.049 648.684L86.6569 638.372C87.5104 644.007 88.7174 649.583 90.2709 655.066C106.117 710.3 137.002 749.901 159.254 743.517C181.506 737.133 186.7 687.182 170.854 631.948C165.942 614.825 156.928 598.022 146.831 582.983L134.015 601.245Z"
            fill="#F2F2F2"
          />
          <path
            d="M519.751 176.983H515.659V64.883C515.659 56.3624 513.981 47.9253 510.72 40.0534C507.46 32.1814 502.68 25.0287 496.655 19.0038C490.63 12.9788 483.478 8.19959 475.606 4.93892C467.734 1.67824 459.297 0 450.776 0H213.269C196.061 0 179.558 6.83587 167.39 19.0038C155.222 31.1717 148.386 47.675 148.386 64.883V679.883C148.386 688.404 150.064 696.841 153.325 704.713C156.586 712.585 161.365 719.737 167.39 725.762C173.415 731.787 180.568 736.566 188.44 739.827C196.312 743.088 204.749 744.766 213.269 744.766H450.776C467.984 744.766 484.487 737.93 496.655 725.762C508.823 713.594 515.658 697.091 515.658 679.883V256.778H519.75L519.751 176.983Z"
            fill="#E6E6E6"
          />
          <path
            d="M453.394 16.874H422.394C423.82 20.3696 424.364 24.1622 423.979 27.9177C423.593 31.6731 422.29 35.2762 420.184 38.4094C418.078 41.5426 415.234 44.1098 411.902 45.8848C408.571 47.6598 404.853 48.5882 401.078 48.588H265.013C261.238 48.5878 257.521 47.6593 254.189 45.8841C250.858 44.109 248.014 41.5418 245.908 38.4086C243.802 35.2754 242.5 31.6725 242.114 27.9172C241.729 24.1619 242.273 20.3695 243.699 16.874H214.743C201.892 16.874 189.568 21.979 180.481 31.0659C171.394 40.1528 166.289 52.4772 166.289 65.328V679.435C166.289 692.286 171.394 704.61 180.481 713.697C189.568 722.784 201.892 727.889 214.743 727.889H453.394C466.245 727.889 478.569 722.784 487.656 713.697C496.743 704.61 501.848 692.286 501.848 679.435V679.435V65.327C501.848 52.4764 496.743 40.1522 487.656 31.0655C478.569 21.9788 466.245 16.874 453.394 16.874V16.874Z"
            fill="white"
          />
          <path
            d="M303.658 261.503C303.657 257.211 304.787 252.994 306.934 249.277C309.081 245.56 312.169 242.474 315.888 240.329C312.171 238.183 307.955 237.052 303.663 237.052C299.371 237.051 295.154 238.181 291.437 240.327C287.719 242.472 284.632 245.559 282.486 249.276C280.34 252.993 279.21 257.21 279.21 261.502C279.21 265.794 280.34 270.011 282.486 273.728C284.632 277.445 287.719 280.531 291.437 282.677C295.154 284.823 299.371 285.952 303.663 285.952C307.955 285.951 312.171 284.821 315.888 282.674C312.17 280.53 309.082 277.445 306.935 273.728C304.788 270.012 303.658 265.795 303.658 261.503V261.503Z"
            fill="#CCCCCC"
          />
          <path
            d="M334.395 261.503C334.394 257.211 335.524 252.994 337.671 249.276C339.818 245.559 342.906 242.473 346.625 240.329C342.908 238.183 338.692 237.052 334.4 237.052C330.107 237.051 325.891 238.181 322.173 240.327C318.456 242.472 315.369 245.559 313.223 249.276C311.077 252.993 309.947 257.21 309.947 261.502C309.947 265.794 311.077 270.011 313.223 273.728C315.369 277.445 318.456 280.531 322.173 282.677C325.891 284.823 330.107 285.952 334.4 285.952C338.692 285.951 342.908 284.821 346.625 282.674C342.907 280.53 339.818 277.445 337.671 273.728C335.524 270.012 334.394 265.795 334.395 261.503V261.503Z"
            fill="#CCCCCC"
          />
          <path
            d="M364.434 285.952C377.937 285.952 388.884 275.006 388.884 261.502C388.884 247.999 377.937 237.052 364.434 237.052C350.931 237.052 339.984 247.999 339.984 261.502C339.984 275.006 350.931 285.952 364.434 285.952Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M405.296 337.869H262.796C261.44 337.867 260.139 337.328 259.18 336.368C258.22 335.409 257.681 334.108 257.679 332.752V190.252C257.681 188.895 258.22 187.595 259.18 186.635C260.139 185.676 261.44 185.136 262.796 185.135H405.296C406.653 185.136 407.953 185.676 408.913 186.635C409.872 187.595 410.412 188.895 410.413 190.252V332.752C410.412 334.108 409.872 335.409 408.913 336.368C407.953 337.328 406.653 337.867 405.296 337.869V337.869ZM262.796 187.183C261.982 187.184 261.202 187.507 260.626 188.083C260.051 188.658 259.727 189.439 259.726 190.253V332.753C259.727 333.567 260.051 334.347 260.626 334.923C261.202 335.498 261.982 335.822 262.796 335.823H405.296C406.11 335.822 406.891 335.498 407.466 334.923C408.042 334.347 408.365 333.567 408.366 332.753V190.253C408.365 189.439 408.042 188.658 407.466 188.083C406.891 187.507 406.11 187.184 405.296 187.183H262.796Z"
            fill="#CCCCCC"
          />
          <path d="M437.114 447.102H218.562V449.149H437.114V447.102Z" fill="#CCCCCC" />
          <path
            d="M225.464 434.322C229.276 434.322 232.366 431.232 232.366 427.42C232.366 423.608 229.276 420.518 225.464 420.518C221.652 420.518 218.562 423.608 218.562 427.42C218.562 431.232 221.652 434.322 225.464 434.322Z"
            fill={colors.mainSVGColor}
          />
          <path d="M437.114 516.118H218.562V518.165H437.114V516.118Z" fill="#CCCCCC" />
          <path
            d="M225.464 503.339C229.276 503.339 232.366 500.249 232.366 496.437C232.366 492.625 229.276 489.535 225.464 489.535C221.652 489.535 218.562 492.625 218.562 496.437C218.562 500.249 221.652 503.339 225.464 503.339Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M433.114 594.71H364.046C362.853 594.709 361.709 594.235 360.865 593.391C360.022 592.547 359.547 591.404 359.546 590.21V566.002C359.547 564.809 360.022 563.666 360.865 562.822C361.709 561.978 362.853 561.504 364.046 561.502H433.114C434.307 561.504 435.451 561.978 436.295 562.822C437.138 563.666 437.613 564.809 437.614 566.002V590.21C437.613 591.404 437.138 592.547 436.295 593.391C435.451 594.235 434.307 594.709 433.114 594.71V594.71Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M247.978 434.322C251.79 434.322 254.88 431.232 254.88 427.42C254.88 423.608 251.79 420.518 247.978 420.518C244.166 420.518 241.076 423.608 241.076 427.42C241.076 431.232 244.166 434.322 247.978 434.322Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M270.492 434.322C274.304 434.322 277.394 431.232 277.394 427.42C277.394 423.608 274.304 420.518 270.492 420.518C266.68 420.518 263.59 423.608 263.59 427.42C263.59 431.232 266.68 434.322 270.492 434.322Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M247.978 503.339C251.79 503.339 254.88 500.249 254.88 496.437C254.88 492.625 251.79 489.535 247.978 489.535C244.166 489.535 241.076 492.625 241.076 496.437C241.076 500.249 244.166 503.339 247.978 503.339Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M270.492 503.339C274.304 503.339 277.394 500.249 277.394 496.437C277.394 492.625 274.304 489.535 270.492 489.535C266.68 489.535 263.59 492.625 263.59 496.437C263.59 500.249 266.68 503.339 270.492 503.339Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M742.066 747.077H24.0799C22.5429 747.077 21.2979 746.531 21.2979 745.859C21.2979 745.187 22.5429 744.64 24.0799 744.64H742.066C743.602 744.64 744.848 745.186 744.848 745.859C744.848 746.532 743.602 747.077 742.066 747.077Z"
            fill="#3F3D56"
          />
          <path
            d="M564.676 489.461C565.167 489.686 565.639 489.949 566.087 490.249L610.939 471.105L612.54 459.29L630.462 459.181L629.403 486.279L570.203 501.935C570.079 502.346 569.929 502.75 569.756 503.143C568.9 504.969 567.524 506.502 565.799 507.549C564.075 508.595 562.08 509.109 560.065 509.026C558.05 508.942 556.104 508.265 554.473 507.08C552.841 505.894 551.596 504.253 550.894 502.362C550.192 500.471 550.064 498.415 550.527 496.452C550.99 494.489 552.022 492.706 553.495 491.328C554.967 489.95 556.814 489.037 558.803 488.705C560.793 488.373 562.836 488.636 564.676 489.461V489.461Z"
            fill="#FFB8B8"
          />
          <path
            d="M636.98 735.021H624.72L618.888 687.733L636.982 687.734L636.98 735.021Z"
            fill="#FFB8B8"
          />
          <path
            d="M615.963 731.518H639.607V746.404H601.076C601.076 742.456 602.645 738.67 605.436 735.878C608.228 733.086 612.015 731.518 615.963 731.518Z"
            fill="#2F2E41"
          />
          <path
            d="M684.66 731.557L672.459 732.759L662.018 686.271L680.025 684.497L684.66 731.557Z"
            fill="#FFB8B8"
          />
          <path
            d="M663.401 730.131L686.931 727.813L688.39 742.629L650.045 746.406C649.854 744.46 650.047 742.496 650.614 740.625C651.182 738.754 652.112 737.013 653.353 735.502C654.593 733.991 656.119 732.739 657.843 731.817C659.567 730.896 661.456 730.323 663.401 730.131Z"
            fill="#2F2E41"
          />
          <path
            d="M640.393 409.135C653.957 409.135 664.954 398.138 664.954 384.574C664.954 371.009 653.957 360.013 640.393 360.013C626.828 360.013 615.832 371.009 615.832 384.574C615.832 398.138 626.828 409.135 640.393 409.135Z"
            fill="#FFB8B8"
          />
          <path
            d="M621.98 725.458C620.927 725.461 619.906 725.091 619.098 724.415C618.291 723.739 617.748 722.799 617.565 721.761C611.22 686.535 590.478 571.356 589.981 568.165C589.971 568.092 589.965 568.018 589.966 567.944V559.356C589.965 559.043 590.063 558.738 590.245 558.484L592.985 554.646C593.116 554.461 593.286 554.309 593.485 554.2C593.683 554.092 593.903 554.031 594.129 554.021C609.751 553.288 660.913 551.142 663.385 554.23C665.867 557.333 664.99 566.736 664.789 568.59L664.799 568.783L687.784 715.779C687.964 716.951 687.675 718.147 686.98 719.108C686.285 720.069 685.239 720.717 684.069 720.913L669.713 723.279C668.644 723.45 667.548 723.233 666.626 722.665C665.704 722.097 665.016 721.218 664.688 720.186C660.247 705.997 645.358 658.268 640.198 639.799C640.166 639.682 640.092 639.581 639.991 639.514C639.889 639.448 639.767 639.42 639.647 639.437C639.527 639.454 639.417 639.514 639.338 639.607C639.259 639.699 639.216 639.816 639.218 639.938C639.476 657.543 640.099 702.461 640.314 717.975L640.337 719.646C640.346 720.777 639.931 721.871 639.174 722.71C638.416 723.55 637.37 724.075 636.244 724.182L622.4 725.44C622.26 725.452 622.119 725.458 621.98 725.458Z"
            fill="#2F2E41"
          />
          <path
            d="M624.805 418.792C620.519 421.34 617.954 426.023 616.482 430.787C613.757 439.608 612.117 448.729 611.598 457.947L610.042 485.547L590.787 558.717C607.476 572.838 617.102 569.628 639.568 558.078C662.033 546.528 664.6 561.929 664.6 561.929L669.092 499.671L675.511 431.638C674.07 429.904 672.439 428.336 670.649 426.964C664.716 422.351 657.814 419.145 650.462 417.586C643.11 416.028 635.501 416.159 628.207 417.969L624.805 418.792Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M618.55 504.239C619.068 504.434 619.57 504.669 620.051 504.943L664.399 482.746L665.135 470.72L683.429 469.459L684.41 496.872L625.144 516.471C624.66 518.547 623.555 520.426 621.978 521.858C620.4 523.291 618.423 524.209 616.31 524.491C614.198 524.773 612.049 524.405 610.151 523.437C608.253 522.468 606.694 520.944 605.683 519.068C604.672 517.192 604.256 515.052 604.49 512.934C604.724 510.815 605.598 508.819 606.995 507.209C608.392 505.599 610.245 504.453 612.31 503.922C614.374 503.392 616.551 503.502 618.55 504.239Z"
            fill="#FFB8B8"
          />
          <path
            d="M675.189 431.95C686.101 435.801 688.023 477.524 688.023 477.524C675.186 470.463 659.782 482.017 659.782 482.017C659.782 482.017 656.572 471.105 652.721 456.985C651.558 453.013 651.419 448.811 652.317 444.771C653.215 440.731 655.12 436.984 657.856 433.878C657.856 433.878 664.278 428.096 675.189 431.95Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M662.415 391.069C659.355 388.62 655.18 393.071 655.18 393.071L652.732 371.037C652.732 371.037 637.431 372.87 627.638 370.425C617.845 367.981 616.315 379.301 616.315 379.301C615.811 374.728 615.708 370.12 616.01 365.53C616.621 360.021 624.578 354.513 638.655 350.84C652.731 347.168 660.076 363.081 660.076 363.081C669.869 367.977 665.475 393.517 662.415 391.069Z"
            fill="#2F2E41"
          />
        </g>
        <defs>
          <clipPath id="clip0_341_6351">
            <rect width="744.848" height="747.077" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
