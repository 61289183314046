import React, { FC, useContext } from "react";
import { BreakpointsContext } from "context/BreakpointContext";
import { colors } from "styles/colors";

export const FooterWaveSVG: FC = () => {
  const { isDesktop } = useContext(BreakpointsContext);

  if (isDesktop) {
    return (
      <svg
        width="1420px"
        height="100%"
        style={{ position: "absolute", minWidth: "100%" }}
        viewBox="0 0 1420 338"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_310_6140)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M701.902 114.089C905.171 126.31 1102.34 63.1782 1302.96 87.1534C1510.24 111.923 1720.21 137.896 1868 222.5C2015.35 306.851 2046.25 457.968 2134.35 563.079C2221.79 667.399 2384.32 762.996 2384.31 875.6C2384.3 988.934 2206.71 1073.75 2134.28 1178.35C2065.46 1277.73 2099.86 1405.23 1964.96 1481.17C1828.37 1558.07 1570.9 1527.46 1407.58 1587.45C1225.62 1654.29 1174.79 1807.99 966.189 1848.06C766.923 1886.34 526.99 1847.09 331.787 1794.12C141.002 1742.34 25.5803 1635.79 -123.431 1552.35C-251.556 1480.6 -362.819 1403.8 -492.175 1332.7C-642.116 1250.29 -935.072 1209.12 -953.557 1096.07C-973.833 972.059 -615.164 918.239 -579.837 796.576C-546.616 682.169 -866.91 562.11 -769.03 460.581C-671.703 359.627 -314.798 444.52 -164.316 365.568C2.30617 278.148 -122.66 73.3635 82.5697 13.7815C268.289 -40.1363 487.51 101.2 701.902 114.089Z"
            fill={colors.main}
          />
        </g>
        <defs>
          <clipPath id="clip0_310_6140">
            <rect width="100%" height="397.091" fill="white" transform="translate(0 0.45459)" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width="100%"
      height="300"
      style={{ position: "absolute", minWidth: "100%" }}
      viewBox="0 0 400 166"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_360_6374)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M197.04 33.1067C256.981 36.7104 315.122 18.0939 374.283 25.1637C435.405 32.4678 495.634 49.0488 539.216 73.9971C582.667 98.8707 593.467 134.511 619.446 165.506C645.229 196.269 693.158 224.459 693.155 257.664C693.151 291.084 640.784 316.096 619.425 346.939C599.131 376.245 609.274 413.843 569.494 436.238C529.216 458.913 453.293 449.886 405.134 467.576C351.475 487.288 336.486 532.61 274.974 544.427C216.214 555.715 145.461 544.141 87.8993 528.519C31.64 513.251 -2.39607 481.832 -46.3369 457.226C-84.1189 436.068 -116.929 413.422 -155.074 392.455C-199.289 368.152 -285.677 356.012 -291.128 322.675C-297.107 286.108 -191.341 270.237 -180.924 234.361C-171.127 200.624 -265.577 165.221 -236.714 135.282C-208.013 105.512 -102.768 130.545 -58.3934 107.264C-9.25922 81.4849 -46.1098 21.0973 14.4092 3.52757C69.1748 -12.3719 133.819 29.3058 197.04 33.1067Z"
          fill="#70AEAD"
        />
      </g>
      <defs>
        <clipPath id="clip0_360_6374">
          <rect width="100%" height="325.891" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const WaveSVG: FC = () => {
  const { isDesktop } = useContext(BreakpointsContext);
  if (isDesktop) {
    return (
      <svg
        width="100%"
        height="100%"
        style={{ position: "absolute", minWidth: "100%" }}
        viewBox="0 0 1520 338"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M714.118 53.0002C636.618 167.5 777.235 172.068 714.118 440.135C651.001 708.203 671.032 790.544 608.95 919.751C547.054 1048.57 447.799 1086.64 364.885 1166.97C282.594 1246.69 212.176 1388.95 117.635 1395.75C22.4812 1402.61 -59.484 1258.64 -151.686 1204.17C-239.29 1152.4 -344.257 1189 -416.187 1080.34C-489.017 970.321 -478.906 752.304 -539.164 618.822C-606.304 470.093 -738.42 436.724 -784.696 264.016C-828.901 99.0349 -810.478 -104.785 -777.82 -271.881C-745.902 -435.195 -663.438 -538.552 -602.402 -668.712C-549.921 -780.627 -492.182 -878.692 -440.321 -991.602C-380.206 -1122.48 -363.378 -1370.93 -269.583 -1393.3C-166.698 -1417.83 -99.7938 -1119.96 4.4911 -1097.67C102.556 -1076.7 183.961 -1352.88 275.129 -1276.85C365.782 -1201.25 316.119 -896.463 391.518 -774.902C475.003 -640.303 639.369 -757.623 701.82 -588.924C758.334 -436.263 828.618 -88.9996 714.118 53.0002Z"
          fill="#70AEAD"
        />
      </svg>
    );
  }

  return (
    <svg
      width="100%"
      height="330px"
      style={{ position: "absolute", minWidth: "100%" }}
      viewBox="0 0 400 220"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_360_6374)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M197.04 33.1067C256.981 36.7104 315.122 18.0939 374.283 25.1637C435.405 32.4678 495.634 49.0488 539.216 73.9971C582.667 98.8707 593.467 134.511 619.446 165.506C645.229 196.269 693.158 224.459 693.155 257.664C693.151 291.084 640.784 316.096 619.425 346.939C599.131 376.245 609.274 413.843 569.494 436.238C529.216 458.913 453.293 449.886 405.134 467.576C351.475 487.288 336.486 532.61 274.974 544.427C216.214 555.715 145.461 544.141 87.8993 528.519C31.64 513.251 -2.39607 481.832 -46.3369 457.226C-84.1189 436.068 -116.929 413.422 -155.074 392.455C-199.289 368.152 -285.677 356.012 -291.128 322.675C-297.107 286.108 -191.341 270.237 -180.924 234.361C-171.127 200.624 -265.577 165.221 -236.714 135.282C-208.013 105.512 -102.768 130.545 -58.3934 107.264C-9.25922 81.4849 -46.1098 21.0973 14.4092 3.52757C69.1748 -12.3719 133.819 29.3058 197.04 33.1067Z"
          fill="#70AEAD"
        />
      </g>
      <defs>
        <clipPath id="clip0_360_6374">
          <rect width="100%" height="325.891" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const WaveMobileSVG: FC = () => {
  return (
    <svg
      width="100%"
      height="263"
      style={{ position: "absolute", minWidth: "100%" }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_360_6374)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M159.015 26.7177C207.388 29.626 254.309 14.6021 302.053 20.3076C351.379 26.2021 399.985 39.5833 435.157 59.7169C470.223 79.7904 478.938 108.553 499.904 133.567C520.711 158.392 559.391 181.142 559.388 207.939C559.385 234.91 517.124 255.095 499.887 279.986C483.509 303.636 491.695 333.979 459.592 352.051C427.087 370.351 365.816 363.066 326.951 377.342C283.647 393.25 271.55 429.825 221.909 439.362C174.488 448.472 117.39 439.131 70.9363 426.524C25.5341 414.202 -1.93365 388.847 -37.3947 368.989C-67.8854 351.914 -94.3635 333.639 -125.147 316.718C-160.829 297.105 -230.546 287.308 -234.945 260.405C-239.77 230.894 -154.416 218.086 -146.009 189.133C-138.103 161.907 -214.325 133.336 -191.032 109.175C-167.87 85.1498 -82.9356 105.352 -47.1245 86.5636C-7.47233 65.7597 -37.2114 17.0259 11.6285 2.84681C55.8253 -9.98433 107.995 23.6503 159.015 26.7177Z"
          fill="#70AEAD"
        />
      </g>
      <defs>
        <clipPath id="clip0_360_6374">
          <rect width="414" height="263" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BubbleBottomSVG: FC = () => {
  return (
    <svg
      width="130"
      height="33"
      viewBox="0 0 130 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.2483 0C65.2483 0 98.4396 33 65.2483 33C32.0569 33 39.5685 0 7.0406 0C-25.4873 0 65.2483 0 65.2483 0Z"
        fill={colors.main}
      />
      <path
        d="M64.7517 0C64.7517 0 31.5604 33 64.7517 33C97.9431 33 90.4315 0 122.959 0C155.487 0 64.7517 0 64.7517 0Z"
        fill={colors.main}
      />
      <path
        d="M57.4619 11.1931L64.924 18.6552L72.386 11.1931"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

interface BackgroundWaveSVGProps {
  headerOffset: number;
}

export const BackgroundWaveSVG: FC<BackgroundWaveSVGProps> = ({ headerOffset }) => {
  return (
    <svg
      id="wave"
      style={{
        pointerEvents: "none",
        position: "absolute",
        top: headerOffset,
        transform: "rotate(180deg)",
      }}
      viewBox="0 0 1440 490"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ transform: "translate(0, 0px)", opacity: 1 }}
        fill="#70AEAD"
        fillOpacity="0.07"
        d="M0,147L60,171.5C120,196,240,245,360,245C480,245,600,196,720,171.5C840,147,960,147,1080,155.2C1200,163,1320,180,1440,163.3C1560,147,1680,98,1800,98C1920,98,2040,147,2160,204.2C2280,261,2400,327,2520,334.8C2640,343,2760,294,2880,228.7C3000,163,3120,82,3240,65.3C3360,49,3480,98,3600,171.5C3720,245,3840,343,3960,326.7C4080,310,4200,180,4320,122.5C4440,65,4560,82,4680,130.7C4800,180,4920,261,5040,285.8C5160,310,5280,278,5400,220.5C5520,163,5640,82,5760,114.3C5880,147,6000,294,6120,367.5C6240,441,6360,441,6480,383.8C6600,327,6720,212,6840,204.2C6960,196,7080,294,7200,285.8C7320,278,7440,163,7560,171.5C7680,180,7800,310,7920,359.3C8040,408,8160,376,8280,310.3C8400,245,8520,147,8580,98L8640,49L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z"
      ></path>
    </svg>
  );
};
