import { Box, Grid, Typography } from "@mui/material";
import { ExpandableListItem, WButton, WIcon, WIconTypes } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { RegionWithLocations } from "adapters";
import { JobFilterItem } from "components/dialogs/FiltersDialog";

export type Regionfilters = {
  regions: JobFilterItem[];
};

interface RegionExpandableListProps {
  expand: boolean;
  itemsToShow: number;
  filters: Regionfilters;
  allResults: RegionWithLocations[];
  handleItemClick: (key: keyof Regionfilters, item: JobFilterItem) => void;
  handleExpandClick?: () => void;
  lastElementRef?: (el: Element | null) => void;
}

export const RegionExpandableList: FC<RegionExpandableListProps> = ({
  expand,
  itemsToShow,
  filters,
  allResults,
  handleItemClick,
  handleExpandClick,
  lastElementRef,
}) => {
  const { localize } = useContext(LocaleContext);

  return (
    <Box>
      <Typography variant="h6" marginLeft="1rem">
        {localize("common.region")}
      </Typography>

      {allResults.slice(0, expand ? itemsToShow : undefined).map((region, index) => (
        <Grid container direction="column" paddingLeft="1rem" key={region.id}>
          <ExpandableListItem
            disableExpand
            label={region.name}
            value={region.id}
            isChecked={filters.regions.some((filterRegion) => filterRegion.value === region.id)}
            isExpanded={filters.regions.some((filterRegion) => filterRegion.value === region.id)}
            handleClick={(value) => handleItemClick("regions", value)}
            expandableListData={region.locations.map((location) => {
              return {
                label: location.city,
                value: location.id,
                isChecked: false,
                isDisabled: true,
              };
            })}
          />
          {lastElementRef && index === allResults.length - 1 && <div ref={lastElementRef} />}
        </Grid>
      ))}
      {expand && allResults.length > itemsToShow && (
        <WButton
          fullWidth
          endIcon={<WIcon icon={WIconTypes.arrowDown} />}
          onClick={handleExpandClick}
        >
          {localize("searchbar.showMoreRegions")}
        </WButton>
      )}
    </Box>
  );
};
