import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  SentTaskData,
  Task,
  createTask,
  deleteTask,
  getTaskList,
  updateTask,
} from "adapters/TaskAdapter";
import { Draggable } from "components/DragAndDrop/Draggable";
import { DropArea } from "components/DragAndDrop/DropArea";
import { TaskCard } from "components/Tasks/TaskCard";
import { usePagination } from "hooks/usePagination";
import { DragAndDropHandler, isDragging } from "components/DragAndDrop/DragAndDropHandler";
import { UserContext } from "context/UserContext";
import { Grid, Skeleton } from "@mui/material";
import { WButton } from "components/styledComponents/WButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { LocaleContext } from "context/LocaleContext";
import { LoaderContext } from "context/LoaderContext";
import { defaultStyles } from "styles/colors";
import { WTypography } from "components/WTypography";
import { NotificationContext } from "context/NotificationContext";

const DEFAULT_ORDER_STEP = 100000;
const INTERVAL_TIME = 60 * 1000;

interface Props {
  isOpen?: boolean;
  filterUserId?: string;
  filterJobId?: string;
  dragGroupName?: string;
}

export const TasksColumn: FC<Props> = ({
  isOpen = true,
  filterUserId,
  filterJobId,
  dragGroupName = "tasks",
}) => {
  const { user } = useContext(UserContext);
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [editTaskId, setEditTaskId] = useState("");
  const [editTaskData, setEditTaskData] = useState<Task>({} as Task);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const isEditing = editTaskId !== "";

  const sendUpdateTask = (id: string, order: number) => {
    updateTask(id, {
      order_no: order,
    });
  };

  const { results: taskResults, handleSearch: fetchAllTasks } = usePagination<Task>(
    getTaskList,
    "error.fetchTaskList",
    "GET_TASK_LIST",
    {},
    true
  );

  useEffect(() => {
    if (isEditing || !isOpen) {
      return;
    }

    const params = {
      agent: showAll ? undefined : user.id,
      status: showCompleted ? "done" : "not_started",
      page_size: showCompleted ? 20 : 1000,
      ordering: showCompleted ? "-done_date" : undefined,
    };
    fetchAllTasks(params);

    const intervalHandle = setInterval(async () => {
      if (isDragging) {
        return;
      }
      fetchAllTasks(params);
    }, INTERVAL_TIME);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [showAll, showCompleted, isEditing, isOpen]);

  useEffect(() => {
    const results = taskResults
      .filter(({ users }) => {
        if (!filterUserId) {
          return true;
        }

        return users.find(({ id }) => id === filterUserId);
      })
      .filter(({ jobs }) => {
        if (!filterJobId) {
          return true;
        }

        return jobs.find(({ id }) => id === filterJobId);
      });
    if (showCompleted) {
      setTasks(results);
      return;
    }
    setTasks(
      results
        .map((task, index) => ({
          ...task,
          order_no: task.order_no ?? (index + 1) * DEFAULT_ORDER_STEP,
        }))
        .sort((a, b) => a.order_no - b.order_no)
    );
  }, [taskResults, showCompleted]);

  const onDrop = useCallback(
    async (elementId: string, areaId: string, neighburIds: (string | null)[]): Promise<void> => {
      const task = tasks.find((task) => task.id === elementId);
      if (!task) {
        return;
      }
      const neighburs: Task[] = [];
      let newOrder = -1;
      neighburIds.forEach((id) => {
        if (!id) {
          return;
        }

        const task = tasks.find((task) => task.id === id);
        if (task) {
          neighburs.push(task);
        }
      });

      if (neighburs.length === 0) {
        newOrder = DEFAULT_ORDER_STEP;
      } else if (neighburs.length === 2) {
        newOrder = Math.floor((neighburs[0].order_no + neighburs[1].order_no) / 2);
      } else if (neighburIds[0] === null) {
        newOrder = Math.floor(neighburs[0].order_no / 2);
      } else if (neighburIds[1] === null) {
        newOrder = neighburs[0].order_no + DEFAULT_ORDER_STEP;
      }

      sendUpdateTask(elementId, newOrder);
      let taskList = [...tasks];
      taskList.splice(
        tasks.findIndex((j) => j.id === elementId),
        1
      );
      taskList = [...taskList, { ...task, order_no: newOrder }].sort(
        (a, b) => a.order_no - b.order_no
      );
      setTasks(taskList);
    },
    [tasks]
  );

  useEffect(() => {
    const dragHandler = new DragAndDropHandler(dragGroupName, onDrop, false);

    return () => {
      dragHandler.destroy();
    };
  }, [onDrop]);

  const onFinishEdit = useCallback(
    async (task: Task, index: number, doSave: boolean, data?: Task) => {
      if (doSave) {
        data = data ?? editTaskData;
        const updatedTaskData: SentTaskData = {} as SentTaskData;
        Object.entries(data).forEach(([key, value]) => {
          if (key === "agent") {
            updatedTaskData[key] = !value ? user.id : value.id;
          } else if (!Array.isArray(value)) {
            updatedTaskData[key] = value ?? null;
          } else {
            updatedTaskData[key] = (value as { id: string }[]).map(({ id }) => id);
          }
        });
        if (editTaskId === "newTask") {
          const created = await createTask({
            ...updatedTaskData,
            agent: updatedTaskData.agent || user.id,
            order_no:
              (tasks.at(-1)?.order_no || tasks.length * DEFAULT_ORDER_STEP) + DEFAULT_ORDER_STEP,
          });
          const newTask = {
            ...task,
            ...data,
            agent: data.agent || {
              id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
            },
            id: created.data.id,
          };
          tasks[index] = newTask;
          addMessage({ type: "success", content: "success.taskCreated" });
          setTimeout(() => {
            const element = document.getElementById(newTask.id);
            element?.scrollIntoView({ block: "center", behavior: "smooth" });
          }, 500);
        } else {
          updateTask(task.id, updatedTaskData);
          tasks[index] = { ...task, ...data };
        }
        setTasks([...tasks]);
      } else if (editTaskId === "newTask") {
        setTasks([...tasks.slice(1)]);
      }
      setEditTaskId("");
      setEditTaskData({} as Task);
    },
    [tasks, setTasks, editTaskData, editTaskId]
  );

  return (
    <>
      {!isEditing ? (
        <Grid container gap="5px" padding="12px" paddingBottom="0">
          <Grid item>
            <WButton
              variant={showCompleted ? "contained" : "outlined"}
              size="small"
              onClick={() => setShowCompleted(!showCompleted)}
            >
              {localize("tasks.showCompleted")}
            </WButton>
          </Grid>
          <Grid item>
            <WButton
              variant={showAll ? "contained" : "outlined"}
              size="small"
              onClick={() => setShowAll(!showAll)}
            >
              {localize("tasks.showAll")}
            </WButton>
          </Grid>
          <Grid item>
            <WButton
              variant="outlined"
              size="small"
              onClick={() => {
                const newTask: Task = {
                  id: "newTask",
                  title: "",
                  status: "not_started",
                  order_no: 0,
                  job_applications: [],
                  jobs: [],
                  users: [],
                  due_date: null,
                  done_date: null,
                  agent: {
                    id: user.id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: "",
                  },
                };
                setTasks([newTask, ...tasks]);
                setEditTaskId("newTask");
                setEditTaskData({} as Task);
              }}
            >
              <WIcon icon={WIconTypes.add} />
            </WButton>
          </Grid>
          <Grid item marginLeft="auto" display="flex" alignItems="center">
            <WTypography variant="body2">
              {localize("tasks.count")}: {tasks.length}
            </WTypography>
          </Grid>
        </Grid>
      ) : null}
      <DropArea
        id="1"
        group={dragGroupName}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "12px",
          overflowX: "hidden",
          height: "max-content",
          flexGrow: 1,
        }}
      >
        {tasks.length === 0 && isLoading("GET_TASK_LIST")
          ? Array.from(Array(4)).map((_, index) => (
              <Skeleton
                key={`task_skeleton_${index}`}
                variant="rectangular"
                width="100%"
                height="150px"
                sx={{ borderRadius: defaultStyles.borderRadius }}
              />
            ))
          : null}

        {tasks.map((task, index) => {
          if (
            (showCompleted && task.status !== "done") ||
            (!showCompleted && task.status !== "not_started")
          ) {
            return null;
          }

          return (
            <Draggable key={`draggable_${task.id}`} locked={isEditing || task.status === "done"}>
              <TaskCard
                style={{ pointerEvents: isEditing && task.id !== editTaskId ? "none" : "auto" }}
                task={task.id === editTaskId ? { ...task, ...editTaskData } : task}
                isEditing={task.id === editTaskId}
                onStartEdit={() => {
                  setEditTaskId(task.id);
                  setEditTaskData({} as Task);
                }}
                onEdit={(updatedData: Partial<Task>) => {
                  setEditTaskData({ ...editTaskData, ...updatedData });
                }}
                onFinishEdit={(doSave, data) => onFinishEdit(task, index, doSave, data)}
                onDelete={() => {
                  tasks.splice(index, 1);
                  setTasks([...tasks]);
                  addMessage({ type: "success", content: "success.taskDeleted" });
                  deleteTask(task.id);
                }}
              />
            </Draggable>
          );
        })}
      </DropArea>
    </>
  );
};
