import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export interface Group {
  id: string;
  name_sv: string;
  name_en: string;
  order_no: number;
  users: GroupUser[] | string[];
  created: string;
}

export interface GroupUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  profile_picture: string;
  rating: number;
}

export type GroupActions = "GET_GROUP" | "GET_GROUP_LIST" | "UPDATE_GROUP" | "CREATE_GROUP";

export const getGroup = async (taskId: string): Promise<AxiosResponse<Group>> => {
  return API.get(`/user-groups/${taskId}/`);
};

export const getGroupList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<Group>>> => {
  return API.get("/user-groups/", { params });
};

export const updateGroup = async (
  groupId: string,
  data: Partial<Group>
): Promise<AxiosResponse<Group>> => {
  return API.patch(`/user-groups/${groupId}/`, data);
};

export const createGroup = async (data: {
  name_sv: string;
  name_en: string;
}): Promise<AxiosResponse<Group>> => {
  return API.post("/user-groups/", data);
};

export const deleteGroup = async (groupId: string): Promise<void> => {
  return API.delete(`/user-groups/${groupId}/`);
};
