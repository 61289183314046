import React, { FC, useContext, useMemo } from "react";
import { Job, getJobList, JobApplication, getJobApplicationList, getUserList } from "adapters";
import { Task } from "adapters/TaskAdapter";
import { SearchDropdown, SearchDropdownProps } from "components/SearchDropdown";
import { usePagination } from "hooks/usePagination";
import { User } from "reducers/UserReducer";
import { WButton } from "components/styledComponents/WButton";
import { LocaleContext } from "context/LocaleContext";
import { Grid } from "@mui/material";
import { WIconTypes } from "components/WIcon";
import { getNameOrEmail } from "utils/generalUtils";
import { TaskDrawerWidth } from "components/KanbanView/TasksDrawer";

interface Props {
  task: Task;
  addingTag: string;
  setAddingTag: (value: string) => void;
  onEdit: (updatedData: Partial<Task>) => void;
}

export const AddTaskTag: FC<Props> = ({ task, addingTag, setAddingTag, onEdit }) => {
  const { localize } = useContext(LocaleContext);

  const {
    handleSearch: handleJobSearch,
    results: searchedJobs,
    setLastElementRef: lastJobsRef,
  } = usePagination<Job>(
    getJobList,
    "error.generalError",
    "GET_PAGINATION_RESULTS",
    { status: "active", page_size: 7 },
    true
  );
  const {
    handleSearch: handleApplicationSearch,
    results: searchedApplications,
    setLastElementRef: lastApplicationsRef,
  } = usePagination<JobApplication>(
    getJobApplicationList,
    "error.generalError",
    "GET_PAGINATION_RESULTS",
    { page_size: 7 },
    true
  );
  const {
    handleSearch: handleUserSearch,
    results: searchedUsers,
    setLastElementRef: lastUsersRef,
  } = usePagination<User>(
    getUserList,
    "error.generalError",
    "GET_PAGINATION_RESULTS",
    { page_size: 7 },
    true
  );

  const { jobs, job_applications, users } = task;

  const addTagOptions = useMemo(() => {
    const addTagOptions: Record<string, SearchDropdownProps> = {
      jobs: {
        items: searchedJobs.map(({ id, title }) => ({
          value: id,
          icon: WIconTypes.job,
          text: title,
          disabled: !!jobs.find((j) => j.id === id),
        })),
        onSearch: (text: string) => handleJobSearch({ search: text }),
        onSelect: (value: string) => {
          const job = searchedJobs.find((j) => j.id === value);
          if (!job) {
            return;
          }
          onEdit({ jobs: [...jobs, { id: job.id, title: job.title }] });
          setAddingTag("");
        },
        setLastElementRef: lastJobsRef,
        loaderAction: "GET_PAGINATION_RESULTS",
        label: localize("tasks.searchAssignments"),
      },
      job_applications: {
        items: searchedApplications.map(({ id, job: { title }, user }) => ({
          value: id,
          icon: WIconTypes.application,
          text: `${getNameOrEmail(user)}: ${title}`,
          disabled: !!job_applications.find((a) => a.id === id),
        })),
        onSearch: (text: string) => handleApplicationSearch({ search: text }),
        onSelect: (value: string) => {
          const application = searchedApplications.find((a) => a.id === value);
          if (!application) {
            return;
          }
          const { id, first_name, last_name, email, user, job } = application;
          onEdit({
            job_applications: [
              ...job_applications,
              {
                id,
                first_name,
                last_name,
                email,
                user: {
                  first_name: user.first_name,
                  last_name: user.last_name,
                  id: user.id,
                  email,
                },
                job: { id: job.id, title: job.title },
              },
            ],
          });
          setAddingTag("");
        },
        setLastElementRef: lastApplicationsRef,
        loaderAction: "GET_PAGINATION_RESULTS",
        label: localize("tasks.searchApplications"),
      },
      users: {
        items: searchedUsers.map(({ id, first_name, last_name, email }) => ({
          value: id,
          icon: WIconTypes.person,
          text: `${first_name} ${last_name} (${email})`,
          disabled: !!users.find((u) => u.id === id),
        })),
        onSearch: (text: string) => handleUserSearch({ search: text }),
        onSelect: (value: string) => {
          const user = searchedUsers.find((u) => u.id === value);
          if (!user) {
            return;
          }
          onEdit({ users: [...users, user] });
          setAddingTag("");
        },
        setLastElementRef: lastUsersRef,
        loaderAction: "GET_PAGINATION_RESULTS",
        label: localize("tasks.searchCandidates"),
      },
    };
    return addTagOptions;
  }, [
    searchedJobs,
    searchedApplications,
    searchedUsers,
    handleJobSearch,
    handleApplicationSearch,
    handleUserSearch,
    onEdit,
    jobs,
    job_applications,
    users,
    lastJobsRef,
  ]);

  if (addingTag === "") {
    return null;
  }

  return (
    <>
      <Grid item>
        <SearchDropdown
          {...addTagOptions[addingTag]}
          onSearch={async (text) => {
            if (!text) {
              return;
            }
            return addTagOptions[addingTag].onSearch(text);
          }}
          dropdownWidth={`calc(${TaskDrawerWidth} - 40px)`}
        />
      </Grid>

      <Grid item>
        <WButton key="cancelAddTag" onClick={() => setAddingTag("")}>
          {localize("tasks.cancelAddTag")}
        </WButton>
      </Grid>
    </>
  );
};
