import {
  AuthActions,
  ConversationActions,
  JobActions,
  ReferralActions,
  SkillActions,
  CVActions,
  JobApplicationActions,
  RegionLocationActions,
  OrganisationActions,
  PageActions,
  ReportsActions,
  InternalJobApplicationActions,
  UserDocumentActions,
} from "adapters";
import { SocketActions } from "components/modals/Messages/ConversationModal";
import { DepartmentActions } from "adapters/DepartmentRoleAdapter";
import { LoaderInitialState, LoaderContextState } from "context/LoaderContext";
import { PaginationActions } from "hooks/usePagination";
import { UserActions } from "./UserReducer";
import { JobMatchesActions } from "adapters/JobMatchesAdapter";
import { PushNotificationActions } from "adapters/PushNotificationsAdapter";
import { SubscriptionActions } from "adapters/SubscriptionsAdapter";
import { EmailTemplateActions } from "adapters/EmailTemplatesAdapter";
import { TaskActions } from "adapters/TaskAdapter";
import { GroupActions } from "adapters/GroupsAdapter";

export type LoaderActions =
  | AuthActions
  | ConversationActions
  | CVActions
  | DepartmentActions
  | EmailTemplateActions
  | GroupActions
  | InternalJobApplicationActions
  | JobActions
  | JobApplicationActions
  | JobMatchesActions
  | OrganisationActions
  | PageActions
  | PaginationActions
  | PushNotificationActions
  | ReferralActions
  | RegionLocationActions
  | ReportsActions
  | SkillActions
  | SocketActions
  | SubscriptionActions
  | TaskActions
  | UserActions
  | UserDocumentActions;

export type LoaderActionType =
  | { type: "SET_LOADING"; payload: LoaderActions }
  | { type: "STOP_LOADING"; payload: LoaderActions };

export function LoaderReducer(
  state: LoaderContextState = LoaderInitialState,
  action: LoaderActionType
): LoaderContextState {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        actions: [...state.actions, action.payload],
      };
    case "STOP_LOADING":
      return {
        ...state,
        actions: state.actions.filter((x) => {
          return x !== action.payload;
        }),
      };
    default:
      return state;
  }
}
