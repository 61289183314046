import { Grid } from "@mui/material";
import { Job } from "adapters";
import React, { FC, useContext } from "react";
import { WButton } from "./styledComponents/WButton";
import { LocaleContext } from "context/LocaleContext";
import { JobCard } from "./JobCard";
import { LoaderContext } from "context/LoaderContext";
import { WSkeleton } from "./styledComponents/WSkeleton";

interface Props {
  jobs: Job[];
  onShowAll: () => void;
  listIsLoading?: boolean;
}

export const FixedJobList: FC<Props> = ({ jobs, onShowAll }) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);

  return (
    <>
      <Grid container justifyContent="flex-start" mb="1rem">
        <WButton variant="contained" onClick={onShowAll}>
          {localize("common.showAllJobs")}
        </WButton>
      </Grid>
      <Grid container item xs={12} gap="1rem">
        {isLoading("GET_JOB") ? (
          <>
            <WSkeleton width="100%" height="100px" variant="rectangular" />
            <WSkeleton width="100%" height="100px" variant="rectangular" />
            <WSkeleton width="100%" height="100px" variant="rectangular" />
          </>
        ) : (
          jobs.map((job) => <JobCard key={job.id} {...job} />)
        )}
      </Grid>
    </>
  );
};
