import { Card, Grid, Typography } from "@mui/material";
import {
  Job,
  getJob,
  getJobApplication,
  createJobApplication,
  CreateJobApplicationForm,
  JobApplication,
  Skill,
  CV,
} from "adapters";
import { WTypography } from "components";
import { JobCardTitleComponent } from "components/JobCardTitleComponent";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";

import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { colors, defaultStyles } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";
import { ExpandedJobDescription } from "components/JobCard/ExpandedJobDescription";
import { JobApplicationForm } from "components/forms/JobApplicationForm";
import { UserContext } from "context/UserContext";
import { updateJobApplication } from "adapters/JobApplicationAdapter";
import { PushNotificationContext, PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { PROFILE_STEPS } from "views/Enums";
import { User } from "reducers/UserReducer";
import { isAfter, parseISO } from "date-fns";
import { WButton } from "components/styledComponents/WButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { ModalContext } from "context/ModalContext";
import { GTMContext } from "context/GTMContext";
import { DateAndSkillRow } from "components/DateAndSkillRow";

interface DetailedJobApplicationContentDTProps {
  jobId: string;
  job?: Job;
  showTitle?: boolean;
}

export const DetailedJobApplicationContentDT: FC<DetailedJobApplicationContentDTProps> = ({
  jobId,
  job,
  showTitle = false,
}) => {
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { showDialog, hideDialog } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { isAuthenticated, user, calculateProfileProgress } = useContext(UserContext);
  const { updateNotification } = useContext(PushNotificationContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);

  const history = useHistory();

  const [currJob, setCurrJob] = useState<Job | null>(null);
  const [canSaveToDraft, setCanSaveToDraft] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [prefilledData, setPrefilledData] = useState<Partial<JobApplication>>();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (job) {
      handleCurrJobUpdate(job);

      return;
    }

    if (jobId) {
      dispatchLoading({ type: "SET_LOADING", payload: "GET_JOB" });
      getJob(jobId)
        .then((res) => {
          handleCurrJobUpdate(res.data);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.fetchingJob", duration: 2000 });
          history.push("/");
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "GET_JOB" });
        });
    }
  }, [jobId]);

  const handleCurrJobUpdate = (job: Job) => {
    setCurrJob(job);
    setCanSaveToDraft(!job.job_application && isAuthenticated);

    setIsSaved(job.is_saved);

    const applicationID = job.job_application?.id;
    if (applicationID) {
      dispatchLoading({ type: "SET_LOADING", payload: "GET_JOB_APPLICATION" });
      getJobApplication(applicationID)
        .then((res) => {
          setPrefilledData({
            ...res.data,
          });
          updateNotification(PUSH_NOTIFICATION_TYPES.jobApplication);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.openJobApplication" });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "GET_JOB_APPLICATION" });
        });
    } else if (isAuthenticated) {
      setPrefilledData({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        cover_letter: "",
        referrals: [],
        skills: filterMatchedSkills(user.skills),
      });
    }
  };

  const submitJobApplication = async (data: CreateJobApplicationForm) => {
    if (currJob?.job_application?.id) {
      return await handleExistingApplicationSubmit(currJob.job_application?.id, data);
    } else {
      return await handleNewApplicationSubmit(data);
    }
  };

  const handleExistingApplicationSubmit = (
    applicationID: string,
    data: CreateJobApplicationForm
  ) => {
    return updateJobApplication(applicationID, data)
      .then(() => {
        if (data.is_draft) {
          addMessage({ type: "success", content: "success.jobApplicationDraft" });
        }
        gtmAddToDataLayer({ action: currJob?.title }, "application_updated");
        return true;
      })
      .catch((e) => {
        addMessage({ type: "error", content: JSON.stringify(e.response.data), isResponse: true });
        return false;
      });
  };

  const handleNewApplicationSubmit = (data: CreateJobApplicationForm) => {
    if (isLoading("CREATE_JOB_APPLICATION")) {
      return;
    }
    const language = (!isAuthenticated ? localStorage.getItem("lang") : user.language) || "en";

    dispatchLoading({ type: "SET_LOADING", payload: "CREATE_JOB_APPLICATION" });
    return createJobApplication({ ...data, language: language })
      .then(() => {
        if (data.is_draft) {
          addMessage({ type: "success", content: "success.jobApplicationDraft" });
        }
        calculateProfileProgress(true, PROFILE_STEPS.jobApplication);

        gtmAddToDataLayer(
          { action: currJob?.title },
          data.is_draft ? "application_saved" : "application_submitted"
        );
        return true;
      })
      .catch((e) => {
        addMessage({ type: "error", content: JSON.stringify(e.response.data), isResponse: true });
        return false;
      })
      .finally(() => dispatchLoading({ type: "STOP_LOADING", payload: "CREATE_JOB_APPLICATION" }));
  };

  const filterMatchedSkills = (matchSkills: Skill[]) => {
    return matchSkills.filter((matchSkill) =>
      currJob?.skills.some((jobSkill) => jobSkill.id === matchSkill.id)
    );
  };
  const getLatestCV = (cv: CV[]) => {
    return cv.sort((x, y) => {
      return isAfter(parseISO(x.created), parseISO(y.created)) ? -1 : 1;
    })[0];
  };
  const loginAndPrefill = (userData: User) => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_USER_SKILLS" });
    // TODO Add price when user has attribute

    setPrefilledData({
      referrals: [],
      cv: getLatestCV(userData.cv),
      first_name: userData.first_name,
      last_name: userData.last_name,
      phone: userData.phone,
      email: userData.email,
      skills: filterMatchedSkills(userData.skills),
      availability_date: userData.availability_date,
    });
  };

  const divRef = useRef<HTMLDivElement | null>(null);

  return (
    <Grid
      container
      className="contentWrapper"
      gap="2rem"
      position="relative"
      bgcolor={colors.white}
    >
      {showTitle && (
        <Grid
          ref={divRef}
          container
          alignItems="center"
          direction="column"
          position="absolute"
          padding="1rem 0rem"
          top="0"
          left="0"
          justifyContent="center"
          bgcolor={colors.siteBackground}
        >
          <WTypography variant="h4" fontWeight="bold">
            {localize("form.applyForJob")}
          </WTypography>
          <WTypography variant="h5" fontWeight="bold">
            {currJob?.title}
          </WTypography>
        </Grid>
      )}
      {!isAuthenticated && (
        <WButton
          variant="outlined"
          startIcon={<WIcon icon={WIconTypes.login} color={"primary"} />}
          color={"primary"}
          sx={{ width: "max-content", position: "absolute", left: "4rem", top: "2.5rem" }}
          onClick={() =>
            showDialog("LOGIN_DIALOG", {
              handleClose: hideDialog,
              onLoginSubmit: loginAndPrefill,
            })
          }
        >
          {localize("navbar.loginText")}
        </WButton>
      )}
      <Card
        sx={{
          width: "100%",
          padding: "1rem",
          position: "relative",
          marginTop: divRef.current ? `${divRef.current.getBoundingClientRect().height}px` : 0,
          borderRadius: defaultStyles.borderRadius,
          boxShadow: defaultStyles.cardShadow,
          backgroundColor: colors.white,
        }}
        elevation={4}
      >
        <Grid container direction="column" wrap="nowrap" gap="1rem">
          <Grid container direction="column" wrap="nowrap" gap="1rem">
            {currJob ? (
              <>
                <JobCardTitleComponent
                  job={currJob}
                  isSaved={isSaved}
                  setIsSaved={setIsSaved}
                  iconVariant="expanded"
                  applicationData={currJob.job_application}
                />
                <DateAndSkillRow wrap created={currJob.created} skills={currJob.skills} />
                <Grid container wrap="nowrap" width="100%">
                  <ExpandedJobDescription job={currJob} />
                </Grid>
              </>
            ) : null}
          </Grid>
          {currJob?.pitch && (
            <Grid container wrap="nowrap" direction="column">
              <Typography variant="h6">{localize("common.summary")}</Typography>
              <WTypography variant="body1" lineClamps={4}>
                {currJob.pitch}
              </WTypography>
            </Grid>
          )}
        </Grid>
      </Card>
      <Grid md={4} item>
        <JobApplicationForm
          sx={{ padding: 0 }}
          prefillFields={prefilledData}
          jobSkills={currJob?.skills}
          onSubmit={submitJobApplication}
          canSaveDraft={canSaveToDraft}
          jobId={currJob?.id || ""}
        />
      </Grid>
    </Grid>
  );
};
