import React, { useState } from "react";
import { Grid } from "@mui/material";
import { DashboardHeader } from "components";
import { JobListTab } from "components/DashboardView/JobListTab";
import { JobMatchesTab } from "components/DashboardView/JobMatchesTab";
import { JobApplicationsTab } from "components/DashboardView/JobApplicationsTab";

export const DASHBOARD_ALL_JOBS_TAB = 0;
export const DASHBOARD_MATCHES_TAB = 1;
export const DASHBOARD_APPLICATIONS_TAB = 2;

const Dashboardview: React.FC = () => {
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [currentTab, setTab] = useState(DASHBOARD_ALL_JOBS_TAB);
  const [currentSearch, setSearch] = useState<string>("");
  const [isSearchView, setIsSearchView] = useState(false);

  const renderCurrentTabContent = (currentTab: number) => {
    if (isSearchView) {
      return (
        <JobListTab
          currentSearch={currentSearch}
          filtersDialog={{
            isOpen: filterDialogOpen,
            setOpen: setFilterDialogOpen,
          }}
          isSearchView={isSearchView}
        />
      );
    }

    switch (currentTab) {
      case DASHBOARD_ALL_JOBS_TAB:
        return (
          <JobListTab
            currentSearch={currentSearch}
            filtersDialog={{
              isOpen: filterDialogOpen,
              setOpen: setFilterDialogOpen,
            }}
          />
        );
      case DASHBOARD_MATCHES_TAB:
        return <JobMatchesTab />;
      case DASHBOARD_APPLICATIONS_TAB:
        return <JobApplicationsTab />;

      default:
        return (
          <JobListTab
            currentSearch={currentSearch}
            filtersDialog={{
              isOpen: filterDialogOpen,
              setOpen: setFilterDialogOpen,
            }}
          />
        );
    }
  };

  return (
    <Grid container>
      <DashboardHeader
        tabCallback={setTab}
        setIsSearchView={setIsSearchView}
        searchCallback={setSearch}
        currentTab={currentTab}
        currentSearch={currentSearch}
        filtersDialog={{
          isOpen: filterDialogOpen,
          setOpen: setFilterDialogOpen,
        }}
      />
      <Grid container className="contentWrapper" gap="1rem">
        {renderCurrentTabContent(currentTab)}
      </Grid>
    </Grid>
  );
};
export default Dashboardview;
