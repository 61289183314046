export type InteractEvent = MouseEvent | TouchEvent;

export const getInteractPoint = (e: InteractEvent): { x: number; y: number } => {
  if (e instanceof MouseEvent) {
    return { x: e.clientX, y: e.clientY };
  }
  return { x: e.touches[0].clientX, y: e.touches[0].clientY };
};

export const rectPointIntersection = (rect: DOMRect, pos: { x: number; y: number }): boolean => {
  if (
    pos.y < rect.y ||
    pos.y > rect.y + rect.height ||
    pos.x < rect.x ||
    pos.x > rect.x + rect.width
  ) {
    return false;
  }
  return true;
};

export const getHighestOrderInArea = (area: HTMLElement): number => {
  let highest = -1;
  Array.from(area.children).forEach((child) => {
    const htmlElement = child as HTMLElement;
    const order = parseInt(htmlElement.style.order);
    if (order > highest) {
      highest = order;
    }
  });
  return highest;
};

export const updateOrdersBetween = (
  area: HTMLElement,
  order1: number,
  order2: number,
  ignored: HTMLElement
): void => {
  const dir = order2 < order1 ? 1 : -1;
  if (order2 < order1) {
    const temp = order2;
    order2 = order1;
    order1 = temp;
  }

  Array.from(area.children).forEach((child) => {
    if (child === ignored) {
      return;
    }
    const htmlElement = child as HTMLElement;
    const order = parseInt(htmlElement.style.order);
    if (order >= order1 && order <= order2) {
      htmlElement.style.order = (order + dir).toString();
    }
  });
};
