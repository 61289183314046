import React, { FC, useCallback, useContext, useEffect, useState, useMemo } from "react";
import { Grid } from "@mui/material";
import { DragAndDropHandler, isDragging } from "components/DragAndDrop/DragAndDropHandler";
import { usePagination } from "hooks/usePagination";
import { KanbanJob, getKanbanJob } from "adapters/JobAdapter";
import { WTypography } from "components/WTypography";
import { WTextField } from "components/styledComponents/WTextField";
import { FilterTitle } from "./FilterTitle";
import { useParams } from "react-router-dom";
import {
  KanbanJobApplication,
  getKanbanJobApplicationList,
  updateKanbanJobApplication,
} from "adapters/JobApplicationAdapter";
import {
  InternalJobApplication,
  deleteInternalJobApplication,
  getInternalJobApplicationList,
  sendJobProposal,
  updateInternalJobApplication,
} from "adapters/InternalJobApplicationAdapter";
import { ApplicationItem, ApplicationItemHeight } from "./ApplicationItem";
import { ApplicationStageCircle } from "components/ApplicationStageCircle";
import { LocaleContext } from "context/LocaleContext";
import { KanbanColumns, ProcessedApplication, TKanbanColumn } from "./KanbanColumns";
import { TaskDrawerWidth, TasksDrawer } from "./TasksDrawer";
import { WButton } from "components/styledComponents/WButton";
import { ModalContext } from "context/ModalContext";
import { WIcon, WIconTypes } from "components/WIcon";
import { DropdownItem, MultiSelectDropdown } from "components/MultiSelectDropdown";

const DEFAULT_ORDER_STEP = 100000;
const INTERVAL_TIME = 60 * 1000;

export const JobKanbanView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { showDialog } = useContext(ModalContext);
  const [job, setJob] = useState<KanbanJob | null>(null);
  const [applications, setApplications] = useState<ProcessedApplication[]>([]);
  const [kanbanColumns, setKanbanColumns] = useState<TKanbanColumn<"application">[]>([]);
  const [filterString, setFilterString] = useState<string>("");
  const [isTasksOpen, setIsTasksOpen] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const { showModal } = useContext(ModalContext);

  const sendUpdateJobApplications = (id: string, stageId: string, order: number) => {
    // Must do this inside setApplications because applications wasn't updating properly
    setApplications((list) => {
      const isInternal = !list.find((a) => a.id === id)?.email;
      if (isInternal) {
        updateInternalJobApplication(id, {
          stage: stageId,
          kanban_job_application_stage_order_no: order,
        });
      } else {
        updateKanbanJobApplication(id, {
          stage: stageId,
          kanban_job_application_stage_order_no: order,
        });
      }
      return list;
    });
  };

  const { jobId } = useParams<{ jobId: string }>();

  useEffect(() => {
    if (!jobId) {
      return;
    }

    (async () => {
      const j = await getKanbanJob(jobId);
      setJob(j.data);
    })();
  }, [jobId]);

  const { results: applicationResults, getInitial: fetchAllApplications } =
    usePagination<KanbanJobApplication>(
      getKanbanJobApplicationList,
      "error.fetchingJobApplications",
      "GET_JOB_APPLICATION_LIST",
      { page_size: 1000, job: jobId }
    );
  const { results: internalApplicationResults, getInitial: fetchAllInternalApplications } =
    usePagination<InternalJobApplication>(
      getInternalJobApplicationList,
      "error.fetchingInternalJobApplications",
      "GET_INTERNAL_JOB_APPLICATION_LIST",
      { page_size: 1000, job: jobId }
    );

  useEffect(() => {
    const intervalHandle = setInterval(async () => {
      if (isDragging) {
        return;
      }
      fetchAllApplications();
      fetchAllInternalApplications();
    }, INTERVAL_TIME);

    return () => {
      clearInterval(intervalHandle);
    };
  }, []);

  useEffect(() => {
    if (!job?.kanban_job_applications_process) {
      return;
    }

    const stages = [...job.kanban_job_applications_process.job_application_counts];
    const applications = [...applicationResults, ...internalApplicationResults]
      .map((application, index) => {
        return {
          ...application,
          stage: application.stage ?? stages[0]?.id,
          kanban_job_application_stage_order_no:
            application.kanban_job_application_stage_order_no ?? (index + 1) * DEFAULT_ORDER_STEP,
        } as ProcessedApplication;
      })
      .filter((application) => stages.find((stage) => stage.id === application.stage));
    setApplications(applications);
  }, [applicationResults, internalApplicationResults, job?.kanban_job_applications_process]);

  const onDrop = useCallback(
    async (elementId: string, areaId: string, neighburIds: (string | null)[]): Promise<void> => {
      const application = applications.find((application) => application.id === elementId);
      if (!application) {
        return;
      }
      const neighburs: ProcessedApplication[] = [];
      let newOrder = -1;
      neighburIds.forEach((id) => {
        if (!id) {
          return;
        }

        const application = applications.find((application) => application.id === id);
        if (application) {
          neighburs.push(application);
        }
      });

      if (neighburs.length === 0) {
        newOrder = DEFAULT_ORDER_STEP;
      } else if (neighburs.length === 2) {
        newOrder = Math.floor(
          (neighburs[0].kanban_job_application_stage_order_no +
            neighburs[1].kanban_job_application_stage_order_no) /
            2
        );
      } else if (neighburIds[0] === null) {
        newOrder = Math.floor(neighburs[0].kanban_job_application_stage_order_no / 2);
      } else if (neighburIds[1] === null) {
        newOrder = neighburs[0].kanban_job_application_stage_order_no + DEFAULT_ORDER_STEP;
      }

      sendUpdateJobApplications(elementId, areaId, newOrder);
      const applicationList = [...applications];
      applicationList.splice(
        applications.findIndex((j) => j.id === elementId),
        1
      );
      setApplications([
        ...applicationList,
        { ...application, stage: areaId, kanban_job_application_stage_order_no: newOrder },
      ]);
    },
    [applications]
  );

  useEffect(() => {
    if (job?.kanban_job_applications_process.job_application_counts.length === 0) {
      return;
    }

    const dragHandler = new DragAndDropHandler("kanban", onDrop, true);

    const columns: Record<string, TKanbanColumn<"application">> = {};
    job?.kanban_job_applications_process.job_application_counts.forEach((stage) => {
      columns[stage.id] = { stage, items: [] };
    });
    applications.forEach((application) => {
      columns[application.stage].items.push(application);
    });
    const columnsArr = Object.values(columns);
    columnsArr.forEach((a) =>
      a.items.sort(
        (a, b) => a.kanban_job_application_stage_order_no - b.kanban_job_application_stage_order_no
      )
    );
    setKanbanColumns(columnsArr);

    return () => {
      dragHandler.destroy();
    };
  }, [applications, job?.kanban_job_applications_process.job_application_counts, onDrop]);

  const recipientItems = useMemo((): DropdownItem[] => {
    return applications.map((a) => ({
      id: a.id,
      name: a,
      primary: `${a.user.first_name} ${a.user.last_name}` || a.email,
    }));
  }, [applications]);

  const recipients = useMemo(() => {
    return applications.filter((a) => selectedRecipients.includes(a.id)).map((a) => a);
  }, [applications, selectedRecipients]);

  return (
    <>
      <Grid
        container
        direction="column"
        height="100vh"
        flexWrap="nowrap"
        paddingTop="30px"
        width={`calc(100% - ${isTasksOpen ? TaskDrawerWidth : 0}) !important`}
        sx={{ transition: "width 0.2s linear" }}
      >
        <Grid item container paddingX="30px" marginBottom="10px" display="flex" alignItems="center">
          <img
            src={
              job?.pictures.length
                ? job.pictures[0].thumb
                : `${process.env.PUBLIC_URL}/assets/logo/icon_64.png`
            }
            alt="Job icon"
            width="40px"
            height="40px"
            style={{ objectFit: "contain", marginRight: 10 }}
          />
          <WTypography variant="h1" fontSize="2rem" lineClamps={1}>
            {job?.title}
          </WTypography>
        </Grid>
        <Grid item container paddingX="30px" gap="5px 30px">
          <Grid item>
            <WTypography variant="body2">
              {localize("common.agent")}:{" "}
              {typeof job?.recruiter === "string"
                ? job.recruiter
                : `${job?.recruiter.first_name} ${job?.recruiter.last_name}`}
            </WTypography>
          </Grid>
          <Grid item>
            <WTypography variant="body2">
              {localize("common.location")}: {job?.locations.map((l) => l.city).join(", ")}
            </WTypography>
          </Grid>
          <Grid item>
            <WTypography variant="body2">
              {localize("common.applications")}: {applicationResults.length}
            </WTypography>
          </Grid>
          <Grid item marginLeft="auto">
            <WButton
              variant="outlined"
              onClick={() => {
                if (!job) {
                  return;
                }
                showDialog("CREATE_INTERNAL_APPLICATION", {
                  job,
                  applications,
                  internalApplications: [],
                  onConfirm: fetchAllInternalApplications,
                });
              }}
            >
              <WIcon icon={WIconTypes.add} size="small" />
              {localize("internalApplication.createInternal")}
            </WButton>
          </Grid>
        </Grid>
        <Grid item container gap="20px" padding="30px">
          <FilterTitle tag="kanbanView.filterName">
            <WTextField onChange={(e) => setFilterString(e.target.value)} />
          </FilterTitle>
          <FilterTitle tag="kanbanView.mailRecipients">
            <MultiSelectDropdown
              selectedIds={selectedRecipients}
              items={recipientItems}
              onToggle={setSelectedRecipients}
              noSelectionTag="kanbanView.selectRecipients"
            />
          </FilterTitle>
          <WButton
            disabled={selectedRecipients.length === 0}
            sx={{ maxHeight: "35px", top: "25px" }}
            variant="outlined"
            size="small"
            startIcon={
              <WIcon
                color={selectedRecipients.length === 0 ? "secondaryDark" : "primary"}
                size="small"
                icon={WIconTypes.mail}
              />
            }
            onClick={() => {
              showModal("SEND_EMAIL_MODAL", {
                users: recipients,
              });
            }}
          >
            {localize("kanbanView.sendMail")}
          </WButton>
        </Grid>
        <KanbanColumns
          columns={kanbanColumns}
          itemHeight={ApplicationItemHeight}
          getVisibleItems={(items) => {
            return items
              .filter((application) => {
                if (!filterString) {
                  return true;
                }
                const name = `${application.user.first_name} ${application.user.last_name}`;
                return name.toLowerCase().includes(filterString.toLowerCase());
              })
              .map((application) => application.id);
          }}
          secondaryTextCallback={(count, stage) => (
            <ApplicationStageCircle name={stage.name} count={count} />
          )}
          itemCallback={(application, visible) => (
            <ApplicationItem
              key={application.id}
              id={application.id}
              application={application}
              style={{ display: !visible ? "none" : "" }}
              onDelete={() => {
                deleteInternalJobApplication(application.id);
                const index = applications.findIndex((a) => a.id === application.id);
                applications.splice(index, 1);
                setApplications([...applications]);
              }}
              onSendJobProposal={() => {
                sendJobProposal(application.id);
                const index = applications.findIndex((a) => a.id === application.id);
                applications[index] = { ...applications[index], is_proposal_sent: true };
                setApplications([...applications]);
              }}
            />
          )}
        />
      </Grid>
      <TasksDrawer isOpen={isTasksOpen} setOpen={setIsTasksOpen} filterJobId={jobId} />
    </>
  );
};
