import { Box, Grid, Typography } from "@mui/material";
import { colors } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { ProfileTaskList } from "./ProfileView/ProfileTaskList";
import { StepsAndActions } from "./ProfileView/MyProfileTab";
import { WIcon, WIconTypes } from "components";

interface ProfileCompletenessBarProps {
  percentComplete: number;
  steps: StepsAndActions[] | undefined;
}

export const ProfileCompletenessBar: FC<ProfileCompletenessBarProps> = ({
  percentComplete = 0,
  steps = [],
}) => {
  const { localize } = useContext(LocaleContext);
  const [showMore, setShowMore] = useState(true);
  const [barWidth, setBarWidth] = useState<number>(0);
  const [circleWidth, setCircleWidth] = useState<number>(0);
  const profileBar = useRef<HTMLDivElement | null>(null);
  const profileBarCircle = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (profileBarCircle.current) {
      setCircleWidth(profileBarCircle.current.offsetWidth);
    }
    calcBarWidth();
    window.addEventListener("resize", calcBarWidth);
    return () => {
      window.removeEventListener("resize", calcBarWidth);
    };
  }, []);

  const calcBarWidth = () => {
    if (profileBar.current) {
      setBarWidth(profileBar.current.getBoundingClientRect().width);
    } else {
      setBarWidth(0);
    }
  };
  const getCircleOffset = () => {
    if (!barWidth) {
      return 0;
    }
    // calculate offset for circle in the progress bar.  Special case for 0 and 100%
    if (percentComplete) {
      if (percentComplete === 100) {
        return barWidth - circleWidth;
      } else {
        return (percentComplete / 100) * barWidth - circleWidth / 2;
      }
    } else {
      return 0;
    }
  };

  return (
    <Grid container gap="1rem" direction="column" marginBottom={showMore ? "0" : "1.5rem"}>
      <Box>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography color="primary" fontWeight="bold">
            {localize("profileView.profileStrengthHeader")}
          </Typography>
          {percentComplete < 100 ? (
            <Box onClick={() => setShowMore(!showMore)}>
              <WIcon icon={showMore ? WIconTypes.arrowUp : WIconTypes.arrowDown} />
            </Box>
          ) : (
            <div>&nbsp;</div>
          )}
        </Grid>
        <Typography color="primary">{localize("profileView.profileStrengthSubHeader")}</Typography>
      </Box>
      <Box
        width="95%"
        height="1rem"
        border="solid 2px"
        borderColor="primary.main"
        borderRadius=".5rem"
        position="relative"
        sx={{
          backgroundImage: `linear-gradient(to right, ${colors.mainDark} ${percentComplete}%,${colors.grey} ${percentComplete}%)`,
        }}
        ref={profileBar}
      >
        {percentComplete >= 0 && percentComplete < 100 && (
          <Box
            width="3rem"
            height="3rem"
            borderRadius="50%"
            border="solid 5px"
            borderColor="primary.main"
            z-index="1"
            textAlign="center"
            fontWeight="bold"
            padding=".5rem 0"
            position="absolute"
            top="-1.2rem"
            left={() => getCircleOffset()}
            sx={{
              backgroundColor: "white",
            }}
            ref={profileBarCircle}
          >
            {percentComplete.toString()}
          </Box>
        )}
        {percentComplete === 100 && (
          <Box
            sx={{
              width: "3rem",
              height: "3rem",
              position: "absolute",
              top: "-1.3rem",
              right: "-1rem",
            }}
          >
            <WIcon icon={WIconTypes.star} size="extraLarge" color="star" fill />
          </Box>
        )}
      </Box>
      {steps.length > 0 && showMore && percentComplete < 100 && <ProfileTaskList steps={steps} />}
    </Grid>
  );
};
