import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import { useContext, useState, useEffect, useMemo } from "react";
import { getPages, getPageHTMLContent, Page, defaultPageContent, PageType } from "adapters";
import { getExtraPageHTMLContent, getExtraPages } from "adapters/DynamicPageAdapter";

type Props =
  | { isExtraPage?: boolean; pageType: PageType }
  | { isExtraPage: boolean; pageType: string };

export const useDynamicPageContent = (
  props: Props
): { pageContent: Page; pageHTMLContent: string } => {
  const { pageType, isExtraPage } = props;

  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { user } = useContext(UserContext);

  const [pageContent, setPageContent] = useState<Page>(defaultPageContent);
  const [pageHTMLContent, setPageHTMLContent] = useState("");
  const lang = localStorage.getItem("lang") || "en";

  const { fetchPages, fetchHtmlContent } = useMemo(() => {
    if (isExtraPage) {
      return { fetchPages: getExtraPages, fetchHtmlContent: getExtraPageHTMLContent };
    }

    return { fetchPages: getPages, fetchHtmlContent: getPageHTMLContent };
  }, []);

  useEffect(() => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_PAGE_LIST" });

    fetchPages({
      page_type: pageType as PageType,
      language: user.language || lang,
    })
      .then((res) => {
        if (res.data.results[0].id) {
          setPageContent(res.data.results[0]);
          dispatchLoading({ type: "SET_LOADING", payload: "GET_PAGE_CONTENT" });

          fetchHtmlContent(res.data.results[0].id)
            .then((contentRes) => {
              setPageHTMLContent(contentRes.data);
            })
            .catch(() => {
              addMessage({ type: "error", content: "error.fetchingPageContent" });
            })
            .finally(() => dispatchLoading({ type: "STOP_LOADING", payload: "GET_PAGE_CONTENT" }));
        }
      })
      .catch(() => {
        // addMessage({ type: "error", content: "error.fetchingPageContent" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_PAGE_LIST" });
      });
  }, []);

  return { pageContent: pageContent, pageHTMLContent: pageHTMLContent };
};
