import axios, { AxiosRequestConfig } from "axios";
import config from "config";

export type PaginatedResponse<T> = {
  count: number;
  next: string;
  previous: string;
  results: T[];
};

const DefaultAxiosConfig: AxiosRequestConfig = {
  baseURL: config.apiURL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "en",
  },
  withCredentials: true,
  xsrfHeaderName: "x-csrftoken",
  xsrfCookieName: "csrftoken",
};

export const API = axios.create(DefaultAxiosConfig);

const UNAUTH_ERROR_CODES = [401, 403, 405, 407];

API.interceptors.response.use(undefined, (error) => {
  if (UNAUTH_ERROR_CODES.includes(error.response.status)) {
    window.location.href = "/login";
    return;
  }
  return Promise.reject(error);
});
