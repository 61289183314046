import { colors, defaultStyles } from "styles/colors";
import { WButton } from "./styledComponents/WButton";
import React, { FC, useContext } from "react";
import { BreakpointsContext } from "context/BreakpointContext";
import { NotificationIndicator } from "./NotificationIndicator";

interface WTabProps {
  active: boolean;
  label: string;
  onTabChange: () => void;
  disabled?: boolean;
  hasNotification?: boolean;
}

export const WTab: FC<WTabProps> = ({
  active,
  label,
  onTabChange,
  disabled = false,
  hasNotification = false,
}) => {
  const { isDesktop } = useContext(BreakpointsContext);

  if (isDesktop) {
    return (
      <WButton
        disableRipple
        onClick={() => !active && onTabChange()}
        disabled={disabled}
        sx={{
          borderRadius: "4px 4px 0 0",
          bgcolor: active ? colors.siteBackground : colors.grey,
          color: active ? colors.mainDark : colors.darkGrey,
          fontWeight: "bold",
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }}
      >
        <NotificationIndicator condition={hasNotification} />
        {label}
      </WButton>
    );
  } else {
    return (
      <WButton
        disableRipple
        fullWidth
        onClick={() => !active && onTabChange()}
        disabled={disabled}
        sx={{
          borderRadius: defaultStyles.borderRadiusFullyRounded,
          bgcolor: active ? colors.darkGrey : colors.grey,
          color: active ? "common.white" : colors.darkGrey,
          "&:hover": {
            backgroundColor: active ? colors.darkGrey : colors.grey,
          },
          boxShadow: active ? defaultStyles.shadow : 0,
          fontWeight: "bold",
        }}
      >
        <NotificationIndicator condition={hasNotification} size="small" />
        {label}
      </WButton>
    );
  }
};
