import React, { FC, Children, cloneElement } from "react";

interface Props {
  order?: number;
  locked?: boolean;
}

export const Draggable: FC<Props> = ({ children, order, locked }) => {
  if (Children.count(children) !== 1) {
    throw new Error("MUST BE EXACTLY ONE CHILD");
  }

  const childElement = Children.toArray(children)[0] as React.ReactElement;

  if (locked) {
    return childElement;
  }

  return cloneElement(childElement, {
    style: { cursor: "pointer", order: order?.toString(), ...childElement.props.style },
    className: "draggable",
  });
};
