export enum DELETABLE_ITEMS {
  cv = "cv",
  departmentRole = "departmentRole",
  location = "location",
}

export enum DIALOGS {
  avatarPicker = "avatarPicker",
  createReferral = "createReferral",
  skills = "skills",
  referrals = "referrals",
  referralsEdit = "referralsEdit",
  editContactDetails = "editContactDetails",
  confirm = "confirm",
  createDeptRole = "createDeptRole",
  agentNotes = "agentNotes",
  createLocation = "createLocation",
  shareJob = "shareJob",
  cvs = "cvs",
}

export enum PROFILE_STEPS {
  skill = "skill",
  availability = "availability",
  cv = "cv",
  jobApplication = "jobApplication",
}

export const INTEGRITY_LINK = "https://www.wisegroup.se/integritetspolicy";
export const PRIVACY_LINK = "https://jobs.wiseit.se/privacy-policy";
export const COOKIE_LINK = `${process.env.PUBLIC_URL}/cookies`;
export const GDPR_LINK = "https://www.wisegroup.se/gdpr/";
