import React, { FC, useContext } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { colors } from "styles/colors";
import { ApplicationStageCircle, ApplicationStageCircleSize } from "./ApplicationStageCircle";
import { Job } from "adapters";
import { LocaleContext } from "context/LocaleContext";

interface Props {
  job: Job;
}

export const ApplicationStageCircles: FC<Props> = ({ job }) => {
  const { localize } = useContext(LocaleContext);

  const {
    kanban_job_applications_process: { total, job_application_counts },
  } = job;

  return (
    <Tooltip
      title={
        <Box display="flex" flexDirection="column">
          {job_application_counts.map(({ name, count }) => {
            return (
              <span key={name} style={{ width: "100%" }}>
                {name}: {count}
              </span>
            );
          })}
          <b>
            {localize("common.total")}: {total}
          </b>
        </Box>
      }
      placement="top"
      arrow
      disableInteractive
      enterDelay={600}
    >
      <Grid
        container
        item
        gap="5px"
        padding={`${PADDING}px`}
        bgcolor={colors.white}
        borderRadius={`${(ApplicationStageCircleSize + PADDING * 2) / 2}px`}
        width="fit-content"
      >
        {job_application_counts.map(({ name, count }) => (
          <ApplicationStageCircle key={name} name={name} count={count} />
        ))}
      </Grid>
    </Tooltip>
  );
};

const PADDING = 3;
