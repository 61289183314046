import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export type EmailTemplateActions =
  | "GET_EMAIL_TEMPLATES"
  | "GET_EMAIL_TEMPLATES_HUBSPOT"
  | "SEND_EMAIL";

export interface EmailTemplate {
  id: string;
  name: string;
  body: string;
  dynamic_fields: string;
  subject: string;
  language: "sv" | "en";
}

export interface EmailTemplateHubspot {
  id: string;
  name: string;
  template_id: string;
}

export const getEmailTemplates = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<EmailTemplate[]>>> => {
  return API.get(`/events/templates/`, { params });
};

export const getHubspotEmailTemplates = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<EmailTemplateHubspot[]>>> => {
  return API.get(`/events/hubspot_templates/`, { params });
};

export const sendEmail = async (
  recipients: string[],
  subject: string,
  body: string
): Promise<AxiosResponse<void>> => {
  return API.post(`/events/send_mail_to_users/`, { recipients, subject, body });
};

export const sendHubspotEmail = async (
  recipients: string[],
  templateId: string
): Promise<AxiosResponse<void>> => {
  return API.post(`/events/send_hubspot_mail_to_users/?template_id=${templateId}`, {
    recipients,
  });
};
