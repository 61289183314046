import { Collapse, Grid } from "@mui/material";
import { AdvancedSearch, DynamicHeader, Navbar, WButton, WIcon, WIconTypes } from "components";
import { LocaleContext } from "context/LocaleContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext, useState } from "react";
import { DashboardTabList } from "components/DashboardView/DashboardTabList";
import { BreakpointsContext } from "context/BreakpointContext";
import { DASHBOARD_ALL_JOBS_TAB } from "views/Dashboardview";
import { generateWelcomeMessage } from "utils/GenerateWelcomeMessage";

interface DashboardHeaderProps {
  tabCallback?: (tab: number) => void;
  searchCallback: (search: string) => void;
  setIsSearchView: (isSearchView: boolean) => void;
  currentTab: number;
  currentSearch: string;
  filtersDialog: {
    isOpen: boolean;
    setOpen: (condition: boolean) => void;
  };
}

export const DashboardHeader: FC<DashboardHeaderProps> = ({
  currentTab,
  searchCallback,
  tabCallback,
  setIsSearchView,
  currentSearch,
  filtersDialog,
}) => {
  const { user } = useContext(UserContext);
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const { welcomeMessage } = generateWelcomeMessage();

  const [showFilterButton, setShowFilterButton] = useState(false);

  const handleFilterAction = (condition: boolean) => {
    // Handle "hideElement" id so element is not hidden while focused

    setShowFilterButton(condition);
    setIsSearchView(condition);
  };

  return (
    <DynamicHeader forceHide={currentTab !== DASHBOARD_ALL_JOBS_TAB}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          maxWidth: {
            xs: "100%",
          },
        }}
      >
        {isDesktop ? (
          <Navbar title={`${welcomeMessage} ${user.first_name}`} />
        ) : (
          <Navbar title={welcomeMessage} subTitle={user.first_name} />
        )}
      </Grid>

      <Grid container item gap="1rem" xs={12} md={6}>
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="flex-start"
          // marginBottom={showFilterButton && isDesktop ? "2rem" : "0"}
        >
          <Grid
            container
            item
            sx={{
              maxWidth: {
                xs: "100%",
                md: "530px",
              },
            }}
            wrap="nowrap"
            id="elementToHide"
          >
            <AdvancedSearch
              onSearch={(search) => searchCallback(search)}
              currentSearch={currentSearch}
              onFocus={() => handleFilterAction(true)}
            />
            {showFilterButton && (
              <Grid
                container
                item
                marginLeft="1rem"
                xs={2}
                alignItems="center"
                justifyContent="center"
              >
                <WButton variant="contained" onClick={() => handleFilterAction(false)}>
                  {localize("common.close")}
                </WButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isDesktop && !showFilterButton && (
          <DashboardTabList
            hideTabs={showFilterButton}
            activeTab={currentTab}
            tabCallback={tabCallback}
          />
        )}
        {isDesktop && (
          <DashboardTabList
            hideTabs={showFilterButton}
            activeTab={currentTab}
            tabCallback={tabCallback}
          />
        )}
        <Collapse in={showFilterButton && !isDesktop} timeout={0} mountOnEnter unmountOnExit>
          <Grid
            container
            margin="-1rem"
            marginTop="0"
            padding="1rem 2rem"
            width="100vw"
            bgcolor="background.default"
            boxShadow={4}
          >
            <WButton
              fullWidth
              variant="outlined"
              startIcon={<WIcon rotationDegrees="90" icon={WIconTypes.sliders} />}
              onClick={() => filtersDialog.setOpen(true)}
            >
              {localize("searchbar.filterButton")}
            </WButton>
          </Grid>
        </Collapse>
      </Grid>
    </DynamicHeader>
  );
};
//
