import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export type CVActions = "GET_CVS" | "DELETE_CV" | "ADD_CV";

export interface CV {
  id: string;
  alias: string;
  user_cv: string;
  created: string;
  is_cv_warning: boolean;
}

export const getUserCV = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<CV>>> => {
  return API.get("/user-cv/", { params: params });
};

export const removeUserCV = async (id: string): Promise<void> => {
  return API.delete(`/user-cv/${id}/`);
};

export const addUserCV = async (data: File, alias: string): Promise<AxiosResponse<CV>> => {
  const formData = new FormData();
  formData.append("user_cv", data);
  formData.append("alias", alias);
  return API.post("/user-cv/", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
