import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { Skill } from "adapters";
import { prettifyDate } from "utils/PrettifyDate";
import { DataChip } from "./DataChip";

interface DateAndSkillRowProps {
  skills: Skill[];
  created: string;
  wrap?: boolean;
}

export const DateAndSkillRow: FC<DateAndSkillRowProps> = ({
  skills,
  created,
  wrap = false,
}): JSX.Element => {
  return (
    <Grid
      container
      gap=".5rem"
      wrap={wrap ? "wrap" : "nowrap"}
      alignItems="center"
      position="relative"
      overflow="hidden"
      sx={
        wrap
          ? {}
          : {
              borderRadius: "15px",
              "::after": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                boxShadow: "inset -15px 0px 5px rgba(255, 255, 255, 0.8)",
              },
            }
      }
    >
      <Typography variant="body2" minWidth="max-content" color="primary">
        {prettifyDate(created, { showRelativeTime: true })}
      </Typography>
      {skills.map((skill) => (
        <DataChip key={skill.id} chipLabel={skill.name} />
      ))}
    </Grid>
  );
};
