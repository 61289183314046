import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export type PageActions =
  | "GET_PAGE_LIST"
  | "GET_PAGE"
  | "GET_PAGE_CONTENT"
  | "GET_EXTRA_PAGE_TYPES";

export interface Page {
  id: string;
  kicker: string;
  title: string;
  sub_title: string;
  call_to_action: string;
  page_photo: string;
  language: string;
}

export const defaultPageContent = {
  id: "",
  kicker: "",
  title: "",
  sub_title: "",
  call_to_action: "",
  page_photo: "",
  language: "",
};

export type PageType =
  | "faq"
  | "landing"
  | "how_it_works"
  | "IT_consultant"
  | "about_us"
  | "consulting_manager"
  | "looking_for_consultant"
  | "integrity"
  | "cookie";

export interface ExtraPageType {
  id: string;
  page_type: string;
  label: string;
  menu: "forYou" | "service";
  order_no: number | null;
}

export interface PageParams {
  language: "sv" | "en" | string;
  page_type: PageType;
}

export interface ExtraPageParams {
  language: "sv" | "en" | string;
  page_type: string;
}

export const getPages = (
  params?: Record<string, unknown> & Partial<PageParams>
): Promise<AxiosResponse<PaginatedResponse<Page>>> => {
  return API.get("/dynamic-page/", { params: params });
};

export const getPage = (id: string): Promise<AxiosResponse<Page>> => {
  return API.get(`/dynamic-page/${id}/`);
};

export const getPageHTMLContent = (id: string): Promise<AxiosResponse<string>> => {
  return API.get(`/dynamic-page/content/${id}/`);
};

export const getExtraPageTypes = (
  params?: Record<string, unknown> & Partial<ExtraPageParams>
): Promise<AxiosResponse<PaginatedResponse<ExtraPageType>>> => {
  return API.get("/dynamic-page/page-type/", { params: params });
};

export const getExtraPages = (
  params?: Record<string, unknown> & Partial<ExtraPageParams>
): Promise<AxiosResponse<PaginatedResponse<Page>>> => {
  return API.get("/dynamic-page/extra-page/", { params: params });
};

export const getExtraPage = (id: string): Promise<AxiosResponse<Page>> => {
  return API.get(`/dynamic-page/extra-page/${id}/`);
};

export const getExtraPageHTMLContent = (id: string): Promise<AxiosResponse<string>> => {
  return API.get(`/dynamic-page/extra-page/content/${id}/`);
};
