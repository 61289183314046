import { CircularProgress, Dialog, FormHelperText, Grid } from "@mui/material";
import { DialogHeader, WButton, WIcon, WIconTypes } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useEffect, useState } from "react";

import { LoaderContext } from "context/LoaderContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import { changeProfilePicture } from "adapters";
import { BreakpointsContext } from "context/BreakpointContext";

const MAX_IMAGE_SIZE = "300px";
const MAX_IMAGE_FILE_SIZE = 10 * 1024 * 1024; // 10MB

interface ProfileImageModalProps {
  imageData: File;
}

export const ProfileImageModal: FC<ProfileImageModalProps> = ({ imageData }) => {
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { hideModal } = useContext(ModalContext);
  const { user, dispatch } = useContext(UserContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const [fileSizeValidation, setFileSizeValidation] = useState(false);

  const updateProfilePicture = async (data: File | undefined) => {
    if (data) {
      dispatchLoading({ type: "SET_LOADING", payload: "UPDATE_PROFILE_PICTURE" });
      changeProfilePicture(data, user.id)
        .then((res) => {
          addMessage({ content: "success.profileImageUpdated", type: "success" });
          dispatch({ type: "UPDATE_USER", user: { ...user, profile_picture: res.data.file } });
        })
        .catch(() => {
          addMessage({ content: "error.imageUpload", type: "error" });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "UPDATE_PROFILE_PICTURE" });
          hideModal();
        });
    }
  };

  useEffect(() => {
    if (imageData.size < MAX_IMAGE_FILE_SIZE) {
      setFileSizeValidation(true);
      return;
    }
    setFileSizeValidation(false);
  }, [imageData]);

  return (
    <>
      <Dialog fullScreen={isDesktop ? false : true} open={true} onClose={() => hideModal()}>
        <DialogHeader
          returnText={localize("profileSections.appSettings")}
          handleClose={() => hideModal()}
          title={`${localize("common.edit")} ${localize("common.profileImage").toLowerCase()}`}
        />
        <Grid container gap="2rem" paddingBottom="1rem">
          <Grid direction="column" container alignItems="center" gap="1rem">
            <div
              style={{
                width: "100%",
                display: "flex",
                position: "relative",
                height: MAX_IMAGE_SIZE,
                backgroundColor: "black",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                width={MAX_IMAGE_SIZE}
                height={MAX_IMAGE_SIZE}
                style={{ position: "absolute" }}
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M50 0H0V50V100H50H100V50V0H50ZM50 0C77.6142 0 100 22.3858 100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0 22.3858 22.3858 0 50 0Z"
                  fill="#181818ab"
                />
              </svg>
              <img
                alt="Profile Image"
                src={URL.createObjectURL(imageData)}
                style={{
                  width: MAX_IMAGE_SIZE,
                  height: MAX_IMAGE_SIZE,
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
            </div>

            {isLoading("UPDATE_PROFILE_PICTURE") && (
              <Grid container justifyContent="space-around" marginTop="2rem">
                <CircularProgress color="success" />
              </Grid>
            )}
          </Grid>

          <WButton
            disabled={!fileSizeValidation}
            onClick={() => updateProfilePicture(imageData)}
            variant="outlined"
            fullWidth
            startIcon={
              <WIcon icon={WIconTypes.check} color={!fileSizeValidation ? "error" : "primary"} />
            }
            sx={{ margin: "0 1rem", position: "sticky", top: 0 }}
          >
            {localize("form.save")}
          </WButton>
          {!fileSizeValidation && (
            <Grid container padding="0 1rem">
              <FormHelperText error>
                {localize("form.imageMaxSizeError", { size: "2.5" })}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </Dialog>
    </>
  );
};
