import { Grid, Typography } from "@mui/material";
import { Job } from "adapters";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, ReactNode, useContext } from "react";
import { colors, defaultStyles } from "styles/colors";
import { prettifyDate } from "utils/PrettifyDate";
import { ExpandedJobDescription } from "./JobCard/ExpandedJobDescription";
import { LocationsComponent } from "./JobCardTitleComponent";
import { WButton } from "./styledComponents/WButton";
import { WIcon, WIconTypes } from "./WIcon";
import { WTypography } from "./WTypography";

interface DetailedJobHeaderMobileProps {
  job: Job;
  buttonContainer?: ReactNode;
  isExpanded?: boolean;
  showLogo?: boolean;
  handleOpenApplicationForm?: () => void;
}

export const DetailedJobHeaderMobile: FC<DetailedJobHeaderMobileProps> = ({
  job,
  buttonContainer,
  isExpanded = false,
  showLogo = true,
  handleOpenApplicationForm,
}) => {
  const { localize } = useContext(LocaleContext);

  return (
    <Grid container wrap="nowrap" direction="column" gap="1rem" padding="1rem" alignItems="start">
      {showLogo && (
        <Grid container item alignItems="start" justifyContent="space-between">
          <div
            style={{
              display: "flex",
              minHeight: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={
                job.pictures.length
                  ? job.pictures[0].thumb
                  : `${process.env.PUBLIC_URL}/assets/logo/logo_black.png`
              }
              alt="Thumbnail image"
              width="100px"
              style={{ objectFit: "contain", objectPosition: "top" }}
            />
          </div>
          {buttonContainer}
        </Grid>
      )}
      <Grid
        container
        item
        xs={8}
        sm={9}
        md={10}
        lg={11}
        direction="column"
        wrap="nowrap"
        gap="0.3rem"
      >
        <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <WTypography lineClamps={2} variant="body1" fontWeight="bold" color="primary.dark">
            {job.title}
          </WTypography>
          {!showLogo && <>{buttonContainer}</>}
        </Grid>
        <Grid container item gap=".3rem">
          <LocationsComponent locations={job.locations} role={job.role} maxLocations={10} />
          <Typography
            variant="body2"
            color="primary"
            sx={{
              overflowWrap: "break-word",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            - {prettifyDate(job.created, { showRelativeTime: true })}
          </Typography>
        </Grid>

        <Grid container gap=".4rem" marginTop=".2rem">
          {job.skills.map((skill) => {
            return (
              <Grid
                item
                key={skill.id}
                style={{ width: "max-content", height: "max-content", padding: "0 1rem" }}
                border={`1px solid ${colors.mainDark}`}
                borderRadius={defaultStyles.borderRadiusFullyRounded}
              >
                <Typography
                  sx={{ textTransform: "capitalize", color: colors.mainDark }}
                  fontSize="12px"
                  fontWeight="bold"
                >
                  {skill.name}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        {isExpanded && (
          <>
            <ExpandedJobDescription job={job} />
            {handleOpenApplicationForm && (
              <Grid container padding="2rem 1rem">
                <WButton
                  onClick={handleOpenApplicationForm}
                  variant="contained"
                  fullWidth
                  startIcon={<WIcon icon={WIconTypes.award} color="secondary" />}
                >
                  {localize("detailedJobDialog.applyButton")}
                </WButton>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
