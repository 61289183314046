import React, { FC, useContext } from "react";
import { Card, Grid } from "@mui/material";
import { AvatarItem, WIcon, WIconButton, WIconTypes } from "components";
import { colors, defaultStyles } from "styles/colors";
import { ModalContext } from "context/ModalContext";
import { AvailabilityTag } from "components/AvailabilityTag";
import { ToolTipListItem } from "components/ToolTipListItem";
import { UserContext } from "context/UserContext";

interface UserListCardSmallProps {
  userId: string;
  selected?: boolean;
  onToggleSelect?: (userId: string) => void;
}

export const UserListCardSmall: FC<UserListCardSmallProps> = ({
  userId,
  selected,
  onToggleSelect,
}) => {
  const { showModal } = useContext(ModalContext);
  const { users } = useContext(UserContext);

  const user = users.find((u) => u.id === userId);

  if (!user) {
    return null;
  }

  const handleUserClick = async () => {
    showModal("CANDIDATE_MODAL", {
      candidateId: userId,
    });
  };

  return (
    <Card
      sx={{
        cursor: "pointer",
        width: "100%",
        position: "relative",
        borderRadius: defaultStyles.borderRadius,
        boxShadow: `${defaultStyles.cardShadow}${
          selected ? `, 0 0 0 2px ${colors.main} inset` : ""
        }`,
        padding: ".6rem .3rem .3rem .3rem",
        overflow: "visible",
        transition: "all 0.2s ease-out",
        "&:hover": { backgroundColor: colors.grey },
      }}
      onClick={handleUserClick}
      elevation={4}
    >
      <Grid container alignItems="center" direction="row" gap=".5rem" wrap="nowrap" columns={20}>
        {onToggleSelect ? (
          <Grid item>
            <WIconButton
              onClick={(event) => {
                event.stopPropagation();
                onToggleSelect(user.id);
              }}
              aria-label="toggle select"
            >
              <WIcon icon={selected ? WIconTypes.checkSquare : WIconTypes.square} />
            </WIconButton>
          </Grid>
        ) : null}
        <Grid item>
          <AvatarItem
            size="tiny"
            imageSrc={user.profile_picture}
            key={user.profile_picture}
            firstName={user.first_name}
            lastName={user.last_name}
          />
        </Grid>
        <Grid item>
          <AvailabilityTag type="small" date={user.availability_date} />
        </Grid>
        <Grid container item xs={17} columns={18} gap=".5rem" alignItems="center">
          <Grid item xs={3} zeroMinWidth>
            <ToolTipListItem fontWeight="bold" label={`${user.first_name} ${user.last_name}`} />
          </Grid>
          <Grid item xs={2} zeroMinWidth>
            <ToolTipListItem label={user.phone} />
          </Grid>
          <Grid item xs={4} zeroMinWidth>
            <ToolTipListItem label={user.email} />
          </Grid>
          <Grid item xs={5} zeroMinWidth maxWidth="250px">
            <ToolTipListItem
              fontWeight="bold"
              label={user.skills.map((skill) => skill.name).join(", ")}
            />
          </Grid>
          <Grid item xs={3} zeroMinWidth>
            {!!user.regions.length && (
              <ToolTipListItem label={user.regions.map((region) => region.name).join(", ")} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
