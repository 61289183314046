import { List, Collapse } from "@mui/material";
import React, { FC, useState, useContext } from "react";
import { WButton, WListItem, WIcon, WIconTypes } from "components";
import { LocaleContext } from "context/LocaleContext";
import { JobFilterItem, JobFilterItemWithParent } from "./dialogs/FiltersDialog";
import { FILTER_ITEM_HEIGHT } from "./FiltersDialog/FiltersBody";
import { BreakpointsContext } from "context/BreakpointContext";

interface ExpandableListItemProps {
  label: string;
  value: string;
  isChecked: boolean;
  isExpanded: boolean;
  disableExpand?: boolean;
  expandableListData: {
    label: string;
    value: string;
    isChecked: boolean;
    isDisabled?: boolean;
  }[];
  // Event handlers
  handleClick: (item: JobFilterItem | JobFilterItemWithParent) => void;
  handleSubListClick?: (item: JobFilterItem) => void;
}

export const ExpandableListItem: FC<ExpandableListItemProps> = ({
  value,
  label,
  isChecked,
  isExpanded = false,
  disableExpand = false,
  expandableListData,
  handleClick,
  handleSubListClick,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const [expand, setExpanded] = useState(isExpanded);

  return (
    <div>
      <WListItem
        showExpand={!disableExpand}
        value={value}
        label={label}
        handleExpandClick={() => setExpanded(!expand)}
        expand={expand}
        isChecked={isChecked}
        handleClick={() => handleClick({ label, value })}
        sx={{ padding: "0 .5rem" }}
      />
      {!disableExpand && (
        <Collapse in={expand} mountOnEnter>
          <List
            disablePadding
            style={{
              boxShadow: "inset 0px 4px 4px -4px rgba(0, 0, 0, 0.25)",
              backgroundColor: "#F2F2F2",
              paddingLeft: "1rem",
              maxHeight: `${FILTER_ITEM_HEIGHT * (isDesktop ? 15 : 10)}px`,
              overflow: "auto",
            }}
          >
            {expandableListData.map((subEntry) => (
              <WListItem
                key={subEntry.value}
                value={subEntry.value}
                disabled={subEntry.isDisabled}
                label={subEntry.label}
                isChecked={subEntry.isChecked}
                handleClick={() => {
                  if (handleSubListClick) {
                    handleSubListClick({ value: subEntry.value, label: subEntry.label });
                  }
                }}
                sx={{ paddingLeft: ".5rem" }}
              />
            ))}
          </List>
          {/** Only show "expand" text if list is more than 4 entries  */}
          {expandableListData.length > 4 && (
            <WButton
              endIcon={<WIcon icon={WIconTypes.arrowUp} size="small" />}
              sx={{ paddingLeft: "1rem" }}
              onClick={() => setExpanded(!expand)}
            >
              {localize("searchbar.showLessSubcategories")}
            </WButton>
          )}
        </Collapse>
      )}
    </div>
  );
};
