import { WTypography } from "components";
import { WMenuButton } from "components/styledComponents/WButton";
import { FilterPeriod, StatisticsFilterContext } from "context/StatisticsFilterContext";
import React, { FC, useContext, useState } from "react";
import { ButtonGroup, Grid, ThemeProvider, Typography } from "@mui/material";
import { StatisticHeader } from "components/headers/StatisticHeader";
import { AgentsTab } from "components/StatisticView/AgentsTab";
import { CandidatesTab } from "components/StatisticView/CandidatesTab";
import { useHistory } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { SelectDropdown } from "components/MultiSelectDropdown";
import { LocaleContext } from "context/LocaleContext";
import { colors, defaultStyles } from "styles/colors";

declare module "@mui/material/styles" {
  interface Palette {
    main: Palette["primary"];
  }

  interface PaletteOptions {
    main?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    main: true;
  }
}

const theme = createTheme({
  palette: {
    main: {
      main: colors.main,
      light: colors.mainLight,
      dark: colors.mainLight,
      contrastText: colors.white,
    },
  },
});

type TabType = "AGENTS" | "CANDIDATES";

const StatisticView: FC = () => {
  const [currentTab, setTab] = useState<TabType>("CANDIDATES");
  const { localize } = useContext(LocaleContext);

  const history = useHistory();
  const { period, setPeriod } = useContext(StatisticsFilterContext);

  const renderCurrentTabContent = (currentTab: TabType) => {
    switch (currentTab) {
      case "CANDIDATES":
        return <CandidatesTab />;

      case "AGENTS":
        return <AgentsTab />;

      default:
        return <CandidatesTab />;
    }
  };

  return (
    <Grid container flexDirection="column">
      <StatisticHeader
        handleAvatarPicker={() => {
          history.push("/settings");
        }}
      />

      <Grid item padding="30px">
        <Grid
          container
          wrap="nowrap"
          height="80px"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="30px"
          sx={{
            paddingX: "25px",
            backgroundColor: colors.white,
            borderRadius: defaultStyles.borderRadius,
            boxShadow: defaultStyles.cardShadow,
          }}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <ButtonGroup variant="contained" sx={{ boxShadow: 0 }}>
                {menuTypes.map(({ label, tag }) => {
                  const selected = tag === currentTab;
                  return (
                    <WMenuButton
                      key={label}
                      variant={selected ? "contained" : "outlined"}
                      color="main"
                      onClick={() => setTab(tag as TabType)}
                    >
                      <Typography variant="body2" color={colors.mainDark}>
                        {localize(label)}
                      </Typography>
                    </WMenuButton>
                  );
                })}
              </ButtonGroup>
            </ThemeProvider>
          </Grid>

          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {localize("statisticView.headerMessage")}
            </Typography>
          </Grid>

          <Grid item>
            <WTypography variant="caption" color={colors.mainDark}>
              {localize("statisticView.choosePeriod")}
            </WTypography>
            <SelectDropdown
              selectedId={period}
              items={[
                { id: "week", primary: localize("statisticView.lastDays", { days: "7" }) },
                { id: "month", primary: localize("statisticView.lastDays", { days: "30" }) },
              ]}
              onSelect={(newPeriod) => setPeriod(newPeriod as FilterPeriod)}
              width="200px"
            />
          </Grid>
        </Grid>

        <Grid item>{renderCurrentTabContent(currentTab)}</Grid>
      </Grid>
    </Grid>
  );
};

export default StatisticView;

const menuTypes = [
  { label: "statisticView.tabCandidates", tag: "CANDIDATES" },
  { label: "statisticView.tabAgents", tag: "AGENTS" },
];
