import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const QualityConsultingSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 289 253"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_336_6748)">
          <path
            d="M273.419 228.36L273.866 218.297C278.523 215.803 283.719 214.485 289 214.459C281.731 220.412 282.64 231.887 277.712 239.892C276.155 242.377 274.061 244.479 271.584 246.043C269.106 247.607 266.309 248.593 263.4 248.927L257.317 252.657C256.482 247.996 256.666 243.209 257.856 238.626C259.046 234.043 261.214 229.773 264.21 226.111C265.911 224.072 267.862 222.257 270.016 220.707C271.473 224.555 273.419 228.36 273.419 228.36Z"
            fill="#F2F2F2"
          />
          <path
            d="M237.299 252.047C250.762 252.047 261.676 241.114 261.676 227.628C261.676 214.142 250.762 203.21 237.299 203.21C223.837 203.21 212.923 214.142 212.923 227.628C212.923 241.114 223.837 252.047 237.299 252.047Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M227.351 218.077C227.016 219.878 226.468 221.632 225.717 223.302C221.417 232.414 233.662 229.444 238.039 229.444C242.823 229.444 247.401 231.964 246.702 220.765C246.404 215.982 242.823 212.087 238.039 212.087C233.952 212.087 228.206 213.907 227.351 218.077Z"
            fill="#2F2E41"
          />
          <path
            d="M237.322 229.441C241.21 229.441 244.362 226.284 244.362 222.389C244.362 218.494 241.21 215.337 237.322 215.337C233.434 215.337 230.282 218.494 230.282 222.389C230.282 226.284 233.434 229.441 237.322 229.441Z"
            fill="#FFB6B6"
          />
          <path
            d="M232.541 215.201C233.774 214.447 235.187 214.04 236.632 214.021C238.077 214.002 239.5 214.372 240.753 215.092C242.006 215.813 243.043 216.857 243.755 218.117C244.467 219.376 244.828 220.804 244.801 222.251C241.214 223.153 237.445 223.843 233.619 222.466L232.716 220.252L232.183 222.468C231.018 222.945 229.85 223.349 228.682 222.464C228.635 221.021 228.966 219.59 229.643 218.316C230.32 217.041 231.319 215.967 232.541 215.201V215.201Z"
            fill="#2F2E41"
          />
          <path
            d="M256.253 243.865C252.03 248.678 246.087 251.635 239.706 252.097C233.325 252.559 227.019 250.489 222.149 246.334L224.24 240.49C224.712 239.452 225.458 238.562 226.397 237.916C227.336 237.27 228.432 236.893 229.569 236.824L230.899 236.742L234.071 234.002C234.071 234.002 242.617 233.696 242.49 233.55L247.667 236.669L247.662 236.647L249.697 236.772C250.834 236.841 251.931 237.218 252.87 237.864C253.809 238.51 254.554 239.4 255.026 240.438L256.253 243.865Z"
            fill="#3F3D56"
          />
          <path
            d="M48.7532 55.5114C48.7447 60.3408 47.3066 65.0593 44.621 69.0701C41.9354 73.0809 38.1227 76.2039 33.6652 78.0442C29.2077 79.8844 24.3055 80.3593 19.5786 79.4087C14.8517 78.4581 10.5123 76.1248 7.10922 72.7038C3.70614 69.2828 1.3922 64.9277 0.460008 60.1894C-0.472184 55.451 0.01924 50.5422 1.87214 46.0836C3.72505 41.6251 6.8562 37.817 10.8697 35.141C14.8831 32.465 19.5986 31.0413 24.4198 31.0498C27.6234 31.0472 30.7958 31.6787 33.7549 32.9082C36.714 34.1376 39.4014 35.9407 41.6626 38.2139C43.9238 40.487 45.7143 43.1853 46.9311 46.1538C48.148 49.1223 48.7672 52.3024 48.7532 55.5114V55.5114Z"
            fill="#F2F2F2"
          />
          <path
            d="M13.0782 47.5248C14.0862 44.3069 16.3783 41.3557 19.5483 40.2678C22.7185 39.18 27.3604 40.8371 29.7436 43.2164C34.1031 47.5685 35.1669 62.5196 32.1718 67.2444C31.5761 67.2128 29.5204 67.1894 28.9175 67.1668L28.0645 64.3191V67.1387C27.1039 67.109 26.1358 67.0885 25.1602 67.0771C21.8891 67.0365 18.7611 65.7271 16.4339 63.4241C14.1066 61.1211 12.7614 58.004 12.6809 54.7281C12.6029 51.5941 12.681 48.7928 13.0782 47.5248Z"
            fill="#CCCCCC"
          />
          <path
            d="M24.015 58.1896C27.8518 58.1896 30.9622 55.0739 30.9622 51.2305C30.9622 47.3872 27.8518 44.2715 24.015 44.2715C20.1782 44.2715 17.0679 47.3872 17.0679 51.2305C17.0679 55.0739 20.1782 58.1896 24.015 58.1896Z"
            fill="#CCCCCC"
          />
          <path
            d="M18.0195 45.1191L26.0185 41.1069C27.6539 41.8451 29.0623 43.0081 30.0977 44.4753C31.1331 45.9425 31.7579 47.6605 31.9071 49.451L32.1065 51.8433L28.7569 50.9892L28.3899 47.9302L27.8211 50.7506L26.2753 50.3566L26.29 45.6075L24.7432 50.3532L19.2223 49.0826L18.0195 45.1191Z"
            fill="#CCCCCC"
          />
          <path
            d="M42.4658 71.6274C38.2426 76.4409 32.2992 79.3978 25.9184 79.8597C19.5377 80.3217 13.2316 78.2516 8.36133 74.0963L10.4522 68.2522C10.9247 67.2142 11.6703 66.3247 12.6091 65.6789C13.548 65.0331 14.6445 64.6555 15.7813 64.5865L17.1115 64.5047L20.2832 61.7645C20.2832 61.7645 28.8292 61.4589 28.7024 61.3125L33.879 64.4315L33.8747 64.41L35.9096 64.5348C37.0465 64.6035 38.1432 64.981 39.0821 65.6268C40.021 66.2726 40.7666 67.1624 41.2388 68.2006L42.4658 71.6274Z"
            fill="#CCCCCC"
          />
          <path
            d="M24.55 192.032C38.0128 192.032 48.9266 181.099 48.9266 167.614C48.9266 154.128 38.0128 143.195 24.55 143.195C11.0871 143.195 0.17334 154.128 0.17334 167.614C0.17334 181.099 11.0871 192.032 24.55 192.032Z"
            fill="#F2F2F2"
          />
          <path
            d="M14.6019 158.062C14.2673 159.863 13.7187 161.617 12.9678 163.287C8.66769 172.399 20.9135 169.429 25.2897 169.429C30.0744 169.429 34.6525 171.949 33.9533 160.751C33.6546 155.967 30.0744 152.072 25.2897 152.072C21.2027 152.072 15.4565 153.892 14.6019 158.062Z"
            fill="#CCCCCC"
          />
          <path
            d="M24.5724 169.427C28.4605 169.427 31.6125 166.27 31.6125 162.375C31.6125 158.48 28.4605 155.323 24.5724 155.323C20.6842 155.323 17.5322 158.48 17.5322 162.375C17.5322 166.27 20.6842 169.427 24.5724 169.427Z"
            fill="#CCCCCC"
          />
          <path
            d="M19.7915 155.186C21.0252 154.433 22.4382 154.025 23.8829 154.006C25.3276 153.987 26.7509 154.357 28.004 155.078C29.257 155.798 30.2938 156.843 31.0059 158.102C31.7181 159.361 32.0794 160.789 32.0521 162.236C28.4647 163.138 24.696 163.829 20.8704 162.452L19.967 160.237L19.4343 162.453C18.2693 162.93 17.1013 163.334 15.9332 162.449C15.8855 161.006 16.2168 159.576 16.8939 158.301C17.5709 157.027 18.5701 155.952 19.7915 155.186Z"
            fill="#CCCCCC"
          />
          <path
            d="M43.5039 183.85C39.2807 188.664 33.3373 191.62 26.9565 192.082C20.5758 192.544 14.2697 190.474 9.39941 186.319L11.4902 180.475C11.9627 179.437 12.7084 178.547 13.6472 177.902C14.586 177.256 15.6826 176.878 16.8194 176.809L18.1495 176.727L21.3213 173.987C21.3213 173.987 29.8673 173.682 29.7405 173.535L34.9171 176.654L34.9128 176.633L36.9477 176.757C38.0846 176.826 39.1813 177.204 40.1202 177.849C41.0591 178.495 41.8046 179.385 42.2769 180.423L43.5039 183.85Z"
            fill="#CCCCCC"
          />
          <path
            d="M129.425 169.715C151.222 169.715 168.893 152.014 168.893 130.18C168.893 108.345 151.222 90.6445 129.425 90.6445C107.627 90.6445 89.957 108.345 89.957 130.18C89.957 152.014 107.627 169.715 129.425 169.715Z"
            fill="#F2F2F2"
          />
          <path
            d="M131.034 134.213C138.142 134.213 143.904 128.441 143.904 121.321C143.904 114.201 138.142 108.429 131.034 108.429C123.926 108.429 118.164 114.201 118.164 121.321C118.164 128.441 123.926 134.213 131.034 134.213Z"
            fill="#CCCCCC"
          />
          <path
            d="M142.365 112.805C142.365 112.805 145.716 106.388 138.344 105.804C138.344 105.804 132.059 100.094 125.653 104.76C125.653 104.76 122.159 104.76 120.249 108.721C120.249 108.721 117.501 107.677 116.897 110.471C116.897 110.471 114.886 116.305 116.897 121.555C118.907 126.805 119.575 127.388 119.575 127.388C119.575 127.388 118.165 120.873 126.207 120.29C134.25 119.706 141.629 114.659 142.299 121.076C142.969 127.493 143.706 124.594 143.706 124.594C143.706 124.594 150.072 115.43 142.365 112.805Z"
            fill="#CCCCCC"
          />
          <path
            d="M158.888 156.468C152.051 164.262 142.428 169.049 132.097 169.797C121.766 170.545 111.556 167.193 103.67 160.466L107.056 151.004C107.821 149.323 109.028 147.883 110.548 146.837C112.068 145.792 113.843 145.18 115.684 145.068L117.838 144.936L122.973 140.499C122.973 140.499 136.81 140.005 136.604 139.768L144.986 144.818L144.979 144.783L148.273 144.985C150.114 145.096 151.89 145.707 153.41 146.753C154.93 147.799 156.137 149.239 156.902 150.92L158.888 156.468Z"
            fill="#CCCCCC"
          />
          <path
            d="M64.421 130.31C77.8839 130.31 88.7976 119.378 88.7976 105.892C88.7976 92.4061 77.8839 81.4736 64.421 81.4736C50.9582 81.4736 40.0444 92.4061 40.0444 105.892C40.0444 119.378 50.9582 130.31 64.421 130.31Z"
            fill="#F2F2F2"
          />
          <path
            d="M63.7481 108.917C68.0456 108.917 71.5294 105.427 71.5294 101.122C71.5294 96.8174 68.0456 93.3276 63.7481 93.3276C59.4506 93.3276 55.9668 96.8174 55.9668 101.122C55.9668 105.427 59.4506 108.917 63.7481 108.917Z"
            fill="#CCCCCC"
          />
          <path
            d="M82.709 122.229C78.4858 127.043 72.5423 130 66.1616 130.462C59.7809 130.924 53.4747 128.854 48.6045 124.698L50.6953 118.854C51.1678 117.816 51.9135 116.927 52.8523 116.281C53.7911 115.635 54.8877 115.258 56.0245 115.189L57.3546 115.107L60.5263 112.367C60.5263 112.367 69.0723 112.061 68.9456 111.915L74.1221 115.034L74.1178 115.012L76.1528 115.137C77.2897 115.206 78.3864 115.583 79.3253 116.229C80.2642 116.875 81.0097 117.764 81.482 118.803L82.709 122.229Z"
            fill="#CCCCCC"
          />
          <path
            d="M71.9551 96.2883C71.2975 93.9308 69.8036 91.8947 67.7537 90.5623C66.1201 89.5156 64.0815 88.9813 62.2308 89.5348C60.38 90.0883 58.8478 91.9077 58.9689 93.8735C58.626 93.7832 58.2648 93.7909 57.9261 93.8957C57.5874 94.0005 57.2847 94.1981 57.0524 94.4663C56.588 95.0154 56.2651 95.6703 56.1121 96.3734C55.5182 98.5774 55.5575 100.905 56.2256 103.087L56.1888 103.4C57.0918 103.251 57.7766 102.475 58.2025 101.648C58.6284 100.821 58.8735 99.9037 59.3351 99.0971C59.7967 98.2905 60.5632 97.571 61.4776 97.5458L61.397 98.9459L63.7571 97.7279C63.57 98.1763 63.3829 98.6247 63.1957 99.0731C63.8828 98.7854 64.5698 98.4978 65.2569 98.2101C65.1801 98.6413 65.1033 99.0725 65.0265 99.5036C65.2066 99.1294 65.4607 98.7956 65.7734 98.5225C66.086 98.2494 66.4507 98.0427 66.8454 97.9149C67.24 97.7871 67.6565 97.7408 68.0696 97.7788C68.4826 97.8169 68.8837 97.9385 69.2485 98.1362C70.0701 98.69 70.6623 99.5245 70.9145 100.484C71.164 101.441 71.2979 102.425 71.3134 103.414C72.3751 101.193 72.6028 98.6635 71.9551 96.2883V96.2883Z"
            fill="#CCCCCC"
          />
          <path
            d="M116.152 125.559V125.573C116.13 125.52 116.115 125.464 116.108 125.406L116.152 125.559Z"
            fill="#F2F2F2"
          />
          <path
            d="M116.152 125.573C116.189 125.726 116.232 125.878 116.276 126.024C116.216 126.04 116.155 126.052 116.094 126.06L116.152 125.573Z"
            fill="#F2F2F2"
          />
          <path
            d="M168.892 130.098C168.891 140.314 164.942 150.132 157.872 157.495C150.803 164.858 141.163 169.194 130.972 169.594V90.6025C141.163 91.0002 150.805 95.3354 157.875 102.699C164.944 110.062 168.893 119.882 168.892 130.098V130.098Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M116.108 125.406L116.152 125.559V125.573C116.13 125.52 116.115 125.464 116.108 125.406V125.406Z"
            fill="#FFB6B6"
          />
          <path
            d="M140.934 122.378C140.937 123.421 140.808 124.461 140.55 125.472V125.479C139.971 127.759 138.767 129.833 137.075 131.465C135.383 133.097 133.269 134.223 130.972 134.717V110.031C133.792 110.638 136.318 112.194 138.131 114.441C139.944 116.688 140.933 119.489 140.934 122.378Z"
            fill="#A0616A"
          />
          <path
            d="M159.032 156.55C155.509 160.575 151.204 163.839 146.379 166.142C141.555 168.444 136.312 169.738 130.972 169.943V140.259C134.054 140.114 136.845 139.961 136.751 139.853L145.132 144.9L145.125 144.864L148.417 145.067C150.258 145.177 152.034 145.787 153.554 146.833C155.075 147.878 156.282 149.32 157.045 151.001L158.952 156.325L159.032 156.55Z"
            fill="#3F3D56"
          />
          <path
            d="M116.152 125.573C116.189 125.726 116.232 125.878 116.276 126.024C116.216 126.04 116.155 126.052 116.094 126.06L116.152 125.573Z"
            fill="#2F2E41"
          />
          <path
            d="M140.586 126.082C140.572 125.879 140.565 125.683 140.55 125.479V125.472C140.508 124.075 140.304 122.689 139.941 121.339C139.47 119.814 138.585 118.354 137.244 117.541C135.235 116.321 132.415 116.982 130.972 118.812V103.582C132.338 103.939 133.637 104.513 134.822 105.281C138.141 107.437 140.559 110.732 141.623 114.549C142.671 118.393 142.303 122.487 140.586 126.082Z"
            fill="#2F2E41"
          />
          <path
            d="M130.43 253C130.304 253.006 130.182 252.962 130.089 252.878C129.996 252.794 129.939 252.677 129.933 252.551V0.448568C129.945 0.325491 130.003 0.21153 130.095 0.128689C130.187 0.0458483 130.306 0 130.43 0C130.553 0 130.672 0.0458483 130.764 0.128689C130.856 0.21153 130.914 0.325491 130.926 0.448568V252.551C130.92 252.677 130.864 252.794 130.77 252.878C130.677 252.962 130.555 253.006 130.43 253V253Z"
            fill="#2F2E41"
          />
          <path
            d="M179.942 117.841L166.213 114.467L147.908 98.8789L150.956 94.0317L167.538 104.613C167.538 104.613 178.508 107.403 182.261 110.62L179.942 117.841Z"
            fill="#FFB6B6"
          />
          <path
            d="M148.545 99.1735C150.393 99.1735 151.89 97.6735 151.89 95.8231C151.89 93.9727 150.393 92.4727 148.545 92.4727C146.698 92.4727 145.201 93.9727 145.201 95.8231C145.201 97.6735 146.698 99.1735 148.545 99.1735Z"
            fill="#FFB6B6"
          />
          <path
            d="M180.587 108.82L167.855 103.62L161.736 110.898L166.282 114.65L178.674 117.876L178.683 117.909C179.553 121.122 185.15 123.304 189.692 124.568C191.19 124.984 192.789 124.814 194.167 124.094C195.545 123.373 196.599 122.156 197.114 120.687V120.687C197.488 119.601 197.55 118.432 197.295 117.312C197.04 116.192 196.477 115.166 195.67 114.35L191.546 110.207L180.587 108.82Z"
            fill="#E6E6E6"
          />
          <path
            d="M145.383 239.522L140.921 237.821L145.346 219.772L151.931 222.282L145.383 239.522Z"
            fill="#FFB6B6"
          />
          <path
            d="M147.075 238.496L145.82 238.017L141.675 234.151L138.288 235.146L138.288 235.146C136.803 234.58 135.154 234.629 133.704 235.28C132.255 235.932 131.123 237.134 130.558 238.622L130.488 238.804L144.875 244.288L147.075 238.496Z"
            fill="#2F2E41"
          />
          <path
            d="M190.488 247.014L185.714 247.014L183.443 228.569L190.489 228.569L190.488 247.014Z"
            fill="#FFB6B6"
          />
          <path
            d="M191.706 245.453L190.362 245.453L185.116 243.316L182.304 245.453H182.304C180.715 245.453 179.191 246.085 178.067 247.211C176.944 248.336 176.313 249.863 176.312 251.454V251.649L191.706 251.65L191.706 245.453Z"
            fill="#2F2E41"
          />
          <path
            d="M197.768 167.563C197.768 167.563 198.325 174.264 196.931 180.686C196.058 184.711 195.732 193.32 195.61 199.184C195.538 202.675 193.97 203.231 193.97 203.231L195.01 211.259L192.472 240.435L183.274 239.597L180.765 180.686L164.233 193.78L164.692 195.312C164.915 196.056 163.27 195.961 162.612 196.373L160.99 200.143C161.022 200.766 160.862 201.384 160.533 201.913C160.204 202.443 159.72 202.858 159.148 203.104L150.532 228.777L143.815 224.739L146.054 201.413L149.189 190.647L161.812 175.102L162.091 170.635L163.345 170.686L163.984 168.82L165.157 165.609L197.768 167.563Z"
            fill="#2F2E41"
          />
          <path
            d="M198.249 168.971L198.299 168.901C200.952 165.18 196.962 156.239 196.36 154.943C197.075 154.711 197.687 154.238 198.092 153.604C198.498 152.97 198.671 152.216 198.583 151.468L198.324 149.259L198.054 147.092L199.991 145.152L199.996 145.103L201.111 135.331L202.732 128.841C203.343 126.387 203.291 123.815 202.581 121.388C201.872 118.961 200.53 116.767 198.695 115.031L194.948 111.492L190.394 104.743H183.05L180.533 109.507C178.091 110.072 175.925 111.477 174.412 113.477C172.899 115.478 172.134 117.946 172.251 120.453L172.822 132.948L170.588 148.506L170.586 151.321L169.423 152.617C169.004 153.083 168.699 153.641 168.533 154.247C168.368 154.852 168.345 155.488 168.468 156.103L168.621 156.87C168.03 157.124 167.508 157.516 167.099 158.014C166.69 158.511 166.407 159.1 166.272 159.73L165.863 161.64C165.513 162.102 163.206 165.185 163.206 166.027C163.206 166.154 163.299 166.276 163.499 166.412C165.072 167.479 173.764 169.654 177.612 168.111C181.71 166.47 198.001 168.932 198.165 168.957L198.249 168.971Z"
            fill="#E6E6E6"
          />
          <path
            d="M185.774 102.564C191.145 102.564 195.499 98.2022 195.499 92.8217C195.499 87.4413 191.145 83.0796 185.774 83.0796C180.403 83.0796 176.048 87.4413 176.048 92.8217C176.048 98.2022 180.403 102.564 185.774 102.564Z"
            fill="#FFB6B6"
          />
          <path
            d="M182.602 135.617L175.846 148.056L156.133 161.812L152.23 157.624L166.672 144.26C166.672 144.26 172.161 134.345 176.223 131.531L182.602 135.617Z"
            fill="#FFB6B6"
          />
          <path
            d="M153.345 163.767C155.192 163.767 156.69 162.267 156.69 160.416C156.69 158.566 155.192 157.066 153.345 157.066C151.498 157.066 150 158.566 150 160.416C150 162.267 151.498 163.767 153.345 163.767Z"
            fill="#FFB6B6"
          />
          <path
            d="M181.994 137.275L186.243 127.061L184.706 121.417C184.4 120.31 183.792 119.311 182.949 118.532C182.106 117.753 181.062 117.226 179.936 117.01V117.01C178.407 116.728 176.829 117.038 175.519 117.877C174.21 118.716 173.268 120.022 172.883 121.531C171.719 126.107 170.825 132.056 173.176 134.41L173.2 134.434L166.206 143.57L176.506 149.401L181.994 137.275Z"
            fill="#E6E6E6"
          />
          <path
            d="M195.383 86.1083C194.592 85.0406 193.455 84.2816 192.586 83.2772C192.148 82.6946 191.678 82.1374 191.177 81.6083C190.126 80.7337 188.801 80.2594 187.435 80.2692C185.953 80.2311 184.483 80.5429 183.144 81.1795C181.805 81.8161 180.635 82.7595 179.727 83.9335C179.02 83.5184 178.314 83.1034 177.607 82.6883C177.328 83.4244 177.048 84.1605 176.769 84.8967C176.54 84.6972 176.248 84.5854 175.944 84.5811C175.641 84.5768 175.345 84.6804 175.111 84.8733C174.876 85.0663 174.717 85.3363 174.662 85.6354C174.608 85.9345 174.66 86.2434 174.811 86.5074C174.643 85.9868 173.872 85.8763 173.447 86.2203C173.251 86.4111 173.105 86.6481 173.023 86.9098C172.941 87.1716 172.925 87.4497 172.978 87.7188C173.065 88.3143 173.328 88.87 173.733 89.3147C174.137 89.7593 174.666 90.0725 175.25 90.214C176.172 90.4198 177.245 90.2036 177.983 90.7944C178.575 91.2681 178.754 92.1306 179.358 92.5886C180.102 93.1529 181.154 92.8919 182.079 93.0166C182.885 93.1552 183.61 93.5916 184.111 94.2397C184.612 94.8877 184.852 95.7004 184.784 96.5171C184.897 95.9948 185.112 95.4999 185.417 95.061C185.573 94.8441 185.788 94.6772 186.037 94.5805C186.286 94.4838 186.557 94.4615 186.819 94.5162C187.661 94.7692 187.799 95.9196 187.595 96.7766C187.053 99.0496 185.001 102.723 185.001 102.723C187.666 103.777 190.841 102.781 192.897 100.783C194.953 98.7843 196.024 95.9548 196.491 93.1233C196.886 90.7222 196.83 88.0627 195.383 86.1083Z"
            fill="#2F2E41"
          />
          <path
            d="M283.419 252.018C283.419 252.088 283.406 252.157 283.379 252.222C283.352 252.287 283.313 252.346 283.264 252.396C283.214 252.445 283.155 252.485 283.09 252.511C283.026 252.538 282.956 252.552 282.886 252.552H157.368C157.227 252.552 157.091 252.495 156.991 252.395C156.891 252.295 156.835 252.159 156.835 252.018C156.835 251.876 156.891 251.74 156.991 251.64C157.091 251.54 157.227 251.484 157.368 251.484H282.886C282.956 251.484 283.026 251.497 283.09 251.524C283.155 251.551 283.214 251.59 283.264 251.64C283.313 251.689 283.352 251.748 283.379 251.813C283.406 251.878 283.419 251.948 283.419 252.018V252.018Z"
            fill="#CCCCCC"
          />
        </g>
        <defs>
          <clipPath id="clip0_336_6748">
            <rect width="289" height="253" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
