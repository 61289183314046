import React, { FC, useContext, useState } from "react";
import { ButtonGroup, Grid, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { colors } from "styles/colors";
import { WMenuButton } from "components/styledComponents/WButton";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SelectedGroups } from "components/Groups/SelectedGroups";
import { AllGroups } from "components/Groups/AllGroups";

export const GroupsView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const [currentTab, setCurrentTab] = useState<TabType>("SELECTED_GROUPS");

  const theme = createTheme({
    palette: {
      main: {
        main: colors.main,
        light: colors.mainLight,
        dark: colors.mainLight,
        contrastText: colors.white,
      },
    },
  });

  type TabType = "SELECTED_GROUPS" | "ALL_GROUPS";
  const menuTypes = [
    { label: "groups.selectedGroups", tag: "SELECTED_GROUPS" },
    { label: "groups.allGroups", tag: "ALL_GROUPS" },
  ];

  const renderCurrentTabContent = (currentTab: TabType) => {
    switch (currentTab) {
      case "SELECTED_GROUPS":
        return <SelectedGroups />;

      case "ALL_GROUPS":
        return <AllGroups />;

      default:
        return <SelectedGroups />;
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      wrap="nowrap"
      gap="20px"
      padding="30px"
      height="100vh"
      overflow="auto"
    >
      <Grid item>
        <ThemeProvider theme={theme}>
          <ButtonGroup variant="contained" sx={{ boxShadow: 0 }}>
            {menuTypes.map(({ label, tag }) => {
              const selected = tag === currentTab;
              return (
                <WMenuButton
                  key={label}
                  variant={selected ? "contained" : "outlined"}
                  color="main"
                  onClick={() => setCurrentTab(tag as TabType)}
                >
                  <Typography variant="body2" color={colors.mainDark}>
                    {localize(label)}
                  </Typography>
                </WMenuButton>
              );
            })}
          </ButtonGroup>
        </ThemeProvider>
      </Grid>
      <Grid item>{renderCurrentTabContent(currentTab)}</Grid>
    </Grid>
  );
};
