import { Box, Dialog, Grid, Typography } from "@mui/material";
import { WButton } from "components";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import React, { FC, useContext } from "react";

export const UnderConstructionModal: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { hideModal } = useContext(ModalContext);

  return (
    <Dialog open={true}>
      <Grid container direction="column" gap="2rem" alignItems="center" padding="2rem">
        <Box textAlign="center">
          <Typography>{localize("dialog.underConstruction")}</Typography>
        </Box>
        <WButton variant="outlined" onClick={() => hideModal()}>
          OK
        </WButton>
      </Grid>
    </Dialog>
  );
};
