import { addUserCV } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useContext, useRef } from "react";
import { WButton, WIcon, WIconTypes } from "components";
import { LocaleContext } from "context/LocaleContext";

interface CVUploadProps {
  onCVSubmit: (cv: File, alias: string) => void;
  disableBackendRequest?: boolean;
}

export const CVUpload: FC<CVUploadProps> = ({ onCVSubmit, disableBackendRequest }) => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { localize } = useContext(LocaleContext);

  const uploadFile = useRef<HTMLInputElement | null>(null);
  const maxFilenameLength = 50;

  const handleAddCV = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length) {
      const regex = /^.+\.(?:(?:[dD][oO][cC][xX]?)|(?:[pP][dD][fF]))$/;
      const isAcceptedFiletype = regex.test(event.target.files[0].name);

      if (!isAcceptedFiletype) {
        addMessage({ content: "error.addingCVWrongFileType", type: "error", isResponse: false });
        return;
      }

      let alias = event.target.files[0].name;
      // remove extension.
      alias = alias.replace(/\.[^/.]+$/, "");
      if (alias.length > maxFilenameLength) {
        alias = alias.substr(0, maxFilenameLength - 1);
      }
      addCV(event.target.files[0], alias);
    }
  };

  const addCV = (cv: File, alias: string): void => {
    if (disableBackendRequest) {
      onCVSubmit(cv, alias);
      return;
    }
    dispatchLoading({ type: "SET_LOADING", payload: "ADD_CV" });
    addUserCV(cv, alias)
      .then(() => {
        onCVSubmit(cv, alias);
      })
      .catch(() => {
        addMessage({ content: "error.addingCV", type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "ADD_CV" });
      });
  };

  return (
    <WButton
      fullWidth
      variant="outlined"
      startIcon={<WIcon icon={WIconTypes.uploadCloud} />}
      onClick={() => uploadFile.current?.click()}
    >
      {localize("profileView.addCV")}
      <input
        hidden
        ref={uploadFile}
        accept=".doc,.docx,.pdf"
        type="file"
        value=""
        onChange={(e) => {
          handleAddCV(e);
        }}
      />
    </WButton>
  );
};
