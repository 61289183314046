import { Link, LinkProps } from "@mui/material";
import { BreakpointsContext } from "context/BreakpointContext";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FooterProps } from "./layout/Footer";

interface FooterLinkProps {
  text: string;
  route?: string;
  setAnchorEl: ((element: HTMLElement | null) => void) | undefined;
}

export const FooterLink: FC<LinkProps & FooterLinkProps & FooterProps> = ({
  text,
  route,
  setAnchorEl,
}) => {
  const history = useHistory();
  const { isDesktop } = useContext(BreakpointsContext);

  const handleRouting = () => {
    if (route) {
      setAnchorEl && setAnchorEl(null);
      history.push(route);
    }
  };

  return (
    <Link
      underline="none"
      sx={{ cursor: "pointer", fontSize: isDesktop ? "14px" : "12px", fontWeight: "bold" }}
      onClick={handleRouting}
    >
      {text}
    </Link>
  );
};
