import React, { FC, useContext } from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { BreakpointsContext } from "context/BreakpointContext";

export const WiserLogo: FC = () => {
  const { isDesktop } = useContext(BreakpointsContext);
  const history = useHistory();

  return (
    <Grid
      container
      maxWidth="max-content"
      sx={{ cursor: "pointer" }}
      onClick={() => {
        history.push("/");
      }}
    >
      {isDesktop ? (
        <img
          src={`${process.env.PUBLIC_URL}/assets/logo/logo_main.png`}
          alt="Wise Logo"
          height="32px"
          style={{ objectFit: "contain" }}
        />
      ) : (
        <img
          src={`${process.env.PUBLIC_URL}/assets/logo/logo_main.png`}
          alt="Wise Logo"
          height="36px"
          style={{ objectFit: "contain" }}
        />
      )}
    </Grid>
  );
};
