import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { CandidatesTrendOverview } from "./CandidatesTrendOverview";
import { CandidateTypeOfApplications } from "./CandidateTypeOfApplications";
import { CandidatesNumberOfNew } from "./CandidatesNumberOfNew";
import { CandidatesPopularCompetencies } from "./CandidatesPopularCompetencies";
import { CandidatesNumberOfLogins } from "./CandidatesNumberOfLogins";
import { ApplicationDetails, getApplicationsData, getUsersPeriod, UsersPeriod } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { StatisticsFilterContext } from "context/StatisticsFilterContext";
import { prettifyDate } from "utils/PrettifyDate";
import { getDateFromCurrent } from "utils/TimeUnitToDate";

export const CandidatesTab: FC = () => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { period } = useContext(StatisticsFilterContext);

  const [usersPeriod, setUsersPeriod] = useState<UsersPeriod>(defaultUsersPeriod);
  const [previousUsersPeriod, setPreviousUsersPeriod] = useState<UsersPeriod>(defaultUsersPeriod);
  const [applicationsData, setApplicationsData] =
    useState<ApplicationDetails>(defaultApplicationsData);

  const getData = useCallback(async () => {
    const periodLength = period === "week" ? 7 : 30;

    dispatchLoading({ type: "SET_LOADING", payload: "GET_USERS_PERIOD" });
    await Promise.all([
      getUsersPeriod({
        from_date: prettifyDate(getDateFromCurrent(-periodLength + 1).toISOString()),
        to_date: prettifyDate(new Date().toISOString()),
      })
        .then((res) => {
          setUsersPeriod(res.data);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.fetchingJob", duration: 2000 });
        }),
      getUsersPeriod({
        from_date: prettifyDate(getDateFromCurrent(-periodLength * 2 + 1).toISOString()),
        to_date: prettifyDate(getDateFromCurrent(-periodLength).toISOString()),
      })
        .then((res) => {
          setPreviousUsersPeriod(res.data);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.fetchingJob", duration: 2000 });
        }),
    ]);
    dispatchLoading({ type: "STOP_LOADING", payload: "GET_USERS_PERIOD" });
    dispatchLoading({ type: "SET_LOADING", payload: "GET_APPLICATIONS_DATA" });
    await Promise.all([
      getApplicationsData({
        from_date: prettifyDate(getDateFromCurrent(-periodLength + 1).toISOString()),
        to_date: prettifyDate(new Date().toISOString()),
      })
        .then((res) => {
          setApplicationsData(res.data);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.fetchingJobApplications", duration: 2000 });
        }),
    ]);
    dispatchLoading({ type: "STOP_LOADING", payload: "GET_APPLICATIONS_DATA" });
  }, [period]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Grid container width="100%" spacing={3}>
      <Grid item container md={6} xs={12} gap="30px">
        <CandidatesTrendOverview
          total={usersPeriod.created}
          previous={previousUsersPeriod.created}
        />
        <CandidatesPopularCompetencies />
      </Grid>

      <Grid item container md={6} xs={12} gap="30px">
        <CandidateTypeOfApplications
          consult={[applicationsData.consult.not_anonymous, applicationsData.consult.anonymous]}
          gig={[applicationsData.gig.not_anonymous, applicationsData.gig.anonymous]}
          gigVerified={applicationsData.gig.verified}
          consultVerified={applicationsData.consult.verified}
        />
        <CandidatesNumberOfNew
          candidatesPeriod={usersPeriod}
          applicationsPeriod={applicationsData}
        />
        <CandidatesNumberOfLogins logins={usersPeriod} />
      </Grid>
    </Grid>
  );
};

const defaultUsersPeriod: UsersPeriod = {
  created: 0,
  logged_in: 0,
  created_period: [],
  logged_in_period: [],
  logged_in_time_period: [],
};

const defaultApplicationsData: ApplicationDetails = {
  gig: {
    anonymous: -1,
    not_anonymous: -1,
    verified: -1,
    anonymous_verified_period: [],
    not_anonymous_period: [],
    verified_period: [],
  },
  consult: {
    anonymous: -1,
    not_anonymous: -1,
    verified: -1,
    anonymous_verified_period: [],
    not_anonymous_period: [],
    verified_period: [],
  },
};
