import { Grid } from "@mui/material";
import { DynamicHeader, Navbar } from "components";
import { UserContext } from "context/UserContext";
import React, { FC, useContext } from "react";
import { BreakpointsContext } from "context/BreakpointContext";
import { generateWelcomeMessage } from "utils/GenerateWelcomeMessage";

export const MessageHeader: FC = () => {
  const { user } = useContext(UserContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const { welcomeMessage } = generateWelcomeMessage();

  return (
    <DynamicHeader>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        paddingBottom="2rem"
        sx={{
          maxWidth: {
            xs: "100%",
          },
        }}
      >
        {isDesktop ? (
          <Navbar title={`${welcomeMessage} ${user.first_name}!`} />
        ) : (
          <Navbar title={welcomeMessage} subTitle={user.first_name} />
        )}
      </Grid>
    </DynamicHeader>
  );
};
//
