import { CircularProgress, Grid, Typography, Box, Divider } from "@mui/material";
import React, { FC, useEffect, useState, useContext, useRef, FormEvent } from "react";
import config from "config";
import {
  ExtendedConversation,
  ExtendedMessageFetch,
  getConversation,
  getConversationMessages,
  Message,
} from "adapters";
import { MessageComponent } from "./MessageComponent";
import { usePagination } from "hooks/usePagination";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { UserContext } from "context/UserContext";
import { NotificationContext } from "context/NotificationContext";
import { colors } from "styles/colors";
import { BreakpointsContext } from "context/BreakpointContext";
import { PushNotificationContext, PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { MessageInput } from "./MessageInput";

interface ConversationContentComponentProps {
  conversationId: string;
  handleNewConvStarted?: () => void;
}

export type SocketActions = "SOCKET_CONNECTING";

export type SocketMessage = {
  message: string;
  sender: string | null;
  sent: string;
};

export const ConversationContentComponent: FC<ConversationContentComponentProps> = ({
  conversationId,
  handleNewConvStarted,
}) => {
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { isAgent, user } = useContext(UserContext);
  const { addMessage } = useContext(NotificationContext);
  const { updateNotification } = useContext(PushNotificationContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const [socketMessages, setSocketMessages] = useState<(Message | SocketMessage)[]>([]);
  const [conversationName, setConversationName] = useState("");
  const [conversationData, setConversationData] = useState<ExtendedConversation>();
  const [socket, setSocket] = useState<WebSocket | null>(null);

  const [state, setState] = useState({
    chatInput: "",
    error: false,
  });
  const [hasScrolledOnce, setScrolledOnce] = useState(false);

  if (!conversationId) {
    return null;
  }

  const { results, setLastElementRef, updatePage } = usePagination<ExtendedMessageFetch>(
    () => getConversationMessages(conversationId, { expand: "conversation" }),
    "error.fetchMessages"
  );

  const bottomMessage = useRef<HTMLDivElement | null>(null);
  const messageContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_CONVERSATION" });

    getConversation(conversationId, { expand: "users" })
      .then((res) => {
        setConversationData(res.data);
        setConversationName(res.data.name);
      })
      .catch(() => addMessage({ type: "error" }));
    dispatchLoading({ type: "STOP_LOADING", payload: "GET_CONVERSATION" });
  }, [conversationId]);

  // Connect to the chat WebSocket
  useEffect(() => {
    updatePage();
    setSocketMessages([]);
    dispatchLoading({ type: "SET_LOADING", payload: "SOCKET_CONNECTING" });
    const currConnection = new WebSocket(`${config.webSocketURL}${conversationId}/`);
    setSocket(currConnection);

    currConnection.onopen = () => {
      dispatchLoading({ type: "STOP_LOADING", payload: "SOCKET_CONNECTING" });
    };
    // Return error if socket could not establish connection
    currConnection.onerror = () => {
      addMessage({ type: "error" });
    };

    return () => {
      currConnection.close();
      updateNotification(PUSH_NOTIFICATION_TYPES.conversation);
    };
  }, [conversationId]);

  if (socket) {
    socket.onmessage = (e: MessageEvent) => {
      const data: SocketMessage = JSON.parse(e.data);
      setSocketMessages([data, ...socketMessages]);
      scrollToBottomMessage();
    };
  }

  useEffect(() => {
    if (isDesktop && messageContainer.current) {
      messageContainer.current.scrollTop = messageContainer.current.scrollHeight;
    }
  }, [messageContainer.current]);

  const sendChat = (event: FormEvent) => {
    event.preventDefault();
    if (!state.chatInput.trim() || state.error) {
      return;
    }

    const stringified = JSON.stringify({ message: state.chatInput });
    socket?.send(stringified);
    setState({ ...state, chatInput: "" });

    if (handleNewConvStarted && results.length < 1 && socketMessages.length < 1) {
      handleNewConvStarted();
    }
  };

  const scrollToBottomMessage = () => {
    if (bottomMessage.current) {
      if (isDesktop && messageContainer.current) {
        messageContainer.current.scrollTop = messageContainer.current.scrollHeight;
        return;
      }
      bottomMessage.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const firstTimeScroll = (instance: HTMLDivElement) => {
    if (instance) {
      // Scroll to bottom once when all messages are loaded
      if (!isDesktop) {
        instance.scrollIntoView(true);
      }
      setScrolledOnce(true);
    }
  };

  const isSocketMessage = (msg: Message | SocketMessage): msg is Message => {
    return (msg as Message).sender?.id !== undefined;
  };

  return (
    <>
      {isDesktop && (
        <>
          <Grid container padding="1rem">
            <Typography variant="h6">{conversationName}</Typography>
          </Grid>
          <Divider style={{ width: "100%" }} />
        </>
      )}

      <form onSubmit={sendChat} id={!isDesktop ? "scrollContainer" : ""}>
        {isLoading("SOCKET_CONNECTING") || isLoading("GET_PAGINATION_RESULTS") ? (
          <Box width="max-content" margin="auto">
            <CircularProgress />
          </Box>
        ) : (
          <Grid
            ref={messageContainer}
            container
            padding="1rem"
            gap="2rem"
            maxHeight={isDesktop ? "50vh" : "auto"}
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {!socketMessages.length && !results.length && !isAgent && (
              <Grid container direction="column" wrap="nowrap">
                <Typography textAlign="center">{localize("messagesView.firstMessage")}</Typography>
              </Grid>
            )}
            {socketMessages
              .concat(results)
              .map((msg, index) => {
                const currentUser = conversationData
                  ? conversationData.users.find(({ email }) => {
                      if (isSocketMessage(msg)) {
                        return email === msg.sender?.email;
                      }
                      return email === msg.sender;
                    })
                  : undefined;

                if (index === 0) {
                  return (
                    <React.Fragment key={`message_${msg.sender}_${index}`}>
                      <Box
                        key={index}
                        ref={hasScrolledOnce ? bottomMessage : firstTimeScroll}
                        style={{ width: "100%" }}
                      >
                        <MessageComponent
                          message={msg}
                          sender={currentUser}
                          isCurrentUser={currentUser?.id === user.id || false}
                        />
                      </Box>
                    </React.Fragment>
                  );
                }

                const isLastElement = results.length === index + 1;
                return isLastElement ? (
                  <React.Fragment key={`message_${msg.sender}_${index}`}>
                    <Box key={index} ref={setLastElementRef} style={{ width: "100%" }}>
                      <MessageComponent
                        message={msg}
                        sender={currentUser}
                        isCurrentUser={currentUser ? currentUser.id === user.id : false}
                      />
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={`message_${msg.sender}_${index}`}>
                    <MessageComponent
                      key={index}
                      message={msg}
                      sender={currentUser}
                      isCurrentUser={currentUser ? currentUser.id === user.id : false}
                    />
                  </React.Fragment>
                );
              })
              .reverse()}
          </Grid>
        )}
        {isDesktop && <Divider style={{ width: "100%" }} />}

        <Box bgcolor={colors.white} padding="1rem" bottom={0}>
          <MessageInput onSubmit={sendChat} state={state} setState={setState} />
        </Box>
      </form>
    </>
  );
};
