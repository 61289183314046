import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const ManageProcessSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 256 260"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="255" height="260" transform="translate(0.5)" fill="white" />
        <mask
          id="mask0_6_1002"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="256"
          height="260"
        >
          <rect x="0.5" width="255" height="260" fill="white" />
        </mask>
        <g mask="url(#mask0_6_1002)">
          <rect opacity="0.02" x="0.5" width="255" height="260" fill="white" />
          <g opacity="0.06">
            <path d="M0.5 0L255.5 260" stroke="white" />
            <path d="M10.4609 -10.1562L265.461 249.844" stroke="white" />
            <path d="M20.4219 -20.3125L275.422 239.688" stroke="white" />
            <path d="M30.3828 -30.4688L285.383 229.531" stroke="white" />
            <path d="M40.3438 -40.625L295.344 219.375" stroke="white" />
            <path d="M50.3047 -50.7812L305.305 209.219" stroke="white" />
            <path d="M60.2656 -60.9375L315.266 199.062" stroke="white" />
            <path d="M70.2266 -71.0938L325.227 188.906" stroke="white" />
            <path d="M80.1875 -81.25L335.188 178.75" stroke="white" />
            <path d="M90.1484 -91.4062L345.148 168.594" stroke="white" />
            <path d="M100.109 -101.562L355.109 158.438" stroke="white" />
            <path d="M110.07 -111.719L365.07 148.281" stroke="white" />
            <path d="M120.031 -121.875L375.031 138.125" stroke="white" />
            <path d="M-9.46094 10.1562L245.539 270.156" stroke="white" />
            <path d="M-19.4219 20.3125L235.578 280.312" stroke="white" />
            <path d="M-29.3828 30.4688L225.617 290.469" stroke="white" />
            <path d="M-39.3438 40.625L215.656 300.625" stroke="white" />
            <path d="M-49.3047 50.7812L205.695 310.781" stroke="white" />
            <path d="M-59.2656 60.9375L195.734 320.938" stroke="white" />
            <path d="M-69.2266 71.0938L185.773 331.094" stroke="white" />
            <path d="M-79.1875 81.25L175.812 341.25" stroke="white" />
            <path d="M-89.1484 91.4062L165.852 351.406" stroke="white" />
            <path d="M-99.1094 101.562L155.891 361.562" stroke="white" />
            <path d="M-109.07 111.719L145.93 371.719" stroke="white" />
            <path d="M-119.031 121.875L135.969 381.875" stroke="white" />
            <path d="M-128.992 132.031L126.008 392.031" stroke="white" />
          </g>
          <g opacity="0.06">
            <path d="M5.48047 -5.07812L260.48 254.922" stroke="white" />
            <path d="M15.4414 -15.2344L270.441 244.766" stroke="white" />
            <path d="M25.4023 -25.3906L280.402 234.609" stroke="white" />
            <path d="M35.3633 -35.5469L290.363 224.453" stroke="white" />
            <path d="M45.3242 -45.7031L300.324 214.297" stroke="white" />
            <path d="M55.2852 -55.8594L310.285 204.141" stroke="white" />
            <path d="M65.2461 -66.0156L320.246 193.984" stroke="white" />
            <path d="M75.207 -76.1719L330.207 183.828" stroke="white" />
            <path d="M85.168 -86.3281L340.168 173.672" stroke="white" />
            <path d="M95.1289 -96.4844L350.129 163.516" stroke="white" />
            <path d="M105.09 -106.641L360.09 153.359" stroke="white" />
            <path d="M115.051 -116.797L370.051 143.203" stroke="white" />
            <path d="M125.012 -126.953L380.012 133.047" stroke="white" />
            <path d="M-4.48047 5.07812L250.52 265.078" stroke="white" />
            <path d="M-14.4414 15.2344L240.559 275.234" stroke="white" />
            <path d="M-24.4023 25.3906L230.598 285.391" stroke="white" />
            <path d="M-34.3633 35.5469L220.637 295.547" stroke="white" />
            <path d="M-44.3242 45.7031L210.676 305.703" stroke="white" />
            <path d="M-54.2852 55.8594L200.715 315.859" stroke="white" />
            <path d="M-64.2461 66.0156L190.754 326.016" stroke="white" />
            <path d="M-74.207 76.1719L180.793 336.172" stroke="white" />
            <path d="M-84.168 86.3281L170.832 346.328" stroke="white" />
            <path d="M-94.1289 96.4844L160.871 356.484" stroke="white" />
            <path d="M-104.09 106.641L150.91 366.641" stroke="white" />
            <path d="M-114.051 116.797L140.949 376.797" stroke="white" />
            <path d="M-124.012 126.953L130.988 386.953" stroke="white" />
          </g>
        </g>
        <g opacity="0.5">
          <path
            d="M140.167 115H116.833C114.992 115 113.5 116.492 113.5 118.333V141.667C113.5 143.508 114.992 145 116.833 145H140.167C142.008 145 143.5 143.508 143.5 141.667V118.333C143.5 116.492 142.008 115 140.167 115Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M122.667 126.667C124.047 126.667 125.167 125.547 125.167 124.167C125.167 122.786 124.047 121.667 122.667 121.667C121.286 121.667 120.167 122.786 120.167 124.167C120.167 125.547 121.286 126.667 122.667 126.667Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M143.5 135L135.167 126.667L116.833 145"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g clipPath="url(#clip0_6_1002)">
          <path
            d="M217.233 228.228L217.238 227.997C217.343 224.126 218.289 220.324 220.01 216.855C221.732 213.386 224.188 210.334 227.208 207.911C228.912 206.565 230.779 205.439 232.765 204.56L232.954 204.476L232.987 204.681C233.371 207.125 233.98 209.62 234.264 210.728L236.166 203.303L236.377 203.242C237.346 202.958 238.378 202.97 239.34 203.278C240.302 203.585 241.15 204.174 241.775 204.968C242.426 205.762 242.825 206.733 242.921 207.756C243.016 208.779 242.802 209.808 242.308 210.708C241.865 211.535 241.429 212.397 241.008 213.231C239.56 216.095 238.062 219.058 235.88 221.359C234.212 223.085 232.179 224.416 229.93 225.256C227.681 226.095 225.273 226.421 222.882 226.21L217.233 228.228Z"
            fill="#F2F2F2"
          />
          <path
            d="M193.521 224.795L190.212 224.795L188.638 212.024L193.522 212.024L193.521 224.795Z"
            fill="#FFB6B6"
          />
          <path
            d="M193.638 228.249L183.461 228.248V228.12C183.461 227.069 183.879 226.061 184.621 225.318C185.364 224.574 186.372 224.157 187.422 224.157H187.423L189.281 222.746L192.75 224.157L193.638 224.157L193.638 228.249Z"
            fill="#2F2E41"
          />
          <path
            d="M227.778 215.467L225.163 217.497L216.093 208.374L219.951 205.378L227.778 215.467Z"
            fill="#FFB6B6"
          />
          <path
            d="M229.987 218.124L221.947 224.366L221.868 224.265C221.224 223.434 220.936 222.382 221.067 221.339C221.199 220.297 221.739 219.349 222.568 218.704L222.569 218.704L223.172 216.449L226.777 215.436L227.479 214.892L229.987 218.124Z"
            fill="#2F2E41"
          />
          <path
            d="M213.463 153.678C213.463 153.678 213.237 157.135 212.952 162.078C212.905 162.889 213.181 163.769 213.132 164.651C213.099 165.236 212.742 165.805 212.709 166.415C212.674 167.059 213.167 167.629 213.132 168.295C213.097 168.945 212.535 169.691 212.501 170.357C212.137 177.523 210.814 186.102 212.138 188.5C212.791 189.682 222.733 206.055 222.733 206.055C222.733 206.055 218.098 213.01 216.773 210.692C215.024 207.63 201.544 193.302 201.544 190.567C201.544 187.834 198.425 167.354 198.425 167.354L196.251 179.474L195.254 181.875L195.567 183.285L194.922 185.187L194.888 187.07L194.26 190.567C194.26 190.567 196.147 217.807 195.085 218.565C194.019 219.327 188.857 220.695 188.248 219.327C187.642 217.959 185.321 190.898 185.321 190.898C185.321 190.898 184.686 178.497 183.997 166.97C183.957 166.301 183.487 165.493 183.451 164.833C183.408 164.068 183.706 163.075 183.666 162.333C183.623 161.481 183.245 160.882 183.209 160.067C182.928 153.77 182.885 148.809 183.388 148.053C184.907 145.775 186.275 143.953 186.275 143.953C186.275 143.953 207.993 138.633 213.463 145.47L213.463 153.678Z"
            fill="#2F2E41"
          />
          <path
            d="M193.929 88.469L199.166 88.176L203.112 90.3313L214.087 152.508C214.087 152.508 202.868 157.696 195.916 155.708C188.963 153.721 181.641 153.833 181.641 153.833L186.977 94.4311L191.281 90.4564L193.929 88.469Z"
            fill="#E6E6E6"
          />
          <path
            d="M202.666 89.7872L210.772 93.0989C212.016 93.1596 213.204 93.6401 214.14 94.4621C215.077 95.2841 215.708 96.3993 215.931 97.6259L215.393 113.642L212.47 121.261L214.554 138.617L213.463 140.803L214.406 143.034C213.022 145.283 213.303 148.23 214.125 151.402H213.132L211.145 151.071C211.145 151.071 206.772 152.59 205.876 147.134C203.531 132.853 202.206 139.147 202.206 139.147L201.052 94.8377L202.666 89.7872Z"
            fill="#3F3D56"
          />
          <path
            d="M191.612 89.7939L184.04 97.7361C182.795 97.7968 181.608 98.2773 180.671 99.0993C179.734 99.9213 179.103 101.036 178.88 102.263L182.011 126.891L180.542 136.341L181.348 138.484L180.764 140.906L181.679 143.122L181.017 146.103L182.011 150.409L186.977 149.084L186.939 145.552L188.263 97.5241L191.612 89.7939Z"
            fill="#3F3D56"
          />
          <path
            d="M166.36 130.62L156.045 138.108C155.65 138.129 155.267 138.253 154.935 138.468C154.603 138.684 154.334 138.983 154.155 139.336C153.975 139.689 153.892 140.082 153.913 140.478C153.934 140.873 154.058 141.256 154.274 141.588C154.49 141.919 154.789 142.188 155.142 142.368C155.495 142.547 155.889 142.63 156.284 142.608C156.679 142.587 157.061 142.462 157.393 142.245C157.724 142.029 157.993 141.729 158.171 141.376L172.197 133.885L166.36 130.62Z"
            fill="#FFB6B6"
          />
          <path
            d="M180.686 99.0683C178.726 99.9729 173.883 123.132 172.462 123.623C171.04 124.115 171.583 124.041 171.384 124.451C171.184 124.861 171.136 126.696 169.772 126.912C168.407 127.127 161.198 133.702 161.198 133.702L163.025 139.797C163.025 139.797 176.898 131.868 180.723 127.477C184.548 123.086 186.607 100.505 186.607 100.505L186.999 98.2523C186.999 98.2523 182.647 98.1637 180.686 99.0683Z"
            fill="#3F3D56"
          />
          <path
            d="M211.795 127.562L202.603 136.393C202.214 136.468 201.852 136.643 201.553 136.902C201.253 137.161 201.028 137.494 200.898 137.868C200.769 138.242 200.74 138.644 200.815 139.033C200.89 139.421 201.065 139.783 201.325 140.083C201.584 140.382 201.917 140.607 202.291 140.736C202.665 140.865 203.067 140.894 203.455 140.818C203.844 140.743 204.205 140.566 204.504 140.307C204.803 140.047 205.027 139.713 205.156 139.339L218.024 129.996L211.795 127.562Z"
            fill="#FFB6B6"
          />
          <path
            d="M213.132 101.056C211.314 102.22 218.222 119.114 216.881 119.795C215.541 120.477 216.068 120.329 215.927 120.763C215.785 121.196 215.989 123.021 214.667 123.421C213.345 123.822 207.104 131.323 207.104 131.323L209.748 137.109C209.748 137.109 222.405 127.354 225.592 122.48C228.78 117.607 226.706 115.961 226.706 115.961C222.751 107.557 219.052 93.8512 213.463 94.7623C213.463 94.7623 214.95 99.8909 213.132 101.056Z"
            fill="#3F3D56"
          />
          <path
            d="M195.052 87.1576C199.954 87.1576 203.927 83.1823 203.927 78.2786C203.927 73.3748 199.954 69.3995 195.052 69.3995C190.151 69.3995 186.177 73.3748 186.177 78.2786C186.177 83.1823 190.151 87.1576 195.052 87.1576Z"
            fill="#FFB6B6"
          />
          <path
            d="M204.04 83.9998C204.04 83.9998 202.085 84.536 200.947 84.8345C201.006 83.9877 201.373 83.1949 201.559 82.3665C201.745 81.538 201.705 80.5637 201.089 79.9788C200.474 79.3939 199.192 79.6218 199.093 80.4634C198.572 79.3497 198.051 78.2339 197.528 77.1161C196.037 80.1856 192.99 77.5698 189.667 78.3454C187.972 78.7449 185.848 78.5733 184.952 77.079C185.456 76.6564 185.88 76.1475 186.204 75.5762C185.688 75.7278 185.151 75.7959 184.613 75.7779C184.625 75.2434 184.765 74.7197 185.022 74.2508C185.279 73.7819 185.644 73.3816 186.088 73.0835C186.566 72.7709 185.383 71.6586 185.8 71.2671C186.656 70.468 187.23 71.0106 187.735 69.9573C188.061 69.3059 188.563 68.7592 189.184 68.3792C189.805 67.9993 190.52 67.8015 191.248 67.8082C192.701 67.8422 195.303 67.8282 195.867 69.1669C197.663 67.9907 200.163 68.323 201.918 69.5569C203.377 70.5785 205.038 73.6664 205.404 75.4095C205.771 77.1527 204.963 82.4806 204.04 83.9998Z"
            fill="#2F2E41"
          />
          <path
            d="M94.1101 64.455H37.2815C36.2594 64.4682 35.2703 64.0935 34.5132 63.4063C33.7562 62.7192 33.2874 61.7707 33.2013 60.7517C33.1644 60.2082 33.2396 59.6629 33.422 59.1497C33.6045 58.6364 33.8905 58.1661 34.2621 57.768C34.6338 57.3699 35.0832 57.0524 35.5826 56.8352C36.082 56.6181 36.6206 56.5059 37.1651 56.5056H94.1101C95.1638 56.5056 96.1743 56.9243 96.9193 57.6697C97.6644 58.4151 98.083 59.4261 98.083 60.4803C98.083 61.5345 97.6644 62.5454 96.9193 63.2909C96.1743 64.0363 95.1638 64.455 94.1101 64.455H94.1101Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M126.799 70.914H33.3577C33.2699 70.914 33.1857 70.8791 33.1236 70.8169C33.0615 70.7548 33.0266 70.6706 33.0266 70.5827C33.0266 70.4949 33.0615 70.4106 33.1236 70.3485C33.1857 70.2864 33.2699 70.2515 33.3577 70.2515H126.799C126.887 70.2515 126.971 70.2864 127.033 70.3485C127.095 70.4106 127.13 70.4949 127.13 70.5827C127.13 70.6706 127.095 70.7548 127.033 70.8169C126.971 70.8791 126.887 70.914 126.799 70.914Z"
            fill="#E6E6E6"
          />
          <path
            d="M122.914 64.6206H37.1651C36.0675 64.6206 35.0148 64.1844 34.2387 63.408C33.4626 62.6315 33.0266 61.5784 33.0266 60.4803C33.0266 59.3822 33.4626 58.3291 34.2387 57.5526C35.0148 56.7762 36.0675 56.34 37.1651 56.34H122.914C124.011 56.34 125.064 56.7762 125.84 57.5526C126.616 58.3291 127.052 59.3822 127.052 60.4803C127.052 61.5784 126.616 62.6315 125.84 63.408C125.064 64.1844 124.011 64.6206 122.914 64.6206ZM37.1651 57.0024C36.2431 57.0024 35.3589 57.3688 34.7069 58.0211C34.055 58.6733 33.6888 59.5579 33.6888 60.4803C33.6888 61.4027 34.055 62.2873 34.7069 62.9395C35.3589 63.5918 36.2431 63.9582 37.1651 63.9582H122.914C123.836 63.9582 124.72 63.5918 125.372 62.9395C126.024 62.2873 126.39 61.4027 126.39 60.4803C126.39 59.5579 126.024 58.6733 125.372 58.0211C124.72 57.3688 123.836 57.0024 122.914 57.0024H37.1651Z"
            fill="#3F3D56"
          />
          <path
            d="M68.6173 95.5904H37.2815C36.2594 95.6035 35.2703 95.2288 34.5132 94.5417C33.7562 93.8546 33.2874 92.9061 33.2013 91.8871C33.1644 91.3436 33.2396 90.7983 33.422 90.285C33.6045 89.7718 33.8905 89.3015 34.2621 88.9034C34.6338 88.5053 35.0832 88.1878 35.5826 87.9706C36.082 87.7534 36.6206 87.6412 37.1651 87.6409H68.6173C69.671 87.6409 70.6815 88.0597 71.4265 88.8051C72.1716 89.5505 72.5902 90.5615 72.5902 91.6157C72.5902 92.6698 72.1716 93.6808 71.4265 94.4262C70.6815 95.1716 69.671 95.5904 68.6173 95.5904H68.6173Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M126.799 102.049H33.3577C33.2699 102.049 33.1857 102.014 33.1236 101.952C33.0615 101.89 33.0266 101.806 33.0266 101.718C33.0266 101.63 33.0615 101.546 33.1236 101.484C33.1857 101.422 33.2699 101.387 33.3577 101.387H126.799C126.887 101.387 126.971 101.422 127.033 101.484C127.095 101.546 127.13 101.63 127.13 101.718C127.13 101.806 127.095 101.89 127.033 101.952C126.971 102.014 126.887 102.049 126.799 102.049Z"
            fill="#E6E6E6"
          />
          <path
            d="M122.914 95.756H37.1651C36.0675 95.756 35.0148 95.3198 34.2387 94.5433C33.4626 93.7668 33.0266 92.7137 33.0266 91.6157C33.0266 90.5176 33.4626 89.4645 34.2387 88.688C35.0148 87.9115 36.0675 87.4753 37.1651 87.4753H122.914C124.011 87.4753 125.064 87.9115 125.84 88.688C126.616 89.4645 127.052 90.5176 127.052 91.6157C127.052 92.7137 126.616 93.7668 125.84 94.5433C125.064 95.3198 124.011 95.756 122.914 95.756ZM37.1651 88.1378C36.2431 88.1378 35.3589 88.5042 34.7069 89.1564C34.055 89.8086 33.6888 90.6933 33.6888 91.6157C33.6888 92.538 34.055 93.4227 34.7069 94.0749C35.3589 94.7271 36.2431 95.0935 37.1651 95.0935H122.914C123.836 95.0935 124.72 94.7271 125.372 94.0749C126.024 93.4227 126.39 92.538 126.39 91.6157C126.39 90.6933 126.024 89.8086 125.372 89.1564C124.72 88.5042 123.836 88.1378 122.914 88.1378H37.1651Z"
            fill="#3F3D56"
          />
          <path
            d="M110.664 126.726H37.2815C36.2594 126.739 35.2703 126.364 34.5132 125.677C33.7562 124.99 33.2874 124.041 33.2013 123.022C33.1644 122.479 33.2396 121.934 33.422 121.42C33.6045 120.907 33.8905 120.437 34.2621 120.039C34.6338 119.641 35.0832 119.323 35.5826 119.106C36.082 118.889 36.6206 118.777 37.1651 118.776H110.664C111.718 118.776 112.728 119.195 113.473 119.94C114.218 120.686 114.637 121.697 114.637 122.751C114.637 123.805 114.218 124.816 113.473 125.562C112.728 126.307 111.718 126.726 110.664 126.726H110.664Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M126.799 133.185H33.3577C33.2699 133.185 33.1857 133.15 33.1236 133.088C33.0615 133.026 33.0266 132.941 33.0266 132.853C33.0266 132.766 33.0615 132.681 33.1236 132.619C33.1857 132.557 33.2699 132.522 33.3577 132.522H126.799C126.887 132.522 126.971 132.557 127.033 132.619C127.095 132.681 127.13 132.766 127.13 132.853C127.13 132.941 127.095 133.026 127.033 133.088C126.971 133.15 126.887 133.185 126.799 133.185Z"
            fill="#E6E6E6"
          />
          <path
            d="M122.914 126.891H37.1651C36.0675 126.891 35.0148 126.455 34.2387 125.679C33.4626 124.902 33.0266 123.849 33.0266 122.751C33.0266 121.653 33.4626 120.6 34.2387 119.823C35.0148 119.047 36.0675 118.611 37.1651 118.611H122.914C124.011 118.611 125.064 119.047 125.84 119.823C126.616 120.6 127.052 121.653 127.052 122.751C127.052 123.849 126.616 124.902 125.84 125.679C125.064 126.455 124.011 126.891 122.914 126.891ZM37.1651 119.273C36.2431 119.273 35.3589 119.64 34.7069 120.292C34.055 120.944 33.6888 121.829 33.6888 122.751C33.6888 123.673 34.055 124.558 34.7069 125.21C35.3589 125.863 36.2431 126.229 37.1651 126.229H122.914C123.836 126.229 124.72 125.863 125.372 125.21C126.024 124.558 126.39 123.673 126.39 122.751C126.39 121.829 126.024 120.944 125.372 120.292C124.72 119.64 123.836 119.273 122.914 119.273H37.1651Z"
            fill="#3F3D56"
          />
          <path
            d="M88.7183 60.415H88.0562V34.8962H135.949V35.5587H88.7183V60.415Z"
            fill="#CCCCCC"
          />
          <path
            d="M137.996 38.9136C140.18 38.9136 141.951 37.1421 141.951 34.9568C141.951 32.7715 140.18 31 137.996 31C135.811 31 134.041 32.7715 134.041 34.9568C134.041 37.1421 135.811 38.9136 137.996 38.9136Z"
            fill="#CCCCCC"
          />
          <path
            d="M65.7322 91.5504H66.3943V76.9621H18.502V77.6246H65.7322V91.5504Z"
            fill="#CCCCCC"
          />
          <path
            d="M16.455 80.9795C18.6393 80.9795 20.41 79.208 20.41 77.0227C20.41 74.8374 18.6393 73.0659 16.455 73.0659C14.2707 73.0659 12.5 74.8374 12.5 77.0227C12.5 79.208 14.2707 80.9795 16.455 80.9795Z"
            fill="#CCCCCC"
          />
          <path d="M110.569 123.81H109.907V142.042H150.516V141.38H110.569V123.81Z" fill="#CCCCCC" />
          <path
            d="M152.563 145.938C154.747 145.938 156.518 144.166 156.518 141.981C156.518 139.796 154.747 138.024 152.563 138.024C150.379 138.024 148.608 139.796 148.608 141.981C148.608 144.166 150.379 145.938 152.563 145.938Z"
            fill="#CCCCCC"
          />
          <path
            d="M244.106 228.898L154.136 229C154.084 229 154.033 228.99 153.985 228.97C153.937 228.951 153.893 228.922 153.857 228.885C153.82 228.848 153.791 228.805 153.771 228.757C153.751 228.709 153.741 228.658 153.741 228.606C153.741 228.554 153.751 228.502 153.771 228.454C153.791 228.407 153.82 228.363 153.857 228.326C153.893 228.29 153.937 228.261 153.985 228.241C154.033 228.221 154.084 228.211 154.136 228.211L244.106 228.109C244.21 228.109 244.311 228.151 244.385 228.225C244.458 228.299 244.5 228.399 244.5 228.504C244.5 228.608 244.458 228.709 244.385 228.783C244.311 228.857 244.21 228.898 244.106 228.898Z"
            fill="#CACACA"
          />
          <path
            d="M207.861 89.7949C207.654 89.4711 207.33 89.2404 206.957 89.1519C206.583 89.0633 206.19 89.1237 205.86 89.3203C206.347 88.6437 205.98 87.4874 205.217 87.02C204.353 86.4905 203.261 86.5782 202.253 86.6835L193.991 87.5469C193.038 87.6465 192.059 87.7537 191.211 88.1993C190.363 88.6448 189.665 89.5143 189.714 90.4714C189.067 90.2337 188.375 90.1398 187.687 90.1964C187.008 90.3035 186.339 90.8001 186.266 91.4842C186.15 92.5819 187.453 93.2158 188.515 93.5167C190.486 94.0755 192.457 94.6342 194.428 95.193L193.82 95.323C197.699 95.6622 201.604 95.097 205.228 93.6719C206.058 93.3444 206.899 92.9521 207.48 92.2745C208.062 91.5969 208.315 90.5633 207.861 89.7949Z"
            fill="#E6E6E6"
          />
        </g>
        <defs>
          <clipPath id="clip0_6_1002">
            <rect width="232" height="198" fill="white" transform="translate(12.5 31)" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
