import { API, PaginatedResponse } from "./api";
import { AxiosResponse } from "axios";
import { User } from "reducers/UserReducer";

export type ConversationActions = "GET_CONVERSATION_LIST" | "GET_CONVERSATION";

type ConversationCreationBody = {
  support_conversation: boolean;
  name?: string;
  users?: string[];
};

export type Conversation = {
  id: string;
  name: string;
  users: string[] | null;
  latest_message: {
    message: string | null;
    sent: string | null;
  };
};

export type ExtendedConversation = {
  id: string;
  name: string;
  users: User[] | [];
  latest_message: {
    message: string | null;
    sent: string | null;
  };
  is_read: boolean;
};

export type Message = {
  message: string;
  sender: User | null;
  sent: string;
};

export type ExtendedMessageFetch = Message & {
  conversation: Conversation;
};

export const getConversationList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<ExtendedConversation>>> => {
  return API.get("/conversations/", { params: params });
};

export const getConversationMessages = async (
  convId: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<Message>>> => {
  return API.get(`/conversations/${convId}/messages/`, { params: params });
};

export const getConversation = async (
  convId: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse<ExtendedConversation>> => {
  return API.get(`/conversations/${convId}/`, { params: params });
};

export const createConversation = async (
  supportConv: boolean,
  name?: string,
  userList?: string[]
): Promise<AxiosResponse<Conversation>> => {
  const body: ConversationCreationBody = {
    support_conversation: supportConv,
    name: name,
    users: userList,
  };

  return API.post("/conversations/", body);
};
