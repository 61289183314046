import { CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import { getGDPRUserByEmail } from "adapters";
import { deleteUser, downloadGdpr, GDPRUserData } from "adapters/UserAdapter";
import { WIcon, WIconTypes, WIconButton, AdvancedSearch } from "components";
import { WButton, WLoadingButton } from "components/styledComponents/WButton";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useContext, useState } from "react";

export const GDPRDialog: FC = () => {
  const { hideDialog } = useContext(ModalContext);
  const { dispatchLoading, isLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);

  const [search, setSearch] = useState("");
  const [isDeleteingUser, setIsDeletingUser] = useState(false);
  const [hasConfirmedDeletion, setHasConfirmedDeletion] = useState(false);
  const [gdprUserData, setGdprUserData] = useState<GDPRUserData>();
  const [timerHandle, setTimerHandle] = useState<NodeJS.Timeout | null>(null);

  const handleSearch = (value: string) => {
    setSearch(value);
    dispatchLoading({ type: "SET_LOADING", payload: "GET_GDPR_USERS" });
    getGDPRUserByEmail({ email: value })
      .then((res) => {
        setGdprUserData(res.data);
      })
      .catch(() => {
        addMessage({ type: "error", content: "Could not fetch user data.", isResponse: true });
      })
      .finally(() => dispatchLoading({ type: "STOP_LOADING", payload: "GET_GDPR_USERS" }));
  };

  const handleFetchGDPR = async () => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_GDPR" });

    const params = {
      user_id: gdprUserData?.wise_user?.id,
      tt_ids: gdprUserData?.tt_user?.ids.join(",")
    };

    const blob = await downloadGdpr(params);
    const objUrl = window.URL.createObjectURL(blob.data);

    const element = document.createElement("a");
    element.href = objUrl;
    element.download = `GDPR-${gdprUserData?.wise_user?.email || gdprUserData?.tt_user?.email}.zip`;
    document.body.appendChild(element);
    element.click();

    dispatchLoading({ type: "STOP_LOADING", payload: "GET_GDPR" });
  };

  const handleStartUserDeletion = () => {
    setIsDeletingUser(true);
  };

  const handleStopUserDeletion = () => {
    setIsDeletingUser(false);
  };

  const handleCancelOngoingDeletion = () => {
    dispatchLoading({ type: "STOP_LOADING", payload: "DELETE_USER" });
    timerHandle && clearTimeout(timerHandle);
    setTimerHandle(null);
    setHasConfirmedDeletion(false);
  };

  const handleFinalUserDeletion = () => {
    const params = {};

    if (gdprUserData?.wise_user?.id) params["user_id"] = gdprUserData?.wise_user.id;
    if (gdprUserData?.tt_user?.id) params["tt_ids"] = gdprUserData?.tt_user.ids.join(",");

    if (hasConfirmedDeletion) {
      handleCancelOngoingDeletion();
      return;
    }

    setTimerHandle(
      setTimeout(() => {
        deleteUser(params)
          .then()
          .catch(() => {
            addMessage({ type: "error", content: "Could not delete User.", isResponse: true });
          })
          .finally(() => {
            handleCancelOngoingDeletion();
            addMessage({ type: "success", content: "User deletion succesful!", isResponse: true });
            hideDialog();
          });
      }, 3000)
    );

    dispatchLoading({ type: "SET_LOADING", payload: "DELETE_USER" });

    setHasConfirmedDeletion(true);
  };

  const isUserResultsEmpty = (data: GDPRUserData | undefined) => {
    if (!data) return true;

    return (
      Object.keys(data).length === 0 ||
      Object.values(data).filter((value) => value !== null).length === 0
    );
  };

  return (
    <Dialog
      open
      onClose={() => {
        handleCancelOngoingDeletion();
        hideDialog();
      }}
      fullWidth={!isDeleteingUser}
      PaperProps={{
        style: { borderRadius: "5px" },
      }}
    >
      <Grid container padding="1rem" gap="1rem" direction="column">
        <WIconButton
          sx={{ position: "absolute", top: "1rem", right: "1rem" }}
          onClick={() => {
            handleCancelOngoingDeletion();
            hideDialog();
          }}
        >
          <WIcon icon={WIconTypes.clear} size="medium" />
        </WIconButton>
        {isDeleteingUser ? (
          <Grid container gap="4rem" justifyContent="center">
            <Grid container gap="2rem" justifyContent="center" marginTop="4rem">
              <Typography variant="h6" color="primary" textAlign="center">
                {localize("dialog.deleteSubtitle")}
              </Typography>
              <Grid container gap=".2rem" justifyContent="center">
                <Typography variant="body1" color="error" textAlign="center">
                  {localize("dialog.deleteUserConfirmText")}
                </Typography>
                <Typography variant="body1" fontWeight="bold" color="error" textAlign="center">
                  {gdprUserData?.wise_user?.email || gdprUserData?.tt_user?.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <WButton
                disabled={hasConfirmedDeletion}
                variant="contained"
                sx={{ boxShadow: "none" }}
                onClick={handleStopUserDeletion}
              >
                {localize("common.goBack")}
              </WButton>
              <WLoadingButton
                displayLoading={isLoading("DELETE_USER")}
                variant={!hasConfirmedDeletion ? "outlined" : "contained"}
                skipDisabled={true}
                color="error"
                sx={{ boxShadow: "none" }}
                onClick={handleFinalUserDeletion}
              >
                {!hasConfirmedDeletion ? localize("dialog.deleteTitle") : localize("common.cancel")}
              </WLoadingButton>
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography variant="h4" color="primary">
              {localize("agentStartPage.gdprTitle")}
            </Typography>
            <AdvancedSearch
              placeholderType="searchUser"
              onSearch={(search) => handleSearch(search)}
              currentSearch={search}
            />
            <Grid container direction="column">
              {isLoading("GET_GDPR_USERS") ? (
                <CircularProgress />
              ) : (
                <>
                  {isUserResultsEmpty(gdprUserData) ? (
                    <Typography variant="body2" fontStyle="italic" fontWeight="bold">
                      {search && localize("agentStartPage.noResults", { email: search })}
                    </Typography>
                  ) : (
                    <>
                      <Grid container gap="1rem" alignItems="flex-end">
                        <Typography variant="body1" fontWeight="bold">
                          {localize("agentStartPage.ttUser")}
                        </Typography>
                        <Typography variant="body2">
                          {gdprUserData?.tt_user 
                            ? `${gdprUserData?.tt_user.email} (${gdprUserData?.tt_user.total})` 
                            : localize("agentStartPage.notFound")}
                        </Typography>
                      </Grid>
                      <Grid container gap="1rem" alignItems="flex-end">
                        <Typography variant="body1" fontWeight="bold">
                          {localize("agentStartPage.wiseUser")}
                        </Typography>
                        <Typography variant="body2">
                          {gdprUserData?.wise_user
                            ? gdprUserData?.wise_user.email
                            : localize("agentStartPage.notFound")}
                        </Typography>
                      </Grid>
                      <Grid container gap="1rem" alignItems="flex-end">
                        <Typography variant="body1" fontWeight="bold">
                          {localize("agentStartPage.candidateUser")}
                        </Typography>
                        <Typography variant="body2">
                          {gdprUserData?.wise_candidates?.length || localize("agentStartPage.notFound")}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
            {(gdprUserData?.wise_user || gdprUserData?.tt_user) && (
              <Grid container>
                <WLoadingButton
                  displayLoading={isLoading("GET_GDPR")}
                  variant="contained"
                  sx={{ width: "max-content", boxShadow: "none" }}
                  startIcon={<WIcon color="secondary" icon={WIconTypes.download} size="medium" />}
                  onClick={handleFetchGDPR}
                >
                  {isLoading("GET_GDPR")
                    ? localize("agentStartPage.fetching")
                    : localize("agentStartPage.fetchGdpr")}
                </WLoadingButton>
                <WButton
                  disabled={isLoading("GET_GDPR")}
                  variant="contained"
                  color="error"
                  sx={{ width: "max-content", marginLeft: "auto", boxShadow: "none" }}
                  startIcon={<WIcon color="secondary" icon={WIconTypes.trash} size="medium" />}
                  onClick={handleStartUserDeletion}
                >
                  {localize("agentStartPage.deleteUser")}
                </WButton>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Dialog>
  );
};
