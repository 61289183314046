import { WTypography } from "components/WTypography";
import React, { FC, useContext } from "react";
import { Grid, Card } from "@mui/material";
import { AvatarItem } from "components/AvatarItem";
import { Agent } from "adapters";
import { colors, defaultStyles } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";
import { prettifyDate, WDateFormats } from "utils/PrettifyDate";

interface AgentCardProps {
  agent: Agent;
  setSelectedAgent: (agent: Agent) => void;
  isSelected: boolean;
}

export const AgentCard: FC<AgentCardProps> = ({
  agent,
  setSelectedAgent,
  isSelected: isSelected,
}) => {
  const { localize } = useContext(LocaleContext);

  return (
    <Card
      sx={{
        cursor: "pointer",
        color: colors.mainDark,
        padding: "10px",
        marginX: "10px",
        borderRadius: defaultStyles.borderRadius,
        backgroundColor: isSelected ? colors.lightGrey : colors.white,
        boxShadow: "none",
        "&:hover": { backgroundColor: colors.grey },
      }}
      onClick={() => setSelectedAgent(agent)}
    >
      <Grid container wrap="nowrap" alignItems="center" padding="3px">
        <AvatarItem imageSrc={agent.profile_picture} size="tiny" />
        <Grid
          container
          direction="column"
          marginLeft="10px"
          sx={{
            overflowX: "hidden",
          }}
        >
          <WTypography variant="body1" fontWeight="bold">
            {agent.full_name}
          </WTypography>
          <WTypography variant="body2">{agent.email}</WTypography>
        </Grid>

        <Grid item xs={4} textAlign="end">
          <WTypography variant="body2" color={colors.darkGrey}>
            {agent.last_login
              ? prettifyDate(agent.last_login, { dateFormat: WDateFormats.dayMonthYear })
              : localize("statisticView.never")}
          </WTypography>
        </Grid>
      </Grid>
    </Card>
  );
};
