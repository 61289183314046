import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { colors } from "styles/colors";
import { BreakpointsContext } from "context/BreakpointContext";
import {
  DESKTOP_ACTION_BAR_SIZE,
  MOBILE_ACTION_BAR_SIZE,
} from "./layout/ActionNavbar/DefaultActionNavbar";

export const StickyWrapper: FC = ({ children }) => {
  const header = document.getElementById("SuperHeader");
  const stickyRef = useRef<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const { isDesktop } = useContext(BreakpointsContext);

  const onScroll = () => {
    if (!stickyRef.current || !header) {
      return;
    }

    if (window.scrollY >= headerHeight) {
      stickyRef.current.style.position = "fixed";
      stickyRef.current.style.boxShadow = "0 1px 1px 0px rgba(0,0,0,0.25)";
      stickyRef.current.style.top = isDesktop ? DESKTOP_ACTION_BAR_SIZE : MOBILE_ACTION_BAR_SIZE;

      return;
    }
    stickyRef.current.style.top = "0";

    stickyRef.current.style.position = "relative";
    stickyRef.current.style.boxShadow = "none";
  };

  useEffect(() => {
    const header = document.getElementById("SuperHeader");

    if (!stickyRef.current || !header) {
      return;
    }

    setHeaderHeight(header.getBoundingClientRect().height);
    stickyRef.current.style.top = "0";

    window.addEventListener("scroll", onScroll, true);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [header, isDesktop]);

  return (
    <Grid
      ref={stickyRef}
      container
      zIndex="2"
      position="relative"
      padding={isDesktop ? "1rem 4rem" : "1rem"}
      sx={{ backgroundColor: colors.siteBackground, transition: "all 0.2s ease-out", gap: "1rem" }}
    >
      {children}
    </Grid>
  );
};
