import { CircularProgress, Dialog, Grid } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useState } from "react";
import { deleteReferral, Referral } from "adapters";

import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { DialogHeader } from "components";
import { DIALOGS } from "views/Enums";
import { ConfirmDialog } from "./ConfirmDialog";
import { UserContext } from "context/UserContext";
import { ReferralEditForm } from "components/ProfileView/Referrals/ReferralEditForm";

interface ProfileReferralsEditProps {
  handleClose: () => void;
  handleSuccessUpdate: () => void;
  referral: Referral;
}

export const ProfileReferralsEdit: FC<ProfileReferralsEditProps> = ({
  handleClose,
  handleSuccessUpdate,
  referral,
}) => {
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { user, dispatch } = useContext(UserContext);

  const [openDialog, setOpenDialog] = useState("");

  const handleReferralDelete = (): void => {
    dispatchLoading({ type: "SET_LOADING", payload: "DELETE_REFERRAL" });
    deleteReferral(referral.id)
      .then(() => {
        addMessage({ content: "success.updateSuccessful", type: "success", duration: 1500 });

        // Remove referral from user referrals
        const updatedReferralList = user.referrals.filter((userRef) => userRef.id !== referral.id);
        dispatch({
          type: "UPDATE_USER",
          user: { referrals: updatedReferralList },
        });

        handleClose();
        handleSuccessUpdate();
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.removeReferral" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "DELETE_REFERRAL" });
      });
  };

  return (
    <Dialog fullScreen open={true} onClose={handleClose}>
      <DialogHeader
        handleClose={handleClose}
        title={`${referral.first_name} ${referral.last_name}`}
        returnText={localize("profileSections.profileTitleReferral")}
      />
      <Grid container height="100%">
        <ReferralEditForm
          referral={referral}
          handleSuccessUpdate={handleSuccessUpdate}
          handleClose={handleClose}
          handleDelete={() => setOpenDialog(DIALOGS.confirm)}
          opts={{ stickyButtons: true }}
        />
      </Grid>
      {openDialog === DIALOGS.confirm && (
        <ConfirmDialog
          title={localize("dialog.confirmDeleteReferralTitle")}
          subtitle={localize("dialog.confirmDeleteReferralSubtitle")}
          cancelAction={() => setOpenDialog("")}
          confirmAction={handleReferralDelete}
        />
      )}
      {isLoading("CREATE_REFERRAL") && (
        <CircularProgress
          color="success"
          sx={{
            margin: "auto",
          }}
        />
      )}
    </Dialog>
  );
};
