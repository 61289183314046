import { Box } from "@mui/material";
import { WTypography } from "components/WTypography";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, ReactNode, useContext } from "react";

interface Props {
  tag: string;
  children: ReactNode;
}

export const FilterTitle: FC<Props> = ({ tag, children }) => {
  const { localize } = useContext(LocaleContext);

  return (
    <Box>
      <WTypography fontSize="12px" lineClamps={1} marginBottom="3px">
        {localize(tag)}
      </WTypography>
      {children}
    </Box>
  );
};
