import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const GoalsSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 278 154"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_336_6631)">
          <path
            d="M72.8897 48.242C81.6829 48.242 88.8112 41.1229 88.8112 32.341C88.8112 23.5591 81.6829 16.4399 72.8897 16.4399C64.0965 16.4399 56.9683 23.5591 56.9683 32.341C56.9683 41.1229 64.0965 48.242 72.8897 48.242Z"
            fill="#FF6584"
          />
          <path
            d="M122.818 81.3425H44.7118C44.614 81.3438 44.5163 81.336 44.4199 81.3194L81.3605 17.4152C81.5859 17.0172 81.9131 16.6861 82.3086 16.4557C82.7041 16.2254 83.1538 16.104 83.6116 16.104C84.0695 16.104 84.5191 16.2254 84.9146 16.4557C85.3102 16.6861 85.6373 17.0172 85.8628 17.4152L110.655 60.2999L111.842 62.3519L122.818 81.3425Z"
            fill="#3F3D56"
          />
          <path
            opacity="0.2"
            d="M122.818 81.3422H96.1504L109.241 62.3515L110.184 60.9836L110.655 60.2998L111.842 62.3515L122.818 81.3422Z"
            fill="black"
          />
          <path
            d="M166.307 81.3422H98.7515L111.842 62.3516L112.784 60.9836L129.843 36.2352C130.961 34.6132 133.652 34.5122 134.967 35.9293C135.055 36.026 135.137 36.1281 135.212 36.2352L166.307 81.3422Z"
            fill="#3F3D56"
          />
          <path
            d="M186.137 70.8291C188.787 70.8291 190.935 68.6839 190.935 66.0376C190.935 63.3913 188.787 61.2461 186.137 61.2461C183.487 61.2461 181.339 63.3913 181.339 66.0376C181.339 68.6839 183.487 70.8291 186.137 70.8291Z"
            fill={colors.mainSVGColor}
          />
          <path d="M186.599 80.4705H185.617L186.065 65.3164L186.599 80.4705Z" fill="#3F3D56" />
          <path
            d="M186.151 68.1883L187.206 66.7305L186.137 68.5492L186.021 68.3471L186.151 68.1883Z"
            fill="#3F3D56"
          />
          <path
            d="M186.036 69.6602L184.981 68.2026L186.05 70.021L186.166 69.819L186.036 69.6602Z"
            fill="#3F3D56"
          />
          <path
            d="M230.147 70.8291C232.796 70.8291 234.944 68.6839 234.944 66.0376C234.944 63.3913 232.796 61.2461 230.147 61.2461C227.497 61.2461 225.349 63.3913 225.349 66.0376C225.349 68.6839 227.497 70.8291 230.147 70.8291Z"
            fill={colors.mainSVGColor}
          />
          <path d="M230.609 80.4705H229.626L230.075 65.3164L230.609 80.4705Z" fill="#3F3D56" />
          <path
            d="M230.161 68.1883L231.216 66.7305L230.147 68.5492L230.031 68.3471L230.161 68.1883Z"
            fill="#3F3D56"
          />
          <path
            d="M230.046 69.6602L228.991 68.2026L230.06 70.021L230.176 69.819L230.046 69.6602Z"
            fill="#3F3D56"
          />
          <path
            d="M200.035 66.2315C203.948 66.2315 207.121 63.0631 207.121 59.1548C207.121 55.2465 203.948 52.0781 200.035 52.0781C196.122 52.0781 192.949 55.2465 192.949 59.1548C192.949 63.0631 196.122 66.2315 200.035 66.2315Z"
            fill={colors.mainSVGColor}
          />
          <path d="M200.718 80.4701H199.267L199.928 58.0889L200.718 80.4701Z" fill="#3F3D56" />
          <path
            d="M200.057 62.3308L201.614 60.1782L200.035 62.864L199.864 62.5654L200.057 62.3308Z"
            fill="#3F3D56"
          />
          <path
            d="M199.886 64.5047L198.328 62.3521L199.907 65.0375L200.078 64.7393L199.886 64.5047Z"
            fill="#3F3D56"
          />
          <path
            d="M217.315 66.2315C221.228 66.2315 224.401 63.0631 224.401 59.1548C224.401 55.2465 221.228 52.0781 217.315 52.0781C213.401 52.0781 210.229 55.2465 210.229 59.1548C210.229 63.0631 213.401 66.2315 217.315 66.2315Z"
            fill={colors.mainSVGColor}
          />
          <path d="M217.998 80.4701H216.547L217.208 58.0889L217.998 80.4701Z" fill="#3F3D56" />
          <path
            d="M217.336 62.3308L218.894 60.1782L217.315 62.864L217.144 62.5654L217.336 62.3308Z"
            fill="#3F3D56"
          />
          <path
            d="M217.166 64.5047L215.607 62.3521L217.187 65.0375L217.358 64.7393L217.166 64.5047Z"
            fill="#3F3D56"
          />
          <path
            d="M172.495 66.2315C176.409 66.2315 179.581 63.0631 179.581 59.1548C179.581 55.2465 176.409 52.0781 172.495 52.0781C168.582 52.0781 165.41 55.2465 165.41 59.1548C165.41 63.0631 168.582 66.2315 172.495 66.2315Z"
            fill={colors.mainSVGColor}
          />
          <path d="M173.178 80.4701H171.727L172.388 58.0889L173.178 80.4701Z" fill="#3F3D56" />
          <path
            d="M172.517 62.3308L174.075 60.1782L172.495 62.864L172.325 62.5654L172.517 62.3308Z"
            fill="#3F3D56"
          />
          <path
            d="M172.346 64.5047L170.788 62.3521L172.367 65.0375L172.538 64.7393L172.346 64.5047Z"
            fill="#3F3D56"
          />
          <path
            d="M256.327 154H25.0868C22.1733 154 19.2884 153.427 16.5968 152.313C13.9051 151.2 11.4594 149.568 9.39932 147.51C7.33922 145.453 5.70505 143.01 4.59013 140.322C3.47521 137.634 2.90137 134.753 2.90137 131.843C2.90137 128.933 3.47521 126.052 4.59013 123.364C5.70505 120.676 7.33922 118.233 9.39932 116.176C11.4594 114.118 13.9051 112.486 16.5968 111.373C19.2884 110.259 22.1733 109.686 25.0868 109.686H255.815C258.078 109.686 260.248 108.788 261.848 107.19C263.449 105.592 264.348 103.424 264.348 101.164C264.348 98.9041 263.449 96.7365 261.848 95.1384C260.248 93.5402 258.078 92.6424 255.815 92.6424H17.4072V79.0073H255.815C261.699 79.0073 267.342 81.3417 271.502 85.4969C275.663 89.6522 278 95.2879 278 101.164C278 107.041 275.663 112.676 271.502 116.832C267.342 120.987 261.699 123.321 255.815 123.321H25.0868C22.8237 123.321 20.6533 124.219 19.0531 125.817C17.4529 127.415 16.5539 129.583 16.5539 131.843C16.5539 134.103 17.4529 136.271 19.0531 137.869C20.6533 139.467 22.8237 140.365 25.0868 140.365H256.327V154Z"
            fill="#E6E6E6"
          />
          <path d="M36.5207 85.4839H25.9399V86.1656H36.5207V85.4839Z" fill="white" />
          <path d="M66.5563 85.4839H55.9756V86.1656H66.5563V85.4839Z" fill="white" />
          <path d="M96.5919 85.4839H86.0112V86.1656H96.5919V85.4839Z" fill="white" />
          <path d="M126.628 85.4839H116.047V86.1656H126.628V85.4839Z" fill="white" />
          <path d="M156.663 85.4839H146.083V86.1656H156.663V85.4839Z" fill="white" />
          <path d="M186.698 85.4839H176.118V86.1656H186.698V85.4839Z" fill="white" />
          <path d="M216.734 85.4839H206.153V86.1656H216.734V85.4839Z" fill="white" />
          <path d="M246.77 85.4839H236.189V86.1656H246.77V85.4839Z" fill="white" />
          <path d="M36.5207 116.163H25.9399V116.844H36.5207V116.163Z" fill="white" />
          <path d="M66.5563 116.163H55.9756V116.844H66.5563V116.163Z" fill="white" />
          <path d="M96.5919 116.163H86.0112V116.844H96.5919V116.163Z" fill="white" />
          <path d="M126.628 116.163H116.047V116.844H126.628V116.163Z" fill="white" />
          <path d="M156.663 116.163H146.083V116.844H156.663V116.163Z" fill="white" />
          <path d="M186.698 116.163H176.118V116.844H186.698V116.163Z" fill="white" />
          <path d="M216.734 116.163H206.153V116.844H216.734V116.163Z" fill="white" />
          <path d="M246.77 116.163H236.189V116.844H246.77V116.163Z" fill="white" />
          <path d="M36.5207 146.842H25.9399V147.524H36.5207V146.842Z" fill="white" />
          <path d="M66.5563 146.842H55.9756V147.524H66.5563V146.842Z" fill="white" />
          <path d="M96.5919 146.842H86.0112V147.524H96.5919V146.842Z" fill="white" />
          <path d="M126.628 146.842H116.047V147.524H126.628V146.842Z" fill="white" />
          <path d="M156.663 146.842H146.083V147.524H156.663V146.842Z" fill="white" />
          <path d="M186.698 146.842H176.118V147.524H186.698V146.842Z" fill="white" />
          <path d="M216.734 146.842H206.153V147.524H216.734V146.842Z" fill="white" />
          <path d="M246.77 146.842H236.189V147.524H246.77V146.842Z" fill="white" />
          <path
            d="M185.637 27.0562C186.748 27.0551 187.842 27.3373 188.814 27.876H182.468C183.437 27.3375 184.528 27.0552 185.637 27.0562V27.0562Z"
            fill="#E6E6E6"
          />
          <path
            d="M173.322 26.2363C175.179 26.2342 176.991 26.8071 178.508 27.8761H168.144C169.659 26.8083 171.468 26.2355 173.322 26.2363Z"
            fill="#E6E6E6"
          />
          <path
            d="M103.569 15.8574C103.795 11.8806 105.427 8.11283 108.175 5.22599C110.923 2.33915 114.608 0.520843 118.573 0.0952631C122.539 -0.330316 126.527 0.664491 129.826 2.90232C133.126 5.14016 135.522 8.47555 136.588 12.3138C136.792 12.3065 136.996 12.2983 137.201 12.2983C140.837 12.299 144.375 13.4754 147.286 15.6514C150.197 17.8275 152.323 20.8863 153.349 24.3702C155.758 23.313 158.45 23.0836 161.004 23.718C163.558 24.3524 165.829 25.8148 167.461 27.8761H114.966C113.434 27.8804 111.918 27.5761 110.507 26.9813C109.096 26.3865 107.82 25.5134 106.754 24.4144C105.689 23.3154 104.857 22.0131 104.308 20.5854C103.758 19.1576 103.503 17.6339 103.557 16.1053C103.561 16.0228 103.565 15.9402 103.569 15.8574Z"
            fill="#E6E6E6"
          />
          <path
            d="M189.467 39.3276C188.355 39.3266 187.262 39.6088 186.29 40.1475H192.636C191.667 39.6089 190.576 39.3267 189.467 39.3276Z"
            fill="#E6E6E6"
          />
          <path
            d="M201.781 38.5078C199.924 38.5057 198.112 39.0785 196.595 40.1476H206.96C205.445 39.0798 203.635 38.507 201.781 38.5078Z"
            fill="#E6E6E6"
          />
          <path
            d="M271.534 28.1289C271.309 24.152 269.677 20.3843 266.929 17.4975C264.181 14.6106 260.496 12.7923 256.53 12.3667C252.565 11.9412 248.577 12.936 245.277 15.1738C241.978 17.4116 239.582 20.747 238.516 24.5853C238.312 24.578 238.108 24.5698 237.902 24.5698C234.266 24.5705 230.729 25.7469 227.818 27.9229C224.907 30.099 222.78 33.1578 221.755 36.6417C219.346 35.5845 216.654 35.3551 214.1 35.9895C211.546 36.6239 209.275 38.0863 207.643 40.1476H260.138C261.67 40.1519 263.186 39.8476 264.597 39.2527C266.008 38.6579 267.284 37.7849 268.349 36.6859C269.414 35.5869 270.247 34.2846 270.796 32.8568C271.345 31.4291 271.6 29.9053 271.546 28.3768C271.543 28.2943 271.539 28.2117 271.534 28.1289Z"
            fill="#E6E6E6"
          />
          <path
            d="M236.536 144.471L242.637 144.475L242.638 143.507L236.536 143.504L236.536 144.471Z"
            fill="#3F3D56"
          />
          <path
            d="M192.424 143.723L193.683 144.013L241.524 144.039L241.954 143.038C242.705 141.321 242.962 139.429 242.698 137.574C242.492 136.192 241.976 134.818 240.803 134.221L238.68 120.099L212.212 120.052L201.971 128.301C201.971 128.301 197.141 128.214 194.516 131.477C193.57 132.69 193.011 134.16 192.912 135.695L192.809 137.392L192.424 143.723Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M202.931 149.386C206.194 149.386 208.839 146.745 208.839 143.486C208.839 140.228 206.194 137.586 202.931 137.586C199.669 137.586 197.024 140.228 197.024 143.486C197.024 146.745 199.669 149.386 202.931 149.386Z"
            fill="#3F3D56"
          />
          <path
            d="M202.931 146.592C204.649 146.592 206.041 145.202 206.041 143.487C206.041 141.772 204.649 140.381 202.931 140.381C201.214 140.381 199.822 141.772 199.822 143.487C199.822 145.202 201.214 146.592 202.931 146.592Z"
            fill="#CCCCCC"
          />
          <path
            d="M232.082 149.402C235.345 149.402 237.989 146.76 237.989 143.502C237.989 140.244 235.345 137.602 232.082 137.602C228.819 137.602 226.174 140.244 226.174 143.502C226.174 146.76 228.819 149.402 232.082 149.402Z"
            fill="#3F3D56"
          />
          <path
            d="M232.082 146.607C233.799 146.607 235.191 145.217 235.191 143.502C235.191 141.787 233.799 140.397 232.082 140.397C230.365 140.397 228.973 141.787 228.973 143.502C228.973 145.217 230.365 146.607 232.082 146.607Z"
            fill="#CCCCCC"
          />
          <path
            d="M206.04 127.337L220.331 127.343H222.075L225.02 127.345V127.13L225.022 124.543L225.025 121.639H223.065L221.321 121.637L218.23 121.635H216.486L212.919 121.633L206.04 127.337Z"
            fill="white"
          />
          <path
            d="M227.346 127.347L234.898 127.351L234.9 125.871V123.284L234.902 121.645H233.796L232.052 121.643L227.348 121.641L227.346 127.347Z"
            fill="white"
          />
          <path
            d="M224.049 133.439L225.018 133.439L225.019 131.602L224.05 131.601L224.049 133.439Z"
            fill="#3F3D56"
          />
          <path
            d="M209.912 129.949L209.912 128.982L208.072 128.981L208.072 129.948L209.912 129.949Z"
            fill="#3F3D56"
          />
          <path
            d="M209.234 129.407L209.127 129.407C208.862 129.407 208.598 129.355 208.353 129.253C208.107 129.151 207.884 129.002 207.696 128.814C207.509 128.627 207.36 128.404 207.258 128.158C207.156 127.913 207.104 127.65 207.104 127.385V127.385C207.105 127.119 207.157 126.856 207.259 126.611C207.361 126.366 207.51 126.143 207.698 125.956C207.886 125.768 208.109 125.619 208.355 125.518C208.6 125.416 208.864 125.364 209.13 125.364L209.236 125.364L209.234 129.407Z"
            fill="#3F3D56"
          />
          <path
            d="M192.809 137.393C193.541 137.472 194.278 137.291 194.89 136.881C195.502 136.471 195.949 135.859 196.153 135.152C196.357 134.445 196.305 133.689 196.005 133.016C195.706 132.344 195.179 131.799 194.516 131.477C193.57 132.69 193.011 134.16 192.912 135.695L192.809 137.393Z"
            fill="#3F3D56"
          />
          <path
            d="M221.32 121.637L225.02 127.13L225.022 124.543L223.065 121.639L221.32 121.637Z"
            fill="#F2F2F2"
          />
          <path
            d="M232.052 121.643L234.9 125.871V123.284L233.796 121.645L232.052 121.643Z"
            fill="#F2F2F2"
          />
          <path
            d="M216.486 121.635L220.331 127.343H222.075L218.23 121.635H216.486Z"
            fill="#F2F2F2"
          />
          <path
            d="M15.7004 97.4148C24.3715 97.4148 31.4009 90.3945 31.4009 81.7345C31.4009 73.0745 24.3715 66.0542 15.7004 66.0542C7.02932 66.0542 0 73.0745 0 81.7345C0 90.3945 7.02932 97.4148 15.7004 97.4148Z"
            fill="#3F3D56"
          />
          <path
            d="M15.7656 86.455L7.90859 78.608C7.65301 78.3522 7.50957 78.0055 7.50977 77.6441C7.50997 77.2828 7.65379 76.9362 7.90966 76.6807C8.16553 76.4252 8.5125 76.2815 8.87435 76.2813C9.2362 76.2811 9.58333 76.4244 9.83948 76.6796L15.635 82.4677L34.1288 61.4055C34.3676 61.1342 34.7046 60.9686 35.0656 60.9452C35.4266 60.9219 35.7821 61.0425 36.0541 61.2807C36.326 61.5189 36.4922 61.8552 36.5162 62.2157C36.5401 62.5762 36.4198 62.9314 36.1816 63.2034L15.7656 86.455Z"
            fill={colors.mainSVGColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_336_6631">
            <rect width="278" height="154" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
