import { Grid, Typography, Box } from "@mui/material";
import { Job } from "adapters";
import { WButton, WTypography } from "components";
import { DataChip } from "components/DataChip";
import { BreakpointsContext } from "context/BreakpointContext";
import React, { FC, SyntheticEvent, useContext } from "react";
import { prettifyDate } from "utils/PrettifyDate";
import dompurify from "dompurify";
import { LocaleContext } from "context/LocaleContext";
import { ExpandedJobBody } from "./ExpandedJobBody";
import { ApplicationStatusComponent } from "components/ApplicationStatusComponent";
import { ActiveJobApplication } from "adapters/JobAdapter";
import { UserContext } from "context/UserContext";
import { DateAndSkillRow } from "components/DateAndSkillRow";

interface Props {
  job: Job;
  opts?: {
    isExpanded?: boolean;
  };
  handleApplyClick: () => void;
  applicationData: ActiveJobApplication;
}
export const JobCardBody: FC<Props> = (props) => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { isAuthenticated } = useContext(UserContext);

  const { pitch = "", skills = [], created = "", body = "", job_status = "" } = props.job;

  const currentOpts = { isExpanded: false, ...props.opts };
  const {
    id = "",
    is_draft = false,
    status = undefined,
    status_progression = [],
  } = props.applicationData;

  const HTMLSanitizer = dompurify.sanitize;

  const handleApplyJobClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    props.handleApplyClick();
  };

  let currentChipLength = 0;

  if (isDesktop) {
    if (currentOpts.isExpanded) {
      return (
        <ExpandedJobBody
          job={props.job}
          handleApplyJobClick={handleApplyJobClick}
          applicationData={{
            id: id,
            is_draft: is_draft,
            status: status,
            status_progression: status_progression,
          }}
        />
      );
    } else {
      return (
        <>
          <DateAndSkillRow created={created} skills={skills} />

          <WTypography variant="body1" lineClamps={4}>
            {pitch}
          </WTypography>
          <Box
            position="relative"
            sx={{
              overflow: "hidden",
              maxHeight: "100px",
              "::after": {
                content: "''",
                position: "absolute",
                backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.001), white)",
                width: "100%",
                height: "2rem",
                bottom: 0,
              },
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: HTMLSanitizer(body) }}></span>
          </Box>
          <Grid container gap="1.5rem" alignItems="center">
            {id !== "" ? (
              <ApplicationStatusComponent
                isDraft={is_draft}
                applicationStatus={status}
                jobStatus={job_status}
                statusProgression={status_progression}
              />
            ) : (
              <WButton
                data-test="apply"
                data-loggedin={!!isAuthenticated}
                variant="contained"
                onClick={handleApplyJobClick}
              >
                {localize("common.applyJob")}
              </WButton>
            )}
          </Grid>
        </>
      );
    }
  } else {
    return (
      <>
        <WTypography variant="body1" lineClamps={4}>
          {pitch}
        </WTypography>
        <Grid container wrap="nowrap" gap="1rem" direction="column">
          <Grid container gap=".5rem" wrap="nowrap">
            {skills.map((skill) => {
              // Breakpoint for stop rendering Chips
              currentChipLength += skill.name.length;
              if (currentChipLength >= 20) {
                return;
              }
              return <DataChip key={skill.id} chipLabel={skill.name} />;
            })}
            {currentChipLength >= 20 && <Typography alignSelf="end">...</Typography>}
          </Grid>
          <Grid justifyContent="space-between" alignItems="center" container wrap="nowrap">
            {id !== "" && (
              <ApplicationStatusComponent
                isDraft={is_draft}
                applicationStatus={status}
                jobStatus={job_status}
                statusProgression={status_progression}
              />
            )}
            <Typography
              variant="body2"
              minWidth="max-content"
              marginLeft="auto"
              fontWeight="light"
              color="primary"
            >
              {prettifyDate(created, { showRelativeTime: true })}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
};
