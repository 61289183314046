import { Job } from "adapters";
import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export type JobMatchesActions = "GET_JOB_MATCHES";
export interface JobMatch {
  id: string;
  read: boolean;
  job: Job;
  user: string;
  match_percentage: string;
}

export const getJobMatchesList = (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<JobMatch>>> => {
  return API.get("/job-matches/", { params: { expand: "job", ...params } });
};

export const removeJobMatch = (id: string): Promise<void> => {
  return API.delete(`/job-matches/${id}/mark-as-inactive`);
};
