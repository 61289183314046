import { Grid, useTheme } from "@mui/material";
import { Job } from "adapters";
import { defaultActiveJobApplication } from "adapters/JobAdapter";
import { JobStatusStepper } from "components/modals/DetailedJobApplicationModal/JobStatusStepper";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import { isPast, isValid, parseISO } from "date-fns";
import React, { FC, useContext } from "react";
import { prettifyDate } from "utils/PrettifyDate";
import { RecruiterCard } from "./RecruiterCard";

interface ExpandedJobDescriptionProps {
  job: Job;
}

export const ExpandedJobDescription: FC<ExpandedJobDescriptionProps> = (props) => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const theme = useTheme();

  const {
    id = "",
    locations = [],
    price,
    start_date,
    end_date = "",
    recruiter = "",
    job_application = defaultActiveJobApplication,
  } = props.job;

  const calculateStartDate = (date: string) => {
    const parsedDate = parseISO(date);
    if (!isValid(parsedDate)) {
      return "";
    }

    if (isPast(parsedDate)) {
      return localize("jobCard.immediately");
    }
    return prettifyDate(date);
  };

  const items = [
    {
      showCondition: !!locations.length,
      label: locations.map((location) => location.city).join(", "),
    },
    {
      showCondition: !!start_date,
      label: `${localize("jobCard.startDate")}: ${calculateStartDate(start_date)}`,
    },
    {
      showCondition: !!end_date,
      label: `${localize("jobCard.endDate")}: ${prettifyDate(end_date)}`,
    },
    { showCondition: !!price, label: `${localize("jobCard.rate")} ${price}` },
  ];
  return (
    <Grid container wrap="nowrap" direction="column" gap="1rem">
      {job_application?.id && <JobStatusStepper jobApplication={job_application} />}
      <Grid container wrap="nowrap" gap="1rem" direction={isDesktop ? "row" : "column"}>
        {items.some((item) => item.showCondition) && (
          <Grid container>
            <ul
              style={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                paddingLeft: "1.2rem",
              }}
            >
              {items.map((item, index) => {
                if (item.showCondition) {
                  return <li key={`${item.label}-${index}`}>{item.label}</li>;
                }
                return undefined;
              })}
            </ul>
          </Grid>
        )}
        <RecruiterCard recruiter={recruiter} jobId={id} />
      </Grid>
    </Grid>
  );
};
