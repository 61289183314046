import {
  PushNotificationInitialState,
  PushNotificationContextState,
  PUSH_NOTIFICATION_TYPES,
} from "context/PushNotificationContext";

export type PushNotificationActionType = {
  type: "SET_NOTIFICATIONS";
  payload: PUSH_NOTIFICATION_TYPES[];
};

export function PushNotificationReducer(
  state: PushNotificationContextState = PushNotificationInitialState,
  action: PushNotificationActionType
): PushNotificationContextState {
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
}
