import React, { FC, useContext, useEffect } from "react";
import { User } from "reducers/UserReducer";
import { getUserList } from "adapters";
import { usePagination } from "hooks/usePagination";
import { UserListCard } from "./UserListCard";
import { Grid, Skeleton } from "@mui/material";
import { UserListCardSmall } from "./UserListCardSmall";
import { UserContext } from "context/UserContext";
import { LoaderContext } from "context/LoaderContext";
import { defaultStyles } from "styles/colors";

interface UserListProps {
  isSortByLatestApplication?: boolean;
}

export const UserList: FC<UserListProps> = ({ isSortByLatestApplication = false }) => {
  const { isLoading } = useContext(LoaderContext);
  const { users, dispatch } = useContext(UserContext);
  const { results, setLastElementRef, handleSearch } = usePagination<User>(
    getUserList,
    "error.generalError",
    "GET_USER_LIST",
    { page_size: 7 }
  );

  useEffect(() => {
    if (isSortByLatestApplication) {
      handleSearch({ ordering: "-latest_application" });
      return;
    }
    handleSearch({ ordering: "" });
  }, [isSortByLatestApplication]);

  useEffect(() => {
    dispatch({ type: "SET_USER_LIST", users: results });
  }, [results]);

  return (
    <Grid
      container
      direction="column"
      overflow="hidden"
      height="fit-content"
      padding="6px"
      flex="100px 1 0"
      gap="1rem"
    >
      {isLoading("GET_USER_LIST")
        ? Array(6)
            .fill(1)
            .map((_, index) => (
              <Skeleton
                key={`skeleton_message_${index}`}
                width="100%"
                height="100px"
                variant="rectangular"
                sx={{ borderRadius: defaultStyles.borderRadius }}
              />
            ))
        : users.map((user, index) => {
            const isLastElement = users.length === index + 1;
            return isLastElement ? (
              <div
                ref={setLastElementRef}
                key={`user_${user.email}_${index}`}
                style={{ width: "100%" }}
              >
                <UserListCard userId={user.id} />
              </div>
            ) : (
              <UserListCard key={`user_${user.email}_${index}`} userId={user.id} />
            );
          })}
    </Grid>
  );
};

interface UserSearchListProps {
  users: User[];
  selectedIds?: string[];
  onToggleSelect?: (userId: string) => void;
}

export const UserSearchList: FC<UserSearchListProps> = ({ users, selectedIds, onToggleSelect }) => {
  return (
    <Grid container gap=".5rem">
      {users.map((user, index) => (
        <UserListCardSmall
          key={`user_${user.email}_${index}`}
          userId={user.id}
          selected={selectedIds?.includes(user.id)}
          onToggleSelect={onToggleSelect}
        />
      ))}
    </Grid>
  );
};
