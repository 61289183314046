import { CircularProgress, Dialog, Grid } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { useForm } from "react-hook-form";
import { createReferral, Referral } from "adapters";

import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { DialogHeader, WButton, WIcon, WIconTypes, WTextField } from "components";
import { UserContext } from "context/UserContext";
import { validateEmail } from "components/forms/validators";
interface ProfileReferralsAddProps {
  handleClose: () => void;
  handleSuccessUpdate: (data: Referral) => void;
  disableBackendRequest?: boolean;
}

export const ProfileReferralsAdd: FC<ProfileReferralsAddProps> = ({
  handleClose,
  handleSuccessUpdate,
  disableBackendRequest = false,
}) => {
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { dispatch, user } = useContext(UserContext);

  const { register, handleSubmit, watch, formState } = useForm<Referral>({
    defaultValues: { first_name: "", last_name: "", comment: "", phone: "" },
  });

  const { errors } = formState;

  const handleReferralAdd = (data: Referral) => {
    // Avoid duplicate requests
    if (isLoading("CREATE_REFERRAL")) {
      return;
    }

    // Only return the referral data without sending creation request
    if (disableBackendRequest) {
      handleSuccessUpdate({ ...data, id: "" });
      handleClose();
      return;
    }

    dispatchLoading({ type: "SET_LOADING", payload: "CREATE_REFERRAL" });
    createReferral(data)
      .then((res) => {
        dispatchLoading({ type: "STOP_LOADING", payload: "CREATE_REFERRAL" });
        addMessage({ content: "success.updateSuccessful", type: "success" });

        dispatch({
          type: "UPDATE_USER",
          user: { ...user, referrals: [...user.referrals, res.data] },
        });

        handleSuccessUpdate(res.data);
        handleClose();
      })
      .catch(() => {
        addMessage({ type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "CREATE_REFERRAL" });
      });
  };

  return (
    <Dialog fullScreen open={true} onClose={handleClose}>
      <DialogHeader
        handleClose={handleClose}
        title={localize("profileView.newReferral")}
        returnText={localize("profileSections.profileTitleReferral")}
      />
      <Grid container padding="2rem 1rem" height="100%">
        <Grid container direction="column" gap="1rem">
          <WTextField
            fullWidth
            label={localize("form.firstnameField")}
            error={!!errors.first_name}
            helperText={errors.first_name?.message}
            value={watch("first_name")}
            {...register("first_name", {
              required: localize("form.requiredFieldError"),
              maxLength: {
                value: 150,
                message: localize("form.fieldMaxLengthError", { length: "150" }),
              },
            })}
          />
          <WTextField
            fullWidth
            label={localize("form.lastnameField")}
            error={!!errors.last_name}
            helperText={errors.last_name?.message}
            value={watch("last_name")}
            {...register("last_name", {
              maxLength: {
                value: 150,
                message: localize("form.fieldMaxLengthError", { length: "150" }),
              },
            })}
          />
          <WTextField
            fullWidth
            type="tel"
            label={localize("form.phoneNumberField")}
            error={!!errors.phone}
            helperText={errors?.phone?.message}
            value={watch("phone")}
            {...register("phone", {
              maxLength: {
                value: 30,
                message: localize("form.fieldMaxLengthError", { length: "30" }),
              },
            })}
          />
          <WTextField
            fullWidth
            type="email"
            error={!!errors.email}
            helperText={errors.email?.message}
            label={localize("form.emailField")}
            {...register("email", {
              required: localize("form.requiredFieldError"),
              validate: (value) => validateEmail(value, localize),
            })}
          />
          <WTextField
            multiline
            minRows={4}
            fullWidth
            label={localize("form.commentField")}
            error={!!errors.comment}
            helperText={errors.comment?.message}
            value={watch("comment")}
            {...register("comment", {
              maxLength: {
                value: 355,
                message: localize("form.fieldMaxLengthError", { length: "355" }),
              },
            })}
          />
        </Grid>
      </Grid>
      <WButton
        type="submit"
        startIcon={<WIcon icon={WIconTypes.check} />}
        onClick={handleSubmit(handleReferralAdd)}
        variant="outlined"
        sx={{ position: "sticky", bottom: 0, margin: "1rem", width: "90%", zIndex: 2 }}
      >
        {localize("form.save")} {localize("profileView.newReferral").toLowerCase()}
      </WButton>
      {isLoading("CREATE_REFERRAL") && (
        <CircularProgress
          color="success"
          sx={{
            margin: "auto",
          }}
        />
      )}
    </Dialog>
  );
};
