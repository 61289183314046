import React, { useContext } from "react";
import { Grid, Divider, Typography, IconButton } from "@mui/material";
import { AuthenticatedIconList } from "./AuthenticatedIconList";
import { AnonymousIconList } from "./AnonymousIconList";
import { UserContext } from "context/UserContext";
import { colors, defaultStyles } from "styles/colors";
import { WIcon, WIconButton, WIconTypes } from "components";
import { useHistory } from "react-router-dom";
import { BreakpointsContext } from "context/BreakpointContext";
import { logout } from "adapters";
import { LocaleContext } from "context/LocaleContext";

export const DESKTOP_ACTION_BAR_SIZE = "80px";
export const MOBILE_ACTION_BAR_SIZE = "70px";

export const ActionNavbar: React.FC = (): JSX.Element => {
  const { isAuthenticated, dispatch } = useContext(UserContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { localize } = useContext(LocaleContext);

  const history = useHistory();
  const handleLogout = () => {
    logout().finally(() => dispatch({ type: "LOGOUT" }));
  };
  return (
    <Grid
      className={isDesktop ? "ActionNavbarDesktop" : "ActionNavbarMobile"}
      width={isDesktop ? DESKTOP_ACTION_BAR_SIZE : undefined}
      height={!isDesktop ? MOBILE_ACTION_BAR_SIZE : undefined}
      direction={isDesktop ? "column" : "row"}
      wrap="nowrap"
      container
      borderRight={isDesktop ? `1px solid ${colors.grey}` : undefined}
      bgcolor="secondary.main"
      boxShadow={!isDesktop ? defaultStyles.shadow : 0}
      zIndex={2}
    >
      {isDesktop ? (
        <>
          <Grid container padding="1rem">
            <img
              onClick={() => history.push("/")}
              src={`${process.env.PUBLIC_URL}/assets/logo/logo_square_dark.png`}
              alt="Wise Logo"
              width="100%"
              height="100%"
              style={{ objectFit: "contain", cursor: "pointer", borderRadius: "5px" }}
            />
          </Grid>
          <Divider variant="middle" />
          <Grid container direction="column" flexGrow="1">
            <Grid container gap="1rem">
              {isAuthenticated ? <AuthenticatedIconList /> : <AnonymousIconList />}
            </Grid>
          </Grid>
          {isAuthenticated && (
            <>
              <Grid container alignItems="center" direction="column">
                <WIconButton
                  size="medium"
                  disableRipple
                  onClick={() => {
                    history.push("/settings");
                  }}
                  sx={{
                    backgroundColor: colors.main,
                    transition: "opacity 0.2s ease-out",
                    "&:hover": {
                      opacity: 0.8,
                    },
                    maxWidth: "max-content",
                  }}
                >
                  <WIcon icon={WIconTypes.settings} color="secondary" />
                </WIconButton>
                <Typography fontSize="10px" color="primary">
                  {localize("profileSections.appSettings")}
                </Typography>
              </Grid>
              <Grid container alignItems="center" direction="column">
                <IconButton size="medium" disableTouchRipple color="error" onClick={handleLogout}>
                  <WIcon icon={WIconTypes.logout} color="error" />
                </IconButton>
                <Typography fontSize="10px" color="primary">
                  {localize("navigationDialog.logout")}
                </Typography>
              </Grid>
            </>
          )}
        </>
      ) : isAuthenticated ? (
        <AuthenticatedIconList />
      ) : (
        <AnonymousIconList />
      )}
    </Grid>
  );
};
