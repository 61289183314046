import React from "react";
import { ComponentProps, FC, ReactNode } from "react";

export const combineProviders = (...components: FC[]): FC => {
  const callbackFn = (AllProviders: FC, CurrentProvider: FC, children: ReactNode): JSX.Element => {
    return (
      <AllProviders>
        <CurrentProvider>{children}</CurrentProvider>
      </AllProviders>
    );
  };

  return components.reduce(
    (AllProviders, CurrentProvider) => {
      return ({ children }: ComponentProps<FC>) =>
        callbackFn(AllProviders, CurrentProvider, children);
    },
    ({ children }) => <>{children}</>
  );
};
