import { Grid } from "@mui/material";
import { colors } from "styles/colors";
import { WTab } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { PushNotificationContext, PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext } from "react";
import { LoaderActions } from "reducers/LoaderReducer";
import { defaultStyles } from "styles/colors";
import { MY_APPLICATIONS_TAB, MY_PROFILE_TAB, MY_SAVED_TAB } from "views/ProfileView";

interface ProfileTabListProps {
  activeTab: number;
  tabCallback?: (tab: number) => void;
}

export const ProfileTabList: FC<ProfileTabListProps> = ({ tabCallback, activeTab }) => {
  const { isAuthenticated } = useContext(UserContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { hasNotification } = useContext(PushNotificationContext);
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const isAuthenticatedTabChange = (action: LoaderActions) => {
    if (!tabCallback) {
      return;
    }

    switch (action) {
      case "GET_JOB_APPLICATION_LIST":
        dispatchLoading({ type: "SET_LOADING", payload: action });
        tabCallback(MY_APPLICATIONS_TAB);
        return;
      case "GET_JOB_MATCHES":
        tabCallback(MY_SAVED_TAB);
        return;
      case "GET_JOB_LIST":
        tabCallback(MY_PROFILE_TAB);
        return;
      default:
        return;
    }
  };

  const Tabs = () => {
    return (
      <>
        <WTab
          active={activeTab === MY_PROFILE_TAB}
          label={localize("profileView.tabProfile")}
          onTabChange={() => isAuthenticated && isAuthenticatedTabChange("GET_JOB_LIST")}
          disabled={isLoading("GET_JOB_LIST")}
        />
        <WTab
          active={activeTab === MY_SAVED_TAB}
          label={localize("profileView.tabSaved")}
          onTabChange={() => isAuthenticated && isAuthenticatedTabChange("GET_JOB_MATCHES")}
          disabled={isLoading("GET_JOB_MATCHES")}
        />
        <WTab
          active={activeTab === MY_APPLICATIONS_TAB}
          label={localize("profileView.tabApplications")}
          onTabChange={() =>
            isAuthenticated && isAuthenticatedTabChange("GET_JOB_APPLICATION_LIST")
          }
          disabled={isLoading("GET_JOB_APPLICATION_LIST")}
          hasNotification={
            isAuthenticated && hasNotification(PUSH_NOTIFICATION_TYPES.jobApplication)
          }
        />
      </>
    );
  };

  if (isDesktop) {
    return (
      <Grid
        container
        item
        wrap="nowrap"
        gap="1rem"
        borderRadius={defaultStyles.borderRadius}
        xs={6}
      >
        <Tabs />
      </Grid>
    );
  }

  return (
    <Grid
      container
      bgcolor={colors.grey}
      borderRadius={defaultStyles.borderRadiusFullyRounded}
      sx={{
        maxWidth: {
          md: "500px",
        },
      }}
      alignItems="center"
      padding="4px"
      wrap="nowrap"
      gap="4px"
    >
      <Tabs />
    </Grid>
  );
};
