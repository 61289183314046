import React, { FC, useCallback, useContext } from "react";
import { Dialog, Grid } from "@mui/material";
import { colors } from "styles/colors";
import { WTextField } from "components";
import { LocaleContext } from "context/LocaleContext";
import { WTypography } from "components/WTypography";
import { WButton } from "components/styledComponents/WButton";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { LoaderContext } from "context/LoaderContext";
import { Group, createGroup } from "adapters/GroupsAdapter";
import { useForm } from "react-hook-form";

interface Props {
  onAddGroup: (group: Group) => void;
}

export interface CreateGroupFormState {
  name_sv: string;
  name_en: string;
}

export const CreateGroupDialog: FC<Props> = ({ onAddGroup }) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { hideDialog } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateGroupFormState>();

  const onCreateGroup = useCallback(
    (data: CreateGroupFormState): void => {
      dispatchLoading({ type: "SET_LOADING", payload: "CREATE_GROUP" });
      createGroup(data)
        .then((res) => {
          addMessage({ content: "success.groupCreated", type: "success" });
          onAddGroup(res.data);
        })
        .catch(() => {
          addMessage({ type: "error" });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "CREATE_GROUP" });
        });
      hideDialog();
    },
    [addMessage, dispatchLoading, hideDialog, onAddGroup]
  );

  return (
    <Dialog open={true}>
      <Grid container direction="column" padding="40px" gap="30px" bgcolor={colors.white}>
        <Grid item>
          <WTypography variant="h4">{localize("groups.createGroup")}</WTypography>
        </Grid>
        <WTextField
          fullWidth
          label={localize("groups.svNameField")}
          error={!!errors.name_sv}
          helperText={errors.name_sv?.message}
          {...register("name_sv", {
            required: localize("form.requiredFieldError"),
          })}
        />
        <WTextField
          fullWidth
          label={localize("groups.enNameField")}
          error={!!errors.name_en}
          helperText={errors.name_en?.message}
          {...register("name_en", {
            required: localize("form.requiredFieldError"),
          })}
        />

        <Grid item container justifyContent="space-between">
          <Grid item>
            <WButton variant="text" onClick={() => hideDialog()}>
              {localize("common.cancel")}
            </WButton>
          </Grid>
          <Grid item>
            <WButton
              variant="contained"
              disabled={isLoading("CREATE_GROUP")}
              onClick={handleSubmit(onCreateGroup)}
            >
              {localize("common.confirm")}
            </WButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
