import { Grid, Typography } from "@mui/material";
import { Job } from "adapters";
import { WSkeleton, WIcon, WIconTypes } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NewsContext } from "context/NewsContext";
import React, { FC, useContext } from "react";
import { JobCard } from "./JobCard";
import { WImageBanner } from "./WImageBanner";

interface MatchedTabContentProps {
  jobList: Job[];
  lastElementRef: (el: Element | null) => void;
  emptyListText?: string;
  opt: {
    listIsLoading?: boolean;
  };
}

export const MatchedTabContent: FC<MatchedTabContentProps> = ({
  jobList,
  lastElementRef,
  emptyListText,
  opt,
}) => {
  const { isLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { latestNews } = useContext(NewsContext);

  const { listIsLoading } = opt;
  return (
    <Grid
      container
      item
      xs={12}
      md={12}
      gap="1rem"
      height="fit-content"
      wrap="nowrap"
      position="relative"
    >
      <Grid container item xs={12} md={8} gap="1rem">
        {listIsLoading || isLoading("GET_JOB_LIST") ? (
          <>
            <WSkeleton width="100%" height="100px" variant="rectangular" />
            <WSkeleton width="100%" height="100px" variant="rectangular" />
            <WSkeleton width="100%" height="100px" variant="rectangular" />
          </>
        ) : !jobList.length ? (
          <Grid container direction="column" alignItems="center" padding="2rem">
            <WIcon icon={WIconTypes.frown} />
            <Typography align="center">
              {emptyListText || localize("jobListView.noResults")}
            </Typography>
          </Grid>
        ) : (
          jobList.map((job, index) => {
            const isLastElement = jobList.length === index + 1;
            return isLastElement ? (
              <div ref={lastElementRef} key={job.id} style={{ width: "100%" }}>
                <JobCard key={job.id} {...job} />
              </div>
            ) : (
              <JobCard key={job.id} {...job} />
            );
          })
        )}
      </Grid>
      {isDesktop && latestNews && !(listIsLoading || isLoading("GET_JOB_LIST")) && (
        <Grid container md={4} item position="relative" minHeight="100%">
          <Grid container maxHeight="180px" position="sticky" sx={{ top: "140px" }}>
            <WImageBanner
              title={latestNews.title}
              textContent={latestNews.body}
              imageSrc={latestNews.news_photo}
              subTitle={latestNews.sub_title}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
