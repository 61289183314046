import { Grid, Typography } from "@mui/material";
import { DynamicHeader, Navbar, WIcon, WIconButton, WIconTypes } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { UserInfoComponent } from "components/UserInfoComponent";
import { BreakpointsContext } from "context/BreakpointContext";
import { UserContext } from "context/UserContext";
import { useHistory } from "react-router-dom";
import { generateWelcomeMessage } from "utils/GenerateWelcomeMessage";

interface StatisticHeaderProps {
  handleAvatarPicker: () => void;
}
export const StatisticHeader: FC<StatisticHeaderProps> = ({ handleAvatarPicker }) => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { user } = useContext(UserContext);

  const history = useHistory();
  const { welcomeMessage } = generateWelcomeMessage();

  if (isDesktop) {
    return (
      <DynamicHeader stopDynamicBehavior={true}>
        <Navbar title={`${welcomeMessage} ${user.first_name}!`} sx={{ paddingBottom: "2rem" }} />
      </DynamicHeader>
    );
  }

  return (
    <DynamicHeader>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        sx={{
          maxWidth: {
            xs: "100%",
            md: "500px",
          },
        }}
      >
        <Typography variant="h5" fontWeight="bold" color="primary" id="elementToTransform">
          {localize("statisticView.headerMessage")}
        </Typography>
      </Grid>
      <WIconButton
        sx={{ position: "absolute", top: "1rem", right: "1rem" }}
        onClick={() => history.push("/settings")}
      >
        <WIcon icon={WIconTypes.settings} color="primary" />
      </WIconButton>
      <UserInfoComponent handleAvatarPicker={handleAvatarPicker} />
    </DynamicHeader>
  );
};
