import { Grid } from "@mui/material";
import React, { FC, TouchEvent, useState, cloneElement, isValidElement } from "react";
import { WIconTypes, WIcon } from "components";

export interface SwipeableElementPropTypes {
  handleRemove: () => Promise<boolean>;
  removeDialog?: JSX.Element;
}

export const SwipeableElement: FC<SwipeableElementPropTypes> = ({ handleRemove, children }) => {
  const [state, setState] = useState({
    startX: 0,
    left: 0,
    scrollY: 0,
    iconOpacity: 25,
    isMoving: false,
    showIcon: false,
  });
  const [isRemoving, setIsRemoving] = useState(false);

  const handleTouchStart = (e: TouchEvent) => {
    const clientX = e.changedTouches[0].clientX;
    const clientY = e.changedTouches[0].clientY;
    setState({
      ...state,
      startX: clientX,
      scrollY: clientY,
    });
  };

  const offsetY = 5;
  const swipeOffset = -150;
  const handleTouchMove = (e: TouchEvent) => {
    const clientY = e.changedTouches[0].clientY;

    if (state.isMoving || Math.abs(state.scrollY - clientY) < offsetY) {
      let deltaX = e.changedTouches[0].clientX - state.startX;
      if (deltaX < swipeOffset && !isRemoving) {
        setState({ ...state, isMoving: false, showIcon: false });
        setIsRemoving(true);
      } else if (deltaX > 0) {
        deltaX = 0;
      }

      // Change opacity of icon while moving
      let deltaIconOpacity = -deltaX * 2;
      if (deltaIconOpacity >= 100) {
        deltaIconOpacity = 100;
      }

      setState({
        ...state,
        left: deltaX,
        iconOpacity: deltaIconOpacity,
        isMoving: true,
        showIcon: true,
      });
    }
  };

  const handleTouchEnd = () => {
    if (isRemoving) {
      handleRemove().then((removedSuccessful) => {
        if (!removedSuccessful) {
          setIsRemoving(false);
        }
      });
    }
    if (state.isMoving) {
      setState({
        ...state,
        startX: 0,
        left: 0,
        scrollY: 0,
        iconOpacity: 25,
        isMoving: false,
        showIcon: false,
      });
    }
  };

  return (
    <Grid container wrap="nowrap" sx={{ position: "relative" }}>
      {isValidElement(children) &&
        cloneElement(children, {
          sx: {
            left: `${state.left}px`,
            position: "relative",
            transition: isRemoving ? "transform 1s 0s" : "width ease",
            transform: isRemoving ? "translateX(-100vw)" : undefined,
            zIndex: 2,
          },
          onTouchStart: (event: TouchEvent) => handleTouchStart(event),
          onTouchMove: (event: TouchEvent) => handleTouchMove(event),
          onTouchEnd: handleTouchEnd,
        })}
      {state.showIcon && !isRemoving && (
        <Grid
          container
          style={{
            height: "100%",
            position: "absolute",
            right: 0,
            width: "fit-content",
            transition: "opacity ease",
            opacity: `${state.iconOpacity}%`,
          }}
          alignItems="center"
          justifyContent="flex-end"
        >
          <WIcon icon={WIconTypes.trash} size="large" color="error" />
        </Grid>
      )}
    </Grid>
  );
};
