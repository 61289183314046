import { getNews, News } from "adapters";
import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";

export const NewsContext = createContext<{
  latestNews?: News;
  latestProfileNews?: News;
}>({
  latestNews: undefined,
  latestProfileNews: undefined,
});

const NewsProvider: React.FC = ({ children }) => {
  const { user } = useContext(UserContext);
  const [latestNews, setLatestNews] = useState<News>();
  const [latestProfileNews, setLatestProfileNews] = useState<News>();
  const lang = localStorage.getItem("lang") || "en";

  useEffect(() => {
    getNews({ language: user.language || lang })
      .then((res) => {
        const news = res.data.results.filter((news) => news.news_type !== "profile")[0];
        const profileNews = res.data.results.filter((news) => news.news_type !== "news")[0];
        setLatestNews(news);
        setLatestProfileNews(profileNews);
      })
      .catch(() => {
        return;
      });
  }, []);

  return (
    <NewsContext.Provider value={{ latestNews, latestProfileNews }}>
      {children}
    </NewsContext.Provider>
  );
};

export default NewsProvider;
