import React, { FC } from "react";
import { Tooltip, Typography } from "@mui/material";

import { colors } from "styles/colors";

interface ToolTipListItemProps {
  label: string;
  fontWeight?: "regular" | "bold";
}

export const ToolTipListItem: FC<ToolTipListItemProps> = ({ label, fontWeight = "regular" }) => {
  const content = (
    <Typography variant="body2" fontWeight={fontWeight} color={colors.mainDark} noWrap>
      {label}
    </Typography>
  );

  if (!label) {
    return content;
  }

  return (
    <Tooltip
      title={label}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          style: { minWidth: "max-content" },
        },
      }}
    >
      {content}
    </Tooltip>
  );
};
