import { Grid, Link, Skeleton } from "@mui/material";
import Grow from "@mui/material/Grow";
import { getGroupList, Group, GroupUser, updateGroup } from "adapters/GroupsAdapter";
import { DataChip } from "components/DataChip";
import { DropdownItem, MultiSelectDropdown } from "components/MultiSelectDropdown";
import { WIconButton } from "components/styledComponents/WIconButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { WTypography } from "components/WTypography";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { User } from "reducers/UserReducer";
import { colors, defaultStyles } from "styles/colors";
import { prettifyDate, WDateFormats } from "utils/PrettifyDate";
import Collapse from "@mui/material/Collapse";

interface Props {
  candidate: User;
}
export const CandidateDetails: FC<Props> = ({ candidate }) => {
  const { localize, currentLanguage } = useContext(LocaleContext);
  const { hideModal } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);
  const { dispatchLoading, isLoading } = useContext(LoaderContext);

  const history = useHistory();

  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [candidateGroups, setCandidateGroups] = useState<Group[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [multiselectVisible, setMultiselectVisible] = useState<boolean>(false);

  useEffect(() => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_USER_APPLICATION_LIST" });
    getGroupList({
      users: candidate.id,
      page_size: 1000,
    })
      .then((res) => {
        setCandidateGroups(res.data.results);
        setSelectedGroups(res.data.results.map((g) => g.id));
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.fetchingGroupList", duration: 2000 });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_USER_APPLICATION_LIST" });
      });

    getGroupList({
      page_size: 1000,
    })
      .then((res) => {
        setGroups(res.data.results);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.fetchingGroupList", duration: 2000 });
      });
  }, []);

  const getDropdownGroups = useMemo((): DropdownItem[] => {
    return groups.map((c) => ({
      id: c.id,
      primary: candidate?.language === "en" ? c.name_sv : c.name_en,
    }));
  }, [groups]);

  const details = useMemo(
    () => [
      {
        title: localize("candidateModal.availableFrom"),
        content: candidate.availability_date
          ? prettifyDate(candidate.availability_date, {
              dateFormat: WDateFormats.dayMonthYear,
            })
          : localize("candidateModal.none"),
        isChip: false,
      },
      {
        title: localize("candidateModal.language"),
        content: candidate.language == "en" ? "English" : "Swedish",
        isChip: false,
      },
      {
        title: localize("candidateModal.regions"),
        content:
          candidate.regions.length > 0 ? (
            candidate.regions.map((region) => <DataChip key={region.id} chipLabel={region.name} />)
          ) : (
            <WTypography variant="body2" fontStyle="italic">
              {localize("candidateModal.none")}
            </WTypography>
          ),
        isChip: true,
      },
      {
        title: localize("candidateModal.competencies"),
        content:
          candidate.skills.length > 0 ? (
            candidate.skills.map((skill) => <DataChip key={skill.id} chipLabel={skill.name} />)
          ) : (
            <WTypography variant="body2" fontStyle="italic">
              {localize("candidateModal.none")}
            </WTypography>
          ),
        isChip: true,
      },
      {
        title: localize("candidateModal.departments"),
        content:
          candidate.departments.length > 0 ? (
            candidate.departments.map((department) => (
              <DataChip key={department.id} chipLabel={department.name} />
            ))
          ) : (
            <WTypography variant="body2" fontStyle="italic">
              {localize("candidateModal.none")}
            </WTypography>
          ),
        isChip: true,
      },
    ],
    [candidate, candidateGroups]
  );

  const handleOnToggle = useCallback(
    (ids: string[]) => {
      let groupToUpdate = "";
      let idExists = false;

      if (ids.length > selectedGroups.length) {
        ids.map((id) => {
          if (groupToUpdate) {
            return;
          }

          idExists = selectedGroups.includes(id);

          if (!idExists) {
            groupToUpdate = id;
            return;
          }
        });
      } else {
        selectedGroups.map((id) => {
          if (groupToUpdate) {
            return;
          }

          idExists = ids.includes(id);

          if (!idExists) {
            groupToUpdate = id;
            return;
          }
        });
      }

      if (groupToUpdate) {
        const groupsUsers = groups.find((group) => group.id === groupToUpdate);

        if (!groupsUsers) {
          return;
        }

        const candidateDetails: GroupUser = {
          id: candidate.id,
          email: candidate.email,
          first_name: candidate.first_name,
          last_name: candidate.last_name,
          profile_picture: candidate.profile_picture,
          rating: candidate.rating ?? 0,
        };

        if (ids.length > selectedGroups.length) {
          (groupsUsers.users as GroupUser[]).push(candidateDetails);

          const temp = candidateGroups;
          temp.push(groupsUsers);

          updateGroup(groupToUpdate, {
            users: (groupsUsers.users as GroupUser[]).map((u) => u.id),
          });

          setCandidateGroups(temp);
        } else {
          const result = (groupsUsers.users as GroupUser[]).filter(
            (user) => user.id !== candidate.id
          );

          const temp = candidateGroups;
          temp.map((group, index) => {
            if (group.id === groupToUpdate) {
              temp.splice(index, 1);
              return;
            }
          });

          updateGroup(groupToUpdate, { users: (result as GroupUser[]).map((u) => u.id) });
          setCandidateGroups(temp);
        }
      }
      console.log(candidateGroups);
      setSelectedGroups(ids);
    },
    [multiselectVisible, selectedGroups]
  );

  return (
    <Grid container gap={3} direction="column">
      <Grow in={true} {...{ timeout: 1000 }}>
        <Grid item>
          <Grid container alignItems="center">
            <WTypography variant="body1" fontWeight="bold" marginRight="5px">
              {localize("candidateModal.groups")}
            </WTypography>

            <WIconButton onClick={() => setMultiselectVisible(!multiselectVisible)}>
              <WIcon icon={multiselectVisible ? WIconTypes.clear : WIconTypes.add} size="small" />
            </WIconButton>

            <Collapse orientation="horizontal" in={multiselectVisible}>
              <MultiSelectDropdown
                onToggle={handleOnToggle}
                selectedIds={selectedGroups}
                items={getDropdownGroups}
              />
            </Collapse>
          </Grid>

          {!isLoading("GET_USER_APPLICATION_LIST") ? (
            <Grid container padding="8px" gap={1}>
              {candidateGroups.length > 0 ? (
                candidateGroups.map((group) => (
                  <Link
                    key={group.id}
                    onClick={() => {
                      hideModal();
                      history.push(`/groups/${group.id}`);
                    }}
                  >
                    <DataChip
                      isLink={true}
                      chipLabel={currentLanguage === "en" ? group.name_sv : group.name_en}
                    />
                  </Link>
                ))
              ) : (
                <WTypography variant="body2" color={colors.darkGrey}>
                  -
                </WTypography>
              )}
            </Grid>
          ) : (
            <Skeleton
              height={"40px"}
              variant="rectangular"
              width="100%"
              sx={{
                borderRadius: defaultStyles.borderRadius,
              }}
            />
          )}
        </Grid>
      </Grow>

      {details.map(({ title, content, isChip }, index) => (
        <Grow key={title} in={true} {...{ timeout: 1000 + (index + 1) * 200 }}>
          <Grid item>
            <Grid container alignItems="center">
              <WTypography variant="body1" fontWeight="bold" marginRight="5px">
                {title}
              </WTypography>
            </Grid>

            {isChip ? (
              <Grid container padding="8px" gap={1}>
                {content}
              </Grid>
            ) : (
              <WTypography
                variant="body2"
                color={colors.mainDark}
                sx={{
                  backgroundColor: colors.mainWithOpacity,
                  borderRadius: defaultStyles.borderRadius,
                  padding: "12px",
                  marginTop: "8px",
                  marginLeft: "8px",
                }}
              >
                {content}
              </WTypography>
            )}
          </Grid>
        </Grow>
      ))}
      <Grid item xs textAlign="end" marginTop="30px">
        <WTypography variant="caption" color={colors.darkGrey}>
          {localize("candidateModal.joined")}:{" "}
          {prettifyDate(candidate.created, { dateFormat: WDateFormats.dayMonthYear })}
        </WTypography>
      </Grid>
    </Grid>
  );
};
