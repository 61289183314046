import { Grid } from "@mui/material";
import React, { FC, useContext, useEffect } from "react";
import { ModalContext } from "context/ModalContext";
import { LocaleContext } from "context/LocaleContext";
import { updateProfile } from "adapters";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import { useForm } from "react-hook-form";
import { WTextField } from "components/styledComponents/WTextField";
import { WTypography } from "components/WTypography";
import { BreakpointsContext } from "context/BreakpointContext";
import { WButton } from "components/styledComponents/WButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { validateLinkedInLink } from "components/forms/validators";

type SocialLinks = {
  linkedin_url: string;
};

export const ProfileSocialBody: FC = () => {
  const { user, dispatch } = useContext(UserContext);
  const { hideModal } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SocialLinks>({
    defaultValues: { linkedin_url: "" },
  });

  useEffect(() => {
    setValue("linkedin_url", user.linkedin_url);
  }, []);

  const handleSave = (data: SocialLinks): void => {
    updateProfile(data, user.id)
      .then(() => {
        dispatch({ type: "UPDATE_USER", user: { ...user, linkedin_url: data.linkedin_url } });
        addMessage({ content: "success.updateSuccessful", type: "success", duration: 1500 });
        hideModal();
      })
      .catch(() => {
        addMessage({ content: "error.addLinkedinLink", type: "error" });
      });
  };

  return (
    <Grid container padding="1rem" direction="column" gap="1rem" height="100%">
      <Grid container direction="column">
        {isDesktop && (
          <WTypography variant="h6">{localize("profileSections.profileSocialTitle")}</WTypography>
        )}
        <WTypography variant="body2">
          {localize("profileSections.profileSocialSubTitle")}
        </WTypography>
      </Grid>
      <Grid container direction="column">
        <WTypography variant="body1">
          {localize("profileSections.ProfileSocialLinkedIn")}
        </WTypography>

        <WTextField
          placeholder="LinkedIn URL"
          sx={isDesktop ? { minWidth: "400px" } : {}}
          error={!!errors.linkedin_url}
          helperText={errors?.linkedin_url?.message}
          {...register("linkedin_url", {
            validate: (value) => validateLinkedInLink(value, localize),
          })}
        />
      </Grid>
      <WButton
        fullWidth
        variant="outlined"
        type="submit"
        onClick={handleSubmit(handleSave)}
        sx={{ marginTop: "auto", marginBottom: ".5rem" }}
        startIcon={<WIcon icon={WIconTypes.check} />}
      >
        {localize("form.save")}
      </WButton>
    </Grid>
  );
};
