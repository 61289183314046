import { WButton, WIconTypes, WIcon } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { Job } from "adapters";
import { UserContext } from "context/UserContext";
import { ModalContext } from "context/ModalContext";
import { WIconButton } from "./styledComponents/WIconButton";

interface ShareJobButtonProps {
  job: Job;
  isIconButton?: boolean;
}
export const ShareJobButton: FC<ShareJobButtonProps> = ({ job, isIconButton = false }) => {
  const { localize } = useContext(LocaleContext);
  const { isAuthenticated } = useContext(UserContext);
  const { showDialog } = useContext(ModalContext);

  const { id = "", title = "" } = job;

  const generateMailURL = (id: string): string => {
    const queryString = window.location.origin;

    return encodeURIComponent(`${queryString}/jobs/${id}`);
  };
  const handleShareJobClick = () => {
    if (isAuthenticated) {
      showDialog("SHARE_JOB", { jobId: id, title: title });
    } else {
      // Send email popup if user is not logged in
      window.location.href = `mailto:${""}?subject=${localize(
        "shareJobDialog.mailSubject"
      )}: ${title}&body=${encodeURIComponent(
        localize("shareJobDialog.mailBody")
      )} ${generateMailURL(id)}`;
    }
  };

  if (isIconButton) {
    return (
      <WIconButton
        onClick={(event) => {
          event.stopPropagation();
          handleShareJobClick();
        }}
      >
        <WIcon icon={WIconTypes.share} />
      </WIconButton>
    );
  }

  return (
    <WButton
      variant="outlined"
      onClick={() => handleShareJobClick()}
      sx={{ width: "100%" }}
      startIcon={<WIcon icon={WIconTypes.share} />}
    >
      {localize("common.shareJobNoEmoji")}
    </WButton>
  );
};
