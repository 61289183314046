import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export type SkillActions =
  | "GET_SKILLS_LIST"
  | "GET_USER_SKILLS"
  | "ADD_USER_SKILLS"
  | "DELETE_USER_SKILLS";

export type Skill = {
  id: string;
  name: string;
};

export type PostSkills = {
  skills: Skill[];
};

export const getSkillsList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<Skill>>> => {
  return API.get("/skills/", { params: params });
};

export const getUserSkills = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<Skill>>> => {
  return API.get("/skills/user-skills/", { params: params });
};

export const addUserSkills = async (skills: PostSkills): Promise<PostSkills | undefined> => {
  return API.post("/skills/add/", skills);
};

export const removeUserSkills = async (skills: PostSkills): Promise<PostSkills | undefined> => {
  return API.post(`/skills/remove/`, skills);
};
