import React, { ReactNode, useContext } from "react";
import { Grid, Skeleton } from "@mui/material";
import { WTypography } from "components/WTypography";
import { defaultStyles } from "styles/colors";
import { KanbanColumnWidth, KanbanColumn, KanbanItemWidth } from "./KanbanColumn";
import { LocaleContext } from "context/LocaleContext";
import { LoaderContext } from "context/LoaderContext";
import { Job, KanbanApplicationStage, KanbanJobStage } from "adapters/JobAdapter";
import { Draggable } from "components/DragAndDrop/Draggable";
import { JobApplication } from "adapters/JobApplicationAdapter";

interface StageTypes {
  job: KanbanJobStage;
  application: KanbanApplicationStage;
}
interface ItemTypes {
  job: ProcessedJob;
  application: ProcessedApplication;
}

export interface ProcessedJob extends Job {
  kanban_job_stage: string;
  kanban_job_stage_order_no: number;
}
export interface ProcessedApplication extends JobApplication {
  stage: string;
  kanban_job_application_stage_order_no: number;
}

export type TKanbanColumn<T extends keyof StageTypes> = {
  stage: StageTypes[T];
  items: ItemTypes[T][];
};

interface Props<T extends keyof StageTypes> {
  columns: TKanbanColumn<T>[];
  itemHeight: string;
  getVisibleItems: (items: ItemTypes[T][]) => string[];
  secondaryTextCallback: (count: number, stage: StageTypes[T]) => ReactNode;
  itemCallback: (item: ItemTypes[T], visible: boolean) => ReactNode;
}
export const KanbanColumns = <T extends keyof StageTypes>(props: Props<T>): JSX.Element => {
  const {
    columns,
    itemHeight,
    getVisibleItems,
    secondaryTextCallback: secondaryCallback,
    itemCallback,
  } = props;
  const { currentLanguage } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);

  let mainContent: ReactNode = null;
  if (columns.length === 0) {
    mainContent = Array.from(Array(5)).map((_, index) => (
      <Skeleton
        key={`skeleton_${index}`}
        variant="rectangular"
        width={KanbanColumnWidth}
        height="100vh"
        sx={{ borderRadius: defaultStyles.borderRadius }}
      />
    ));
  } else {
    const showItemSkeletons =
      !columns.some((column) => column.items.length > 0) &&
      (isLoading("GET_JOB_LIST") || isLoading("GET_JOB_APPLICATION_LIST"));
    mainContent = columns.map(({ stage, items }) => {
      let title = "";
      if ((stage as KanbanApplicationStage).name) {
        title = (stage as KanbanApplicationStage).name;
      } else {
        title = stage[`name_${currentLanguage}`];
      }
      const visibleItems = getVisibleItems(items);
      const itemCount = visibleItems.length;
      return (
        <KanbanColumn
          key={stage.id}
          id={stage.id}
          title={
            <Grid container gap="10px" alignItems="center">
              <Grid item>
                <WTypography fontWeight="bold">{title}</WTypography>
              </Grid>
              <Grid item>{secondaryCallback(itemCount, stage)}</Grid>
            </Grid>
          }
          group="kanban"
        >
          {showItemSkeletons
            ? Array.from(Array(5)).map((_, index) => (
                <Skeleton
                  key={`skeleton_${index}`}
                  variant="rectangular"
                  width={KanbanItemWidth}
                  height={itemHeight}
                  sx={{ borderRadius: defaultStyles.borderRadius }}
                />
              ))
            : items.map((item) => (
                <Draggable key={`draggable_${item.id}`}>
                  {itemCallback(item, visibleItems.includes(item.id))}
                </Draggable>
              ))}
        </KanbanColumn>
      );
    });
  }

  return (
    <Grid item width="100%" flexGrow={1} padding="0px 30px 20px" sx={{ overflow: "auto" }}>
      <Grid container wrap="nowrap" width="max-content" gap="20px">
        {mainContent}
      </Grid>
    </Grid>
  );
};
