import { Dialog } from "@mui/material";
import { DialogHeader } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { ModalContext } from "context/ModalContext";
import { ProfileSocialBody } from "./ProfileSocialBody";
import { BreakpointsContext } from "context/BreakpointContext";

export const ProfileSocialModal: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { hideModal } = useContext(ModalContext);
  const { isDesktop } = useContext(BreakpointsContext);
  return (
    <Dialog fullScreen={!isDesktop} open={true} onClose={hideModal}>
      {!isDesktop && (
        <DialogHeader
          handleClose={hideModal}
          title={localize("profileSections.socialIntegration")}
          returnText={localize("profileSections.myDetails")}
        />
      )}
      <ProfileSocialBody />
    </Dialog>
  );
};
