import { Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { WDialogButton } from "components/styledComponents/WButton";

interface ConfirmDialogProps {
  title?: string;
  subtitle?: string;
  subtitleColor?: string;
  isWarning?: boolean;
  cancelAction: () => void;
  confirmAction: () => void;
  displayLoading?: boolean;
  confirmText?: string;
  cancelText?: string;
}
export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  subtitle,
  cancelAction,
  isWarning,
  confirmAction,
  displayLoading = false,
  subtitleColor,
  confirmText,
  cancelText,
}) => {
  const { localize } = useContext(LocaleContext);

  return (
    <Dialog open={true} onClose={cancelAction}>
      <DialogContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          gap="1rem"
          marginTop="1rem"
          maxWidth="300px"
        >
          <Typography variant="h6" fontWeight="bold" textAlign="center">
            {title || localize("dialog.deleteTitle")}
          </Typography>
          <Typography variant="body1" color={subtitleColor} textAlign="center">
            {subtitle || localize("dialog.deleteSubtitle")}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <WDialogButton variant="outlined" onClick={() => cancelAction()}>
            {cancelText || localize("common.goBack")}
          </WDialogButton>
          <WDialogButton
            variant="contained"
            color="primary"
            onClick={confirmAction}
            disabled={displayLoading}
          >
            {confirmText ||
              (isWarning ? localize("common.confirmTwo") : localize("common.confirm"))}
          </WDialogButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
