export const getDateFromCurrent = (offset: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() + offset);
  return date;
};

// Returns the last X days (including current day)
export const getLastDays = (days: number): string[] => {
  return Array.from({ length: days }, (_, i) => {
    const date = getDateFromCurrent(-days + i + 1);
    return `${date.getDate()}/${date.getMonth() + 1}`;
  });
};

// Returns the last X months (including current month)
export const getLastMonths = (months: number): number[] => {
  return Array.from({ length: months }, (_, i) => {
    const date = new Date();
    date.setMonth(date.getMonth() - months + i + 1);
    return date.getMonth();
  });
};
