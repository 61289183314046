import { styled, IconButton } from "@mui/material";

interface WIconButtonProps {
  variant?: string;
}

export const WIconButton = styled(IconButton)<WIconButtonProps>(
  ({ theme, variant = "default" }) => `
  ${
    variant === "filled"
      ? `
      color: white;
      background-color: ${theme.palette.primary.main};
      :hover {
          background-color: ${theme.palette.primary.main};
      }
    `
      : ``
  }
`
);

export const WIconOutlinedButton = styled(IconButton)(
  `
  border: 1px solid;
  `
);

WIconOutlinedButton.defaultProps = {
  color: "primary",
};
