import { colors } from "styles/colors";
import {
  InteractEvent,
  getHighestOrderInArea,
  getInteractPoint,
  rectPointIntersection,
  updateOrdersBetween,
} from "./utils";

/**
 * Works with both mouse and touch.
 * Make sure to call the destroy method once done (eg leaving the page).
 */
export let isDragging = false;

export class DragAndDropHandler {
  dragInitiated = false;
  heldElement: HTMLElement | null = null;
  fillerElement: HTMLElement | null = null;
  lastSwappedElement: HTMLElement | null = null;
  lastIntersectArea: HTMLElement | null = null;
  interactOffsetX = 0;
  interactOffsetY = 0;
  groupName = "";
  originalOrder = "";
  originalParent: HTMLElement | null = null;
  onDrop?: (elementId: string, areaId: string, neighburIds: (string | null)[]) => void;
  keepInView = false;

  constructor(
    group: string,
    onDrop: (elementId: string, areaId: string, neighburIds: (string | null)[]) => void,
    keepInView: boolean
  ) {
    this.onDrop = onDrop;
    document.addEventListener("mousedown", this.#dragStart);
    document.addEventListener("touchstart", this.#dragStart);
    this.groupName = `group_${group}`;
    this.keepInView = keepInView;
  }

  destroy = (): void => {
    document.removeEventListener("mousedown", this.#dragStart);
    document.removeEventListener("mousemove", this.#dragMove);
    document.removeEventListener("mouseup", this.#dragStop);
    document.removeEventListener("mouseleave", this.#dragStop);
    document.removeEventListener("touchstart", this.#dragStart);
    document.removeEventListener("touchmove", this.#dragMove);
    document.removeEventListener("touchend", this.#dragStop);
    document.removeEventListener("touchcancel", this.#dragStop);
    isDragging = false;
  };

  #dragStart = (e: InteractEvent): void => {
    if (!(e.target instanceof HTMLElement) || (e instanceof MouseEvent && e.button !== 0)) {
      return;
    }

    const element = this.#getIntersectedDraggable(e);
    if (!element) {
      return;
    }
    this.heldElement = element;

    document.addEventListener("mousemove", this.#dragMove);
    document.addEventListener("mouseup", this.#dragStop);
    document.addEventListener("mouseleave", this.#dragStop);
    document.addEventListener("touchmove", this.#dragMove, { passive: false });
    document.addEventListener("touchend", this.#dragStop, { once: true });
    document.addEventListener("touchcancel", this.#dragStop, { once: true });
    document.removeEventListener("mousedown", this.#dragStart);
    document.removeEventListener("touchstart", this.#dragStart);
  };

  #dragMove = (e: InteractEvent): void => {
    if (!this.heldElement) {
      return;
    }

    if (!this.dragInitiated) {
      this.#initiateDragging(e);
    }

    if (!this.heldElement?.parentElement || !this.fillerElement?.parentElement) {
      this.destroy();
      return;
    }

    if (e.cancelable) {
      e.preventDefault();
    }

    const lastSwappedElementRect = this.lastSwappedElement?.getBoundingClientRect();
    if (
      lastSwappedElementRect &&
      !rectPointIntersection(lastSwappedElementRect, getInteractPoint(e))
    ) {
      this.lastSwappedElement = null;
    }

    const intersectedElement = this.#getIntersectedDraggable(e);
    if (intersectedElement) {
      this.#swapWithIntersected(intersectedElement);
    } else {
      const intersectedArea = this.#getIntersectedDropArea(e);
      if (intersectedArea && intersectedArea !== this.lastIntersectArea) {
        this.#swapArea(intersectedArea);
      }
    }

    this.#setHeldElementPosition(e);

    if (this.keepInView) {
      setTimeout(() => {
        this.heldElement?.scrollIntoView({
          block: "nearest",
        });
      }, 1000);
    }
  };

  #dragStop = (): void => {
    document.removeEventListener("mousemove", this.#dragMove);
    document.removeEventListener("mouseup", this.#dragStop);
    document.removeEventListener("mouseleave", this.#dragStop);
    document.removeEventListener("touchmove", this.#dragMove);
    document.removeEventListener("touchend", this.#dragStop);
    document.removeEventListener("touchcancel", this.#dragStop);
    document.addEventListener("mousedown", this.#dragStart);
    document.addEventListener("touchstart", this.#dragStart);

    if (!this.dragInitiated) {
      this.heldElement = null;
      this.fillerElement = null;
    }

    this.dragInitiated = false;
    isDragging = false;
    this.lastSwappedElement = null;

    if (!this.heldElement?.parentElement || !this.fillerElement?.parentElement) {
      return;
    }

    if (
      this.fillerElement.parentElement !== this.originalParent ||
      this.fillerElement.style.order !== this.originalOrder
    ) {
      this.#handleDrop();
    }
    this.fillerElement.parentElement.removeChild(this.fillerElement);

    this.heldElement.style.position = "";
    this.heldElement.style.transform = "";
    this.heldElement.style.zIndex = "";
    this.heldElement.style.left = "";
    this.heldElement.style.top = "";
    this.heldElement.style.order = this.fillerElement.style.order;
    this.heldElement.style.boxShadow = "";
    this.heldElement.style.pointerEvents = "auto";

    this.heldElement = null;
    this.fillerElement = null;

    const dropAreas = document.getElementsByClassName("droparea");
    Array.from(dropAreas).forEach((area) => {
      const htmlElement = area as HTMLElement;
      htmlElement.classList.remove("showIsDroppable");
      htmlElement.classList.remove("highlightAsCurrentTarget");
    });
  };

  #initiateDragging = (e: InteractEvent): void => {
    if (!this.heldElement?.parentElement) {
      this.destroy();
      return;
    }

    this.dragInitiated = true;
    isDragging = true;

    const parentElement = this.heldElement.parentElement;

    this.originalOrder = this.heldElement.style.order;
    this.originalParent = parentElement;

    const rect = this.heldElement.getBoundingClientRect();
    const parentRect = parentElement.getBoundingClientRect();
    const x = rect.left + parentElement.scrollLeft - parentRect.left;
    const y = rect.top + parentElement.scrollTop - parentRect.top;
    this.heldElement.style.transform = `translate(${x}px, ${y}px)`;
    this.heldElement.style.position = "absolute";
    this.heldElement.style.left = "0";
    this.heldElement.style.top = "0";
    this.heldElement.style.zIndex = "100";
    this.heldElement.style.boxShadow = `0 0 10px #999, 0 0 0 2px ${colors.main}`;
    this.heldElement.style.pointerEvents = "none";

    const filler = document.createElement("div");
    filler.style.width = `${rect.width}px`;
    filler.style.minWidth = `${rect.width}px`;
    filler.style.height = `${rect.height}px`;
    filler.style.minHeight = `${rect.height}px`;
    filler.style.border = `dashed 2px ${colors.darkGrey}`;
    filler.style.order = this.heldElement.style.order;

    this.fillerElement = filler;
    this.lastIntersectArea = parentElement;
    parentElement.appendChild(filler);

    const { x: interactX, y: interactY } = getInteractPoint(e);
    this.interactOffsetX = interactX - rect.left;
    this.interactOffsetY = interactY - rect.top;

    const dropAreas = document.getElementsByClassName("droparea");
    Array.from(dropAreas)
      .filter((area) => area.classList.contains(this.groupName))
      .forEach((area) => {
        const htmlElement = area as HTMLElement;
        if (htmlElement.classList.contains(this.groupName)) {
          htmlElement.classList.add("showIsDroppable");
        }
      });
    parentElement.classList.add("highlightAsCurrentTarget");
  };

  #setHeldElementPosition = (e: InteractEvent): void => {
    if (!this.heldElement?.parentElement) {
      this.destroy();
      return;
    }

    const parentElement = this.heldElement.parentElement;
    const { x: interactX, y: interactY } = getInteractPoint(e);
    const rect = parentElement.getBoundingClientRect();
    const x = interactX - rect.left - this.interactOffsetX + parentElement.scrollLeft;
    const y = interactY - rect.top - this.interactOffsetY + parentElement.scrollTop;
    this.heldElement.style.transform = `translate(${x}px, ${y}px)`;
  };

  #getIntersectedDraggable = (e: InteractEvent): HTMLElement | null => {
    const { x, y } = getInteractPoint(e);

    const intersected = document.elementFromPoint(x, y);
    let el: HTMLElement | null = intersected as HTMLElement;
    while (el) {
      const htmlEl = el as HTMLElement;
      if (htmlEl.parentElement?.classList.contains(this.groupName)) {
        if (
          el !== this.heldElement &&
          el !== this.lastSwappedElement &&
          htmlEl.classList.contains("draggable")
        ) {
          return htmlEl;
        }
        return null;
      }
      el = htmlEl.parentElement;
    }

    return null;
  };

  #getIntersectedDropArea = (e: InteractEvent): HTMLElement | null => {
    if (!this.heldElement?.parentElement || !this.fillerElement?.parentElement) {
      this.destroy();
      return null;
    }

    const pos = getInteractPoint(e);
    const fillerParent = this.fillerElement.parentElement;
    const fillerParentRect = fillerParent.getBoundingClientRect();

    if (rectPointIntersection(fillerParentRect, pos)) {
      return fillerParent;
    }

    const dropAreas = document.getElementsByClassName("droparea");
    for (const area of Array.from(dropAreas)) {
      if (area === fillerParent || !area.classList.contains(this.groupName)) {
        continue;
      }

      if (rectPointIntersection(area.getBoundingClientRect(), pos)) {
        return area as HTMLElement;
      }
    }

    return null;
  };

  #swapWithIntersected = (intersectedElement: HTMLElement): void => {
    if (!intersectedElement?.parentElement || !this.fillerElement?.parentElement) {
      this.destroy();
      return;
    }

    this.lastSwappedElement = intersectedElement;

    const intersectParent = intersectedElement.parentElement;

    const order1 = parseInt(this.fillerElement.style.order);
    const order2 = parseInt(intersectedElement.style.order);
    this.fillerElement.style.order = order2.toString();

    updateOrdersBetween(intersectParent, order1, order2, this.fillerElement);
  };

  #swapArea = (newArea: HTMLElement): void => {
    if (!this.fillerElement?.parentElement) {
      return;
    }

    const order1 = parseInt(this.fillerElement.style.order);

    updateOrdersBetween(
      this.fillerElement.parentElement,
      order1,
      Number.MAX_SAFE_INTEGER,
      this.fillerElement
    );

    this.fillerElement.style.order = (getHighestOrderInArea(newArea) + 1).toString();
    this.fillerElement.parentElement.removeChild(this.fillerElement);
    newArea.appendChild(this.fillerElement);

    newArea.classList.add("highlightAsCurrentTarget");
    if (this.lastIntersectArea) {
      this.lastIntersectArea.classList.remove("highlightAsCurrentTarget");
    }

    this.lastIntersectArea = newArea;
  };

  #handleDrop = (): void => {
    if (!this.onDrop || !this.heldElement || !this.fillerElement?.parentElement) {
      return;
    }

    let closestLower = -1;
    let closestHigher = Number.MAX_SAFE_INTEGER;
    const currentOrder = parseInt(this.fillerElement.style.order);
    const ids: (string | null)[] = [null, null];
    Array.from(this.fillerElement.parentElement.children).forEach((el) => {
      const element = el as HTMLElement;
      if (element === this.heldElement || element === this.fillerElement) {
        return;
      }
      const order = parseInt(element.style.order);
      if (order < currentOrder && order > closestLower) {
        closestLower = order;
        ids[0] = element.id;
      } else if (order > currentOrder && order < closestHigher) {
        closestHigher = order;
        ids[1] = element.id;
      }
    });

    this.onDrop(this.heldElement.id, this.fillerElement.parentElement.id, ids);
  };
}
