import { BottomNavigationAction, Typography, useTheme } from "@mui/material";
import { FloatingIndicatorWrapper } from "components/FloatingIndicatorWrapper";
import React, { FC, ReactElement } from "react";
import { useLocation, useHistory } from "react-router-dom";

interface NavbarIcon {
  icon: ReactElement;
  route: string;
  label: string;
  hasNotification?: boolean;
}
export const NavbarIcon: FC<NavbarIcon> = ({ icon, route, label, hasNotification = false }) => {
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const currentLocation = location.pathname;
  const isActive = currentLocation === route;

  return (
    <BottomNavigationAction
      showLabel
      disableRipple
      icon={<FloatingIndicatorWrapper condition={hasNotification}>{icon}</FloatingIndicatorWrapper>}
      label={
        <Typography
          fontSize="10px"
          color={isActive ? theme.palette.primary.main : theme.palette.primary.light}
        >
          {label}
        </Typography>
      }
      onClick={() => history.push(route)}
      sx={{
        "& svg": {
          stroke: isActive ? theme.palette.primary.main : theme.palette.primary.light,
        },
        padding: 0,
      }}
    />
  );
};
