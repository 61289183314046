import { HorizontalBarChart } from "components/StatisticView/HorizontalBarChart";
import { WIcon, WIconTypes } from "components/WIcon";
import { WTypography } from "components/WTypography";
import React, { FC, useContext, useEffect, useState } from "react";
import { Grid, Skeleton, Tooltip } from "@mui/material";
import { colors, defaultStyles } from "styles/colors";
import { getTopSkills, TopSkills } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { StatisticsFilterContext } from "context/StatisticsFilterContext";
import { prettifyDate } from "utils/PrettifyDate";
import { LocaleContext } from "context/LocaleContext";
import { getDateFromCurrent } from "utils/TimeUnitToDate";

export const CandidatesPopularCompetencies: FC = () => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { period } = useContext(StatisticsFilterContext);
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);

  const [popularCompetencies, setPopularCompetencies] = useState<TopSkills>(defaultTopSkillTotal);

  useEffect(() => {
    const periodLength = period === "week" ? 7 : 30;

    dispatchLoading({ type: "SET_LOADING", payload: "GET_TOP_SKILLS" });
    getTopSkills({
      from_date: prettifyDate(getDateFromCurrent(-periodLength + 1).toISOString()),
      to_date: prettifyDate(new Date().toISOString()),
    })
      .then((res) => {
        setPopularCompetencies(res.data);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.listingSkills", duration: 2000 });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_TOP_SKILLS" });
      });
  }, [period]);

  return (
    <Grid
      item
      width="100%"
      minWidth="350px"
      sx={{
        padding: "20px",
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadius,
        boxShadow: defaultStyles.cardShadow,
      }}
    >
      <Grid container justifyContent="space-between" marginBottom="10px">
        <WTypography variant="h5" fontWeight="bold" marginTop="10px">
          {localize("statisticView.popularCompetencies")}{" "}
        </WTypography>
        <Tooltip title={localize("statisticView.popularCompetenciesTooltip")} placement="left">
          <Grid>
            <WIcon icon={WIconTypes.info} />
          </Grid>
        </Tooltip>
      </Grid>

      {!isLoading("GET_TOP_SKILLS") ? (
        <HorizontalBarChart data={popularCompetencies.job_and_user_skills} />
      ) : (
        <Grid container justifyContent="center">
          <Skeleton
            height="1100px"
            width="90%"
            variant="rectangular"
            sx={{ borderRadius: defaultStyles.borderRadius, marginBottom: "1px" }}
          />
        </Grid>
      )}
    </Grid>
  );
};

const defaultTopSkillTotal: TopSkills = {
  job_and_user_skills: [],
};
