import { Grid } from "@mui/material";
import { WButton, WTextField } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { useForm } from "react-hook-form";

export type SetPasswordState = {
  password: string;
  re_password: string;
};

interface SetPasswordProps {
  onSubmit: (data: SetPasswordState) => void;
}
export const SetPasswordForm: FC<SetPasswordProps> = ({ onSubmit }) => {
  const { localize } = useContext(LocaleContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SetPasswordState>();

  const currPassword = watch("password");
  const currRePassword = watch("re_password");
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container gap="1rem" justifyContent="center">
        <WTextField
          fullWidth
          label={localize("form.newPasswordField")}
          error={!!errors.password}
          helperText={errors?.password?.message}
          {...register("password", {
            required: localize("form.requiredFieldError"),
            minLength: { value: 8, message: localize("form.fieldMinLengthError", { length: "8" }) },
            max: 128,
          })}
          type="password"
        />
        <WTextField
          fullWidth
          label={localize("form.confirmPasswordField")}
          error={!!errors.re_password}
          helperText={errors?.re_password?.message}
          {...register("re_password", {
            required: localize("form.requiredFieldError"),
            minLength: { value: 8, message: localize("form.fieldMinLengthError", { length: "8" }) },
            max: 128,
            validate: (value) => {
              return value === currPassword || localize("form.confirmPasswordFieldNoMatch");
            },
          })}
          type="password"
        />
        <WButton
          fullWidth
          variant="contained"
          type="submit"
          disabled={!currPassword || !currRePassword}
        >
          {localize("form.setPassword")}
        </WButton>
      </Grid>
    </form>
  );
};
