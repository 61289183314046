import React, { useContext, FC, useState, useEffect, useMemo } from "react";
import { Typography, Grid, Skeleton } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { colors, defaultStyles } from "styles/colors";
import { getTrendInfo } from "./TrendInfo";
import { getJobApplicationList, getUsersData, JobApplication, UsersData } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { StatisticsFilterContext } from "context/StatisticsFilterContext";
import { WTypography } from "components/WTypography";
import { usePagination } from "hooks/usePagination";

interface trendProps {
  total: number;
  previous: number;
}
export const CandidatesTrendOverview: FC<trendProps> = ({ total, previous }) => {
  const { localize } = useContext(LocaleContext);
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { period } = useContext(StatisticsFilterContext);
  const { isLoading } = useContext(LoaderContext);

  const [usersData, setUsersData] = useState<UsersData>(defaultUserData);

  const { count } = usePagination<JobApplication>(
    getJobApplicationList,
    "error.fetchingJobApplications",
    "GET_JOB_APPLICATION_LIST"
  );
  const applicationsCount = count;

  useEffect(() => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_USERS_DATA" });
    getUsersData()
      .then((res) => {
        setUsersData(res.data);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.fetchUsers", duration: 2000 });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_USERS_DATA" });
      });
  }, [period]);

  const threeTrends = useMemo(
    () => [
      {
        name: localize("statisticView.newCandidates"),
        total: total,
        previous: previous,
      },
      {
        name: localize("statisticView.activeCandidates"),
        total: usersData.active,
        previous: 0,
      },
      {
        name: localize("statisticView.totalApplications"),
        total: applicationsCount,
        previous: 0,
      },
    ],
    [total, previous, usersData, applicationsCount]
  );

  return (
    <Grid
      item
      width="100%"
      minWidth="350px"
      sx={{
        padding: "20px",
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadius,
        boxShadow: defaultStyles.cardShadow,
      }}
    >
      <Typography variant="h5" fontWeight="bold" color={colors.textDark}>
        {localize("statisticView.overview")}
      </Typography>
      <Typography variant="body2">
        {localize("statisticView.outOfXCandidates", {
          total: usersData.total.toString(),
        })}{" "}
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="10px"
      >
        {isLoading("GET_USERS_DATA")
          ? Array(3)
              .fill(1)
              .map((_, index) => {
                return (
                  <Skeleton
                    key={`skeleton_message_${index}`}
                    width="33%"
                    height="90px"
                    variant="rectangular"
                    sx={{ borderRadius: defaultStyles.borderRadius, marginBottom: "1px" }}
                  />
                );
              })
          : threeTrends.map((trend, index) => {
              return (
                <Grid
                  item
                  xs
                  key={trend.name}
                  borderLeft={index !== 0 ? defaultStyles.borderLeftMainDark : "none"}
                  paddingLeft={index !== 0 ? "20px" : "0"}
                  marginLeft={index !== 0 ? "5px" : "0"}
                >
                  <WTypography variant="h4" fontWeight="bold">
                    {trend.total}
                  </WTypography>
                  <WTypography variant="body2">{trend.name}</WTypography>
                  {index == 0 ? (
                    <Grid
                      container
                      alignItems="center"
                      width="max-content"
                      color={colors.darkGrey}
                      gap="3px"
                      fontSize="small"
                    >
                      {getTrendInfo(trend.total, trend.previous).trendOutput}
                    </Grid>
                  ) : null}
                </Grid>
              );
            })}
      </Grid>
    </Grid>
  );
};

const defaultUserData: UsersData = {
  total: 0,
  active: 0,
  inactive: 0,
  set_up_skills: 0,
  new_job_subscribe: 0,
};
