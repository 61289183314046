import { Grid, IconButton } from "@mui/material";
import { Task } from "adapters/TaskAdapter";
import React, {
  FC,
  HTMLAttributes,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { colors, defaultStyles } from "styles/colors";
import { WIcon, WIconTypes } from "../WIcon";
import { WTypography } from "../WTypography";
import { LocaleContext } from "context/LocaleContext";
import { format, isPast, isToday, isValid } from "date-fns";
import { useHistory } from "react-router";
import { ModalContext } from "context/ModalContext";
import { WTextField } from "components/styledComponents/WTextField";
import { WButton } from "components/styledComponents/WButton";
import { TaskMenuButton } from "./TaskMenuButton";
import { TaskTag, TaskTagInfo } from "./TaskTag";
import { WDateFormats } from "utils/PrettifyDate";
import { DatePicker } from "@mui/lab";
import { AddTagButtons } from "./AddTagButtons";
import { AddTaskTag } from "./AddTaskTag";
import { UserContext } from "context/UserContext";
import { getNameOrEmail } from "utils/generalUtils";

interface Props extends HTMLAttributes<HTMLDivElement> {
  task: Task;
  isEditing: boolean;
  onStartEdit: () => void;
  onEdit: (updatedData: Partial<Task>) => void;
  onFinishEdit: (doSave: boolean, finishedData?: Task) => void;
  onDelete: () => void;
}

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 600;

export const TaskCard: FC<Props> = ({
  task,
  isEditing,
  onStartEdit,
  onEdit,
  onFinishEdit,
  onDelete,
  ...restProps
}) => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { showModal, hideModal } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const [addingTag, setAddingTag] = useState("");

  const {
    id,
    title,
    description,
    status,
    due_date,
    agent,
    jobs,
    job_applications,
    users,
    done_date,
  } = task;
  const isDone = status === "done";

  const tags = useMemo(() => {
    const tags: TaskTagInfo[] = [];
    tags.push({
      type: "agent",
      text: agent.id === user.id ? localize("tasks.you") : getNameOrEmail(agent),
      color: agent.id === user.id ? colors.mainDark : undefined,
    });
    if (due_date) {
      const d = new Date(due_date);
      tags.push({
        type: "date",
        text: due_date,
        color: !isDone && (isToday(d) || isPast(d)) ? colors.error : undefined,
      });
    }
    if (jobs.length > 0) {
      jobs.forEach(({ title, id }, index) => {
        tags.push({
          internalIndex: index,
          type: "jobs",
          text: title,
          onClick: (e) => {
            e.stopPropagation();
            history.push(`/kanban/jobs/${id}`);
          },
        });
      });
    }
    if (job_applications.length > 0) {
      job_applications.forEach(({ job: { title }, user }, index) => {
        tags.push({
          internalIndex: index,
          type: "job_applications",
          text: `${getNameOrEmail(user)}: ${title}`,
          onClick: (e) => {
            e.stopPropagation();
            hideModal();
            setTimeout(() => {
              showModal("CANDIDATE_MODAL", { candidateId: user.id });
            }, 1);
          },
        });
      });
    }
    if (users.length > 0) {
      users.forEach(({ id, first_name, last_name, email }, index) => {
        tags.push({
          internalIndex: index,
          type: "users",
          text: `${first_name} ${last_name} (${email})`,
          onClick: (e) => {
            e.stopPropagation();
            hideModal();
            setTimeout(() => {
              showModal("CANDIDATE_MODAL", { candidateId: id });
            }, 1);
          },
        });
      });
    }
    return tags;
  }, [agent, due_date, jobs, job_applications, users]);

  const onToggleStatus = useCallback(
    (e: MouseEvent): void => {
      e.stopPropagation();
      const newStatus = status === "done" ? "not_started" : "done";
      onFinishEdit(true, {
        status: newStatus,
        done_date: newStatus === "done" ? new Date().toISOString() : undefined,
      } as Task);
    },
    [onFinishEdit, status]
  );

  const onDatePickerChange = useCallback(
    (newDate: Date | null) => {
      if (isValid(newDate) || newDate === null) {
        onEdit({
          due_date: newDate ? format(newDate, WDateFormats.date) : null,
        });
      }
    },
    [onEdit]
  );

  if (!isEditing) {
    return (
      <Grid
        container
        direction="column"
        flexWrap="nowrap"
        bgcolor={colors.white}
        padding="16px"
        paddingTop="10px"
        borderRadius={defaultStyles.borderRadius}
        width="100%"
        gap="5px"
        id={id}
        {...restProps}
      >
        <Grid item display="flex" alignItems="center">
          <IconButton sx={{ marginLeft: "-10px" }} onClick={onToggleStatus}>
            <WIcon
              icon={isDone ? WIconTypes.checkSquare : WIconTypes.square}
              customColor={colors.main}
            />
          </IconButton>
          <WTypography
            variant="body1"
            fontSize="14px"
            fontWeight="bold"
            marginRight="auto"
            lineClamps={5}
            sx={{
              textDecoration: isDone ? "line-through" : "none",
              textDecorationColor: colors.textDark,
            }}
          >
            {title}
          </WTypography>
          <TaskMenuButton onStartEdit={!isDone ? onStartEdit : undefined} onDelete={onDelete} />
        </Grid>
        {description ? (
          <Grid item>
            <WTypography
              variant="body2"
              fontSize="14px"
              color={colors.darkGrey}
              lineClamps={100}
              whiteSpace="pre-line"
            >
              {description}
            </WTypography>
          </Grid>
        ) : null}
        <Grid item container gap="2px 10px">
          {tags.map((info) => (
            <TaskTag key={`${info.type}_${info.text}`} {...info} />
          ))}
        </Grid>
        {isDone && done_date ? (
          <Grid item>
            <WTypography variant="body2" fontSize="0.8rem" color={colors.darkGrey}>
              {localize("tasks.completedDate")} {format(new Date(done_date), WDateFormats.date)}
            </WTypography>
          </Grid>
        ) : null}
      </Grid>
    );
  }

  const titleValid = title.length > 0 && title.length <= MAX_TITLE_LENGTH;
  const descriptionValid = (description?.length ?? 0) <= MAX_DESCRIPTION_LENGTH;

  return (
    <Grid
      container
      direction="column"
      flexWrap="nowrap"
      bgcolor={colors.white}
      padding="16px"
      borderRadius={defaultStyles.borderRadius}
      width="100%"
      gap="15px"
      id={id}
      {...restProps}
    >
      <Grid item>
        <WTextField
          fullWidth
          label={localize("common.title")}
          value={title}
          onChange={(e) => onEdit({ title: e.target.value })}
          required
          error={title.length > MAX_TITLE_LENGTH}
          helperText={
            title.length > MAX_TITLE_LENGTH
              ? localize("form.maxTextLength", {
                  limit: MAX_TITLE_LENGTH.toString(),
                  over: (title.length - MAX_TITLE_LENGTH).toString(),
                })
              : ""
          }
        />
      </Grid>
      <Grid item>
        <WTextField
          fullWidth
          label={localize("tasks.description")}
          value={description ?? ""}
          onChange={(e) => onEdit({ description: e.target.value })}
          multiline
          minRows={2}
          maxRows={5}
          error={!descriptionValid}
          helperText={
            description && !descriptionValid
              ? localize("form.maxTextLength", {
                  limit: MAX_DESCRIPTION_LENGTH.toString(),
                  over: (description.length - MAX_DESCRIPTION_LENGTH).toString(),
                })
              : ""
          }
        />
      </Grid>
      <Grid item>
        <DatePicker
          label={localize("tasks.dueDate")}
          mask="____-__-__"
          value={due_date}
          inputFormat={WDateFormats.date}
          renderInput={(params) => <WTextField fullWidth {...params} />}
          onChange={onDatePickerChange}
          InputAdornmentProps={{ position: "start" }}
          clearable
          views={["day"]}
        />
      </Grid>
      <Grid item container gap="2px 10px">
        {tags.map((info) => {
          if (info.type === "date" || info.type === "agent") {
            return null;
          }

          return (
            <TaskTag
              key={`${info.type}_${info.text}`}
              {...info}
              isEditing={true}
              onDelete={() => {
                if (typeof info.internalIndex === "undefined") {
                  onEdit({ [info.type]: null });
                  return;
                }
                const result = [...task[info.type]];
                result.splice(info.internalIndex, 1);
                onEdit({ [info.type]: result });
              }}
            />
          );
        })}
      </Grid>

      <AddTaskTag task={task} addingTag={addingTag} setAddingTag={setAddingTag} onEdit={onEdit} />

      {addingTag === "" ? (
        <>
          <AddTagButtons types={["jobs", "applications", "users"]} setAddingTag={setAddingTag} />
          <Grid item container justifyContent="space-between">
            <WButton key="cancelEdit" onClick={() => onFinishEdit(false)}>
              {localize("common.cancel")}{" "}
            </WButton>
            <WButton
              variant="contained"
              color="primary"
              disabled={!titleValid || !descriptionValid}
              onClick={() => onFinishEdit(true)}
            >
              {localize("form.save")}
            </WButton>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};
