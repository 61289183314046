import { Grid, Collapse, Box } from "@mui/material";
import { WButton, WIcon, WIconTypes } from "components";
import { ProfileSectionCard } from "components/ProfileView/ProfileSectionCard";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import React, { FC, useContext, useState, useRef } from "react";
import { ReferralList } from "./ReferralList";

export const ReferralSection: FC = () => {
  const { showModal, showDialog, hideDialog } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const [isExpanded, setExpand] = useState(false);
  const uploadFile = useRef<HTMLInputElement | null>(null);

  if (isDesktop) {
    return (
      <Box>
        <ProfileSectionCard
          isExpanded={isExpanded}
          title={"profileTitleReferral"}
          subTitle={localize("profileSections.profileSubTitleReferral")}
          onClick={() => setExpand(!isExpanded)}
          infoToolTip={localize("profileSections.referenceDialogInfo")}
          icons={
            <Grid container alignItems="center" gap="1rem">
              <WButton
                variant="outlined"
                startIcon={<WIcon icon={WIconTypes.addUser} />}
                onClick={(event) => {
                  event.stopPropagation();
                  showDialog("REFERRAL_DIALOG_ADD", {
                    handleClose: () => hideDialog(),
                    handleSuccessUpdate: () => hideDialog(),
                  });
                  uploadFile.current?.click();
                }}
              >
                {localize("profileView.addReferral")}
              </WButton>
              <WIcon icon={isExpanded ? WIconTypes.arrowUp : WIconTypes.arrowDown} />
            </Grid>
          }
        />
        <Collapse in={isExpanded} unmountOnExit>
          <ReferralList />
        </Collapse>
      </Box>
    );
  }

  return (
    <ProfileSectionCard
      title={"profileTitleReferral"}
      subTitle={localize("profileSections.profileSubTitleReferral")}
      onClick={() => showModal("PROFILE_REFERRALS_MODAL")}
    />
  );
};
