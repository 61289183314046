import React, { FC, Children, cloneElement, HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  group: string;
  id: string;
}

export const DropArea: FC<Props> = (props) => {
  const { group, children, ...restProps } = props;

  return (
    <div
      className={`droparea group_${group}`}
      {...restProps}
      style={{ position: "relative", ...restProps.style }}
    >
      {Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        const childElement = child as React.ReactElement;
        return cloneElement(childElement, {
          order: index,
        });
      })}
    </div>
  );
};
