import { getWithExpiry } from "components/dialogs/CookieConsent";
import React, { createContext, useEffect, useState } from "react";

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

export interface DataLayerVariables {
  user_id: string;
  logged_in: string;
  action: string;
  label: string;
  dl_page: string;
  dl_page_path: string;
  profile_strength: number;
}

export type GAEvent =
  | "log_in"
  | "user_status"
  | "cta_click"
  | "dl_pageview"
  | "create_account"
  | "application_submitted"
  | "application_verified"
  | "application_saved"
  | "application_updated"
  | "application_shared"
  | "profile_update"
  | "subscription_paused"
  | "subscription_started"
  | "chat"
  | "search";

export const GTMContext = createContext<{
  gtmAddToDataLayer: (dataLayerObject: Partial<DataLayerVariables>, event: GAEvent) => void;
  cookieValue: string | null;
  setCookieValue: (state: string | null) => void;
}>({
  gtmAddToDataLayer: () => false,
  cookieValue: null,
  setCookieValue: () => false,
});

const GTMProvider: React.FC = ({ children }) => {
  const [cookieValue, setCookieValue] = useState<string | null>("");

  const gtmAddToDataLayer = (
    dataLayerObject: Partial<DataLayerVariables>,
    event: GAEvent
  ): void => {
    if (cookieValue === "true") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: event, ...dataLayerObject });
    }
  };

  useEffect(() => {
    setCookieValue(getWithExpiry("allowAnalytics"));
  }, []);

  return (
    <GTMContext.Provider value={{ gtmAddToDataLayer, cookieValue, setCookieValue }}>
      {children}
    </GTMContext.Provider>
  );
};

export default GTMProvider;
