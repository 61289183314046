import React, { useContext, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { PrivateRoute } from "components/routes/PrivateRoute";
import { PublicRoute } from "components/routes/PublicRoute";
import LoginView from "views/LoginView";
import NotFoundView from "views/NotFoundView";
import ProfileView from "views/ProfileView";
import RegisterView from "views/RegisterView";
import StartPageView from "views/StartPageView";
import JobListView from "views/JobListView";
import SavedJobsView from "views/SavedJobsView";
import MessagesView from "views/MessagesView";
import { AgentRoute } from "components/routes/AgentRoute";
import { CandidatesView } from "views/AgentViews/CandidatesView";
import JobMatchesView from "views/JobMatchesView";
import { LoginMailVerifyView } from "views/LoginMailVerifyView";
import { ForgotPasswordView } from "views/ForgotPasswordView";
import { ContactUsView } from "views/ContactUsView";
import { ProfileSettingsView } from "views/ProfileSettingsView";
import JobApplicationViewDT from "views/JobApplicationViewDT";
import { ItConsultantView } from "views/infoViews/ItConsultantView";
import { ConsultingManagementView } from "views/infoViews/ConsultingManagementView";
import { ConsultingRecruitmentView } from "views/infoViews/ConsultingRecruitmentView";
import { FAQView } from "views/infoViews/FAQView";
import { HowItWorksView } from "views/infoViews/HowItWorksView";
import ApplicationTTView from "views/ApplicationTTView";
import { GTMContext } from "context/GTMContext";
import { UserContext } from "context/UserContext";
import { IntegrityView } from "views/infoViews/IntegrityView";
import { CookiePolicyView } from "views/infoViews/CookiePolicyView";
import { UnsubscribeJobsView } from "views/UnsubscribeJobsView";
import StatisticView from "views/StatisticView";
import { DynamicExtraPage } from "views/infoViews/DynamicExtraPage";
import { JobsKanbanView } from "components/KanbanView/JobsKanbanView";
import { JobKanbanView } from "components/KanbanView/JobKanbanView";
import { JobView } from "views/JobView";
import { GroupsView } from "views/GroupsView";

export const Routes = (): JSX.Element => {
  const history = useHistory();
  const { gtmAddToDataLayer } = useContext(GTMContext);
  const { isAuthenticated } = useContext(UserContext);

  useEffect(() => {
    trackPageView();
    return history.listen(trackPageView);
  }, []);

  const trackPageView = () => {
    gtmAddToDataLayer(
      { dl_page: document.title, dl_page_path: window.location.pathname },
      "dl_pageview"
    );
    gtmAddToDataLayer({ logged_in: isAuthenticated ? "true" : "false" }, "user_status");
  };

  return (
    <Switch>
      {/** Public routes */}
      <PublicRoute path="/" exact>
        <StartPageView />
      </PublicRoute>
      <PublicRoute exact path="/jobs">
        <JobListView />
      </PublicRoute>
      <PublicRoute exact path="/jobs/:jobId">
        <JobView />
      </PublicRoute>
      <PublicRoute path="/login" exact={true} redirect>
        <LoginView />
      </PublicRoute>
      <PublicRoute path="/it-consultant" exact={true}>
        <ItConsultantView />
      </PublicRoute>
      <PublicRoute path="/consulting-management" exact={true}>
        <ConsultingManagementView />
      </PublicRoute>
      <PublicRoute path="/consulting-recruitment" exact={true}>
        <ConsultingRecruitmentView />
      </PublicRoute>
      <PublicRoute path="/how-it-works" exact={true}>
        <HowItWorksView />
      </PublicRoute>
      <PublicRoute path="/faq" exact={true}>
        <FAQView />
      </PublicRoute>
      <PublicRoute path="/integrity" exact={true}>
        <IntegrityView />
      </PublicRoute>
      <PublicRoute path="/cookies" exact={true}>
        <CookiePolicyView />
      </PublicRoute>
      <PublicRoute path="/login/:mailInvUuid" exact redirect>
        <LoginMailVerifyView />
      </PublicRoute>
      <PublicRoute path="/forgotPassword" exact={true} redirect>
        <ForgotPasswordView />
      </PublicRoute>
      <PublicRoute path="/unsubscribe/:verificationUuid">
        <UnsubscribeJobsView />
      </PublicRoute>
      <PublicRoute path="/contactUs" exact>
        <ContactUsView />
      </PublicRoute>
      <PublicRoute path="/register" exact={true} redirect>
        <RegisterView />
      </PublicRoute>
      <PublicRoute path="/jobs/apply/:jobId" exact>
        <JobApplicationViewDT />
      </PublicRoute>
      <PublicRoute path="/jobs/external/:jobId" exact>
        <ApplicationTTView />
      </PublicRoute>
      {/** Private routes */}
      <PrivateRoute path="/saved">
        <SavedJobsView />
      </PrivateRoute>
      <PrivateRoute path="/matches">
        <JobMatchesView />
      </PrivateRoute>
      <PrivateRoute path="/profile/:urlID" exact={true}>
        <ProfileView />
      </PrivateRoute>
      <PrivateRoute path="/profile" exact={true}>
        <ProfileView />
      </PrivateRoute>
      <PrivateRoute path="/messages" exact={true} component={MessagesView} />
      <PrivateRoute path="/messages/:conversationId" component={MessagesView} />

      <PrivateRoute path="/settings" exact={true}>
        <ProfileSettingsView />
      </PrivateRoute>
      {/** Agent routes */}
      <PrivateRoute path="/statistics" exact={true}>
        <StatisticView />
      </PrivateRoute>
      <PrivateRoute path="/kanban/jobs" exact={true}>
        <JobsKanbanView />
      </PrivateRoute>
      <PrivateRoute path="/kanban/jobs/:jobId">
        <JobKanbanView />
      </PrivateRoute>
      <AgentRoute path="/candidates" exact={true}>
        <CandidatesView />
      </AgentRoute>
      <AgentRoute path="/groups/:groupId">
        <GroupsView />
      </AgentRoute>
      <AgentRoute path="/groups">
        <GroupsView />
      </AgentRoute>

      <PublicRoute path="/:pageType" exact={true}>
        <DynamicExtraPage />
      </PublicRoute>
      <Route path="*">
        <NotFoundView />
      </Route>
    </Switch>
  );
};
