import React, { FC, useContext } from "react";
import { NavbarIcon } from "./NavbarIcon";
import { LocaleContext } from "context/LocaleContext";
import { PushNotificationContext, PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { WIcon, WIconTypes } from "components";
import { UserContext } from "context/UserContext";

export const AuthenticatedIconList: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { hasNotification } = useContext(PushNotificationContext);
  const { isAgent } = useContext(UserContext);

  return (
    <>
      <NavbarIcon
        route="/"
        label={localize("routes.startPage")}
        icon={<WIcon icon={WIconTypes.home} size="medium" color="secondaryDark" />}
        hasNotification={
          hasNotification(PUSH_NOTIFICATION_TYPES.jobApplication) ||
          hasNotification(PUSH_NOTIFICATION_TYPES.jobMatch)
        }
      />
      {isAgent && (
        <NavbarIcon
          route="/statistics"
          label={localize("routes.statistics")}
          icon={<WIcon icon={WIconTypes.barChart2} size="medium" color="secondaryDark" />}
        />
      )}
      {isAgent && (
        <NavbarIcon
          route="/kanban/jobs"
          label={localize("routes.kanban")}
          icon={<WIcon icon={WIconTypes.kanban} size="medium" color="secondaryDark" />}
        />
      )}
      {isAgent && (
        <NavbarIcon
          route="/candidates"
          label={localize("routes.candidates")}
          icon={<WIcon icon={WIconTypes.candidates} size="medium" color="secondaryDark" />}
        />
      )}
      {isAgent && (
        <NavbarIcon
          route="/groups"
          label={localize("routes.groups")}
          icon={<WIcon icon={WIconTypes.groups} size="medium" color="secondaryDark" />}
        />
      )}
      <NavbarIcon
        route="/jobs"
        label={localize("routes.jobs")}
        icon={<WIcon icon={WIconTypes.search} size="medium" color="secondaryDark" />}
      />
      <NavbarIcon
        route="/messages"
        label={localize("routes.messages")}
        icon={<WIcon icon={WIconTypes.message} size="medium" color="secondaryDark" />}
        hasNotification={hasNotification(PUSH_NOTIFICATION_TYPES.conversation)}
      />
      <NavbarIcon
        route="/profile"
        label={localize("routes.profile")}
        icon={<WIcon icon={WIconTypes.person} size="medium" color="secondaryDark" />}
      />
    </>
  );
};
