import React, { FC, useContext, useEffect, useState } from "react";
import { Grid, styled } from "@mui/material";
import { WIconTypes } from "components";
import { colors } from "styles/colors";
import { getAgentNotes } from "adapters";
import { CardLabel } from "./CardLabel";
import { UserRatingLabel } from "./UserRatingLabel";
import { LocaleContext } from "context/LocaleContext";
import { User } from "reducers/UserReducer";

interface UserCardLabelsProps {
  wiseUser: User;
}

export const UserCardLabels: FC<UserCardLabelsProps> = ({ wiseUser }) => {
  const { localize } = useContext(LocaleContext);

  const { cv, id, referrals } = wiseUser;

  const [notesCount, setNotesCount] = useState(0);

  const getAgentNote = (): void => {
    getAgentNotes({ user: id, page_size: 0 }).then((res) => {
      setNotesCount(res.data.count);
    });
  };

  useEffect(() => {
    getAgentNote();
  }, []);
  return (
    <WCardLabelWrapper container gap=".5rem">
      <Grid item bgcolor={colors.siteBackground}>
        <UserRatingLabel rating={wiseUser.rating} shadow />
      </Grid>
      {!!cv.length && (
        <CardLabel
          text={cv.length.toString()}
          icon={WIconTypes.fileText}
          label={localize("common.cv")}
          color={{ background: colors.siteBackground, text: "#0B4F6B" }}
        />
      )}
      {!!referrals.length && (
        <CardLabel
          text={referrals.length.toString()}
          icon={WIconTypes.person}
          label={localize("common.referrals")}
          color={{ background: colors.siteBackground, text: "#207193" }}
        />
      )}
      {notesCount > 0 && (
        <CardLabel
          text={notesCount.toString()}
          icon={WIconTypes.clipBoard}
          label={localize("profileView.editNoteButton")}
          color={{ background: colors.siteBackground, text: "#3696BE" }}
        />
      )}
    </WCardLabelWrapper>
  );
};

const WCardLabelWrapper = styled(Grid)`
  position: absolute;
  align-items: center;
  top: -0.4rem;
`;
