import { Grid } from "@mui/material";
import { BreakpointsContext } from "context/BreakpointContext";
import React, { FC, useContext } from "react";
import { defaultStyles } from "styles/colors";
import { WTypography } from "./WTypography";

interface WImageBannerProps {
  title: string;
  subTitle?: string;
  tip?: string;
  textContent: string;
  imageSrc: string;
  fullGradient?: boolean;
  size?: "xs" | "lg";
}

export const WImageBanner: FC<WImageBannerProps> = ({
  size,
  title,
  subTitle,
  tip,
  textContent,
  imageSrc,
  fullGradient = true,
}) => {
  const { isDesktop } = useContext(BreakpointsContext);

  const containerSizes = {
    xs: {
      height: "280px",
    },
  };

  const ArticleContent = () => {
    return (
      <Grid
        container
        item
        xs={12}
        md={size === "xs" ? 12 : 8}
        lg={size === "xs" ? 12 : 6}
        position="absolute"
        gap=".5rem"
        padding="1rem"
      >
        <Grid container direction="column" wrap="nowrap" width="100%">
          {tip && (
            <WTypography width="100%" lineClamps={1} variant="body1" color="secondary">
              {tip}
            </WTypography>
          )}
          <WTypography width="100%" lineClamps={1} variant="h6" color="secondary">
            {title}
          </WTypography>
        </Grid>
        {subTitle && (
          <WTypography width="100%" lineClamps={1} variant="body1" color="secondary">
            {subTitle}
          </WTypography>
        )}

        <WTypography
          lineClamps={5}
          variant="body2"
          color="secondary"
          sx={{
            whiteSpace: "pre-wrap",
          }}
        >
          {textContent}
        </WTypography>
      </Grid>
    );
  };

  return (
    <Grid
      container
      maxHeight={size ? containerSizes[size].height : isDesktop ? "180px" : "200px"}
      position="relative"
      sx={{
        "::before": {
          background: `linear-gradient(90deg, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) ${
            fullGradient ? "100%" : "75%"
          })`,
          content: "''",
          position: "absolute",
          height: "100%",
          borderRadius: defaultStyles.borderRadius,
          width: "100%",
        },
      }}
    >
      <ArticleContent />
      <img
        src={imageSrc}
        alt="News image"
        width="100%"
        height={size ? containerSizes[size].height : "100%"}
        style={{
          borderRadius: defaultStyles.borderRadius,
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
    </Grid>
  );
};
