import { Dialog, DialogContent, Grid, Link, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { UserContext } from "context/UserContext";
import { BreakpointsContext } from "context/BreakpointContext";
import { WDialogButton } from "components/styledComponents/WButton";
import { COOKIE_LINK } from "views/Enums";

export const COOKIE_DAYS_TO_LIVE = 1000 * 60 * 60 * 24 * 365; //365 days to live

export const setWithExpiry = (key: string, value: string, daysToLive: number): void => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + daysToLive,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string): string | null => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const IGNORED_PATHS = ["/integrity", "/faq", "/contactUs", "/cookies"];

export const CookieConsent: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const [showModal, setShowModal] = useState(true);
  const { isAuthenticated } = useContext(UserContext);

  useEffect(() => {
    const isAnalyticsCookieSet = getWithExpiry("allowAnalytics");
    if (isAnalyticsCookieSet) {
      setShowModal(false);
    }
  }, [window.location.pathname]);

  const handleAccept = () => {
    setWithExpiry("allowAnalytics", "true", COOKIE_DAYS_TO_LIVE);
    setShowModal(false);
  };

  const handleDecline = () => {
    setWithExpiry("allowAnalytics", "false", COOKIE_DAYS_TO_LIVE);
    setShowModal(false);
  };

  if (IGNORED_PATHS.includes(window.location.pathname)) {
    return null;
  }

  return (
    <Dialog open={showModal}>
      <DialogContent>
        <Grid container direction="column" alignItems="center" gap="1rem" marginTop="1rem">
          <Typography variant="h6" fontWeight="bold" textAlign="center">
            {localize("cookie.title")}
          </Typography>
          <Typography variant="body1" textAlign="center">
            {localize("cookie.description1")}
          </Typography>
          <Typography variant="body2" textAlign="center">
            {localize("cookie.description2")}
          </Typography>
          <Link href={COOKIE_LINK} target="_blank">
            {localize("common.cookieText")}
          </Link>
        </Grid>
      </DialogContent>
      <Grid
        container
        padding={isDesktop ? "1rem 4rem" : "1rem 1rem"}
        justifyContent="space-between"
        alignItems="center"
      >
        <WDialogButton
          data-test="cookiesAccepted"
          data-loggedin={!!isAuthenticated}
          variant="contained"
          onClick={() => handleAccept()}
        >
          {localize("cookie.acceptButton")}
        </WDialogButton>
        <WDialogButton
          data-test="cookiesRejected"
          data-loggedin={!!isAuthenticated}
          variant="outlined"
          onClick={() => handleDecline()}
        >
          {localize("cookie.declineButton")}
        </WDialogButton>
      </Grid>
    </Dialog>
  );
};
