import React, { FC, useContext, useState } from "react";
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { colors, defaultStyles } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";
import { UserList } from "views/AgentViews/UserList";
import ConversationList from "components/ConversationList";
import { useHistory } from "react-router";
import { WButton, WIcon, WIconTypes } from "components";
import { LoaderContext } from "context/LoaderContext";
import { AgentStartPageHeader } from "components/headers/AgentStartPageHeader";
import { TaskDrawerWidth, TasksDrawer } from "components/KanbanView/TasksDrawer";
import { dynamicHeaderHeight } from "components/DynamicHeader";

export const AgentStartPage: FC = () => {
  const { localize } = useContext(LocaleContext);
  const history = useHistory();
  const { isLoading } = useContext(LoaderContext);

  const [showRecentApplications, setShowRecentApplications] = useState(false);
  const [isTasksOpen, setIsTasksOpen] = useState(false);

  return (
    <>
      <Grid
        container
        height="100vh"
        overflow="hidden"
        justifyContent="space-around"
        width={`calc(100% - ${isTasksOpen ? TaskDrawerWidth : 0}) !important`}
        sx={{ transition: "width 0.2s linear" }}
      >
        <AgentStartPageHeader />

        <Grid
          container
          width="100%"
          height={`calc(100vh - ${dynamicHeaderHeight}px)`}
          gap="20px"
          padding="20px"
          paddingTop="50px"
        >
          <Grid
            item
            container
            flex="400px 10 0"
            height="100%"
            direction="column"
            padding="10px"
            sx={{
              backgroundColor: colors.white,
              borderRadius: defaultStyles.borderRadius,
              boxShadow: defaultStyles.cardShadow,
            }}
          >
            <Grid container justifyContent="space-between" padding="5px">
              <Typography color="primary" variant="h5" fontWeight="bold">
                {localize("agentStartPage.candidates")}
              </Typography>
              <WButton
                variant="outlined"
                startIcon={<WIcon icon={WIconTypes.search} />}
                sx={{ minWidth: "max-content" }}
                onClick={() => history.push("/candidates")}
              >
                {localize("agentStartPage.candidateListTitle")}
              </WButton>
              <FormControlLabel
                control={
                  <Switch
                    style={{ pointerEvents: "auto" }}
                    checked={showRecentApplications}
                    onChange={(e) => setShowRecentApplications(e.target.checked)}
                  />
                }
                style={{ pointerEvents: "none" }}
                label={localize("agentStartPage.showRecentApplications")}
                disabled={isLoading("GET_USER_LIST")}
                sx={{ width: "100%" }}
              />
            </Grid>

            <UserList isSortByLatestApplication={showRecentApplications} />
          </Grid>

          <Grid
            item
            container
            flex="400px 10 0"
            height="100%"
            direction="column"
            padding="10px"
            sx={{
              backgroundColor: colors.white,
              borderRadius: defaultStyles.borderRadius,
              boxShadow: defaultStyles.cardShadow,
            }}
          >
            <Grid container justifyContent="space-between" padding="5px">
              <Typography color="primary" variant="h5" fontWeight="bold">
                {localize("agentStartPage.messageListSubtitle")}
              </Typography>
              <WButton
                variant="outlined"
                startIcon={<WIcon icon={WIconTypes.message} />}
                onClick={() => history.push("/messages")}
              >
                {localize("agentStartPage.messageListTitle")}
              </WButton>
            </Grid>

            <ConversationList fullscreen={false} />
          </Grid>
        </Grid>
      </Grid>
      <TasksDrawer
        isOpen={isTasksOpen}
        setOpen={setIsTasksOpen}
        openDrawerButtonY={`${dynamicHeaderHeight + 5}px`}
      />
    </>
  );
};
