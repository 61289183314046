import { getJobMatchesList, JobMatch } from "adapters";
import { MatchedTabContent } from "components/MatchedTabContent";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { PushNotificationContext, PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { usePagination, DEFAULT_PAGINATION_SIZE } from "hooks/usePagination";
import React, { FC, useContext, useEffect } from "react";

export const JobMatchesTab: FC = () => {
  const { isLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { updateNotification } = useContext(PushNotificationContext);

  // Detect if tab is mounted, to avoid duplicate request intially

  const { results, setLastElementRef } = usePagination<JobMatch>(
    getJobMatchesList,
    "error.fetchJobList",
    "GET_JOB_MATCHES",
    { page_size: DEFAULT_PAGINATION_SIZE }
  );

  useEffect(() => {
    updateNotification(PUSH_NOTIFICATION_TYPES.jobMatch);
  }, []);

  return (
    <MatchedTabContent
      lastElementRef={setLastElementRef}
      jobList={results.map((res) => res.job)}
      emptyListText={localize("common.emptyJobMatches")}
      opt={{ listIsLoading: isLoading("GET_JOB_MATCHES") }}
    />
  );
};
