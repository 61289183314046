import { Grid, Tooltip, Typography } from "@mui/material";
import { Job } from "adapters";
import { FiltersBody, WButton, WSkeleton, WIcon, WIconTypes } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NewsContext } from "context/NewsContext";
import { UserContext } from "context/UserContext";
import React, { FC, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { JobFilters } from "./dialogs/FiltersDialog";
import { JobCard } from "./JobCard";
import { WImageBanner } from "./WImageBanner";
import { NotificationContext } from "context/NotificationContext";
import { copyToClipboard, toggleArrayItem } from "utils/generalUtils";

interface JobListProps {
  jobList: Job[];
  lastElementRef: (el: Element | null) => void;
  filters: JobFilters;
  setFilters: (filters: JobFilters) => void;
  listIsLoading?: boolean;
  emptyListText?: string;
  showImageBanner?: boolean;
}

export const JobList: FC<JobListProps> = ({
  jobList,
  lastElementRef,
  listIsLoading,
  emptyListText,
  filters,
  setFilters,
  showImageBanner = false,
}) => {
  const { isLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { latestNews } = useContext(NewsContext);
  const { isAuthenticated } = useContext(UserContext);
  const { addMessage } = useContext(NotificationContext);

  const [showFilters, setShowFilters] = useState(false);
  const [multiselectActive, setMultiselectActive] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);

  const onToggleSelect = useMemo((): ((jobId: string) => void) | undefined => {
    if (!multiselectActive) {
      return undefined;
    }

    return (jobId: string) => {
      setSelectedJobs(toggleArrayItem(selectedJobs, jobId));
    };
  }, [multiselectActive, setSelectedJobs, selectedJobs]);

  const onShare = useCallback(() => {
    let str = "";
    selectedJobs.forEach((id, index) => {
      if (index !== 0) {
        str += ",";
      }
      str += id;
    });
    copyToClipboard("jobs", str);

    addMessage({
      type: "success",
      content: "jobListView.copiedLinkToSelectedJobs",
      duration: 3000,
    });
  }, [addMessage, selectedJobs]);

  let content: ReactNode = null;
  if (listIsLoading || isLoading("GET_JOB_LIST")) {
    content = (
      <>
        <WSkeleton width="100%" height="100px" variant="rectangular" />
        <WSkeleton width="100%" height="100px" variant="rectangular" />
        <WSkeleton width="100%" height="100px" variant="rectangular" />
      </>
    );
  } else if (!jobList.length) {
    content = (
      <Grid container direction="column" alignItems="center" padding="2rem">
        <WIcon icon={WIconTypes.frown} />
        <Typography align="center">{emptyListText || localize("jobListView.noResults")}</Typography>
      </Grid>
    );
  } else {
    content = jobList.map((job, index) => {
      const isLastElement = jobList.length === index + 1;
      return isLastElement ? (
        <div ref={lastElementRef} key={job.id} style={{ width: "100%" }}>
          <JobCard
            key={job.id}
            {...job}
            selected={selectedJobs.includes(job.id)}
            onToggleSelect={onToggleSelect}
          />
        </div>
      ) : (
        <JobCard
          key={job.id}
          {...job}
          selected={selectedJobs.includes(job.id)}
          onToggleSelect={onToggleSelect}
        />
      );
    });
  }

  return (
    <Grid container gap="1rem">
      <Grid container justifyContent="flex-end" gap="5px">
        {selectedJobs.length > 0 && (
          <Tooltip title={localize("common.copyToClipboard")} placement="top">
            <WButton
              variant="outlined"
              startIcon={<WIcon icon={WIconTypes.clipBoard} />}
              onClick={onShare}
            >
              {localize("common.shareJobNoEmoji")}
            </WButton>
          </Tooltip>
        )}
        <WButton
          variant="outlined"
          onClick={() => {
            if (multiselectActive) {
              setSelectedJobs([]);
            }
            setMultiselectActive(!multiselectActive);
          }}
        >
          {localize(
            multiselectActive ? "common.deactivateMultiselect" : "common.activateMultiselect"
          )}
        </WButton>

        {!showFilters && isDesktop && (
          <WButton
            variant={
              Object.values(filters).some((filter) => !!filter.length) ? "contained" : "outlined"
            }
            startIcon={<WIcon rotationDegrees="90" icon={WIconTypes.sliders} />}
            onClick={() => setShowFilters(true)}
          >
            {localize("searchbar.filterButton")}
          </WButton>
        )}
      </Grid>
      <Grid container gap="2rem" wrap="nowrap">
        <Grid container item xs={12} md={showFilters ? 8 : 12} gap="1rem" height="fit-content">
          {showImageBanner && latestNews && isAuthenticated && (
            <WImageBanner
              title={latestNews.title}
              textContent={latestNews.body}
              imageSrc={latestNews.news_photo}
              subTitle={latestNews.sub_title}
            />
          )}
          {content}
        </Grid>
        {isDesktop && showFilters && (
          <Grid item md={4} xl={3}>
            <FiltersBody
              filters={filters}
              setFilters={setFilters}
              setShowFilters={setShowFilters}
              showBanner
              latestNews={latestNews}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
