import { Dialog } from "@mui/material";
import React, { FC, useContext } from "react";
import { ModalContext } from "context/ModalContext";
import { LocaleContext } from "context/LocaleContext";
import { ActiveJobApplication } from "adapters/JobAdapter";
import { Job } from "adapters";
import { DialogHeader } from "components";
import { ActiveApplicationContent } from "components/ActiveApplicationContent";
import { BreakpointsContext } from "context/BreakpointContext";

import { DetailedJobApplicationContentDT } from "./DetailedJobApplicationContentDT";

export interface DetailedJobApplicationProps {
  job: Job;
  onClose?: () => void;
  handleTypeChange?: (save: boolean) => void;
  onRemoveApplication: (id: string) => void;
  applicationId: string;
  isCancelled?: boolean;
  setJobApplicationData?: (data: ActiveJobApplication) => void;
}

export const DetailedJobApplicationModal: FC<DetailedJobApplicationProps> = ({
  job,
  onClose,
  handleTypeChange,
  onRemoveApplication,
  setJobApplicationData,
  applicationId,
  isCancelled = false,
}) => {
  const { hideModal } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const onHideModal = () => {
    onClose ? onClose() : hideModal();
  };

  if (isDesktop) {
    return (
      <Dialog aria-labelledby="job-details" open={true} fullScreen>
        <DialogHeader
          titleVariant="h6"
          handleClose={onHideModal}
          returnText={localize("common.goBack")}
          lineClampTitle={1}
        />
        <DetailedJobApplicationContentDT jobId={job.id} showTitle />
      </Dialog>
    );
  }

  return (
    <Dialog aria-labelledby="job-details" open={true} fullScreen>
      <DialogHeader
        title={job.title}
        titleVariant="h6"
        handleClose={onHideModal}
        returnText={localize("common.goBack")}
        lineClampTitle={1}
      />
      <ActiveApplicationContent
        job={job}
        isCancelled={isCancelled}
        handleTypeChange={handleTypeChange}
        onRemoveApplication={onRemoveApplication}
        applicationId={applicationId}
        setJobApplicationData={setJobApplicationData}
      />
    </Dialog>
  );
};
