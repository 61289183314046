export const colors = Object.freeze({
  main: "#70AEAD",
  mainWithOpacity: "#BDCBCD33",
  mainDark: "#002D40",
  mainLight: "#aacfcf",
  accent: "#d49857",
  success: "#00976E",
  siteBackground: "#FBFBFB",
  primaryGreen: "#1C5240",
  white: "#FFF",
  lightGrey: "#F0F0F0",
  grey: "#ECECEC",
  darkGrey: "#6D6D6D",
  searchBarBorder: "#C0C0C0",
  mainDarkRed: "#CC0043",
  // Green for search button and change profile button border lighter than success.
  buttonBackground: "#20C198",
  buttonSelected: "#b2c2c5",
  textDark: "#222222",
  textLight: "#FFF",
  lightBorder: "#DCE3E4",
  textDarkLighter: "#767676",
  cancelText: "#59BCE3",
  draftColor: "#B07400",
  star: "#E7DC7D",
  mainSVGColor: "#6C63FF",
  error: "#CC0043",
  errorLight: "#FAE6ED",
  loginBackground: "#068895",
  loginLogo: "#09B7C9",
});

export const AvatarColors = ["#F94144", "#F3722C", "#F8961E", "#F9C74F", "#90BE6D", "#43AA8B"];

export const defaultStyles = Object.freeze({
  // Shadow 4 is theme.shadows[4] from MUI standard
  shadow: 4,
  cardShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
  bigCardShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
  dropDownShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  borderRadiusTop: "10px 10px 0 0",
  borderRadiusBottom: " 0 0 10px 10px",
  borderRadiusLarge: "1rem",
  borderRadiusFullyRounded: "60rem",
  borderRadiusSearchBar: "10px",
  borderLeftMainDark: "1.4px solid rgba(109,109,109,0.3)",
});
