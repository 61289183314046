import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export interface Task {
  id: string;
  title: string;
  description?: string;
  due_date: string | null;
  done_date: string | null;
  status: "not_started" | "done";
  agent: TaskUser;
  users: TaskUser[];
  jobs: TaskJob[];
  job_applications: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    user: TaskUser;
    job: TaskJob;
  }[];
  order_no: number;
}
export interface TaskUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}
export interface TaskJob {
  id: string;
  title: string;
}

export interface SentTaskData {
  id: string;
  title: string;
  description: string;
  due_date: string | null;
  status: "not_started" | "done";
  agent: string;
  users: string[];
  jobs: string[];
  job_applications: string[];
  order_no: number;
}

export type TaskActions =
  | "GET_TASK_LIST"
  | "GET_TASK"
  | "CREATE_TASK"
  | "UPDATE_TASK"
  | "DELETE_TASK";

export const getTaskList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<Task>>> => {
  return API.get("/tasks/", { params });
};

export const getTask = async (taskId: string): Promise<AxiosResponse<Task>> => {
  return API.get(`/tasks/${taskId}/`);
};

export const createTask = async (data: Partial<SentTaskData>): Promise<AxiosResponse<Task>> => {
  return API.post(`/tasks/`, data);
};

export const updateTask = async (
  taskId: string,
  data: Partial<SentTaskData>
): Promise<AxiosResponse<Task>> => {
  return API.patch(`/tasks/${taskId}/`, data);
};

export const deleteTask = async (taskId: string): Promise<AxiosResponse<void>> => {
  return API.delete(`/tasks/${taskId}/`);
};
