import { Grid, Skeleton, Typography } from "@mui/material";
import { JobApplication } from "adapters";
import { ProfileJobApplicationCard } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, SyntheticEvent, useContext } from "react";
import { defaultStyles } from "styles/colors";

interface ApplicationsTabContentProps {
  selectedApplicationCallback?: (applicationNr: number) => void;
  paginationOpts: {
    updatePage: () => void;
    results: JobApplication[];
    setLastJobApplicationRef: (node: Element | null) => void;
  };
}

export const ApplicationsTabContent: FC<ApplicationsTabContentProps> = ({
  selectedApplicationCallback,
  paginationOpts,
}) => {
  const { isLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const handleCardPressed = (event: SyntheticEvent, index: number) => {
    event.stopPropagation();

    if (selectedApplicationCallback) {
      selectedApplicationCallback(index);
    }
  };
  const { updatePage, results, setLastJobApplicationRef } = paginationOpts;
  return (
    <Grid container gap={isDesktop ? "1rem" : "2px"}>
      {isLoading("GET_JOB_APPLICATION_LIST") ? (
        Array(3)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              height={isDesktop ? "150px" : "100px"}
              variant="rectangular"
              width="100%"
              key={`skeleton_${index}`}
              sx={{
                borderRadius: defaultStyles.borderRadius,
              }}
            />
          ))
      ) : results.length ? (
        results.map((appl, index) => {
          if (index === results.length - 1) {
            return (
              <Grid
                container
                item
                key={`jobApplication_${appl.id}_${index}`}
                ref={setLastJobApplicationRef}
                sx={{
                  cursor: appl.job.job_status !== "archived" ? "pointer" : "default",
                }}
                onClick={(e) => {
                  if (appl.job.job_status !== "archived") {
                    handleCardPressed(e, index);
                  }
                }}
              >
                <ProfileJobApplicationCard
                  isCancelled={appl.is_canceled}
                  isDraft={appl.is_draft}
                  jobApplication={appl}
                  job={appl.job}
                  onRemove={updatePage}
                />
              </Grid>
            );
          }

          return (
            <Grid
              container
              item
              sx={{ cursor: appl.job.job_status !== "archived" ? "pointer" : "default" }}
              key={`jobApplication_${appl.id}_${index}`}
              onClick={(e) => {
                if (appl.job.job_status !== "archived") {
                  handleCardPressed(e, index);
                }
              }}
            >
              <ProfileJobApplicationCard
                isCancelled={appl.is_canceled}
                isDraft={appl.is_draft}
                jobApplication={appl}
                job={appl.job}
                onRemove={updatePage}
              />
            </Grid>
          );
        })
      ) : (
        <Grid container justifyContent="center">
          <Typography padding="1rem" variant="body2" fontWeight="bold" textAlign="center">
            {localize("common.emptyJobApplicationList")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
