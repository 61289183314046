import { Box, Grid, Typography } from "@mui/material";
import { ExtendedConversation } from "adapters";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { prettifyDate } from "utils/PrettifyDate";
import { AvatarItem, AvatarItemSizes } from "./AvatarItem";
import { colors, defaultStyles } from "styles/colors";
import { WIcon, WIconTypes, WTypography } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { LoaderContext } from "context/LoaderContext";
interface ConversationCardProps {
  id: string;
  conv: ExtendedConversation;
  isSelected?: boolean;
  fullscreen?: boolean;
  avatarSize?: AvatarItemSizes;
  setSelectedConv?: (id: string) => void;
}

type AvatarDetails = {
  firstName: string;
  lastName: string;
  image: string;
};

export const ConversationCard: FC<ConversationCardProps> = ({
  id,
  fullscreen,
  conv,
  avatarSize,
  setSelectedConv,
  isSelected = false,
}) => {
  const { localize } = useContext(LocaleContext);
  const { showModal, hideModal } = useContext(ModalContext);
  const { isLoading } = useContext(LoaderContext);
  const { user, isAgent } = useContext(UserContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const [AvatarDetails, setAvatarDetails] = useState<AvatarDetails>(Object);
  const [isRead, setIsRead] = useState(conv.is_read);

  useEffect(() => {
    if (conv.users) {
      if (conv.users.length > 1) {
        // If theres more than 1 users, select the one that is not currentUser.  Wise initiated messages
        for (const convUser of conv.users) {
          if (convUser.id != user.id) {
            setAvatarDetails({
              firstName: convUser.first_name,
              lastName: convUser.last_name,
              image: convUser.profile_picture
                ? convUser.profile_picture
                : `${process.env.PUBLIC_URL}/assets/logo/icon_64.png`,
            });
            break;
          }
        }
      } else if (conv.users.length === 1) {
        if (isAgent) {
          // If theres  1 user, this is a message from the user to the agent.
          setAvatarDetails({
            firstName: conv.users[0].first_name,
            lastName: conv.users[0].last_name,
            image: conv.users[0].profile_picture,
          });
        } else {
          const names: string[] = conv.name.split(" ");
          setAvatarDetails({
            firstName: names[0] || "Wise",
            lastName: names[1] || "Agent",
            image: `${process.env.PUBLIC_URL}/assets/logo/icon_64.png`,
          });
        }
      }
    }
  }, []);

  const clickOnCard = () => {
    if (isLoading("SOCKET_CONNECTING")) {
      return;
    }
    setIsRead(true);

    if (isDesktop && !isAgent) {
      if (setSelectedConv) {
        setSelectedConv(id);
      }

      return;
    }

    showModal("MESSAGE_MODAL", {
      conversationId: id,
      fullscreen: fullscreen,
      onClose: hideModal,
    });
  };

  return (
    <Grid
      container
      key={id}
      wrap="nowrap"
      gap="1rem"
      alignItems="center"
      padding="1rem"
      bgcolor={isDesktop ? (isSelected ? colors.white : colors.siteBackground) : colors.white}
      sx={{
        "&:hover": { backgroundColor: colors.grey },
      }}
      onClick={clickOnCard}
      borderRadius={isDesktop ? defaultStyles.borderRadius : 0}
      boxShadow={isDesktop ? defaultStyles.cardShadow : ""}
    >
      <AvatarItem
        firstName={AvatarDetails.firstName}
        lastName={AvatarDetails.lastName}
        imageSrc={AvatarDetails.image}
        direction="column"
        size={avatarSize}
      />

      <Grid container direction="column">
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <WTypography lineClamps={1} variant="body1" fontWeight="bold" color="primary">
            {!isAgent ? conv.name : `${AvatarDetails.firstName} ${AvatarDetails.lastName}`}
          </WTypography>
          <Box>
            {!isRead && (
              <Box
                display="inline-block"
                width="13px"
                height="13px"
                sx={{ borderRadius: "50%" }}
                bgcolor="error.main"
              ></Box>
            )}
            <Typography
              marginLeft=".5rem"
              display="inline-block"
              paddingRight=".5rem"
              fontSize=".6rem"
              noWrap
              textOverflow="inherit"
              color="primary"
            >
              {conv.latest_message.sent
                ? prettifyDate(conv.latest_message.sent, { showRelativeTime: true })
                : ""}
            </Typography>
          </Box>
        </Grid>
        <Grid container justifyContent="space-between" gap="1rem" wrap="nowrap">
          {conv.latest_message.message ? (
            <WTypography lineClamps={2} fontSize="1rem" lineHeight="1.2rem" color={colors.mainDark}>
              {conv.latest_message.message}
            </WTypography>
          ) : (
            <WTypography lineClamps={2} variant="body2" fontWeight="light" fontStyle="italic">
              {localize("messagesView.firstMessage")}
            </WTypography>
          )}
          {!isDesktop && (
            <Box textAlign="right">
              <WIcon icon={WIconTypes.arrowRight} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
