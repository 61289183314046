import { Dialog, Grid, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { Referral } from "adapters";
import { WIconButton } from "components/styledComponents/WIconButton";
import { WIcon, WIconTypes } from "components/WIcon";

interface ReferralInfoDialogProps {
  handleClose: () => void;
  referral: Referral;
}

export const ReferralInfoDialog: FC<ReferralInfoDialogProps> = ({ handleClose, referral }) => {
  const { localize } = useContext(LocaleContext);

  const { first_name, last_name, email, comment, phone } = referral;

  return (
    <Dialog maxWidth="sm" open={true} onClose={handleClose}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap=".1rem"
        padding="1rem"
        position="relative"
      >
        <WIconButton
          sx={{ position: "absolute", top: "1rem", right: "1rem" }}
          onClick={handleClose}
        >
          <WIcon icon={WIconTypes.clear} size="medium" />
        </WIconButton>
        <Typography color="primary" variant="h6">
          {localize("common.referral")}
        </Typography>
        <Grid container gap=".2rem" mb="20px">
          <ReferralField
            title={localize("candidateModal.name")}
            content={`${first_name} ${last_name}`}
          />
          <ReferralField title={localize("candidateModal.email")} content={email} />
          <ReferralField title={localize("candidateModal.phone")} content={phone} />
          <ReferralField title={localize("candidateModal.comment")} content={comment} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

interface ReferralFieldProps {
  title: string;
  content?: string;
}

const ReferralField: FC<ReferralFieldProps> = ({ title, content }) => {
  if (!content) {
    return null;
  }
  return (
    <Grid container direction="column">
      <Typography color="primary" variant="body1" fontWeight="bold">
        {title}
      </Typography>
      <Typography color="primary" variant="body1">
        {content}
      </Typography>
    </Grid>
  );
};
