import React, { FC, useContext, useEffect, useState } from "react";
import { Referral } from "adapters";
import { WButton, WIcon, WIconTypes, WTypography } from "components";
import { LocaleContext } from "context/LocaleContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { ModalContext } from "context/ModalContext";

interface JobApplicationReferralAccordionProps {
  referralList: Referral[];
  handleSelectReferral: (referrals: Referral[]) => void;
  handleCreateReferral: (data: Referral) => void;
  preSelectedReferrals: Referral[] | null | undefined;
}

export const JobApplicationReferralAccordion: FC<JobApplicationReferralAccordionProps> = ({
  referralList,
  handleSelectReferral,
  handleCreateReferral,
  preSelectedReferrals,
}) => {
  const { localize } = useContext(LocaleContext);
  const { showDialog, hideDialog } = useContext(ModalContext);

  const [accordionIsExpanded, setAccordionIsExpanded] = useState(false);
  const [selectedReferrals, setSelectedReferrals] = useState<Referral[]>([]);

  useEffect(() => {
    if (preSelectedReferrals) {
      setSelectedReferrals(preSelectedReferrals);
    }
  }, [preSelectedReferrals]);

  const handleExpandAccordion = (state: boolean) => {
    setAccordionIsExpanded(state);
  };

  const handleSelectedItem = (referral: Referral) => {
    if (selectedReferrals.some((selectedReferral) => referral.id === selectedReferral.id)) {
      const filteredReferrals = selectedReferrals.filter(
        (selectedReferral) => referral.id !== selectedReferral.id
      );
      setSelectedReferrals(filteredReferrals);
      handleSelectReferral(filteredReferrals);
    } else {
      setSelectedReferrals([...selectedReferrals, referral]);
      handleSelectReferral([...selectedReferrals, referral]);
    }
  };

  const handleRemoveSelectedReferrals = (referral: Referral) => {
    const filteredReferrals = selectedReferrals.filter(
      (selectedReferral) => referral.id !== selectedReferral.id
    );
    setSelectedReferrals(filteredReferrals);
    handleSelectReferral(filteredReferrals);
  };

  const handleReferralSubmit = (referral: Referral) => {
    setSelectedReferrals([...selectedReferrals, referral]);
    handleCreateReferral(referral);
  };

  return (
    <>
      {referralList.length > 0 ? (
        <Grid container>
          <Accordion
            disableGutters
            expanded={accordionIsExpanded}
            sx={{ boxShadow: "none", minWidth: "100%" }}
          >
            <AccordionSummary
              onClick={() => handleExpandAccordion(!accordionIsExpanded)}
              expandIcon={<WIcon icon={WIconTypes.arrowDown} />}
            >
              <Grid container direction="column" wrap="nowrap">
                <WTypography lineClamps={1} fontWeight="bold" color="primary">
                  {localize("form.referralField")}
                </WTypography>
                <Typography variant="body2" sx={{ opacity: "0.6" }}>
                  {localize("form.addExistingReferral")}
                </Typography>
              </Grid>
            </AccordionSummary>
            {referralList.map((referral) => {
              return (
                <AccordionDetails key={referral.id}>
                  <Grid container wrap="nowrap" gap="1rem">
                    <WButton
                      onClick={() => handleSelectedItem(referral)}
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0",
                        width: "100%",
                      }}
                    >
                      <Grid container wrap="nowrap" gap="1rem" alignItems="center">
                        <WIcon icon={WIconTypes.person} />
                        <Grid container alignItems="center" wrap="nowrap" gap=".2rem">
                          <Typography>{referral.first_name}</Typography>
                          <Typography>{referral.last_name}</Typography>
                        </Grid>
                      </Grid>

                      <Checkbox
                        edge="end"
                        checked={selectedReferrals.some(
                          (selectedItem) => referral.id === selectedItem.id
                        )}
                        sx={{
                          color: "text.primary",
                        }}
                      />
                    </WButton>
                  </Grid>
                </AccordionDetails>
              );
            })}
          </Accordion>
        </Grid>
      ) : (
        <Grid container>
          <Typography fontWeight="bold" color="primary">
            {localize("form.referralField")}
          </Typography>
        </Grid>
      )}
      <Grid container direction="column" wrap="nowrap" alignItems="center" margin="1rem 0">
        <WButton
          fullWidth
          variant="outlined"
          startIcon={<WIcon icon={WIconTypes.addUser} />}
          onClick={() =>
            showDialog("REFERRAL_DIALOG_ADD", {
              handleClose: () => hideDialog(),
              handleSuccessUpdate: handleReferralSubmit,
              disableBackendRequest: true,
            })
          }
        >
          {localize("profileView.addReferral")}
        </WButton>
      </Grid>
      {selectedReferrals && (
        <Grid container direction="column" wrap="nowrap" gap="2rem">
          {selectedReferrals.map((referral) => {
            return (
              <Grid
                key={`referall_${referral.id}`}
                container
                wrap="nowrap"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid container wrap="nowrap" gap="1rem" alignItems="center">
                  <WIcon icon={WIconTypes.person} />
                  <Grid container alignItems="center" wrap="nowrap" gap=".2rem">
                    <Typography>{referral.first_name}</Typography>
                    <Typography>{referral.last_name}</Typography>
                  </Grid>
                </Grid>
                <WButton onClick={() => handleRemoveSelectedReferrals(referral)}>
                  <WIcon icon={WIconTypes.clear} />
                </WButton>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};
