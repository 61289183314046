import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { FC, useContext, useRef, useState } from "react";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { DialogHeader, WButton, WIcon, WIconTypes } from "components";
import { CV, addUserCV, removeUserCV } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { PROFILE_STEPS } from "views/Enums";
import { UserContext } from "context/UserContext";
import { CvCard } from "./CvCard";

export interface CVDetails extends CV {
  extension?: string;
}
export const ProfileCvsModal: FC = () => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { hideModal, showDialog, hideDialog } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { user, dispatch, calculateProfileProgress } = useContext(UserContext);
  const [expanded, setExpanded] = useState(-1);
  const uploadFile = useRef<HTMLInputElement | null>(null);
  const maxFilenameLength = 50;

  const handleAddCV = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length) {
      let alias = event.target.files[0].name;
      // remove extension.
      alias = alias.replace(/\.[^/.]+$/, "");
      if (alias.length > maxFilenameLength) {
        alias = alias.substring(0, maxFilenameLength - 1);
      }
      dispatchLoading({ type: "SET_LOADING", payload: "ADD_CV" });
      addUserCV(event.target.files[0], alias)
        .then((res) => {
          calculateProfileProgress(true, PROFILE_STEPS.cv);
          addMessage({ content: "success.fileUploaded", type: "success", duration: 1000 });
          dispatch({ type: "UPDATE_USER", user: { cv: [...user.cv, res.data] } });
        })
        .catch(() => {
          addMessage({ content: "error.fileUploaded", type: "error" });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "ADD_CV" });
        });
    }
  };

  const handleRemoveCV = (currentCV: string): void => {
    dispatchLoading({ type: "SET_LOADING", payload: "DELETE_CV" });
    removeUserCV(currentCV)
      .then(() => {
        const updatedCvList = user.cv.filter((userCv) => userCv.id !== currentCV);
        dispatch({ type: "UPDATE_USER", user: { ...user, cv: updatedCvList } });
        calculateProfileProgress(!!updatedCvList.length, PROFILE_STEPS.cv);

        addMessage({ content: "success.removingCV", type: "success", duration: 1000 });
        setExpanded(-1);
        hideDialog();
      })
      .catch(() => {
        addMessage({ content: "error.removingCV", type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "DELETE_CV" });
      });
  };

  const handleExpand = (index: number) => {
    if (index === expanded) {
      setExpanded(-1);
    } else {
      setExpanded(index);
    }
  };

  const showWarningDialog = (currentCV: string) => {
    showDialog("CONFIRM_DIALOG", {
      title: localize("dialog.confirmDeleteCvTitle"),
      subtitle: localize("dialog.confirmDeleteCvSubtitle"),
      cancelAction: () => hideDialog(),
      confirmAction: () => handleRemoveCV(currentCV),
    });
  };

  return (
    <Dialog open={true} fullScreen sx={{ height: "100%" }}>
      <DialogHeader
        handleClose={() => hideModal()}
        title={localize("profileSections.profileTitleCv")}
        returnText={localize("profileView.headerMessage")}
        infoToolTip={localize("profileSections.cvDialogInfo")}
      />
      <Box marginTop=".5rem" textAlign="center">
        {user.cv.length > 0 ? (
          user.cv.map((cv, index) => {
            return (
              <Accordion disableGutters expanded={expanded === index} key={`cv${cv.id}`}>
                <AccordionSummary
                  onClick={() => handleExpand(index)}
                  sx={{
                    backgroundColor: "common.white",
                    padding: "0",
                    paddingRight: "1rem",
                    margin: 0,
                    "& .MuiAccordionSummary-content ": {
                      margin: 0,
                    },
                  }}
                  expandIcon={<WIcon icon={WIconTypes.arrowDown} />}
                >
                  <CvCard cv={cv} />
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container gap="1rem" direction="column">
                    <Grid
                      container
                      alignItems="center"
                      gap="1.5rem"
                      onClick={() => {
                        showWarningDialog(cv.id);
                      }}
                    >
                      <WIcon icon={WIconTypes.trash} />
                      <Typography>{`${localize("common.delete")} CV`}</Typography>
                    </Grid>
                    <Grid container alignItems="center" gap="1.5rem">
                      <WIcon icon={WIconTypes.download} />
                      <Link href={cv.user_cv} target="_blank" download>
                        <Typography>{`${localize("common.download")} CV`}</Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Typography margin="1rem">{localize("profileView.noCVs")}</Typography>
        )}
      </Box>

      <Box
        marginTop="auto"
        width="100%"
        padding="0 1rem"
        sx={{ position: "sticky", bottom: 0, backgroundColor: "background.default" }}
      >
        <WButton
          fullWidth
          variant="outlined"
          startIcon={<WIcon icon={WIconTypes.uploadCloud} />}
          sx={{ margin: "1rem 0" }}
          onClick={() => uploadFile.current?.click()}
        >
          {localize("profileView.addCV")}
          <input
            hidden
            ref={uploadFile}
            accept=".doc,.docx,.pdf"
            type="file"
            value=""
            onChange={(e) => {
              handleAddCV(e);
            }}
          />
        </WButton>
      </Box>
    </Dialog>
  );
};
