import { WIcon, WIconTypes } from "components/WIcon";
import React, { useContext } from "react";
import { colors } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";
import { WTypography } from "components/WTypography";

export function getTrendInfo(
  value: number,
  previousValue: number,
  color?: string
): { trendOutput: React.ReactElement; trendColor: string } {
  const { localize } = useContext(LocaleContext);
  let iconColor = "";
  const setColor = color ?? colors.darkGrey;

  if (value === previousValue) {
    return {
      trendOutput: (
        <>
          <WIcon customColor={setColor} icon={WIconTypes.arrowLineRight} size="small" />{" "}
          <WTypography variant="caption" color={setColor}>
            {localize("statisticView.noChange")}{" "}
          </WTypography>
        </>
      ),
      trendColor: colors.darkGrey,
    };
  } else if (previousValue >= 0) {
    const diff = value - previousValue;
    if (diff > 0) {
      iconColor = color ?? colors.success;

      return {
        trendOutput: (
          <>
            <WIcon customColor={iconColor} icon={WIconTypes.trendingUp} size="small" />
            <WTypography variant="caption" color={setColor}>
              {diff} {localize("statisticView.more")}
            </WTypography>
          </>
        ),
        trendColor: colors.success,
      };
    } else {
      iconColor = color ?? colors.mainDarkRed;

      return {
        trendOutput: (
          <>
            <WIcon customColor={iconColor} icon={WIconTypes.trendingDown} size="small" />
            <WTypography variant="caption" color={setColor}>
              {diff * -1} {localize("statisticView.fewer")}
            </WTypography>
          </>
        ),
        trendColor: colors.error,
      };
    }
  } else {
    return {
      trendOutput: <WIcon color="success" icon={WIconTypes.trendingUp} />,
      trendColor: colors.success,
    };
  }
}
