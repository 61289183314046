import { MenuItem } from "@mui/material";
import { WSkeleton, WButton } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { Department } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { JobFilterItem, JobFilterItemWithParent } from "components/dialogs/FiltersDialog";
import { Box, Typography } from "@mui/material";
import { ExpandableListItem } from "components";
import { colors } from "styles/colors";
import { WIcon, WIconTypes } from "components/WIcon";

type DepartmentFilters = {
  departments: JobFilterItem[];
  roles: JobFilterItemWithParent[];
};

interface DepartmentExpandableListProps {
  expand: boolean;
  itemsToShow: number;
  filters: DepartmentFilters;
  allResults: Department[];
  handleItemClick: (
    key: keyof DepartmentFilters,
    item: JobFilterItem | JobFilterItemWithParent,
    parent?: string | undefined
  ) => void;
  handleExpandClick?: () => void;
  lastElementRef?: (el: Element | null) => void;
}

export const DepartmentExpandableList: FC<DepartmentExpandableListProps> = ({
  expand,
  itemsToShow,
  filters,
  allResults,
  handleItemClick,
  handleExpandClick,
  lastElementRef,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);

  return (
    <Box>
      <Typography variant="h6" marginLeft="1rem">
        {localize("common.workRole")}
      </Typography>
      {isLoading("GET_DEPARTMENTS") ? (
        <WSkeleton width="100%">
          <MenuItem />
        </WSkeleton>
      ) : (
        allResults.slice(0, expand ? itemsToShow : undefined).map((dep, index) => (
          <Box key={`departments${dep.id}_${index}`}>
            <ExpandableListItem
              label={dep.name}
              value={dep.id}
              isChecked={dep.roles.some((role) =>
                filters.roles.some((filterRole) => filterRole.value === role.id)
              )}
              isExpanded={dep.roles.some((role) =>
                filters.roles.some((filterRole) => filterRole.value === role.id)
              )}
              expandableListData={dep.roles.map((role) => {
                return {
                  label: role.name,
                  value: role.id,
                  isChecked: filters.roles.some((filterRole) => filterRole.value === role.id),
                };
              })}
              handleClick={(value) => handleItemClick("departments", value)}
              handleSubListClick={(value) =>
                handleItemClick("roles", { ...value, parent: { label: dep.name, value: dep.id } })
              }
            />
            {lastElementRef && index === allResults.length - 1 && <div ref={lastElementRef} />}
          </Box>
        ))
      )}
      {expand && allResults.length > itemsToShow && handleExpandClick && (
        <WButton
          fullWidth
          endIcon={<WIcon icon={WIconTypes.arrowDown} />}
          sx={{ color: colors.mainDarkRed }}
          onClick={handleExpandClick}
        >
          {localize("searchbar.showMoreDepartments")}
        </WButton>
      )}
    </Box>
  );
};
