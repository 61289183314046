import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const StatusCheckUpSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 257 225"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_334_6557)">
          <path
            d="M15.5444 0C15.2891 0.000280322 15.0444 0.101793 14.8639 0.282271C14.6834 0.462748 14.5818 0.707445 14.5815 0.962678V33.7977C14.5818 34.0529 14.6834 34.2976 14.8639 34.4781C15.0444 34.6585 15.2891 34.7601 15.5444 34.7603H127.524C127.779 34.7601 128.024 34.6585 128.204 34.4781C128.385 34.2976 128.486 34.0529 128.487 33.7977V0.962678C128.486 0.707445 128.385 0.462748 128.204 0.282271C128.024 0.101793 127.779 0.000280322 127.524 0H15.5444Z"
            fill="#E6E6E6"
          />
          <path d="M16.9941 32.3481H126.073V2.41211H16.9941V32.3481Z" fill="white" />
          <path
            d="M112.305 26.1377C112.095 26.1377 111.888 26.1789 111.695 26.2591C111.501 26.3392 111.325 26.4567 111.177 26.6048C111.029 26.7529 110.912 26.9287 110.831 27.1222C110.751 27.3157 110.71 27.523 110.71 27.7325C110.71 27.9419 110.751 28.1493 110.831 28.3427C110.912 28.5362 111.029 28.712 111.177 28.8601C111.325 29.0082 111.501 29.1257 111.695 29.2058C111.888 29.286 112.095 29.3272 112.305 29.3272H118.685C118.894 29.3272 119.102 29.286 119.295 29.2058C119.489 29.1257 119.664 29.0082 119.812 28.8601C119.961 28.712 120.078 28.5362 120.158 28.3427C120.238 28.1493 120.28 27.9419 120.28 27.7325C120.28 27.523 120.238 27.3157 120.158 27.1222C120.078 26.9287 119.961 26.7529 119.812 26.6048C119.664 26.4567 119.489 26.3392 119.295 26.2591C119.102 26.1789 118.894 26.1377 118.685 26.1377H112.305Z"
            fill={colors.mainSVGColor}
          />
          <path d="M120.28 23.0444H46.6328V23.4642H120.28V23.0444Z" fill="#E6E6E6" />
          <path
            d="M31.2385 26.6683C36.369 26.6683 40.5282 22.5098 40.5282 17.3801C40.5282 12.2503 36.369 8.0918 31.2385 8.0918C26.1079 8.0918 21.9487 12.2503 21.9487 17.3801C21.9487 22.5098 26.1079 26.6683 31.2385 26.6683Z"
            fill="#E6E6E6"
          />
          <path
            d="M46.7503 20.7016L46.5176 20.3525L54.1705 15.2513L59.4182 17.7701L66.9666 12.9482L67.0686 12.9917L81.9824 19.3527L93.94 13.4795L105.552 18.1863L120.142 21.1517L120.42 21.4659L105.635 18.6731L93.9562 13.9388L81.9941 19.8138L67.0054 13.4209L59.4468 18.2492L54.2036 15.7329L46.7503 20.7016Z"
            fill="#3F3D56"
          />
          <path
            d="M47.9968 26.3576C47.6389 26.3634 47.2976 26.5095 47.0465 26.7646C46.7955 27.0197 46.6548 27.3633 46.6548 27.7211C46.6548 28.079 46.7955 28.4225 47.0465 28.6776C47.2976 28.9327 47.6389 29.0789 47.9968 29.0847H62.2645C62.6259 29.09 62.9746 28.9517 63.2341 28.7001C63.4936 28.4486 63.6425 28.1043 63.6483 27.743C63.6541 27.3816 63.5163 27.0328 63.265 26.773C63.0137 26.5133 62.6696 26.3639 62.3082 26.3576C62.2937 26.3574 62.2791 26.3574 62.2645 26.3576H47.9968Z"
            fill="#E6E6E6"
          />
          <path
            d="M34.9751 17.2648C34.8706 17.1604 34.7289 17.1017 34.5812 17.1017C34.4334 17.1017 34.2917 17.1603 34.1872 17.2648L34.1872 17.2648L31.7955 19.6561V13.7594C31.7943 13.6125 31.735 13.472 31.6307 13.3686C31.5263 13.2651 31.3853 13.207 31.2384 13.207C31.0914 13.207 30.9504 13.2651 30.8461 13.3686C30.7417 13.472 30.6825 13.6125 30.6812 13.7594V19.6561L28.2896 17.2648C28.1853 17.1605 28.0438 17.1018 27.8963 17.1016C27.7488 17.1014 27.6072 17.1597 27.5026 17.2637C27.398 17.3677 27.3389 17.509 27.3384 17.6565C27.3378 17.804 27.3958 17.9458 27.4996 18.0506L27.5017 18.0526L30.8444 21.3948C30.9489 21.4993 31.0906 21.558 31.2384 21.558C31.3861 21.558 31.5278 21.4993 31.6323 21.3948L34.9751 18.0526C35.0796 17.9481 35.1383 17.8064 35.1383 17.6587C35.1383 17.511 35.0796 17.3693 34.9751 17.2648Z"
            fill="white"
          />
          <path
            d="M59.5991 52.5869C59.3438 52.5872 59.0991 52.6887 58.9185 52.8692C58.738 53.0497 58.6365 53.2944 58.6362 53.5496V86.3846C58.6365 86.6398 58.738 86.8845 58.9185 87.065C59.0991 87.2455 59.3438 87.347 59.5991 87.3473H171.579C171.834 87.347 172.079 87.2455 172.259 87.065C172.44 86.8845 172.541 86.6398 172.542 86.3846V53.5496C172.541 53.2944 172.44 53.0497 172.259 52.8692C172.079 52.6887 171.834 52.5872 171.579 52.5869H59.5991Z"
            fill="#E6E6E6"
          />
          <path d="M61.0493 84.935H170.129V54.999H61.0493V84.935Z" fill="white" />
          <path
            d="M156.36 78.7251C155.937 78.7251 155.531 78.8931 155.232 79.1922C154.933 79.4913 154.765 79.8969 154.765 80.3199C154.765 80.7428 154.933 81.1485 155.232 81.4475C155.531 81.7466 155.937 81.9146 156.36 81.9146H162.739C163.162 81.9146 163.568 81.7466 163.867 81.4475C164.166 81.1485 164.334 80.7428 164.334 80.3199C164.334 79.8969 164.166 79.4913 163.867 79.1922C163.568 78.8931 163.162 78.7251 162.739 78.7251H156.36Z"
            fill={colors.mainSVGColor}
          />
          <path d="M164.335 75.6313H90.688V76.0511H164.335V75.6313Z" fill="#E6E6E6" />
          <path
            d="M75.2931 79.2558C80.4237 79.2558 84.5829 75.0972 84.5829 69.9675C84.5829 64.8377 80.4237 60.6792 75.2931 60.6792C70.1626 60.6792 66.0034 64.8377 66.0034 69.9675C66.0034 75.0972 70.1626 79.2558 75.2931 79.2558Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M90.8048 73.2886L90.5718 72.9395L98.2247 67.8384L103.473 70.3572L111.021 65.5353L111.123 65.5787L126.037 71.9398L137.995 66.0666L149.606 70.773L164.196 57.8525L164.474 58.1667L149.689 71.2588L138.011 66.5248L126.049 72.3997L111.06 66.0069L103.502 70.8352L98.26 68.3199L90.8048 73.2886Z"
            fill="#3F3D56"
          />
          <path
            d="M92.0522 78.944C91.6905 78.9381 91.3413 79.0761 91.0813 79.3277C90.8214 79.5792 90.6721 79.9237 90.6662 80.2854C90.6603 80.647 90.7983 80.9961 91.0499 81.256C91.3015 81.5159 91.646 81.6652 92.0077 81.6711V81.6711C92.0225 81.6713 92.0374 81.6713 92.0522 81.6711H106.32C106.678 81.6653 107.019 81.5192 107.27 81.2641C107.521 81.009 107.662 80.6654 107.662 80.3076C107.662 79.9497 107.521 79.6061 107.27 79.3511C107.019 79.096 106.678 78.9498 106.32 78.944H92.0522Z"
            fill="#E6E6E6"
          />
          <path
            d="M71.5567 70.0827C71.6085 70.1344 71.6699 70.1755 71.7375 70.2035C71.8051 70.2315 71.8775 70.2459 71.9507 70.2459C72.0238 70.2459 72.0963 70.2315 72.1639 70.2035C72.2315 70.1755 72.2929 70.1345 72.3446 70.0827L72.3446 70.0827L74.7363 67.6914V73.5881C74.7363 73.7358 74.795 73.8775 74.8995 73.982C75.004 74.0864 75.1457 74.1451 75.2935 74.1451C75.4412 74.1451 75.5829 74.0864 75.6874 73.982C75.7919 73.8775 75.8506 73.7358 75.8506 73.5881V67.6914L78.2423 70.0827C78.294 70.1344 78.3554 70.1755 78.423 70.2035C78.4906 70.2315 78.5631 70.2459 78.6362 70.2459C78.7094 70.2459 78.7818 70.2315 78.8494 70.2035C78.917 70.1755 78.9785 70.1345 79.0302 70.0827C79.0819 70.031 79.123 69.9696 79.151 69.902C79.179 69.8344 79.1934 69.762 79.1934 69.6888C79.1934 69.6157 79.179 69.5432 79.151 69.4757C79.123 69.4081 79.0819 69.3467 79.0302 69.2949L75.6874 65.9527C75.5829 65.8482 75.4412 65.7896 75.2935 65.7896C75.1457 65.7896 75.004 65.8482 74.8995 65.9527L71.5567 69.2949C71.4523 69.3994 71.3936 69.5411 71.3936 69.6888C71.3936 69.8366 71.4523 69.9782 71.5567 70.0827V70.0827Z"
            fill="white"
          />
          <path
            d="M0.962834 105.174C0.707561 105.174 0.462825 105.276 0.28232 105.456C0.101815 105.637 0.000280366 105.881 0 106.137V138.971C0.000280366 139.227 0.101815 139.471 0.28232 139.652C0.462825 139.832 0.707561 139.934 0.962834 139.934H112.942C113.198 139.934 113.442 139.832 113.623 139.652C113.803 139.471 113.905 139.227 113.905 138.971V106.137C113.905 105.881 113.803 105.637 113.623 105.456C113.442 105.276 113.198 105.174 112.942 105.174H0.962834Z"
            fill="#E6E6E6"
          />
          <path d="M2.41309 137.522H111.493V107.586H2.41309V137.522Z" fill="white" />
          <path
            d="M97.7234 131.312C97.3004 131.312 96.8947 131.48 96.5956 131.779C96.2965 132.078 96.1284 132.483 96.1284 132.906C96.1284 133.329 96.2965 133.735 96.5956 134.034C96.8947 134.333 97.3004 134.501 97.7234 134.501H104.103C104.526 134.501 104.932 134.333 105.231 134.034C105.53 133.735 105.698 133.329 105.698 132.906C105.698 132.483 105.53 132.078 105.231 131.779C104.932 131.48 104.526 131.312 104.103 131.312H97.7234Z"
            fill={colors.mainSVGColor}
          />
          <path d="M105.698 128.219H32.0513V128.638H105.698V128.219Z" fill="#E6E6E6" />
          <path
            d="M16.6569 131.842C21.7875 131.842 25.9466 127.684 25.9466 122.554C25.9466 117.424 21.7875 113.266 16.6569 113.266C11.5263 113.266 7.36719 117.424 7.36719 122.554C7.36719 127.684 11.5263 131.842 16.6569 131.842Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M32.1677 125.876L31.9351 125.526L39.5879 120.425L44.8357 122.944L52.3844 118.12L67.3999 124.525L79.3575 118.651L90.9676 123.36L105.558 117.5L105.836 117.814L91.0527 123.846L79.3741 119.112L67.4123 124.987L52.4236 118.595L44.8653 123.423L39.6222 120.907L32.1677 125.876Z"
            fill="#3F3D56"
          />
          <path
            d="M33.4152 131.531C33.0573 131.537 32.7161 131.683 32.465 131.938C32.2139 132.193 32.0732 132.537 32.0732 132.894C32.0732 133.252 32.2139 133.596 32.465 133.851C32.7161 134.106 33.0573 134.252 33.4152 134.258H47.6829C47.862 134.261 48.0399 134.229 48.2065 134.163C48.3731 134.097 48.5251 133.999 48.6538 133.874C48.7824 133.75 48.8854 133.601 48.9566 133.437C49.0278 133.272 49.066 133.096 49.0689 132.917C49.0718 132.738 49.0394 132.56 48.9736 132.393C48.9077 132.227 48.8097 132.075 48.6852 131.946C48.5606 131.817 48.4119 131.714 48.2475 131.643C48.0832 131.572 47.9064 131.534 47.7273 131.531C47.7125 131.531 47.6977 131.531 47.6829 131.531H33.4152Z"
            fill="#E6E6E6"
          />
          <path
            d="M12.92 122.669C12.9718 122.721 13.0332 122.762 13.1008 122.79C13.1683 122.818 13.2408 122.832 13.3139 122.832C13.3871 122.832 13.4596 122.818 13.5271 122.79C13.5947 122.762 13.6562 122.721 13.7079 122.669L13.7079 122.669L16.0996 120.278V126.175C16.1003 126.322 16.159 126.462 16.263 126.566C16.3671 126.67 16.5079 126.729 16.655 126.729C16.802 126.73 16.9432 126.672 17.0479 126.569C17.1526 126.465 17.2123 126.325 17.2139 126.178V120.278L19.6055 122.669C19.7097 122.774 19.8513 122.833 19.9991 122.833C20.1468 122.834 20.2887 122.775 20.3934 122.671C20.4982 122.567 20.5572 122.425 20.5576 122.278C20.558 122.13 20.4997 121.988 20.3955 121.883L20.3934 121.881L17.0507 118.539C16.9462 118.435 16.8045 118.376 16.6567 118.376C16.509 118.376 16.3673 118.435 16.2628 118.539L12.92 121.881C12.8683 121.933 12.8272 121.994 12.7992 122.062C12.7712 122.13 12.7568 122.202 12.7568 122.275C12.7568 122.348 12.7712 122.421 12.7992 122.488C12.8272 122.556 12.8683 122.617 12.92 122.669V122.669Z"
            fill="white"
          />
          <path
            d="M229.647 219.565L224.27 219.564L221.712 198.829L229.647 198.829L229.647 219.565Z"
            fill="#FFB7B7"
          />
          <path
            d="M231.018 224.776L213.681 224.775V224.556C213.681 222.767 214.392 221.051 215.658 219.785C216.923 218.52 218.639 217.809 220.429 217.809H220.43L231.018 217.81L231.018 224.776Z"
            fill="#2F2E41"
          />
          <path
            d="M209.911 219.565L204.535 219.564L201.977 198.829L209.912 198.829L209.911 219.565Z"
            fill="#FFB7B7"
          />
          <path
            d="M211.283 224.776L193.946 224.775V224.556C193.946 222.767 194.657 221.051 195.922 219.785C197.188 218.52 198.904 217.809 200.694 217.809H200.694L211.283 217.81L211.283 224.776Z"
            fill="#2F2E41"
          />
          <path
            d="M199.777 152.308C200.243 151.928 200.619 151.451 200.88 150.909C201.141 150.368 201.281 149.775 201.288 149.174C201.295 148.573 201.171 147.978 200.923 147.43C200.676 146.882 200.311 146.396 199.855 146.004L202.777 137.074L197.783 133.975L193.833 146.644C193.226 147.453 192.937 148.458 193.021 149.466C193.105 150.475 193.556 151.418 194.288 152.116C195.021 152.814 195.984 153.219 196.996 153.255C198.008 153.29 198.997 152.954 199.777 152.308V152.308Z"
            fill="#FFB7B7"
          />
          <path
            d="M205.105 146.413C205.105 146.413 200.086 173.575 200.149 178.847C200.225 185.21 203.056 212.477 203.056 212.477L210.265 210.675L211.627 179.071L217.328 158.9L219.521 184.771L221.722 211.985H230.085L231.958 143.71L204.654 142.81L205.105 146.413Z"
            fill="#2F2E41"
          />
          <path
            d="M230.11 91.6807L215.693 92.1311L206.682 147.99C206.682 147.99 225.83 156.774 233.94 150.467C242.05 144.161 230.11 91.6807 230.11 91.6807Z"
            fill="#CBCBCB"
          />
          <path
            d="M217.72 103.618L205.556 173.441L197.896 171.189L203.303 145.963L206.006 120.736L205.556 95.5098L215.017 91.4556L217.72 88.3022C217.72 88.3022 223.127 95.0593 217.72 103.618Z"
            fill="#2F2E41"
          />
          <path
            d="M224.028 101.914L224.929 175.243L243.401 172.09L242.049 104.069L243.852 94.6089L234.841 90.5546L231.192 88.3022C231.192 88.3022 223.127 90.7497 224.028 101.914Z"
            fill="#2F2E41"
          />
          <path
            d="M209.611 96.8611L205.556 95.5098L199.699 121.637L194.743 142.359L201.951 143.71L208.71 121.187L209.611 96.8611Z"
            fill="#2F2E41"
          />
          <path
            d="M250.23 156.693C249.764 156.313 249.387 155.835 249.126 155.294C248.865 154.752 248.726 154.16 248.719 153.559C248.711 152.958 248.836 152.363 249.083 151.815C249.331 151.267 249.696 150.78 250.152 150.389L247.229 141.459L252.224 138.359L256.174 151.028C256.781 151.838 257.07 152.843 256.986 153.851C256.902 154.86 256.451 155.802 255.718 156.501C254.986 157.199 254.022 157.604 253.011 157.64C251.999 157.675 251.01 157.338 250.23 156.693V156.693Z"
            fill="#FFB7B7"
          />
          <path
            d="M239.958 95.9842L244.013 94.6328L249.87 120.76L255.264 146.744L247.151 147.937L240.859 120.31L239.958 95.9842Z"
            fill="#2F2E41"
          />
          <path
            d="M228.694 84.6179C233.547 81.9655 235.33 75.882 232.678 71.0299C230.025 66.1779 223.94 64.3947 219.088 67.0471C214.235 69.6994 212.451 75.783 215.104 80.635C217.757 85.4871 223.841 87.2702 228.694 84.6179Z"
            fill="#FFB7B7"
          />
          <path
            d="M248.851 225H167.644C167.55 225 167.461 224.963 167.394 224.897C167.328 224.83 167.291 224.741 167.291 224.647C167.291 224.553 167.328 224.464 167.394 224.397C167.461 224.331 167.55 224.294 167.644 224.294H248.851C248.945 224.294 249.035 224.331 249.101 224.397C249.167 224.464 249.204 224.553 249.204 224.647C249.204 224.741 249.167 224.83 249.101 224.897C249.035 224.963 248.945 225 248.851 225Z"
            fill="#CBCBCB"
          />
          <path
            d="M220.399 62.7312C229.972 59.971 232.689 67.9042 232.689 67.9042C237.304 70.965 234.415 74.8026 234.415 74.8026C235.579 75.0612 233.251 81.8738 232.475 81.7014C232.06 81.6093 230.995 82.8805 230.092 84.0861C229.782 84.0857 229.473 84.1154 229.169 84.1748L230.922 77.6917C230.922 77.6917 225.014 76.7867 223.203 73.9411C221.47 71.2188 215.765 70.3593 214.141 72.1777C214.045 71.6549 213.849 71.1556 213.562 70.7078C213.341 70.3675 213.051 70.077 212.711 69.8544C214.146 63.7552 220.399 62.7312 220.399 62.7312Z"
            fill="#2F2E41"
          />
        </g>
        <defs>
          <clipPath id="clip0_334_6557">
            <rect width="257" height="225" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
