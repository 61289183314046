import { Grid, Link } from "@mui/material";
import { CV, addUserDocument } from "adapters";
import { CvCard } from "components/modals/Profile/ProfileCV/CvCard";
import { WIconButton, WIconOutlinedButton } from "components/styledComponents/WIconButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { LocaleContext } from "context/LocaleContext";
import { LoaderContext } from "context/LoaderContext";
import { ModalContext } from "context/ModalContext";
import React, { FC, useCallback, useContext, useRef } from "react";
import { colors, defaultStyles } from "styles/colors";
import Grow from "@mui/material/Grow";
import { WTypography } from "components/WTypography";
import { UserDocument, removeUserDocument } from "adapters/UserDocumentAdapter";
import { UserDocumentCard } from "./UserDocumentCard";
import { NotificationContext } from "context/NotificationContext";

interface CandidateDocuments {
  cvs: CV[];
  userDocuments: UserDocument[];
  candidateId: string;
  onAddDocument: (userDocument: UserDocument) => void;
  onRemoveDocument: (selectedDocumentId: string) => void;
}

export const CandidateDocuments: FC<CandidateDocuments> = ({
  cvs,
  userDocuments,
  candidateId,
  onAddDocument,
  onRemoveDocument,
}) => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const uploadFile = useRef<HTMLInputElement | null>(null);
  const maxFilenameLength = 50;
  const { showDialog, hideDialog } = useContext(ModalContext);

  const handleAddDocument = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (event.target.files?.length) {
        let alias = event.target.files[0].name;
        // remove extension.
        alias = alias.replace(/\.[^/.]+$/, "");
        if (alias.length > maxFilenameLength) {
          alias = alias.substring(0, maxFilenameLength - 1);
        }
        dispatchLoading({ type: "SET_LOADING", payload: "ADD_DOCUMENT" });
        addUserDocument(event.target.files[0], alias, candidateId)
          .then((res) => {
            onAddDocument(res.data);
            addMessage({ content: "success.fileUploaded", type: "success", duration: 1000 });
          })
          .catch(() => {
            addMessage({ content: "error.fileUploaded", type: "error" });
          })
          .finally(() => {
            dispatchLoading({ type: "STOP_LOADING", payload: "ADD_DOCUMENT" });
          });
      }
    },
    [addMessage, candidateId, dispatchLoading, onAddDocument]
  );

  const handleRemoveDocument = useCallback(
    (selectedDocumentId: string): void => {
      dispatchLoading({ type: "SET_LOADING", payload: "DELETE_DOCUMENT" });
      removeUserDocument(selectedDocumentId)
        .then(() => {
          onRemoveDocument(selectedDocumentId);
          addMessage({ content: "success.removingCV", type: "success", duration: 1000 });
          hideDialog();
        })
        .catch(() => {
          addMessage({ content: "error.removingCV", type: "error" });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "DELETE_DOCUMENT" });
        });
    },
    [addMessage, dispatchLoading, hideDialog, onRemoveDocument]
  );

  const showWarningDialog = (selectedDocumentId: string) => {
    showDialog("CONFIRM_DIALOG", {
      cancelAction: () => hideDialog(),
      confirmAction: () => handleRemoveDocument(selectedDocumentId),
    });
  };

  return (
    <Grid container gap={2} direction="column">
      <Grid container alignItems="center" paddingBottom="20px">
        <WTypography variant="body1" fontWeight="bold" marginRight="5px">
          {localize("candidateModal.uploadDocument")}
          <input
            hidden
            ref={uploadFile}
            accept=".doc,.docx,.pdf"
            type="file"
            value=""
            onChange={(e) => handleAddDocument(e)}
          />
        </WTypography>

        <WIconButton onClick={() => uploadFile.current?.click()}>
          <WIcon icon={WIconTypes.add} size="small" />
        </WIconButton>
      </Grid>
      {userDocuments.length === 0 && (
        <WTypography variant="body2" fontWeight="bold" color={colors.darkGrey}>
          -
        </WTypography>
      )}

      {userDocuments.map((userDocument, index) => (
        <Grow key={userDocument.id} in={true} {...{ timeout: 1000 + index * 200 }}>
          <Grid
            container
            key={userDocument.id}
            gap="8px"
            direction="column"
            sx={{
              backgroundColor: colors.mainWithOpacity,
              borderRadius: defaultStyles.borderRadius,
              padding: "12px",
            }}
          >
            <UserDocumentCard
              key={userDocument.id}
              userDocument={userDocument}
              isLast={true}
              css={{
                backgroundColor: "transparent",
                iconSize: "35px",
                textColor: colors.darkGrey,
                subtitleSize: "caption",
                border: "0",
                padding: "5px",
              }}
              icons={
                <Grid container gap="1rem" wrap="nowrap">
                  <Link href={userDocument.user_document} target="_blank" download>
                    <WIconOutlinedButton>
                      <WIcon icon={WIconTypes.download} />
                    </WIconOutlinedButton>
                  </Link>
                  <WIconOutlinedButton onClick={() => showWarningDialog(userDocument.id)}>
                    <WIcon icon={WIconTypes.trash} />
                  </WIconOutlinedButton>
                </Grid>
              }
            />
          </Grid>
        </Grow>
      ))}

      {cvs.length > 0 && (
        <WTypography variant="body1" fontWeight="bold" marginRight="5px" paddingY="20px">
          {localize("candidateModal.cvs")}
        </WTypography>
      )}

      {cvs.map((cv, index) => (
        <Grow key={cv.id} in={true} {...{ timeout: 1000 + index * 200 }}>
          <Grid
            container
            key={cv.id}
            gap="8px"
            direction="column"
            sx={{
              backgroundColor: colors.mainWithOpacity,
              borderRadius: defaultStyles.borderRadius,
              padding: "12px",
            }}
          >
            <CvCard
              key={cv.id}
              cv={cv}
              isLast={true}
              css={{
                backgroundColor: "transparent",
                iconSize: "35px",
                textColor: colors.darkGrey,
                subtitleSize: "caption",
                border: "0",
                padding: "5px",
              }}
              icons={
                <Grid container gap="1rem" wrap="nowrap">
                  <Link href={cv.user_cv} target="_blank" download>
                    <WIconOutlinedButton>
                      <WIcon icon={WIconTypes.download} />
                    </WIconOutlinedButton>
                  </Link>
                </Grid>
              }
            />
          </Grid>
        </Grow>
      ))}
    </Grid>
  );
};
