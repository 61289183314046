import React, { FC, useContext } from "react";
import { Grid } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { useDynamicPageContent } from "hooks/useDynamicPageContent";
import { DynamicViewContent, WButton } from "components";
import { UserContext } from "context/UserContext";
import { useHistory, useParams } from "react-router-dom";
import { LoaderContext } from "context/LoaderContext";
import NotFoundView from "views/NotFoundView";

interface DynamicExtraPageProps {
  pageType?: string;
}

export const DynamicExtraPage: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);
  const { isAuthenticated } = useContext(UserContext);
  const { pageType = "" } = useParams<DynamicExtraPageProps>();

  const history = useHistory();

  const { pageContent, pageHTMLContent } = useDynamicPageContent({
    isExtraPage: true,
    pageType,
  });

  if (!isLoading("GET_PAGE_LIST") && !pageContent.id) {
    return <NotFoundView />;
  }

  return (
    <Grid container position="relative">
      <DynamicViewContent
        pageContent={pageContent}
        forceSuperHeader
        pageHTMLContent={pageHTMLContent}
      />
      {!isAuthenticated && (
        <Grid container justifyContent="center">
          <WButton variant="contained" onClick={() => history.push("/login")}>
            {localize("dynamicViewsCTA.getStarted")}
          </WButton>
        </Grid>
      )}
    </Grid>
  );
};
