import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { defaultStyles } from "styles/colors";
import { LineChartCard } from "./LineChartCard";

interface GraphProps {
  graphs: {
    title: string;
    prop: string;
    dataProp?: string;
    target?: number;
    targetProp?: string;
  }[];
  dataObj: Record<string, number | number[] | string>;
  previousDataObj?: Record<string, number | number[] | string>;
  targets?: Record<string, number>;
  labels?: string[];
}

export const LineGraphs: React.FC<GraphProps> = ({
  graphs,
  dataObj,
  previousDataObj,
  targets,
  labels,
}) => {
  return (
    <>
      {graphs.map(({ title, prop, dataProp, targetProp }) => {
        const total = dataObj[prop] as number;
        dataProp = dataProp || `${prop}_period`;

        const data = dataObj[dataProp] as number[];

        let previousData = undefined;

        if (previousDataObj) {
          previousData = previousDataObj[dataProp] as number[];
        }

        return (
          <Grid item key={prop} xs padding="20px">
            {data.length === 0 || total === -1 ? (
              <Skeleton
                key={`skeleton_${prop}`}
                height="250px"
                variant="rectangular"
                width="100%"
                sx={{
                  borderRadius: defaultStyles.borderRadius,
                }}
              />
            ) : (
              <LineChartCard
                key={`chart_${prop}`}
                title={title}
                total={total}
                data={data}
                previousData={previousData}
                tooltipLabels={labels}
                target={targetProp && targets ? targets[targetProp] : undefined}
              />
            )}
          </Grid>
        );
      })}
    </>
  );
};
