import { Box, CircularProgress, Dialog, Grid } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { ModalContext } from "context/ModalContext";
import { DetailedJobHeaderMobile, DialogHeader, WButton, WIcon, WIconTypes } from "components";
import { JobApplicationForm } from "components/forms/JobApplicationForm";
import { CV, getJobApplication, JobApplication, Job, Skill } from "adapters";

import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { LocaleContext } from "context/LocaleContext";
import {
  CreateJobApplicationForm,
  updateJobApplication,
  createJobApplication,
} from "adapters/JobApplicationAdapter";
import { PushNotificationContext, PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { UserContext } from "context/UserContext";
import { PROFILE_STEPS } from "views/Enums";
import { isAfter, parseISO } from "date-fns";
import { User } from "reducers/UserReducer";
import { ActiveJobApplication, defaultActiveJobApplication } from "adapters/JobAdapter";
import { GTMContext } from "context/GTMContext";

interface JobApplicationFormModalProps {
  applicationId?: string;
  handleTypeChange?: (save: boolean) => void;
  job: Job;
  setJobApplicationData?: (data: ActiveJobApplication) => void;
}

export const JobApplicationFormModal: FC<JobApplicationFormModalProps> = ({
  applicationId,
  handleTypeChange,
  job,
  setJobApplicationData,
}) => {
  const { isAuthenticated, user, calculateProfileProgress } = useContext(UserContext);

  const { hideModal, popModal, showDialog, hideDialog } = useContext(ModalContext);
  const { dispatchLoading, isLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { localize } = useContext(LocaleContext);
  const { updateNotification } = useContext(PushNotificationContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);

  const [prefilledData, setPrefilledData] = useState<Partial<JobApplication>>();
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    setCanSave(!applicationId && isAuthenticated);
    if (applicationId) {
      dispatchLoading({ type: "SET_LOADING", payload: "GET_JOB_APPLICATION" });
      getJobApplication(applicationId)
        .then((res) => {
          setPrefilledData({
            ...res.data,
          });
          updateNotification(PUSH_NOTIFICATION_TYPES.jobApplication);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.openJobApplication" });
          popModal();
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "GET_JOB_APPLICATION" });
        });
    } else if (isAuthenticated) {
      setPrefilledData({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        cover_letter: "",
        referrals: [],
        skills: filterMatchedSkills(user.skills),
      });
    }
  }, []);

  const submitJobApplication = async (data: CreateJobApplicationForm) => {
    if (applicationId) {
      if (isAuthenticated && setJobApplicationData) {
        setJobApplicationData({
          ...defaultActiveJobApplication,
          id: applicationId,
          is_draft: data.is_draft,
        });
      }
      return await handleExistingApplicationSubmit(applicationId, data);
    } else {
      return await handleNewApplicationSubmit(data);
    }
  };

  const handleExistingApplicationSubmit = (
    applicationID: string,
    data: CreateJobApplicationForm
  ) => {
    if (handleTypeChange) {
      handleTypeChange(data.is_draft);
    }
    return updateJobApplication(applicationID, data)
      .then(() => {
        gtmAddToDataLayer({ action: job.title }, "application_updated");
        popModal();
        return true;
      })
      .catch((e) => {
        addMessage({ type: "error", content: JSON.stringify(e.response.data), isResponse: true });
        return false;
      });
  };

  const handleNewApplicationSubmit = (data: CreateJobApplicationForm) => {
    if (isLoading("CREATE_JOB_APPLICATION")) {
      return;
    }
    const language = (!isAuthenticated ? localStorage.getItem("lang") : user.language) || "en";

    dispatchLoading({ type: "SET_LOADING", payload: "CREATE_JOB_APPLICATION" });

    return createJobApplication({ ...data, language: language })
      .then((res) => {
        calculateProfileProgress(true, PROFILE_STEPS.jobApplication);

        gtmAddToDataLayer(
          { action: job.title },
          data.is_draft ? "application_saved" : "application_submitted"
        );

        if (handleTypeChange) {
          handleTypeChange(data.is_draft);
        }

        hideModal();

        if (isAuthenticated && setJobApplicationData) {
          setJobApplicationData({
            ...defaultActiveJobApplication,
            id: res.data.id,
            is_draft: data.is_draft,
          });
        }

        return true;
      })
      .catch((e) => {
        addMessage({ type: "error", content: JSON.stringify(e.response.data), isResponse: true });
        return false;
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "CREATE_JOB_APPLICATION" });
      });
  };

  const filterMatchedSkills = (matchSkills: Skill[]) => {
    return matchSkills.filter((matchSkill) =>
      job.skills.some((jobSkill) => jobSkill.id === matchSkill.id)
    );
  };

  const getLatestCV = (cv: CV[]) => {
    return cv.sort((x, y) => {
      return isAfter(parseISO(x.created), parseISO(y.created)) ? -1 : 1;
    })[0];
  };

  const loginAndPrefill = (userData: User) => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_USER_SKILLS" });
    // TODO Add price when user has attribute
    setPrefilledData({
      referrals: [],
      cv: getLatestCV(userData.cv),
      first_name: userData.first_name,
      last_name: userData.last_name,
      phone: userData.phone,
      email: userData.email,
      skills: filterMatchedSkills(userData.skills),
      availability_date: userData.availability_date,
    });
  };

  return (
    <Dialog aria-labelledby="application-details" open={true} fullScreen>
      <Box paddingBottom="5rem">
        <DialogHeader
          rightSideContent={
            !isAuthenticated && (
              <WButton
                variant="outlined"
                startIcon={<WIcon icon={WIconTypes.login} color={"primary"} />}
                color={"primary"}
                sx={{ width: "max-content" }}
                onClick={() =>
                  showDialog("LOGIN_DIALOG", {
                    handleClose: hideDialog,
                    onLoginSubmit: loginAndPrefill,
                  })
                }
              >
                {localize("navbar.loginText")}
              </WButton>
            )
          }
          title={applicationId ? `${job.title}` : `${localize("form.applyForJob")}`}
          handleClose={popModal}
          returnText={localize("common.goBack")} // ToDo:: Pass in modal source
        />
        <DetailedJobHeaderMobile job={job} />
        <Grid container>
          {isLoading("GET_JOB_APPLICATION") ? (
            <CircularProgress sx={{ margin: "auto" }} />
          ) : (
            <JobApplicationForm
              prefillFields={prefilledData}
              jobSkills={job?.skills}
              onSubmit={submitJobApplication}
              canSaveDraft={canSave}
              jobId={job.id || ""}
            />
          )}
        </Grid>
      </Box>
    </Dialog>
  );
};
