import React, { FC, useContext } from "react";
import { Grid, Skeleton } from "@mui/material";
import { colors, defaultStyles } from "styles/colors";
import { WTypography } from "components";
import { Bar } from "react-chartjs-2";
import { LocaleContext } from "context/LocaleContext";
import { Chart, ChartDataset, registerables } from "chart.js";
import { LoaderContext } from "context/LoaderContext";
Chart.register(...registerables);

interface BarChartProps {
  title?: string;
  labels: string[];
  tooltipLabel: string;
  data: number[];
  total?: number;
  stackedData?: number[];
  yMax?: number;
}

export const BarChart: FC<BarChartProps> = ({
  title,
  labels,
  data,
  total,
  stackedData,
  yMax,
  tooltipLabel,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);

  const cardTitle = localize(`statisticView.${title}`);

  const datasets: ChartDataset<"bar">[] = [
    {
      label: localize(`statisticView.${tooltipLabel}`),
      data: data,
      backgroundColor: [colors.mainDark, "rgba(112, 174, 173, 0.75)"],
      borderColor: colors.main,
      hoverBackgroundColor: [colors.main, colors.main],
      barPercentage: 0.35,
      borderWidth: 0,
      borderRadius: {
        topRight: 30,
        topLeft: 30,
        bottomRight: 30,
        bottomLeft: 30,
      },
      borderSkipped: false,
    },
  ];

  if (stackedData) {
    datasets.push({
      label: localize("statisticView.verified"),
      data: stackedData,
      backgroundColor: [colors.main, colors.main],
      borderColor: colors.mainLight,
      hoverBackgroundColor: [colors.main, colors.main],
      barPercentage: 0.35,
      borderWidth: 0,
      borderRadius: {
        topRight: 30,
        topLeft: 30,
        bottomRight: 30,
        bottomLeft: 30,
      },
      borderSkipped: false,
    });
  }

  return (
    <>
      {isLoading("GET_APPLICATIONS_DATA") ? (
        Array(1)
          .fill(1)
          .map((_, index) => (
            <Skeleton
              key={`skeleton_message_${index}`}
              height="350px"
              width="250px"
              variant="rectangular"
              sx={{ borderRadius: defaultStyles.borderRadius, marginBottom: "1px" }}
            />
          ))
      ) : (
        <Grid container sx={{ maxWidth: "300px", height: "300px" }} mt="10px">
          <Grid item marginBottom="15px">
            <WTypography variant="h6" fontWeight="bold">
              {cardTitle} ({total || 0})
            </WTypography>
          </Grid>
          <Bar
            data={{
              datasets,
              labels,
            }}
            options={{
              scales: {
                xAxes: {
                  grid: {
                    display: false,
                  },
                  stacked: true,
                },
                yAxes: {
                  beginAtZero: true,
                  max: yMax,
                  grid: {
                    lineWidth: 0.4,
                  },
                },
              },
              plugins: {
                legend: { display: false },
                tooltip: {
                  intersect: false,
                  mode: "index",
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </Grid>
      )}
    </>
  );
};
