import React, { useContext, useEffect, useMemo, useState } from "react";
import { Grid, Button } from "@mui/material";
import { colors } from "styles/colors";
import { useHistory } from "react-router-dom";
import { BreakpointsContext } from "context/BreakpointContext";
import { WButton } from "components/styledComponents/WButton";
import { LocaleContext } from "context/LocaleContext";
import { Box } from "@mui/system";
import { ExpandableNavbarItem } from "./ExpandableNavbarItem";
import { WIcon, WIconTypes } from "components/WIcon";
import { WiserLogo } from "./WiserLogoSVG";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { ExtraPageType, getExtraPageTypes } from "adapters/DynamicPageAdapter";
import { GTMContext } from "context/GTMContext";

export const DESKTOP_ACTION_BAR_SIZE = "68.5px";
export const MOBILE_ACTION_BAR_SIZE = "70px";

export const DefaultActionNavbar: React.FC = (): JSX.Element => {
  const { isDesktop } = useContext(BreakpointsContext);
  const { localize, currentLanguage } = useContext(LocaleContext);
  const [extraPageTypes, setExtraPageTypes] = useState<ExtraPageType[]>([]);
  const { gtmAddToDataLayer } = useContext(GTMContext);

  const [showBackground, setShowBackground] = useState(false);

  const history = useHistory();

  const onScroll = () => {
    if (window.scrollY < 10) {
      setShowBackground(false);
    } else {
      setShowBackground(true);
    }
  };

  useEffect(() => {
    if (isDesktop) {
      setShowBackground(true);
      return;
    }
    window.addEventListener("scroll", onScroll, true);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [isDesktop]);

  useEffect(() => {
    const loadExtraPages = async () => {
      const pager = await getExtraPageTypes({ language: currentLanguage });
      setExtraPageTypes(pager.data.results);
    };

    loadExtraPages();
  }, []);

  const { forYouItems, serviceItems } = useMemo(() => {
    const extraForYou: ExtraPageType[] = [];
    const extraService: ExtraPageType[] = [];
    extraPageTypes.forEach((p) => {
      if (p.menu === "forYou") {
        extraForYou.push(p);
      } else {
        extraService.push(p);
      }
    });

    const forYouItems = sortItems(extraForYou, [
      { label: localize("navbarListItem.consult"), route: "/it-consultant" },
      {
        label: localize("navbarListItem.consultManager"),
        route: "/consulting-management",
      },
      {
        label: localize("navbarListItem.consultSearch"),
        route: "/consulting-recruitment",
      },
    ]);
    const serviceItems = sortItems(extraService, [
      { label: localize("navbarListItem.howItWork"), route: "/how-it-works" },
      { label: localize("navbarListItem.faq"), route: "/faq" },
    ]);

    return { forYouItems, serviceItems };
  }, [extraPageTypes, localize]);

  const onActionClick = () => {
    const container = document.getElementById("CardContentContainer");

    if (window.location.pathname !== "/") {
      history.push("/");
    }

    if (!container) {
      return;
    }

    smoothScrollIntoView(container, {
      scrollMode: "if-needed",
      block: "center",
      skipOverflowHiddenElements: false,
    });
  };

  const handleRegisterClick = () => {
    history.push("/register");
    gtmAddToDataLayer({ action: "create_account" }, "cta_click");
  };

  const navBarSections = useMemo(
    () => [
      {
        section: localize("navbar.forYou"),
        items: forYouItems,
      },
      {
        section: localize("navbar.service"),
        items: serviceItems,
      },
      {
        section: localize("navbar.aboutUs"),
        items: [],
        route: "/contactUs",
      },
    ],
    [localize, forYouItems, serviceItems]
  );

  return (
    <Grid
      height={!isDesktop ? MOBILE_ACTION_BAR_SIZE : undefined}
      wrap="nowrap"
      container
      sx={{ transition: "all 0.2s ease-out" }}
      bgcolor={showBackground || isDesktop ? "secondary.main" : "transparent"}
      alignItems="center"
      justifyContent="space-between"
      zIndex={4}
      padding={isDesktop ? "1rem 1.5rem" : ".5rem 1rem"}
      position={isDesktop ? "sticky" : "fixed"}
      top="0"
    >
      {!isDesktop && (
        <ExpandableNavbarItem
          expandedCallback={setShowBackground}
          label={localize("navbar.menu")}
          fullWidth={true}
          sections={navBarSections}
          icon={<WIcon icon={WIconTypes.menu} />}
        />
      )}

      <WiserLogo />

      {isDesktop && (
        <Grid container maxWidth="max-content" gap="1rem">
          <Button sx={{ fontWeight: "bold" }} onClick={onActionClick}>
            {localize("navbar.availableAssignments")}
          </Button>
          <ExpandableNavbarItem label={localize("navbar.forYou")} listitems={forYouItems} />
          <ExpandableNavbarItem label={localize("navbar.service")} listitems={serviceItems} />
          <Button
            sx={{ fontWeight: "bold" }}
            onClick={() => {
              history.push("/contactUs");
            }}
          >
            {localize("navbar.aboutUs")}
          </Button>
          <Box minWidth="2px" minHeight="100%" sx={{ backgroundColor: colors.mainDark }} />
          <WButton
            variant="contained"
            color="primary"
            sx={{
              width: "max-content",
              padding: "0 1.5rem",
              boxShadow: "none",
            }}
            onClick={() => history.push("/login")}
          >
            {localize("navbar.loginText")}
          </WButton>
          <WButton
            variant="contained"
            color="primary"
            sx={{
              width: "max-content",
              padding: "0 1.5rem",
              boxShadow: "none",
              marginLeft: "-10px",
            }}
            onClick={handleRegisterClick}
          >
            {localize("navbar.registerText")}
          </WButton>
        </Grid>
      )}
      {!isDesktop && (
        <WButton
          variant="contained"
          color={"primary"}
          sx={{
            width: "max-content",
            paddingRight: "2rem",
            paddingLeft: "2rem",
            boxShadow: "none",
          }}
          onClick={() => history.push("/login")}
        >
          {localize("navbar.loginText")}
        </WButton>
      )}
    </Grid>
  );
};

interface PageItem {
  label: string;
  route: string;
}

function sortItems(extraPages: ExtraPageType[], fixed: PageItem[]): PageItem[] {
  extraPages.sort((a, b) => (a?.order_no ?? 1000) - (b?.order_no ?? 1000));
  const items: PageItem[] = [];
  const count = extraPages.length + fixed.length;
  // Ordered pages
  for (let i = 0; i < count; i++) {
    if (extraPages[0]?.order_no !== i) {
      const page = fixed.shift();
      if (page) {
        items.push(page);
      }
    } else {
      const page = extraPages.shift();
      if (page) {
        items.push({ label: page.label, route: `/${page.page_type}` });
      }
    }
  }
  // Unordered pages
  extraPages.forEach((page) => {
    items.push({ label: page.label, route: `/${page.page_type}` });
  });

  return items;
}
