import React, { FC, useContext } from "react";
import { Grid } from "@mui/material";
import { WIcon } from "components/WIcon";
import { WTypography } from "components/WTypography";
import { WButton } from "components/styledComponents/WButton";
import { TaskTagProp, getIcon } from "./TaskTag";
import { LocaleContext } from "context/LocaleContext";

interface Props {
  types: (keyof typeof buttonData)[];
  setAddingTag: (value: string) => void;
}

export const AddTagButtons: FC<Props> = ({ types, setAddingTag }) => {
  const { localize } = useContext(LocaleContext);

  return (
    <Grid item container gap="5px">
      {types.map((type) => {
        const { tag, value } = buttonData[type];
        return (
          <Grid item key={value}>
            <WButton size="small" variant="outlined" onClick={() => setAddingTag(value)}>
              <WIcon icon={getIcon(value as TaskTagProp)} size="small" />
              <WTypography variant="body2" marginLeft="3px">
                {localize(tag)}
              </WTypography>
            </WButton>
          </Grid>
        );
      })}
    </Grid>
  );
};

const buttonData = {
  jobs: {
    value: "jobs",
    tag: "common.assignment",
  },
  applications: {
    value: "job_applications",
    tag: "common.application",
  },
  users: {
    value: "users",
    tag: "common.user",
  },
};
