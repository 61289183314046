import { Box, CircularProgress, Grid, Link, Tooltip, Typography } from "@mui/material";
import { WButton, WIcon, WIconButton, WIconTypes, WTextField } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { getOrganisation, updateOrganisation, createOrganisation, Organisation } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";

import { UserContext } from "context/UserContext";
import { useForm } from "react-hook-form";
import { WTypography } from "components/WTypography";
import { useHistory } from "react-router-dom";

interface ProfileDetailsBodyProps {
  onSubmit: () => void;
}

const defaultCompanyDetails = {
  id: "",
  name: "",
  company_name: "",
  org_nr: "",
  tax_document: null,
  liability_insurance: null,
};

export const ProfileCompanyDetailsBody: FC<ProfileDetailsBodyProps> = ({ onSubmit }) => {
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { user } = useContext(UserContext);
  const [organisation, setOrganisation] = useState<Organisation>(defaultCompanyDetails);
  const [taxName, setTaxName] = useState("");
  const [liabilityName, setLiabilityName] = useState("");
  const uploadTaxDoc = useRef<HTMLInputElement | null>(null);
  const uploadLiabilityDoc = useRef<HTMLInputElement | null>(null);

  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<Organisation>({
    defaultValues: defaultCompanyDetails,
  });

  useEffect(() => {
    loadOrganisation();
  }, []);

  const loadOrganisation = () => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_ORGANISATION" });
    getOrganisation()
      .then((res) => {
        if (res.data.results.length) {
          reset({ ...res.data.results[0] });
          setOrganisation(res.data.results[0]);
          if (res.data.results[0].tax_document) {
            if (typeof res.data.results[0].tax_document === "string") {
              setTaxName(
                decodeURI(
                  res.data.results[0].tax_document.substring(
                    res.data.results[0].tax_document.lastIndexOf("/") + 1
                  )
                )
              );
            }
          }
          if (res.data.results[0].liability_insurance) {
            if (typeof res.data.results[0].liability_insurance === "string") {
              setLiabilityName(
                decodeURI(
                  res.data.results[0].liability_insurance.substring(
                    res.data.results[0].liability_insurance.lastIndexOf("/") + 1
                  )
                )
              );
            }
          }
        } else {
          setValue("name", `${user.first_name} ${user.last_name}`, { shouldDirty: true });
        }
      })
      .catch(() => {
        addMessage({ type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_ORGANISATION" });
      });
  };

  const dirtyValues = (
    dirtyFields: Record<string, unknown> | boolean,
    allValues: Organisation
  ): Partial<Organisation> => {
    if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
    );
  };

  const submitAndClose = (data: Organisation) => {
    const changedFields = dirtyValues(dirtyFields, data);
    if (Object.keys(changedFields).length) {
      submitForm(changedFields);
    } else {
      onSubmit();
    }
  };

  const submitForm = async (data: Partial<Organisation>) => {
    dispatchLoading({ type: "SET_LOADING", payload: "UPDATE_ORGANISATION" });
    if (!organisation.id) {
      createOrganisation(data)
        .then(() => {
          addMessage({ content: "success.organisation", type: "success" });
          onSubmit();
        })
        .catch(() => {
          addMessage({ content: "error.organisation", type: "error" });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "UPDATE_ORGANISATION" });
        });
    } else {
      updateOrganisation(data, organisation.id)
        .then(() => {
          addMessage({ content: "success.organisation", type: "success" });
          onSubmit();
        })
        .catch(() => {
          addMessage({ content: "error.organisation", type: "error" });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "UPDATE_ORGANISATION" });
        });
    }
  };

  const handleAddDoc = (event: React.ChangeEvent<HTMLInputElement>, target: string): void => {
    if (event.target.files?.length) {
      const myFile: File = event.target.files[0];

      switch (target) {
        case "tax_document":
          setValue("tax_document", myFile, { shouldDirty: true });
          setTaxName(myFile.name);
          return;
        case "liability_insurance":
          setValue("liability_insurance", myFile, { shouldDirty: true });
          setLiabilityName(myFile.name);
          return;

        default:
          break;
      }
    }
  };

  const handleClearTaxDoc = () => {
    setValue("tax_document", null, { shouldDirty: true });
    setTaxName("");
  };

  const handleClearLiabilityDoc = () => {
    setValue("liability_insurance", null, { shouldDirty: true });
    setLiabilityName("");
  };

  if (isLoading("GET_ORGANISATION")) {
    return (
      <Grid container justifyContent="space-around" marginTop="2rem">
        <CircularProgress color="success" />
      </Grid>
    );
  } else {
    return (
      <form onSubmit={handleSubmit(submitAndClose)}>
        <Grid container padding="1rem" gap="1rem" height="100%">
          <Grid container direction="column" gap="1rem">
            <WTextField
              label={localize("form.contactName")}
              error={!!errors.name}
              helperText={errors?.name?.message}
              {...register("name", { required: localize("form.requiredFieldError") })}
            />
            <WTextField
              label={localize("form.companyName")}
              error={!!errors.company_name}
              helperText={errors?.company_name?.message}
              {...register("company_name", { required: localize("form.requiredFieldError") })}
            />
            <Grid container wrap="nowrap" alignItems="center" gap="1.6rem">
              <WTextField
                label={localize("form.orgNumber")}
                error={!!errors.org_nr}
                helperText={errors?.org_nr?.message}
                {...register("org_nr", { required: localize("form.requiredFieldError") })}
              />
              <Tooltip
                arrow
                title={
                  <Box>
                    <Typography variant="body2">{localize("common.orgnrInfoDetails")}</Typography>
                    <Link
                      onClick={() => {
                        history.push("/faq");
                      }}
                      sx={{ cursor: "pointer" }}
                      fontStyle="italic"
                      variant="body2"
                      color="inherit"
                      fontWeight="bold"
                      underline="none"
                    >
                      {localize("common.faq")}
                    </Link>
                  </Box>
                }
                enterTouchDelay={200}
                disableFocusListener // touch still enabled
                PopperProps={{
                  popperOptions: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -8],
                        },
                      },
                    ],
                  },
                }}
              >
                <WIconButton
                  sx={{
                    height: "max-content",
                    width: "max-content",
                    padding: "4px",
                  }}
                >
                  <WIcon icon={WIconTypes.info} color="primary" />
                </WIconButton>
              </Tooltip>
            </Grid>
            <Grid container direction="column">
              <WTypography variant="h6">{localize("form.taxDocument")}</WTypography>

              <Grid container gap="1rem" wrap="nowrap" alignItems="center">
                <Grid container gap="1rem" alignItems="center">
                  <WTypography fontStyle="italic">
                    {taxName || localize("form.uploadTaxDocument")}
                  </WTypography>
                  {taxName && (
                    <WIconButton onClick={handleClearTaxDoc}>
                      <WIcon icon={WIconTypes.clear} />
                    </WIconButton>
                  )}
                </Grid>
                <WIconButton
                  sx={{ marginLeft: "auto" }}
                  onClick={() => uploadTaxDoc.current?.click()}
                >
                  <WIcon size="large" icon={WIconTypes.uploadCloud} />
                  <input
                    hidden
                    ref={uploadTaxDoc}
                    accept=".doc,.docx,.pdf"
                    type="file"
                    value=""
                    onChange={(e) => handleAddDoc(e, "tax_document")}
                  />
                </WIconButton>
              </Grid>
            </Grid>
            <Grid container direction="column">
              <WTypography variant="h6">{localize("form.liabilityInsurance")}</WTypography>

              <Grid container gap="1rem" wrap="nowrap" alignItems="center">
                <Grid container gap="1rem" alignItems="center">
                  <WTypography fontStyle="italic">
                    {liabilityName || localize("form.uploadLiabilityInsurance")}
                  </WTypography>
                  {liabilityName && (
                    <WIconButton onClick={handleClearLiabilityDoc}>
                      <WIcon icon={WIconTypes.clear} />
                    </WIconButton>
                  )}
                </Grid>
                <WIconButton
                  sx={{ marginLeft: "auto" }}
                  onClick={() => uploadLiabilityDoc.current?.click()}
                >
                  <WIcon size="large" icon={WIconTypes.uploadCloud} />
                  <input
                    hidden
                    ref={uploadLiabilityDoc}
                    accept=".doc,.docx,.pdf"
                    type="file"
                    value=""
                    onChange={(e) => handleAddDoc(e, "liability_insurance")}
                  />
                </WIconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          width="100%"
          padding="0 1rem"
          sx={{ backgroundColor: "background.default" }}
        >
          <WButton
            fullWidth
            variant="outlined"
            type="submit"
            sx={{ width: "max-content", marginLeft: "auto", marginBottom: ".5rem" }}
            startIcon={<WIcon icon={WIconTypes.check} />}
          >
            {localize("form.save")} {localize("common.companyDetails").toLowerCase()}
          </WButton>
        </Grid>
      </form>
    );
  }
};
