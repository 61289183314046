import React, { FC, useContext } from "react";
import { Grid, Tooltip } from "@mui/material";
import { colors, defaultStyles } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";
import { WTypography } from "components/WTypography";
import { BarChart } from "./BarChart";
import { WIcon, WIconTypes } from "components/WIcon";

interface GraphData {
  consult: number[];
  gig: number[];
  gigVerified: number;
  consultVerified: number;
}

export const CandidateTypeOfApplications: FC<GraphData> = ({
  consult,
  gig,
  gigVerified,
  consultVerified,
}) => {
  const { localize } = useContext(LocaleContext);

  const labels = [localize("statisticView.loggedIn"), localize("statisticView.notLoggedIn")];
  const totalConsultApplications = consult[0] + consult[1];
  const totalGigApplications = gig[0] + gig[1];

  const yMax = Math.max.apply(null, consult.concat(gig));

  return (
    <Grid
      container
      width="100%"
      minWidth="320px"
      flexDirection="column"
      sx={{
        padding: "18px",
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadius,
        boxShadow: defaultStyles.cardShadow,
      }}
    >
      <Grid container justifyContent="space-between" marginBottom="30px">
        <WTypography variant="h5" fontWeight="bold">
          {localize("statisticView.typesOfApplications")}
        </WTypography>
        <Tooltip title={localize("statisticView.typesOfApplicationsTooltip")} placement="left">
          <Grid>
            <WIcon icon={WIconTypes.info} />
          </Grid>
        </Tooltip>
      </Grid>

      <Grid container justifyContent="space-evenly" paddingBottom="3rem" gap="40px 10px">
        <BarChart
          title="gigApplication"
          tooltipLabel={"total"}
          labels={labels}
          data={gig}
          stackedData={[gig[0], gigVerified - gig[0]]}
          total={totalGigApplications}
          yMax={yMax}
        />

        <BarChart
          title="consultApplication"
          tooltipLabel="total"
          labels={labels}
          data={consult}
          stackedData={[consult[0], consultVerified - consult[0]]}
          total={totalConsultApplications}
          yMax={yMax}
        />
      </Grid>
    </Grid>
  );
};
