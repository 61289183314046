import React, { FC, ReactNode, useContext } from "react";
import { ConditionalWrapper } from "utils/ConditionalWrapper";
import { useTheme } from "@mui/material";
import { BreakpointsContext } from "context/BreakpointContext";

interface FloatingIndicatorWrapperProps {
  condition: boolean;
  children: ReactNode;
  variant?: "icon" | "relativeTop";
}
export const FloatingIndicatorWrapper: FC<FloatingIndicatorWrapperProps> = ({
  children,
  condition,
  variant = "icon",
}) => {
  const { isDesktop } = useContext(BreakpointsContext);

  const theme = useTheme();
  const size = isDesktop ? 7 : 6;
  const styles =
    variant === "icon"
      ? {
          top: "-5px",
          right: "-5px",
        }
      : {
          top: "-10px",
        };
  return (
    <ConditionalWrapper
      condition={condition}
      wrapper={(children) => (
        <div style={{ position: "relative" }}>
          {children}
          <svg style={{ position: "absolute", ...styles }} width={size * 2} height={size * 2}>
            <circle r={size} cx={size} cy={size} strokeWidth="0" fill={theme.palette.error.main} />
          </svg>
        </div>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};
