import { Grid } from "@mui/material";
import { Job } from "adapters";
import { WIconButton } from "components/styledComponents/WIconButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { WTypography } from "components/WTypography";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { colors } from "styles/colors";
import { capitalizeFirstLetter } from "utils/generalUtils";
import { prettifyDate, WDateFormats } from "utils/PrettifyDate";

interface Props {
  job: Job;
  handleClick: (id: string) => void;
  applicationVisible: string | boolean;
  id: string;
  isInternal: boolean;
  created: string;
}

export const CandidateJobApplicationHeader: FC<Props> = ({
  job,
  handleClick,
  applicationVisible,
  id,
  isInternal,
  created,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  return (
    <Grid container gap={1} justifyContent="space-between" wrap="nowrap">
      <Grid
        item
        sx={{
          display: "flex",
          minHeight: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={
            job?.pictures?.length
              ? job.pictures[0].thumb
              : `${process.env.PUBLIC_URL}/assets/logo/logo_black.png`
          }
          alt="Thumbnail image"
          width={isDesktop ? "63px" : "31px"}
          height={isDesktop ? "42px" : "21px"}
          style={{ objectFit: "contain" }}
        />
        <WTypography variant="body1" fontWeight="bold" marginLeft="8px">
          {capitalizeFirstLetter(job.title)}
        </WTypography>
      </Grid>

      <Grid item display="flex" alignItems="center">
        {isInternal ? (
          <WTypography variant="caption" color={colors.error}>
            {localize("common.internal")}
          </WTypography>
        ) : (
          <WTypography variant="caption" color={colors.darkGrey}>
            {localize("candidateModal.applied")}{" "}
            {prettifyDate(created, { dateFormat: WDateFormats.dayMonthYear })}
          </WTypography>
        )}
        <WIconButton onClick={() => handleClick(id)}>
          <WIcon icon={applicationVisible === id ? WIconTypes.arrowUp : WIconTypes.arrowDown} />
        </WIconButton>
      </Grid>
    </Grid>
  );
};
