import { AxiosResponse } from "axios";
import { PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { API } from "./api";

export type PushNotificationActions = "GET_ALL_NOTIFICATIONS";

export interface PushNotificationOverview {
  notification_type: PUSH_NOTIFICATION_TYPES;
  total: number;
}

export const getUserNotifications = (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PushNotificationOverview[]>> => {
  return API.get("/notifications/user/", { params: params });
};
