import { Grid, Skeleton, Tooltip } from "@mui/material";
import React, { FC, HTMLAttributes, useContext, useEffect, useState } from "react";
import { colors, defaultStyles } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";
import { format } from "date-fns";
import { WTextField } from "components/styledComponents/WTextField";
import { WButton } from "components/styledComponents/WButton";
import { WDateFormats } from "utils/PrettifyDate";
import { WTypography } from "components/WTypography";
import { AgentNote, Job, JobApplication, getJob, getUserApplications } from "adapters";
import { TaskMenuButton } from "components/Tasks/TaskMenuButton";
import { TaskTag } from "components/Tasks/TaskTag";
import { AddTagButtons } from "components/Tasks/AddTagButtons";
import { SearchDropdown } from "components/SearchDropdown";
import { usePagination } from "hooks/usePagination";
import { WIconTypes } from "components/WIcon";
import { User } from "reducers/UserReducer";
import { getNameOrEmail } from "utils/generalUtils";
import { AvatarItem } from "components/AvatarItem";
import { UserContext } from "context/UserContext";
import { LoaderContext } from "context/LoaderContext";

interface Props extends HTMLAttributes<HTMLDivElement> {
  note: AgentNote;
  isEditing: boolean;
  onStartEdit: () => void;
  onEdit: (updatedData: Partial<AgentNote>) => void;
  onFinishEdit: (doSave: boolean, finishedData?: AgentNote) => void;
  onDelete: () => void;
}

const MAX_TITLE_LENGTH = 100;
const MAX_TEXT_LENGTH = 4098;

export const NoteCard: FC<Props> = ({
  note,
  isEditing,
  onStartEdit,
  onEdit,
  onFinishEdit,
  onDelete,
  ...restProps
}) => {
  const { user } = useContext(UserContext);
  const { localize } = useContext(LocaleContext);
  const { dispatchLoading } = useContext(LoaderContext);
  const [addingTag, setAddingTag] = useState("");
  const [job, setJob] = useState<Job | null>(null);

  const { id, created, updated, title, note: text, user: candidateId } = note;
  const author = note.author as User;
  const wasUpdated = updated.substring(0, 19) !== created.substring(0, 19);
  const isAuthor = author.id === user.id;
  const isRatingCard = text.startsWith("Rating: ") && !title && !note.job;
  const disableMenu = !isAuthor || isRatingCard;

  useEffect(() => {
    if (note.job === null || note.job == "") {
      return;
    }

    (async () => {
      dispatchLoading({ type: "SET_LOADING", payload: "GET_JOB" });
      const j = await getJob(note.job ?? "");
      setJob(j.data);
      dispatchLoading({ type: "STOP_LOADING", payload: "GET_JOB" });
    })();
  }, [note.job]);

  if (!isEditing) {
    return (
      <Grid
        container
        direction="column"
        flexWrap="nowrap"
        bgcolor={colors.white}
        padding="16px"
        borderRadius={defaultStyles.borderRadius}
        width="100%"
        gap="5px"
        id={id}
        {...restProps}
      >
        <Grid item container alignItems="center" wrap="nowrap" gap="5px">
          {!isRatingCard ? (
            <AvatarItem
              imageSrc={author.profile_picture}
              firstName={author.first_name}
              lastName={author.last_name}
              size="tiny"
            />
          ) : null}
          <Grid item container direction="column" gap="0" marginRight="auto">
            <WTypography
              lineClamps={1}
              variant="body2"
              display="flex"
              fontWeight={isAuthor ? "bold" : "regular"}
            >
              {getNameOrEmail(author)}
            </WTypography>

            <Tooltip
              title={
                <>
                  {wasUpdated ? (
                    <WTypography variant="body2" fontSize="0.8rem">
                      * {localize("candidateModal.updated")}{" "}
                      {format(new Date(updated), WDateFormats.dateTime)}
                    </WTypography>
                  ) : null}
                  <WTypography variant="body2" fontSize="0.8rem">
                    {localize("candidateModal.created")}{" "}
                    {format(new Date(created), WDateFormats.dateTime)}
                  </WTypography>
                </>
              }
              arrow
              placement="top"
              enterDelay={300}
            >
              <div style={{ width: "fit-content" }}>
                <WTypography
                  variant="body2"
                  fontSize="0.8rem"
                  lineHeight="0.8rem"
                  color={colors.textDarkLighter}
                  width="fit-content"
                >
                  {format(new Date(updated), WDateFormats.date)} {wasUpdated ? " *" : null}
                </WTypography>
              </div>
            </Tooltip>
          </Grid>
          {!disableMenu ? <TaskMenuButton onStartEdit={onStartEdit} onDelete={onDelete} /> : null}
        </Grid>
        {title ? (
          <Grid item>
            <WTypography
              variant="body1"
              fontSize="14px"
              fontWeight="bold"
              marginRight="auto"
              lineClamps={5}
            >
              {title}
            </WTypography>
          </Grid>
        ) : null}
        <Grid item>
          <WTypography
            variant="body2"
            fontSize="14px"
            color={colors.darkGrey}
            lineClamps={100}
            whiteSpace="pre-line"
          >
            {text}
          </WTypography>
        </Grid>

        <JobTag jobId={note.job} job={job} />
      </Grid>
    );
  }

  const titleValid = (title?.length ?? 0) <= MAX_TITLE_LENGTH;
  const textValid = text?.length > 0 && (text?.length ?? 0) <= MAX_TEXT_LENGTH;

  return (
    <Grid
      container
      direction="column"
      flexWrap="nowrap"
      bgcolor={colors.white}
      padding="16px"
      borderRadius={defaultStyles.borderRadius}
      width="100%"
      gap="15px"
      id={id}
      {...restProps}
    >
      <Grid item>
        <WTextField
          fullWidth
          label={localize("candidateModal.noteTitle")}
          value={title ?? ""}
          onChange={(e) => onEdit({ title: e.target.value })}
          error={title ? title.length > MAX_TITLE_LENGTH : false}
          helperText={
            title && title.length > MAX_TITLE_LENGTH
              ? localize("form.maxTextLength", {
                  limit: MAX_TITLE_LENGTH.toString(),
                  over: (title.length - MAX_TITLE_LENGTH).toString(),
                })
              : ""
          }
        />
      </Grid>
      <Grid item>
        <WTextField
          fullWidth
          required
          label={localize("candidateModal.noteText")}
          value={text}
          onChange={(e) => onEdit({ note: e.target.value })}
          multiline
          minRows={2}
          maxRows={10}
          error={text.length > MAX_TEXT_LENGTH}
          helperText={
            text && !textValid
              ? localize("form.maxTextLength", {
                  limit: MAX_TEXT_LENGTH.toString(),
                  over: (text.length - MAX_TEXT_LENGTH).toString(),
                })
              : ""
          }
        />
      </Grid>

      {job ? (
        <Grid item>
          <TaskTag
            text={job.title}
            type="job_applications"
            isEditing
            onDelete={() => {
              onEdit({ job: null });
              setJob(null);
            }}
          />
        </Grid>
      ) : null}

      <AddJobTag
        candidateId={candidateId}
        addingTag={addingTag}
        setAddingTag={setAddingTag}
        onEdit={onEdit}
      />

      {addingTag === "" ? (
        <>
          {!job ? <AddTagButtons types={["applications"]} setAddingTag={setAddingTag} /> : null}
          <Grid item container justifyContent="space-between">
            <WButton key="cancelEdit" onClick={() => onFinishEdit(false)}>
              {localize("common.cancel")}{" "}
            </WButton>
            <WButton
              variant="contained"
              color="primary"
              disabled={!titleValid || !textValid}
              onClick={() => onFinishEdit(true)}
            >
              {localize("form.save")}
            </WButton>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

interface JobTagProps {
  jobId: string | null;
  job: Job | null;
}
const JobTag: FC<JobTagProps> = ({ jobId, job }) => {
  const { isLoading } = useContext(LoaderContext);

  if (!jobId) {
    return null;
  }

  if (isLoading("GET_JOB") || !job) {
    return (
      <Skeleton variant="rectangular" width="100%" height="22px" sx={{ borderRadius: "100px" }} />
    );
  }

  return <TaskTag text={job.title} type="job_applications" />;
};

interface AddJobTagProps {
  candidateId: string;
  addingTag: string;
  setAddingTag: (value: string) => void;
  onEdit: (updatedData: Partial<AgentNote>) => void;
}
const AddJobTag: FC<AddJobTagProps> = ({ candidateId, addingTag, setAddingTag, onEdit }) => {
  const { localize } = useContext(LocaleContext);

  const {
    handleSearch: handleApplicationSearch,
    results: searchedApplications,
    setLastElementRef: lastApplicationsRef,
  } = usePagination<JobApplication>(
    (params) => getUserApplications(candidateId, params),
    "error.generalError",
    "GET_PAGINATION_RESULTS",
    { page_size: 7, ordering: "-created" },
    true
  );

  useEffect(() => {
    if (addingTag === "") {
      return;
    }
    handleApplicationSearch({});
  }, [addingTag]);

  if (addingTag === "") {
    return null;
  }

  return (
    <>
      <Grid item>
        <SearchDropdown
          items={searchedApplications
            .map(({ job }) => job)
            .map(({ id, title }) => ({ value: id, icon: WIconTypes.application, text: title }))}
          onSelect={(value) => {
            const jobs = searchedApplications.map(({ job }) => job);
            const job = jobs.find((j) => j.id === value);
            if (!job) {
              return;
            }
            onEdit({ job: job.id });
            setAddingTag("");
          }}
          onSearch={async (text) => {
            if (!text) {
              return;
            }
            return handleApplicationSearch({ search: text });
          }}
          setLastElementRef={lastApplicationsRef}
          loaderAction="GET_PAGINATION_RESULTS"
          label={localize("candidateModal.searchApplications")}
        />
      </Grid>

      <Grid item>
        <WButton key="cancelAddTag" onClick={() => setAddingTag("")}>
          {localize("tasks.cancelAddTag")}
        </WButton>
      </Grid>
    </>
  );
};
