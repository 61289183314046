import { Grid, Link, Skeleton } from "@mui/material";
import { getJobApplication, JobApplication } from "adapters";
import { DataChip } from "components/DataChip";
import { CandidateReferences } from "components/modals/CandidateModal/CandidateReferences";
import { CvCard } from "components/modals/Profile/ProfileCV/CvCard";
import { WIconOutlinedButton } from "components/styledComponents/WIconButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { WTypography } from "components/WTypography";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { colors, defaultStyles } from "styles/colors";
import { prettifyDate, WDateFormats } from "utils/PrettifyDate";
import Grow from "@mui/material/Grow";
import {
  InternalJobApplication,
  deleteInternalJobApplication,
  getInternalJobApplication,
  sendJobProposal,
} from "adapters/InternalJobApplicationAdapter";
import { WButton } from "components/styledComponents/WButton";

interface Props {
  applicationId: string;
  onUpdateInternalApplication?: (action: "delete" | "send_proposal") => void;
}

export const CandidateSpecificApplication: FC<Props> = ({
  applicationId,
  onUpdateInternalApplication,
}) => {
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { hideModal } = useContext(ModalContext);

  const history = useHistory();

  const [applicationData, setApplicationData] = useState<JobApplication | null>(null);

  const textColor = colors.darkGrey;

  useEffect(() => {
    (onUpdateInternalApplication
      ? getInternalJobApplication(applicationId)
      : getJobApplication(applicationId)
    )
      .then((res) => {
        setApplicationData(res.data as JobApplication);
      })
      .catch(() => {
        addMessage({ type: "error" });
      });
  }, [applicationId]);

  const updateReferences = (referralId: string, newComment: string) => {
    if (!applicationData) {
      return;
    }

    const candidateReferral = applicationData.referrals.find((ref) => ref.id === referralId);

    if (candidateReferral) {
      candidateReferral.agent_comment = newComment;
    }
  };

  if (!applicationData) {
    return (
      <Grid container>
        <Skeleton
          width="100%"
          height={onUpdateInternalApplication ? "40px" : "300px"}
          variant="rectangular"
          sx={{ borderRadius: defaultStyles.borderRadius, marginBottom: "1px" }}
        />
      </Grid>
    );
  }

  const {
    cover_letter,
    cv,
    skills,
    availability_date,
    price,
    referrals,
    first_name,
    last_name,
    email,
    phone,
    job,
    id,
  } = applicationData;

  if (onUpdateInternalApplication) {
    return (
      <Grid container direction="column" gap={2}>
        <Grow key={id} in={true} {...{ timeout: 1000 }}>
          <Grid container justifyContent="space-between">
            {(applicationData as unknown as InternalJobApplication).is_proposal_sent ? (
              <WTypography>{localize("kanbanView.proposalSent")}</WTypography>
            ) : (
              <>
                <Grid item>
                  <WButton
                    variant="outlined"
                    onClick={async () => {
                      await sendJobProposal(id);
                      setApplicationData({ ...applicationData, is_proposal_sent: true });
                      onUpdateInternalApplication("send_proposal");
                    }}
                  >
                    {localize("kanbanView.sendJobProposal")}
                  </WButton>
                </Grid>
                <Grid item>
                  <WButton
                    onClick={async () => {
                      await deleteInternalJobApplication(id);
                      onUpdateInternalApplication("delete");
                    }}
                  >
                    {localize("common.delete")}
                  </WButton>
                </Grid>
              </>
            )}
            <Grid item display="flex" alignItems="center">
              <Link
                variant="body2"
                sx={{ ":hover": { cursor: "pointer" } }}
                onClick={() => {
                  hideModal();
                  history.push(`/kanban/jobs/${job.id}`);
                }}
              >
                {localize("candidateModal.goToAssignment")}
              </Link>
              <WIcon size="small" icon={WIconTypes.arrowRight} />
            </Grid>
          </Grid>
        </Grow>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" gap={2}>
      <Grow key={id} in={true} {...{ timeout: 1000 }}>
        <Grid container direction="column" key={id}>
          <Grid container justifyContent="space-between">
            <WTypography variant="body1" fontWeight="bold">
              {localize("candidateModal.details")}
            </WTypography>
            <Grid item display="flex" alignItems="center">
              <Link
                variant="body2"
                sx={{ ":hover": { cursor: "pointer" } }}
                onClick={() => {
                  hideModal();
                  history.push(`/kanban/jobs/${job.id}`);
                }}
              >
                {localize("candidateModal.goToAssignment")}
              </Link>
              <WIcon size="small" icon={WIconTypes.arrowRight} />
            </Grid>
          </Grid>
          <WTypography variant="body2" color={textColor}>
            {first_name} {last_name}
          </WTypography>
          <Grid item color={textColor} gap="3px" alignItems="center" display="flex">
            <WIcon icon={WIconTypes.mail} size="tiny" customColor={textColor} />
            <WTypography variant="caption">{email}</WTypography>
          </Grid>
          {phone ? (
            <Grid item gap="3px" color={textColor} alignItems="center" display="flex">
              <WIcon icon={WIconTypes.phone} size="tiny" customColor={textColor} />
              <WTypography variant="caption">{phone}</WTypography>
            </Grid>
          ) : null}
        </Grid>
      </Grow>

      <Grow in={true} {...{ timeout: 1200 }}>
        <Grid item>
          <WTypography variant="body1" fontWeight="bold">
            {localize("candidateModal.motivation")}
          </WTypography>
          <WTypography
            variant="body2"
            color={textColor}
            lineClamps={30}
            fontStyle={cover_letter ? "normal" : "italic"}
          >
            {cover_letter ?? localize("candidateModal.none")}
          </WTypography>
        </Grid>
      </Grow>

      <Grow in={true} {...{ timeout: 1400 }}>
        <Grid item>
          <WTypography variant="body1" fontWeight="bold" marginBottom="5px">
            {localize("candidateModal.cv")}
          </WTypography>
          {cv ? (
            <CvCard
              key={cv.id}
              cv={cv}
              isLast={true}
              css={{
                backgroundColor: "transparent",
                iconSize: "30px",
                textColor: colors.darkGrey,
                subtitleSize: "caption",
                titleSize: "body2",
                border: "0",
                padding: "5px",
              }}
              icons={
                <Link href={cv.user_cv} target="_blank" download>
                  <WIconOutlinedButton size="small">
                    <WIcon icon={WIconTypes.download} size="small" />
                  </WIconOutlinedButton>
                </Link>
              }
            />
          ) : (
            <WTypography fontStyle="italic" variant="body2" color={textColor}>
              {localize("candidateModal.none")}
            </WTypography>
          )}
        </Grid>
      </Grow>

      <Grow in={true} {...{ timeout: 1600 }}>
        <Grid item>
          <WTypography variant="body1" fontWeight="bold">
            {localize("candidateModal.competencies")}
          </WTypography>
          <Grid container gap={2}>
            {skills.length > 0 ? (
              skills.map((skill) => <DataChip key={skill.id} chipLabel={skill.name} />)
            ) : (
              <WTypography variant="body2" fontStyle="italic" color={textColor}>
                {localize("candidateModal.none")}
              </WTypography>
            )}
          </Grid>
        </Grid>
      </Grow>

      <Grow in={true} {...{ timeout: 1800 }}>
        <Grid item>
          <WTypography variant="body1" fontWeight="bold">
            {localize("candidateModal.availableFrom")}
          </WTypography>
          <WTypography
            variant="body2"
            fontStyle={availability_date ? "normal" : "italic"}
            color={textColor}
          >
            {availability_date
              ? prettifyDate(availability_date, { dateFormat: WDateFormats.dayMonthYear })
              : localize("candidateModal.none")}
          </WTypography>
        </Grid>
      </Grow>
      <Grow in={true} {...{ timeout: 2000 }}>
        <Grid item>
          <WTypography variant="body1" fontWeight="bold">
            {localize("candidateModal.hourlyRate")}
          </WTypography>
          <WTypography variant="body2" fontStyle={price ? "normal" : "italic"} color={textColor}>
            {price || localize("candidateModal.none")}
          </WTypography>
        </Grid>
      </Grow>

      <Grow in={true} {...{ timeout: 2200 }}>
        <Grid item>
          <WTypography variant="body1" fontWeight="bold">
            {localize("candidateModal.references")}
          </WTypography>
          <Grid item>
            {referrals.length > 0 ? (
              <CandidateReferences references={referrals} updateReferences={updateReferences} />
            ) : (
              <WTypography variant="body2" fontStyle="italic" color={textColor}>
                {localize("candidateModal.none")}
              </WTypography>
            )}
          </Grid>
        </Grid>
      </Grow>
    </Grid>
  );
};
