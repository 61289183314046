import { Grid, Collapse, Box } from "@mui/material";
import { addUserCV } from "adapters";
import { WButton, WIcon, WIconTypes } from "components";
import { ProfileSectionCard } from "components/ProfileView/ProfileSectionCard";
import { BreakpointsContext } from "context/BreakpointContext";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext, useState, useRef } from "react";
import { PROFILE_STEPS } from "views/Enums";
import { CvList } from "./CvList";

export const CvSection: FC = () => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { showModal } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { user, calculateProfileProgress, dispatch } = useContext(UserContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const [isExpanded, setExpand] = useState(false);
  const uploadFile = useRef<HTMLInputElement | null>(null);
  const maxFilenameLength = 50;

  const handleAddCV = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length) {
      let alias = event.target.files[0].name;
      // remove extension.
      alias = alias.replace(/\.[^/.]+$/, "");
      if (alias.length > maxFilenameLength) {
        alias = alias.substring(0, maxFilenameLength - 1);
      }
      dispatchLoading({ type: "SET_LOADING", payload: "ADD_CV" });
      addUserCV(event.target.files[0], alias)
        .then((res) => {
          calculateProfileProgress(true, PROFILE_STEPS.cv);
          addMessage({ content: "success.fileUploaded", type: "success", duration: 1000 });
          dispatch({ type: "UPDATE_USER", user: { cv: [...user.cv, res.data] } });
        })
        .catch(() => {
          addMessage({ content: "error.fileUploaded", type: "error" });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "ADD_CV" });
        });
    }
  };

  if (isDesktop) {
    return (
      <Box>
        <ProfileSectionCard
          title={"profileTitleCv"}
          isExpanded={isExpanded}
          subTitle={localize("profileSections.profileSubTitleCv")}
          onClick={() => setExpand(!isExpanded)}
          infoToolTip={localize("profileSections.cvDialogInfo")}
          icons={
            <Grid container alignItems="center" gap="1rem">
              <WButton
                variant="outlined"
                startIcon={<WIcon icon={WIconTypes.uploadCloud} />}
                onClick={(event) => {
                  event.stopPropagation();
                  uploadFile.current?.click();
                }}
              >
                {localize("profileView.addCV")}
                <input
                  hidden
                  ref={uploadFile}
                  accept=".doc,.docx,.pdf"
                  type="file"
                  value=""
                  onChange={(e) => {
                    handleAddCV(e);
                  }}
                />
              </WButton>
              <WIcon icon={isExpanded ? WIconTypes.arrowUp : WIconTypes.arrowDown} />
            </Grid>
          }
        />
        <Collapse in={isExpanded} unmountOnExit>
          <CvList />
        </Collapse>
      </Box>
    );
  }

  return (
    <ProfileSectionCard
      title={"profileTitleCv"}
      subTitle={localize("profileSections.profileSubTitleCv")}
      onClick={() => showModal("PROFILE_CV_MODAL")}
    />
  );
};
