import { Grid } from "@mui/material";
import Grow from "@mui/material/Grow";
import { Job, JobApplication } from "adapters";
import { InternalJobApplication } from "adapters/InternalJobApplicationAdapter";
import { CandidateJobApplicationHeader } from "components/modals/CandidateModal/CandidateJobApplicationHeader";
import { CandidateSpecificApplication } from "components/modals/CandidateModal/CandidateSpecificApplication";
import React, { FC, useState } from "react";
import { colors, defaultStyles } from "styles/colors";

interface Props {
  applications: (JobApplication | InternalJobApplication)[];
  selectedApplication?: {
    id?: string;
    job?: Job;
    isInternal?: boolean;
    created?: string;
  };
  setLastElementRef: (node: Element | null) => void;
  onUpdateInternalApplication?: (action: "delete" | "send_proposal") => void;
}

export const CandidateJobApplications: FC<Props> = ({
  applications,
  selectedApplication,
  setLastElementRef,
  onUpdateInternalApplication,
}) => {
  const [applicationVisible, setApplicationVisible] = useState<boolean | string>(
    selectedApplication?.id ?? false
  );

  const handleClick = (id: string) => {
    if (id === applicationVisible) {
      setApplicationVisible(false);
    } else {
      setApplicationVisible(id);
    }
  };

  return (
    <Grid container gap={2}>
      {selectedApplication?.id && selectedApplication?.job ? (
        <Grow in={true} {...{ timeout: 1000 }}>
          <Grid
            container
            gap={1}
            sx={{
              backgroundColor: selectedApplication.isInternal
                ? colors.errorLight
                : colors.mainWithOpacity,
              borderRadius: defaultStyles.borderRadius,
              padding: "12px",
            }}
          >
            <CandidateJobApplicationHeader
              id={selectedApplication.id}
              applicationVisible={applicationVisible}
              job={selectedApplication.job}
              handleClick={handleClick}
              isInternal={selectedApplication.isInternal || false}
              created={selectedApplication.created || ""}
            />

            {applicationVisible === selectedApplication.id ? (
              <CandidateSpecificApplication
                applicationId={selectedApplication.id}
                onUpdateInternalApplication={
                  selectedApplication?.isInternal ? onUpdateInternalApplication : undefined
                }
              />
            ) : null}
          </Grid>
        </Grow>
      ) : null}

      {applications.map(({ id, job, is_proposal_sent, created }, index) => {
        if (selectedApplication?.id === id) {
          return;
        }

        const isInternal = typeof is_proposal_sent !== "undefined";
        created = typeof created === "undefined" ? "" : created;

        return (
          <Grow
            ref={index === applications.length - 1 ? setLastElementRef : undefined}
            key={id}
            in={true}
            {...{ timeout: 1000 + index * 200 }}
          >
            <Grid
              container
              key={id}
              gap={1}
              sx={{
                backgroundColor: isInternal ? colors.errorLight : colors.mainWithOpacity,
                borderRadius: defaultStyles.borderRadius,
                padding: "12px",
              }}
            >
              <CandidateJobApplicationHeader
                id={id}
                applicationVisible={applicationVisible}
                job={job}
                handleClick={handleClick}
                isInternal={isInternal}
                created={created}
              />
              {applicationVisible === id ? (
                <CandidateSpecificApplication
                  applicationId={id}
                  onUpdateInternalApplication={isInternal ? onUpdateInternalApplication : undefined}
                />
              ) : null}
            </Grid>
          </Grow>
        );
      })}
    </Grid>
  );
};
