import { Collapse, Grid } from "@mui/material";
import { AdvancedSearch, DynamicHeader, Navbar, WButton, WIcon, WIconTypes } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useState } from "react";
import { FiltersDialog, JobFilters } from "components/dialogs/FiltersDialog";
import { JobQueryParams } from "adapters";
import { BreakpointsContext } from "context/BreakpointContext";

interface JobListHeaderProps {
  searchCallback: (search: string) => void;
  filterCallback: (filters: JobFilters) => void;
  currentParams: Partial<JobQueryParams>;
}

export const JobListHeader: FC<JobListHeaderProps> = ({
  searchCallback,
  filterCallback,
  currentParams,
}) => {
  const { isDesktop } = useContext(BreakpointsContext);

  const { localize } = useContext(LocaleContext);

  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);
  const [showFilterButton, setShowFilterButton] = useState(false);

  return (
    <DynamicHeader forceHide={showFilterButton && !isDesktop} stopDynamicBehavior={isDesktop}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          maxWidth: {
            xs: "100%",
          },
        }}
      >
        <Navbar title={localize("jobListView.headerMessage")} />
      </Grid>
      <Grid
        item
        xs={showFilterButton ? 9 : 12}
        sx={{
          maxWidth: {
            xs: "100%",
            md: "530px",
          },
        }}
        paddingTop="1rem"
        paddingBottom={showFilterButton ? "1rem" : "0"}
      >
        <AdvancedSearch
          onSearch={(search) => searchCallback(search)}
          currentSearch={currentParams.search}
          onFocus={() => setShowFilterButton(true)}
        />
      </Grid>
      {showFilterButton && !isDesktop && (
        <Grid
          container
          item
          marginLeft="auto"
          marginTop="auto"
          xs={2}
          alignItems="center"
          justifyContent="center"
          paddingBottom="1rem"
        >
          <WButton variant="contained" onClick={() => setShowFilterButton(false)}>
            {localize("common.close")}
          </WButton>
        </Grid>
      )}
      <Collapse in={showFilterButton && !isDesktop} timeout={0}>
        <Grid
          container
          margin="-1rem"
          padding="1rem 2rem"
          width="100vw"
          bgcolor="background.default"
          boxShadow={4}
        >
          <WButton
            fullWidth
            variant="outlined"
            startIcon={<WIcon rotationDegrees="90" icon={WIconTypes.sliders} />}
            onClick={() => setOpenFiltersDialog(true)}
          >
            {localize("searchbar.filterButton")}
          </WButton>
          <FiltersDialog
            open={openFiltersDialog}
            handleClose={() => setOpenFiltersDialog(false)}
            filterCallback={filterCallback}
            currentFilters={currentParams}
          />
        </Grid>
      </Collapse>
    </DynamicHeader>
  );
};
