import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Divider,
} from "@mui/material";
import { CreateJobApplicationForm, CV, JobApplication, Referral, Skill } from "adapters";
import { WButton, WDatePicker, WIcon, WIconTypes, WTextField } from "components";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UserContext } from "context/UserContext";
import { ConfirmDialog } from "components/dialogs/ConfirmDialog";
import { validateEmail } from "./validators";
import { WDateFormats } from "utils/PrettifyDate";
import { INTEGRITY_LINK } from "views/Enums";
import { format } from "date-fns";
import { JobApplicationCVAccordion } from "./JobApplicationCVAccordion";
import { JobApplicationReferralAccordion } from "./JobApplicationReferralAccordion";
import { JobApplicationSkillComponent } from "./JobApplicationSkillComponent";
import { ModalContext } from "context/ModalContext";

const MIN_REQUIRED_SKILLS = 2;

export interface JobApplicationState {
  email: string;
  phone: string;
  cover_letter: string;
  cv?: string | File;
  cv_alias?: string;
  referrals: Referral[];
  skills: Skill[];
  first_name: string;
  last_name: string;
  availability_date: Date | null;
  price: string;
}
interface JobApplicationFormProps {
  readOnly?: boolean;
  prefillFields?: Partial<JobApplication>;
  canSaveDraft?: boolean;
  onSubmit: (data: CreateJobApplicationForm) => Promise<boolean | undefined>;
  jobSkills?: Skill[];
  jobId: string;
  sx?: Record<string, unknown>;
}

export const JobApplicationForm: FC<JobApplicationFormProps> = ({
  prefillFields,
  readOnly,
  onSubmit,
  canSaveDraft,
  jobSkills,
  jobId,
  sx,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { user, isAuthenticated } = useContext(UserContext);
  const { showDialog, hideDialog, hideModal } = useContext(ModalContext);

  const [referralList, setReferralList] = useState<Referral[]>(user.referrals || []);
  const [cvList, setCvList] = useState<CV[]>(user.cv || []);
  const [confirmSubmitDialog, openConfirmSubmitDialog] = useState(false);
  const [integrityAccepted, setIntegrityAccepted] = useState(false);
  const requiredSkills = jobSkills ? Math.min(jobSkills.length, MIN_REQUIRED_SKILLS) : 0;

  const { register, formState, setValue, watch, handleSubmit, trigger, setError, clearErrors } =
    useForm<JobApplicationState>({
      defaultValues: {
        email: "",
        phone: "",
        price: "",
        cover_letter: "",
        referrals: [],
        skills: [],
        first_name: "",
        last_name: "",
        availability_date: null,
      },
      mode: "onChange",
    });

  const { errors, isValid } = formState;
  const selectedSkills = watch("skills");
  const selectedReferrals = watch("referrals");
  const selectedCV = watch("cv");

  // Load in lists if isAuthenticated changes
  useEffect(() => {
    setReferralList(user.referrals);
    setCvList(user.cv);
  }, [isAuthenticated]);

  // Check integrity button if anonymous user
  const submitDisabled = isAuthenticated ? !isValid : !isValid || !integrityAccepted;

  useEffect(() => {
    if (prefillFields) {
      setValue("email", prefillFields.email || "");
      setValue("phone", prefillFields.phone || "");
      setValue("first_name", prefillFields.first_name || "");
      setValue("last_name", prefillFields.last_name || "");
      if (prefillFields.cv) {
        setValue("cv", prefillFields.cv.id);
        const checkForDuplicateCV = cvList.some((cv) => cv.id === prefillFields.cv?.id);
        if (!checkForDuplicateCV) {
          setCvList([...cvList, prefillFields?.cv]);
        }
      }
      setValue("referrals", prefillFields.referrals || []);
      setValue("skills", prefillFields.skills || []);
      setValue("cover_letter", prefillFields.cover_letter || "");
      setValue(
        "availability_date",
        prefillFields.availability_date ? new Date(prefillFields.availability_date) : null
      );
      setValue("price", prefillFields.price || "");
      trigger();
    }
  }, [prefillFields]);

  const handleSkillsSubmit = (skills: Skill[]) => {
    setValue("skills", skills);
  };

  const handleReferralSubmit = (data: Referral) => {
    setValue("referrals", [...selectedReferrals, data]);
  };

  const handleOpenConfirmDialog = () => {
    trigger().then((success) => {
      if (success) {
        openConfirmSubmitDialog(true);
      }
    });
  };

  const hasRequiredSkills = () => {
    if (requiredSkills - selectedSkills.length <= 0) {
      return true;
    }

    return false;
  };

  const parseSubmit = async (data: JobApplicationState, isDraft: boolean) => {
    const parsedFormState: CreateJobApplicationForm = {
      ...data,
      availability_date: data.availability_date
        ? format(data.availability_date, WDateFormats.date)
        : undefined,
      job: jobId,
      is_draft: isDraft,
    };

    // Sanitize empty values
    Object.keys(parsedFormState).forEach((field) => {
      if (
        parsedFormState[field] === undefined ||
        (Array.isArray(parsedFormState[field]) && parsedFormState[field].length === 0)
      ) {
        delete parsedFormState[field];
      }
    });

    //Parse referrals and skills to either include ID or whole object
    parsedFormState.referrals = data.referrals.map((ref) => {
      return ref.id ? ref.id : ref;
    });

    parsedFormState.skills = data.skills.map((skill) => {
      return skill.id;
    });

    const success = await onSubmit(parsedFormState);
    if (success) {
      openNoticeDialog();
    }
  };

  const openNoticeDialog = () => {
    if (confirmSubmitDialog) {
      openConfirmSubmitDialog(false);
    }

    trigger().then((success) => {
      if (success) {
        showDialog("NOTICE_DIALOG", {
          title: !isAuthenticated
            ? localize("dialog.mailConfirmation")
            : localize("dialog.applicationSent"),
          textContent: !isAuthenticated
            ? localize("dialog.confirmJobApplicationSubtitle")
            : localize("dialog.applicationSentContent"),
          subContent: !isAuthenticated
            ? localize("dialog.confirmJobApplicationWarning")
            : localize("dialog.applicationSentSubContent"),
          confirmAction: () => {
            hideDialog();
            hideModal();
          },
          closeAction: () => {
            hideDialog();
            hideModal();
          },
        });
      }
    });
  };

  const handleSubmitApplication = (data: JobApplicationState) => {
    if (!hasRequiredSkills()) {
      addMessage({
        type: "error",
        content: "error.jobApplicationFailedRequirements",
      });
      return;
    }

    parseSubmit(data, false);
  };

  return (
    <>
      <Grid container gap="1rem">
        <Grid container gap="1rem" padding="1rem" sx={{ ...sx }} paddingBottom="0">
          <WTextField
            disabled={readOnly}
            fullWidth
            label={localize("form.firstnameField")}
            error={!!errors.first_name}
            helperText={errors?.first_name?.message}
            value={watch("first_name")}
            {...register("first_name", {
              maxLength: {
                value: 150,
                message: localize("form.fieldMaxLengthError", { length: "150" }),
              },
            })}
          />
          <WTextField
            disabled={readOnly}
            fullWidth
            label={localize("form.lastnameField")}
            error={!!errors.last_name}
            helperText={errors?.last_name?.message}
            value={watch("last_name")}
            {...register("last_name", {
              maxLength: {
                value: 150,
                message: localize("form.fieldMaxLengthError", { length: "150" }),
              },
            })}
          />
          <WTextField
            required
            disabled={readOnly || isAuthenticated}
            fullWidth
            label={localize("form.emailField")}
            error={!!errors.email}
            helperText={errors?.email?.message}
            value={watch("email")}
            type="email"
            {...register("email", {
              required: localize("form.requiredFieldError"),
              validate: (value) => validateEmail(value, localize),
              maxLength: {
                value: 100,
                message: localize("form.fieldMaxLengthError", { length: "100" }),
              },
            })}
          />
          <WTextField
            disabled={readOnly}
            fullWidth
            error={!!errors.phone}
            helperText={errors?.phone?.message}
            label={localize("form.phoneNumberField")}
            value={watch("phone")}
            type="tel"
            {...register("phone", {
              maxLength: {
                value: 30,
                message: localize("form.fieldMaxLengthError", { length: "30" }),
              },
            })}
          />

          <Grid container item>
            <Grid container item xs={12} justifyContent="space-between">
              <Typography fontWeight="bold" color="primary" align="center">
                {localize("form.motivationField")}
              </Typography>
            </Grid>
            <WTextField
              disabled={readOnly}
              multiline
              minRows={6}
              fullWidth
              error={!!errors.cover_letter}
              helperText={errors?.cover_letter?.message}
              placeholder={localize("form.motivationPlaceholder")}
              value={watch("cover_letter")}
              {...register("cover_letter", {
                maxLength: {
                  value: 10000,
                  message: localize("form.fieldMaxLengthError", { length: "10000" }),
                },
              })}
            />
          </Grid>
          <Typography color="primary" align="center">
            {localize("form.mandatoryInfo")}
          </Typography>
        </Grid>

        <Divider sx={{ width: "100%" }} />

        <Grid container wrap="nowrap" gap=".5rem" direction="column" padding="0 1rem">
          <Grid container direction="column" wrap="nowrap" gap=".5rem" alignItems="center">
            <JobApplicationCVAccordion
              cvList={cvList}
              handleSelectedCV={(cv: string | File | undefined) => {
                setValue("cv", cv);
              }}
              handleCreatedCV={(cv: File, alias: string) => {
                setValue("cv", cv);
                setValue("cv_alias", alias);
              }}
              preSelectedCV={prefillFields && prefillFields.cv}
            />
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%" }} />

        <Grid container wrap="nowrap" gap=".5rem" direction="column" padding="0 1rem">
          <JobApplicationReferralAccordion
            referralList={referralList}
            handleSelectReferral={(referrals: Referral[]) => {
              setValue("referrals", referrals);
            }}
            handleCreateReferral={handleReferralSubmit}
            preSelectedReferrals={prefillFields && prefillFields.referrals}
          />
        </Grid>
        <Divider sx={{ width: "100%" }} />

        {jobSkills && jobSkills.length > 0 && (
          <>
            <Grid container wrap="nowrap" gap="1.5rem" direction="column" padding="0 1rem">
              <JobApplicationSkillComponent
                hasRequiredSkill={{
                  state: hasRequiredSkills(),
                  nrOfRequiredSkills: requiredSkills,
                }}
                jobSkills={jobSkills}
                preSelectedSkills={selectedSkills}
                handleSelectedSkills={handleSkillsSubmit}
              />
            </Grid>
            <Divider sx={{ width: "100%" }} />
          </>
        )}

        <Grid container wrap="nowrap" gap="1rem" direction="column" padding="0 1rem">
          <Typography fontWeight="bold" color="primary">
            {localize("form.priceAndAvailability")}
          </Typography>

          <WTextField
            disabled={readOnly}
            fullWidth
            error={!!errors.price}
            helperText={errors?.price?.message}
            value={watch("price")}
            label={localize("jobCard.rate")}
            {...register("price", {
              maxLength: {
                value: 60,
                message: localize("form.fieldMaxLengthError", { length: "60" }),
              },
            })}
          />
          <WDatePicker
            onError={(reason) => {
              reason
                ? setError("availability_date", { type: "manual", message: "Invalid date" })
                : clearErrors("availability_date");
            }}
            disabled={readOnly}
            label={localize("form.availabilityDate")}
            value={watch("availability_date")}
            onChange={(date) => setValue("availability_date", date)}
          />
        </Grid>

        <Grid container padding="0 1rem">
          {!isAuthenticated && (
            <FormControlLabel
              sx={{
                "*": {
                  color: "primary.dark",
                },
              }}
              control={
                <Checkbox
                  checked={integrityAccepted}
                  onChange={() => setIntegrityAccepted(!integrityAccepted)}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {localize("form.integrityCheckbox")}{" "}
                    <a href={INTEGRITY_LINK} target="_blank" rel="noreferrer">
                      {localize("common.integrityText")}
                    </a>{" "}
                    {"*"}
                  </Typography>
                  {!integrityAccepted && (
                    <FormHelperText error>{localize("form.requiredFieldError")}</FormHelperText>
                  )}
                </>
              }
            />
          )}
        </Grid>
        <Divider sx={{ width: "100%" }} />

        <Grid
          container
          direction="column"
          wrap="nowrap"
          alignItems="center"
          padding="0rem 1rem"
          gap=".5rem"
        >
          <WButton
            fullWidth
            disabled={submitDisabled || !hasRequiredSkills()}
            color="primary"
            variant="contained"
            data-test="applicationSent"
            data-loggedin={!!isAuthenticated}
            sx={{
              alignItems: "center",
            }}
            startIcon={<WIcon color="secondary" icon={WIconTypes.send} />}
            onClick={
              !selectedCV
                ? () => handleOpenConfirmDialog()
                : handleSubmit((data) => {
                    handleSubmitApplication(data);
                  })
            }
          >
            {localize("form.sendApplication")}
          </WButton>
          <WButton
            disabled={!canSaveDraft}
            fullWidth
            variant="outlined"
            onClick={handleSubmit((data) => parseSubmit(data, true))}
            data-test="applicationSaved"
            data-loggedin={!!isAuthenticated}
          >
            {localize("form.saveDraft")}
          </WButton>
        </Grid>
      </Grid>

      {confirmSubmitDialog && (
        <ConfirmDialog
          title={localize("dialog.confirmJobApplication")}
          subtitle={localize("dialog.confirmJobApplicationSubtitleNoCV")}
          subtitleColor={"warning.main"}
          isWarning={!selectedCV}
          cancelAction={() => openConfirmSubmitDialog(false)}
          confirmAction={handleSubmit((data) => {
            handleSubmitApplication(data);
          })}
          displayLoading={isLoading("CREATE_JOB_APPLICATION")}
        />
      )}
    </>
  );
};
