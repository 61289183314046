import { Checkbox, MenuItem } from "@mui/material";
import React, { FC } from "react";
import { Item } from "views/AgentViews/CandidatesView";
import { FilterTextField } from "../FilterTextField";

interface FilterListProps {
  listData: Item[];
  handleClick: (value: Item, key: "roles" | "departments" | "regions") => void;
  checked: string[];
  filterLabel: string;
  filterKey: "roles" | "departments" | "regions";
  disabled?: boolean;
}

export const FilterList: FC<FilterListProps> = ({
  listData,
  handleClick,
  checked,
  filterLabel,
  filterKey,
  disabled = false,
}) => {
  return (
    <FilterTextField
      disabled={disabled}
      value={[]}
      focused={!!checked.length}
      label={checked.length ? checked.join(",") : filterLabel}
      SelectProps={{
        multiple: true,
      }}
      sx={{
        marginTop: ".5rem",
      }}
    >
      {listData.map((data) => (
        <MenuItem
          key={data.name}
          value={data.name}
          onClick={() => {
            handleClick(data, filterKey);
          }}
        >
          <Checkbox
            checked={checked.includes(data.name)}
            sx={{
              color: "text.primary",
            }}
          />
          {data.name}
        </MenuItem>
      ))}
    </FilterTextField>
  );
};
