import { Box, Grid, Typography } from "@mui/material";
import React, { FC, useContext } from "react";
import { colors } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";
import { StepsAndActions } from "./MyProfileTab";
import { WIcon, WIconTypes } from "components";

interface ProfileTaskListProps {
  steps: StepsAndActions[];
}

export const ProfileTaskList: FC<ProfileTaskListProps> = ({ steps }) => {
  const { localize } = useContext(LocaleContext);
  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: "secondary.main",
      }}
    >
      <Grid container color="primary.main">
        {steps.map((stepObj) => (
          <Grid
            container
            key={stepObj.step}
            wrap="nowrap"
            padding="0.5rem 0"
            sx={{ backgroundColor: "background.default", cursor: stepObj.action && "pointer" }}
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor={colors.grey}
            onClick={() => stepObj.action && stepObj.action()}
          >
            <Typography> {localize(`profileView.${stepObj.step}Task`)}</Typography>
            {stepObj.action ? (
              <Box>
                <WIcon icon={WIconTypes.arrowRight} />
              </Box>
            ) : (
              <div>&nbsp;</div>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
