import { Checkbox, Grid, Typography, FormControlLabel } from "@mui/material";
import { WButton, WIcon, WIconTypes, WTextField } from "components";
import { createUser } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { validateEmail } from "./validators";
import { NotificationContext } from "context/NotificationContext";
import CircularProgress from "@mui/material/CircularProgress";
import { defaultStyles } from "styles/colors";
import { INTEGRITY_LINK } from "views/Enums";
import { useHistory } from "react-router-dom";

type RegisterProfileState = {
  email: string;
  password: string;
  re_password: string;
  language: string;
};

export const RegisterProfileForm: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<RegisterProfileState>({
    defaultValues: {
      email: "",
      password: "",
      re_password: "",
      language: localStorage.getItem("lang") || "en",
    },
  });
  const { addMessage } = useContext(NotificationContext);

  const [integrityAccepted, setIntegrityAccepted] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const history = useHistory();

  const submitForm = async (data: RegisterProfileState) => {
    dispatchLoading({ type: "SET_LOADING", payload: "CREATE_USER" });
    createUser(data)
      .then(() => {
        setRegistrationSuccess(true);

        // gtmAddToDataLayer({ user_id: "TODO, push id if we ever respond with id" }, "create_account");
      })
      .catch((e) => {
        if (e.response.data.non_field_errors) {
          addMessage({
            type: "error",
            content: e.response.data.non_field_errors[0],
            isResponse: true,
          });
          return;
        }

        addMessage({
          type: "error",
        });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "CREATE_USER" });
      });
  };

  if (registrationSuccess) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        gap="1rem"
        padding="1rem"
        sx={{
          backgroundColor: "secondary.main",
          borderRadius: defaultStyles.borderRadiusLarge,
        }}
      >
        <WIcon icon={WIconTypes.mail} size="large" color="success" />
        <Typography variant="body1" fontWeight="bold">
          {localize("registerView.verificationTitleText")}
        </Typography>
        <Typography variant="body2">{localize("registerView.verificationBodyText")}</Typography>
      </Grid>
    );
  }
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid gap="1rem" container>
        <WTextField
          fullWidth
          label={localize("form.emailField")}
          error={!!errors.email}
          helperText={errors?.email?.message}
          {...register("email", {
            required: localize("form.requiredFieldError"),
            validate: (value) => validateEmail(value, localize),
          })}
        />

        <WTextField
          fullWidth
          label={localize("form.passwordField")}
          error={!!errors.password}
          helperText={errors?.password?.message}
          {...register("password", {
            required: localize("form.requiredFieldError"),
            minLength: {
              value: 8,
              message: localize("form.fieldMinLengthError", { length: "8" }),
            },
            max: 128,
          })}
          type="password"
        />
        <WTextField
          fullWidth
          label={localize("form.confirmPasswordField")}
          error={!!errors.re_password}
          helperText={errors?.re_password?.message}
          {...register("re_password", {
            required: localize("form.requiredFieldError"),
            minLength: {
              value: 8,
              message: localize("form.fieldMinLengthError", { length: "8" }),
            },
            max: 128,
            validate: (value) => {
              return (
                value === getValues("password") || localize("form.confirmPasswordFieldNoMatch")
              );
            },
          })}
          type="password"
        />
        <FormControlLabel
          sx={{
            "*": {
              color: "primary.dark",
            },
          }}
          control={
            <Checkbox
              checked={integrityAccepted}
              onChange={() => setIntegrityAccepted(!integrityAccepted)}
            />
          }
          label={
            <Typography variant="body2">
              {localize("form.integrityCheckbox")}{" "}
              <a href={INTEGRITY_LINK} target="_blank" rel="noreferrer">
                {localize("common.integrityText")}
              </a>
            </Typography>
          }
        />
        <WButton
          fullWidth
          variant="contained"
          type="submit"
          disabled={!!Object.keys(errors).length || !integrityAccepted}
        >
          {localize("registerView.sendVerification")}
        </WButton>
        {isLoading("CREATE_USER") && (
          <CircularProgress
            color="success"
            sx={{
              margin: "auto",
            }}
          />
        )}
        <Grid container direction="column" marginTop="auto">
          <Typography variant="body2">
            {localize("form.registerExistingAccount")}
            <WButton variant="text" onClick={() => history.push("/login")}>
              {localize("form.login")}
            </WButton>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};
