import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Skeleton,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { LoaderContext } from "context/LoaderContext";
import { Searchbar } from "components/Searchbar";
import { getDepartments, Department } from "adapters/DepartmentRoleAdapter";
import { NotificationContext } from "context/NotificationContext";
import { FilterList } from "components/AdvancedSearch/FilterList";
import { UserSearchList } from "./UserList";
import { User } from "reducers/UserReducer";
import { getUserList, getRegions } from "adapters";
import { WButton, WIcon, WIconButton, WIconTypes, WTypography } from "components";
import { DEFAULT_PAGINATION_SIZE, usePagination } from "hooks/usePagination";
import { parseUserParamsAndSearch } from "utils/JobUtils";
import { colors, defaultStyles } from "styles/colors";
import { FilterUserSkillList } from "components/AdvancedSearch/FilterUserSkillList";
import { ModalContext } from "context/ModalContext";
import { UserContext } from "context/UserContext";
import { toggleArrayItem } from "utils/generalUtils";
import { Box } from "@mui/system";

export type Item = {
  id: string;
  name: string;
};

export type UserFilterObject = {
  regions: Item[];
  roles: Item[];
  departments: Item[];
  skills: Item[];
  search: string;
  boolean_type: string;
  cv_search: boolean;
};

const defaultUserObject = {
  regions: [],
  roles: [],
  departments: [],
  skills: [],
  search: "",
  boolean_type: "and",
  cv_search: false,
};

const PAGE_SIZE_OPTIONS = [10, 20, 30, 100];
const ORDER_OPTIONS = [
  "first_name",
  "last_name",
  "email",
  "availability_date",
  "latest_application",
  "created",
];
const ORDER_DIRECTIONS = ["ascending", "descending"];

export const CandidatesView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { showDialog } = useContext(ModalContext);
  const { users, dispatch } = useContext(UserContext);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [regions, setRegions] = useState<Item[]>([]);
  const [selectableRoles, setSelectableRoles] = useState<Item[]>([]);
  const [userFilterObject, setUserFilterObject] = useState<UserFilterObject>(defaultUserObject);
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleUsers, setVisibleUsers] = useState<number | null>(DEFAULT_PAGINATION_SIZE);
  const [order, setOrder] = useState<string>("created");
  const [orderDirection, setOrderDirection] = useState<string>("ascending");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const { results, pageResults, count, handleSearch, getPage, updatePage } = usePagination<User>(
    getUserList,
    "error.generalError",
    "GET_USER_LIST",
    { page_size: DEFAULT_PAGINATION_SIZE }
  );

  useEffect(() => {
    fetchDepartments();
    fetchRegions();
  }, []);

  useEffect(() => {
    dispatch({ type: "SET_USER_LIST", users: pageResults });
  }, [pageResults]);

  useEffect(() => {
    updatePage(parseUserParamsAndSearch(userFilterObject), visibleUsers ?? DEFAULT_PAGINATION_SIZE);
  }, [visibleUsers]);

  useEffect(() => {
    const parsedFilters = parseUserParamsAndSearch(userFilterObject);

    parsedFilters["ordering"] = orderDirection === "descending" ? `-${order}` : order;

    if (parsedFilters.boolean_type === "and") {
      delete parsedFilters.boolean_type;
    }

    if (!parsedFilters.cv_search) {
      delete parsedFilters.cv_search;
    }
    handleSearch(parsedFilters);
  }, [order, orderDirection]);

  const handleInputChange = (value: string) => {
    setUserFilterObject({ ...userFilterObject, search: value });
  };

  const updateSelectableRoles = useCallback(() => {
    const selectedDepartments = departments.filter((department) =>
      userFilterObject.departments.some(
        (currentDepartment) => currentDepartment.id === department.id
      )
    );

    const newSelectableRoles: Item[] = [];
    selectedDepartments.forEach((department) => {
      department.roles.forEach((role) => newSelectableRoles.push(role));
    });

    setSelectableRoles(newSelectableRoles);
  }, [userFilterObject.departments, departments]);

  const handleClickItem = useCallback(
    (value: Item, key: "roles" | "departments" | "regions" | "skills"): void => {
      const currentFilters = userFilterObject[key];
      let newFilters = currentFilters;

      if (currentFilters.some((item) => item.id === value.id)) {
        newFilters = currentFilters.filter((item) => item.id !== value.id);
      } else {
        newFilters.push(value);
      }

      setUserFilterObject({ ...userFilterObject, [key]: newFilters });

      if (key === "departments") {
        updateSelectableRoles();
      }
    },
    [userFilterObject, updateSelectableRoles, setUserFilterObject]
  );

  const fetchDepartments = useCallback(() => {
    // do this only once, used as reference to obtain id's for departments and roles
    dispatchLoading({ type: "SET_LOADING", payload: "GET_DEPARTMENTS" });
    getDepartments()
      .then((res) => {
        setDepartments(res.data.results);
      })
      .catch(() => {
        addMessage({ content: "error.fetchingDepartments", type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_DEPARTMENTS" });
      });
  }, [getDepartments, dispatchLoading, setDepartments]);

  const fetchRegions = useCallback(() => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_REGIONS" });
    getRegions()
      .then((res) => {
        setRegions(res.data.results);
      })
      .catch(() => {
        addMessage({ content: "error.fetchingRegions", type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_REGIONS" });
      });
  }, [getRegions, dispatchLoading, setRegions]);

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault();
    handleSearch(userFilterObject);
  };

  const handleChangePage = useCallback(
    (_, page: number) => {
      if (page === currentPage) {
        return;
      }
      setCurrentPage(page);
      getPage(page, parseUserParamsAndSearch(userFilterObject));
    },
    [currentPage, setCurrentPage, getPage, userFilterObject]
  );

  const handleChangeVisibleUsers = useCallback(
    (_, newVisibleUsers: number | null) => {
      setVisibleUsers(newVisibleUsers);
      setCurrentPage(1);
    },
    [setVisibleUsers, setCurrentPage]
  );

  /**
   * Current order Options:
   * first_name, last_name, email, availability_date, latest_application, created
   */
  const handleChangeOrder = useCallback(
    (_, selectedOrder: string) => {
      if (selectedOrder === "latest_application") {
        selectedOrder = "-latest_application";
      }
      setOrder(selectedOrder ?? "created");
    },
    [setOrder]
  );

  const handleChangeOrderDirection = useCallback(
    (_, selectedDirection: string | null) => {
      if (selectedDirection !== null) {
        setOrderDirection(selectedDirection);
      }
    },
    [setOrderDirection]
  );

  const onToggleSelect = useCallback(
    (userId: string) => setSelectedUsers(toggleArrayItem(selectedUsers, userId)),
    [setSelectedUsers, selectedUsers]
  );

  return (
    <form onSubmit={submitForm}>
      <Grid
        container
        item
        direction="column"
        margin="auto"
        xs={12}
        md={11}
        gap="1rem"
        paddingTop="1rem"
        paddingLeft="1rem"
        paddingRight="1rem"
      >
        <Grid
          item
          md={8}
          marginLeft="auto"
          marginRight="auto"
          container
          flexWrap="nowrap"
          sx={{
            backgroundColor: "secondary.main",
            border: "1px solid secondary.main",
            borderRadius: "4px",
          }}
          paddingRight=".5rem"
          alignItems="center"
        >
          <Grid item xs={10}>
            <Searchbar setInputValue={handleInputChange} placeholder={localize("form.search")} />
          </Grid>

          <Grid container item xs={2} justifyContent="flex-end">
            <Button color="success" variant="contained" disableElevation type="submit">
              {localize("form.search")}
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Stack direction="row" spacing={1} alignItems="center">
            <WTypography>AND</WTypography>
            <Switch
              onChange={(e) => {
                setUserFilterObject({
                  ...userFilterObject,
                  boolean_type: e.target.checked ? "or" : "and",
                });
              }}
            />
            <WTypography>OR</WTypography>
          </Stack>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  setUserFilterObject({ ...userFilterObject, cv_search: e.target.checked });
                }}
              />
            }
            label={localize("searchbar.cvSearch")}
          />
          <Button
            sx={{
              borderRadius: defaultStyles.borderRadiusFullyRounded,
            }}
            color="primary"
            variant="outlined"
            startIcon={<WIcon icon={WIconTypes.visibility} />}
            onClick={() => {
              showDialog("GDPR_DIALOG");
            }}
          >
            {localize("agentStartPage.manageUsers")}
          </Button>
        </Grid>
        <Grid container justifyContent="center" alignItems="space-around">
          <Button
            sx={{
              margin: "auto",
              borderRadius: defaultStyles.borderRadiusFullyRounded,
              width: "250px",
            }}
            color="primary"
            size="medium"
            variant={showFilters ? "contained" : "outlined"}
            disableElevation
            startIcon={<WIcon rotationDegrees="90" icon={WIconTypes.sliders} />}
            onClick={() => setShowFilters(!showFilters)}
          >
            {localize("searchbar.filterButton")}
          </Button>
        </Grid>

        <Grid container>
          <Collapse in={showFilters} mountOnEnter unmountOnExit sx={{ width: "100%" }}>
            <Grid
              container
              item
              gap="1rem"
              sx={{
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
              }}
              flexWrap="nowrap"
            >
              <FilterList
                listData={regions}
                filterKey="regions"
                checked={userFilterObject.regions.map((region) => region.name)}
                handleClick={handleClickItem}
                filterLabel={localize("common.region")}
              />
              <FilterUserSkillList
                selectedSkills={userFilterObject.skills.map((department) => department.name)}
                handleClick={handleClickItem}
                filterLabel={localize("common.skills")}
              />
              {!isLoading("GET_DEPARTMENTS") && (
                <>
                  <FilterList
                    disabled={!departments.length}
                    filterKey="departments"
                    listData={departments}
                    checked={userFilterObject.departments.map((department) => department.name)}
                    handleClick={handleClickItem}
                    filterLabel={localize("common.department")}
                  />
                  <FilterList
                    disabled={!departments.length}
                    filterKey="roles"
                    listData={selectableRoles}
                    checked={userFilterObject.roles.map((role) => role.name)}
                    handleClick={handleClickItem}
                    filterLabel={localize("common.role")}
                  />
                </>
              )}
              {isLoading("GET_DEPARTMENTS") && (
                <>
                  <Skeleton width="100%" sx={{ padding: "1rem" }}>
                    {localize("common.department")}
                  </Skeleton>
                  <Skeleton width="100%" sx={{ padding: "1rem" }}>
                    {localize("common.role")}
                  </Skeleton>
                </>
              )}
            </Grid>
          </Collapse>
        </Grid>

        <Grid item container direction="column" gap=".2rem">
          <Grid container alignItems="flex-end" gap="2rem" justifyContent="space-between">
            <MultiSelectMenu
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              pageResults={pageResults}
              users={results}
            />
            <Grid container direction="column" gap=".1rem" width="max-content">
              <Typography paddingLeft="6.2rem" variant="body2">
                {localize(`orderOptions.sortBy`)}
              </Typography>
              <Grid container gap=".2rem">
                <ToggleButtonGroup
                  value={orderDirection}
                  exclusive
                  onChange={handleChangeOrderDirection}
                >
                  {ORDER_DIRECTIONS.map((direction) => {
                    return (
                      <ToggleButton
                        key={`orderType${direction}`}
                        sx={{ paddingTop: "4px", paddingBottom: "4px" }}
                        value={direction}
                      >
                        {direction === "ascending" ? (
                          <WIcon icon={WIconTypes.arrowDown} />
                        ) : (
                          <WIcon icon={WIconTypes.arrowUp} />
                        )}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
                <ToggleButtonGroup value={order} onChange={handleChangeOrder} exclusive>
                  {ORDER_OPTIONS.map((orderType) => {
                    return (
                      <ToggleButton
                        key={`orderType${orderType}`}
                        sx={{ paddingTop: "4px", paddingBottom: "4px" }}
                        value={orderType}
                      >
                        {localize(`orderOptions.${orderType}`)}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <Grid item>
              <Typography color={colors.mainDarkRed} variant="body2">
                {localize("searchbar.matchingCandidates", { count: count.toString() })}
              </Typography>
              {selectedUsers.length > 0 ? (
                <Typography color={colors.mainDarkRed} variant="body2">
                  {localize("searchbar.selectedCandidates", {
                    total: selectedUsers.length.toString(),
                    page: pageResults.filter((u) => selectedUsers.includes(u.id)).length.toString(),
                  })}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <UserSearchList
            users={users}
            selectedIds={selectedUsers}
            onToggleSelect={onToggleSelect}
          />
          <Grid container justifyContent="center" marginTop=".5rem">
            <Pagination
              color="primary"
              count={Math.ceil(count / (visibleUsers ?? DEFAULT_PAGINATION_SIZE))}
              page={currentPage}
              siblingCount={0}
              onChange={handleChangePage}
            />

            <Grid container gap="5px" alignItems="center" width="max-content" marginLeft="auto">
              <Typography variant="body2"> {localize("searchbar.candidatesPerPage")}</Typography>
              <ToggleButtonGroup value={visibleUsers} exclusive onChange={handleChangeVisibleUsers}>
                {PAGE_SIZE_OPTIONS.map((size) => {
                  return (
                    <ToggleButton
                      key={`size_${size}`}
                      sx={{ paddingTop: "4px", paddingBottom: "4px" }}
                      value={size}
                      disabled={count < size}
                    >
                      {size}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

interface MultiSelectMenuProps {
  selectedUsers: string[];
  setSelectedUsers: (users: string[]) => void;
  pageResults: User[];
  users: User[];
}
const MultiSelectMenu: FC<MultiSelectMenuProps> = ({
  selectedUsers,
  setSelectedUsers,
  pageResults,
  users,
}) => {
  const { localize } = useContext(LocaleContext);
  const { showModal } = useContext(ModalContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const noPageSelections = useMemo(() => {
    return selectedUsers.filter((id) => pageResults.find((u) => u.id === id)).length === 0;
  }, [selectedUsers, pageResults]);

  return (
    <Grid container alignItems="center" width="max-content">
      <Grid item ml="5px">
        <Tooltip
          title={localize(noPageSelections ? "common.selectPage" : "common.deselectPage")}
          placement="top-start"
          arrow
        >
          <WIconButton
            onClick={(event) => {
              event.stopPropagation();
              if (noPageSelections) {
                const selected = [...new Set([...selectedUsers, ...pageResults.map((u) => u.id)])];
                setSelectedUsers(selected);
              } else {
                setSelectedUsers(
                  selectedUsers.filter((id) => !pageResults.find((u) => u.id === id))
                );
              }
            }}
            aria-label="toggle select"
          >
            <WIcon icon={noPageSelections ? WIconTypes.square : WIconTypes.checkSquare} />
          </WIconButton>
        </Tooltip>
      </Grid>
      {selectedUsers.length > 0 ? (
        <Grid item>
          <WButton onClick={(e) => setAnchorEl(e.currentTarget)} variant="outlined">
            {localize("common.action")}...
          </WButton>
          <Menu open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
            <Box sx={{ width: "200px" }}>
              <MenuItem
                onClick={() => {
                  showModal("SEND_EMAIL_MODAL", {
                    users: users.filter((user) => selectedUsers.includes(user.id)),
                  });
                  setAnchorEl(null);
                  setSelectedUsers([]);
                }}
              >
                <ListItemIcon>
                  <WIcon icon={WIconTypes.mail} />
                </ListItemIcon>
                <ListItemText>{localize("emailTemplates.sendEmail")}</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setSelectedUsers([]);
                }}
              >
                <ListItemIcon>
                  <WIcon icon={WIconTypes.checkSquare} />
                </ListItemIcon>
                <ListItemText>{localize("common.deselectAll")}</ListItemText>
              </MenuItem>
            </Box>
          </Menu>
        </Grid>
      ) : null}
    </Grid>
  );
};
