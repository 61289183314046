import { Grid, Dialog, Typography, CircularProgress, Button } from "@mui/material";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { ModalContext } from "context/ModalContext";
import { LocaleContext } from "context/LocaleContext";
import { DialogHeader, WButton, WIcon, WIconTypes, WTypography } from "components";
import { NotificationContext } from "context/NotificationContext";
import { BreakpointsContext } from "context/BreakpointContext";
import { GTMContext } from "context/GTMContext";
import { DepartmentFilterList } from "components/FiltersDialog/DepartmentFilterList";
import { JobFilterItem, JobFilters } from "components/dialogs/FiltersDialog";
import { RegionFilterList } from "components/FiltersDialog/RegionFilterList";
import { toggleArrayItem } from "utils/generalUtils";
import {
  getSubscriptions,
  createSubscription,
  updateSubscription,
  deleteSubscription,
  Subscription,
} from "adapters/SubscriptionsAdapter";
import { LoaderContext } from "context/LoaderContext";
import { usePagination } from "hooks/usePagination";
import { colors } from "styles/colors";

export const ProfileNotificationsModal: FC = () => {
  const { hideModal } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);
  const { isLoading } = useContext(LoaderContext);

  const [currentSubscription, setCurrentSubscription] = useState<Subscription | undefined>(
    undefined
  );
  const [subscribeToNewJobs, setSubscribeToNewJobs] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [interval, setInterval] = useState<"weekly" | "daily">("weekly");

  const { results } = usePagination<Subscription>(
    getSubscriptions,
    "error.generalError",
    "GET_SUBSCRIPTIONS",
    { page_size: 1 }
  );

  useEffect(() => {
    if (results.length === 0 || currentSubscription) {
      return;
    }

    const subscription = results[0];
    setCurrentSubscription(subscription);
    if (subscription) {
      setSelectedRoles(subscription.department_roles);
      setSelectedRegions(subscription.regions);
      setSubscribeToNewJobs(true);
      setInterval(subscription.time_interval);
    }
  }, [results]);

  const handleSave = useCallback(async (): Promise<void> => {
    if (!subscribeToNewJobs) {
      if (!currentSubscription) {
        hideModal();
        return;
      }
      await deleteSubscription(currentSubscription.id);
      gtmAddToDataLayer({}, "subscription_paused");
      addMessage({ content: "success.jobSubscription", type: "success" });
      hideModal();
      return;
    }

    if (!currentSubscription) {
      const subscription = {
        department_roles: selectedRoles,
        regions: selectedRegions,
        time_interval: interval,
      };
      const created = await createSubscription(subscription);
      if (created.data) {
        setCurrentSubscription(created.data);
        gtmAddToDataLayer({}, "subscription_started");
      }
    } else {
      const updatedProps: Partial<Subscription> = {};
      if (currentSubscription.time_interval !== interval) {
        updatedProps.time_interval = interval;
      }
      if (
        currentSubscription.department_roles.length !== selectedRoles.length ||
        !currentSubscription.department_roles.every((role) => selectedRoles.includes(role))
      ) {
        updatedProps.department_roles = selectedRoles;
      }
      if (
        currentSubscription.regions.length !== selectedRegions.length ||
        !currentSubscription.regions.every((role) => selectedRegions.includes(role))
      ) {
        updatedProps.regions = selectedRegions;
      }
      await updateSubscription(currentSubscription.id, updatedProps);
    }

    addMessage({ content: "success.jobSubscription", type: "success" });
    hideModal();
  }, [
    subscribeToNewJobs,
    currentSubscription,
    selectedRegions,
    selectedRoles,
    interval,
    gtmAddToDataLayer,
    addMessage,
    hideModal,
  ]);

  let topContent = null;
  let bottomContent = null;

  if (isLoading("GET_SUBSCRIPTIONS")) {
    topContent = <CircularProgress size="60px" sx={{ margin: "100px auto" }} />;
  } else {
    topContent = (
      <>
        <Typography width="100%">{localize("form.jobSubscription")}</Typography>

        <Grid container gap="20px" flexWrap="nowrap">
          <BigButton
            title={localize("common.never")}
            selected={!subscribeToNewJobs}
            onClick={() => setSubscribeToNewJobs(false)}
          />
          <BigButton
            title={localize("common.week")}
            subTitle={localize("form.weekSendDay")}
            selected={subscribeToNewJobs && interval === "weekly"}
            onClick={() => {
              setInterval("weekly");
              setSubscribeToNewJobs(true);
            }}
          />
          <BigButton
            title={localize("common.day")}
            selected={subscribeToNewJobs && interval === "daily"}
            onClick={() => {
              setInterval("daily");
              setSubscribeToNewJobs(true);
            }}
          />
        </Grid>
      </>
    );
  }

  if (subscribeToNewJobs) {
    bottomContent = (
      <>
        <Typography width="100%" marginTop="20px">
          {localize("form.limitEmails")}
        </Typography>
        <RegionFilterList
          expand={true}
          itemsToShow={100}
          filters={
            {
              regions: selectedRegions.map((regionId) => {
                return { label: "", value: regionId };
              }),
              locations: [] as JobFilterItem[],
            } as JobFilters
          }
          handleFilterClick={(key, item) => {
            setSelectedRegions(toggleArrayItem(selectedRegions, item.value));
          }}
          handleExpandClick={() => ({})}
        />
        <DepartmentFilterList
          itemsToShow={100}
          pageSize={100}
          expand={true}
          filters={
            {
              departments: [] as JobFilterItem[],
              roles: selectedRoles.map((id) => ({ label: "", value: id })),
            } as JobFilters
          }
          handleExpandClick={() => ({})}
          handleFilterClick={(key, item) => {
            setSelectedRoles(toggleArrayItem(selectedRoles, item.value));
          }}
          handleDepartmentClick={(department) => {
            const roles = [...selectedRoles];
            const everythingSelected = department.roles.every((role) =>
              selectedRoles.includes(role.id)
            );
            if (everythingSelected) {
              department.roles.forEach((role) => {
                const index = roles.indexOf(role.id);
                if (index !== -1) {
                  roles.splice(index, 1);
                }
              });
            } else {
              department.roles.forEach((role) => {
                if (!roles.includes(role.id)) {
                  roles.push(role.id);
                }
              });
            }
            setSelectedRoles(roles);
          }}
        />
      </>
    );
  } else {
    bottomContent = (
      <WTypography variant="body2" marginTop="20px">
        {localize("form.moreFilterOptions")}
      </WTypography>
    );
  }

  return (
    <Dialog
      fullScreen={isDesktop ? false : true}
      open={true}
      sx={{ height: "100%" }}
      onClose={hideModal}
      PaperProps={
        isDesktop
          ? {
              style: { borderRadius: "15px", width: "600px" },
            }
          : undefined
      }
    >
      {!isDesktop && (
        <DialogHeader
          handleClose={() => hideModal()}
          title={localize("profileSections.subscriptions")}
          returnText={localize("profileSections.appSettings")}
        />
      )}
      <Grid container direction="column" padding="1rem" gap=".5rem">
        <Grid item display="flex" justifyContent="center">
          <img
            alt="job image"
            src={`${process.env.PUBLIC_URL}/assets/images/newsletter.svg`}
            style={{
              width: "300px",
              objectFit: "cover",
              marginBottom: "30px",
            }}
          />
        </Grid>
        {isDesktop && (
          <Typography variant="h6">{localize("profileSections.subscriptions")}</Typography>
        )}

        {topContent}

        {bottomContent}

        {!isLoading("GET_SUBSCRIPTIONS") ? (
          <WButton
            fullWidth
            variant="outlined"
            sx={{ marginTop: "30px" }}
            startIcon={<WIcon icon={WIconTypes.check} />}
            onClick={handleSave}
          >
            {localize("form.save")} {localize("profileSections.appSettings").toLowerCase()}
          </WButton>
        ) : null}
      </Grid>
    </Dialog>
  );
};

interface BigButtonProps {
  title: string;
  subTitle?: string;
  selected: boolean;
  onClick: () => void;
}

const BigButton: FC<BigButtonProps> = ({ title, subTitle, selected, onClick }) => {
  return (
    <Grid item width="100%">
      <Button
        variant="outlined"
        onClick={onClick}
        sx={{
          width: "100%",
          height: "70px",
          border: "none !important",
          display: "flex",
          flexDirection: "column",
          boxShadow: selected
            ? `0 0 0 4px ${colors.primaryGreen}`
            : `0 0 0 2px ${colors.lightBorder}`,
        }}
      >
        <WTypography fontSize="1.2rem" fontWeight="bold">
          {title}
        </WTypography>
        <WTypography variant="body2" fontSize="0.9rem">
          {subTitle}
        </WTypography>
      </Button>
    </Grid>
  );
};
