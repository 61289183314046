import { Box, Card, Grid } from "@mui/material";
import { ModalContext } from "context/ModalContext";
import React, { FC, useContext, ReactNode, useState, useEffect, useCallback } from "react";
import { deleteJobApplication, Job } from "adapters";
import { colors, defaultStyles } from "styles/colors";
import { BreakpointsContext } from "context/BreakpointContext";
import { JobCardTitleComponent } from "./JobCardTitleComponent";
import { JobCardBody } from "./JobCard/JobCardBody";
import { NotificationContext } from "context/NotificationContext";
import { ActiveJobApplication, defaultActiveJobApplication } from "adapters/JobAdapter";
import { GTMContext } from "context/GTMContext";
import { UserContext } from "context/UserContext";

interface JobCardPropTypes {
  sx?: Record<string, unknown>;
  topIndicator?: ReactNode;
  selected?: boolean;
  onToggleSelect?: (jobId: string) => void;
}

export const JobCard: FC<JobCardPropTypes & Job> = (props) => {
  const { sx, topIndicator, selected, onToggleSelect, ...job } = props;

  const { id = "" } = job;

  const { showModal, hideDialog } = useContext(ModalContext);
  const { isAuthenticated } = useContext(UserContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { addMessage } = useContext(NotificationContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);
  const [bodyIsExpanded, setBodyExpand] = useState(false);
  const [jobApplicationData, setJobApplicationData] = useState<ActiveJobApplication>(
    defaultActiveJobApplication
  );
  const [isSaved, setIsSaved] = useState(job.is_saved);

  useEffect(() => {
    if (job.job_application) {
      setJobApplicationData(job.job_application);
    }
  }, []);

  const removeApplication = async (applId: string) => {
    deleteJobApplication(applId)
      .then(() => {
        setJobApplicationData(defaultActiveJobApplication);
        hideDialog();
        addMessage({ type: "success", content: "success.removedSuccessful", duration: 2000 });
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.removeJobApplication", duration: 2000 });
      });
  };

  const handleApplyJobClick = useCallback(() => {
    if (isDesktop) {
      gtmAddToDataLayer({ action: "apply_now", label: job.title }, "cta_click");
      showModal("DETAILED_JOB_APPLICATION_MODAL", {
        job: job,
        applicationId: jobApplicationData.id,
        onRemoveApplication: removeApplication,
        setJobApplicationData,
      });
      return;
    }

    if (jobApplicationData.id !== "") {
      showModal("DETAILED_JOB_APPLICATION_MODAL", {
        job: job,
        applicationId: jobApplicationData.id,
        onRemoveApplication: removeApplication,
        setJobApplicationData,
      });
      return;
    }
    showModal("DETAILED_JOB_MODAL", {
      jobId: id,
      setIsSaved: setIsSaved,
      setJobApplicationData,
    });
  }, [isDesktop, showModal, job, jobApplicationData]);

  const handleJobClick = useCallback(() => {
    if (isDesktop) {
      if (bodyIsExpanded && window.getSelection()?.toString()) {
        return;
      }

      if (job.title && !bodyIsExpanded) {
        gtmAddToDataLayer({ dl_page: job.title, dl_page_path: `/${job.title}/` }, "dl_pageview");
        gtmAddToDataLayer({ logged_in: isAuthenticated ? "true" : "false" }, "user_status");
      }
      setBodyExpand(!bodyIsExpanded);
    } else {
      handleApplyJobClick();
    }
  }, [isDesktop, bodyIsExpanded, window.getSelection(), handleApplyJobClick, setBodyExpand, job]);

  return (
    <>
      <Card
        sx={
          job.job_status === "archived"
            ? {
                width: "100%",
                position: "relative",
                borderRadius: defaultStyles.borderRadius,
                boxShadow: "0",
                ...sx,
              }
            : {
                width: "100%",
                position: "relative",
                borderRadius: defaultStyles.borderRadius,
                boxShadow: defaultStyles.cardShadow,
                ...sx,
              }
        }
        elevation={4}
      >
        <Grid
          container
          direction="column"
          padding="1rem"
          gap=".5rem"
          sx={{
            backgroundColor: "secondary.main",
            cursor: !bodyIsExpanded && isDesktop ? "pointer" : "auto",
            boxShadow: selected ? `0 0 0 2px ${colors.main} inset` : "",
            ...(!bodyIsExpanded && isDesktop
              ? {
                  transition: "filter 0.2s ease-out",
                  "&:hover": {
                    filter: "contrast(0.9)",
                  },
                }
              : {}),
          }}
          onClick={handleJobClick}
        >
          <>
            {!!topIndicator && topIndicator}
            <JobCardTitleComponent
              hideTopButtons={job.job_status === "archived"}
              job={job}
              isSaved={isSaved}
              setIsSaved={setIsSaved}
              showStatus={false}
              iconVariant={isDesktop ? "expanded" : "options"}
              applicationData={job.job_application}
              selected={selected}
              onToggleSelect={onToggleSelect}
            />
          </>
          <JobCardBody
            job={job}
            applicationData={{
              ...jobApplicationData,
            }}
            opts={{
              isExpanded: bodyIsExpanded,
            }}
            handleApplyClick={handleApplyJobClick}
          />
        </Grid>
        {job.job_status === "archived" && (
          <Box
            sx={{
              position: "absolute",
              top: "0",
              right: "0",
              left: "0",
              bottom: "0",
              backgroundColor: colors.grey,
              opacity: 0.5,
            }}
          ></Box>
        )}
      </Card>
    </>
  );
};
