import React, { FC, useCallback, useContext, useState } from "react";
import { Dialog, Grid } from "@mui/material";
import { colors, defaultStyles } from "styles/colors";
import { SearchDropdown } from "components/SearchDropdown";
import { usePagination } from "hooks/usePagination";
import { User } from "reducers/UserReducer";
import { Job, JobApplication, getJobList, getUserList } from "adapters";
import { WIcon, WIconTypes } from "components/WIcon";
import {
  InternalJobApplication,
  createInternalJobApplication,
} from "adapters/InternalJobApplicationAdapter";
import { LocaleContext } from "context/LocaleContext";
import { WTypography } from "components/WTypography";
import { WIconButton } from "components/styledComponents/WIconButton";
import { WButton } from "components/styledComponents/WButton";
import { ModalContext } from "context/ModalContext";
import styled from "@emotion/styled";
import { NotificationContext } from "context/NotificationContext";
import { LoaderContext } from "context/LoaderContext";
import { KanbanJob } from "adapters/JobAdapter";

interface Props {
  job?: KanbanJob;
  candidate?: User;
  applications: JobApplication[];
  internalApplications: InternalJobApplication[];
  onConfirm: () => void;
}
export const CreateInternalApplicationDialog: FC<Props> = ({
  job,
  candidate,
  applications,
  internalApplications,
  onConfirm,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { hideDialog } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedJobs, setSelectedJobs] = useState<Job[]>([]);

  const {
    results: searchedUsers,
    handleSearch: handleUserSearch,
    setLastElementRef: setUserLastElementRef,
  } = usePagination<User>(getUserList, "error.fetchUsers", "GET_USER_LIST", { page_size: 7 }, true);

  const {
    results: searchedJobs,
    handleSearch: handleJobSearch,
    setLastElementRef: setJobLastElementRef,
  } = usePagination<Job>(
    getJobList,
    "error.fetchJobList",
    "GET_JOB_LIST",
    { status: "active", page_size: 7 },
    true
  );

  const onCreateApplications = useCallback(async () => {
    dispatchLoading({ payload: "CREATE_INTERNAL_JOB_APPLICATION", type: "SET_LOADING" });
    const data: { user: string; job: string }[] = [];
    if (job) {
      selectedUsers.forEach(({ id }) => {
        data.push({
          user: id,
          job: job.id,
        });
      });
    }
    if (candidate) {
      selectedJobs.forEach(({ id }) => {
        data.push({
          user: candidate.id,
          job: id,
        });
      });
    }
    await Promise.all(data.map(async (d) => await createInternalJobApplication(d)));
    addMessage({
      content:
        data.length > 0
          ? "success.internalApplicationsCreated"
          : "success.internalApplicationCreated",
      type: "success",
    });
    dispatchLoading({ payload: "CREATE_INTERNAL_JOB_APPLICATION", type: "STOP_LOADING" });
    onConfirm();
    hideDialog();
  }, [candidate, job, selectedJobs, selectedUsers, onConfirm]);

  return (
    <Dialog open={true}>
      <Grid container direction="column" padding="40px" gap="30px" bgcolor={colors.white}>
        <Grid item>
          <WTypography variant="h3">{localize("internalApplication.createInternal")}</WTypography>
          <WTypography variant="body2" lineClamps={100}>
            {localize("internalApplication.explanation")}
          </WTypography>
        </Grid>

        {!candidate ? (
          <Grid item container direction="column" gap="10px">
            <Grid item>
              <WTypography variant="body2">
                <WIcon icon={WIconTypes.person} size="tiny" />
                {localize("internalApplication.selectedCandidates")}
              </WTypography>
            </Grid>
            <List item container>
              {selectedUsers.length === 0 ? (
                <WTypography variant="body2" fontStyle="italic">
                  {localize("internalApplication.noUserSelection")}
                </WTypography>
              ) : (
                selectedUsers.map(({ id, first_name, last_name, email }, index) => (
                  <Grid key={id} item container wrap="nowrap">
                    <WTypography lineClamps={1} marginRight="auto">
                      {first_name} {last_name} ({email})
                    </WTypography>
                    <WIconButton
                      size="small"
                      onClick={() =>
                        setSelectedUsers([
                          ...selectedUsers.slice(0, index),
                          ...selectedUsers.slice(index + 1),
                        ])
                      }
                    >
                      <WIcon icon={WIconTypes.trash} size="small" />
                    </WIconButton>
                  </Grid>
                ))
              )}
            </List>
            <Grid item>
              <SearchDropdown
                items={searchedUsers.map(({ id, first_name, last_name, email }) => ({
                  value: id,
                  icon: WIconTypes.person,
                  text: `${first_name} ${last_name} (${email})`,
                  disabled:
                    !!applications.find((a) => a.user.id === id) ||
                    !!internalApplications.find((a) => a.user.id === id) ||
                    !!selectedUsers.find((u) => u.id === id),
                }))}
                onSearch={(text: string) => handleUserSearch({ search: text })}
                onSelect={(value) => {
                  const user = searchedUsers.find((u) => u.id === value);
                  if (!user) {
                    return;
                  }
                  setSelectedUsers([...selectedUsers, user]);
                }}
                setLastElementRef={setUserLastElementRef}
                loaderAction="GET_USER_LIST"
                placement="bottom"
                label={localize("internalApplication.searchCandidates")}
              />
            </Grid>
          </Grid>
        ) : null}

        {!job ? (
          <Grid item container direction="column" gap="10px">
            <Grid item>
              <WTypography variant="body2">
                <WIcon icon={WIconTypes.job} size="tiny" />
                {localize("internalApplication.selectedAssignments")}
              </WTypography>
            </Grid>
            <List item container>
              {selectedJobs.length === 0 ? (
                <WTypography variant="body2" fontStyle="italic">
                  {localize("internalApplication.noAssignmentSelection")}
                </WTypography>
              ) : (
                selectedJobs.map(({ id, title }, index) => (
                  <Grid key={id} item container wrap="nowrap">
                    <WTypography lineClamps={1} marginRight="auto">
                      {title}
                    </WTypography>
                    <WIconButton
                      size="small"
                      onClick={() =>
                        setSelectedJobs([
                          ...selectedJobs.slice(0, index),
                          ...selectedJobs.slice(index + 1),
                        ])
                      }
                    >
                      <WIcon icon={WIconTypes.trash} size="small" />
                    </WIconButton>
                  </Grid>
                ))
              )}
            </List>
            <Grid item>
              <SearchDropdown
                items={searchedJobs.map(({ id, title }) => ({
                  value: id,
                  icon: WIconTypes.job,
                  text: title,
                  disabled:
                    !!applications.find((a) => a.job.id === id) ||
                    !!internalApplications.find((a) => a.job.id === id) ||
                    !!selectedJobs.find((j) => j.id === id),
                }))}
                onSearch={(text: string) => handleJobSearch({ search: text })}
                onSelect={(value) => {
                  const job = searchedJobs.find((j) => j.id === value);
                  if (!job) {
                    return;
                  }
                  setSelectedJobs([...selectedJobs, job]);
                }}
                setLastElementRef={setJobLastElementRef}
                loaderAction="GET_JOB_LIST"
                placement="bottom"
                label={localize("internalApplication.searchAssignments")}
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid item container justifyContent="space-between">
          <Grid item>
            <WButton variant="text" onClick={() => hideDialog()}>
              {localize("common.cancel")}
            </WButton>
          </Grid>
          <Grid item>
            <WButton
              variant="contained"
              disabled={
                (selectedJobs.length === 0 && selectedUsers.length === 0) ||
                isLoading("CREATE_INTERNAL_JOB_APPLICATION")
              }
              onClick={onCreateApplications}
            >
              {localize("common.confirm")}
            </WButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const List = styled(Grid)`
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 3px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  background-color: ${colors.lightGrey};
  border-radius: ${defaultStyles.borderRadius};
`;
