import { CircularProgress, Grid, Typography } from "@mui/material";
import { JobMatch, getJobMatchesList, removeJobMatch } from "adapters";
import { JobCard } from "components/JobCard";
import { SwipeableElement } from "components/SwipeableElement";
import { LoaderContext } from "context/LoaderContext";
import { usePagination } from "hooks/usePagination";
import React, { FC, useContext } from "react";
import { NotificationContext } from "context/NotificationContext";
import { LocaleContext } from "context/LocaleContext";
import { CircularPercentage } from "components/JobMatchesView/CircularPercentage";

const JobMatchesView: FC = () => {
  const { isLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { localize } = useContext(LocaleContext);

  const { results, setLastElementRef, updatePage } = usePagination<JobMatch>(
    getJobMatchesList,
    "error.fetchJobList"
  );

  const removeMatch = async (jobId: string) => {
    return removeJobMatch(jobId)
      .then(() => {
        setTimeout(() => {
          updatePage();
          addMessage({ type: "success", content: "success.removedSuccessful", duration: 2000 });
        }, 1000);
        return true;
      })
      .catch(() => {
        addMessage({ type: "error" });
        return false;
      });
  };

  return (
    <div className="contentWrapper">
      <Grid container gap="1rem">
        <Typography width="100%" variant="h6" align="center" color="primary.dark">
          {localize("routes.matches")}
        </Typography>
        {isLoading("GET_PAGINATION_RESULTS") ? (
          <CircularProgress sx={{ margin: "auto" }} />
        ) : !results.length ? (
          <Typography> {localize("common.emptyJobMatches")}</Typography>
        ) : (
          results.map((jobMatch, index) => {
            const isLastElement = results.length === index + 1;
            return isLastElement ? (
              <div ref={setLastElementRef} key={jobMatch.id} style={{ width: "100%" }}>
                <SwipeableElement handleRemove={() => removeMatch(jobMatch.id)}>
                  <JobCard
                    {...jobMatch.job}
                    created=""
                    topIndicator={
                      jobMatch.match_percentage !== "0%" ? (
                        <Grid container gap=".5rem">
                          <CircularPercentage percentage={parseInt(jobMatch.match_percentage)}>
                            <Typography variant="body2" color="success.main">
                              {jobMatch.match_percentage}
                            </Typography>
                          </CircularPercentage>
                          <Typography alignSelf="center" color="success.main">
                            {localize("common.matchingGrade")}
                          </Typography>
                        </Grid>
                      ) : undefined
                    }
                  />
                </SwipeableElement>
              </div>
            ) : (
              <SwipeableElement key={jobMatch.id} handleRemove={() => removeMatch(jobMatch.id)}>
                <JobCard
                  {...jobMatch.job}
                  topIndicator={
                    <Grid container>
                      <CircularPercentage percentage={parseInt(jobMatch.match_percentage)}>
                        <Typography variant="body2" color="success.main">
                          {jobMatch.match_percentage}
                        </Typography>
                      </CircularPercentage>
                    </Grid>
                  }
                />
              </SwipeableElement>
            );
          })
        )}
      </Grid>
    </div>
  );
};
export default JobMatchesView;
