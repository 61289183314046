import { Grid, IconButton, Tooltip } from "@mui/material";
import React, { FC, ReactNode, useState } from "react";
import { Variant } from "@mui/material/styles/createTypography";
import { colors } from "styles/colors";
import { WButton, WIcon, WIconButton, WIconTypes, WTypography } from "components";

interface DialogHeaderProps {
  rightSideContent?: ReactNode;
  title?: string;
  infoToolTip?: string;
  titleVariant?: Variant;
  returnText?: string;
  iconSize?: "large";
  lineClampTitle?: number;
  handleClose: () => void;
}

export const DialogHeader: FC<DialogHeaderProps> = ({
  handleClose,
  title,
  titleVariant,
  returnText,
  iconSize,
  infoToolTip,
  lineClampTitle,
  rightSideContent,
}) => {
  const [infoTooltipOpen, openInfoTooltip] = useState(false);

  return (
    <Grid
      container
      padding="1rem 0 .5rem 0"
      direction="column"
      sx={{ position: "sticky", top: 0, backgroundColor: colors.white, zIndex: "10" }}
      wrap="nowrap"
      borderBottom="1px solid"
      borderColor={colors.grey}
    >
      <Grid container alignItems="center" gap="1rem" padding="0rem 1rem" wrap="nowrap">
        <Grid container alignItems="center" onClick={handleClose}>
          <IconButton aria-label="Back">
            <WIcon icon={WIconTypes.arrowLeft} size={iconSize || "medium"} />
          </IconButton>
          <WButton sx={{ color: colors.textDarkLighter, fontSize: "1rem" }}>{returnText}</WButton>
        </Grid>
        {rightSideContent && (
          <Grid container width="fit-content" marginLeft="auto" justifyContent="flex-end">
            {rightSideContent}
          </Grid>
        )}
      </Grid>
      {title && (
        <Grid container wrap="nowrap" alignItems="center">
          <WTypography
            variant={titleVariant ? titleVariant : "h6"}
            fontSize="2rem"
            color={colors.mainDark}
            fontWeight="bold"
            paddingLeft="2rem"
            paddingRight="1rem"
            lineClamps={lineClampTitle}
          >
            {title}
          </WTypography>
          {infoToolTip && (
            <Tooltip
              arrow
              title={infoToolTip}
              open={infoTooltipOpen}
              onClose={() => openInfoTooltip(false)}
              leaveDelay={3000}
              leaveTouchDelay={3000}
              PopperProps={{
                popperOptions: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -8],
                      },
                    },
                  ],
                },
              }}
            >
              <WIconButton onClick={() => openInfoTooltip(true)} sx={{ height: "max-content" }}>
                <WIcon icon={WIconTypes.info} color="primary" />
              </WIconButton>
            </Tooltip>
          )}
        </Grid>
      )}
    </Grid>
  );
};
