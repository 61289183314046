import React, { FC, useContext, useEffect, useState } from "react";
import { Card, Grid, Typography } from "@mui/material";
import { AvatarItem, WIcon, WIconTypes, WTypography } from "components";
import { LocaleContext } from "context/LocaleContext";
import { User } from "reducers/UserReducer";
import { colors, defaultStyles } from "styles/colors";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import { AvailabilityTag } from "components/AvailabilityTag";
import { UserCardLabels } from "components/UserCardLabels";
import { getUserApplications, JobApplication } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { DataChip } from "components/DataChip";
import { addMonths } from "date-fns";
import { prettifyDate } from "utils/PrettifyDate";

const CREATION_LIMIT = 3; //months

interface UserListCardProps {
  userId: string;
}

export const UserListCard: FC<UserListCardProps> = ({ userId }) => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { showModal } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);
  const [userApplications, setUserApplications] = useState<JobApplication[]>([]);
  const { users } = useContext(UserContext);

  const user = users.find((u) => u.id === userId);

  useEffect(() => {
    if (!user) {
      return;
    }

    const getApplications = () => {
      dispatchLoading({ type: "SET_LOADING", payload: "GET_USER_APPLICATIONS" });
      const limit = prettifyDate(addMonths(new Date(), -CREATION_LIMIT).toISOString());
      const page_limit = 3;

      getUserApplications(userId, { created_gte: limit, page_size: page_limit })
        .then((res) => {
          setUserApplications(res.data.results);
        })
        .catch(() => {
          addMessage({ type: "error" });
        })
        .finally(() => dispatchLoading({ type: "STOP_LOADING", payload: "GET_USER_APPLICATIONS" }));
    };

    getApplications();
  }, [user]);

  if (!user) {
    return null;
  }

  const handleUserClick = () => {
    showModal("CANDIDATE_MODAL", {
      candidateId: userId,
    });
  };

  return (
    <Card
      sx={{
        cursor: "pointer",
        width: "100%",
        position: "relative",
        borderRadius: defaultStyles.borderRadius,
        boxShadow: defaultStyles.cardShadow,
        padding: "1.5rem 1rem 1rem 1rem",
        overflow: "visible",
        transition: "all 0.2s ease-out",
        "&:hover": { backgroundColor: colors.grey },
      }}
      onClick={handleUserClick}
      elevation={4}
    >
      <UserCardLabels wiseUser={user} />

      <Grid container wrap="nowrap">
        <Grid container alignItems="center" gap="1rem" wrap="nowrap">
          <AvatarItem
            size="small"
            imageSrc={user.profile_picture}
            key={user.profile_picture}
            firstName={user.first_name}
            lastName={user.last_name}
          />
          <Grid container direction="column" gap="0.3rem" minWidth="40%">
            <Grid container gap=".3rem" wrap="nowrap" alignItems="center">
              <Typography variant="h6" fontWeight="bold" color={colors.mainDark} noWrap>
                {user.first_name} {user.last_name}
              </Typography>
            </Grid>
            <Grid container gap=".3rem" wrap="nowrap">
              <WTypography lineClamps={1} variant="body1" color={colors.mainDark} width="100%">
                {user.email}
              </WTypography>
            </Grid>
            <Grid container gap=".3rem" wrap="nowrap" overflow="hidden">
              <Applications applications={userApplications} />
            </Grid>
            <Grid container gap=".3rem" wrap="nowrap" overflow="hidden">
              <BottomRow user={user} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

interface BottomRowProps {
  user: User;
}
const BottomRow: FC<BottomRowProps> = ({ user }) => {
  if (user.skills.length > 0 || user.availability_date) {
    return (
      <>
        <Grid container gap=".5rem" height="24px">
          {user.skills.slice(0, 6).map((skill) => (
            <DataChip key={skill.id} chipLabel={skill.name} />
          ))}
        </Grid>
        <AvailabilityTag date={user.availability_date} />
      </>
    );
  }

  return null;
};

interface ApplicationsProps {
  applications: JobApplication[];
}

const Applications: FC<ApplicationsProps> = ({ applications }) => {
  const { localize } = useContext(LocaleContext);
  const numberOfApplicationToShow = 3;

  const getNumberOfApplicationsNotShown = (jobApplicationsLength: number) => {
    const numberOfApplicationNotShown = jobApplicationsLength - numberOfApplicationToShow;
    if (jobApplicationsLength > numberOfApplicationToShow) {
      return `+ ${numberOfApplicationNotShown} ${localize("agentStartPage.moreApplications")}`;
    }
    return null;
  };

  if (applications.length === 0) {
    return null;
  }

  return (
    <>
      <WIcon icon={WIconTypes.application} size="small" color="secondaryDark" />
      <Grid container wrap="nowrap" direction="column">
        {applications.slice(0, numberOfApplicationToShow).map((application) => {
          return (
            <WTypography
              lineClamps={1}
              variant="body2"
              color={colors.darkGrey}
              key={application.id}
            >
              {application.job.title}
            </WTypography>
          );
        })}
        <WTypography variant="body2" color={colors.darkGrey}>
          {getNumberOfApplicationsNotShown(applications.length)}
        </WTypography>
      </Grid>
    </>
  );
};
