import { FC, useEffect } from "react";

interface Props {
  title?: string;
  desc?: string;
  img?: string;
}

const Meta: FC<Props> = ({ title, desc, img }) => {
  useEffect(() => {
    if (title) {
      document.querySelector(`meta[property="og:title"]`)?.setAttribute("content", title);
    }
    if (desc) {
      document.querySelector(`meta[property="og:description"]`)?.setAttribute("content", desc);
      document.querySelector(`meta[name="description"]`)?.setAttribute("content", desc);
    }
    if (img) {
      document.querySelector(`meta[property="og:image"]`)?.setAttribute("content", img);
    }
  });

  return null;
};

export default Meta;
