import { TextField } from "@mui/material";
import { styled } from "@mui/material";
import { defaultStyles } from "styles/colors";

export const FilterTextField = styled(TextField)(
  ({ theme }) => `
  & .Mui-focused {
    font-weight: bold;
    background-color: ${theme.palette.secondary.main};
    svg {
      color: ${theme.palette.primary.main};
    }
  }
  & .MuiInputLabel-root {
    width: 80%;
    color: ${theme.palette.text.primary}
  };
  & .MuiOutlinedInput-root {
    border-radius: ${defaultStyles.borderRadiusLarge};
  }
`
);

FilterTextField.defaultProps = {
  size: "small",
  fullWidth: true,
  select: true,
  InputLabelProps: {
    shrink: false,
  },
};
