import React, { FC, useContext, useState } from "react";
import { Grid, Box, InputAdornment } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { colors, defaultStyles } from "styles/colors";
import { WIcon, WTextField, WIconTypes } from "components";
import { Region, Role } from "adapters";

export type FiltersType = {
  regions: Region[];
  roles: Role[];
};

interface AdvancedSearchProps {
  onSearch: (search: string) => void;
  customBorder?: boolean;
  placeholderType?: "searchAssignment" | "searchUser";
  currentSearch?: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const AdvancedSearch: FC<AdvancedSearchProps> = ({
  onSearch,
  currentSearch,
  onFocus,
  placeholderType = "searchAssignment",
  customBorder = false,
}) => {
  const { localize } = useContext(LocaleContext);

  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (value: string) => {
    setInputValue(value);
    // If previous search exists and current input is empty, clear pagination
    if (currentSearch && value === "") {
      onSearch(value);
    }
  };

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault();
    if (inputValue) {
      onSearch(inputValue);
    }
  };

  return (
    <form onSubmit={submitForm} style={{ width: "100%" }}>
      <Box>
        <Grid
          container
          flexWrap="nowrap"
          sx={{
            backgroundColor: "secondary.main",
            borderRadius: defaultStyles.borderRadiusSearchBar,
            height: "40px",
            maxWidth: customBorder ? "100%" : "530px",
            border: customBorder ? `1px solid ${colors.searchBarBorder}` : undefined,
          }}
          paddingRight=".5rem"
          alignItems="center"
        >
          <Grid container>
            <WTextField
              onFocus={onFocus}
              variant="standard"
              fullWidth
              value={inputValue}
              onChange={(event) => handleInputChange(event.target.value)}
              placeholder={localize(`searchbar.${placeholderType}`)}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ opacity: inputValue.length > 0 ? "1" : "0.45" }}
                  >
                    <span
                      onClick={submitForm}
                      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                    >
                      <WIcon icon={WIconTypes.search} />
                    </span>
                  </InputAdornment>
                ),
              }}
              type="text"
              sx={{
                padding: ".5rem",
                alignItems: "center",
                ".MuiInput-input": {
                  padding: 0,
                },
                ".MuiInput-input:focus::placeholder": {
                  color: "transparent",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
