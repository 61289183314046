import {
  differenceInDays,
  differenceInYears,
  format,
  formatDistanceStrict,
  isValid,
  parseISO,
} from "date-fns";
import locale from "date-fns/locale/en-US";

export enum WDateFormats {
  timestamp = "HH:mm",
  date = "yyyy-MM-dd",
  dayFirst = "dd-MM-yyyy",
  dayAndMonth = "d MMM",
  dayMonthYear = "d MMM yyyy",
  dateTime = "yyyy-MM-dd HH:mm",
}

export const prettifyDate = (
  date: string,
  options?: { dateFormat?: string; showRelativeTime?: boolean }
): string => {
  const parsedDate = parseISO(date);
  if (!isValid(parsedDate)) {
    return "";
  }
  const today = new Date();

  // Overiding the formatDistance function to custom localization
  const formatDistance = (token: string, count: string) => {
    const formatDistanceLocale = {
      xSeconds: `${count}s`,
      xMinutes: `${count}m`,
      xHours: `${count}h`,
      xDays: `${count}d`,
    };
    return formatDistanceLocale[token];
  };

  // Show relativeTime such as "5h" or "3d"
  if (options?.showRelativeTime) {
    // If difference is larger than 1 year, show day and month and year format
    if (differenceInYears(today, parsedDate) >= 1) {
      return format(parsedDate, WDateFormats.dayMonthYear);
    }
    // If difference is larger than 6 days, show day and month format
    if (differenceInDays(today, parsedDate) > 6) {
      return format(parsedDate, WDateFormats.dayAndMonth);
    }

    return formatDistanceStrict(today, parsedDate, {
      locale: {
        ...locale,
        formatDistance,
      },
    });
  }

  return format(parsedDate, options?.dateFormat ?? WDateFormats.date);
};
