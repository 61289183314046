import { Grid, Box } from "@mui/material";
import { UserDocument } from "adapters/UserDocumentAdapter";
import { WTypography } from "components";
import React, { FC, ReactNode } from "react";
import { colors, defaultStyles } from "styles/colors";
import { prettifyFilename } from "utils/generalUtils";
import { prettifyDate } from "utils/PrettifyDate";

interface UserDocumentCardProps {
  icons?: ReactNode;
  isLast?: boolean;
  userDocument: UserDocument;
  css?: {
    backgroundColor?: string;
    iconSize?: string;
    textColor?: string;
    titleSize?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body1" | "body2";
    subtitleSize?: "body1" | "body2" | "caption";
    border?: string;
    padding?: string;
  };
}

export const UserDocumentCard: FC<UserDocumentCardProps> = ({
  icons,
  userDocument,
  isLast,
  css,
}) => {
  const fileExtensionString = userDocument.user_document.substring(
    userDocument.user_document.lastIndexOf(".") + 1
  );
  let fileExtension = "";
  if (fileExtensionString.indexOf("docx") > -1) {
    fileExtension = "docx";
  } else if (fileExtensionString.indexOf("doc") > -1) {
    fileExtension = "doc";
  } else {
    fileExtension = "pdf";
  }

  const iconSize = css?.iconSize ?? "60px";
  const textColor = css?.textColor ?? colors.textDark;

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        backgroundColor: css?.backgroundColor ?? colors.white,
        borderRadius: isLast ? defaultStyles.borderRadiusBottom : "0",
      }}
      padding={css?.padding ?? "1rem"}
      borderBottom={css?.border ?? `1px solid ${colors.grey}`}
      flexWrap="nowrap"
      gap="1rem"
    >
      <img
        height={iconSize}
        width={iconSize}
        alt="CV Icon"
        src={
          fileExtension === "pdf"
            ? `${process.env.PUBLIC_URL}/assets/images/pdfIcon.png`
            : `${process.env.PUBLIC_URL}/assets/images/wordIcon.png`
        }
      />

      <Box width="65%" textAlign="left">
        <WTypography fontWeight="bold" variant={css?.titleSize ?? "body1"}>
          {prettifyFilename(userDocument.alias)}
        </WTypography>
        <WTypography variant={css?.subtitleSize ?? "body2"} color={textColor}>{`${prettifyDate(
          userDocument.created
        )} · ${fileExtension}`}</WTypography>
      </Box>
      {icons && <Grid marginLeft="auto">{icons}</Grid>}
    </Grid>
  );
};
