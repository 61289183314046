import { Grid, Typography } from "@mui/material";
import { DynamicHeader, Navbar, WIcon, WIconButton, WIconTypes } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { UserInfoComponent } from "components/UserInfoComponent";
import { ProfileTabList } from "components/ProfileView/ProfileTabList";
import { BreakpointsContext } from "context/BreakpointContext";
import { UserContext } from "context/UserContext";
import { useHistory } from "react-router-dom";
import { generateWelcomeMessage } from "utils/GenerateWelcomeMessage";

interface ProfileHeaderProps {
  handleAvatarPicker: () => void;
  tabCallback?: (tab: number) => void;
  currentTab: number;
}

export const ProfileHeader: FC<ProfileHeaderProps> = ({
  currentTab,
  handleAvatarPicker,
  tabCallback,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { user } = useContext(UserContext);

  const history = useHistory();
  const { welcomeMessage } = generateWelcomeMessage();

  return (
    <DynamicHeader stopDynamicBehavior={isDesktop}>
      {isDesktop ? (
        <Navbar title={`${welcomeMessage} ${user.first_name}`} />
      ) : (
        <>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            sx={{
              maxWidth: {
                xs: "100%",
                md: "500px",
              },
            }}
            gap="1rem"
          >
            <Typography variant="h5" fontWeight="bold" color="primary" id="elementToTransform">
              {localize("profileView.headerMessage")}
            </Typography>
          </Grid>
          <WIconButton
            sx={{ position: "absolute", top: "1rem", right: "1rem" }}
            onClick={() => history.push("/settings")}
          >
            <WIcon icon={WIconTypes.settings} color="primary" />
          </WIconButton>
          <UserInfoComponent handleAvatarPicker={handleAvatarPicker} />
        </>
      )}
      <ProfileTabList tabCallback={tabCallback} activeTab={currentTab} />
    </DynamicHeader>
  );
};
