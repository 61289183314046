import React, { FC, HTMLAttributes, useContext } from "react";
import { Button, Grid } from "@mui/material";
import { Job } from "adapters/JobAdapter";
import { WTypography } from "components/WTypography";
import { colors } from "styles/colors";
import { useHistory } from "react-router-dom";
import { AvatarItem } from "components/AvatarItem";
import { ApplicationStageCircles } from "components/ApplicationStageCircles";
import { KanbanItemWidth } from "./KanbanColumn";
import { ModalContext } from "context/ModalContext";

interface Props extends HTMLAttributes<HTMLDivElement> {
  job: Job;
}

export const JobItemHeight = "150px";

export const JobItem: FC<Props> = (props) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const { job, ...restProps } = props;
  const { title, pictures, id, recruiter } = job;

  const recruiterIsObject = typeof recruiter === "object";

  let name = "";
  if (!recruiterIsObject) {
    name = recruiter;
  } else if (recruiter.first_name || recruiter.last_name) {
    name = `${recruiter.first_name} ${recruiter.last_name}`;
  } else {
    name = recruiter.email;
  }

  return (
    <Grid
      container
      direction="column"
      flexWrap="nowrap"
      width={KanbanItemWidth}
      height={JobItemHeight}
      borderRadius="5px"
      bgcolor={job.job_status === "open" ? `${colors.lightGrey}` : colors.lightBorder}
      padding="16px"
      justifyContent="space-between"
      onClick={() => {
        history.push(`/kanban/jobs/${id}`);
      }}
      id={job.id}
      {...restProps}
    >
      <Grid item>
        <Button
          variant="text"
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            width: "calc(100% + 16px)",
            margin: "-6px -8px",
            ":hover": {
              backgroundColor: colors.mainLight,
            },
          }}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            showModal("DETAILED_JOB_MODAL", { jobId: id });
          }}
        >
          <img
            src={
              pictures.length
                ? pictures[0].thumb
                : `${process.env.PUBLIC_URL}/assets/logo/icon_64.png`
            }
            alt="Job icon"
            width="25px"
            height="25px"
            style={{ objectFit: "contain" }}
          />
          <WTypography
            fontWeight="bold"
            fontSize="14px"
            lineClamps={1}
            textAlign="left"
            marginLeft="5px"
          >
            {title}
          </WTypography>
        </Button>
      </Grid>
      <Grid item display="flex" alignItems="center">
        <AvatarItem
          size="xtiny"
          imageSrc={recruiterIsObject ? recruiter.profile_picture || undefined : undefined}
          firstName={recruiterIsObject ? recruiter.first_name : recruiter}
          lastName={recruiterIsObject ? recruiter.last_name : ""}
        />
        <WTypography
          fontSize="14px"
          color={colors.textDarkLighter}
          lineClamps={1}
          width="fit-content"
          marginLeft="5px"
        >
          {name}
        </WTypography>
      </Grid>
      <ApplicationStageCircles job={job} />
      {job.job_status !== "open" ? (
        <WTypography fontSize="14px" textAlign="right" color={colors.textDarkLighter}>
          {job.job_status}
        </WTypography>
      ) : null}
    </Grid>
  );
};
