import { Grid, Link, Tooltip, Typography } from "@mui/material";
import { createConversation } from "adapters/ConversationAdapter";
import { AvatarItem } from "components/AvatarItem";
import { WButton } from "components/styledComponents/WButton";
import { UserRatingLabel } from "components/UserRatingLabel";
import { WIcon, WIconTypes } from "components/WIcon";
import { WTypography } from "components/WTypography";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, SyntheticEvent, useContext } from "react";
import { User } from "reducers/UserReducer";
import { colors } from "styles/colors";
import { cleanLinkedInLink } from "utils/generalUtils";
import { JobApplication, updateAgentUserRating } from "adapters";
import { InternalJobApplication } from "adapters/InternalJobApplicationAdapter";

interface Props {
  candidate: User;
  onUpdateRating: (rating: number) => void;
  applications: JobApplication[];
  internalApplications: InternalJobApplication[];
  onCreateInternal: () => void;
}

export const GeneralInformation: FC<Props> = ({
  candidate,
  onUpdateRating,
  applications,
  internalApplications,
  onCreateInternal,
}) => {
  const { localize } = useContext(LocaleContext);
  const { showModal, showDialog } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);
  const {
    user: { id: agentId },
  } = useContext(UserContext);
  const textColor = colors.darkGrey;
  const titleColor = colors.mainDark;

  const {
    first_name,
    last_name,
    email,
    phone,
    linkedin_url,
    rating,
    profile_picture,
    departments,
  } = candidate;

  const generalInformation = [
    {
      header: "phone",
      content: phone ?? "-",
      href: "",
    },
    {
      header: "email",
      content: email ?? "-",
      href: `mailto:${email}`,
    },
    {
      header: "linkedIn",
      content: cleanLinkedInLink(linkedin_url ?? "-"),
      href: linkedin_url ?? "/",
    },
  ];

  const startSupportConversation = () => {
    createConversation(false, "Wise Support Chat", [agentId, candidate.id])
      .then((res) => {
        showModal("MESSAGE_MODAL", { conversationId: res.data.id });
      })
      .catch(() => {
        addMessage({ type: "error" });
      });
  };

  const handleStartSupportConversationClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    startSupportConversation();
  };

  const actionButtons = [
    {
      tooltipText: localize("candidateModal.newMessage"),
      icon: WIconTypes.message,
      action: handleStartSupportConversationClick,
    },
    {
      tooltipText: localize("internalApplication.createInternal"),
      icon: WIconTypes.application,
      action: () => {
        if (!candidate) {
          return;
        }
        showDialog("CREATE_INTERNAL_APPLICATION", {
          candidate,
          applications,
          internalApplications,
          onConfirm: onCreateInternal,
        });
      },
    },
  ];

  return (
    <Grid container alignItems="center" gap="1rem" wrap="nowrap" justifyContent="space-between">
      <AvatarItem
        size="small"
        imageSrc={profile_picture}
        firstName={first_name}
        lastName={last_name}
      />
      <Grid container direction="column">
        <Grid container gap="10px" wrap="nowrap" alignItems="center">
          <Grid container>
            <Typography variant="h6" fontWeight="bold" color={titleColor}>
              {first_name} {last_name}
            </Typography>
            <UserRatingLabel
              rating={rating}
              canRate
              handleSetUserRating={(rating) => {
                onUpdateRating(rating);
                updateAgentUserRating(candidate.id, { rating });
              }}
            />
          </Grid>

          <Grid item xs={6} textAlign="end">
            {actionButtons.map(({ tooltipText, icon, action }) => (
              <Tooltip
                key={tooltipText}
                title={tooltipText}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: colors.mainDark,
                      fontWeight: "bold",
                      "& .MuiTooltip-arrow": {
                        color: colors.mainDark,
                      },
                    },
                  },
                }}
              >
                <WButton
                  variant="outlined"
                  sx={{ maxWidth: "max-content", marginRight: "10px" }}
                  onClick={action}
                >
                  <WIcon icon={icon} size="small" />
                </WButton>
              </Tooltip>
            ))}
          </Grid>
        </Grid>

        {departments.length > 0 && (
          <Grid item alignItems="center" marginTop="-5px" marginBottom="5px">
            <Typography variant="body2" color={textColor}>
              {departments[0].name}
            </Typography>
          </Grid>
        )}

        {generalInformation.map(({ header, content, href }) => (
          <Grid container gap="10px" key={header}>
            <Grid item xs={1}>
              <Typography variant="body2" fontWeight="bold" color={textColor}>
                {localize(`candidateModal.${header}`)}
              </Typography>
            </Grid>
            <Grid item xs>
              <WTypography lineClamps={1} variant="body2" color={textColor} width="100%" noWrap>
                {!href ? (
                  content
                ) : (
                  <Link href={href} color={textColor}>
                    {content}
                  </Link>
                )}
              </WTypography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
