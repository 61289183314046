import React, { FC, useContext, useEffect, useState } from "react";
import ConversationList from "components/ConversationList";
import { LocaleContext } from "context/LocaleContext";
import { Card, Grid, Typography } from "@mui/material";
import { WButton, WIcon, WIconButton, WIconTypes } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { createConversation } from "adapters/ConversationAdapter";
import { UserContext } from "context/UserContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { colors, defaultStyles } from "styles/colors";
import { ConversationContentComponent } from "components/modals/Messages/ConversationContentComponent";
import { useParams } from "react-router";
import { MessageHeader } from "components/headers/MessageHeader";

interface MessagesViewProps {
  conversationId?: string;
}

const MessagesView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { user } = useContext(UserContext);
  const { showModal, hideModal } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);
  const [refreshCounter, setRefreshCounter] = useState(1);
  const [selectedConvID, setSelectedConv] = useState("");
  const [updateConvList, setUpdateConvList] = useState(false);
  const { conversationId } = useParams<MessagesViewProps>();

  useEffect(() => {
    if (conversationId) {
      if (conversationId === "newMessage") {
        startSupportConversation();
        return;
      }

      if (!isDesktop) {
        showModal("MESSAGE_MODAL", {
          conversationId: conversationId,
          onClose: handleCloseNewConversation,
        });
      }
      handleConvSelect(conversationId);
      return;
    }
    handleConvSelect("");
  }, [conversationId]);

  const startSupportConversation = () => {
    createConversation(true, localize("messagesView.chatWithAgentNewMessageSubject"), [user.id])
      .then((res) => {
        if (!isDesktop) {
          showModal("MESSAGE_MODAL", {
            conversationId: res.data.id,
            onClose: handleCloseNewConversation,
          });
        }
        setSelectedConv(res.data.id);
      })
      .catch(() => {
        addMessage({ content: "error.createConversation", type: "error" });
      });
  };

  const handleCloseNewConversation = () => {
    // updating the key causes refresh of conversationlist so latest conversation is visable.
    setRefreshCounter((prev) => prev + 1);
    hideModal();
  };

  const handleConvSelect = (id: string) => {
    setSelectedConv(id);
  };

  const handleUpdateConvList = () => {
    setUpdateConvList(!updateConvList);
  };

  if (isDesktop) {
    return (
      <>
        <MessageHeader />
        <Grid container wrap="nowrap" className="contentWrapper">
          <Grid container wrap="nowrap" gap="1rem">
            <Grid container item md={4} minWidth="320px" gap="1rem" direction="column">
              <WButton
                startIcon={<WIcon icon={WIconTypes.write} />}
                fullWidth
                sx={{ maxHeight: "max-content" }}
                variant="outlined"
                onClick={startSupportConversation}
              >
                {localize("messagesView.writeMessagePlaceholder")}
              </WButton>
              <ConversationList
                key={`conversation_${refreshCounter}`}
                fullscreen={false}
                setSelectedConv={handleConvSelect}
                forceUpdate={updateConvList}
                selectedID={selectedConvID}
              />
            </Grid>
            {!!selectedConvID.length && (
              <Grid container item md={8}>
                <Card
                  sx={{
                    width: "100%",
                    height: "max-content",
                    position: "relative",
                    borderRadius: defaultStyles.borderRadius,
                    backgroundColor: colors.white,
                    boxShadow: defaultStyles.cardShadow,
                  }}
                  elevation={4}
                >
                  <ConversationContentComponent
                    conversationId={selectedConvID}
                    handleNewConvStarted={handleUpdateConvList}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "primary.light",
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${
            isDesktop ? "dt_vector" : "mb_vector"
          }.svg)`,
          backgroundPositionX: "right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "60%",
          top: "0",
          right: "0",
        }}
      >
        <Typography padding="2rem" variant="h4" fontWeight="bold" color="primary">
          {localize("common.yourMessages")}
        </Typography>
      </Grid>
      <Grid
        padding=".5rem 1rem"
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Typography width="100%" variant="h6" color="primary.dark">
          {localize("messagesView.writeMessagePlaceholder")}
        </Typography>
        <WIconButton onClick={() => startSupportConversation()}>
          <WIcon icon={WIconTypes.write} />
        </WIconButton>
      </Grid>
      <ConversationList
        key={`conversation_${refreshCounter}`}
        setSelectedConv={handleConvSelect}
        selectedID={selectedConvID}
      />
    </>
  );
};

export default MessagesView;
