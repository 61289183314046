import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export interface Organisation {
  id: string;
  name: string;
  company_name: string;
  org_nr: string;
  tax_document: File | string | null;
  liability_insurance: File | string | null;
}

export type OrganisationActions =
  | "GET_ORGANISATION"
  | "REMOVE_ORGANISATION"
  | "UPDATE_ORGANISATION"
  | "ADD_ORGANISATION";

export const createOrganisation = async (
  data: Partial<Organisation>
): Promise<AxiosResponse<PaginatedResponse<Organisation>>> => {
  const formData = new FormData();
  data.name && formData.append("name", data.name);
  data.company_name && formData.append("company_name", data.company_name);
  data.org_nr && formData.append("org_nr", data.org_nr);
  data.liability_insurance && formData.append("liability_insurance", data.liability_insurance);
  data.tax_document && formData.append("tax_document", data.tax_document);
  return API.post("/organisation/", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getOrganisation = async (): Promise<
  AxiosResponse<PaginatedResponse<Organisation>>
> => {
  return API.get("/organisation/get_my_organisations/");
};

export const updateOrganisation = async (
  data: Partial<Organisation>,
  id: string
): Promise<AxiosResponse<Organisation>> => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value ?? "");
  });

  return API.patch(`/organisation/${id}/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const removeOrganisation = async (id: string): Promise<void> => {
  return API.delete(`/organisation/${id}/`);
};
