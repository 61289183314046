import { JobQueryParams } from "adapters";
import { UserFilterObject } from "views/AgentViews/CandidatesView";

export const parseParamsAndSearch = (filters: Partial<JobQueryParams>): Record<string, unknown> => {
  const parsedFilters = {};
  if (Object.keys(filters).length) {
    Object.entries(filters).forEach(([key, filter]) => {
      if (!filter.length) {
        return;
      }
      parsedFilters[key] = Array.isArray(filter)
        ? filter.map((filterEntry) => filterEntry.value).join(",")
        : filter;
    });
  }

  return parsedFilters;
};
export const parseUserParamsAndSearch = (
  filters: Partial<UserFilterObject>
): Record<string, unknown> => {
  const parsedFilters = {};
  if (Object.keys(filters).length) {
    Object.entries(filters).forEach(([key, filter]) => {
      if (typeof filter !== "boolean" && !filter.length) {
        return;
      }
      parsedFilters[key] = Array.isArray(filter)
        ? filter.map((filterEntry) => filterEntry.id).join(",")
        : filter;
    });
  }

  return parsedFilters;
};

export const parseJobBodyWithBlankLinks = (
  container: React.MutableRefObject<HTMLDivElement | null>
): void => {
  if (container?.current && container) {
    const aTags = container.current.getElementsByTagName("a");

    for (let i = 0; i < aTags.length; i++) {
      const tag = aTags.item(i);
      if (tag) tag.target = "_blank";
    }
  }
};
