import { Dialog } from "@mui/material";
import { ContactUs, DialogHeader } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";

import { ModalContext } from "context/ModalContext";

export const ProfileAboutModal: FC = (): JSX.Element => {
  const { localize } = useContext(LocaleContext);
  const { hideModal } = useContext(ModalContext);

  return (
    <Dialog fullScreen open={true}>
      <DialogHeader
        handleClose={() => hideModal()}
        title={localize("profileSections.wiseGroup")}
        returnText={localize("common.goBack")}
      />
      <ContactUs />
    </Dialog>
  );
};
