import { Box, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import React, { FC } from "react";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { useHistory } from "react-router-dom";
import { LanguageSelectDialog } from "./LanguageSelectDialog";
import { logout } from "adapters";
import { UserContext } from "context/UserContext";
import { defaultStyles } from "styles/colors";
import { WButton, WIconTypes, WIcon } from "components";
import { INTEGRITY_LINK } from "views/Enums";

export const NavigationList: FC = () => {
  const { localize } = React.useContext(LocaleContext);
  const { hideModal } = React.useContext(ModalContext);
  const { dispatch, isAgent } = React.useContext(UserContext);
  const history = useHistory();

  const handleNavigation = (route: string): void => {
    history.push(route);
    hideModal();
  };

  const handleLogout = () => {
    logout().finally(() => dispatch({ type: "LOGOUT" }));

    hideModal();
  };

  const [openLangDialog, setOpenLangDialog] = React.useState<boolean>(false);

  if (isAgent) {
    return (
      <Box position="relative">
        <List sx={{ paddingBottom: "2rem" }}>
          <ListItem divider sx={{ borderColor: "secondary.main" }} />
          <ListItem button onClick={() => handleNavigation("/profile")}>
            <ListItemIcon>
              <WIcon icon={WIconTypes.person} size="large" />
            </ListItemIcon>
            <Typography variant="body1">{localize("routes.profile")} </Typography>
          </ListItem>
          <ListItem button onClick={() => setOpenLangDialog(true)}>
            <ListItemIcon>
              <WIcon icon={WIconTypes.globe} size="large" />
            </ListItemIcon>
            <Typography> {localize("navigationDialog.selectLanguage")} </Typography>
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <WIcon icon={WIconTypes.logout} size="large" />
            </ListItemIcon>
            <Typography> {localize("navigationDialog.logout")} </Typography>
          </ListItem>
          <LanguageSelectDialog
            isOpen={openLangDialog}
            handleClose={() => setOpenLangDialog(false)}
          />
        </List>
        <Box position="absolute" right={0} bottom={0} sx={{ cursor: "pointer" }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/teamtailor.svg`}
            alt="team tailor"
            height="32px"
            onClick={() => window.open("https://www.teamtailor.com", "_blank")?.focus()}
          />
        </Box>
      </Box>
    );
  }
  return (
    <List
      sx={{
        borderRadius: defaultStyles.borderRadiusLarge,
        backgroundColor: "secondary.main",
        "& *": {
          color: "primary.dark",
        },
      }}
    >
      <ListItem button onClick={() => handleNavigation("/profile")}>
        <ListItemIcon>
          <WIcon icon={WIconTypes.person} size="large" />
        </ListItemIcon>
        <Typography variant="body1">{localize("routes.profile")} </Typography>
      </ListItem>
      <ListItem button onClick={() => handleNavigation("/saved")}>
        <ListItemIcon>
          <WIcon icon={WIconTypes.heart} size="large" />
        </ListItemIcon>
        <Typography variant="body1"> {localize("routes.saved")}</Typography>
      </ListItem>
      <ListItem button onClick={() => handleNavigation("/applications")}>
        <ListItemIcon>
          <WIcon icon={WIconTypes.fileText} size="large" />
        </ListItemIcon>
        <Typography variant="body1"> {localize("routes.applications")}</Typography>
      </ListItem>
      <ListItem button onClick={() => setOpenLangDialog(true)}>
        <ListItemIcon>
          <WIcon icon={WIconTypes.globe} size="large" />
        </ListItemIcon>
        <Typography> {localize("navigationDialog.selectLanguage")} </Typography>
      </ListItem>
      <ListItem button onClick={() => handleNavigation("/contactUs")}>
        <ListItemIcon>
          <WIcon icon={WIconTypes.helpCircle} size="large" />
        </ListItemIcon>
        <Typography> {localize("routes.contact")} </Typography>
      </ListItem>
      <ListItem button onClick={handleLogout}>
        <ListItemIcon>
          <WIcon icon={WIconTypes.logout} size="large" />
        </ListItemIcon>
        <Typography> {localize("navigationDialog.logout")} </Typography>
      </ListItem>
      <LanguageSelectDialog isOpen={openLangDialog} handleClose={() => setOpenLangDialog(false)} />
      <ListItem>
        <WButton variant="text">
          <a href={INTEGRITY_LINK} target="_blank" rel="noreferrer">
            {localize("common.integrityLink")}
          </a>
        </WButton>
      </ListItem>
    </List>
  );
};
