import { Button, Grid } from "@mui/material";
import { Job } from "adapters";
import { JobCard } from "components/JobCard";
import { JobCardTitleComponent } from "components/JobCardTitleComponent";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import React, { FC, useContext, useState } from "react";

interface ProfileSavedJobCardProps {
  job: Job;
  jobIndex: number;
  onRemove: (jobId: string, index: number) => Promise<boolean>;
}

export const ProfileSavedJobCard: FC<ProfileSavedJobCardProps> = ({ onRemove, job, jobIndex }) => {
  const { showModal, hideDialog } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const [isSaved, setIsSaved] = useState(job.is_saved);

  const HandleButtonPress = () => {
    return (
      <Button
        fullWidth
        size="large"
        variant="text"
        sx={{ color: "text.primary" }}
        onClick={() => {
          onRemove(job.id, jobIndex);
          hideDialog();
        }}
      >
        {localize("dialog.alreadySavedJob")}
      </Button>
    );
  };

  if (isDesktop) {
    return (
      <Grid container item md={8}>
        <JobCard {...job} />
      </Grid>
    );
  }

  return (
    <Grid
      container
      padding="1rem"
      gap=".5rem"
      wrap="nowrap"
      direction="column"
      bgcolor="secondary.main"
      onClick={() => showModal("DETAILED_JOB_MODAL", { jobId: job.id, setIsSaved: setIsSaved })}
    >
      <JobCardTitleComponent
        isSaved={isSaved}
        job={job}
        handleButtonPress={<HandleButtonPress />}
      />
    </Grid>
  );
};
