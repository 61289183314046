import { Dialog, Grid } from "@mui/material";
import { WButton, WTypography } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, ReactNode, useContext } from "react";
import { colors, defaultStyles } from "styles/colors";

interface OptionsDialogProps {
  content: ReactNode;
  handleClose: () => void;
  title?: string;
  closeText?: string;
}

export const OptionsDialog: FC<OptionsDialogProps> = ({
  content,
  handleClose,
  title,
  closeText,
}) => {
  const { localize } = useContext(LocaleContext);

  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      PaperProps={{
        sx: {
          borderRadius: defaultStyles.borderRadiusLarge,
          position: "absolute",
          margin: "auto",
          width: "60%",
        },
      }}
    >
      <Grid>
        {title && (
          <WTypography variant="h6" padding="1rem" textAlign="center">
            {title}
          </WTypography>
        )}
        {content}
        <WButton
          fullWidth
          size="large"
          variant="text"
          sx={{ color: colors.cancelText }}
          onClick={() => handleClose()}
        >
          {closeText ? closeText : localize("common.close")}
        </WButton>
      </Grid>
    </Dialog>
  );
};
