import React, { FC, ReactNode } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import { WIcon, WIconTypes } from "components";

import { defaultStyles } from "styles/colors";

interface CardLabelProps {
  icon: WIconTypes;
  text: string;
  label: string;
  color: { text: string; background: string };
  customTitle?: ReactNode;
}

export const CardLabel: FC<CardLabelProps> = ({ icon, text, color, label, customTitle }) => {
  return (
    <Tooltip
      title={customTitle || label}
      arrow
      componentsProps={{
        tooltip: {
          style: { minWidth: "max-content" },
          sx: {
            bgcolor: color.text,
            color: color.background,
            fontWeight: "bold",
            "& .MuiTooltip-arrow": {
              color: color.text,
            },
          },
        },
      }}
    >
      <Grid
        container
        gap=".5rem"
        alignItems="center"
        justifyContent="center"
        padding=".1rem .2rem"
        sx={{
          width: "max-content",
          backgroundColor: `${color.background}`,
        }}
        boxShadow="-1px 1px 2px rgba(0, 0, 0, 0.25);"
        border={`1px solid ${color.text}`}
        borderRadius={defaultStyles.borderRadius}
      >
        <WIcon icon={icon} size="small" customColor={color.text} />
        <Typography lineHeight="0.1" fontWeight="bold" variant="body2" color={color.text}>
          {text}
        </Typography>
      </Grid>
    </Tooltip>
  );
};
