import {
  Checkbox,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, ReactNode, useCallback, useContext } from "react";
import { colors, defaultStyles } from "styles/colors";
import { WTypography } from "./WTypography";

interface BaseProps {
  items: DropdownItem[];
  noSelectionTag?: string;
  disabled?: boolean;
  width?: string;
}
interface SingleSelectProps extends BaseProps {
  onSelect: (id: string) => void;
  selectedId: string;
}
interface MultiSelectProps extends BaseProps {
  onToggle: (ids: string[]) => void;
  selectedIds: string[];
}

export interface DropdownItem {
  id: string;
  primary: string | ReactNode;
  secondary?: string | ReactNode;
  disabled?: boolean;
}

export const SelectDropdown: FC<SingleSelectProps> = ({
  noSelectionTag,
  selectedId,
  items,
  onSelect,
  disabled,
  width = "250px",
}) => {
  const { localize } = useContext(LocaleContext);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const {
        target: { value },
      } = event;
      onSelect(value);
    },
    [onSelect]
  );

  return (
    <Select<string>
      size="small"
      sx={{
        width,
      }}
      disabled={disabled}
      value={selectedId}
      onChange={handleChange}
      input={<StyledInput />}
      renderValue={(selected) => {
        if (!selected && noSelectionTag) {
          return localize(noSelectionTag);
        }
        return items.find((item) => item.id === selected)?.primary;
      }}
      MenuProps={MenuProps}
      displayEmpty
    >
      {renderItems(items, selectedId)}
    </Select>
  );
};

export const MultiSelectDropdown: FC<MultiSelectProps> = ({
  noSelectionTag,
  selectedIds,
  items,
  onToggle,
  disabled,
  width = "250px",
}) => {
  const { localize } = useContext(LocaleContext);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;
      onToggle(typeof value === "string" ? value.split(",") : value);
    },
    [onToggle]
  );

  return (
    <Select<string[]>
      size="small"
      multiple
      sx={{
        width,
      }}
      disabled={disabled}
      value={selectedIds}
      onChange={handleChange}
      input={<StyledInput />}
      renderValue={(selected) => {
        if (selected.length === 0 && noSelectionTag) {
          return localize(noSelectionTag);
        }
        return selected.map((id) => items.find((item) => item.id === id)?.primary).join(", ");
      }}
      MenuProps={MenuProps}
      displayEmpty
    >
      {renderItems(items, selectedIds)}
    </Select>
  );
};

const renderItems = (items: DropdownItem[], selectedId: string | string[]): ReactNode => {
  return items.map(({ id, primary: title, secondary, disabled }) => (
    <MenuItem
      key={id}
      value={id}
      disabled={disabled}
      sx={{ height: ITEM_HEIGHT, overflow: "hidden" }}
    >
      {Array.isArray(selectedId) ? <Checkbox checked={selectedId.includes(id)} /> : null}
      <WTypography variant="body1" fontWeight="bold">
        {title}
      </WTypography>
      {typeof secondary !== "undefined" ? (
        <WTypography marginLeft="10px" variant="body1">
          {secondary}
        </WTypography>
      ) : null}
    </MenuItem>
  ));
};

const ITEM_HEIGHT = 38;

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 10,
      maxHeight: ITEM_HEIGHT * 8,
      borderRadius: defaultStyles.borderRadius,
      boxShadow: defaultStyles.bigCardShadow,
      left: 0,
    },
  },
};

const StyledInput = styled(OutlinedInput)(`
  border-radius: ${defaultStyles.borderRadius};
  border-width: 1px !important;
  overflow: hidden;
  
  fieldset {
    border-color: ${colors.lightBorder} !important;
    border-width: 1px !important;
    border-radius: ${defaultStyles.borderRadius};
  }

  [aria-expanded="true"] {
      background-color: ${colors.buttonSelected};
  }
`);
