import React, { FC } from "react";
import { styled, Grid, GridProps } from "@mui/material";

export const WCustomSVGGrid = styled(Grid)(`
      padding:1rem;
      overflow: hidden;
     background-color: white;
     border-radius: .5rem;
`);

export interface WGridSVGWrapperProps {
  size?: "small" | "medium" | "large";
}

export const WGridSVGWrapper: FC<GridProps & WGridSVGWrapperProps> = (props) => {
  const { children, size = "medium", ...gridProps } = props;

  const svgSizes = {
    small: "150px",
    medium: "260px",
    large: "450px",
  };

  return (
    <WCustomSVGGrid
      container
      {...gridProps}
      sx={{ width: svgSizes[size], height: size === "large" ? "max-content" : svgSizes[size] }}
    >
      {children}
    </WCustomSVGGrid>
  );
};
