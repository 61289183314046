import { Grid, MenuItem } from "@mui/material";
import { addUserRegions, deleteUserRegions, getRegions, RegionWithLocations } from "adapters";
import { RegionExpandableList, WButton, WIcon, WIconTypes, WSkeleton } from "components";
import { JobFilterItem } from "components/dialogs/FiltersDialog";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import { usePagination } from "hooks/usePagination";
import React, { FC, useContext, useEffect, useState } from "react";
import { colors, defaultStyles } from "styles/colors";

export const RegionSection: FC = () => {
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { localize } = useContext(LocaleContext);
  const { user, dispatch } = useContext(UserContext);

  const { results, setLastElementRef } = usePagination<RegionWithLocations>(
    getRegions,
    "error.fetchingRegions",
    "GET_REGIONS"
  );

  const [state, setState] = useState<{ regions: JobFilterItem[] }>({
    regions: [],
  });

  useEffect(() => {
    initUserRegions();
  }, []);

  const initUserRegions = () => {
    const tempFilterItems: JobFilterItem[] =
      user.regions.map((region) => {
        return {
          label: region.name,
          value: region.id,
        };
      }) || [];

    setState({
      ...state,
      regions: [...state.regions, ...tempFilterItems],
    });
  };

  const currentUserRegions = user.regions;

  const handleSaveChanges = () => {
    if (currentUserRegions) {
      // if there's any currently selected Regions in filter that does not exist in the savedUserRegions it's an addition
      const additions =
        state.regions.filter(
          (region) => !currentUserRegions.some((userRegion) => userRegion.id === region.value)
        ) || [];

      // if savedUserRegions contains a region that does not exist in the state it's a deletion
      const deletions: string[] =
        currentUserRegions
          .filter((userRegion) => !state.regions.some((region) => region.value === userRegion.id))
          .map((region) => region.id) || [];

      if (additions.length + deletions.length === 0) {
        return;
      }

      if (additions.length && deletions.length) {
        Promise.all([handleAdditions(additions, false), handleDeletions(deletions, false)])
          .then(() => {
            addMessage({ content: "success.updateSuccessful", type: "success" });
          })
          .catch(() => {
            addMessage({ content: "error.deleteOrAddLocations", type: "error" });
          })
          .finally(() => {
            // Update user context

            const regionMapping = (item: JobFilterItem) => {
              return {
                id: item.value,
                name: item.label,
              };
            };

            const parsedRegionAdditions = additions.map((addition) => regionMapping(addition));
            const updatedRegions = state.regions
              .filter((region) => !deletions.includes(region.value))
              .map((region) => regionMapping(region));

            dispatch({
              type: "UPDATE_USER",
              user: { regions: [...updatedRegions, ...parsedRegionAdditions] },
            });
          });
      } else {
        if (additions.length) {
          handleAdditions(additions, true);
        } else {
          handleDeletions(deletions, true);
        }
      }
    }
  };

  const handleAdditions = (additions: JobFilterItem[], shouldCloseAndSave: boolean) => {
    // Avoid duplicate requests
    if (isLoading("ADD_USER_REGIONS") || additions.length < 1) {
      return;
    }

    dispatchLoading({ type: "SET_LOADING", payload: "ADD_USER_REGIONS" });
    addUserRegions(additions.map((region) => region.value))
      .then(() => {
        if (shouldCloseAndSave) {
          // Update user context
          const allRegions = currentUserRegions.concat(
            additions.map((addition) => {
              return { id: addition.value, name: addition.label };
            })
          );
          dispatch({ type: "UPDATE_USER", user: { regions: allRegions } });
          addMessage({ content: "success.updateSuccessful", type: "success" });
        }
      })
      .catch(() => {
        if (shouldCloseAndSave) {
          addMessage({ content: "error.addingRegion", type: "error" });
        }
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "ADD_USER_REGIONS" });
      });
  };

  const handleDeletions = (deletions: string[], shouldCloseAndSave: boolean) => {
    // Avoid duplicate requests
    if (isLoading("REMOVE_USER_REGIONS") || deletions.length < 1) {
      return;
    }
    dispatchLoading({ type: "SET_LOADING", payload: "REMOVE_USER_REGIONS" });
    deleteUserRegions(deletions)
      .then(() => {
        if (shouldCloseAndSave) {
          // Update user context
          const updatedRegions = currentUserRegions.filter(
            (userRegion) => !deletions.includes(userRegion.id)
          );
          dispatch({ type: "UPDATE_USER", user: { regions: updatedRegions } });

          addMessage({ content: "success.updateSuccessful", type: "success" });
        }
      })
      .catch(() => {
        if (shouldCloseAndSave) {
          addMessage({ content: "error.removingRegion", type: "error" });
        }
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "REMOVE_USER_REGIONS" });
      });
  };

  const handleRemoveItem = (key: string, item: JobFilterItem) => {
    const tempFilter = state[key].filter(
      (prevValue: JobFilterItem) => prevValue.value !== item.value
    );

    setState({
      ...state,
      [key]: [...tempFilter],
    });
  };

  const handleAddItem = (key: string, item: JobFilterItem) => {
    setState({
      ...state,
      [key]: [...state[key], item],
    });
  };

  const handleItemClick = (key: "regions", item: JobFilterItem) => {
    if (state[key].some((filter) => filter.value === item.value)) {
      handleRemoveItem(key, item);
    } else {
      handleAddItem(key, item);
    }
  };

  return (
    <Grid
      container
      direction="column"
      gap="1rem"
      padding="1rem 0"
      height="100%"
      sx={{
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadiusBottom,
        border: `1px solid ${colors.grey}`,
      }}
    >
      {isLoading("GET_USER_REGIONS") || isLoading("GET_REGIONS") ? (
        <WSkeleton width="100%" height="100px">
          <MenuItem />
        </WSkeleton>
      ) : (
        <RegionExpandableList
          expand={false}
          itemsToShow={4}
          allResults={results}
          filters={state}
          handleItemClick={handleItemClick}
          lastElementRef={setLastElementRef}
        />
      )}
      <Grid container justifyContent="flex-end">
        <Grid item xs={12} md={2} padding="0 1rem ">
          <WButton
            fullWidth
            variant="outlined"
            startIcon={<WIcon icon={WIconTypes.check} />}
            onClick={handleSaveChanges}
          >
            {localize("form.save")}
          </WButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
