import { Grid, Box } from "@mui/material";
import { Job } from "adapters";
import { WTypography, WButton, WIcon, WIconTypes } from "components";
import React, { FC, useContext, SyntheticEvent, useRef, useEffect } from "react";
import dompurify from "dompurify";
import { LocaleContext } from "context/LocaleContext";
import { ExpandedJobDescription } from "./ExpandedJobDescription";
import { ModalContext } from "context/ModalContext";
import { UserContext } from "context/UserContext";
import { ActiveJobApplication } from "adapters/JobAdapter";
import { GTMContext } from "context/GTMContext";
import { parseJobBodyWithBlankLinks } from "utils/JobUtils";
import { DateAndSkillRow } from "components/DateAndSkillRow";

interface ExpandedJobBodyProps {
  job: Job;
  handleApplyJobClick: (event: SyntheticEvent) => void;
  applicationData: ActiveJobApplication;
}

export const ExpandedJobBody: FC<ExpandedJobBodyProps> = (props) => {
  const { localize } = useContext(LocaleContext);
  const { showDialog } = useContext(ModalContext);
  const { isAuthenticated } = useContext(UserContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);

  const HTMLSanitizer = dompurify.sanitize;
  const content = useRef<HTMLDivElement | null>(null);

  const { id = "", skills = [], pitch = "", body = "", created = "", title = "" } = props.job;
  const { handleApplyJobClick, applicationData } = props;

  const generateMailURL = (id: string): string => {
    const queryString = window.location.origin;

    return encodeURIComponent(`${queryString}/jobs/${id}`);
  };

  const handleShareJobclick = (event: SyntheticEvent) => {
    event.stopPropagation();

    if (isAuthenticated) {
      showDialog("SHARE_JOB", { jobId: id, title: title });
    } else {
      // Send email popup if user is not logged in
      gtmAddToDataLayer({ action: props.job.title }, "application_shared");
      window.location.href = `mailto:${""}?subject=${localize(
        "shareJobDialog.mailSubject"
      )}: ${title}&body=${encodeURIComponent(
        localize("shareJobDialog.mailBody")
      )} ${generateMailURL(id)}`;
    }
  };

  useEffect(() => {
    parseJobBodyWithBlankLinks(content);
  }, [content, body]);

  return (
    <Grid container direction="column" wrap="nowrap" gap="1rem">
      <DateAndSkillRow created={created} skills={skills} />

      <ExpandedJobDescription job={props.job} />
      <Grid>
        <WTypography variant="body1" lineClamps={4}>
          {pitch}
        </WTypography>
      </Grid>

      <Grid container gap="1rem" margin="1rem 0">
        <WButton variant="contained" onClick={(event) => handleApplyJobClick(event)}>
          {applicationData.id !== ""
            ? localize("common.editApplication")
            : localize("common.applyJob")}
        </WButton>
        <WButton
          variant="contained"
          onClick={(event) => handleShareJobclick(event)}
          color="secondary"
          startIcon={<WIcon icon={WIconTypes.share} />}
        >
          {localize("common.shareJob")}
        </WButton>
      </Grid>

      <Box
        overflow="hidden"
        sx={{
          "& img": {
            maxWidth: "100%",
          },
        }}
      >
        <span ref={content} dangerouslySetInnerHTML={{ __html: HTMLSanitizer(body) }}></span>
      </Box>
      <Grid container gap="1rem">
        <WButton variant="contained" onClick={(event) => handleApplyJobClick(event)}>
          {applicationData.id !== ""
            ? localize("common.editApplication")
            : localize("common.applyJob")}
        </WButton>
        <WButton
          variant="contained"
          onClick={(event) => handleShareJobclick(event)}
          color="secondary"
          startIcon={<WIcon icon={WIconTypes.share} />}
        >
          {localize("common.shareJob")}
        </WButton>
      </Grid>
    </Grid>
  );
};
