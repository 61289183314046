import { Box, Typography } from "@mui/material";
import { WButton, WIcon, WIconTypes } from "components";
import { SetPasswordForm, SetPasswordState } from "components/forms/SetPasswordForm";
import { WCard } from "components/styledComponents/WCard";
import React, { FC, useContext, useEffect } from "react";
import { LocaleContext } from "context/LocaleContext";

interface JobApplicationVerifyCardProps {
  hasPassword: boolean;
  onSubmit: (data: SetPasswordState) => void;
  onLoginRedirect: () => void;
}
export const JobApplicationVerifyCard: FC<JobApplicationVerifyCardProps> = ({
  hasPassword,
  onSubmit,
  onLoginRedirect,
}) => {
  const { localize } = useContext(LocaleContext);

  useEffect(() => {
    let redirectTimer: NodeJS.Timeout;
    if (hasPassword) {
      redirectTimer = setTimeout(() => {
        onLoginRedirect();
      }, 6000);
    }
    return () => clearTimeout(redirectTimer);
  }, []);

  return (
    <WCard>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <WIcon icon={WIconTypes.check} size="large" color="success" />
        </Box>
      </div>
      <Typography variant="h6">{localize("verifyMailView.jobApplVerificationTitle")}</Typography>
      {!hasPassword ? (
        <>
          <Typography>{localize("verifyMailView.jobApplVerificationCompleteNoPass")}</Typography>
          <SetPasswordForm onSubmit={onSubmit} />
        </>
      ) : (
        <>
          <Typography>{localize("verifyMailView.jobApplVerificationComplete")}</Typography>
          <WButton variant="text" onClick={onLoginRedirect}>
            {localize("common.loginRedirect")}
          </WButton>
        </>
      )}
    </WCard>
  );
};
