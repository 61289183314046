import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

type NewsType = "profile" | "news";
export interface News {
  id: string;
  title: string;
  sub_title: string;
  body: string;
  news_photo: string;
  news_type: NewsType;
}

export const getNews = (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<News>>> => {
  return API.get("/news/", { params: params });
};
