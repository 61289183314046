import { Typography, Grid, Box } from "@mui/material";
import { Referral } from "adapters";
import { WIcon, WIconTypes } from "components";
import React, { FC, ReactNode } from "react";
import { colors, defaultStyles } from "styles/colors";

interface ReferralCardProps {
  referral: Referral;
  onClick?: () => void;
  icons?: ReactNode;
  isLast?: boolean;
}
export const ReferralCard: FC<ReferralCardProps> = ({ referral, onClick, icons, isLast }) => {
  return (
    <Grid
      container
      item
      alignItems="center"
      sx={
        onClick
          ? {
              backgroundColor: colors.white,
              borderRadius: isLast ? defaultStyles.borderRadiusBottom : "0",
              cursor: "pointer",
              transition: "all 0.2s ease-out",
              ":hover": {
                backgroundColor: colors.lightGrey,
              },
            }
          : {
              backgroundColor: colors.white,
              borderRadius: isLast ? defaultStyles.borderRadiusBottom : "0",
            }
      }
      padding="1.5rem"
      key={`referral${referral.id}`}
      borderBottom={`1px solid ${colors.grey}`}
      gap="1rem"
      wrap="nowrap"
      onClick={onClick}
    >
      <WIcon icon={WIconTypes.person} />
      <Box width="70%" textAlign="left">
        <Typography fontWeight="bold">
          {referral.first_name} {referral.last_name}
        </Typography>
        <Typography fontSize="1rem">{referral.email}</Typography>
      </Box>
      {icons && <Grid marginLeft="auto">{icons}</Grid>}
    </Grid>
  );
};
