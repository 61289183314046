import { Dialog, Grid, MenuItem } from "@mui/material";
import {
  DialogHeader,
  WSkeleton,
  WButton,
  RegionExpandableList,
  WIcon,
  WIconTypes,
} from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { getRegions, RegionWithLocations, addUserRegions, deleteUserRegions } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { JobFilterItem } from "components/dialogs/FiltersDialog";
import { ModalContext } from "context/ModalContext";
import { UserContext } from "context/UserContext";
import { usePagination } from "hooks/usePagination";

const FILTER_ITEMS_TO_SHOW = 4;

type Regionfilters = {
  regions: JobFilterItem[];
};

const defaultFilterState: Regionfilters = {
  regions: [],
};

export const ProfileRegionModal: FC = () => {
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { hideModal } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);
  const { user, dispatch } = useContext(UserContext);

  const [filters, setFilters] = useState<Regionfilters>(defaultFilterState);
  const [currentUserRegions, setCurrentUserRegions] = useState<string[]>();
  const [showMore, setShowMore] = useState<{
    locations: boolean;
    regions: boolean;
  }>({
    locations: false,
    regions: false,
  });

  const { results, setLastElementRef } = usePagination<RegionWithLocations>(
    getRegions,
    "error.fetchingRegions",
    "GET_REGIONS"
  );

  useEffect(() => {
    initUserRegions();
  }, []);

  const initUserRegions = () => {
    const tempFilterItems: JobFilterItem[] =
      user.regions.map((region) => {
        return {
          label: region.name,
          value: region.id,
        };
      }) || [];

    setCurrentUserRegions(user.regions.map((region) => region.id));

    setFilters({
      ...filters,
      regions: [...filters["regions"], ...tempFilterItems],
    });
  };

  const handleSaveChanges = () => {
    if (currentUserRegions) {
      const additions: JobFilterItem[] =
        filters.regions.filter((region) => !currentUserRegions.includes(region.value)) || []; // if there's any currently selected Regions in filter that does not exist in the savedUserRegions it's an addition

      const deletions: string[] =
        currentUserRegions.filter(
          (userRegion) => !filters.regions.some((region) => region.value === userRegion)
        ) || []; // if savedUserRegions contains a region that does not exist in the filters it's a deletion

      if (additions.length + deletions.length === 0) {
        return;
      }

      if (additions.length && deletions.length) {
        Promise.all([handleAdditions(additions, false), handleDeletions(deletions, false)])
          .then(() => {
            addMessage({ content: "success.updateSuccessful", type: "success" });
          })
          .catch(() => {
            addMessage({ content: "error.deleteOrAddLocations", type: "error" });
          })
          .finally(() => {
            // Update user context

            const regionMapping = (item: JobFilterItem) => {
              return {
                id: item.value,
                name: item.label,
              };
            };

            const parsedRegionAdditions = additions.map((addition) => regionMapping(addition));
            const updatedRegions = filters.regions
              .filter((region) => !deletions.includes(region.value))
              .map((region) => regionMapping(region));

            dispatch({
              type: "UPDATE_USER",
              user: { regions: [...updatedRegions, ...parsedRegionAdditions] },
            });

            hideModal();
          });
      } else {
        if (additions.length) {
          handleAdditions(additions, true);
        } else {
          handleDeletions(deletions, true);
        }
      }
    }
  };

  const handleAdditions = (additions: JobFilterItem[], shouldCloseAndSave: boolean) => {
    // Avoid duplicate requests
    if (isLoading("ADD_USER_REGIONS") || additions.length < 1) {
      return;
    }

    dispatchLoading({ type: "SET_LOADING", payload: "ADD_USER_REGIONS" });
    addUserRegions(additions.map((region) => region.value))
      .then(() => {
        if (shouldCloseAndSave) {
          // Update user context
          const allRegions = user.regions.concat(
            additions.map((addition) => {
              return { id: addition.value, name: addition.label };
            })
          );
          dispatch({ type: "UPDATE_USER", user: { regions: allRegions } });

          hideModal();
          addMessage({ content: "success.updateSuccessful", type: "success" });
        }
      })
      .catch(() => {
        if (shouldCloseAndSave) {
          addMessage({ content: "error.addingRegion", type: "error" });
        }
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "ADD_USER_REGIONS" });
      });
  };

  const handleDeletions = (deletions: string[], shouldCloseAndSave: boolean) => {
    // Avoid duplicate requests
    if (isLoading("REMOVE_USER_REGIONS") || deletions.length < 1) {
      return;
    }
    dispatchLoading({ type: "SET_LOADING", payload: "REMOVE_USER_REGIONS" });
    deleteUserRegions(deletions)
      .then(() => {
        if (shouldCloseAndSave) {
          // Update user context
          const updatedRegions = user.regions.filter(
            (userRegion) => !deletions.includes(userRegion.id)
          );
          dispatch({ type: "UPDATE_USER", user: { regions: updatedRegions } });

          hideModal();
          addMessage({ content: "success.updateSuccessful", type: "success" });
        }
      })
      .catch(() => {
        if (shouldCloseAndSave) {
          addMessage({ content: "error.removingRegion", type: "error" });
        }
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "REMOVE_USER_REGIONS" });
      });
  };

  const handleRemoveItem = (key: string, item: JobFilterItem) => {
    const tempFilter = filters[key].filter(
      (prevValue: JobFilterItem) => prevValue.value !== item.value
    );

    setFilters({
      ...filters,
      [key]: [...tempFilter],
    });
  };

  const handleAddItem = (key: string, item: JobFilterItem) => {
    setFilters({
      ...filters,
      [key]: [...filters[key], item],
    });
  };

  const handleItemClick = (key: keyof Regionfilters, item: JobFilterItem) => {
    if (filters[key].some((filter) => filter.value === item.value)) {
      handleRemoveItem(key, item);
    } else {
      handleAddItem(key, item);
    }
  };

  return (
    <Dialog fullScreen open={true} onClose={() => hideModal()}>
      <DialogHeader
        returnText={localize("profileView.headerMessage")}
        handleClose={() => hideModal()}
        title={`${localize("common.select")} ${localize("common.location").toLowerCase()}`}
      />
      <Grid container direction="column" gap="1rem" height="100%">
        {(isLoading("GET_USER_REGIONS") || isLoading("GET_REGIONS")) && (
          <WSkeleton width="100%">
            <MenuItem />
          </WSkeleton>
        )}
        <RegionExpandableList
          expand={!showMore.regions}
          itemsToShow={FILTER_ITEMS_TO_SHOW}
          allResults={results}
          filters={filters}
          handleItemClick={handleItemClick}
          handleExpandClick={() => setShowMore({ ...showMore, regions: true })}
          lastElementRef={setLastElementRef}
        />
      </Grid>
      <WButton
        variant="outlined"
        startIcon={<WIcon icon={WIconTypes.uploadCloud} />}
        sx={{ margin: "1rem", position: "sticky", top: 0 }}
        onClick={handleSaveChanges}
      >
        {localize("form.save")}
      </WButton>
    </Dialog>
  );
};
