import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export type RegionLocationActions =
  | "GET_REGIONS"
  | "GET_USER_REGIONS"
  | "ADD_USER_REGIONS"
  | "REMOVE_USER_REGIONS";

export type Location = {
  id: string;
  city: string;
};

export type Region = {
  id: string;
  name: string;
};

export interface LocationWithRegion extends Location {
  region: Region;
}

export type RegionWithLocations = {
  id: string;
  name: string;
  locations: Location[];
};

// get all regions
export const getRegions = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<RegionWithLocations>>> => {
  return API.get("/regions/", { params: params });
};

export const deleteUserRegions = async (data: string[]): Promise<AxiosResponse> => {
  return API.post(`/regions/remove-user/`, { regions: data });
};

// get users locations
export const getUserRegions = async (): Promise<
  AxiosResponse<PaginatedResponse<RegionWithLocations>>
> => {
  return API.get("/regions/user-locations/");
};

// add location to user
export const addUserRegions = async (data: string[]): Promise<AxiosResponse> => {
  return API.post("/regions/add-user/", { regions: data });
};
