import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Fade,
  Grid,
  MenuItem,
} from "@mui/material";
import { WMenu } from "components/styledComponents/WMenu";
import { WButton, WIcon, WIconTypes, WTypography } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import React, { FC, ReactElement, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { colors } from "styles/colors";
import { Footer } from "../Footer";

type ListItem = {
  label: string;
  route: string;
};

type SectionWithListItem = {
  section: string;
  route?: string;
  items?: ListItem[];
};

interface ExpandableNavbarItemProps {
  icon?: ReactElement;
  listitems?: ListItem[];
  sections?: SectionWithListItem[];
  fullWidth?: boolean;
  label: string;
  expandedCallback?: (isExpanded: boolean) => void;
  hasNotification?: boolean;
}
export const ExpandableNavbarItem: FC<ExpandableNavbarItemProps> = ({
  listitems,
  label,
  icon,
  sections,
  expandedCallback,
  fullWidth = false,
}) => {
  const { isDesktop } = useContext(BreakpointsContext);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [accordionIsExpanded, setAccordionIsExpanded] = useState(-1);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (expandedCallback) expandedCallback(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (expandedCallback) expandedCallback(false);
  };

  const handleItemClick = (route: string) => {
    setAnchorEl(null);
    history.push(route);

    if (expandedCallback) expandedCallback(false);
  };

  const handleExpandAccordion = (index: number) => {
    setAccordionIsExpanded(accordionIsExpanded === index ? -1 : index);
  };

  return (
    <>
      <Button
        color={"primary"}
        disableRipple
        sx={{
          width: "max-content",
          cursor: "pointer",
          fontWeight: "bold",
          svg: {
            transition: "all 0.2s ease-out",
            transform: anchorEl ? "rotate(-180deg)" : "rotate(0deg)",
          },
        }}
        onClick={handleClick}
        endIcon={!icon && <WIcon icon={WIconTypes.arrowDown} />}
        startIcon={anchorEl && icon ? <WIcon icon={WIconTypes.clear} /> : icon}
      >
        {label}
      </Button>
      <WMenu
        id="fade-menu"
        fullwidth={fullWidth}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {isDesktop
          ? listitems?.map((item, index) => (
              <MenuItem
                key={`navbarItem_${item.label}_${index}`}
                onClick={() => handleItemClick(item.route)}
                sx={{
                  backgroundColor: colors.white,
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: colors.mainDark,
                }}
              >
                {item.label}
              </MenuItem>
            ))
          : sections?.map((section, index) => {
              return section.items && section.items.length > 0 ? (
                <Accordion
                  key={`section_${section.section}_${index}`}
                  disableGutters
                  expanded={accordionIsExpanded === index}
                  sx={{
                    boxShadow: "none",
                    minWidth: "100%",
                    ":before": {
                      display: "none", //removes the gap between acordion list items
                    },
                  }}
                >
                  <AccordionSummary
                    onClick={() => handleExpandAccordion(index)}
                    expandIcon={<WIcon icon={WIconTypes.arrowDown} />}
                  >
                    <Grid container direction="column" wrap="nowrap">
                      <WTypography lineClamps={1} fontWeight="bold" color="primary">
                        {section.section}
                      </WTypography>
                    </Grid>
                  </AccordionSummary>
                  {section.items?.map((item, index) => (
                    <AccordionDetails key={`navbarItem_${item.label}_${index}`}>
                      <WButton
                        onClick={() => handleItemClick(item.route)}
                        sx={{
                          marginLeft: ".5rem",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0",
                          width: "100%",
                        }}
                      >
                        {item.label}
                      </WButton>
                    </AccordionDetails>
                  ))}
                </Accordion>
              ) : (
                <MenuItem
                  key={`navbarItem_${section.section}_${index}`}
                  onClick={() => handleItemClick(section.route ? section.route : "/")}
                  sx={{
                    backgroundColor: colors.white,
                    fontWeight: "bold",
                    color: colors.mainDark,
                  }}
                >
                  {section.section}
                </MenuItem>
              );
            })}
        <Box position="fixed" bottom="0" left="0" right="0">
          {fullWidth && <Footer setAnchorEl={setAnchorEl} />}
        </Box>
      </WMenu>
    </>
  );
};
