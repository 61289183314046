import { Box, Typography, MenuItem } from "@mui/material";
import { Department, getDepartments } from "adapters";
import { ExpandableListItem, WButton, WIcon, WIconTypes, WSkeleton } from "components";
import { JobFilterItem, JobFilters } from "components/dialogs/FiltersDialog";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { DEFAULT_PAGINATION_SIZE, usePagination } from "hooks/usePagination";
import React, { FC, useContext } from "react";
import { colors } from "styles/colors";

interface DepartmentFilterListProps {
  expand: boolean;
  itemsToShow: number;
  filters: JobFilters;
  handleFilterClick: (key: keyof JobFilters, item: JobFilterItem) => void;
  handleExpandClick: () => void;
  handleDepartmentClick?: (department: Department) => void;
  pageSize?: number;
}

export const DepartmentFilterList: FC<DepartmentFilterListProps> = ({
  expand,
  itemsToShow,
  filters,
  handleFilterClick,
  handleExpandClick,
  handleDepartmentClick,
  pageSize,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);

  const { results, setLastElementRef } = usePagination<Department>(
    getDepartments,
    "error.fetchingDepartments",
    "GET_DEPARTMENTS",
    { page_size: pageSize || DEFAULT_PAGINATION_SIZE }
  );

  return (
    <Box>
      <Typography variant="h6">{localize("common.role")}</Typography>
      {isLoading("GET_DEPARTMENTS") ? (
        <WSkeleton width="100%">
          <MenuItem />
        </WSkeleton>
      ) : (
        results.slice(0, expand ? itemsToShow : undefined).map((dep, index) => (
          <React.Fragment key={dep.id}>
            <ExpandableListItem
              key={dep.id}
              label={dep.name}
              value={dep.id}
              isChecked={
                filters.departments.some((filterDep) => filterDep.value === dep.id) ||
                dep.roles.every((role) => filters.roles.find((item) => item.value === role.id))
              }
              isExpanded={dep.roles.some((role) =>
                filters.roles.some((filterRole) => filterRole.value === role.id)
              )}
              expandableListData={dep.roles.map((role) => {
                return {
                  label: role.name,
                  value: role.id,
                  isChecked: filters.roles.some((filterRole) => filterRole.value === role.id),
                };
              })}
              handleClick={(value) => {
                if (handleDepartmentClick) {
                  handleDepartmentClick(dep);
                } else {
                  handleFilterClick("departments", value);
                }
              }}
              handleSubListClick={(value) => handleFilterClick("roles", value)}
            />
            {index === results.length - 1 && <div ref={setLastElementRef} />}
          </React.Fragment>
        ))
      )}
      {expand && results.length > itemsToShow && (
        <WButton
          fullWidth
          endIcon={<WIcon icon={WIconTypes.arrowDown} color="mainDarkRed" />}
          onClick={handleExpandClick}
          sx={{ maxWidth: "max-content", color: colors.mainDarkRed }}
        >
          {localize("searchbar.showMoreDepartments")}
        </WButton>
      )}
    </Box>
  );
};
