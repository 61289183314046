import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export interface Referral {
  comment?: string;
  email: string;
  first_name: string;
  id: string;
  last_name?: string;
  phone?: string;
  agent_comment?: string;
}

export type ReferralActions =
  | "GET_REFERRAL_LIST"
  | "GET_REFFERAL"
  | "CREATE_REFERRAL"
  | "UPDATE_REFERRAL"
  | "DELETE_REFERRAL";

export const getReferralList = async (): Promise<AxiosResponse<PaginatedResponse<Referral>>> => {
  return API.get("/referral/");
};

export const getReferral = async (referralId: string): Promise<AxiosResponse<Referral>> => {
  return API.get(`/referral/${referralId}/`);
};

export const createReferral = async (data: {
  email: string;
  first_name: string;
  last_name?: string;
  phone?: string;
  comment?: string;
}): Promise<AxiosResponse<Referral>> => {
  return API.post("/referral/", data);
};

export const updateReferral = async (
  data: Record<string, string>,
  id: string
): Promise<AxiosResponse<Referral>> => {
  return API.patch(`/referral/${id}/`, data);
};

export const deleteReferral = async (id: string): Promise<AxiosResponse<Referral>> => {
  return API.delete(`/referral/${id}/`);
};
