import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export interface Role {
  id: string;
  name: string;
}

interface DepartmentSimple {
  id: string;
  name: string;
}

export interface RoleWithDepartment extends Role {
  department: DepartmentSimple;
}

export type Department = {
  id: string;
  name: string;
  roles: Role[];
};

type ShiftRoles = {
  //type for adding or removing user roles
  roles: string[];
};

export type DepartmentActions =
  | "GET_DEPARTMENTS"
  | "GET_USER_DEPARTMENTS"
  | "ADD_USER_DEPARTMENT"
  | "DELETE_USER_DEPARTMENT";

export const getDepartments = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<Department>>> => {
  return API.get("/departments/", { params: params });
};

// get all department roles associated with a user.
export const getUserDepartments = async (): Promise<
  AxiosResponse<PaginatedResponse<RoleWithDepartment>>
> => {
  return API.get("/departments/roles/user/");
};

// add a department role to the current user.
export const addUserDepartment = async (data: ShiftRoles): Promise<AxiosResponse<Role>> => {
  return API.post(`/departments/roles/add/`, data);
};

// remove a department role from the current user.
export const deleteUserDepartment = async (data: ShiftRoles): Promise<AxiosResponse<Role>> => {
  return API.post(`/departments/roles/remove/`, data);
};
