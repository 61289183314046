import { Box, Typography, MenuItem } from "@mui/material";
import { Skill, getSkillsList } from "adapters";
import { WSkeleton, WButton, WListItem, WIcon, WIconTypes } from "components";
import { JobFilterItem, JobFilters } from "components/dialogs/FiltersDialog";
import { WSearchBar } from "components/WSearchBar";
import { BreakpointsContext } from "context/BreakpointContext";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { usePagination } from "hooks/usePagination";
import React, { FC, useContext, useEffect, useState } from "react";
import { colors } from "styles/colors";
import { FILTER_ITEM_HEIGHT } from "./FiltersBody";

interface SkillFilterListProps {
  expand: boolean;
  itemsToShow: number;
  filters: JobFilters;
  handleFilterClick: (key: keyof JobFilters, item: JobFilterItem) => void;
  handleExpandClick: () => void;
}

export const SkillFilterList: FC<SkillFilterListProps> = ({
  expand,
  itemsToShow,
  filters,
  handleFilterClick,
  handleExpandClick,
}) => {
  const { results, setLastElementRef, handleSearch, nextPage } = usePagination<Skill>(
    getSkillsList,
    "error.listingSkills",
    "GET_SKILLS"
  );

  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const [searchText, setSearchText] = useState("");
  const [currentSkills, setCurrentSkills] = useState<Skill[]>([]);

  useEffect(() => {
    handleSearch(searchText ? { search: searchText } : {});
  }, [searchText]);

  // Handle if all items in current pagination page is selected, force next page in pagination
  useEffect(() => {
    if (filteredSkills.length === 0) {
      if (results.every((skill) => currentSkills.some((currSkill) => currSkill.id === skill.id))) {
        nextPage();
      }
    }
  }, [results]);

  const filteredSkills = results.filter((skill) => {
    return (
      !currentSkills.some((item) => item.id === skill.id) &&
      (searchText === "" || skill.name.toLowerCase().includes(searchText))
    );
  });

  const handleCheckboxClick = (clickedItem: JobFilterItem) => {
    const clickedSkill = { id: clickedItem.value, name: clickedItem.label };
    if (currentSkills.some((currSkill) => currSkill.id === clickedSkill.id)) {
      const tempSkills = currentSkills.filter((currSkill) => currSkill.id !== clickedSkill.id);

      setCurrentSkills(tempSkills);
    } else {
      setCurrentSkills([...currentSkills, clickedSkill]);
    }

    handleFilterClick("skills", clickedItem);
  };

  return (
    <Box>
      <Typography variant="h6">{localize("common.skills")}</Typography>
      <Box maxWidth="460px" margin="1rem 0rem">
        <WSearchBar
          label={localize("common.skillsSearch")}
          value={searchText}
          handleSearchClick={(newValue) => setSearchText(newValue.toLowerCase())}
        />
      </Box>
      {isLoading("GET_SKILLS") ? (
        <WSkeleton width="100%">
          <MenuItem />
        </WSkeleton>
      ) : (
        <Box
          sx={{
            maxHeight: `${FILTER_ITEM_HEIGHT * (isDesktop ? 15 : 10)}px`,
            overflow: "auto",
          }}
        >
          {results.length > 0 ? (
            results.slice(0, expand ? itemsToShow : undefined).map((skill, index) => (
              <React.Fragment key={skill.id}>
                <WListItem
                  key={skill.id}
                  label={skill.name}
                  value={skill.id}
                  isChecked={filters.skills.some((filterSkill) => filterSkill.value === skill.id)}
                  handleClick={handleCheckboxClick}
                  sx={{ padding: "0 .5rem" }}
                />
                {index === results.length - 1 && <div ref={setLastElementRef} />}
              </React.Fragment>
            ))
          ) : (
            <Typography>{localize("searchbar.noSkillResults")}</Typography>
          )}
        </Box>
      )}
      {expand && results.length > itemsToShow && (
        <WButton
          fullWidth
          endIcon={<WIcon icon={WIconTypes.arrowDown} color="mainDarkRed" />}
          onClick={handleExpandClick}
          sx={{ maxWidth: "max-content", color: colors.mainDarkRed }}
        >
          {localize("searchbar.showMoreSkills")}
        </WButton>
      )}
    </Box>
  );
};
