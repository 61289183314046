import { createTheme } from "@mui/material/styles";
import { ApplicationDetails } from "adapters";
import { LineGraphs } from "components/StatisticView/LineGraphs";
import { WMenuButton } from "components/styledComponents/WButton";
import { WIcon, WIconTypes } from "components/WIcon";
import { WTypography } from "components/WTypography";
import { LocaleContext } from "context/LocaleContext";
import { StatisticsFilterContext } from "context/StatisticsFilterContext";
import React, { FC, useContext, useState } from "react";
import { ButtonGroup, Grid, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { colors, defaultStyles } from "styles/colors";

const theme = createTheme({
  palette: {
    main: {
      main: colors.main,
      light: colors.mainLight,
      dark: colors.mainLight,
      contrastText: colors.white,
    },
  },
});

type TabType = "APPLICATIONS" | "CANDIDATES";

interface GraphData {
  candidatesPeriod: Record<string, number | number[] | string>;
  applicationsPeriod: ApplicationDetails;
}

export const CandidatesNumberOfNew: FC<GraphData> = ({ candidatesPeriod, applicationsPeriod }) => {
  const { period } = useContext(StatisticsFilterContext);
  const { localize } = useContext(LocaleContext);

  const [currentTab, setTab] = useState<TabType>("CANDIDATES");

  const periodLength = period === "week" ? 7 : 30;
  const applicationsTotalData: {
    gig: { total_period: number[] };
    consult: { total_period: number[] };
  } = splitUpApplications(applicationsPeriod, periodLength);
  const applicationGraphLabels = [
    localize("statisticView.gigApplication"),
    localize("statisticView.consultApplication"),
  ];

  const renderCurrentTabContent = (currentTab: TabType) => {
    switch (currentTab) {
      case "CANDIDATES":
        return <LineGraphs graphs={CANDIDATE_GRAPH} dataObj={candidatesPeriod} />;

      case "APPLICATIONS":
        return (
          <LineGraphs
            graphs={APPLICATION_GRAPH}
            dataObj={applicationsTotalData.gig}
            previousDataObj={applicationsTotalData.consult}
            labels={applicationGraphLabels}
          />
        );

      default:
        return <LineGraphs graphs={CANDIDATE_GRAPH} dataObj={candidatesPeriod} />;
    }
  };

  return (
    <Grid
      item
      width="100%"
      minWidth="350px"
      sx={{
        padding: "20px",
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadius,
        boxShadow: defaultStyles.cardShadow,
      }}
    >
      <Grid container justifyContent="space-between" paddingTop="10px">
        <Grid item>
          <WTypography variant="h5" fontWeight="bold">
            {localize("statisticView.new")} {currentTab.toLowerCase()}
          </WTypography>
        </Grid>
        <Grid item>
          <ThemeProvider theme={theme}>
            <ButtonGroup variant="contained" sx={{ boxShadow: 0, marginRight: "30px" }}>
              {menuChoices.map(({ label, tag }) => {
                const selected = tag === currentTab;
                return (
                  <WMenuButton
                    key={label}
                    variant={selected ? "contained" : "outlined"}
                    color="main"
                    onClick={() => setTab(tag as TabType)}
                  >
                    <Typography variant="body2" color={colors.mainDark}>
                      {localize(label)}
                    </Typography>
                  </WMenuButton>
                );
              })}
            </ButtonGroup>
          </ThemeProvider>
          <Tooltip title={localize("statisticView.newInformationTooltip")} placement="left">
            <Grid position="absolute" marginLeft="235px" marginTop="-50px">
              <WIcon icon={WIconTypes.info} />
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container>{renderCurrentTabContent(currentTab)}</Grid>
    </Grid>
  );
};

const menuChoices = [
  { label: "statisticView.tabCandidates", tag: "CANDIDATES" },
  { label: "statisticView.tabApplications", tag: "APPLICATIONS" },
];

const CANDIDATE_GRAPH = [
  {
    title: "newCandidates",
    prop: "created",
  },
];

const APPLICATION_GRAPH = [
  {
    title: "newApplications",
    prop: "total",
  },
];

const splitUpApplications = (applicationsData: ApplicationDetails, periodLength: number) => {
  const applicationsTotalData: {
    gig: { total_period: number[] };
    consult: { total_period: number[] };
  } = {
    gig: {
      total_period: [],
    },
    consult: {
      total_period: [],
    },
  };

  for (let i = 0; i < periodLength; i++) {
    const gigData = applicationsData.gig;
    const consultData = applicationsData.consult;

    applicationsTotalData.gig.total_period.push(
      gigData.anonymous_verified_period[i] + gigData.not_anonymous_period[i]
    );

    applicationsTotalData.consult.total_period.push(
      consultData.anonymous_verified_period[i] + consultData.not_anonymous_period[i]
    );
  }

  return applicationsTotalData;
};
