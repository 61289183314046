import { Alert, Snackbar, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useEffect, useState } from "react";
import { colors, defaultStyles } from "styles/colors";
import { MOBILE_ACTION_BAR_SIZE } from "./layout/ActionNavbar/ActionNavbar";

export const NotificationComponent: FC = () => {
  const { messages, removeMessage } = React.useContext(NotificationContext);
  const { localize } = React.useContext(LocaleContext);
  const [isOpen, setOpen] = useState(!!messages);

  useEffect(() => {
    setOpen(!!messages);
  }, [messages]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    // Timeout so that message is removed after transition effect
    setTimeout(() => {
      removeMessage();
    }, 1000);
  };

  const SnackBarColors = {
    primary: colors.mainDark,
    error: colors.error,
    success: colors.success,
  };

  return (
    <Snackbar
      sx={{
        minWidth: "60%",
        // backgroundColor: "primary",
        /** Apply padding to place it above the fixed content at bottom */
        paddingBottom: MOBILE_ACTION_BAR_SIZE,
      }}
      open={isOpen}
      autoHideDuration={messages?.duration || 5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      disableWindowBlurListener
    >
      <Alert
        severity={messages?.type}
        icon={false}
        sx={{
          width: "100%",
          borderRadius: defaultStyles.borderRadiusLarge,
          backgroundColor: messages ? SnackBarColors[messages?.type] : SnackBarColors.primary,
          svg: {
            color: colors.siteBackground,
          },
        }}
        onClose={handleClose}
      >
        {!!messages && (
          <Typography variant="body2" color="secondary">
            {messages?.content
              ? messages.isResponse
                ? messages?.content
                : localize(messages.content)
              : localize("error.generalError")}
          </Typography>
        )}
      </Alert>
    </Snackbar>
  );
};
