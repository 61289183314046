import React, { FC, ReactNode, useEffect, useState, useRef, useContext } from "react";
import { Grid, styled } from "@mui/material";
import { DESKTOP_ACTION_BAR_SIZE } from "./layout/ActionNavbar/ActionNavbar";
import { BreakpointsContext } from "context/BreakpointContext";

// HOW IT WORKS:
// This wrapping component looks for 2 id's
// 1. elementToHide
// 2. elementToTransform
// If you need an element to be hidden or transformed, apply these id's to the element/s wrapped by the DynamicHeader
// and the component will hopefully do the rest.
interface DynamicHeaderProps {
  children?: ReactNode;
  forceHide?: boolean;
  stopDynamicBehavior?: boolean;
}

export let dynamicHeaderHeight = 0;

const OFFSET_THRESHOLD = 100; // how much needed to scroll after a direction change before we hide/show hidden element
const DEBOUNCE_TIMER_MS = 100;
const DIRECTION_CHANGE_THRESHOLD = 20; // To prevent the small irregularities when scrolling

export const DynamicHeader: FC<DynamicHeaderProps> = ({
  children,
  forceHide = false,
  stopDynamicBehavior = false,
}) => {
  const [positionState, setPositionState] = useState({
    previousScrollPosition: 0,
    offsetPosition: 0,
  });

  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [simulatedHeight, setSimulatedHeight] = useState(0);
  const [direction, setDirection] = useState(0);
  const [lastDirection, setLastDirection] = useState(-1);
  const [shouldHide, setShouldHide] = useState(forceHide);
  const [shouldTransform, setShouldTransform] = useState(forceHide);
  const [timerHandle, setTimerHandle] = useState<NodeJS.Timeout | null>(null);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const { isDesktop } = useContext(BreakpointsContext);

  const onScroll = () => {
    if (timerHandle) {
      clearTimeout(timerHandle);
      setTimerHandle(null);
    }

    setTimerHandle(
      setTimeout(() => {
        setCurrentScrollPosition(window.scrollY);
      }, DEBOUNCE_TIMER_MS)
    );
  };

  // Apply forceHide when changed
  useEffect(() => {
    setShouldHide(forceHide);
    setShouldTransform(forceHide);
  }, [forceHide]);

  useEffect(() => {
    if (forceHide) {
      return;
    }

    if (window.scrollY < 25) {
      //force appear the hidden element(in case of jankyness)

      setShouldHide(false);
      setShouldTransform(false);
      return;
    }

    setPositionState({ ...positionState, previousScrollPosition: window.scrollY });

    const positionDiff = Math.abs(currentScrollPosition - positionState.previousScrollPosition);

    if (window.scrollY < positionState.previousScrollPosition) {
      // moving up
      if (positionDiff > DIRECTION_CHANGE_THRESHOLD) {
        setLastDirection(direction);
        setDirection(1);
      }

      if (direction !== lastDirection) {
        setPositionState({ ...positionState, offsetPosition: currentScrollPosition });
      }

      if (Math.abs(currentScrollPosition - positionState.offsetPosition) > OFFSET_THRESHOLD) {
        setShouldHide(false);
        setShouldTransform(false);
      }
    } else {
      //Moving down
      if (positionDiff > DIRECTION_CHANGE_THRESHOLD) {
        setLastDirection(direction);
        setDirection(0);
      }

      if (direction !== lastDirection) {
        setPositionState({ ...positionState, offsetPosition: currentScrollPosition });
      }

      if (Math.abs(currentScrollPosition - positionState.offsetPosition) > OFFSET_THRESHOLD) {
        setShouldHide(true);
        setShouldTransform(true);
      }
    }
  }, [currentScrollPosition]);

  useEffect(() => {
    if (stopDynamicBehavior) {
      return () => {
        window.removeEventListener("scroll", onScroll);
        if (timerHandle) {
          clearTimeout(timerHandle);
        }
        setTimerHandle(null);
      };
    }

    window.addEventListener("scroll", onScroll, true);

    setPositionState({ ...positionState, previousScrollPosition: window.scrollY });

    return () => {
      window.removeEventListener("scroll", onScroll);
      if (timerHandle) {
        clearTimeout(timerHandle);
      }
      setTimerHandle(null);
    };
  }, []);

  useEffect(() => {
    if (headerRef.current && !shouldHide) {
      const height = headerRef.current.getBoundingClientRect().height;
      setSimulatedHeight(height);
      dynamicHeaderHeight = height;
    }
  }, [stopDynamicBehavior]);

  return (
    <>
      <span
        style={{
          position: "sticky",
          top: "0",
          right: "0",
          width: "100%",
          zIndex: 2,
          height: `${simulatedHeight}px`,
        }}
      >
        <WDynamicHeaderContainer
          ref={headerRef}
          hiddenelementheight={shouldHide ? "0px" : "100px"}
          transformedwidth={shouldTransform ? "100%" : "0"}
          transformedfontsize={shouldTransform ? "1rem" : ""}
          container
          className="header"
          id="dynamicHeader"
          gap={!stopDynamicBehavior && shouldHide ? "0.25rem" : "1rem"}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${
              isDesktop ? "dt_vector" : "mb_vector"
            }.svg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            top: "0",
            right: "0",
            left: { md: DESKTOP_ACTION_BAR_SIZE },
            minWidth: { md: "auto" },
            zIndex: 2,
            boxShadow: { xs: "0px 5px 2px -2px rgba(0, 0, 0, 0.25);", md: 0 },
          }}
        >
          {children}
        </WDynamicHeaderContainer>
      </span>
    </>
  );
};

interface ContainerProps {
  hiddenelementheight?: string;
  transformedwidth?: string;
  transformedfontsize?: string;
}

const WDynamicHeaderContainer = styled(Grid)<ContainerProps>`
  transition: gap 0.2s ease-out;
  #elementToHide {
    transition: max-height 0.2s ease-out;
    max-height: ${(props) => props.hiddenelementheight};
    overflow: hidden;
  }
  #elementToTransform {
    transition: all 0.4s ease-out;
    text-align: center;
    overflow: visible;
    white-space: nowrap;
    top: "1rem";
    // CSS magic!
    width: ${(props) => props.transformedwidth};
    font-size: ${(props) => props.transformedfontsize};
  }
`;
