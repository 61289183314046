import React, { FC, useContext } from "react";
import { Grid } from "@mui/material";
import {
  CreateManyProfilesSVG,
  MatchingProposalsSVG,
  QuickReplySVG,
  StatusCheckUpSVG,
} from "components/assets";
import { LocaleContext } from "context/LocaleContext";
import { useDynamicPageContent } from "hooks/useDynamicPageContent";
import { DynamicViewContent, WButton } from "components";
import { UserContext } from "context/UserContext";
import { useHistory } from "react-router-dom";
import { ExplanationCard } from "components/ExplanationSVGCard";

export const ConsultingManagementView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isAuthenticated } = useContext(UserContext);

  const history = useHistory();

  const explanationCards: ExplanationCard[] = [
    {
      svg: <CreateManyProfilesSVG />,
      title: localize("consultingManagement.createProfileTitle"),
      subTitle: localize("consultingManagement.createProfileSubTitle"),
    },
    {
      svg: <MatchingProposalsSVG />,
      title: localize("consultingManagement.matchingTitle"),
      subTitle: localize("consultingManagement.matchingSubTitle"),
    },
    {
      svg: <QuickReplySVG />,
      title: localize("consultingManagement.quickReplyTitle"),
      subTitle: localize("consultingManagement.quickReplySubTitle"),
    },
    {
      svg: <StatusCheckUpSVG />,
      title: localize("consultingManagement.processTitle"),
      subTitle: localize("consultingManagement.processSubTitle"),
    },
  ];

  const { pageContent, pageHTMLContent } = useDynamicPageContent({
    pageType: "consulting_manager",
  });

  return (
    <Grid container position="relative">
      <DynamicViewContent
        pageContent={pageContent}
        forceSuperHeader
        explanationCards={explanationCards}
        pageHTMLContent={pageHTMLContent}
      />
      {!isAuthenticated && (
        <Grid container justifyContent="center">
          <WButton variant="contained" onClick={() => history.push("/login")}>
            {localize("dynamicViewsCTA.getStarted")}
          </WButton>
        </Grid>
      )}
    </Grid>
  );
};
