import { Grid } from "@mui/material";
import { Job, getJobList } from "adapters";
import { JobListHeader } from "components";
import { defaultFilterState, JobFilters } from "components/dialogs/FiltersDialog";
import { FixedJobList } from "components/FixedJobList";
import { JobList } from "components/JobList";
import Meta from "components/Meta";
import { usePagination } from "hooks/usePagination";
import { useUrlSharedData } from "hooks/useUrlSharedData";

import React, { FC, useState } from "react";
import { parseParamsAndSearch } from "utils/JobUtils";

const JobListView: FC = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramValue = urlParams.get("jobs");

  const { results, setLastElementRef, handleSearch, getInitial } = usePagination<Job>(
    getJobList,
    "error.fetchJobList",
    "GET_JOB_LIST",
    { status: "active", page_size: 5, jobs: paramValue },
    true
  );

  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<JobFilters>(defaultFilterState);
  const { jobsFromUrl, clearJobsFromUrl } = useUrlSharedData(setFilters, handleSearch, getInitial);

  const handleSearchcallback = (value: string) => {
    setSearch(value);
    const parsedFilters = parseParamsAndSearch({ search: value, ...filters });
    handleSearch(parsedFilters);
  };

  const handleFilterCallback = (value: JobFilters) => {
    setFilters(value);
    const parsedFilters = parseParamsAndSearch({ search: search, ...value });
    handleSearch(parsedFilters);
  };

  return (
    <>
      <Meta
        title="Available assignments on GetWiser"
        desc="Find available assignments using advanced but easy search and filter systems"
      />
      <JobListHeader
        searchCallback={handleSearchcallback}
        currentParams={{ ...filters, search: search }}
        filterCallback={handleFilterCallback}
      />
      <Grid container className="contentWrapper">
        {jobsFromUrl ? (
          <FixedJobList jobs={jobsFromUrl} onShowAll={clearJobsFromUrl} />
        ) : (
          <JobList
            jobList={results}
            lastElementRef={setLastElementRef}
            filters={filters}
            setFilters={handleFilterCallback}
          />
        )}
      </Grid>
    </>
  );
};
export default JobListView;
