import { Grid, Typography } from "@mui/material";
import { WButton, WIcon, WIconTypes } from "components";
import { ForgotPasswordForm } from "components/forms/ForgotPasswordForm";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { LoginBackgroundBottomSVG, LoginBackgroundTopSVG } from "./LoginView";

export const ForgotPasswordView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const history = useHistory();

  return (
    <Grid
      container
      className="contentWrapper"
      alignItems="center"
      position="relative"
      sx={{
        flex: "1 1 auto",
      }}
    >
      {isDesktop && (
        <>
          <LoginBackgroundTopSVG />
          <LoginBackgroundBottomSVG />
        </>
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        gap="2rem"
        zIndex="2"
        maxWidth="400px"
        margin="auto"
      >
        <Typography width="100%" variant="h5" color="primary" fontWeight="bold">
          {localize("form.forgotPassword")}
        </Typography>
        <ForgotPasswordForm />
        <WButton
          onClick={() => history.goBack()}
          variant="outlined"
          sx={{ maxWidth: "max-content", border: "2px solid" }}
          startIcon={<WIcon icon={WIconTypes.arrowLeft} />}
        >
          {localize("common.goBack")}
        </WButton>
      </Grid>
    </Grid>
  );
};
