import { KanbanJob, getKanbanJobList } from "adapters/JobAdapter";
import { addMonths } from "date-fns";
import { usePagination } from "hooks/usePagination";
import React, { FC, createContext, useCallback, useState } from "react";
import { prettifyDate } from "utils/PrettifyDate";

export const CreationLimits = [3, 6, 12, -1];

export const KanbanJobsContext = createContext<{
  fetchAllJobs: (limit: number) => void;
  jobResults: KanbanJob[];
  creationLimit: number;
  setCreationLimit: (newLimit: number) => void;
}>({
  fetchAllJobs: () => false,
  jobResults: [],
  creationLimit: 3,
  setCreationLimit: () => false,
});

const KanbanJobsProvider: FC = ({ children }) => {
  const [creationLimit, setCreationLimit] = useState(3);
  const { results, handleSearch } = usePagination<KanbanJob>(
    getKanbanJobList,
    "error.fetchJobList",
    "GET_JOB_LIST",
    {
      page_size: 1000,
    },
    true
  );

  const fetchAllJobs = useCallback((newLimit: number) => {
    if (newLimit === -1) {
      handleSearch({});
    } else {
      const limit = prettifyDate(addMonths(new Date(), -newLimit).toISOString());
      handleSearch({ created_gte: limit });
    }
  }, []);

  return (
    <KanbanJobsContext.Provider
      value={{ fetchAllJobs, jobResults: results, creationLimit, setCreationLimit }}
    >
      {children}
    </KanbanJobsContext.Provider>
  );
};

export default KanbanJobsProvider;
