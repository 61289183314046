import { LineGraphs } from "components/StatisticView/LineGraphs";
import React, { FC, useContext } from "react";
import { Grid } from "@mui/material";
import { colors, defaultStyles } from "styles/colors";
import { WTypography } from "components/WTypography";
import { LocaleContext } from "context/LocaleContext";

interface GraphData {
  logins: Record<string, number | number[] | string>;
}
export const CandidatesNumberOfLogins: FC<GraphData> = ({ logins }) => {
  const { localize } = useContext(LocaleContext);

  return (
    <Grid
      item
      width="100%"
      minWidth="350px"
      sx={{
        padding: "20px",
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadius,
        boxShadow: defaultStyles.cardShadow,
      }}
    >
      <WTypography variant="h5" fontWeight="bold">
        {localize("statisticView.numberOfLogins")}
      </WTypography>

      <Grid container>
        <LineGraphs graphs={LOGINGRAPH} dataObj={logins} />
      </Grid>
    </Grid>
  );
};

const LOGINGRAPH = [
  {
    title: "logins",
    prop: "logged_in",
  },
];
