import { LocaleContext } from "context/LocaleContext";
import { getHours } from "date-fns";
import { useContext } from "react";

export const generateWelcomeMessage = (): { welcomeMessage: string } => {
  const { localize } = useContext(LocaleContext);
  const currentDate = new Date();
  const currentHour = getHours(currentDate);

  let currentMessage = localize("dashboardView.welcomeMessageMorning");
  if (currentHour >= 0 && currentHour < 9) {
    currentMessage = localize("dashboardView.welcomeMessageMorning");
  } else if (currentHour >= 9 && currentHour < 18) {
    currentMessage = localize("dashboardView.welcomeMessageDay");
  } else if (currentHour >= 18) {
    currentMessage = localize("dashboardView.welcomeMessageNight");
  }
  return { welcomeMessage: currentMessage };
};
