import { Grid, Box } from "@mui/material";
import { WButton, WIconTypes, WIcon, WTypography } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useEffect, useRef } from "react";
import { Job, removeSavedJob, saveJob } from "adapters";
import dompurify from "dompurify";
import { ShareJobButton } from "components/ShareJobButton";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import { useHistory } from "react-router-dom";
import { ModalContext } from "context/ModalContext";
import { parseJobBodyWithBlankLinks } from "utils/JobUtils";

interface DetailedJobBodyProps {
  job: Job;
  handleOpenApplicationForm: () => void;
  setSaveJob: (state: boolean) => void;
  isSaved: boolean;
  isApplication?: boolean;
  isCancelled?: boolean;
}
export const DetailedJobBody: FC<DetailedJobBodyProps> = ({
  job,
  handleOpenApplicationForm,
  setSaveJob,
  isSaved,
  isApplication = false,
  isCancelled = false,
}) => {
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { isAuthenticated } = useContext(UserContext);
  const { hideModal } = useContext(ModalContext);

  const { body, pitch, id } = job;

  const history = useHistory();

  const HTMLSanitizer = dompurify.sanitize;
  const content = useRef<HTMLDivElement | null>(null);

  const handleSaveJob = () => {
    saveJob(id)
      .then(() => {
        setSaveJob(true);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.saveJob" });
      });
  };

  const handleRemoveJob = () => {
    removeSavedJob(id)
      .then(() => {
        setSaveJob(false);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.removeSavedJob" });
      });
  };

  useEffect(() => {
    parseJobBodyWithBlankLinks(content);
  }, [content, body]);

  return (
    <>
      <Grid padding="1rem">
        <WTypography variant="body1" lineClamps={4}>
          {pitch}
        </WTypography>
      </Grid>

      <Box
        overflow="hidden"
        sx={{
          "& img": {
            maxWidth: "100%",
          },
          padding: "1rem",
        }}
      >
        <span ref={content} dangerouslySetInnerHTML={{ __html: HTMLSanitizer(body) }}></span>
      </Box>
      {!isCancelled && (
        <Grid container item padding="0 1rem" md={4}>
          <WButton
            onClick={handleOpenApplicationForm}
            variant="contained"
            fullWidth
            startIcon={!isApplication && <WIcon icon={WIconTypes.award} color="secondary" />}
          >
            {isApplication
              ? localize("detailedJobDialog.editApplication")
              : localize("detailedJobDialog.applyButton")}
          </WButton>
        </Grid>
      )}

      <Grid container item wrap="nowrap" gap="1rem" padding="1rem" md={4}>
        {!isApplication && (
          <WButton
            variant="outlined"
            onClick={(event) => {
              event.stopPropagation();
              if (isAuthenticated) {
                isSaved ? handleRemoveJob() : handleSaveJob();
                return;
              }
              hideModal();
              history.push("/login");
            }}
            sx={{ width: "100%" }}
            startIcon={<WIcon icon={WIconTypes.heart} color="primary" fill={isSaved} />}
          >
            {!isSaved && localize("dialog.saveJobNoEmoji")}
          </WButton>
        )}
        <ShareJobButton job={job} />
      </Grid>
    </>
  );
};
