import React, { FC, useContext, useMemo } from "react";
import { Grid } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { colors } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";
import { TopSkill } from "adapters/ReportsAdapter";

interface GraphData {
  data: TopSkill[];
}

export const HorizontalBarChart: FC<GraphData> = ({ data }) => {
  const { localize } = useContext(LocaleContext);

  const { labels, jobs, candidates } = useMemo(() => {
    return splitIntoLabelCandidatesJobs(data);
  }, [data]);

  const dataSetJob = {
    label: `${localize("statisticView.jobs")}`,
    data: jobs,
    backgroundColor: [colors.mainDarkRed],
    borderColor: [colors.mainDarkRed],
    borderWidth: 0,
    borderRadius: {
      topRight: 10,
      topLeft: 10,
      bottomRight: 10,
      bottomLeft: 10,
    },
    borderSkipped: false,
    barPercentage: 0.6,
  };

  const dataSetCandidates = {
    label: `${localize("statisticView.tabCandidates")}`,
    data: candidates,
    backgroundColor: [colors.main],
    borderColor: [colors.main],
    borderWidth: 0,
    borderRadius: 10,
    borderSkipped: false,
    barPercentage: 0.6,
  };

  return (
    <Grid container height="100%" minHeight="800px" maxHeight="1100px">
      <Bar
        data={{
          labels: labels,
          datasets: [dataSetJob, dataSetCandidates],
        }}
        options={{
          indexAxis: "y",
          maintainAspectRatio: false,
          plugins: {
            legend: {
              align: "end",
              labels: {
                usePointStyle: true,
                pointStyle: "rectRounded",
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                drawBorder: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            y: {
              beginAtZero: true,
              stacked: true,
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </Grid>
  );
};

const splitIntoLabelCandidatesJobs = (obj: TopSkill[]) => {
  const labels: string[] = [],
    jobs: number[] = [],
    candidates: number[] = [];

  obj.forEach((item) => {
    labels.push(item.skill);
    jobs.push(item.job_count);
    candidates.push(item.user_count);
  });

  return { labels, jobs, candidates };
};
