import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { deleteJobApplication, JobApplication, Job } from "adapters";
import { JobCardTitleComponent } from "components/JobCardTitleComponent";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useContext, useState } from "react";
import { colors, defaultStyles } from "styles/colors";

interface ProfileJobApplicationCardProps {
  isDraft: boolean;
  isCancelled: boolean;
  stage?: "";
  job: Job;
  jobApplication: JobApplication;
  onRemove: () => void;
}

export const ProfileJobApplicationCard: FC<ProfileJobApplicationCardProps> = ({
  isDraft,
  onRemove,
  jobApplication,
  job,
  isCancelled,
}) => {
  const { showModal, hideDialog } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const [isTypeDraft, setIsDraft] = useState(isDraft);

  const { job_status } = job;

  const removeApplication = async (applId: string) => {
    deleteJobApplication(applId)
      .then(() => {
        setTimeout(() => {
          onRemove();
          addMessage({ type: "success", content: "success.jobApplicationRemoved", duration: 2000 });
        }, 300);
        hideDialog();
      })
      .catch(() => {
        addMessage({ type: "success", content: "error.removeJobApplication", duration: 2000 });
      });
  };

  const handleRemoval = (applId: string) => {
    removeApplication(applId);
    hideDialog();
  };

  const HandleButtonPress = () => {
    return (
      <Button
        fullWidth
        size="large"
        variant="text"
        sx={{ color: "text.primary" }}
        onClick={() => {
          handleRemoval(jobApplication.id);
        }}
      >
        {localize("dialog.removeJobApplication")}
      </Button>
    );
  };

  return (
    <Grid
      container
      padding="1rem"
      gap=".5rem"
      wrap="nowrap"
      position="relative"
      direction="column"
      sx={
        job_status === "archived" || isCancelled
          ? { boxShadow: "none" }
          : {
              transition: "background-color 0.2s ease-out",
              boxShadow: isDesktop ? defaultStyles.cardShadow : "none",
              borderRadius: isDesktop ? defaultStyles.borderRadius : "0",
              "&:hover": {
                backgroundColor: colors.grey,
              },
            }
      }
      bgcolor="secondary.main"
      onClick={() => {
        if (!isDesktop) {
          showModal("DETAILED_JOB_APPLICATION_MODAL", {
            applicationId: jobApplication.id,
            handleTypeChange: setIsDraft,
            job: job,
            onRemoveApplication: handleRemoval,
            isCancelled: isCancelled || job_status !== "archived",
          });
        }
      }}
    >
      <JobCardTitleComponent
        hideTopButtons={isDesktop || job_status === "archived" || isCancelled}
        job={job}
        handleButtonPress={<HandleButtonPress />}
        isDraft={isTypeDraft}
        showStatus={true}
        isCancelled={isCancelled}
        applicationData={{
          id: jobApplication.id,
          is_draft: jobApplication.is_draft,
          status: jobApplication.status,
          status_progression: jobApplication.status_progression,
        }}
      />
      {job_status === "archived" ||
        (isCancelled && (
          <Box
            sx={{
              position: "absolute",
              top: "0",
              right: "0",
              left: "0",
              bottom: "0",
              backgroundColor: colors.grey,
              opacity: 0.5,
            }}
          ></Box>
        ))}
    </Grid>
  );
};
