import { styled, Button, ButtonProps, CircularProgress } from "@mui/material";
import { defaultStyles, colors } from "styles/colors";
import React, { FC } from "react";

export const WButton = styled(Button)(
  (props) => `
  border-radius: ${defaultStyles.borderRadiusFullyRounded};
  svg {
    stroke: ${props.variant === "contained" ? "#fff" : ""};
  }
`
);

export const WMenuButton = styled(Button)(
  (props) => `
  border-color: ${colors.main} !important;
  text-transform: none;
  border-radius: 0.4rem;
  padding: .4rem 1.1rem;
  svg {
    stroke: ${props.variant === "contained" ? "#fff" : ""};
  }
  `
);

export const WDialogButton = styled(Button)(`
  border-radius: ${defaultStyles.borderRadiusFullyRounded};
  padding: .2rem 2rem;
`);

export const WRedButton = styled(Button)(
  ({ theme }) => `
  border-radius: ${defaultStyles.borderRadiusFullyRounded};
  border-color: ${theme.palette.error.main};
  color: ${theme.palette.error.main};
  font-weight: bold;
`
);

interface WLoadingButtonProps {
  displayLoading: boolean;
  skipDisabled?: boolean;
}

export const WLoadingButton: FC<WLoadingButtonProps & ButtonProps> = (props) => {
  const { displayLoading, skipDisabled = false, children, ...buttonProps } = props;
  return (
    <WButton
      {...buttonProps}
      disabled={!skipDisabled ? buttonProps.disabled || displayLoading : false}
    >
      {children}
      {displayLoading && (
        <CircularProgress size={20} color="secondary" sx={{ marginLeft: "1rem" }} />
      )}
    </WButton>
  );
};
