import React, { FC, useContext } from "react";
import { WButton } from "components";
import { LocaleContext } from "context/LocaleContext";
import { UserContext } from "context/UserContext";
import { useHistory } from "react-router-dom";
import { DynamicViewContent } from "components/DynamicViewComponent";
import { useDynamicPageContent } from "hooks/useDynamicPageContent";
import { ChatUsSVG, MailUsSVG, VisitUsSVG } from "components/assets";
import { Grid, Link } from "@mui/material";
import { ExplanationCard } from "components/ExplanationSVGCard";
import Meta from "components/Meta";

export const ContactUs: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isAuthenticated } = useContext(UserContext);
  const history = useHistory();

  const explanationCards: ExplanationCard[] = [
    {
      svg: <ChatUsSVG />,
      title: localize("aboutUs.chatTitle"),
      callToAction: (
        <WButton
          variant="contained"
          onClick={() => {
            if (isAuthenticated) {
              history.push("/messages");
              return;
            }
            history.push("/login");
          }}
        >
          {localize("aboutUs.chatSubTitle")}
        </WButton>
      ),
    },
    {
      svg: <MailUsSVG />,
      title: localize("aboutUs.mailUsTitle"),
      link: (
        <Link href={`mailto:${localize("aboutUs.mailUsSubTitle")}`}>
          {localize("aboutUs.mailUsSubTitle")}
        </Link>
      ),
    },
    {
      svg: <VisitUsSVG />,
      title: localize("aboutUs.visitUsTitle"),
      subTitle: localize("aboutUs.visitUsSubTitle"),
      subsubTitle: localize("aboutUs.address"),
    },
    // {
    //   svg: <CallUsSVG />,
    //   title: localize("aboutUs.callUsTitle"),
    //   subTitle: localize("aboutUs.callUsSubTitle"),
    // },
  ];
  const { pageContent, pageHTMLContent } = useDynamicPageContent({ pageType: "about_us" });

  return (
    <Grid container position="relative">
      <Meta title="Contact us" desc="How to get in contact with the team at GetWiser" />
      <DynamicViewContent
        pageContent={pageContent}
        explanationCards={explanationCards}
        pageHTMLContent={pageHTMLContent}
      />
    </Grid>
  );
};
