import React, { FC } from "react";
import { UserContext } from "context/UserContext";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface PublicRouteProps extends RouteProps {
  redirect?: boolean;
}

export const PublicRoute: FC<PublicRouteProps> = ({
  children,
  redirect,
  ...props
}): JSX.Element => {
  const { isAuthenticated } = React.useContext(UserContext);

  if (isAuthenticated && redirect) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  return <Route {...props}>{children}</Route>;
};
