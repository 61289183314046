import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { Job, Location, removeSavedJob, RoleWithDepartment, saveJob } from "adapters";
import { ActiveJobApplication, defaultActiveJobApplication } from "adapters/JobAdapter";
import { WIcon, WIconButton, WTypography, WIconTypes } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { GTMContext } from "context/GTMContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, SyntheticEvent, useContext } from "react";
import { useHistory } from "react-router-dom";
import { colors } from "styles/colors";
import { prettifyDate } from "utils/PrettifyDate";
import { ApplicationStatusComponent } from "./ApplicationStatusComponent";

interface JobCardTitleComponentProps {
  job: Job;
  iconVariant?: "options" | "expanded" | "none";
  handleButtonPress?: JSX.Element;
  hideTopButtons?: boolean;
  isDraft?: boolean;
  applicationDate?: string;
  showStatus?: boolean;
  isCancelled?: boolean;
  applicationData?: ActiveJobApplication | null;
  isSaved?: boolean;
  setIsSaved?: (state: boolean) => void;
  selected?: boolean;
  onToggleSelect?: (jobId: string) => void;
}

export const JobCardTitleComponent: FC<JobCardTitleComponentProps> = ({
  job,
  iconVariant = "options",
  handleButtonPress,
  hideTopButtons = false,
  isDraft = false,
  applicationDate,
  showStatus = false,
  applicationData,
  isSaved,
  isCancelled = false,
  setIsSaved,
  selected,
  onToggleSelect,
}) => {
  const { localize } = useContext(LocaleContext);
  const { showDialog, hideDialog } = useContext(ModalContext);
  const { isAuthenticated } = useContext(UserContext);
  const { addMessage } = useContext(NotificationContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);

  const { id = "", title = "", locations = [], pictures = [], role } = job;
  const {
    id: appId = "",
    status = null,
    status_progression = [],
  } = applicationData || defaultActiveJobApplication;

  const history = useHistory();

  const generateMailURL = (id: string): string => {
    const queryString = window.location.origin;

    return encodeURIComponent(`${queryString}/jobs/${id}`);
  };

  const handleShareJobClick = () => {
    if (isAuthenticated) {
      showDialog("SHARE_JOB", { jobId: id, title: title });
    } else {
      // Send email popup if user is not logged in
      gtmAddToDataLayer({ action: job.title }, "application_shared");

      window.location.href = `mailto:${""}?subject=${localize(
        "shareJobDialog.mailSubject"
      )}: ${title}&body=${encodeURIComponent(
        localize("shareJobDialog.mailBody", { title: job.title })
      )} ${generateMailURL(id)}`;
    }
  };

  const handleSaveJob = () => {
    if (!setIsSaved) {
      return;
    }
    saveJob(id)
      .then(() => {
        setIsSaved(true);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.saveJob" });
      });
  };

  const handleRemoveJob = () => {
    if (!setIsSaved) {
      return;
    }
    removeSavedJob(id)
      .then(() => {
        setIsSaved(false);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.removeSavedJob" });
      });
  };

  const handleButtonClick = (event: SyntheticEvent) => {
    // Handle so that the parent click event wont trigger
    event.stopPropagation();
    showDialog("OPTIONS_DIALOG", {
      content: (
        <Box>
          {isAuthenticated && handleButtonPress ? (
            handleButtonPress
          ) : (
            <Button
              fullWidth
              disabled={appId !== ""}
              size="large"
              aria-label="save job"
              variant="text"
              sx={{ color: "text.primary" }}
              onClick={() => {
                if (isAuthenticated) {
                  isSaved ? handleRemoveJob() : handleSaveJob();
                  hideDialog();

                  return;
                }
                history.push("/login");
                hideDialog();
              }}
            >
              {isSaved ? localize("dialog.alreadySavedJob") : localize("dialog.saveJob")}
            </Button>
          )}
          <Divider sx={{ width: "100%" }} />
          <Button
            fullWidth
            size="large"
            variant="text"
            aria-label="share job"
            sx={{ color: "text.primary" }}
            onClick={() => {
              handleShareJobClick();
            }}
          >
            {localize("dialog.recommendJob")}
          </Button>
          <Divider sx={{ width: "100%" }} />
        </Box>
      ),
      handleClose: () => hideDialog(),
    });
  };

  const renderIcons = () => {
    if (onToggleSelect) {
      return (
        <Box position="absolute" top="0" right="0" zIndex={1}>
          <WIconButton
            onClick={(event) => {
              event.stopPropagation();
              onToggleSelect(id);
            }}
            aria-label="toggle select"
          >
            <WIcon icon={selected ? WIconTypes.checkSquare : WIconTypes.square} />
          </WIconButton>
        </Box>
      );
    } else if (iconVariant === "options") {
      return (
        <Box position="absolute" top="0" right="0" zIndex={1}>
          <WIconButton
            onClick={(event) => {
              handleButtonClick(event);
            }}
            aria-label="more actions"
            sx={{ marginLeft: "auto", marginBottom: "auto" }}
          >
            <WIcon icon={WIconTypes.moreHorizontal} />
          </WIconButton>
        </Box>
      );
    } else if (iconVariant === "expanded") {
      return (
        <Grid item xs="auto" position="absolute" top="0" right="0" zIndex={1}>
          <WIconButton
            onClick={(event) => {
              event.stopPropagation();
              handleShareJobClick();
            }}
            aria-label="share job"
          >
            <WIcon icon={WIconTypes.share} />
          </WIconButton>
          <WIconButton
            disabled={appId !== ""}
            aria-label="save job"
            sx={{ opacity: appId !== "" ? 0.5 : 1 }}
            onClick={(event) => {
              event.stopPropagation();
              if (isAuthenticated) {
                isSaved ? handleRemoveJob() : handleSaveJob();
                return;
              }
              history.push("/login");
            }}
          >
            <WIcon icon={WIconTypes.heart} fill={isSaved} />
          </WIconButton>
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <Grid position="relative" container wrap="nowrap">
      {!hideTopButtons && renderIcons()}
      <Grid container wrap="nowrap" gap=".5rem" position="relative" direction="column">
        <Grid container gap="1rem" wrap="nowrap">
          <div
            style={{
              display: "flex",
              minHeight: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={
                pictures.length
                  ? pictures[0].thumb
                  : `${process.env.PUBLIC_URL}/assets/logo/logo_black.png`
              }
              alt="Thumbnail image"
              width={isDesktop ? "100px" : "63px"}
              height={isDesktop ? "66px" : "42px"}
              style={{ objectFit: "contain" }}
            />
          </div>
          <Grid container direction="column" wrap="nowrap">
            <Grid
              container
              item
              paddingRight={hideTopButtons ? "0" : "2rem"}
              direction="column"
              wrap="nowrap"
            >
              <WTypography
                lineClamps={2}
                width="calc(100% - 120px)"
                variant={isDesktop ? "h6" : "body1"}
                fontWeight="bold"
                color={colors.mainDark}
                sx={{ fontSize: isDesktop && !hideTopButtons ? "23px" : "1rem" }}
              >
                {title}
              </WTypography>
              <LocationsComponent locations={locations} role={role} />
            </Grid>
            {showStatus && !isDesktop && (
              <Grid
                container
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
                gap=".5rem"
              >
                <ApplicationStatusComponent
                  isDraft={isDraft}
                  applicationStatus={status}
                  jobStatus={job.job_status}
                  isCancelled={isCancelled}
                  statusProgression={status_progression}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        {isDesktop && showStatus && (
          <>
            <Typography variant="body2" fontWeight="light" color="primary">
              {applicationDate &&
                `
                 ${localize("jobCard.lastUpdated")}
                 ${prettifyDate(applicationDate, { showRelativeTime: false })}
                 `}
            </Typography>
            <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
              <ApplicationStatusComponent
                isDraft={isDraft}
                applicationStatus={status}
                jobStatus={job.job_status}
                isCancelled={isCancelled}
                statusProgression={status_progression}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

interface LocationsComponentProps {
  locations?: Location[];
  role?: RoleWithDepartment;
  maxLocations?: number;
}

export const LocationsComponent: FC<LocationsComponentProps> = ({
  locations = [],
  maxLocations,
}) => {
  const MAX_LOCATIONS = maxLocations || 2;

  const getLocationsString = (locations: Location[]) => {
    if (!locations) {
      return "";
    }

    {
      !!locations.length &&
        locations
          .slice(0, 2)
          .map((location) => location.city)
          .join(", ");
    }

    return locations
      .slice(0, MAX_LOCATIONS)
      .map((location) => location.city)
      .join(", ");
  };

  //Not decided if they want role yet. But I'll leave it for now :)
  const getRoleString = (role?: RoleWithDepartment | null) => {
    if (!role?.department) {
      return "";
    }

    return `, ${role.department.name}-${role.name}`;
  };

  const locationAndRoleString = (locations: Location[] | [], role?: RoleWithDepartment | null) => {
    if (getLocationsString(locations) === "") {
      return getRoleString(role);
    } else {
      if (getRoleString(role) === "") {
        return getLocationsString(locations);
      } else {
        return `${getLocationsString(locations)} ${getRoleString(role)}`;
      }
    }
  };

  return (
    <>
      <Typography
        variant="body2"
        color="gray"
        sx={{
          overflowWrap: "break-word",
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
        }}
      >
        {locationAndRoleString(locations)}
      </Typography>
    </>
  );
};
