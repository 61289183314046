import React, { ChangeEvent, FC, useContext, useEffect } from "react";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useDynamicPageContent } from "hooks/useDynamicPageContent";
import { DynamicViewContent } from "components";
import { LocaleContext } from "context/LocaleContext";
import {
  COOKIE_DAYS_TO_LIVE,
  getWithExpiry,
  setWithExpiry,
} from "components/dialogs/CookieConsent";
import { GTMContext } from "context/GTMContext";

export const CookiePolicyView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { setCookieValue, cookieValue } = useContext(GTMContext);

  const { pageContent, pageHTMLContent } = useDynamicPageContent({ pageType: "cookie" });

  const handleCookieSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked ? "true" : "false";

    setWithExpiry("allowAnalytics", isChecked, COOKIE_DAYS_TO_LIVE);
    setCookieValue(isChecked);
  };

  useEffect(() => {
    switch (getWithExpiry("allowAnalytics")) {
      case "true":
        setCookieValue("true");
        break;
      default:
        setCookieValue("false");
        break;
    }
  }, []);

  return (
    <Grid container position="relative">
      <DynamicViewContent pageContent={pageContent} pageHTMLContent={pageHTMLContent} />
      {pageHTMLContent && (
        <Grid container padding="1rem">
          <FormControlLabel
            control={
              <Switch
                checked={cookieValue === "true" ? true : false}
                onChange={handleCookieSwitch}
              />
            }
            label={localize("cookie.cookieSwitch")}
            labelPlacement="start"
          />
        </Grid>
      )}
    </Grid>
  );
};
