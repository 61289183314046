import {
  ModalInitialState,
  ModalContextState,
  ModalTypes,
  DialogTypes,
} from "context/ModalContext";

export type ModalActionType =
  | { type: "SHOW_MODAL"; modalType: ModalTypes; modalProps?: Record<string, unknown> }
  | { type: "HIDE_MODAL" }
  | {
      type: "POP_MODAL";
    }
  | {
      type: "PUSH_MODAL";
      modalType: ModalTypes;
      modalProps?: Record<string, unknown>;
    }
  | {
      type: "SHOW_DIALOG";
      dialogType: DialogTypes;
      dialogProps?: Record<string, unknown>;
    }
  | { type: "HIDE_DIALOG" };

export function ModalReducer(
  state: ModalContextState = ModalInitialState,
  action: ModalActionType
): ModalContextState {
  switch (action.type) {
    case "SHOW_MODAL":
      return {
        ...state,
        stack: [{ modalType: action.modalType, modalProps: action.modalProps || {} }],
      };
    case "HIDE_MODAL":
      return {
        ...state,
        stack: [],
      };
    case "POP_MODAL": {
      const currentModal = state.stack;
      currentModal.pop();
      return {
        ...state,
        stack: currentModal,
      };
    }
    case "PUSH_MODAL":
      return {
        ...state,
        stack: [
          ...state.stack,
          { modalType: action.modalType, modalProps: action.modalProps || {} },
        ],
      };
    case "SHOW_DIALOG":
      return {
        ...state,
        dialogType: action.dialogType,
        dialogProps: action.dialogProps || {},
      };
    case "HIDE_DIALOG":
      return {
        ...state,
        dialogType: undefined,
        dialogProps: undefined,
      };
    default:
      return state;
  }
}
