import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { LoginForm } from "components/forms/LoginForm";
import { Typography, Grid } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { colors } from "styles/colors";
import { Box } from "@mui/system";
import { BreakpointsContext } from "context/BreakpointContext";

const LoginPageView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const history = useHistory();

  return (
    <Grid
      container
      className="contentWrapper"
      alignItems="center"
      position="relative"
      sx={{
        flex: "1 1 auto",
      }}
    >
      {isDesktop && (
        <>
          <LoginBackgroundTopSVG />
          <LoginBackgroundBottomSVG />
        </>
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        gap="2rem"
        zIndex="2"
        maxWidth="400px"
        minHeight="550px"
        margin="auto"
      >
        <Typography width="100%" variant="h5" color="primary" fontWeight="bold">
          {localize("navbar.loginText")}
        </Typography>
        <LoginForm registerButton={true} onSubmit={() => history.push("/")} />
      </Grid>
    </Grid>
  );
};

export default LoginPageView;

export const LoginBackgroundTopSVG: FC = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        top: 0,
        right: 0,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 313 896"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_68_2022)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M276.237 -44.4335C280.569 -32.7902 267.353 -12.8983 271.459 -1.10482C278.092 17.9467 304.105 21.9697 306.795 43.3348C309.928 68.2202 315.94 106.616 287.685 127.755C254.645 152.473 227.775 105.127 193.855 127.829C153.495 154.841 154.175 220.448 112.987 244.678C81.4597 263.225 64.711 229.25 45.1922 215.286C27.8479 202.878 -1.04919 194.37 4.99264 167.417C12.4749 134.039 79.7381 100.599 77.6112 70.6653C75.0422 34.5122 16.0287 69.687 11.5722 34.4744C8.37239 9.1922 32.042 -38.9553 61.1124 -59.6954C89.9117 -80.242 121.526 -84.536 150.289 -88.4019C172.732 -91.4184 189.872 -83.9197 208.791 -79.5443C222.713 -76.3245 235.509 -72.1125 247.329 -65.9589C258.392 -60.1998 271.827 -56.2863 276.237 -44.4335Z"
            fill={colors.main}
          />
        </g>
        <defs>
          <filter
            id="filter0_d_68_2022"
            x="0.176086"
            y="-89.0981"
            width="313.4"
            height="347.186"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_68_2022" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_68_2022"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Box>
  );
};
export const LoginBackgroundBottomSVG: FC = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        bottom: "1rem",
        left: 0,
        height: "max-content",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 390 312"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_68_2023)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-84.7566 178.984C-89.6248 166.098 -72.4058 155.121 -76.9878 142.22C-84.3897 121.378 -116.437 103.801 -118.856 82.9705C-121.675 58.7082 -127.481 20.644 -91.5804 16.587C-49.6018 11.8432 -18.3425 69.1975 24.6408 66.7543C75.784 63.8474 77.7453 3.94308 129.796 4.00408C169.638 4.05078 188.931 43.8515 212.511 66.9745C233.463 87.5215 268.892 110.714 260.257 131.94C249.563 158.226 164.823 152.563 166.178 180.868C167.816 215.054 239.773 211.407 243.789 245.75C246.672 270.407 217.937 304.75 181.045 308.009C144.496 311.237 105.156 298.209 69.3646 286.318C41.4376 277.04 20.5277 261.058 -2.71802 246.958C-19.8241 236.583 -35.4932 225.909 -49.8714 213.996C-63.3281 202.846 -79.8008 192.102 -84.7566 178.984Z"
            fill={colors.main}
          />
        </g>
        <defs>
          <filter
            id="filter0_d_68_2023"
            x="-121.017"
            y="0.00390625"
            width="390.604"
            height="312.504"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_68_2023" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_68_2023"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Box>
  );
};
