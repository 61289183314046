import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const CreateProfileSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 256 260"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="255" height="260" transform="translate(0.5)" fill="white" />
        <mask
          id="mask0_6_807"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="256"
          height="260"
        >
          <rect x="0.5" width="255" height="260" fill="white" />
        </mask>
        <g mask="url(#mask0_6_807)">
          <rect opacity="0.02" x="0.5" width="255" height="260" fill="white" />
        </g>
        <g opacity="0.5">
          <path
            d="M140.167 115H116.833C114.992 115 113.5 116.492 113.5 118.333V141.667C113.5 143.508 114.992 145 116.833 145H140.167C142.008 145 143.5 143.508 143.5 141.667V118.333C143.5 116.492 142.008 115 140.167 115Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M122.667 126.667C124.047 126.667 125.167 125.547 125.167 124.167C125.167 122.786 124.047 121.667 122.667 121.667C121.286 121.667 120.167 122.786 120.167 124.167C120.167 125.547 121.286 126.667 122.667 126.667Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M143.5 135L135.167 126.667L116.833 145"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g clipPath="url(#clip0_6_807)">
          <path
            d="M98.4643 91.4196C97.2656 91.876 96.0415 92.2629 94.7982 92.5783C93.1558 92.9784 92.2286 93.1638 90.6434 92.5489C90.5878 92.5274 90.5346 92.506 90.4845 92.485C87.4799 91.2082 84.9116 89.0861 83.092 86.3768C81.2724 83.6676 80.2801 80.4881 80.2359 77.2254C80.228 77.1134 80.228 76.9958 80.228 76.881C80.2264 76.328 80.2529 75.7753 80.3072 75.225C80.3767 74.4754 80.4967 73.7313 80.6664 72.9979L80.6505 72.995C79.3671 71.6834 78.8733 69.8868 79.037 68.0816C79.1492 66.8578 79.5378 65.6753 80.1737 64.6234C80.8095 63.5714 81.6759 62.6775 82.7076 62.0088C84.3245 60.9928 86.1477 60.3496 88.0445 60.1261C88.5538 60.0674 89.066 60.0377 89.5787 60.0371H94.3954C95.3902 59.8043 96.4008 59.6451 97.419 59.5607H97.4269C107.121 58.7571 114.766 65.1801 114.053 72.995C114.048 73.0036 114.04 73.0122 114.034 73.0209C113.828 73.3566 113.646 73.6752 113.485 73.9794C113.329 74.2635 113.197 74.5362 113.084 74.7945C112.199 76.792 116.403 107.783 114.895 108.254C114.037 108.521 110.578 85.4593 111.198 89.8446C111.98 95.3463 102.93 89.7697 98.4643 91.4196Z"
            fill="#2F2E41"
          />
          <path
            d="M113.068 147.443L126.506 176.054L74.8132 170.822L85.6037 143.355L113.068 147.443Z"
            fill="#FFB6B6"
          />
          <path
            d="M140.043 204.808C131.054 207.798 121.581 209.066 112.122 208.546L108.008 201.878C108.008 201.878 107.836 204.361 107.579 208.157C103.076 207.636 98.6318 206.694 94.3045 205.345C86.0979 202.78 78.4052 198.806 70.6075 195.141C69.028 185.731 68.4354 171.221 77.1674 162.774L124.167 171.382C124.167 171.382 131.708 177.019 129.691 179.038C127.674 181.054 127.361 180.742 128.687 182.067C130.012 183.391 132.876 185.163 132.905 186.321C132.917 186.773 136.131 194.975 140.043 204.808Z"
            fill="#2F2E41"
          />
          <path
            d="M104.86 92.1547L93.5054 92.9464L86.1663 100.034L83.5959 100.493C81.2855 100.905 79.2066 102.149 77.7533 103.991C76.3001 105.833 75.5736 108.143 75.7117 110.485C76.2404 119.437 77.4242 132.814 79.8983 137.154C83.7348 143.883 86.9037 141.772 83.7348 143.883C80.566 145.994 78.4385 144.335 79.8983 147.672C81.3582 151.009 77.9253 155.289 77.9253 155.289L76.5587 156.655L78.0081 157.248L124.192 163.255C124.192 163.255 120.199 145.31 122.696 140.902C125.194 136.493 131.249 123.168 125.845 117.954L120.561 99.3699L111.472 96.6907L104.86 92.1547Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M173.598 165.806C172.838 165.782 172.092 165.593 171.412 165.252C170.733 164.912 170.135 164.428 169.661 163.834C169.187 163.24 168.847 162.55 168.666 161.812C168.485 161.074 168.466 160.306 168.611 159.56L146.947 144.897L149.532 137.911L175.135 155.515C176.374 155.852 177.445 156.632 178.146 157.706C178.847 158.78 179.13 160.074 178.94 161.343C178.751 162.611 178.102 163.766 177.117 164.589C176.133 165.411 174.88 165.844 173.598 165.806V165.806Z"
            fill="#FFB6B6"
          />
          <path
            d="M112.407 98.4922L109.259 99.738L126.052 132.517L160.817 156.2L165.828 147.736L139.365 124.891L128.77 104.584C127.279 101.726 124.782 99.5216 121.76 98.3966C118.738 97.2716 115.406 97.3056 112.407 98.4922Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M98.592 89.2718C104.889 89.2718 109.994 84.1699 109.994 77.8763C109.994 71.5826 104.889 66.4807 98.592 66.4807C92.2948 66.4807 87.1899 71.5826 87.1899 77.8763C87.1899 84.1699 92.2948 89.2718 98.592 89.2718Z"
            fill="#FFB6B6"
          />
          <path
            d="M108.507 73.0574C108.317 73.1577 108.13 73.25 107.94 73.3424C107.741 73.4374 107.543 73.5324 107.343 73.6222C103.527 75.3508 100.419 75.5435 98.5333 73.0574C98.4818 71.7494 98.1871 70.4625 97.6646 69.2622C97.7569 70.6035 97.4032 71.9377 96.6585 73.0574H92.8611C88.9502 75.6517 86.9433 78.0349 89.6579 86.5516C89.8349 87.1111 91.0469 106.99 91.3929 108.518C85.4724 106.248 80.3653 82.1494 80.2359 75.7995C80.228 75.6966 80.228 75.5884 80.228 75.4828C80.2264 74.9741 80.2529 74.4657 80.3072 73.96C80.3769 73.2695 80.4969 72.585 80.6663 71.912L80.6505 71.9093C80.0481 71.3337 79.5886 70.6255 79.3084 69.8411C79.0283 69.0567 78.9354 68.2177 79.037 67.391C80.0302 66.0913 81.2593 64.9901 82.6601 64.1448C83.9338 63.5283 85.2615 63.0301 86.6264 62.6563C86.6607 62.6484 86.6898 62.6404 86.7241 62.6325C87.1413 62.519 87.5691 62.4161 87.9969 62.3264C88.8785 62.1416 89.7699 62.0068 90.6667 61.9226C90.7327 61.9199 90.7961 61.9146 90.8542 61.9146C91.2129 61.9149 91.565 62.0115 91.8735 62.1944C91.8761 62.1944 91.8761 62.197 91.8814 62.197C92.1779 62.3741 92.4237 62.6244 92.5953 62.9239C92.7669 63.2235 92.8584 63.5621 92.8611 63.9072H97.0915C97.2473 63.9072 97.4031 63.9125 97.5589 63.9204C103.45 64.1553 108.185 66.6731 108.489 72.4609C108.502 72.6615 108.507 72.8568 108.507 73.0574Z"
            fill="#2F2E41"
          />
          <path
            d="M83.4964 73.5155C87.645 73.5155 91.0081 70.1543 91.0081 66.008C91.0081 61.8618 87.645 58.5005 83.4964 58.5005C79.3477 58.5005 75.9846 61.8618 75.9846 66.008C75.9846 70.1543 79.3477 73.5155 83.4964 73.5155Z"
            fill="#2F2E41"
          />
          <path
            d="M93.8091 62.5356C92.9557 66.5932 88.7453 69.02 84.7173 68.0273C82.783 67.5506 81.1174 66.3254 80.087 64.6213C79.0565 62.9173 78.7455 60.8739 79.2225 58.9407C80.2158 54.915 85.1042 56.0756 88.3143 53.449C92.8553 49.7334 94.96 57.0636 93.8091 62.5356Z"
            fill="#2F2E41"
          />
          <path
            d="M77.1191 175.483C83.5212 149.39 96.2064 126.963 117.215 109.392C118.028 108.055 119.338 107.095 120.858 106.723C122.379 106.35 123.985 106.596 125.324 107.405L175.266 137.669C176.604 138.482 177.564 139.791 177.937 141.311C178.309 142.83 178.064 144.435 177.254 145.774C169.235 172.548 157.225 195.781 137.158 211.866C136.345 213.202 135.035 214.162 133.514 214.535C131.994 214.907 130.388 214.661 129.049 213.852L79.1066 183.588C77.7691 182.775 76.8086 181.466 76.436 179.946C76.0634 178.427 76.3091 176.822 77.1191 175.483Z"
            fill="#F2F2F2"
          />
          <path
            opacity="0.2"
            d="M85.9959 184.277C84.6584 183.464 83.698 182.155 83.3254 180.635C82.9528 179.116 83.1985 177.511 84.0085 176.172C90.4106 150.079 103.096 127.652 124.104 110.08C124.642 109.205 125.402 108.488 126.307 108.001L125.324 107.405C123.985 106.596 122.379 106.35 120.859 106.722C119.338 107.095 118.028 108.055 117.215 109.391C96.2065 126.963 83.5213 149.39 77.1192 175.483C76.3092 176.822 76.0636 178.427 76.4361 179.946C76.8087 181.466 77.7691 182.775 79.1067 183.588L129.049 213.852C129.938 214.386 130.952 214.676 131.989 214.693C133.027 214.709 134.05 214.451 134.955 213.945L85.9959 184.277Z"
            fill="black"
          />
          <path
            d="M144.565 169.699L111.042 149.511C110.509 149.189 110.126 148.67 109.977 148.066C109.827 147.462 109.924 146.824 110.245 146.291C110.566 145.758 111.086 145.375 111.689 145.225C112.293 145.075 112.932 145.17 113.466 145.49L146.989 165.678C147.52 166 147.902 166.52 148.051 167.123C148.2 167.726 148.103 168.364 147.782 168.896C147.461 169.428 146.943 169.811 146.34 169.962C145.737 170.112 145.099 170.018 144.565 169.699L144.565 169.699Z"
            fill="white"
          />
          <path
            d="M125.063 142.928L117.688 138.487C117.154 138.167 116.768 137.648 116.616 137.045C116.465 136.441 116.559 135.802 116.879 135.268C117.198 134.733 117.717 134.348 118.321 134.196C118.925 134.045 119.565 134.139 120.099 134.458L120.112 134.466L127.487 138.907C128.019 139.23 128.401 139.749 128.549 140.352C128.698 140.956 128.602 141.593 128.281 142.125C127.96 142.657 127.441 143.041 126.838 143.191C126.235 143.342 125.597 143.247 125.063 142.928Z"
            fill="white"
          />
          <path
            d="M139.237 178.536L105.714 158.349C105.181 158.028 104.797 157.508 104.646 156.905C104.496 156.301 104.592 155.662 104.912 155.129C105.233 154.596 105.752 154.212 106.356 154.061C106.96 153.91 107.599 154.005 108.133 154.325L108.138 154.328L141.661 174.516C142.192 174.838 142.574 175.358 142.723 175.961C142.871 176.564 142.775 177.201 142.454 177.733C142.133 178.265 141.615 178.649 141.012 178.799C140.409 178.95 139.771 178.855 139.237 178.536L139.237 178.536Z"
            fill="white"
          />
          <path
            d="M148.823 131.634C154.23 131.634 158.614 127.253 158.614 121.848C158.614 116.444 154.23 112.062 148.823 112.062C143.415 112.062 139.031 116.444 139.031 121.848C139.031 127.253 143.415 131.634 148.823 131.634Z"
            fill="#3F3D56"
          />
          <path
            d="M131.945 226.708C143.503 226.708 152.872 217.344 152.872 205.792C152.872 194.241 143.503 184.876 131.945 184.876C120.386 184.876 111.017 194.241 111.017 205.792C111.017 217.344 120.386 226.708 131.945 226.708Z"
            fill="white"
          />
          <path
            d="M131.945 227C127.748 227 123.645 225.756 120.155 223.426C116.666 221.096 113.946 217.783 112.34 213.908C110.734 210.033 110.314 205.769 111.132 201.655C111.951 197.541 113.972 193.762 116.94 190.796C119.907 187.83 123.688 185.81 127.805 184.992C131.921 184.174 136.187 184.594 140.065 186.199C143.942 187.804 147.256 190.522 149.588 194.01C151.92 197.497 153.164 201.598 153.164 205.792C153.158 211.415 150.92 216.805 146.942 220.781C142.964 224.757 137.57 226.994 131.945 227ZM131.945 185.168C127.863 185.168 123.873 186.378 120.48 188.644C117.086 190.91 114.441 194.131 112.879 197.9C111.317 201.668 110.909 205.815 111.705 209.816C112.501 213.817 114.467 217.491 117.353 220.376C120.239 223.26 123.916 225.224 127.919 226.02C131.922 226.816 136.071 226.407 139.842 224.846C143.612 223.285 146.835 220.642 149.103 217.25C151.37 213.859 152.581 209.871 152.581 205.792C152.574 200.324 150.398 195.082 146.53 191.215C142.661 187.349 137.416 185.174 131.945 185.168Z"
            fill="#3F3D56"
          />
          <path
            d="M129.09 216.678C128.728 216.678 128.372 216.594 128.048 216.432C127.725 216.271 127.444 216.036 127.227 215.747L121.518 208.139C121.334 207.894 121.2 207.616 121.124 207.32C121.048 207.024 121.031 206.716 121.074 206.413C121.118 206.11 121.22 205.819 121.376 205.556C121.532 205.293 121.738 205.063 121.982 204.88C122.227 204.696 122.506 204.563 122.802 204.487C123.098 204.411 123.407 204.395 123.709 204.438C124.012 204.482 124.303 204.584 124.566 204.74C124.83 204.896 125.059 205.102 125.243 205.347L128.978 210.324L138.572 195.942C138.741 195.688 138.959 195.469 139.213 195.299C139.467 195.129 139.752 195.011 140.052 194.951C140.352 194.891 140.66 194.891 140.96 194.95C141.26 195.009 141.546 195.127 141.8 195.297C142.054 195.466 142.273 195.684 142.443 195.938C142.613 196.192 142.731 196.477 142.791 196.776C142.851 197.076 142.852 197.384 142.792 197.684C142.733 197.984 142.615 198.269 142.446 198.523L131.027 215.642C130.82 215.951 130.542 216.207 130.217 216.387C129.891 216.567 129.527 216.667 129.154 216.677C129.133 216.678 129.111 216.678 129.09 216.678Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M199.532 99.7991C200.363 99.7281 200.997 99.2017 200.948 98.6235C200.898 98.0453 200.184 97.6342 199.352 97.7053C198.521 97.7764 197.887 98.3027 197.936 98.8809C197.986 99.4591 198.7 99.8702 199.532 99.7991Z"
            fill="white"
          />
          <path
            d="M208.888 96.6268C209.72 96.5557 210.354 96.0294 210.305 95.4512C210.255 94.873 209.541 94.4619 208.709 94.533C207.878 94.604 207.244 95.1304 207.293 95.7085C207.343 96.2867 208.057 96.6978 208.888 96.6268Z"
            fill="#3F3D56"
          />
          <path
            d="M195.096 92.6645C195.928 92.5934 196.562 92.0671 196.513 91.4889C196.463 90.9107 195.749 90.4996 194.917 90.5707C194.086 90.6417 193.451 91.168 193.501 91.7462C193.55 92.3244 194.265 92.7355 195.096 92.6645Z"
            fill="#FF6584"
          />
          <path
            d="M191.171 90.3655C191.529 90.2846 191.725 89.8022 191.609 89.288C191.493 88.7738 191.108 88.4224 190.75 88.5032C190.392 88.5841 190.197 89.0665 190.313 89.5807C190.429 90.0949 190.813 90.4463 191.171 90.3655Z"
            fill="#E6E6E6"
          />
          <path
            d="M188.377 84.7842C188.735 84.7034 188.931 84.221 188.815 83.7068C188.698 83.1925 188.314 82.8412 187.956 82.922C187.598 83.0029 187.402 83.4852 187.518 83.9995C187.635 84.5137 188.019 84.8651 188.377 84.7842Z"
            fill="#3F3D56"
          />
          <path
            d="M201.784 92.6222C202.142 92.5414 202.338 92.059 202.222 91.5447C202.105 91.0305 201.721 90.6792 201.363 90.76C201.005 90.8408 200.809 91.3232 200.925 91.8374C201.042 92.3517 201.426 92.703 201.784 92.6222Z"
            fill="#E6E6E6"
          />
          <path
            d="M197.697 96.013C198.055 95.9322 198.251 95.4498 198.135 94.9355C198.019 94.4213 197.634 94.07 197.276 94.1508C196.918 94.2316 196.722 94.714 196.839 95.2283C196.955 95.7425 197.339 96.0938 197.697 96.013Z"
            fill="#FF6584"
          />
          <path
            d="M205.137 98.7748C205.495 98.694 205.691 98.2116 205.575 97.6974C205.459 97.1831 205.074 96.8318 204.716 96.9126C204.359 96.9935 204.163 97.4758 204.279 97.9901C204.395 98.5043 204.78 98.8557 205.137 98.7748Z"
            fill="#3F3D56"
          />
          <path
            d="M160.9 56.5656C161.043 56.0031 160.502 55.3809 159.693 55.176C158.884 54.9711 158.113 55.261 157.97 55.8235C157.828 56.386 158.368 57.0081 159.177 57.2131C159.986 57.418 160.757 57.1281 160.9 56.5656Z"
            fill="white"
          />
          <path
            d="M151.02 56.5019C151.162 55.9394 150.622 55.3173 149.813 55.1123C149.004 54.9074 148.232 55.1973 148.09 55.7598C147.947 56.3223 148.487 56.9445 149.296 57.1494C150.105 57.3543 150.877 57.0644 151.02 56.5019Z"
            fill="#3F3D56"
          />
          <path
            d="M162.754 64.7582C162.897 64.1957 162.357 63.5736 161.547 63.3686C160.738 63.1637 159.967 63.4536 159.824 64.0161C159.682 64.5787 160.222 65.2008 161.031 65.4057C161.84 65.6106 162.611 65.3207 162.754 64.7582Z"
            fill="#FF6584"
          />
          <path
            d="M165.057 67.8475C165.116 67.3235 164.868 66.8658 164.503 66.8251C164.138 66.7843 163.795 67.176 163.737 67.7C163.678 68.2239 163.926 68.6817 164.291 68.7224C164.656 68.7631 164.999 68.3714 165.057 67.8475Z"
            fill="#E6E6E6"
          />
          <path
            d="M165.87 74.0354C165.928 73.5115 165.68 73.0537 165.316 73.013C164.951 72.9723 164.608 73.364 164.549 73.8879C164.491 74.4119 164.739 74.8696 165.103 74.9104C165.468 74.9511 165.811 74.5594 165.87 74.0354Z"
            fill="#3F3D56"
          />
          <path
            d="M155.769 62.243C155.827 61.719 155.579 61.2613 155.214 61.2206C154.85 61.1798 154.506 61.5716 154.448 62.0955C154.389 62.6194 154.637 63.0772 155.002 63.1179C155.367 63.1586 155.71 62.7669 155.769 62.243Z"
            fill="#E6E6E6"
          />
          <path
            d="M160.741 60.3761C160.799 59.8521 160.551 59.3944 160.187 59.3537C159.822 59.3129 159.479 59.7046 159.42 60.2286C159.362 60.7525 159.61 61.2103 159.974 61.251C160.339 61.2917 160.682 60.9 160.741 60.3761Z"
            fill="#FF6584"
          />
          <path
            d="M154.615 55.3323C154.674 54.8083 154.426 54.3506 154.061 54.3098C153.696 54.2691 153.353 54.6608 153.295 55.1848C153.236 55.7087 153.484 56.1665 153.849 56.2072C154.214 56.2479 154.557 55.8562 154.615 55.3323Z"
            fill="#3F3D56"
          />
          <path
            d="M80.2332 175.76C79.487 175.454 78.8161 174.99 78.2669 174.4C77.7177 173.81 77.3034 173.107 77.0526 172.341C76.8017 171.575 76.7203 170.764 76.8141 169.963C76.9078 169.163 77.1744 168.392 77.5954 167.705L61.5078 145.104L66.6688 139.119L85.58 166.103C86.685 166.897 87.4601 168.069 87.7583 169.396C88.0566 170.724 87.8573 172.114 87.1983 173.305C86.5392 174.495 85.4662 175.402 84.1825 175.855C82.8988 176.307 81.4936 176.273 80.2332 175.76H80.2332Z"
            fill="#FFB6B6"
          />
          <path
            d="M80.6028 104.137C79.7889 103.658 78.831 103.485 77.901 103.649C76.971 103.812 76.1298 104.302 75.5283 105.029L53.7919 135.412L73.9359 164.006L81.8184 155.544L69.5568 136.287L85.8525 120.781C87.1917 117.849 87.3962 114.525 86.4267 111.451C85.4571 108.377 83.3823 105.771 80.6028 104.137Z"
            fill={colors.mainSVGColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_6_807">
            <rect width="168" height="195" fill="white" transform="translate(53.5 32)" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
