import { Grid, Typography } from "@mui/material";
import { unsubscribeJobs } from "adapters/AuthAdapter";
import { WLoadingButton } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { LoginBackgroundBottomSVG, LoginBackgroundTopSVG } from "./LoginView";

export const UnsubscribeJobsView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  
  const history = useHistory();
  const params = useParams<{verificationUuid: string}>();

  const redirectToLogin = (): void => {
    history.push("/login");
    dispatchLoading({ type: "STOP_LOADING", payload: "UNSUBSCRIBE_JOBS" });
  };

  const onCancelJobSubscription  = async (): Promise<void> => {
    dispatchLoading({ type: "SET_LOADING", payload: "UNSUBSCRIBE_JOBS" });
    try {
      const succes = await unsubscribeJobs(params["verificationUuid"]);
      if (succes) {
        addMessage({ content: "success.jobSubscription", type: "success" });
        setTimeout(redirectToLogin, 1000);
      }
    } catch (error) {
      addMessage({ content: "error.jobSubscription", type: "error" });
      dispatchLoading({ type: "STOP_LOADING", payload: "UNSUBSCRIBE_JOBS" });
    }
  };

  return (
    <Grid
      container
      className="contentWrapper"
      alignItems="center"
      position="relative"
      sx={{
        flex: "1 1 auto",
      }}
    >
      {isDesktop && (
        <>
          <LoginBackgroundTopSVG />
          <LoginBackgroundBottomSVG />
        </>
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        gap="2rem"
        zIndex="2"
        maxWidth="400px"
        margin="auto"
      >
        <Typography width="100%" variant="h5" color="primary" fontWeight="bold">
          {localize("unsubscribe.title")}
        </Typography>
        <Typography width="100%" variant="body1" color="primary" fontWeight="bold">
          {localize("unsubscribe.description")}
        </Typography>
        <WLoadingButton
          displayLoading={isLoading("UNSUBSCRIBE_JOBS")}
          variant="outlined"
          sx={{ maxWidth: "max-content", border: "2px solid" }}
          onClick={onCancelJobSubscription}
        >
          {localize("common.confirm")}
        </WLoadingButton>
      </Grid>
    </Grid>
  );
};
