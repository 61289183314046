import React, { FC, useContext } from "react";
import { NavbarIcon } from "./NavbarIcon";
import { LocaleContext } from "context/LocaleContext";
import { WIcon, WIconTypes } from "components";

export const AnonymousIconList: FC = () => {
  const { localize } = useContext(LocaleContext);

  return (
    <>
      <NavbarIcon
        route="/"
        label={localize("routes.startPage")}
        icon={<WIcon icon={WIconTypes.home} size="medium" color="secondaryDark" />}
      />
      <NavbarIcon
        route="/jobs"
        label={localize("routes.jobs")}
        icon={<WIcon icon={WIconTypes.search} size="medium" color="secondaryDark" />}
      />
      <NavbarIcon
        route="/contactUs"
        label={localize("routes.contact")}
        icon={<WIcon icon={WIconTypes.helpCircle} size="medium" color="secondaryDark" />}
      />
    </>
  );
};
