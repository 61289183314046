import { Box } from "@mui/material";
import React, { FC, useContext, useState } from "react";
import { ModalContext } from "context/ModalContext";
import { DetailedJobHeaderMobile } from "components";
import { Job } from "adapters";
import { JobCardTopButtons } from "components/JobCard/JobCardTopButtons";
import { ActiveJobApplication } from "adapters/JobAdapter";
import { DetailedJobBody } from "./modals/DetailedJobModal/DetailedJobBody";
import { BreakpointsContext } from "context/BreakpointContext";

export interface ActiveApplicationContentProps {
  job: Job;
  handleTypeChange?: (save: boolean) => void;
  onRemoveApplication: (id: string) => void;
  isCancelled?: boolean;
  applicationId: string;
  setJobApplicationData?: (data: ActiveJobApplication) => void;
}

export const ActiveApplicationContent: FC<ActiveApplicationContentProps> = ({
  job,
  applicationId,
  handleTypeChange,
  onRemoveApplication,
  setJobApplicationData,
  isCancelled = false,
}) => {
  const { pushModal, showModal } = useContext(ModalContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const [isSaved, setIsSaved] = useState(job.is_saved);

  const handleOpenApplicationForm = () => {
    if (isDesktop) {
      showModal("DETAILED_JOB_APPLICATION_MODAL", {
        job: job,
        applicationId: job.id,
        onRemoveApplication: onRemoveApplication,
      });
      return;
    }

    pushModal("APPLICATION_MODAL", {
      applicationId: applicationId,
      handleTypeChange: handleTypeChange,
      job: job,
      setJobApplicationData,
    });
  };

  return (
    <Box paddingBottom="5rem">
      <DetailedJobHeaderMobile
        job={job}
        showLogo={false}
        buttonContainer={
          !isCancelled && (
            <JobCardTopButtons
              job={job}
              applicationOpts={{
                applicationID: applicationId,
                handleOpenApplicationForm: handleOpenApplicationForm,
                onRemoveApplication: onRemoveApplication,
              }}
              iconVariant="expanded"
              setSaveJob={setIsSaved}
              isSaved={isSaved}
            />
          )
        }
        isExpanded={true}
      />

      <DetailedJobBody
        job={job}
        isCancelled={isCancelled}
        handleOpenApplicationForm={handleOpenApplicationForm}
        setSaveJob={setIsSaved}
        isSaved={isSaved}
        isApplication={true}
      />
    </Box>
  );
};
