import { Grid, Typography, Skeleton } from "@mui/material";
import React, { FC, useContext, useEffect } from "react";
import { ExtendedConversation, getConversationList } from "adapters";
import { LocaleContext } from "context/LocaleContext";
import { LoaderContext } from "context/LoaderContext";
import { usePagination } from "hooks/usePagination";
import { ConversationCard } from "./ConversationCard";
import { BreakpointsContext } from "context/BreakpointContext";
import { defaultStyles } from "styles/colors";

interface ConversationListProps {
  fullscreen?: boolean;
  forceUpdate?: boolean;
  selectedID?: string;
  setSelectedConv?: (id: string) => void;
}

const ConversationList: FC<ConversationListProps> = ({
  setSelectedConv,
  fullscreen = true,
  selectedID = "",
  forceUpdate = false,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading } = useContext(LoaderContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const { results, setLastElementRef, updatePage } = usePagination<ExtendedConversation>(
    () => getConversationList({ expand: "users" }),
    "error.generalError",
    "GET_CONVERSATION_LIST",
    { page_size: 8 }
  );

  useEffect(() => {
    updatePage();
  }, [forceUpdate]);

  return (
    <Grid
      container
      direction="column"
      overflow="hidden"
      height="fit-content"
      padding="6px"
      flex="100px 1 0"
      gap={isDesktop ? "1rem" : "1px"}
    >
      {!isLoading("GET_CONVERSATION_LIST") && !results.length && (
        <Grid item padding="1rem">
          <Typography fontStyle="italic">
            {localize("messagesView.emptyConversationList")}
          </Typography>
        </Grid>
      )}
      {isLoading("GET_CONVERSATION_LIST")
        ? Array(10)
            .fill(1)
            .map((_, index) => (
              <Skeleton
                key={`skeleton_message_${index}`}
                width="100%"
                height="100px"
                variant="rectangular"
                sx={{ borderRadius: defaultStyles.borderRadius }}
              />
            ))
        : results.map((conv, index) => {
            if (index === results.length - 1) {
              return (
                <div key={index} ref={setLastElementRef} style={{ width: "100%" }}>
                  <ConversationCard
                    id={conv.id}
                    conv={conv}
                    isSelected={conv.id === selectedID}
                    setSelectedConv={setSelectedConv}
                    fullscreen={fullscreen}
                    avatarSize="xsmall"
                  />
                </div>
              );
            }
            return (
              <ConversationCard
                key={index}
                id={conv.id}
                isSelected={conv.id === selectedID}
                conv={conv}
                setSelectedConv={setSelectedConv}
                fullscreen={fullscreen}
                avatarSize="xsmall"
              />
            );
          })}
    </Grid>
  );
};
export default ConversationList;
