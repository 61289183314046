import { Typography, TypographyProps } from "@mui/material";
import React, { FC } from "react";

interface WTypographyProps {
  lineClamps?: number;
}
export const WTypography: FC<WTypographyProps & TypographyProps> = (props) => {
  const { lineClamps, children, ...typographyProps } = props;
  return (
    <Typography
      {...typographyProps}
      sx={{
        ...props.sx,
        overflowWrap: "break-word",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        WebkitLineClamp: lineClamps || 2,
      }}
    >
      {children}
    </Typography>
  );
};
