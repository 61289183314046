import { Grid } from "@mui/material";
import { updateProfile } from "adapters";
import { WButton, WDatePicker, WIcon, WIconTypes } from "components";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import { format, parseISO } from "date-fns";
import React, { FC, useContext, useState, useEffect } from "react";
import { colors, defaultStyles } from "styles/colors";
import { WDateFormats } from "utils/PrettifyDate";
import { PROFILE_STEPS } from "views/Enums";

export const AvailabilityDateSection: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { user, dispatch, calculateProfileProgress } = useContext(UserContext);

  const [availabilityDate, setAvailabilityDate] = useState<Date | null>(null);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (user.availability_date) {
      setAvailabilityDate(parseISO(user.availability_date));
    }
  }, []);

  const handleSave = () => {
    dispatchLoading({ type: "SET_LOADING", payload: "UPDATE_USER" });
    let formatedDate: string | undefined = undefined;
    if (availabilityDate) {
      formatedDate = format(availabilityDate, WDateFormats.date);
    }
    if (user.availability_date !== formatedDate) {
      updateProfile({ availability_date: formatedDate }, user.id)
        .then(() => {
          if (!formatedDate) {
            addMessage({ content: "success.availabilityDateRemoved", type: "success" });
          } else {
            addMessage({ content: "success.availabilityDate", type: "success" });
          }
          dispatch({
            type: "UPDATE_USER",
            user: {
              ...user,
              availability_date: formatedDate,
            },
          });
          calculateProfileProgress(!!formatedDate, PROFILE_STEPS.availability);
        })
        .catch(() => {
          addMessage({ content: "error.availabilityDate", type: "error" });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "UPDATE_USER" });
        });
    }
  };

  const handleClear = () => {
    if (user.availability_date) {
      setAvailabilityDate(parseISO(user.availability_date));
      return;
    }
    setAvailabilityDate(null);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="space-between"
      padding="1rem"
      gap="1rem"
      sx={{
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadiusBottom,
        border: `1px solid ${colors.grey}`,
      }}
    >
      <Grid maxWidth="460px" container wrap="nowrap" gap="1rem">
        <WDatePicker
          onError={(reason) => setError(!!reason)}
          onChange={(date) => setAvailabilityDate(date)}
          value={availabilityDate}
          label={localize("form.availabilityDate")}
        />
        <WButton
          fullWidth
          variant="outlined"
          sx={{ maxWidth: "max-content" }}
          startIcon={<WIcon icon={WIconTypes.trash} />}
          onClick={handleClear}
        >
          {localize("common.reset")}
        </WButton>
      </Grid>
      <Grid item xs={12} md={2} container justifyContent="flex-end" width="100%">
        <WButton
          fullWidth
          variant="outlined"
          startIcon={<WIcon icon={WIconTypes.check} />}
          onClick={handleSave}
          disabled={hasError || !availabilityDate}
        >
          {localize("form.save")}
        </WButton>
      </Grid>
    </Grid>
  );
};
