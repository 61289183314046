import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";

export type SubscriptionActions = "GET_SUBSCRIPTIONS";

export interface Subscription {
  id: string;
  department_roles: string[];
  regions: string[];
  time_interval: "weekly" | "daily";
}

export const getSubscriptions = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<Subscription>>> => {
  return API.get(`/subscriptions/`, { params });
};

export const createSubscription = async (
  data: Partial<Subscription>
): Promise<AxiosResponse<Subscription>> => {
  return API.post(`/subscriptions/`, data);
};

export const updateSubscription = async (
  id: string,
  data: Partial<Subscription>
): Promise<AxiosResponse<Subscription>> => {
  return API.patch(`/subscriptions/${id}/`, data);
};

export const deleteSubscription = async (id: string): Promise<AxiosResponse<void>> => {
  return API.delete(`/subscriptions/${id}/`);
};
