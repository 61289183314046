import { Skill, RoleWithDepartment, Referral, CV, Region, UserDocument } from "adapters";
import { ClientConfiguration, UserInitialState, ProfileCompleteness } from "context/UserContext";
import { languages } from "context/LocaleContext";

interface ProfileCompletenessStep {
  name: string;
  isDone: boolean;
}

export type User = {
  id: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  availability_date?: string;
  created: string;
  profile_picture: string;
  rating?: number;
  profile_completed: string;
  profile_completeness: number;
  completeness_steps: ProfileCompletenessStep[];
  tax_document: string;
  language?: languages;
  linkedin_url: string;
  skills: Skill[];
  regions: Region[];
  departments: RoleWithDepartment[];
  referrals: Referral[];
  cv: CV[];
  user_documents: UserDocument[];
};

export type UserActions =
  | "POST_LOGIN"
  | "GET_USER"
  | "GET_USER_LIST"
  | "SET_USER_LIST"
  | "GET_USER_APPLICATION_LIST"
  | "CREATE_USER"
  | "UPDATE_USER"
  | "GET_AGENT_NOTES"
  | "REMOVE_AGENT_NOTE"
  | "CREATE_USER_NOTE"
  | "GET_SKILLS"
  | "UPDATE_PROFILE_PICTURE"
  | "GET_PROFILE_COMPLETENESS"
  | "GET_GDPR_USERS"
  | "GET_GDPR"
  | "DELETE_USER"
  | "GET_USER_APPLICATION"
  | "GET_USER_APPLICATIONS";

export type UserActionsType =
  | {
      type: "GET_USER";
      user: User;
    }
  | {
      type: "UPDATE_USER";
      user: Partial<User>;
    }
  | {
      type: "LOGOUT";
    }
  | {
      type: "GET_AGENT_NOTES";
    }
  | {
      type: "REMOVE_AGENT_NOTE";
    }
  | {
      type: "CREATE_USER_NOTE";
    }
  | {
      type: "GET_SKILLS";
    }
  | {
      type: "CREATE_USER_NOTE";
    }
  | {
      type: "REFRESH_AUTH";
    }
  | {
      type: "UPDATE_PROFILE_PICTURE";
      user: User;
    }
  | {
      type: "GET_CLIENT_CONFIG";
      clientConfig: ClientConfiguration;
    }
  | {
      type: "SET_PROFILE_COMPLETENESS";
      profileCompleteness: ProfileCompleteness;
    }
  | {
      type: "SET_USER_LIST";
      users: User[];
    };

export function UserReducer(
  state = UserInitialState,
  action: UserActionsType
): typeof UserInitialState {
  switch (action.type) {
    case "GET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: { ...state.user, ...action.user },
      };
    case "SET_PROFILE_COMPLETENESS":
      return {
        ...state,
        profileCompleteness: action.profileCompleteness,
      };
    case "LOGOUT":
      return UserInitialState;
    case "GET_CLIENT_CONFIG":
      return {
        ...state,
        clientConfiguration: action.clientConfig,
      };
    case "SET_USER_LIST": {
      return { ...state, users: action.users };
    }
    default:
      return state;
  }
}
