import React, { createContext } from "react";
import { NotificationMessage, NotificationReducer } from "reducers/NotificationReducer";

export const NotificationInitialState = {
  messages: null,
};

export type NotificationContextState = {
  messages: NotificationMessage | null;
};

export const NotificationContext = createContext<{
  addMessage: (message: NotificationMessage) => void;
  removeMessage: () => void;
  messages: NotificationMessage | null;
}>({
  addMessage: () => false,
  removeMessage: () => false,
  messages: {} as NotificationMessage,
});

const NotificationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(NotificationReducer, NotificationInitialState);

  const addMessage = (message: NotificationMessage) => {
    if (state.messages) {
      dispatch({ type: "REMOVE_NOTIFICATION" });

      // Delay for switching between notifications
      setTimeout(() => {
        dispatch({ type: "ADD_NOTIFICATION", message: message });
      }, 100);
    } else {
      dispatch({ type: "ADD_NOTIFICATION", message: message });
    }
  };

  const removeMessage = () => {
    dispatch({ type: "REMOVE_NOTIFICATION" });
  };

  return (
    <NotificationContext.Provider value={{ addMessage, removeMessage, messages: state.messages }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
