import { Grid, Typography } from "@mui/material";
import React, { FC, useContext } from "react";
import { AvatarItem } from "./AvatarItem";
import { UserContext } from "context/UserContext";
import { BreakpointsContext } from "context/BreakpointContext";
import { colors } from "styles/colors";

interface UserInfoComponentProps {
  handleAvatarPicker: () => void;
  opts?: {
    reverse?: boolean;
    color?: "primary" | "secondary";
  };
  edit?: boolean;
}

export const UserInfoComponent: FC<UserInfoComponentProps> = ({
  handleAvatarPicker,
  opts,
  edit = false,
}) => {
  const { user } = useContext(UserContext);

  const currentOpts = {
    reverse: false,
    color: colors.white,
    ...opts,
  };

  const { isDesktop } = useContext(BreakpointsContext);

  return (
    <Grid
      container
      alignItems="center"
      gap="0.5rem"
      wrap="nowrap"
      direction={currentOpts.reverse ? "row-reverse" : "row"}
      mt="20px"
    >
      <Grid onClick={handleAvatarPicker} sx={{ cursor: "pointer" }}>
        <AvatarItem
          imageSrc={user.profile_picture}
          firstName={user.first_name}
          lastName={user.last_name}
          edit={edit}
          size={isDesktop ? "xsmall" : "tiny"}
        />
      </Grid>
      <Grid
        container
        marginRight="1rem"
        sx={{ flexDirection: "column", flexBasis: "100px", minWidth: "100px", flexGrow: "2" }}
      >
        <Grid container gap="0.25rem" justifyContent={currentOpts.reverse ? "flex-end" : undefined}>
          <Typography
            fontWeight="bold"
            color={currentOpts.color}
            variant="h6"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {user.first_name}
          </Typography>
          <Typography
            fontWeight="bold"
            color={currentOpts.color}
            variant="h6"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {user.last_name}
          </Typography>
        </Grid>
        <Typography
          variant="body2"
          color={currentOpts.color}
          align={currentOpts.reverse ? "left" : "inherit"}
        >
          {user.departments.length ? user.departments[0].name : ""}
        </Typography>
      </Grid>
    </Grid>
  );
};
