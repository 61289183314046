import { Dialog, Grid } from "@mui/material";
import { DialogHeader, WButton, FiltersBody, WIcon, WIconTypes } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useEffect, useState } from "react";

export type JobFilterItem = { label: string; value: string };
export interface JobFilterItemWithParent extends JobFilterItem {
  parent: JobFilterItem;
}

export type JobFilters = {
  roles: JobFilterItem[];
  regions: JobFilterItem[];
  departments: JobFilterItem[];
  locations: JobFilterItem[];
  skills: JobFilterItem[];
  tags: string;
};

interface FiltersDialogProps {
  open: boolean;
  handleClose: () => void;
  filterCallback: (filters: JobFilters) => void;
  currentFilters: Partial<JobFilters>;
}

export const defaultFilterState: JobFilters = {
  roles: [],
  regions: [],
  departments: [],
  locations: [],
  skills: [],
  tags: "KONSULT,GIG",
};

export const FiltersDialog: FC<FiltersDialogProps> = ({
  open,
  handleClose,
  filterCallback,
  currentFilters,
}) => {
  const { localize } = useContext(LocaleContext);

  const [filters, setFilters] = useState<JobFilters>(defaultFilterState);

  // Prefill filters on open to re-render values
  useEffect(() => {
    if (open) {
      setFilters({
        roles: currentFilters.roles || [],
        regions: currentFilters.regions || [],
        departments: currentFilters.departments || [],
        locations: currentFilters.locations || [],
        skills: currentFilters.skills || [],
        tags: currentFilters.tags || "KONSULT,GIG",
      });
    }
  }, [open]);

  const submitAndClose = () => {
    filterCallback(filters);
    handleClose();
  };

  return (
    <Dialog open={open} fullScreen>
      <DialogHeader
        returnText={localize("jobListView.headerMessage")}
        rightSideContent={
          <WButton
            variant="contained"
            sx={{ width: "max-content" }}
            onClick={() => submitAndClose()}
          >
            {localize("common.showJobs")}
          </WButton>
        }
        handleClose={() => handleClose()}
      />
      <FiltersBody filters={filters} setFilters={setFilters} />
      <Grid
        container
        bgcolor="background.default"
        padding="1rem"
        position="sticky"
        bottom={0}
        boxShadow={4}
        marginTop="auto"
      >
        <WButton
          fullWidth
          variant="outlined"
          startIcon={<WIcon icon={WIconTypes.trash} size="small" />}
          onClick={() => {
            setFilters(defaultFilterState);
          }}
        >
          {localize("searchbar.clearFilters")}
        </WButton>
      </Grid>
    </Dialog>
  );
};
