import { API, PaginatedResponse } from "adapters/api";
import { AxiosResponse } from "axios";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { useEffect, useState, useContext, useMemo, useCallback } from "react";
import { LoaderActions } from "reducers/LoaderReducer";

export type PaginationActions = "GET_PAGINATION_RESULTS" | "GET_NEXT_PAGE";

export const DEFAULT_PAGINATION_SIZE = 10;

type DefaultPaginationOptions = {
  page_size?: number;
  page?: number;
  search?: string;
};

type PaginationOptions = Record<string, unknown> & DefaultPaginationOptions;

type PaginationState = {
  next: string;
  previous: string;
  count: number;
  currentPage: number;
  opts?: PaginationOptions;
};

export const usePagination = <T>(
  fetchPage: (params?: Record<string, unknown>) => Promise<AxiosResponse>,
  errorMessage: string,
  loadingContext?: LoaderActions,
  opts: PaginationOptions = {
    page_size: DEFAULT_PAGINATION_SIZE,
  },
  disableAutoFetchInitial?: boolean
): {
  results: T[];
  pageResults: T[];
  count: number;
  nextPage: () => void;
  getPage: (pageNumber: number, params?: Record<string, unknown>) => void;
  setLastElementRef: (node: Element | null) => void;
  updatePage: (params?: Record<string, unknown>, pageSize?: number) => void;
  handleSearch: (params: Record<string, unknown>) => void;
  getInitial: () => void;
} => {
  const [results, setResults] = useState<Record<string, T[]>>({});
  const [pageResults, setPageResults] = useState<T[]>([]);
  const [state, setState] = useState<PaginationState>({
    next: "",
    previous: "",
    count: 0,
    currentPage: 1,
    opts: opts,
  });
  const [lastElementRef, setLastElementRef] = useState<Element | null>(null);
  const { addMessage } = useContext(NotificationContext);
  const { dispatchLoading } = useContext(LoaderContext);

  const nextPage = useCallback(() => {
    if (!state.next) return;

    dispatchLoading({ type: "SET_LOADING", payload: "GET_NEXT_PAGE" });
    API.get(state.next)
      .then((res: AxiosResponse<PaginatedResponse<T>>) => {
        setState({
          ...state,
          next: res.data.next,
          previous: res.data.previous,
          count: res.data.count,
          currentPage: state.currentPage + 1,
        });
        setResults({ ...results, [state.currentPage + 1]: res.data.results });
        setPageResults(res.data.results);
      })
      .catch(() => {
        addMessage({ type: "error", content: errorMessage });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_NEXT_PAGE" });
      });
  }, [state, results, loadingContext, errorMessage]);

  const getPage = useCallback(
    (pageNumber: number, params?: Record<string, unknown>) => {
      if (results[pageNumber]) {
        setPageResults(results[pageNumber]);
        return;
      }

      dispatchLoading({ type: "SET_LOADING", payload: loadingContext ?? "GET_PAGINATION_RESULTS" });
      fetchPage({ ...state.opts, ...params, page: pageNumber })
        .then((res: AxiosResponse<PaginatedResponse<T>>) => {
          setState({
            ...state,
            next: res.data.next,
            previous: res.data.previous,
            count: res.data.count,
            currentPage: pageNumber,
          });
          setResults({ ...results, [pageNumber]: res.data.results });
          setPageResults(res.data.results);
        })
        .catch(() => {
          addMessage({ type: "error", content: errorMessage });
        })
        .finally(() => {
          dispatchLoading({
            type: "STOP_LOADING",
            payload: loadingContext ?? "GET_PAGINATION_RESULTS",
          });
        });
    },
    [state, results, loadingContext, errorMessage]
  );

  const handleSearch = useCallback(
    (params: Record<string, unknown>) => {
      dispatchLoading({
        type: "SET_LOADING",
        payload: loadingContext ?? "GET_PAGINATION_RESULTS",
      });
      fetchPage({ ...state.opts, ...params })
        .then((res: AxiosResponse<PaginatedResponse<T>>) => {
          setState({
            ...state,
            next: res.data.next,
            previous: res.data.previous,
            count: res.data.count,
          });
          setResults({ [state.currentPage]: res.data.results });
          setPageResults(res.data.results);
        })
        .catch(() => {
          addMessage({ type: "error", content: errorMessage });
        })
        .finally(() => {
          dispatchLoading({
            type: "STOP_LOADING",
            payload: loadingContext ?? "GET_PAGINATION_RESULTS",
          });
        });
    },
    [state, loadingContext, errorMessage]
  );

  const updatePage = useCallback(
    (params?: Record<string, unknown>, pageSize?: number) => {
      dispatchLoading({
        type: "SET_LOADING",
        payload: loadingContext ?? "GET_PAGINATION_RESULTS",
      });
      fetchPage({ ...state.opts, ...params, page_size: pageSize ?? DEFAULT_PAGINATION_SIZE })
        .then((res: AxiosResponse<PaginatedResponse<T>>) => {
          setState({
            next: res.data.next,
            previous: res.data.previous,
            count: res.data.count,
            currentPage: 1,
            opts: { ...state.opts, page_size: pageSize ?? DEFAULT_PAGINATION_SIZE },
          });
          setResults({ [state.currentPage]: res.data.results });
          setPageResults(res.data.results);
        })
        .catch(() => {
          addMessage({ type: "error", content: errorMessage });
        })
        .finally(() => {
          dispatchLoading({
            type: "STOP_LOADING",
            payload: loadingContext ?? "GET_PAGINATION_RESULTS",
          });
        });
    },
    [state, loadingContext, errorMessage]
  );

  const getInitial = useCallback(() => {
    dispatchLoading({ type: "SET_LOADING", payload: loadingContext ?? "GET_PAGINATION_RESULTS" });
    fetchPage(state.opts)
      .then((res: AxiosResponse<PaginatedResponse<T>>) => {
        setState({
          ...state,
          next: res.data.next,
          previous: res.data.previous,
          count: res.data.count,
        });
        setResults({ [state.currentPage]: res.data.results });
        setPageResults(res.data.results);
      })
      .catch(() => {
        addMessage({ type: "error", content: errorMessage });
      })
      .finally(() => {
        dispatchLoading({
          type: "STOP_LOADING",
          payload: loadingContext ?? "GET_PAGINATION_RESULTS",
        });
      });
  }, [state, loadingContext, errorMessage]);

  const handleInfiniteScroll = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      if (entry.isIntersecting) {
        nextPage();
      }
    },
    [nextPage]
  );

  useEffect(() => {
    const node = lastElementRef;
    if (!node) return;
    const observer = new IntersectionObserver(handleInfiniteScroll);
    observer.observe(node);
    return () => {
      observer.disconnect();
    };
  }, [lastElementRef]);

  useEffect(() => {
    if (!disableAutoFetchInitial) {
      getInitial();
    }
  }, []);

  const reducedResults = useMemo(() => {
    return Object.values(results).reduce((total, tArr) => total.concat(tArr), []) || [];
  }, [results]);

  return {
    results: reducedResults,
    pageResults,
    count: state.count,
    nextPage,
    getPage,
    setLastElementRef,
    handleSearch,
    updatePage,
    getInitial,
  };
};
