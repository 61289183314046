import { getUserNotifications } from "adapters/PushNotificationsAdapter";
import React, { createContext, useEffect, useContext, useReducer, FC } from "react";
import { PushNotificationReducer } from "reducers/PushNotificationReducer";
import { LoaderContext } from "./LoaderContext";
import { NotificationContext } from "./NotificationContext";
import { UserContext } from "./UserContext";

export enum PUSH_NOTIFICATION_TYPES {
  jobMatch = "job_match",
  conversation = "conversations",
  jobApplication = "application_stage",
}

export const PushNotificationInitialState = {
  notifications: [],
};

export type PushNotificationContextState = {
  notifications: PUSH_NOTIFICATION_TYPES[];
};

export const PushNotificationContext = createContext<{
  notifications: PUSH_NOTIFICATION_TYPES[];
  hasNotification: (notification: PUSH_NOTIFICATION_TYPES) => boolean;
  updateNotification: (notification: PUSH_NOTIFICATION_TYPES) => void;
}>({
  notifications: [],
  hasNotification: () => false,
  updateNotification: () => false,
});

// Interval timeout for push notifications in milliseconds
const PUSH_NOTIFICATION_INTERVAL = 5 * 60 * 1000;

const PushNotificationProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(PushNotificationReducer, PushNotificationInitialState);
  const { isAuthenticated } = useContext(UserContext);
  const { dispatchLoading, isLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);

  const hasNotification = (notification: PUSH_NOTIFICATION_TYPES) => {
    return state.notifications.includes(notification);
  };

  const fetchAllNotifications = () => {
    if (isLoading("GET_ALL_NOTIFICATIONS")) {
      return;
    }
    dispatchLoading({ type: "SET_LOADING", payload: "GET_ALL_NOTIFICATIONS" });
    getUserNotifications({ expand: "users" })
      .then((res) => {
        dispatch({
          type: "SET_NOTIFICATIONS",
          payload: res.data.map((notification) => notification.notification_type),
        });
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.fetchingNotifications" });
      })
      .finally(() => dispatchLoading({ type: "STOP_LOADING", payload: "GET_ALL_NOTIFICATIONS" }));
  };

  const updateNotification = (notification: PUSH_NOTIFICATION_TYPES) => {
    if (!hasNotification(notification)) {
      return;
    }
    fetchAllNotifications();
  };

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (isAuthenticated) {
      fetchAllNotifications();

      interval = setInterval(() => {
        fetchAllNotifications();
      }, PUSH_NOTIFICATION_INTERVAL);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isAuthenticated]);

  return (
    <PushNotificationContext.Provider
      value={{ notifications: state.notifications, hasNotification, updateNotification }}
    >
      {children}
    </PushNotificationContext.Provider>
  );
};

export default PushNotificationProvider;
