import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";
import { Job } from "./JobAdapter";
import { User } from "reducers/UserReducer";

export type InternalJobApplication = {
  id: string;
  is_proposal_sent: boolean;
  job: Job;
  user: User;
  kanban_job_application_stage_order_no?: number;
  stage?: string;
  created?: string;
};

export type InternalJobApplicationActions =
  | "GET_INTERNAL_JOB_APPLICATION"
  | "GET_INTERNAL_JOB_APPLICATION_LIST"
  | "CREATE_INTERNAL_JOB_APPLICATION"
  | "UPDATE_INTERNAL_JOB_APPLICATION"
  | "PROPOSAL_INTERNAL_JOB_APPLICATION";

export const getInternalJobApplication = async (
  applId: string
): Promise<AxiosResponse<InternalJobApplication>> => {
  return API.get(`/job-applications/internal/${applId}/`);
};

export const getInternalJobApplicationList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<InternalJobApplication>>> => {
  return API.get("/job-applications/internal/", { params: params });
};

export const createInternalJobApplication = async (
  data?: Record<string, unknown>
): Promise<AxiosResponse<InternalJobApplication>> => {
  return API.post("/job-applications/internal/", data);
};

export const updateInternalJobApplication = async (
  applId: string,
  data: Partial<InternalJobApplication>
): Promise<AxiosResponse<InternalJobApplication>> => {
  return API.patch(`/job-applications/internal/${applId}/`, data);
};

export const deleteInternalJobApplication = async (applicationId: string): Promise<void> => {
  return API.delete(`/job-applications/internal/${applicationId}/`);
};

export const sendJobProposal = async (applicationId: string): Promise<void> => {
  return API.post(`/job-applications/internal/${applicationId}/job-proposal/`);
};
