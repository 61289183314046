import React, { FC } from "react";
import { Box } from "@mui/material";

const STEP_COLORS = Object.freeze({
  Inbox: "#0014C9",
  Reviewing: "#1ABE0C",
  Interview: "#B90000",
  Intervju: "#B90000",
  Offered: "#840AA3",
  Hired: "#11A3D1",
  Bakgrundskontroll: "#ECC600",
  Telefonintervju: "#EF410A",
  "Presenterad för kund": "#d16e11",
  "Passar för andra uppdrag": "#d111b7",
});

interface Props {
  name: string;
  count: number;
}

export const ApplicationStageCircleSize = 22;

export const ApplicationStageCircle: FC<Props> = ({ name, count }) => {
  const color = STEP_COLORS[name] || "#999999";

  return (
    <Box
      bgcolor={`${color}19`}
      color={color}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={`${ApplicationStageCircleSize}px`}
      height={`${ApplicationStageCircleSize}px`}
      borderRadius="100px"
      fontSize="12px"
      fontWeight="700"
    >
      <span>{count}</span>
    </Box>
  );
};
