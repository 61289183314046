import React, { useContext, FC, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { usePagination } from "hooks/usePagination";
import { getJobApplicationList, JobApplication } from "adapters";
import { ApplicationsTabContent } from "components/ApplicationsTabContent";
import { BreakpointsContext } from "context/BreakpointContext";
import { PushNotificationContext, PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { ApplicationTabDT } from "components/ApplicationTabDT";

export const MyJobApplicationsTab: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const { updateNotification } = useContext(PushNotificationContext);

  const {
    results,
    updatePage,
    setLastElementRef: setLastJobApplicationRef,
  } = usePagination<JobApplication>(
    getJobApplicationList, // will need to expand department later
    "error.fetchJobList",
    "GET_JOB_APPLICATION_LIST"
  );

  const activeApplicationText = (count: number) => {
    if (count === 1) {
      return localize("profileView.jobApplicationOneResult");
    } else if (count > 1) {
      return localize("profileView.jobApplicationResults", { count: count.toString() });
    } else {
      return "";
    }
  };

  useEffect(() => {
    updateNotification(PUSH_NOTIFICATION_TYPES.jobApplication);
  }, []);

  if (isDesktop) {
    return (
      <Grid container wrap="nowrap" className="contentWrapper">
        <ApplicationTabDT paginationOpts={{ updatePage, results, setLastJobApplicationRef }} />
      </Grid>
    );
  }

  return (
    <Grid container gap="1rem" paddingTop="1rem">
      <Box paddingLeft="1rem">
        <Typography color="primary" variant="h4" fontWeight="bold">
          {localize("profileView.myApplications")}
        </Typography>
        <Typography sx={{ textTransform: "uppercase", opacity: "0.75" }}>
          {activeApplicationText(results.filter((job) => !job.is_canceled).length)}
        </Typography>
      </Box>
      <ApplicationsTabContent paginationOpts={{ updatePage, results, setLastJobApplicationRef }} />
    </Grid>
  );
};
