import { CircularProgress, Grid, Typography } from "@mui/material";
import { Job, getJobList, removeSavedJob } from "adapters";
import { JobCard } from "components/JobCard";
import { SwipeableElement } from "components/SwipeableElement";
import { LoaderContext } from "context/LoaderContext";
import { usePagination } from "hooks/usePagination";
import React, { FC, useContext } from "react";
import { NotificationContext } from "context/NotificationContext";
import { LocaleContext } from "context/LocaleContext";

const SavedJobsView: FC = () => {
  const { isLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { localize } = useContext(LocaleContext);

  const { results, setLastElementRef, updatePage } = usePagination<Job>(
    () => getJobList({ status: "active", user_interest: true }),
    "error.fetchJobList"
  );

  const removeJob = async (jobId: string) => {
    return removeSavedJob(jobId)
      .then(() => {
        setTimeout(() => {
          updatePage();
          addMessage({ type: "success", content: "success.removedSuccessful", duration: 2000 });
        }, 1000);
        return true;
      })
      .catch(() => {
        addMessage({ type: "error" });
        return false;
      });
  };

  return (
    <div className="contentWrapper">
      <Grid container gap="1rem">
        <Typography width="100%" variant="h6" align="center" color="primary.dark">
          {localize("routes.saved")}
        </Typography>
        {isLoading("GET_PAGINATION_RESULTS") ? (
          <CircularProgress sx={{ margin: "auto" }} />
        ) : !results.length ? (
          <Typography>{localize("common.emptySavedJobs")}</Typography>
        ) : (
          results.map((job, index) => {
            const isLastElement = results.length === index + 1;
            return isLastElement ? (
              <div ref={setLastElementRef} key={job.id} style={{ width: "100%" }}>
                <SwipeableElement key={job.id} handleRemove={() => removeJob(job.id)}>
                  <JobCard {...job} />
                </SwipeableElement>
              </div>
            ) : (
              <SwipeableElement key={job.id} handleRemove={() => removeJob(job.id)}>
                <JobCard {...job} />
              </SwipeableElement>
            );
          })
        )}
      </Grid>
    </div>
  );
};
export default SavedJobsView;
