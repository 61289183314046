import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const ApplyAssignmentSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 791 706"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_341_6321)">
          <path
            d="M51.1248 536.144C55.048 537.523 59.2602 537.869 63.3556 537.148C67.4511 536.426 71.2917 534.662 74.5075 532.026C82.6973 525.151 85.2655 513.83 87.3542 503.344L93.5339 472.327L80.5963 481.236C71.2916 487.642 61.778 494.254 55.3361 503.533C48.8943 512.813 46.0839 525.48 51.2582 535.521"
            fill="#E6E6E6"
          />
          <path
            d="M53.1226 575.855C51.4942 563.991 49.8188 551.974 50.9638 539.983C51.9784 529.334 55.2275 518.934 61.8421 510.404C65.3531 505.885 69.6253 502.014 74.4667 498.963C75.7289 498.167 76.8908 500.167 75.6341 500.96C67.2569 506.254 60.7773 514.072 57.1296 523.286C53.101 533.532 52.4541 544.702 53.148 555.586C53.5675 562.168 54.4588 568.707 55.3539 575.239C55.4246 575.534 55.3805 575.845 55.2305 576.109C55.0805 576.373 54.8358 576.571 54.5459 576.661C54.2502 576.741 53.935 576.701 53.6687 576.549C53.4024 576.398 53.2065 576.148 53.1234 575.853L53.1226 575.855Z"
            fill="#F2F2F2"
          />
          <path
            d="M64.8438 556.818C66.5261 559.376 68.8388 561.457 71.5591 562.862C74.2794 564.266 77.3156 564.946 80.3752 564.837C88.2396 564.463 94.7932 558.977 100.692 553.766L118.144 538.357L106.594 537.805C98.2882 537.407 89.7677 537.034 81.8563 539.598C73.9449 542.162 66.6487 548.324 65.2023 556.513"
            fill="#E6E6E6"
          />
          <path
            d="M48.5198 582.675C56.3595 568.803 65.4521 553.387 81.7006 548.459C86.2181 547.094 90.9549 546.605 95.656 547.018C97.1379 547.146 96.7678 549.43 95.289 549.303C87.4086 548.649 79.5433 550.729 73.0174 555.195C66.7375 559.47 61.8477 565.413 57.7096 571.714C55.1745 575.575 52.9038 579.599 50.6332 583.617C49.9076 584.902 47.7858 583.974 48.5198 582.675Z"
            fill="#F2F2F2"
          />
          <path
            d="M675.815 411.433H243.6C242.268 411.431 240.992 410.901 240.05 409.959C239.108 409.018 238.578 407.741 238.577 406.409V5.02359C238.578 3.69179 239.108 2.41502 240.05 1.4733C240.992 0.531571 242.268 0.001741 243.6 0H675.815C677.147 0.001741 678.424 0.531571 679.365 1.4733C680.307 2.41502 680.837 3.69179 680.839 5.02359V406.408C680.837 407.74 680.307 409.017 679.366 409.959C678.424 410.9 677.147 411.43 675.815 411.432V411.433Z"
            fill="white"
          />
          <path
            d="M675.815 411.433H243.6C242.268 411.431 240.992 410.901 240.05 409.959C239.108 409.018 238.578 407.741 238.577 406.409V5.02359C238.578 3.69179 239.108 2.41502 240.05 1.4733C240.992 0.531571 242.268 0.001741 243.6 0H675.815C677.147 0.001741 678.424 0.531571 679.365 1.4733C680.307 2.41502 680.837 3.69179 680.839 5.02359V406.408C680.837 407.74 680.307 409.017 679.366 409.959C678.424 410.9 677.147 411.43 675.815 411.432V411.433ZM243.6 2.00548C242.802 2.00721 242.037 2.32506 241.473 2.88945C240.908 3.45384 240.59 4.21883 240.589 5.017V406.408C240.59 407.206 240.908 407.971 241.473 408.536C242.037 409.1 242.802 409.418 243.6 409.42H675.815C676.613 409.418 677.378 409.1 677.943 408.536C678.507 407.971 678.825 407.206 678.827 406.408V5.02359C678.825 4.22542 678.507 3.46043 677.943 2.89604C677.378 2.33165 676.613 2.0138 675.815 2.01207L243.6 2.00548Z"
            fill="#CACACA"
          />
          <path
            d="M447.829 104.301C446.926 104.303 446.06 104.663 445.422 105.302C444.783 105.942 444.425 106.808 444.425 107.712C444.425 108.615 444.783 109.482 445.422 110.121C446.06 110.761 446.926 111.121 447.829 111.122H634.972C635.876 111.121 636.742 110.761 637.38 110.121C638.018 109.482 638.377 108.615 638.377 107.712C638.377 106.808 638.018 105.942 637.38 105.302C636.742 104.663 635.876 104.303 634.972 104.301H447.829Z"
            fill="#E4E4E4"
          />
          <path
            d="M447.83 124.767C446.926 124.769 446.061 125.129 445.423 125.768C444.785 126.408 444.427 127.274 444.427 128.177C444.427 129.08 444.785 129.946 445.423 130.586C446.061 131.225 446.926 131.585 447.83 131.588H543.37C544.273 131.585 545.138 131.225 545.776 130.586C546.414 129.946 546.772 129.08 546.772 128.177C546.772 127.274 546.414 126.408 545.776 125.768C545.138 125.129 544.273 124.769 543.37 124.767H447.83Z"
            fill="#E4E4E4"
          />
          <path
            d="M284.261 234.82C283.358 234.822 282.492 235.183 281.854 235.822C281.217 236.462 280.858 237.328 280.858 238.231C280.858 239.134 281.217 240 281.854 240.64C282.492 241.279 283.358 241.639 284.261 241.641H635.154C636.057 241.639 636.923 241.279 637.561 240.64C638.198 240 638.557 239.134 638.557 238.231C638.557 237.328 638.198 236.462 637.561 235.822C636.923 235.183 636.057 234.822 635.154 234.82H284.261Z"
            fill="#E4E4E4"
          />
          <path
            d="M284.261 255.286C283.358 255.288 282.492 255.649 281.854 256.288C281.217 256.927 280.858 257.794 280.858 258.697C280.858 259.6 281.217 260.466 281.854 261.105C282.492 261.745 283.358 262.105 284.261 262.107H543.552C544.455 262.105 545.32 261.745 545.958 261.105C546.596 260.466 546.954 259.6 546.954 258.697C546.954 257.794 546.596 256.927 545.958 256.288C545.32 255.649 544.455 255.288 543.552 255.286H284.261Z"
            fill="#E4E4E4"
          />
          <path
            d="M284.261 275.288C283.358 275.29 282.492 275.651 281.854 276.29C281.217 276.929 280.858 277.796 280.858 278.699C280.858 279.602 281.217 280.468 281.854 281.107C282.492 281.747 283.358 282.107 284.261 282.109H635.154C636.057 282.107 636.923 281.747 637.561 281.107C638.198 280.468 638.557 279.602 638.557 278.699C638.557 277.796 638.198 276.929 637.561 276.29C636.923 275.651 636.057 275.29 635.154 275.288H284.261Z"
            fill="#E4E4E4"
          />
          <path
            d="M284.261 295.753C283.358 295.755 282.492 296.115 281.854 296.755C281.217 297.394 280.858 298.26 280.858 299.163C280.858 300.067 281.217 300.933 281.854 301.572C282.492 302.212 283.358 302.572 284.261 302.574H543.552C544.455 302.572 545.32 302.212 545.958 301.572C546.596 300.933 546.954 300.067 546.954 299.163C546.954 298.26 546.596 297.394 545.958 296.755C545.32 296.115 544.455 295.755 543.552 295.753H284.261Z"
            fill="#E4E4E4"
          />
          <path
            d="M284.261 316.288C283.358 316.29 282.492 316.651 281.854 317.29C281.217 317.929 280.858 318.796 280.858 319.699C280.858 320.602 281.217 321.468 281.854 322.107C282.492 322.747 283.358 323.107 284.261 323.109H635.154C636.057 323.107 636.923 322.747 637.561 322.107C638.198 321.468 638.557 320.602 638.557 319.699C638.557 318.796 638.198 317.929 637.561 317.29C636.923 316.651 636.057 316.29 635.154 316.288H284.261Z"
            fill="#E4E4E4"
          />
          <path
            d="M284.261 336.753C283.358 336.755 282.492 337.115 281.854 337.755C281.217 338.394 280.858 339.26 280.858 340.163C280.858 341.067 281.217 341.933 281.854 342.572C282.492 343.212 283.358 343.572 284.261 343.574H543.552C544.455 343.572 545.32 343.212 545.958 342.572C546.596 341.933 546.954 341.067 546.954 340.163C546.954 339.26 546.596 338.394 545.958 337.755C545.32 337.115 544.455 336.755 543.552 336.753H284.261Z"
            fill="#E4E4E4"
          />
          <path
            d="M339.839 166.944C330.148 166.944 320.674 164.071 312.616 158.687C304.558 153.302 298.277 145.65 294.568 136.696C290.86 127.743 289.889 117.89 291.78 108.385C293.67 98.8801 298.337 90.1491 305.19 83.2963C312.043 76.4435 320.774 71.7766 330.279 69.8859C339.784 67.9952 349.636 68.9655 358.59 72.6742C367.543 76.3829 375.196 82.6633 380.58 90.7214C385.965 98.7794 388.838 108.253 388.838 117.944C388.824 130.935 383.657 143.39 374.471 152.576C365.285 161.762 352.83 166.929 339.839 166.944V166.944Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M191.098 352.217C191.013 350.428 190.537 348.68 189.704 347.095C188.87 345.511 187.699 344.128 186.274 343.045C184.848 341.961 183.202 341.204 181.452 340.825C179.702 340.447 177.89 340.457 176.145 340.854L159.948 318.029L143.677 324.489L167.002 356.401C167.864 359.29 169.756 361.762 172.319 363.349C174.882 364.936 177.938 365.527 180.908 365.011C183.878 364.496 186.556 362.908 188.434 360.55C190.312 358.192 191.26 355.227 191.098 352.217H191.098Z"
            fill="#A0616A"
          />
          <path
            d="M159.531 350.525L110.523 286.947L128.886 229.236C130.232 214.727 139.311 210.675 139.698 210.51L140.287 210.257L156.266 252.869L144.534 284.155L173.33 332.587L159.531 350.525Z"
            fill="#3F3D56"
          />
          <path
            d="M329.727 154.536C327.951 154.759 326.244 155.368 324.729 156.322C323.213 157.275 321.925 158.55 320.956 160.055C319.986 161.56 319.358 163.259 319.116 165.033C318.874 166.807 319.024 168.613 319.555 170.323L298.05 188.235L305.748 203.959L335.762 178.236C338.575 177.153 340.893 175.076 342.278 172.398C343.662 169.72 344.015 166.627 343.271 163.706C342.527 160.784 340.738 158.237 338.242 156.547C335.745 154.857 332.716 154.142 329.727 154.536V154.536Z"
            fill="#A0616A"
          />
          <path
            d="M330.482 186.138L270.883 239.915L211.925 226.069C197.355 225.85 192.614 217.111 192.419 216.738L192.121 216.17L233.37 196.944L265.47 206.223L311.53 173.768L330.482 186.138Z"
            fill="#3F3D56"
          />
          <path
            d="M227.248 568.437L243.261 568.436L250.878 506.672L227.245 506.673L227.248 568.437Z"
            fill="#A0616A"
          />
          <path
            d="M274.143 583.304L223.817 583.306L223.816 563.862L260.023 563.86C263.768 563.86 267.359 565.347 270.007 567.995C272.655 570.643 274.143 574.234 274.143 577.979L274.143 583.304L274.143 583.304Z"
            fill="#2F2E41"
          />
          <path
            d="M163.247 568.437L179.26 568.436L186.878 506.672L163.245 506.673L163.247 568.437Z"
            fill="#A0616A"
          />
          <path
            d="M210.143 583.304L159.816 583.306L159.816 563.862L196.023 563.86C197.877 563.86 199.713 564.225 201.426 564.935C203.139 565.644 204.695 566.684 206.007 567.995C207.318 569.306 208.358 570.863 209.067 572.576C209.777 574.289 210.142 576.124 210.142 577.979L210.143 583.304V583.304Z"
            fill="#2F2E41"
          />
          <path
            d="M157.552 342.991L158.858 434.42L160.165 554.584L188.899 551.972L203.267 386.094L221.553 551.972H251.218L254.206 384.788L243.757 348.216L157.552 342.991Z"
            fill="#2F2E41"
          />
          <path
            d="M213.794 355.269C182.528 355.272 153.749 341.121 153.36 340.927L153.038 340.765L150.414 277.799C149.653 275.574 134.671 231.668 132.134 217.713C129.563 203.574 166.822 191.165 171.347 189.714L172.374 178.34L214.127 173.841L219.419 188.394L234.399 194.011C236.097 194.649 237.502 195.887 238.349 197.491C239.195 199.095 239.424 200.954 238.991 202.716L230.665 236.572L250.999 348.584L246.621 348.774C236.128 353.513 224.804 355.269 213.794 355.269Z"
            fill="#3F3D56"
          />
          <path
            d="M209.304 162.953C223.873 154.989 229.227 136.723 221.263 122.154C213.299 107.585 195.033 102.231 180.465 110.195C165.896 118.159 160.542 136.425 168.506 150.994C176.47 165.562 194.736 170.916 209.304 162.953Z"
            fill="#A0616A"
          />
          <path
            d="M170.536 165.677C176.265 171.78 186.905 168.504 187.652 160.167C187.71 159.519 187.706 158.868 187.639 158.221C187.254 154.528 185.12 151.175 185.631 147.276C185.747 146.306 186.108 145.381 186.681 144.589C191.247 138.475 201.964 147.323 206.273 141.789C208.915 138.395 205.809 133.052 207.837 129.259C210.513 124.253 218.438 126.723 223.409 123.981C228.939 120.931 228.608 112.446 224.968 107.285C220.528 100.991 212.744 97.6329 205.057 97.1488C197.371 96.6648 189.737 98.7428 182.56 101.54C174.406 104.717 166.32 109.109 161.303 116.279C155.2 124.998 154.613 136.72 157.665 146.915C159.521 153.117 165.857 160.694 170.536 165.677Z"
            fill="#2F2E41"
          />
          <path
            d="M382 584.078H1C0.734784 584.078 0.480414 583.973 0.292877 583.785C0.105341 583.598 0 583.343 0 583.078C0 582.813 0.105341 582.559 0.292877 582.371C0.480414 582.183 0.734784 582.078 1 582.078H382C382.265 582.078 382.52 582.183 382.707 582.371C382.895 582.559 383 582.813 383 583.078C383 583.343 382.895 583.598 382.707 583.785C382.52 583.973 382.265 584.078 382 584.078Z"
            fill="#CACACA"
          />
          <path
            d="M337.009 136.452C336.125 136.453 335.274 136.118 334.628 135.516L318.47 120.509C317.791 119.877 317.391 119.001 317.357 118.074C317.324 117.147 317.659 116.244 318.29 115.565C318.922 114.885 319.797 114.484 320.724 114.449C321.651 114.414 322.554 114.749 323.234 115.38L336.92 128.092L363.996 101.015C364.321 100.688 364.707 100.429 365.132 100.251C365.557 100.074 366.013 99.9818 366.474 99.981C366.935 99.9801 367.391 100.07 367.817 100.246C368.243 100.422 368.63 100.68 368.956 101.006C369.281 101.332 369.54 101.719 369.716 102.145C369.891 102.571 369.982 103.027 369.981 103.488C369.98 103.949 369.888 104.405 369.71 104.83C369.532 105.255 369.273 105.641 368.946 105.966L339.485 135.427C339.16 135.752 338.774 136.01 338.349 136.186C337.924 136.362 337.469 136.452 337.009 136.452V136.452Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_341_6321">
            <rect width="680.839" height="584.232" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
