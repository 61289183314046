import { Divider, Grid, Skeleton, Typography } from "@mui/material";
import React, { FC, useContext, useEffect } from "react";
import { LoaderContext } from "context/LoaderContext";
import { usePagination } from "hooks/usePagination";
import { AgentCard } from "./AgentCard";
import { Agent, getAgents } from "adapters";
import { colors, defaultStyles } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";

interface AgentsListProps {
  forceUpdate?: boolean;
  setSelectedAgent: (agent: Agent) => void;
  selectedAgent: Agent | undefined;
}

const AgentsList: FC<AgentsListProps> = ({
  forceUpdate = false,
  setSelectedAgent,
  selectedAgent,
}) => {
  const { isLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);

  const { results, setLastElementRef, updatePage } = usePagination<Agent>(
    getAgents,
    "error.fetchAgents",
    "GET_AGENTS"
  );

  useEffect(() => {
    updatePage();
  }, [forceUpdate]);

  useEffect(() => {
    if (results.length !== 0 && !selectedAgent) {
      setSelectedAgent(results[0]);
    }
  }, [results]);

  return (
    <Grid container direction="column" flexWrap="nowrap" padding="5px" maxHeight="1000px">
      <Grid container justifyContent="space-between" paddingRight="26px">
        <Typography variant="h5" fontWeight="bold" color={colors.textDark} marginLeft="4px">
          {localize("statisticView.selectAgent")}
        </Typography>
        <Typography color={colors.darkGrey} variant="body1" fontWeight="bold" marginTop="25px">
          {localize("statisticView.lastLogin")}:
        </Typography>
      </Grid>

      <Grid item overflow="auto">
        {isLoading("GET_AGENTS")
          ? Array(5)
              .fill(1)
              .map((_, index) => (
                <Skeleton
                  key={`skeleton_message_${index}`}
                  width="100%"
                  height="70px"
                  variant="rectangular"
                  sx={{ borderRadius: defaultStyles.borderRadius, marginBottom: "1px" }}
                />
              ))
          : results.map((agent, index) => {
              if (index === results.length - 1) {
                return (
                  <div key={index} ref={setLastElementRef} style={{ width: "100%" }}>
                    <AgentCard
                      agent={agent}
                      setSelectedAgent={setSelectedAgent}
                      isSelected={selectedAgent?.id === agent.id}
                    />
                  </div>
                );
              }
              return (
                <>
                  <AgentCard
                    key={index}
                    agent={agent}
                    setSelectedAgent={setSelectedAgent}
                    isSelected={selectedAgent?.id === agent.id}
                  />
                  <Divider variant="middle" sx={{ borderColor: colors.grey }} />
                </>
              );
            })}
      </Grid>
    </Grid>
  );
};
export default AgentsList;
