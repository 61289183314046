import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { colors } from "styles/colors";

interface NoticeDialogProps {
  title: string;
  textContent: string;
  subContent: string;
  warning?: string;
  closeAction: () => void;
  confirmAction?: () => void;
}
export const NoticeDialog: FC<NoticeDialogProps> = ({
  title,
  textContent,
  subContent,
  warning,
  closeAction,
  confirmAction,
}) => {
  const { localize } = useContext(LocaleContext);

  return (
    <Dialog open onClose={closeAction}>
      <DialogContent>
        <Typography fontWeight="bold" textAlign="center" variant="body1">
          {title}
        </Typography>
        <Grid container direction="column" alignItems="center" gap="1rem">
          <Typography textAlign="center" variant="body1">
            {textContent}
          </Typography>
          <Typography textAlign="center" variant="body1">
            {subContent}
          </Typography>
          {warning && (
            <Typography textAlign="center" fontWeight="bold" variant="body1">
              {warning}
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Button
            fullWidth
            size="large"
            variant="text"
            sx={{ color: colors.cancelText, fontWeight: "bold" }}
            onClick={confirmAction ? () => confirmAction() : () => closeAction()}
          >
            {localize("common.close")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
