import React, { FC, HTMLAttributes, useContext } from "react";
import { Box, Grid } from "@mui/material";
import { WTypography } from "components/WTypography";
import { colors } from "styles/colors";
import { AvatarItem } from "components/AvatarItem";
import { JobApplication } from "adapters/JobApplicationAdapter";
import { InternalJobApplication } from "adapters/InternalJobApplicationAdapter";
import { LocaleContext } from "context/LocaleContext";
import { prettifyDate } from "utils/PrettifyDate";
import { UserRatingLabel } from "components/UserRatingLabel";
import { ModalContext } from "context/ModalContext";
import { KanbanItemWidth } from "./KanbanColumn";
import { InternalJobAppMenuButton } from "./InternalJobAppMenuButton";

interface Props extends HTMLAttributes<HTMLDivElement> {
  application: JobApplication | InternalJobApplication;
  onDelete: () => void;
  onSendJobProposal: () => void;
}

export const ApplicationItemHeight = "130px";

export const ApplicationItem: FC<Props> = (props) => {
  const { localize } = useContext(LocaleContext);
  const { showModal } = useContext(ModalContext);

  const { application, onDelete, onSendJobProposal, ...restProps } = props;

  const {
    user: { profile_picture, first_name, last_name, email, rating, id: userId },
  } = application;

  const isInternal = !("email" in application);
  const isProposalSent = (application as InternalJobApplication).is_proposal_sent;

  return (
    <Box position="relative" {...restProps}>
      <Grid
        container
        direction="column"
        width={KanbanItemWidth}
        height={ApplicationItemHeight}
        borderRadius="5px"
        bgcolor={isInternal ? `${colors.errorLight}` : colors.lightGrey}
        padding="16px"
        justifyContent="space-between"
        onClick={() =>
          showModal("CANDIDATE_MODAL", {
            candidateId: userId,
            selectedApplication: {
              id: application.id,
              job: application.job,
              isInternal: isInternal,
              created: application.created,
            },
            onUpdateInternalApplication: (action) =>
              action === "delete" ? onDelete() : onSendJobProposal(),
          })
        }
      >
        <div style={{ paddingRight: "30px" }}>
          <AvatarItem
            size="tiny"
            imageSrc={profile_picture}
            firstName={first_name || email}
            lastName={last_name}
            showName
          />
        </div>
        <Grid item>
          <WTypography
            fontSize="14px"
            color={colors.textDarkLighter}
            lineClamps={1}
            marginLeft="5px"
          >
            {!isInternal ? (
              <>
                {localize("jobSteps.applied")} {prettifyDate(application.created)}
              </>
            ) : null}
          </WTypography>

          {isInternal && isProposalSent ? (
            <WTypography color={colors.error}>{localize("kanbanView.proposalSent")}</WTypography>
          ) : null}
        </Grid>
        <Grid item display="flex" justifyContent="space-between" alignItems="flex-end">
          <UserRatingLabel
            rating={rating}
            customColor={isInternal ? `${colors.error}4D` : undefined}
          />
          {isInternal ? (
            <WTypography color={colors.error}>{localize("common.internal")}</WTypography>
          ) : null}
        </Grid>
      </Grid>
      {isInternal && !isProposalSent ? (
        <InternalJobAppMenuButton onDelete={onDelete} onSendJobProposal={onSendJobProposal} />
      ) : null}
    </Box>
  );
};
