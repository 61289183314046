import { ButtonGroup, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { News } from "adapters";
import {
  WButton,
  WChip,
  WIcon,
  WIconButton,
  WIconOutlinedButton,
  WIconTypes,
  WImageBanner,
} from "components";
import { JobFilterItem, JobFilters } from "components/dialogs/FiltersDialog";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useCallback, useContext, useState } from "react";
import { colors } from "styles/colors";
import { DepartmentFilterList } from "./DepartmentFilterList";
import { RegionFilterList } from "./RegionFilterList";
import { SkillFilterList } from "./SkillFilterList";
import { NotificationContext } from "context/NotificationContext";
import { copyToClipboard } from "utils/generalUtils";

interface FiltersBodyProps {
  filters: JobFilters;
  setFilters: (filters: JobFilters) => void;
  setShowFilters?: (condition: boolean) => void;
  itemsToShow?: number;
  showBanner?: boolean;
  latestNews?: News;
}

export const FILTER_ITEM_HEIGHT = 40; //2.5rem

export const FiltersBody: FC<FiltersBodyProps> = ({
  filters,
  itemsToShow = 4,
  setFilters,
  setShowFilters,
  showBanner = false,
  latestNews,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { addMessage } = useContext(NotificationContext);

  const [showMore, setShowMore] = useState({
    departments: false,
    regions: false,
    skills: false,
  });

  const handleRemoveFilter = useCallback(
    (key: string, item: JobFilterItem) => {
      setFilters({
        ...filters,
        [key]: filters[key].filter((prevValue: JobFilterItem) => prevValue.value !== item.value),
      });
    },
    [setFilters, filters]
  );

  const handleFilterClick = useCallback(
    (key: keyof JobFilters, item: JobFilterItem) => {
      const filter = filters[key];
      if (typeof filter === "string") {
        return;
      }
      if (filter.some((filter) => filter.value === item.value)) {
        handleRemoveFilter(key, item);
      } else {
        setFilters({ ...filters, [key]: [...filter, item] });
      }
    },
    [setFilters, filters, handleRemoveFilter]
  );

  const handleCopyClick = useCallback(() => {
    copyToClipboard("jobFilters", JSON.stringify(filters));

    addMessage({
      type: "success",
      content: "common.copiedToClipboard",
      duration: 3000,
    });
  }, [setFilters, filters]);

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        padding="1rem"
        gap=".5rem"
        sx={!isDesktop ? { backgroundColor: colors.white } : {}}
      >
        {setShowFilters && (
          <Grid container alignItems="center">
            <Typography variant="h6" mr="auto">
              {localize("common.filterJobs")}
            </Typography>
            <Tooltip title={localize("common.copyToClipboard")} placement="top">
              <WIconOutlinedButton onClick={handleCopyClick} style={{ marginRight: "10px" }}>
                <WIcon icon={WIconTypes.clipBoard} />
              </WIconOutlinedButton>
            </Tooltip>
            <WIconButton
              variant="contained"
              sx={{ backgroundColor: colors.mainDark }}
              onClick={() => setShowFilters(false)}
            >
              <WIcon rotationDegrees="90" icon={WIconTypes.sliders} color="secondary" />
            </WIconButton>
          </Grid>
        )}
        <Grid
          container
          item
          gap=".5rem"
          paddingBottom="1rem"
          paddingLeft={!isDesktop ? ".8rem" : 0}
        >
          {Object.entries(filters).map(([key, value]) => {
            if (!Array.isArray(value)) {
              return null;
            }
            return value.map((chip) => (
              <WChip
                sx={{ padding: "0 .4rem", border: "none" }}
                key={chip.value}
                chipLabel={chip.label}
                onClick={() => handleRemoveFilter(key, chip)}
                icon={<WIcon icon={WIconTypes.clear} />}
                variant="shadow"
              />
            ));
          })}
        </Grid>
        <Box paddingLeft=".8rem">
          <RegionFilterList
            expand={!showMore.regions}
            itemsToShow={itemsToShow}
            filters={filters}
            handleFilterClick={handleFilterClick}
            handleExpandClick={() => setShowMore({ ...showMore, regions: true })}
          />
        </Box>
        <Divider style={{ width: "100%" }} sx={{ borderBottomWidth: "2px" }} />

        <Box paddingLeft=".8rem">
          <DepartmentFilterList
            expand={!showMore.departments}
            itemsToShow={itemsToShow}
            filters={filters}
            handleFilterClick={handleFilterClick}
            handleExpandClick={() => setShowMore({ ...showMore, departments: true })}
          />
        </Box>

        <Divider style={{ width: "100%" }} sx={{ borderBottomWidth: "2px" }} />

        <Box paddingLeft=".8rem">
          <SkillFilterList
            expand={!showMore.skills}
            itemsToShow={itemsToShow}
            filters={filters}
            handleFilterClick={handleFilterClick}
            handleExpandClick={() => setShowMore({ ...showMore, skills: true })}
          />
        </Box>

        <Divider style={{ width: "100%" }} sx={{ borderBottomWidth: "2px" }} />

        <Typography variant="h6" mr="auto">
          {localize("searchbar.jobType")}
        </Typography>
        <Box paddingLeft=".8rem">
          <ButtonGroup>
            {jobTypes.map(({ label, tag }) => {
              const selected = tag === filters.tags;
              return (
                <WButton
                  key={label}
                  variant={selected ? "contained" : "outlined"}
                  onClick={() => {
                    setFilters({ ...filters, tags: tag });
                  }}
                >
                  {localize(label)}
                </WButton>
              );
            })}
          </ButtonGroup>
        </Box>
      </Grid>

      {showBanner && latestNews && (
        <WImageBanner
          title={latestNews.title}
          subTitle={latestNews.sub_title}
          textContent={latestNews.body}
          imageSrc={latestNews.news_photo}
          size="xs"
        />
      )}
    </>
  );
};

const jobTypes = [
  { label: "searchbar.all", tag: "KONSULT,GIG" },
  { label: "searchbar.gigs", tag: "GIG" },
  { label: "searchbar.regular", tag: "KONSULT" },
];
