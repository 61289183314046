import { AgentMonthData, getAgentMonthData } from "adapters";
import { MonthBarChartCard } from "components/StatisticView/MonthBarChartCard";
import { getTrendInfo } from "components/StatisticView/TrendInfo";
import { WIcon, WIconTypes } from "components/WIcon";
import { WTypography } from "components/WTypography";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import { colors, defaultStyles } from "styles/colors";
import { LoaderContext } from "context/LoaderContext";
import { prettifyDate } from "utils/PrettifyDate";

interface CardData {
  cards: {
    total: number;
    previous: number;
    name: string;
    icon: WIconTypes;
    monthLabel: string;
  }[];
  selectedCard: string;
  setSelectedCard: (name: string) => void;
  selectedAgentID: string;
}

export const AgentSpecificCards: FC<CardData> = ({
  cards,
  selectedCard,
  setSelectedCard,
  selectedAgentID,
}) => {
  const { isLoading } = useContext(LoaderContext);
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { localize } = useContext(LocaleContext);

  const [agentMonthData, setAgentMonthData] = useState<AgentMonthData>(defaultAgentMonthData);

  useEffect(() => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_AGENT_MONTH_DATA" });
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 12);
    getAgentMonthData({
      agent_id: selectedAgentID,
      from_date: prettifyDate(fromDate.toISOString()),
      to_date: prettifyDate(new Date().toISOString()),
    })
      .then((res) => {
        setAgentMonthData(res.data);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.fetchingAgentDetails", duration: 2000 });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_AGENT_MONTH_DATA" });
      });
  }, [selectedAgentID]);

  return (
    <>
      {isLoading("GET_AGENT_DETAILS") ? (
        <Grid container justifyContent="center" paddingX="30px" gap="30px" marginBottom="1rem">
          {Array(4)
            .fill(0)
            .map((_, index) => {
              return (
                <Skeleton
                  variant="rectangular"
                  width="170px"
                  height="150px"
                  key={`skeleton_${index}`}
                  sx={{
                    borderRadius: defaultStyles.borderRadius,
                  }}
                />
              );
            })}
        </Grid>
      ) : (
        <Grid container wrap="nowrap" paddingX="30px" mb="20px" direction="row">
          <Grid container justifyContent="center" gap="30px">
            {cards.map((card) => {
              return (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  key={card.monthLabel}
                  xs
                  maxWidth="170px"
                  sx={{
                    paddingY: "15px",
                    backgroundColor:
                      selectedCard == card.monthLabel ? colors.mainDark : colors.main,
                    borderRadius: defaultStyles.borderRadius,
                    boxShadow: defaultStyles.cardShadow,
                    color: colors.white,
                    ":hover": {
                      backgroundColor: selectedCard != card.monthLabel ? colors.mainDark : "none",
                      cursor: selectedCard != card.monthLabel ? "pointer" : "default",
                    },
                  }}
                  onClick={() => {
                    setSelectedCard(card.monthLabel);
                  }}
                >
                  <Grid item>
                    <WIcon size="large" customColor={colors.white} icon={card.icon} />
                  </Grid>

                  <Grid item marginTop="0.4rem">
                    <WTypography variant="h5" fontWeight="bold">
                      {card.total}
                    </WTypography>
                  </Grid>

                  {selectedCard == card.monthLabel ? (
                    <Grid item position="absolute" marginTop="12rem">
                      <WIcon size="medium" icon={WIconTypes.arrowDown} />
                    </Grid>
                  ) : null}

                  <Grid item textAlign="center">
                    <WTypography variant="body2" fontWeight="bold">
                      {card.name}
                    </WTypography>

                    <Grid container justifyContent="center" gap="3px">
                      {getTrendInfo(card.total, card.previous, colors.white).trendOutput}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}

      <WTypography variant="h5" fontWeight="bold" marginTop="30px">
        {localize("statisticView.yearlyOverview")}
      </WTypography>
      <MonthBarChartCard dataSets={[{ label: selectedCard, data: agentMonthData[selectedCard] }]} />
    </>
  );
};

const defaultAgentMonthData: AgentMonthData = {
  logged_in: 0,
  logged_in_period: [],
  messages_sent_period: [],
  notes_posted_period: [],
  ratings_changed_period: [],
};
