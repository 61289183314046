import { Dialog } from "@mui/material";
import React, { FC, useState, useContext } from "react";
import { ExtendedConversation, Message } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { colors } from "styles/colors";
import { DialogHeader } from "components";
import { ConversationContentComponent } from "./ConversationContentComponent";

interface ConversationModalProps {
  conversationId: string;
  onClose?: () => void;
  fullscreen?: boolean;
  returnText?: string;
}

export type SocketActions = "SOCKET_CONNECTING";

export type SocketMessage = {
  message: string;
  sender: string | null;
  sent: string;
};

export const ConversationModal: FC<ConversationModalProps> = ({
  onClose,
  conversationId,
  fullscreen = true,
  returnText,
}) => {
  const { isLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { hideModal } = useContext(ModalContext);

  const [messages] = useState<(Message | SocketMessage)[]>([]);
  const [conversation] = useState<ExtendedConversation>();

  const onHideModal = () => {
    onClose ? onClose() : hideModal();
  };

  return (
    <Dialog
      open={true}
      fullScreen={fullscreen}
      PaperProps={{ sx: { overflow: "hidden", backgroundColor: colors.white } }}
    >
      <DialogHeader
        handleClose={() => onHideModal()}
        title={
          isLoading("GET_CONVERSATION")
            ? ""
            : messages.length
            ? conversation?.name
            : localize("messagesView.newMessageTitle")
        }
        returnText={returnText ? returnText : localize("common.yourMessages")}
        lineClampTitle={1}
      />
      <ConversationContentComponent conversationId={conversationId} />
    </Dialog>
  );
};
