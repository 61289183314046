import { DesktopDatePicker, MobileDatePicker } from "@mui/lab";
import { WTextField } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { WDateFormats } from "utils/PrettifyDate";

interface WDatePickerProps {
  label: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  onAccept?: (date: Date | null) => void;
  onClose?: () => void;
  onError?: (reason: string | null) => void;
  disabled?: boolean;
  open?: boolean;
}
export const WDatePicker: FC<WDatePickerProps> = ({
  label = "",
  value,
  disabled = false,
  onChange,
  onAccept,
  onClose,
  onError,
  open,
}) => {
  const { isDesktop } = useContext(BreakpointsContext);
  const { localize } = useContext(LocaleContext);

  if (isDesktop) {
    return (
      <DesktopDatePicker
        disabled={disabled}
        mask="____-__-__"
        label={label}
        inputFormat={WDateFormats.date}
        value={value}
        onChange={onChange}
        disablePast
        onError={onError}
        renderInput={(params) => <WTextField fullWidth {...params} />}
        InputAdornmentProps={{ position: "start" }}
      />
    );
  }
  return (
    <MobileDatePicker
      open={open}
      clearable
      disabled={disabled}
      mask="____-__-__"
      label={label}
      inputFormat={WDateFormats.date}
      disablePast
      value={value}
      onChange={onChange}
      onAccept={onAccept}
      onClose={onClose}
      onError={onError}
      renderInput={(params) => <WTextField fullWidth {...params} />}
      cancelText={localize("common.cancel")}
      clearText={localize("common.unavailable")}
      okText={localize("form.save")}
    />
  );
};
