import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const QuickDeliverySVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 527 232"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_336_6708)">
          <path
            d="M156.784 191.022C154.972 205.222 148.31 218.213 141.203 230.728C140.968 231.154 140.726 231.574 140.478 232H66.609C65.1151 231.612 63.6404 231.186 62.1718 230.728C53.8285 228.187 45.9518 224.311 38.8478 219.25C11.6714 199.664 1.21392 162.654 5.93734 129.491C8.87437 108.887 17.9139 87.851 35.5166 76.7543C44.8107 70.8848 56.7491 68.9771 67.4481 71.1583C67.7281 71.2091 68.0077 71.2727 68.2936 71.33C77.4986 73.403 85.6992 78.554 90.3655 86.8526C98.7566 101.765 93.76 120.238 89.6345 136.835C85.5213 153.433 83.2898 173.299 95.2286 185.54C91.2871 173.935 95.1776 160.091 104.592 152.244C111.706 146.323 121.61 144.104 130.644 145.942C131.508 146.126 132.36 146.336 133.199 146.59C135.227 147.185 137.183 148.004 139.029 149.032C153.109 156.899 158.812 175.016 156.784 191.022Z"
            fill="#F2F2F2"
          />
          <path
            d="M112.984 230.728C115.031 231.205 117.084 231.625 119.15 232H107.981C106.518 231.606 105.063 231.186 103.62 230.728C100.448 229.736 97.3241 228.598 94.2494 227.313C81.9168 222.137 70.5566 214.366 61.9619 204.026C57.7585 199.017 54.326 193.408 51.7779 187.384C49.0148 180.577 47.2588 173.402 46.5653 166.088C45.0077 151.08 46.597 135.659 49.5657 120.919C52.6293 105.641 57.5747 90.8021 64.2887 76.7417C65.0961 75.0565 65.9309 73.382 66.7934 71.718C66.8527 71.581 66.9425 71.4594 67.0559 71.3624C67.1694 71.2654 67.3035 71.1956 67.448 71.1584C67.7414 71.1015 68.0455 71.1632 68.2935 71.33C68.5912 71.5009 68.812 71.7796 68.9102 72.1086C69.0084 72.4376 68.9765 72.7917 68.8212 73.0979C61.8988 86.6021 56.6513 100.902 53.1955 115.679C49.8137 130.095 47.8938 145.179 48.5485 160.002C49.1715 173.852 52.7889 187.473 60.9956 198.799C68.4906 209.139 78.8398 217.291 90.289 222.843C97.5357 226.311 105.148 228.956 112.984 230.728V230.728Z"
            fill="white"
          />
          <path
            d="M137.497 232H134.204C133.835 231.58 133.479 231.154 133.129 230.728C125.629 221.612 120.547 210.751 118.355 199.149C115.693 184.671 117.764 169.72 124.261 156.511C126.074 152.807 128.21 149.27 130.644 145.942C131.508 146.126 132.36 146.336 133.199 146.591C126.797 155.076 122.49 164.956 120.631 175.423C118.973 185.231 119.524 195.286 122.245 204.854C124.966 214.422 129.788 223.262 136.359 230.728C136.727 231.154 137.109 231.58 137.497 232Z"
            fill="white"
          />
          <path
            d="M434.142 221.185C434.62 224.933 436.378 228.362 438.254 231.664C438.316 231.777 438.38 231.888 438.445 232H457.941C458.335 231.898 458.724 231.785 459.112 231.664C461.314 230.994 463.393 229.971 465.267 228.635C472.44 223.466 475.2 213.698 473.953 204.946C473.178 199.508 470.792 193.957 466.147 191.028C463.624 189.491 460.614 188.964 457.719 189.551C457.645 189.564 457.572 189.581 457.496 189.596C456.291 189.829 455.149 190.315 454.145 191.021C453.141 191.727 452.298 192.638 451.671 193.693C449.456 197.629 450.775 202.504 451.864 206.884C452.95 211.265 453.538 216.508 450.388 219.739C450.849 218.387 450.943 216.938 450.659 215.538C450.375 214.139 449.724 212.84 448.772 211.776C447.821 210.712 446.603 209.921 445.244 209.483C443.885 209.046 442.434 208.978 441.041 209.288C440.813 209.336 440.588 209.392 440.366 209.459C439.831 209.616 439.315 209.832 438.828 210.103C435.112 212.179 433.607 216.961 434.142 221.185Z"
            fill="#F2F2F2"
          />
          <path
            d="M445.702 231.664C445.161 231.79 444.619 231.901 444.074 232H447.022C447.408 231.896 447.792 231.785 448.173 231.664C449.01 231.402 449.834 231.102 450.646 230.763C453.937 229.426 456.86 227.319 459.167 224.617C460.277 223.295 461.182 221.815 461.855 220.225C462.584 218.428 463.048 216.535 463.231 214.604C463.565 210.614 463.298 206.595 462.439 202.684C461.63 198.651 460.325 194.735 458.553 191.024C458.34 190.58 458.12 190.138 457.892 189.699C457.876 189.662 457.853 189.63 457.823 189.605C457.793 189.579 457.757 189.561 457.719 189.551C457.642 189.536 457.562 189.552 457.496 189.596C457.418 189.641 457.359 189.715 457.333 189.802C457.307 189.888 457.316 189.982 457.357 190.063C459.184 193.627 460.569 197.401 461.481 201.301C462.42 205.126 462.833 209.061 462.707 212.998C462.64 216.66 461.498 220.221 459.422 223.238C457.404 225.954 454.749 228.134 451.691 229.583C449.779 230.499 447.77 231.197 445.702 231.664Z"
            fill="white"
          />
          <path
            d="M439.232 232H440.102C440.199 231.889 440.293 231.777 440.385 231.664C442.365 229.258 443.706 226.392 444.284 223.33C444.987 219.509 444.44 215.563 442.726 212.077C442.247 211.099 441.683 210.166 441.041 209.288C440.813 209.336 440.588 209.392 440.367 209.459C442.056 211.698 443.193 214.306 443.684 217.068C444.121 219.657 443.976 222.31 443.258 224.836C442.539 227.361 441.267 229.694 439.533 231.664C439.435 231.777 439.335 231.889 439.232 232Z"
            fill="white"
          />
          <path
            d="M258.421 135.137H197.405L196.758 116.371C196.73 115.557 196.879 114.747 197.194 113.997C197.509 113.246 197.982 112.573 198.582 112.023C199.182 111.473 199.894 111.06 200.669 110.811C201.444 110.563 202.264 110.486 203.071 110.585L238.578 114.934H238.583C243.847 114.948 248.891 117.049 252.609 120.776C256.327 124.504 258.417 129.553 258.421 134.819V135.137Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M231.226 68.5698H188.67C188.104 68.5697 187.544 68.6811 187.021 68.8977C186.498 69.1142 186.023 69.4318 185.623 69.8321C185.223 70.2324 184.905 70.7077 184.689 71.2308C184.472 71.7539 184.361 72.3145 184.361 72.8807V115.45C184.361 116.016 184.472 116.577 184.689 117.1C184.905 117.623 185.223 118.098 185.623 118.499C186.023 118.899 186.498 119.217 187.021 119.433C187.544 119.65 188.104 119.761 188.67 119.761H231.226C231.792 119.761 232.352 119.65 232.875 119.433C233.398 119.217 233.873 118.899 234.273 118.499C234.674 118.098 234.991 117.623 235.207 117.1C235.424 116.577 235.535 116.016 235.535 115.45V72.8807C235.535 72.3145 235.424 71.7539 235.207 71.2308C234.991 70.7077 234.674 70.2324 234.273 69.8321C233.873 69.4318 233.398 69.1142 232.875 68.8977C232.352 68.6811 231.792 68.5697 231.226 68.5698V68.5698Z"
            fill="#CCCCCC"
          />
          <path
            d="M221.642 100.402L201.549 104.316C200.358 104.547 199.123 104.295 198.116 103.617C197.11 102.938 196.413 101.887 196.18 100.696L194.635 92.7619C194.405 91.5697 194.656 90.3347 195.335 89.3277C196.013 88.3207 197.063 87.6241 198.254 87.3906L218.347 83.4767C219.539 83.2459 220.773 83.4974 221.78 84.1761C222.787 84.8549 223.483 85.9053 223.716 87.097L225.261 95.0307C225.491 96.2229 225.24 97.458 224.562 98.465C223.883 99.4719 222.833 100.169 221.642 100.402Z"
            fill="#3F3D56"
          />
          <path
            d="M217.622 91.0286L202.288 94.0156C202.008 94.0703 201.717 94.0112 201.48 93.8515C201.243 93.6917 201.079 93.4443 201.025 93.1638C200.97 92.8832 201.029 92.5924 201.189 92.3554C201.349 92.1184 201.596 91.9546 201.876 91.8999L217.21 88.9129C217.49 88.8583 217.781 88.9174 218.018 89.0771C218.255 89.2369 218.419 89.4842 218.473 89.7648C218.528 90.0453 218.469 90.3361 218.309 90.5731C218.15 90.8101 217.902 90.974 217.622 91.0286V91.0286Z"
            fill="white"
          />
          <path
            d="M214.641 95.4525L206.71 96.9975C206.43 97.0521 206.139 96.993 205.902 96.8333C205.665 96.6735 205.501 96.4262 205.447 96.1456C205.392 95.865 205.451 95.5743 205.611 95.3373C205.77 95.1002 206.018 94.9364 206.298 94.8818L214.229 93.3368C214.368 93.3097 214.511 93.3103 214.65 93.3385C214.788 93.3666 214.92 93.4218 215.037 93.5009C215.155 93.58 215.255 93.6815 215.333 93.7995C215.412 93.9175 215.466 94.0497 215.493 94.1886C215.52 94.3275 215.519 94.4704 215.491 94.6091C215.463 94.7478 215.408 94.8796 215.329 94.9969C215.25 95.1143 215.148 95.2149 215.03 95.2931C214.912 95.3712 214.78 95.4254 214.641 95.4525Z"
            fill="white"
          />
          <path
            d="M235.535 72.8807V78.5386H184.361V72.8807C184.361 72.3145 184.472 71.7539 184.689 71.2308C184.905 70.7077 185.223 70.2324 185.623 69.8321C186.023 69.4318 186.498 69.1142 187.021 68.8977C187.544 68.6811 188.104 68.5697 188.67 68.5698H231.226C231.792 68.5697 232.352 68.6811 232.875 68.8977C233.398 69.1142 233.873 69.4318 234.273 69.8321C234.673 70.2324 234.991 70.7077 235.207 71.2308C235.424 71.7539 235.535 72.3145 235.535 72.8807Z"
            fill="#B3B3B3"
          />
          <path
            d="M337.725 80.3108C337.43 79.3568 336.929 78.4792 336.258 77.7399C335.587 77.0005 334.762 76.4173 333.841 76.0315C332.92 75.6456 331.926 75.4663 330.928 75.5064C329.93 75.5465 328.954 75.8048 328.067 76.2633L269.521 55.251L266.942 69.8816L325.302 85.9355C326.171 87.375 327.537 88.4457 329.142 88.9447C330.747 89.4437 332.48 89.3364 334.011 88.6431C335.542 87.9498 336.766 86.7186 337.451 85.1829C338.135 83.6472 338.233 81.9137 337.725 80.3108V80.3108Z"
            fill="#FFB8B8"
          />
          <path
            opacity="0.2"
            d="M337.725 80.3108C337.43 79.3568 336.929 78.4792 336.258 77.7399C335.587 77.0005 334.762 76.4173 333.841 76.0315C332.92 75.6456 331.926 75.4663 330.928 75.5064C329.93 75.5465 328.954 75.8048 328.067 76.2633L269.521 55.251L266.942 69.8816L325.302 85.9355C326.171 87.375 327.537 88.4457 329.142 88.9447C330.747 89.4437 332.48 89.3364 334.011 88.6431C335.542 87.9498 336.766 86.7186 337.451 85.1829C338.135 83.6472 338.233 81.9137 337.725 80.3108V80.3108Z"
            fill="black"
          />
          <path
            d="M279.912 57.9168L274.384 72.365C274.226 72.776 273.982 73.1484 273.668 73.4566C273.354 73.7649 272.977 74.0017 272.563 74.1512C272.149 74.3006 271.708 74.359 271.27 74.3224C270.831 74.2859 270.406 74.1552 270.022 73.9394L256.586 66.3743C254.488 65.5572 252.8 63.9413 251.891 61.8809C250.982 59.8205 250.927 57.4838 251.738 55.383C252.549 53.2822 254.16 51.5888 256.217 50.6739C258.274 49.7591 260.61 49.6976 262.712 50.5028L277.716 53.8316C278.146 53.9271 278.55 54.114 278.901 54.3797C279.252 54.6454 279.541 54.9836 279.749 55.3713C279.958 55.759 280.08 56.187 280.108 56.6263C280.136 57.0656 280.07 57.5057 279.912 57.9168V57.9168Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M342.334 93.6885L321.355 103.227L324.11 113.6C329.428 133.629 330.84 154.497 328.267 175.061V175.061L343.605 178.265L356.319 145.833L351.869 108.95L342.334 93.6885Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M383.496 141.7C369.712 141.7 358.48 151.723 357.935 164.275H409.057C408.512 151.723 397.279 141.7 383.496 141.7Z"
            fill="#3F3D56"
          />
          <path
            d="M372.766 143.863C372.048 87.2044 339.791 92.4169 339.791 92.4169C339.791 92.4169 340.394 110.053 340.684 110.601C361.24 149.506 327.315 195.117 284.132 186.726C283.318 186.568 282.535 186.407 281.785 186.244C276.313 185.102 271.482 181.917 268.275 177.338C265.067 172.759 263.723 167.129 264.517 161.595C268.771 127.423 247.613 128.028 247.613 128.028H216.676L200.609 113.564C200.042 113.053 199.359 112.689 198.619 112.504C197.879 112.319 197.104 112.318 196.364 112.501C195.623 112.684 194.938 113.046 194.37 113.555C193.802 114.064 193.366 114.705 193.103 115.421L191.036 121.033C191.036 121.033 160.522 124.213 163.065 154.101H172.498C172.476 154.95 172.51 155.8 172.6 156.644L243.005 156.072C247.132 156.038 250.775 159.383 250.792 163.512C250.796 164.517 250.598 165.512 250.208 166.439C249.819 167.366 249.247 168.204 248.526 168.904C247.805 169.605 246.951 170.152 246.014 170.515C245.076 170.877 244.076 171.046 243.072 171.013L231.721 170.634C228.224 186.214 236.806 196.707 246.263 203.304C255.166 209.426 265.73 212.672 276.533 212.605H307.37C347.419 212.605 354.412 178.901 354.412 178.901C375.39 169.998 372.766 143.863 372.766 143.863Z"
            fill="#3F3D56"
          />
          <path
            d="M381.589 231.046C401.337 231.046 417.347 215.031 417.347 195.276C417.347 175.521 401.337 159.506 381.589 159.506C361.84 159.506 345.83 175.521 345.83 195.276C345.83 215.031 361.84 231.046 381.589 231.046Z"
            fill="#3F3D56"
          />
          <path
            d="M381.589 204.043C386.429 204.043 390.353 200.118 390.353 195.276C390.353 190.434 386.429 186.509 381.589 186.509C376.748 186.509 372.824 190.434 372.824 195.276C372.824 200.118 376.748 204.043 381.589 204.043Z"
            fill="white"
          />
          <path
            d="M201.048 231.046C223.781 231.046 242.21 212.611 242.21 189.871C242.21 167.13 223.781 148.695 201.048 148.695C178.315 148.695 159.886 167.13 159.886 189.871C159.886 212.611 178.315 231.046 201.048 231.046Z"
            fill="#3F3D56"
          />
          <path
            d="M201.048 199.963C206.62 199.963 211.137 195.445 211.137 189.871C211.137 184.297 206.62 179.779 201.048 179.779C195.476 179.779 190.959 184.297 190.959 189.871C190.959 195.445 195.476 199.963 201.048 199.963Z"
            fill="white"
          />
          <path
            d="M321.415 106.046L313.867 85.5379C313.474 84.4691 313.294 83.3332 313.34 82.1951C313.385 81.0571 313.654 79.9391 314.132 78.9051C314.609 77.8711 315.286 76.9413 316.122 76.1687C316.959 75.3962 317.939 74.796 319.008 74.4025L340.647 66.4337C342.527 65.7412 344.525 65.4259 346.527 65.5058C348.529 65.5857 350.495 66.0592 352.314 66.8993C354.133 67.7394 355.769 68.9297 357.128 70.4021C358.487 71.8745 359.542 73.6003 360.235 75.4809C360.859 77.1689 361.178 78.9545 361.176 80.7542C361.168 83.0394 360.651 85.2941 359.663 87.3544C358.674 89.4147 357.24 91.2288 355.463 92.6649C354.179 93.695 352.736 94.5089 351.19 95.0744L321.415 106.046Z"
            fill="#3F3D56"
          />
          <path
            d="M360.858 80.754C360.857 82.9926 360.353 85.2024 359.385 87.2207C358.417 89.239 357.009 91.0145 355.264 92.4166C352.587 91.7104 350.097 90.4295 347.965 88.663C345.833 86.8965 344.111 84.6867 342.92 82.1874C341.728 79.688 341.095 76.959 341.064 74.1901C341.033 71.4212 341.605 68.6787 342.741 66.1534C344.921 65.6791 347.18 65.6993 349.352 66.2125C351.523 66.7258 353.553 67.719 355.29 69.1194C357.028 70.5197 358.43 72.2916 359.394 74.3049C360.357 76.3182 360.858 78.5218 360.858 80.754V80.754Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M168.468 158.234C173.384 158.234 177.368 153.394 177.368 147.423C177.368 141.453 173.384 136.613 168.468 136.613C163.553 136.613 159.568 141.453 159.568 147.423C159.568 153.394 163.553 158.234 168.468 158.234Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M286.113 185.69H293.906L297.614 165.47L286.111 165.47L286.113 185.69Z"
            fill="#FFB8B8"
          />
          <path
            d="M284.125 183.145L299.473 183.144H299.474C300.758 183.144 302.03 183.397 303.217 183.889C304.403 184.38 305.482 185.101 306.39 186.01C307.298 186.918 308.019 187.997 308.51 189.184C309.002 190.371 309.255 191.643 309.255 192.928V193.246L284.125 193.247L284.125 183.145Z"
            fill="#2F2E41"
          />
          <path
            d="M295.159 177.391C295.068 177.391 294.977 177.387 294.886 177.378L284.1 176.593C283.664 176.552 283.243 176.412 282.869 176.183C282.496 175.954 282.18 175.642 281.946 175.272C281.712 174.901 281.566 174.482 281.52 174.046C281.473 173.611 281.528 173.17 281.678 172.759L296.113 140.318C296.216 140.029 296.258 139.721 296.237 139.415C296.215 139.108 296.131 138.81 295.988 138.538C295.846 138.263 295.648 138.021 295.405 137.828C295.163 137.635 294.883 137.496 294.583 137.419C287.795 135.644 270.38 130.918 255.305 125.389C248.846 123.019 244.778 119.596 243.214 115.213C241.153 109.434 244.203 104.164 244.333 103.943L244.436 103.77L258.621 105.06L274 106.368L307.701 124.446C310.525 125.974 312.67 128.509 313.709 131.548C314.747 134.586 314.604 137.905 313.307 140.842L297.77 175.695C297.545 176.2 297.179 176.629 296.715 176.93C296.252 177.231 295.712 177.391 295.159 177.391Z"
            fill="#2F2E41"
          />
          <path
            d="M261.088 40.8775C269.711 40.8775 276.701 33.8848 276.701 25.2588C276.701 16.6329 269.711 9.64014 261.088 9.64014C252.465 9.64014 245.474 16.6329 245.474 25.2588C245.474 33.8848 252.465 40.8775 261.088 40.8775Z"
            fill="#FFB8B8"
          />
          <path
            d="M281.663 187.598H289.457L293.165 167.378L281.662 167.379L281.663 187.598Z"
            fill="#FFB8B8"
          />
          <path
            d="M279.675 185.052L295.023 185.052H295.024C296.308 185.052 297.58 185.305 298.767 185.797C299.954 186.288 301.032 187.009 301.94 187.917C302.848 188.826 303.569 189.905 304.061 191.092C304.552 192.279 304.805 193.551 304.805 194.836V195.154L279.675 195.155L279.675 185.052Z"
            fill="#2F2E41"
          />
          <path
            d="M290.709 179.299C290.618 179.299 290.527 179.295 290.436 179.286L279.65 178.5C279.214 178.46 278.793 178.32 278.419 178.091C278.046 177.862 277.73 177.55 277.496 177.18C277.262 176.809 277.116 176.39 277.07 175.954C277.023 175.518 277.077 175.078 277.228 174.666L291.663 142.225C291.766 141.936 291.808 141.629 291.787 141.322C291.765 141.016 291.681 140.718 291.538 140.446C291.396 140.17 291.197 139.928 290.955 139.736C290.713 139.543 290.432 139.403 290.132 139.327C283.345 137.552 265.93 132.826 250.854 127.296C244.396 124.927 240.328 121.504 238.764 117.12C236.702 111.342 239.752 106.072 239.883 105.851L239.986 105.678L254.171 106.968L269.55 108.276L303.251 126.354C306.074 127.881 308.219 130.417 309.258 133.455C310.297 136.494 310.154 139.813 308.857 142.75L293.319 177.603C293.095 178.108 292.728 178.537 292.265 178.838C291.802 179.139 291.261 179.299 290.709 179.299Z"
            fill="#2F2E41"
          />
          <path
            d="M265.903 49.734L249.375 44.0107C249.375 44.0107 238.999 51.9851 243.984 71.7675C246.586 81.9759 245.828 92.7517 241.822 102.495C241.34 103.701 240.784 104.875 240.157 106.012C240.157 106.012 258.593 128.269 275.757 111.736L269.082 79.6219C269.082 79.6219 279.571 58.3188 265.903 49.734Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M263.026 41.4085C260.804 38.0812 259.05 33.4675 261.497 30.3017C263.912 27.1767 268.675 27.6371 272.374 26.2543C277.526 24.3284 280.514 18.2741 279.582 12.852C278.65 7.43001 274.299 2.93532 269.116 1.09624C263.932 -0.742844 258.087 -0.144732 253.011 1.97308C246.763 4.58014 241.402 9.62332 239.155 16.0117C236.907 22.4001 238.109 30.0705 242.771 34.9808C247.771 40.2451 255.616 41.648 262.874 41.5629"
            fill="#2F2E41"
          />
          <path
            d="M248.502 13.0259C245.702 15.3062 241.43 14.2933 238.424 12.2921C235.418 10.2908 232.979 7.44117 229.727 5.87211C223.993 3.10592 217.061 4.9448 211.481 8.00885C205.901 11.0729 200.954 15.306 195.06 17.7105C189.166 20.115 181.644 20.2338 177.181 15.6936C176.082 18.8769 175.993 22.3216 176.924 25.5578C177.855 28.794 179.761 31.6641 182.383 33.7764C185.005 35.8887 188.214 37.1404 191.574 37.3607C194.933 37.5809 198.278 36.7591 201.153 35.0072C207.612 31.0719 211.179 22.8208 218.423 20.6487C222.431 19.4468 226.736 20.4148 230.787 21.4633C234.838 22.5119 239.062 23.6448 243.151 22.7522C247.239 21.8596 251.063 18.1843 250.543 14.0308L248.502 13.0259Z"
            fill="#2F2E41"
          />
          <path
            d="M336.948 79.6744C336.538 78.7642 335.932 77.9551 335.175 77.3043C334.418 76.6534 333.527 76.1765 332.566 75.9072C331.604 75.6379 330.595 75.5828 329.61 75.7457C328.625 75.9085 327.688 76.2855 326.864 76.85L266.174 63.2241L265.419 78.0612L325.314 86.7895C326.353 88.1108 327.841 89.0046 329.496 89.3017C331.15 89.5988 332.856 89.2785 334.29 88.4015C335.724 87.5244 336.787 86.1516 337.277 84.5432C337.767 82.9347 337.65 81.2024 336.948 79.6744V79.6744Z"
            fill="#FFB8B8"
          />
          <path
            d="M276.815 64.5873L273.111 79.6074C273.005 80.0347 272.809 80.4344 272.535 80.779C272.261 81.1237 271.917 81.4053 271.524 81.6046C271.132 81.804 270.701 81.9164 270.262 81.9343C269.822 81.9521 269.384 81.875 268.977 81.7081L254.71 75.8592C252.526 75.3086 250.649 73.9137 249.492 71.9807C248.335 70.0477 247.992 67.7345 248.538 65.5486C249.084 63.3628 250.474 61.4828 252.404 60.3212C254.334 59.1597 256.646 58.8114 258.832 59.3528L274.132 60.8043C274.57 60.846 274.994 60.9817 275.375 61.202C275.756 61.4224 276.085 61.7223 276.339 62.0813C276.594 62.4403 276.768 62.85 276.85 63.2824C276.932 63.7149 276.92 64.1599 276.815 64.5873V64.5873Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M329.937 91.4629C332.395 91.4629 334.387 89.4699 334.387 87.0115C334.387 84.553 332.395 82.5601 329.937 82.5601C327.48 82.5601 325.487 84.553 325.487 87.0115C325.487 89.4699 327.48 91.4629 329.937 91.4629Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M527 231.364C526.999 231.532 526.932 231.694 526.813 231.813C526.694 231.932 526.533 231.999 526.364 232H0.635706C0.467106 232 0.305411 231.933 0.186193 231.814C0.0669755 231.694 0 231.533 0 231.364C0 231.195 0.0669755 231.034 0.186193 230.914C0.305411 230.795 0.467106 230.728 0.635706 230.728H526.364C526.533 230.729 526.694 230.796 526.813 230.915C526.932 231.034 526.999 231.195 527 231.364V231.364Z"
            fill="#3F3D56"
          />
        </g>
        <defs>
          <clipPath id="clip0_336_6708">
            <rect width="527" height="232" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
