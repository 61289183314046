import React, { FC, ReactNode, useContext, useState } from "react";
import { Collapse, Grid, Box } from "@mui/material";
import { ModalContext, ModalTypes } from "context/ModalContext";
import { ProfileSectionCard } from "./ProfileSectionCard";
import { BreakpointsContext } from "context/BreakpointContext";
import { WIcon, WIconTypes } from "components";
import { useHistory } from "react-router-dom";
import { ProfileContactDetailsBody } from "components/modals/Profile/ProfileContactDetails/ProfileContactDetailsBody";
import { ProfileCompanyDetailsBody } from "components/modals/Profile/ProfileCompanyDetails/ProfileCompanyDetailsBody";
import { INTEGRITY_LINK } from "views/Enums";

interface ProfileSettingsSectionsProps {
  showSection: string;
}

type BaseTemplate = {
  title: string;
  subTitle?: string;
  section: string;
  icon?: ReactNode;
  link?: string;
};

type SettingsTemplate = BaseTemplate & {
  modalName: ModalTypes;
};

type SettingsTemplateDT = BaseTemplate & {
  expandableContent?: ReactNode;
  onClickAction?: () => void;
};

export enum SECTIONS {
  MYDETAILS = "MYDETAILS",
  SETTINGS = "SETTINGS",
  ABOUT = "ABOUT",
}

export const ProfileSettingsSections: FC<ProfileSettingsSectionsProps> = ({
  showSection,
}): JSX.Element => {
  const { isDesktop } = useContext(BreakpointsContext);
  const { showModal } = useContext(ModalContext);

  const [expanded, setExpandedSection] = useState<string[]>([]);

  const history = useHistory();

  const mobileSections: SettingsTemplate[] = [
    {
      title: "profileImage",
      section: SECTIONS.MYDETAILS,
      icon: <WIcon icon={WIconTypes.edit} />,
      modalName: "PROFILE_IMAGE_OPTIONS_MODAL",
    },
    {
      title: "contactDetails",
      subTitle: "contactDetailsSubTitle",
      section: SECTIONS.MYDETAILS,
      modalName: "PROFILE_CONTACTDETAILS",
    },
    {
      title: "companyDetails",
      subTitle: "companyDetailsSubTitle",
      section: SECTIONS.MYDETAILS,
      modalName: "PROFILE_COMPANY",
    },
    {
      title: "socialIntegration",
      subTitle: "socialIntegrationSubTitle",
      section: SECTIONS.MYDETAILS,
      icon: <WIcon icon={WIconTypes.edit} />,
      modalName: "PROFILE_SOCIAL",
    },
    {
      title: "subscriptions",
      subTitle: "subscriptionsSubtitle",
      section: SECTIONS.SETTINGS,
      modalName: "PROFILE_SUBSCRIPTIONS",
    },
    {
      title: "languages",
      subTitle: "languagesSubTitle",
      section: SECTIONS.SETTINGS,
      icon: <WIcon icon={WIconTypes.edit} />,
      modalName: "PROFILE_LANGUAGE",
    },
    {
      title: "integrity",
      section: SECTIONS.ABOUT,
      modalName: "PROFILE_GDPR_MODAL",
      link: INTEGRITY_LINK,
    },
    {
      title: "wiseGroup",
      section: SECTIONS.ABOUT,
      modalName: "PROFILE_ABOUT",
    },
  ];

  const desktopSections: SettingsTemplateDT[] = [
    {
      title: "contactDetails",
      subTitle: "contactDetailsSubTitle",
      section: SECTIONS.MYDETAILS,
      expandableContent: (
        <ProfileContactDetailsBody onSubmit={() => handleExpand("contactDetails")} />
      ),
    },
    {
      title: "companyDetails",
      subTitle: "companyDetailsSubTitle",
      section: SECTIONS.MYDETAILS,
      expandableContent: (
        <ProfileCompanyDetailsBody onSubmit={() => handleExpand("companyDetails")} />
      ),
    },
    {
      title: "socialIntegration",
      subTitle: "socialIntegrationSubTitle",
      section: SECTIONS.MYDETAILS,
      icon: <WIcon icon={WIconTypes.edit} />,
      onClickAction: () => showModal("PROFILE_SOCIAL"),
    },
    {
      title: "subscriptions",
      subTitle: "subscriptionsSubtitle",
      section: SECTIONS.SETTINGS,
      icon: <WIcon icon={WIconTypes.edit} />,
      onClickAction: () => showModal("PROFILE_SUBSCRIPTIONS"),
    },
    {
      title: "languages",
      subTitle: "languagesSubTitle",
      section: SECTIONS.SETTINGS,
      icon: <WIcon icon={WIconTypes.edit} />,
      onClickAction: () => showModal("PROFILE_LANGUAGE"),
    },
    {
      title: "integrity",
      section: SECTIONS.ABOUT,
      link: INTEGRITY_LINK,
    },
    {
      title: "wiseGroup",
      section: SECTIONS.ABOUT,
      onClickAction: () => history.push("/contactUs"),
    },
  ];

  const handleExpand = (title: string) => {
    if (expanded.includes(title)) {
      setExpandedSection(expanded.filter((x) => x !== title));
    } else {
      setExpandedSection([...expanded, title]);
    }
  };

  return (
    <>
      <Grid container direction="column" gap={isDesktop ? "4px" : "1px"}>
        {isDesktop
          ? desktopSections.map((section, index) => {
              if (section.expandableContent) {
                const isExpanded = expanded.includes(section.title);
                return (
                  section.section === showSection && (
                    <Box key={`${section.title}${index}`}>
                      <ProfileSectionCard
                        title={section.title}
                        subTitle={section.subTitle}
                        key={`${section.title}${index}`}
                        onClick={() => handleExpand(section.title)}
                        icons={
                          <WIcon icon={isExpanded ? WIconTypes.arrowUp : WIconTypes.arrowDown} />
                        }
                      />
                      <Collapse in={isExpanded}>{section.expandableContent}</Collapse>
                    </Box>
                  )
                );
              } else {
                return (
                  section.section === showSection && (
                    <ProfileSectionCard
                      title={section.title}
                      subTitle={section.subTitle}
                      key={`${section.title}${index}`}
                      icons={section.icon || <WIcon icon={WIconTypes.arrowRight} />}
                      onClick={() => {
                        if (section.link) {
                          window.open(section.link, "_blank");
                        } else if (section.onClickAction) {
                          section.onClickAction();
                        }
                      }}
                    />
                  )
                );
              }
            })
          : mobileSections.map((section, index) => {
              return (
                section.section === showSection && (
                  <ProfileSectionCard
                    title={section.title}
                    subTitle={section.subTitle}
                    key={`${section.title}${index}`}
                    isSmall
                    icons={section.icon ? section.icon : <WIcon icon={WIconTypes.arrowRight} />}
                    onClick={() => {
                      if (section.link) {
                        window.open(section.link, "_blank");
                      } else {
                        showModal(section.modalName);
                      }
                    }}
                  />
                )
              );
            })}
      </Grid>
    </>
  );
};
