import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const ChatUsSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 942 688"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_342_6381)">
          <path
            d="M848.739 242H847.739C836.869 241.988 826.448 237.664 818.761 229.978C811.075 222.291 806.752 211.87 806.739 201V187H684.739V185H808.739V201C808.751 211.167 812.728 220.928 819.824 228.208C826.92 235.489 836.576 239.715 846.739 239.987V185H875.739C879.716 184.996 883.529 183.414 886.341 180.602C889.153 177.79 890.735 173.977 890.739 170V17C890.735 13.0231 889.153 9.21044 886.341 6.39837C883.529 3.5863 879.716 2.0045 875.739 2H493.739C489.762 2.0045 485.95 3.5863 483.138 6.39837C480.326 9.21044 478.744 13.0231 478.739 17V128H476.739V17C476.744 12.4929 478.537 8.17181 481.724 4.98479C484.911 1.79777 489.232 0.00507387 493.739 0H875.739C880.246 0.00507387 884.567 1.79777 887.754 4.98479C890.941 8.17181 892.734 12.4929 892.739 17V170C892.734 174.507 890.941 178.828 887.754 182.015C884.567 185.202 880.246 186.995 875.739 187H848.739V242Z"
            fill="#CCCCCC"
          />
          <path
            d="M812.719 50.51C812.593 47.2836 811.224 44.2312 808.897 41.9923C806.571 39.7534 803.468 38.5019 800.239 38.5H569.239C565.924 38.5 562.745 39.817 560.4 42.1612C558.056 44.5054 556.739 47.6848 556.739 51C556.739 54.3152 558.056 57.4946 560.4 59.8388C562.745 62.183 565.924 63.5 569.239 63.5H800.239C803.553 63.4954 806.73 62.177 809.073 59.8338C811.416 57.4906 812.735 54.3138 812.739 51C812.743 50.8364 812.736 50.6728 812.719 50.51V50.51Z"
            fill="#CCCCCC"
          />
          <path
            d="M812.719 92.51C812.593 89.2836 811.224 86.2312 808.897 83.9923C806.571 81.7534 803.468 80.5019 800.239 80.5H569.239C565.924 80.5 562.745 81.817 560.4 84.1612C558.056 86.5054 556.739 89.6848 556.739 93C556.739 96.3152 558.056 99.4946 560.4 101.839C562.745 104.183 565.924 105.5 569.239 105.5H800.239C803.553 105.495 806.73 104.177 809.073 101.834C811.416 99.4906 812.735 96.3138 812.739 93C812.743 92.8364 812.736 92.6728 812.719 92.51V92.51Z"
            fill="#CCCCCC"
          />
          <path
            d="M812.719 134.51C812.593 131.284 811.224 128.231 808.897 125.992C806.571 123.753 803.468 122.502 800.239 122.5H569.239C567.407 122.5 565.597 122.903 563.938 123.681C562.279 124.459 560.811 125.592 559.639 127C559.125 127.623 558.67 128.293 558.279 129H668.739C672.716 129.005 676.529 130.587 679.341 133.399C682.153 136.211 683.734 140.023 683.739 144V147.5H800.239C803.553 147.495 806.73 146.177 809.073 143.834C811.416 141.491 812.735 138.314 812.739 135C812.743 134.836 812.736 134.673 812.719 134.51Z"
            fill="#CCCCCC"
          />
          <path
            d="M24.5177 684.704C25.0617 685.151 25.6059 685.579 26.1628 686H163.567C164.59 685.579 165.609 685.147 166.624 684.704C181.13 678.392 194.631 669.986 206.698 659.755C214.71 652.967 222.081 645.959 227.424 639.391C234.879 630.226 241.745 620.238 244.938 608.858C248.125 597.478 247.173 584.433 240.054 574.996C237.85 572.093 235.14 569.612 232.055 567.671C231.543 567.347 231.025 567.036 230.494 566.744C221.638 561.978 211.308 560.735 201.574 563.266C187.81 566.822 176.372 576.751 168.211 588.397C160.05 600.036 154.752 613.405 149.531 626.625C144.233 603.029 128.189 581.894 106.517 571.149C84.8513 560.403 57.9327 560.533 36.727 572.172C30.0547 575.857 24.0275 580.604 18.8825 586.227C18.3711 586.778 17.8721 587.341 17.38 587.911C7.0363 599.887 0.565589 615.225 0.0345786 631.042C-0.204162 641.255 1.8748 651.39 6.11528 660.684C10.3558 669.978 16.6475 678.191 24.5177 684.704V684.704Z"
            fill="#F2F2F2"
          />
          <path
            d="M158.074 686H160.017C160.043 685.566 160.076 685.139 160.114 684.705C160.794 676.233 162.215 667.837 164.363 659.613C169.292 640.839 177.709 623.159 189.177 607.499C200.612 591.788 215.027 578.482 231.602 568.338C231.723 568.269 231.827 568.173 231.905 568.057C231.984 567.942 232.035 567.81 232.055 567.671C232.087 567.49 232.066 567.304 231.996 567.135C231.926 566.965 231.809 566.818 231.659 566.713C231.509 566.607 231.332 566.546 231.149 566.536C230.965 566.527 230.783 566.57 230.623 566.661C230.579 566.687 230.536 566.715 230.494 566.745C221.104 572.469 212.387 579.23 204.508 586.902C190.414 600.65 179.05 616.941 171.015 634.916C163.927 650.658 159.585 667.498 158.178 684.705C158.139 685.139 158.106 685.573 158.074 686Z"
            fill="white"
          />
          <path
            d="M17.3796 587.911C17.3937 587.919 17.4091 587.923 17.4249 587.924C18.8433 588.184 20.2684 588.456 21.6805 588.747C33.2713 591.127 44.6176 594.573 55.5749 599.039C66.5034 603.499 77.0157 608.918 86.9886 615.232C96.9743 621.542 106.391 628.711 115.131 636.658C123.872 644.593 131.889 653.289 139.09 662.644C144.472 669.653 149.365 677.024 153.734 684.704C153.98 685.132 154.22 685.566 154.46 686H156.681C156.448 685.566 156.209 685.132 155.963 684.704C150.676 675.293 144.614 666.339 137.84 657.935C130.349 648.634 122.045 640.018 113.026 632.189C104.065 624.404 94.4476 617.408 84.2811 611.281C74.0723 605.128 63.339 599.892 52.2067 595.632C41.4038 591.502 30.2504 588.355 18.8822 586.227C18.565 586.169 18.2541 586.111 17.9366 586.053C16.7256 585.832 16.2073 587.659 17.3796 587.911Z"
            fill="white"
          />
          <path
            d="M56.0184 655.525C69.5492 653.664 83.2903 653.973 96.7235 656.442C110.118 658.891 123.048 663.412 135.05 669.842C141.795 673.467 148.196 677.696 154.176 682.479C155.153 683.259 153.77 684.626 152.802 683.853C142.31 675.506 130.548 668.895 117.964 664.269C105.479 659.652 92.3569 656.989 79.06 656.377C71.5362 656.031 63.9966 656.373 56.5349 657.398C56.2868 657.458 56.0254 657.421 55.8035 657.295C55.5817 657.169 55.4159 656.963 55.3398 656.72C55.2729 656.471 55.3066 656.207 55.4336 655.983C55.5606 655.759 55.7708 655.595 56.0184 655.525H56.0184Z"
            fill="white"
          />
          <path
            d="M667.739 129H285.739C281.233 129.006 276.912 130.799 273.725 133.986C270.539 137.173 268.746 141.493 268.739 146V299C268.746 303.507 270.539 307.827 273.725 311.014C276.912 314.201 281.233 315.994 285.739 316H312.739V371H313.739C324.61 370.989 335.031 366.665 342.718 358.979C350.404 351.292 354.728 340.87 354.739 330V316H667.739C672.246 315.994 676.566 314.201 679.753 311.014C682.94 307.827 684.733 303.507 684.739 299V146C684.733 141.493 682.94 137.173 679.753 133.986C676.566 130.799 672.246 129.006 667.739 129V129ZM682.739 299C682.734 302.977 681.153 306.789 678.341 309.601C675.529 312.413 671.716 313.995 667.739 314H352.739V330C352.726 340.166 348.748 349.927 341.653 357.207C334.557 364.488 324.902 368.715 314.739 368.99V314H285.739C281.762 313.995 277.95 312.413 275.138 309.601C272.326 306.789 270.744 302.977 270.739 299V146C270.744 142.023 272.326 138.211 275.138 135.399C277.95 132.587 281.762 131.005 285.739 131H667.739C671.716 131.005 675.529 132.587 678.341 135.399C681.153 138.211 682.734 142.023 682.739 146V299Z"
            fill="#3F3D56"
          />
          <path
            d="M592.239 167.5H361.239C358.01 167.502 354.908 168.753 352.581 170.992C350.254 173.231 348.885 176.284 348.759 179.51C348.742 179.673 348.736 179.836 348.739 180C348.744 183.314 350.062 186.49 352.405 188.834C354.749 191.177 357.925 192.495 361.239 192.5H592.239C595.554 192.5 598.734 191.183 601.078 188.839C603.422 186.495 604.739 183.315 604.739 180C604.739 176.685 603.422 173.505 601.078 171.161C598.734 168.817 595.554 167.5 592.239 167.5Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M592.239 209.5H361.239C358.01 209.502 354.908 210.753 352.581 212.992C350.254 215.231 348.885 218.284 348.759 221.51C348.742 221.673 348.736 221.836 348.739 222C348.744 225.314 350.062 228.49 352.405 230.834C354.749 233.177 357.925 234.495 361.239 234.5H592.239C595.554 234.5 598.734 233.183 601.078 230.839C603.422 228.495 604.739 225.315 604.739 222C604.739 218.685 603.422 215.505 601.078 213.161C598.734 210.817 595.554 209.5 592.239 209.5Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M592.239 251.5H361.239C358.01 251.502 354.908 252.753 352.581 254.992C350.254 257.231 348.885 260.284 348.759 263.51C348.742 263.673 348.736 263.836 348.739 264C348.744 267.314 350.062 270.49 352.405 272.834C354.749 275.177 357.925 276.495 361.239 276.5H592.239C595.554 276.5 598.734 275.183 601.078 272.839C603.422 270.495 604.739 267.315 604.739 264C604.739 260.685 603.422 257.505 601.078 255.161C598.734 252.817 595.554 251.5 592.239 251.5Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M224.726 472.72C224.276 472.72 223.826 472.692 223.379 472.635C221.841 472.447 220.359 471.935 219.032 471.134C217.705 470.333 216.562 469.26 215.679 467.986C214.795 466.713 214.19 465.267 213.904 463.743C213.618 462.22 213.658 460.653 214.02 459.145C214.105 458.795 214.209 458.45 214.33 458.111L180.965 415.397C179.497 413.502 178.678 411.183 178.631 408.786C178.584 406.388 179.312 404.04 180.705 402.088L192.392 385.856L207.668 397.641L201.531 408.184L225.834 450.782C226.322 450.827 226.807 450.906 227.285 451.019C229.903 451.648 232.2 453.215 233.74 455.424C235.28 457.634 235.956 460.331 235.641 463.005C235.326 465.68 234.041 468.146 232.029 469.936C230.017 471.726 227.419 472.717 224.726 472.72L224.726 472.72Z"
            fill="#A0616A"
          />
          <path
            d="M282.467 675.118L294.727 675.117L300.559 627.829L282.465 627.83L282.467 675.118Z"
            fill="#A0616A"
          />
          <path
            d="M318.371 686.5L279.84 686.502L279.84 671.615L303.484 671.614C305.439 671.614 307.374 671.999 309.181 672.747C310.987 673.495 312.628 674.591 314.01 675.974C315.393 677.356 316.489 678.997 317.238 680.803C317.986 682.609 318.371 684.545 318.371 686.5V686.5Z"
            fill="#2F2E41"
          />
          <path
            d="M181.467 675.118L193.727 675.117L199.559 627.829L181.465 627.83L181.467 675.118Z"
            fill="#A0616A"
          />
          <path
            d="M217.371 686.5L178.84 686.502L178.84 671.615L202.484 671.614C204.439 671.614 206.374 671.999 208.181 672.747C209.987 673.495 211.628 674.591 213.01 675.974C214.393 677.356 215.489 678.997 216.238 680.803C216.986 682.609 217.371 684.545 217.371 686.5V686.5Z"
            fill="#2F2E41"
          />
          <path
            d="M188.992 388.159L216.516 398.619L223.236 379.952C224.129 377.473 224.116 374.759 223.201 372.289C222.285 369.819 220.526 367.751 218.234 366.453V366.453C215.916 365.139 213.204 364.697 210.588 365.208C207.973 365.719 205.626 367.148 203.972 369.237L188.992 388.159Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M280.288 636.945C279.189 636.944 278.121 636.577 277.254 635.903C276.386 635.228 275.767 634.284 275.495 633.219C261.77 580.708 247.833 554.388 232.889 523.953C232.515 523.194 231.909 522.574 231.159 522.184C230.409 521.793 229.553 521.652 228.717 521.781C227.881 521.91 227.108 522.302 226.51 522.9C225.913 523.499 225.522 524.273 225.394 525.109L206.87 632.158C206.685 633.352 206.072 634.438 205.146 635.214C204.22 635.99 203.043 636.403 201.835 636.376L174.333 635.706C173.666 635.692 173.009 635.543 172.401 635.268C171.793 634.993 171.247 634.597 170.796 634.105C170.32 633.633 169.955 633.061 169.73 632.429C169.504 631.798 169.423 631.124 169.492 630.458C181.948 548.313 191.755 473.957 210.615 451.273C211.138 450.778 211.737 450.37 212.39 450.066L211.818 449.979C211.654 449.953 211.219 449.886 211.213 449.449L211.208 449.025L211.638 448.944C212.325 448.92 213.01 449.023 213.66 449.248L256.704 455.873C257.896 456.056 258.981 456.667 259.757 457.591C260.532 458.516 260.945 459.69 260.918 460.897C263.258 471.844 266.844 488.368 267.165 489.182C290.131 532.843 299.042 567.225 308.305 625.934C308.497 627.179 308.21 628.45 307.503 629.493C306.796 630.535 305.721 631.271 304.493 631.553L281.409 636.818C281.041 636.903 280.665 636.945 280.288 636.945V636.945Z"
            fill="#2F2E41"
          />
          <path
            d="M256.132 463.032C255.745 463.032 255.361 462.986 254.985 462.897L212.606 452.816C211.957 452.664 211.345 452.381 210.809 451.984C210.274 451.587 209.825 451.084 209.491 450.507C209.146 449.91 208.925 449.249 208.842 448.565C208.759 447.88 208.815 447.186 209.007 446.523C213.577 430.68 207.584 411.041 200.428 392.177C199.518 386.661 200.409 380.999 202.971 376.029C205.532 371.06 209.627 367.048 214.647 364.589L214.763 364.533L237.012 365.656C240.665 366.614 244.054 368.388 246.924 370.844C249.793 373.3 252.069 376.374 253.579 379.836C262.155 399.907 264.61 425.661 261.085 458.571C260.955 459.794 260.378 460.927 259.463 461.751C258.549 462.574 257.362 463.03 256.132 463.032L256.132 463.032Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M261.833 417.217C259.571 417.222 257.364 416.52 255.52 415.21L239.105 403.511L250.816 388.179L261.388 394.265L303.87 369.758C303.912 369.269 303.989 368.784 304.099 368.306C304.597 366.188 305.711 364.265 307.302 362.781C308.892 361.297 310.887 360.317 313.035 359.967C315.182 359.616 317.385 359.911 319.365 360.812C321.345 361.714 323.013 363.182 324.158 365.031C325.304 366.881 325.875 369.029 325.8 371.203C325.725 373.377 325.007 375.481 323.736 377.247C322.466 379.013 320.7 380.363 318.663 381.125C316.625 381.888 314.407 382.029 312.29 381.531C311.939 381.447 311.593 381.345 311.253 381.226L268.579 414.892C266.656 416.403 264.279 417.222 261.833 417.217Z"
            fill="#A0616A"
          />
          <path
            d="M233.898 408.286L258.247 391.728L246.804 375.522C245.284 373.37 243.053 371.824 240.504 371.158C237.955 370.492 235.253 370.748 232.875 371.882V371.882C230.47 373.028 228.553 374.997 227.472 377.433C226.391 379.869 226.217 382.611 226.981 385.164L233.898 408.286Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M222.188 356.223C235.753 356.223 246.749 345.226 246.749 331.662C246.749 318.097 235.753 307.101 222.188 307.101C208.623 307.101 197.627 318.097 197.627 331.662C197.627 345.226 208.623 356.223 222.188 356.223Z"
            fill="#A0616A"
          />
          <path
            d="M224.438 356.79C220.752 356.774 217.908 352.893 218.073 349.21C218.238 345.527 220.712 342.287 223.692 340.117C226.672 337.947 230.167 336.625 233.49 335.03C236.813 333.434 240.107 331.444 242.159 328.382C243.399 326.377 244.139 324.104 244.317 321.754C244.495 319.404 244.106 317.046 243.182 314.877C241.273 310.54 237.995 306.949 233.849 304.653C227.692 301.048 220.402 299.896 213.433 301.425C206.463 302.954 200.326 307.052 196.242 312.903L192.034 324.361C187.746 329.517 187.066 337.175 189.702 343.341C192.338 349.507 197.95 354.147 204.283 356.352C210.708 358.417 217.587 358.623 224.124 356.947"
            fill="#2F2E41"
          />
          <path
            d="M197.9 320.017C196.217 317.422 193.922 315.282 191.217 313.784C188.512 312.285 185.48 311.476 182.388 311.426C179.296 311.377 176.239 312.088 173.487 313.499C170.735 314.909 168.373 316.975 166.608 319.514C163.653 323.765 162.58 329.004 160.549 333.765C156.869 342.292 150.178 349.164 141.752 353.068C149.129 354.366 156.479 350.613 162.377 345.995C168.275 341.378 173.462 335.741 180.067 332.209C185.264 329.441 191.102 328.1 196.985 328.324"
            fill="#2F2E41"
          />
          <path
            d="M404.633 688H23.6328C23.3676 688 23.1132 687.895 22.9257 687.707C22.7382 687.52 22.6328 687.265 22.6328 687C22.6328 686.735 22.7382 686.48 22.9257 686.293C23.1132 686.105 23.3676 686 23.6328 686H404.633C404.898 686 405.152 686.105 405.34 686.293C405.527 686.48 405.633 686.735 405.633 687C405.633 687.265 405.527 687.52 405.34 687.707C405.152 687.895 404.898 688 404.633 688Z"
            fill="#3F3D56"
          />
          <path
            d="M882.739 366C915.324 366 941.739 339.585 941.739 307C941.739 274.415 915.324 248 882.739 248C850.154 248 823.739 274.415 823.739 307C823.739 339.585 850.154 366 882.739 366Z"
            fill="#E6E6E6"
          />
          <path
            d="M888.366 349.052C885.963 348.985 882.972 348.901 880.67 347.219C879.984 346.698 879.42 346.032 879.019 345.27C878.617 344.508 878.387 343.667 878.344 342.806C878.303 342.194 878.403 341.581 878.637 341.015C878.872 340.448 879.234 339.944 879.696 339.541C880.383 339.028 881.186 338.696 882.034 338.574C882.882 338.452 883.747 338.544 884.55 338.842L882.588 324.506L884.028 324.309L886.334 341.162L885.132 340.611C883.737 339.971 881.824 339.646 880.634 340.651C880.342 340.913 880.115 341.24 879.97 341.605C879.824 341.969 879.765 342.363 879.797 342.754C879.832 343.395 880.005 344.021 880.303 344.588C880.602 345.156 881.019 345.653 881.527 346.045C883.319 347.355 885.703 347.524 888.407 347.6L888.366 349.052Z"
            fill="#3F3D56"
          />
          <path d="M875.466 325.742H867.638V327.196H875.466V325.742Z" fill="#3F3D56" />
          <path d="M900.176 325.742H892.348V327.196H900.176V325.742Z" fill="#3F3D56" />
          <path
            d="M854.037 305.671C847.733 304.952 842.85 298.912 842.314 292.783C841.676 285.492 845.922 278.567 851.747 274.476C857.839 270.197 865.429 268.411 872.773 268.017C887.098 267.304 901.177 271.945 912.271 281.036C917.773 285.606 922.912 291.428 924.86 298.46C926.552 304.567 925.175 311.485 920.264 315.762C919.049 316.81 917.63 317.595 916.096 318.067C914.563 318.539 912.948 318.688 911.354 318.505C907.543 318.11 904.026 316.106 900.983 313.893C895.062 309.588 890.201 303.591 882.988 301.341C877.698 299.691 870.835 301.143 867.602 305.926C866.869 307.021 866.394 308.268 866.213 309.574C866.146 309.852 866.187 310.146 866.329 310.395C866.47 310.644 866.701 310.829 866.974 310.915C867.253 310.99 867.55 310.952 867.801 310.81C868.052 310.667 868.237 310.431 868.315 310.153C869.163 304.129 876.571 301.881 881.769 303.257C889.348 305.263 894.436 312.026 900.654 316.323C903.873 318.723 907.665 320.237 911.652 320.713C915.109 320.984 918.539 319.93 921.247 317.765C926.56 313.57 928.591 306.355 927.417 299.859C926.056 292.337 920.993 285.89 915.438 280.899C904.176 270.887 889.556 265.486 874.49 265.772C866.805 265.948 858.787 267.534 852.167 271.596C845.746 275.536 840.782 282.127 840.134 289.788C839.563 296.532 843.181 303.585 849.391 306.54C850.856 307.233 852.426 307.676 854.037 307.851C855.429 308.01 855.417 305.828 854.037 305.671L854.037 305.671Z"
            fill="#3F3D56"
          />
        </g>
        <defs>
          <clipPath id="clip0_342_6381">
            <rect width="941.739" height="688" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
