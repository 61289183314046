import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import {
  Group,
  GroupUser,
  getGroup,
  getGroupList,
  updateGroup,
  deleteGroup,
} from "adapters/GroupsAdapter";
import { WTypography } from "components";
import { GroupCard, GroupCardHeight } from "components/Groups/GroupCard";
import { LocaleContext } from "context/LocaleContext";
import { usePagination } from "hooks/usePagination";
import { useHistory, useParams } from "react-router-dom";
import { LoaderContext } from "context/LoaderContext";
import { defaultStyles } from "styles/colors";
import { GroupDrawer, GroupDrawerWidth } from "components/Groups/GroupDrawer";
import styled from "@emotion/styled";
import { ModalContext } from "context/ModalContext";

export const SelectedGroups: FC = () => {
  const history = useHistory();
  const { localize } = useContext(LocaleContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(undefined);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [groupCount, setGroupCount] = useState(0);
  const { hideDialog } = useContext(ModalContext);
  const { groupId } = useParams<{ groupId: string }>();

  const { pageResults, count, updatePage } = usePagination<Group>(
    () => getGroupList({ id: JSON.parse(localStorage.getItem("selectedGroups") || "[]").join() }),
    "error.fetchingGroupList",
    "GET_GROUP_LIST"
  );

  useEffect(() => {
    setSelectedGroups(JSON.parse(localStorage.getItem("selectedGroups") || "[]"));

    if (selectedGroups.length === 0) {
      setGroups([]);
      setGroupCount(0);
    } else {
      setGroups(pageResults);
      setGroupCount(count);
    }
  }, [pageResults]);

  useEffect(() => {
    if (!groupId) {
      return;
    }

    const group = groups.find((g) => g.id === groupId);
    if (group) {
      setSelectedGroup(group);
    } else if (groups.length > 0 && !isLoading("GET_GROUP")) {
      (async () => {
        dispatchLoading({ payload: "GET_GROUP", type: "SET_LOADING" });
        const g = await getGroup(groupId);
        dispatchLoading({ payload: "GET_GROUP", type: "STOP_LOADING" });
        if (!g) {
          return;
        }
        setGroups((list) => [g.data, ...list]);
      })();
    }
  }, [groupId, groups]);

  const onChangeUsers = useCallback(
    (newUsers: GroupUser[]) => {
      const index = groups.findIndex((g) => g.id === groupId);
      const newGroups = [
        ...groups.slice(0, index),
        { ...groups[index], users: newUsers },
        ...groups.slice(index + 1),
      ];
      setGroups(newGroups);
      updateGroup(groupId, {
        users: newUsers.map((u) => u.id),
      });
    },
    [groups, setGroups, groupId]
  );

  const handleRemoveFromSelectdGroups = useCallback(
    (groupId: string) => {
      let index = selectedGroups.findIndex((g) => g === groupId);
      const newSelectedGroups = [
        ...selectedGroups.slice(0, index),
        ...selectedGroups.slice(index + 1),
      ];

      index = groups.findIndex((g) => g.id === groupId);
      const newGroups = [...groups.slice(0, index), ...groups.slice(index + 1)];
      setGroups(newGroups);

      setSelectedGroups(newSelectedGroups);
      localStorage.setItem("selectedGroups", JSON.stringify(newSelectedGroups));
      setGroupCount(newGroups.length);
    },
    [groups, selectedGroups]
  );

  const handleRemoveGroup = useCallback(
    async (removedGroupId: string): Promise<void> => {
      await deleteGroup(removedGroupId);
      hideDialog();
      updatePage();

      if (groupId === removedGroupId) {
        history.push("/groups/");
      }
    },
    [groupId, hideDialog, history, updatePage]
  );

  return (
    <>
      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        gap="20px"
        padding="30px"
        height="100vh"
        width={`calc(100% - ${groupId ? GroupDrawerWidth : 0}) !important`}
        overflow="auto"
      >
        <Grid item>
          <WTypography variant="h1" fontSize="2rem" lineClamps={1}>
            {localize("groups.groups")} ({groupCount})
          </WTypography>
        </Grid>
        <CustomGrid>
          {groups.map((group) => (
            <Grid key={group.id} item>
              <GroupCard
                isSelected={groupId === group.id}
                isInSelectedGroups={selectedGroups.includes(group.id)}
                isAllGroups={false}
                group={group}
                onClick={() => {
                  if (groupId === group.id) {
                    history.push("/groups/");
                  } else {
                    history.push(`/groups/${group.id}`);
                  }
                }}
                onToggleSelectedGroups={handleRemoveFromSelectdGroups}
              />
            </Grid>
          ))}

          {isLoading("GET_GROUP_LIST")
            ? Array(10)
                .fill(1)
                .map((_, index) => (
                  <Grid key={`group_skeleton_${index}`} item>
                    <Skeleton
                      key={`group_skeleton_${index}`}
                      variant="rectangular"
                      width="100%"
                      height={GroupCardHeight}
                      sx={{ borderRadius: defaultStyles.borderRadius }}
                    />
                  </Grid>
                ))
            : null}
        </CustomGrid>
      </Grid>
      <GroupDrawer
        isOpen={!!groupId}
        group={selectedGroup}
        onChangeUsers={onChangeUsers}
        onRemoveGroup={handleRemoveGroup}
      />
    </>
  );
};

const CustomGrid = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  align-content: start;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;
