import { colors, defaultStyles } from "./colors";
import { createTheme } from "@mui/material/styles";

const BaseTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: defaultStyles.borderRadius,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          ":-webkit-autofill": {
            borderRadius: "inherit",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      light: colors.main,
      main: colors.mainDark,
    },
    secondary: {
      main: colors.white,
    },
    success: {
      main: colors.success,
      light: colors.buttonBackground,
    },
    error: {
      main: colors.error,
      light: colors.error,
    },
    text: {
      primary: colors.textDark,
    },
    background: {
      default: colors.siteBackground,
      paper: colors.siteBackground,
    },
    common: {
      black: colors.textDark,
      white: colors.white,
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default BaseTheme;
