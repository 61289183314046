import { Grid, Link, Typography } from "@mui/material";
import React, { Component, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  // TODO: Add log to backend if this error crashing would occur.

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Grid container padding="2rem" direction="column" textAlign="center">
          <h1> Ooops</h1>
          <Typography>
            Ett oväntat fel inträffade, Vänligen starta om sidan eller klicka på knappen för att gå
            till startsidan.
          </Typography>
          <Link href="/">Gå till startsidan</Link>
        </Grid>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
