import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";
import React, { FC } from "react";
import { colors } from "styles/colors";

export const CompleteProfileSVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 780 580"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_341_6223)">
          <path d="M703.876 19.2891H0.299805V353.148H703.876V19.2891Z" fill="#E6E6E6" />
          <path d="M683.758 47.918H20.4189V326.467H683.758V47.918Z" fill="white" />
          <path d="M703.576 0H0V29.89H703.576V0Z" fill={colors.mainSVGColor} />
          <path
            d="M22.2119 23.1161C25.2715 23.1161 27.7519 20.6358 27.7519 17.5761C27.7519 14.5165 25.2715 12.0361 22.2119 12.0361C19.1522 12.0361 16.6719 14.5165 16.6719 17.5761C16.6719 20.6358 19.1522 23.1161 22.2119 23.1161Z"
            fill="white"
          />
          <path
            d="M43.2402 23.1161C46.2999 23.1161 48.7802 20.6358 48.7802 17.5761C48.7802 14.5165 46.2999 12.0361 43.2402 12.0361C40.1805 12.0361 37.7002 14.5165 37.7002 17.5761C37.7002 20.6358 40.1805 23.1161 43.2402 23.1161Z"
            fill="white"
          />
          <path
            d="M64.269 23.1161C67.3287 23.1161 69.809 20.6358 69.809 17.5761C69.809 14.5165 67.3287 12.0361 64.269 12.0361C61.2093 12.0361 58.729 14.5165 58.729 17.5761C58.729 20.6358 61.2093 23.1161 64.269 23.1161Z"
            fill="white"
          />
          <path d="M496.078 90.2676H402.655V97.2895H496.078V90.2676Z" fill="#E6E6E6" />
          <path d="M546.92 110.882H402.655V117.904H546.92V110.882Z" fill={colors.mainSVGColor} />
          <path d="M521.728 130.514H404.155V137.536H521.728V130.514Z" fill="#E6E6E6" />
          <path d="M471.292 152.055H402.655V159.077H471.292V152.055Z" fill="#E6E6E6" />
          <path d="M506.246 173.095H402.655V180.117H506.246V173.095Z" fill={colors.mainSVGColor} />
          <path d="M371.514 88.0591H360.074V99.4986H371.514V88.0591Z" fill="#E6E6E6" />
          <path d="M371.514 108.673H360.074V120.112H371.514V108.673Z" fill={colors.mainSVGColor} />
          <path d="M371.514 128.305H360.074V139.744H371.514V128.305Z" fill="#E6E6E6" />
          <path d="M371.514 149.846H360.074V161.285H371.514V149.846Z" fill="#E6E6E6" />
          <path d="M371.514 170.887H360.074V182.326H371.514V170.887Z" fill={colors.mainSVGColor} />
          <path d="M496.078 194.268H402.655V201.289H496.078V194.268Z" fill="#E6E6E6" />
          <path d="M546.92 214.882H402.655V221.904H546.92V214.882Z" fill={colors.mainSVGColor} />
          <path d="M521.728 234.514H404.155V241.536H521.728V234.514Z" fill="#E6E6E6" />
          <path d="M471.292 256.055H402.655V263.077H471.292V256.055Z" fill="#E6E6E6" />
          <path d="M506.246 277.095H402.655V284.117H506.246V277.095Z" fill="#E6E6E6" />
          <path d="M371.514 192.059H360.074V203.499H371.514V192.059Z" fill="#E6E6E6" />
          <path d="M371.514 212.673H360.074V224.112H371.514V212.673Z" fill={colors.mainSVGColor} />
          <path d="M371.514 232.305H360.074V243.744H371.514V232.305Z" fill="#E6E6E6" />
          <path d="M371.514 253.846H360.074V265.285H371.514V253.846Z" fill="#E6E6E6" />
          <path d="M371.514 274.887H360.074V286.326H371.514V274.887Z" fill="#E6E6E6" />
          <path d="M200.535 249.348H107.112V256.37H200.535V249.348Z" fill="#3F3D56" />
          <path d="M212.186 267.593H94.6123V274.615H212.186V267.593Z" fill="#3F3D56" />
          <path
            d="M214.544 160.77C214.54 171.323 211.8 181.695 206.589 190.873C201.379 200.051 193.878 207.721 184.818 213.133C175.758 218.546 165.449 221.516 154.898 221.754C144.347 221.992 133.915 219.49 124.62 214.491L124.608 214.479C115.114 209.364 107.145 201.824 101.514 192.627C95.8827 183.429 92.7904 172.903 92.552 162.121C92.3137 151.34 94.9377 140.687 100.157 131.25C105.376 121.813 113.004 113.928 122.263 108.398C131.522 102.869 142.081 99.8928 152.865 99.7733C163.648 99.6538 174.271 102.395 183.65 107.718C193.03 113.041 200.83 120.755 206.257 130.074C211.684 139.394 214.544 149.985 214.544 160.77V160.77Z"
            fill="#3F3D56"
          />
          <path
            d="M153.855 157.809C163.013 157.809 170.438 150.384 170.438 141.226C170.438 132.068 163.013 124.644 153.855 124.644C144.697 124.644 137.272 132.068 137.272 141.226C137.272 150.384 144.697 157.809 153.855 157.809Z"
            fill="white"
          />
          <path
            d="M182.17 192.072C163.632 198.579 143.418 198.501 124.931 191.85L124.919 191.842L137.273 167.456H170.438L182.17 192.072Z"
            fill="white"
          />
          <path
            d="M656.072 567.394H643.813L637.98 520.106L656.075 520.107L656.072 567.394Z"
            fill="#FFB7B7"
          />
          <path
            d="M659.199 579.278L619.668 579.277V578.777C619.669 574.696 621.29 570.783 624.175 567.897C627.061 565.012 630.974 563.391 635.055 563.391H635.056L659.2 563.392L659.199 579.278Z"
            fill="#2F2E41"
          />
          <path
            d="M686.016 567.394H698.276L704.108 520.106L686.014 520.107L686.016 567.394Z"
            fill="#FFB7B7"
          />
          <path
            d="M682.889 563.392L707.033 563.391H707.034C711.114 563.391 715.028 565.012 717.913 567.897C720.799 570.783 722.42 574.696 722.42 578.777V579.277L682.889 579.278L682.889 563.392Z"
            fill="#2F2E41"
          />
          <path
            d="M653.348 197.854C658.613 187.256 654.29 174.395 643.692 169.129C633.094 163.864 620.233 168.187 614.968 178.785C609.702 189.383 614.025 202.244 624.623 207.51C635.222 212.775 648.082 208.452 653.348 197.854Z"
            fill="#FFB7B7"
          />
          <path
            d="M618.379 335.731C618.379 335.731 611.612 363.926 614.996 377.459C618.379 390.993 628.529 483.472 628.529 483.472L635.296 556.779L662.363 559.035L658.98 473.322L662.363 398.888L681.536 471.067V557.035L710.242 558.163L710.858 446.255C710.858 446.255 713.114 358.287 698.453 347.009C683.791 335.731 681.536 333.476 681.536 333.476L618.379 335.731Z"
            fill="#2F2E41"
          />
          <path
            d="M659.24 211.673L684.919 225.207L688.302 342.498C688.302 342.498 673.641 333.475 651.085 344.753C628.529 356.031 613.868 348.137 613.868 348.137L627.401 297.386L621.762 229.718L629.828 222.398L659.24 211.673Z"
            fill="#CBCBCB"
          />
          <path
            d="M629.831 220.263L625.146 224.079C625.146 224.079 595.823 238.74 594.695 248.891L614.996 304.153C614.996 304.153 620.635 316.558 616.123 321.07C611.612 325.581 607.101 336.859 608.229 342.498C609.357 348.137 601.462 371.82 610.484 371.82C619.507 371.82 640.935 317.686 639.807 296.258C638.679 274.83 637.899 218.702 629.831 220.263Z"
            fill="#2F2E41"
          />
          <path
            d="M649.544 212.944L659.874 211.225C659.874 211.225 698.219 221.375 703.858 238.292L688.069 277.765C688.069 277.765 699.347 349.944 695.964 349.944H692.58C692.58 349.944 701.603 356.711 695.964 358.966C690.325 361.222 676.791 379.267 673.408 371.372C670.024 363.477 649.724 269.87 651.98 249.57C654.235 229.27 647.541 215.071 649.544 212.944Z"
            fill="#2F2E41"
          />
          <path
            d="M654.027 167.338L655.031 165.318L649.981 162.809C649.981 162.809 644.41 153.744 634.329 156.291C624.248 158.839 619.713 160.363 619.713 160.363L614.676 162.898L617.204 165.413L612.668 166.938L615.698 168.443L612.173 170.47L614.071 180.857C614.071 180.857 617.223 172.979 623.283 175.989C629.343 179 640.427 174.433 640.427 174.433L650.057 193.07C650.057 193.07 652.044 186.537 655.586 188.279C655.586 188.279 664.636 173.868 654.027 167.338Z"
            fill="#2F2E41"
          />
          <path
            d="M685.396 365.237C684.839 363.876 684.586 362.41 684.657 360.942C684.728 359.474 685.12 358.039 685.805 356.738C686.49 355.437 687.452 354.303 688.623 353.414C689.794 352.526 691.146 351.905 692.583 351.595L696.924 316.125L711.07 328.156L704.779 359.994C705.139 362.443 704.589 364.939 703.231 367.009C701.874 369.079 699.805 370.579 697.416 371.225C695.027 371.871 692.484 371.619 690.268 370.515C688.053 369.411 686.319 367.533 685.396 365.237V365.237Z"
            fill="#FFB7B7"
          />
          <path
            d="M624.894 362.796C625.304 361.384 625.399 359.9 625.173 358.447C624.947 356.995 624.405 355.609 623.586 354.389C622.767 353.168 621.69 352.142 620.431 351.383C619.172 350.623 617.762 350.149 616.3 349.993L608.223 315.184L595.433 328.646L605.064 359.638C604.966 362.111 605.778 364.535 607.347 366.449C608.916 368.363 611.132 369.636 613.576 370.025C616.021 370.414 618.523 369.893 620.609 368.561C622.695 367.229 624.219 365.178 624.894 362.796V362.796Z"
            fill="#FFB7B7"
          />
          <path
            d="M685.941 229.101C685.941 229.101 700.603 223.462 705.114 240.379C709.625 257.296 715.264 291.13 715.264 291.13C715.544 309.944 714.544 309.944 715.264 328.348L711.88 345.264L692.708 343.009L693.544 299.944L683.686 256.169L685.941 229.101Z"
            fill="#2F2E41"
          />
          <path
            d="M604.845 242.124L597.894 242.867C597.894 242.867 591.312 247.763 591.312 259.04C591.312 270.318 590.184 304.152 590.184 304.152C590.184 304.152 589.544 304.944 594.695 333.475C597.206 347.381 601.544 353.944 601.544 353.944L615.544 340.944L613.868 307.536L618.379 289.491L604.845 242.124Z"
            fill="#2F2E41"
          />
          <path
            d="M778.32 579.944H554.32C554.055 579.944 553.8 579.838 553.613 579.651C553.425 579.463 553.32 579.209 553.32 578.944C553.32 578.679 553.425 578.424 553.613 578.237C553.8 578.049 554.055 577.944 554.32 577.944H778.32C778.585 577.944 778.839 578.049 779.027 578.237C779.215 578.424 779.32 578.679 779.32 578.944C779.32 579.209 779.215 579.463 779.027 579.651C778.839 579.838 778.585 579.944 778.32 579.944Z"
            fill="#CBCBCB"
          />
          <path
            d="M312.32 579.944H193.32C193.055 579.944 192.8 579.838 192.613 579.651C192.425 579.463 192.32 579.209 192.32 578.944C192.32 578.679 192.425 578.424 192.613 578.237C192.8 578.049 193.055 577.944 193.32 577.944H312.32C312.585 577.944 312.839 578.049 313.027 578.237C313.214 578.424 313.32 578.679 313.32 578.944C313.32 579.209 313.214 579.463 313.027 579.651C312.839 579.838 312.585 579.944 312.32 579.944Z"
            fill="#CBCBCB"
          />
          <path
            d="M259.405 537.407C239.894 551.951 234.365 577.542 234.365 577.542C234.365 577.542 260.468 579.551 279.979 565.007C299.49 550.462 305.02 524.872 305.02 524.872C305.02 524.872 278.916 522.863 259.405 537.407Z"
            fill="#F2F2F2"
          />
          <path
            d="M267.533 544.236C258.156 566.692 234.671 578.264 234.671 578.264C234.671 578.264 226.39 553.427 235.767 530.971C245.144 508.515 268.629 496.943 268.629 496.943C268.629 496.943 276.91 521.779 267.533 544.236Z"
            fill="#F2F2F2"
          />
        </g>
        <defs>
          <clipPath id="clip0_341_6223">
            <rect width="779.32" height="579.944" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
