import { ModalContext } from "context/ModalContext";
import React, { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

export const JobView: FC = () => {
  const { showModal, hideModal } = React.useContext(ModalContext);

  const { jobId } = useParams<{ jobId: string }>();
  const history = useHistory();

  useEffect(() => {
    showModal("DETAILED_JOB_MODAL", {
      onClose: () => {
        history.push("/");
        hideModal();
      },
      jobId,
    });
  }, []);

  return <></>;
};
