import { Grid, CircularProgress } from "@mui/material";
import { getUser, updateProfile } from "adapters";
import { WTextField, WIcon, WIconTypes, WButton } from "components";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";

export type EditContactDialogState = {
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
};

interface ProfileDetailsBodyProps {
  onSubmit: () => void;
}

export const ProfileContactDetailsBody: FC<ProfileDetailsBodyProps> = ({ onSubmit }) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { dispatch, user } = useContext(UserContext);
  const { addMessage } = useContext(NotificationContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<EditContactDialogState>({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      email: user.email,
    },
  });

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = () => {
    dispatchLoading({ type: "SET_LOADING", payload: "GET_USER" });
    getUser(user.id)
      .then((res) => {
        reset(res.data);
      })
      .catch(() => {
        addMessage({ type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "GET_USER" });
      });
  };

  const dirtyValues = (
    dirtyFields: Record<string, unknown> | boolean,
    allValues: EditContactDialogState
  ): Partial<EditContactDialogState> => {
    if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
    );
  };

  const submitAndClose = (data: EditContactDialogState) => {
    const changedFields = dirtyValues(dirtyFields, data);
    if (Object.keys(changedFields).length) {
      submitForm(changedFields);
    } else {
      onSubmit();
    }
  };

  const submitForm = async (data: Partial<EditContactDialogState>) => {
    dispatchLoading({ type: "SET_LOADING", payload: "UPDATE_USER" });
    updateProfile(data, user.id)
      .then(() => {
        addMessage({ content: "success.updateSuccessful", type: "success" });
        dispatch({ type: "UPDATE_USER", user: { ...user, ...data } });
        onSubmit();
      })
      .catch(() => {
        addMessage({ type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "UPDATE_USER" });
      });
  };

  return (
    <form onSubmit={handleSubmit(submitAndClose)}>
      {isLoading("GET_USER") ? (
        <Grid container justifyContent="space-around" marginTop="2rem">
          <CircularProgress color="success" />
        </Grid>
      ) : (
        <>
          <Grid container padding="1rem" gap="1rem" height="100%">
            <Grid container direction="column" gap="1rem">
              <WTextField
                label={localize("form.firstnameField")}
                error={!!errors.first_name}
                helperText={errors?.first_name?.message}
                {...register("first_name", { required: localize("form.requiredFieldError") })}
              />
              <WTextField
                label={localize("form.lastnameField")}
                error={!!errors.last_name}
                helperText={errors?.last_name?.message}
                {...register("last_name", { required: localize("form.requiredFieldError") })}
              />
              <WTextField
                type="tel"
                variant="outlined"
                error={!!errors.phone}
                label={localize("form.phoneNumberField")}
                helperText={errors?.phone?.message}
                {...register("phone")}
              />
              <WTextField
                variant="outlined"
                label={localize("form.emailField")}
                disabled
                type="email"
                {...register("email")}
              />
            </Grid>
          </Grid>
          <Grid container padding="0 1rem" sx={{ backgroundColor: "background.default" }}>
            <WButton
              fullWidth
              variant="outlined"
              type="submit"
              sx={{ width: "max-content", marginLeft: "auto", marginBottom: ".5rem" }}
              startIcon={<WIcon icon={WIconTypes.check} />}
            >
              {localize("form.save")} {localize("common.contactDetails").toLowerCase()}
            </WButton>
          </Grid>
        </>
      )}
    </form>
  );
};
