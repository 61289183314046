import { Collapse, Grid } from "@mui/material";
import { Job, getJobList } from "adapters";
import { AdvancedSearch, WButton, WIcon, WIconTypes } from "components";
import { FiltersDialog, JobFilters, defaultFilterState } from "components/dialogs/FiltersDialog";
import { SuperHeader } from "components/headers/SuperHeader";
import { JobList } from "components/JobList";
import { StickyWrapper } from "components/StickyWrapper";
import { BreakpointsContext } from "context/BreakpointContext";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { usePagination } from "hooks/usePagination";
import React, { FC, useContext, useState } from "react";
import { parseParamsAndSearch } from "utils/JobUtils";
import { useDynamicPageContent } from "hooks/useDynamicPageContent";
import { useUrlSharedData } from "hooks/useUrlSharedData";
import { FixedJobList } from "components/FixedJobList";

export const DefaultStartPage: FC = () => {
  const { isLoading } = useContext(LoaderContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { localize } = useContext(LocaleContext);

  const { results, setLastElementRef, handleSearch, getInitial } = usePagination<Job>(
    getJobList,
    "error.fetchJobList",
    "GET_JOB_LIST_STARTPAGE",
    {
      status: "active",
      page_size: 4,
    },
    true
  );
  const { pageContent } = useDynamicPageContent({ pageType: "landing" });

  const [currentSearch, setSearch] = useState<string>("");
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [isSearchView, setIsSearchView] = useState(false);
  const [filters, setFilters] = useState<JobFilters>(defaultFilterState);
  const [showFilterButton, setShowFilterButton] = useState(false);
  const { jobsFromUrl, clearJobsFromUrl } = useUrlSharedData(setFilters, handleSearch, getInitial);

  const handleFilterAction = (condition: boolean) => {
    // Handle "hideElement" id so element is not hidden while focused

    setShowFilterButton(condition);
    setIsSearchView(condition);
  };
  const handleFilterCallback = (value: JobFilters) => {
    setFilters(value);
    const parsedFilters = parseParamsAndSearch({ search: currentSearch, ...value });
    handleSearch(parsedFilters);
  };

  const handleSearchCallback = (value: string) => {
    setSearch(value);
    const parsedFilters = parseParamsAndSearch({ search: value, ...filters });
    handleSearch(parsedFilters);
  };

  return (
    <Grid container>
      <SuperHeader headerContent={{ ...pageContent }} />
      <StickyWrapper>
        <Grid
          container
          sx={{
            maxWidth: {
              xs: "100%",
            },
          }}
          item
          wrap="nowrap"
        >
          <AdvancedSearch
            onSearch={handleSearchCallback}
            currentSearch={currentSearch}
            customBorder
            onFocus={() => handleFilterAction(true)}
          />
          {showFilterButton && !isDesktop && (
            <Grid
              container
              item
              marginLeft="1rem"
              xs={2}
              alignItems="center"
              justifyContent="center"
            >
              <WButton variant="outlined" onClick={() => handleFilterAction(false)}>
                {localize("common.close")}
              </WButton>
            </Grid>
          )}
        </Grid>

        <Collapse
          in={showFilterButton && !isDesktop}
          timeout={0}
          mountOnEnter
          unmountOnExit
          sx={{ width: "100%" }}
        >
          <WButton
            fullWidth
            variant="outlined"
            startIcon={<WIcon rotationDegrees="90" icon={WIconTypes.sliders} />}
            onClick={() => setFilterDialogOpen(true)}
          >
            {localize("searchbar.filterButton")}
          </WButton>
        </Collapse>
      </StickyWrapper>

      <Grid container className="contentWrapper" position="relative" paddingTop="0">
        <span id="CardContentContainer" style={{ position: "absolute", height: "400px" }} />
        {jobsFromUrl ? (
          <FixedJobList
            jobs={jobsFromUrl}
            onShowAll={clearJobsFromUrl}
            listIsLoading={isLoading("GET_JOB_LIST_STARTPAGE")}
          />
        ) : (
          <JobList
            jobList={results}
            lastElementRef={setLastElementRef}
            listIsLoading={isLoading("GET_JOB_LIST_STARTPAGE")}
            filters={filters}
            setFilters={handleFilterCallback}
            showImageBanner={!isSearchView}
          />
        )}
        <FiltersDialog
          open={filterDialogOpen}
          handleClose={() => setFilterDialogOpen(false)}
          filterCallback={handleFilterCallback}
          currentFilters={filters}
        />
      </Grid>
    </Grid>
  );
};
