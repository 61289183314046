import React, { FC, ReactNode, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import { UserContext } from "context/UserContext";
import { WButton, WIconTypes, WIcon, WTypography } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { UserInfoComponent } from "components/UserInfoComponent";
import { ModalContext } from "context/ModalContext";

interface NavbarProps {
  title?: string;
  subTitle?: string;
  rightSideItem?: ReactNode;
  leftSideitem?: ReactNode;
  centerTitle?: boolean;
  sx?: Record<string, unknown>;
}

export const Navbar: FC<NavbarProps> = ({
  title,
  subTitle,
  rightSideItem,
  leftSideitem,
  sx,
  centerTitle = false,
}) => {
  const history = useHistory();
  const { localize } = useContext(LocaleContext);
  const { isAuthenticated } = useContext(UserContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { showModal } = useContext(ModalContext);

  const handleAvatarPicker = () => {
    if (window.location.pathname.includes("profile")) {
      showModal("PROFILE_IMAGE_OPTIONS_MODAL");
    }
    history.push("/profile");
  };

  const RenderSideAction = () => {
    if (!isAuthenticated) {
      return (
        <WButton
          variant="contained"
          sx={!isDesktop ? { position: "absolute", top: ".7rem", right: "1rem" } : {}}
          startIcon={<WIcon icon={WIconTypes.login} color={"secondary"} />}
          onClick={() => history.push("/login")}
        >
          {localize("navbar.loginText")}
        </WButton>
      );
    }
    // If desktop user and logged in, show Avatar
    if (isDesktop) {
      return (
        <UserInfoComponent
          handleAvatarPicker={handleAvatarPicker}
          edit={window.location.pathname.includes("profile")}
          opts={{
            reverse: true,
            color: "primary",
          }}
        />
      );
    }
    // Default show none in right corner
    return null;
  };

  return (
    <Grid
      position="relative"
      container
      wrap={!centerTitle ? "nowrap" : "wrap"}
      alignItems="start"
      sx={sx}
    >
      {leftSideitem && <Box>{leftSideitem}</Box>}
      <Grid
        container
        wrap="nowrap"
        order={centerTitle ? 1 : "initial"}
        direction="column"
        alignItems="center"
        sx={{
          alignItems: centerTitle ? "center" : "flex-start",
        }}
      >
        <WTypography
          variant="h4"
          id={!isDesktop ? "elementToTransform" : ""}
          fontWeight="bold"
          color={"primary"}
        >
          {title}
        </WTypography>
        {subTitle && (
          <Grid container id={!isDesktop ? "elementToHide" : ""}>
            <WTypography
              margin={centerTitle ? "auto" : "0"}
              variant="h5"
              fontWeight="bold"
              textAlign={centerTitle ? "center" : "left"}
              color={centerTitle || isDesktop ? "primary" : "secondary"}
            >
              {subTitle}
            </WTypography>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        item
        xs="auto"
        position="absolute"
        right="1rem"
        top="-50%"
        marginLeft={centerTitle ? "auto" : "initial"}
      >
        {rightSideItem || <RenderSideAction />}
      </Grid>
    </Grid>
  );
};
