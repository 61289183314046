import { Skeleton, styled } from "@mui/material";
import { defaultStyles } from "styles/colors";

export const WSkeleton = styled(Skeleton)(
  ({ variant }) => `
  border-radius: ${variant === "rectangular" ? defaultStyles.borderRadiusLarge : undefined}
  `
);

WSkeleton.defaultProps = {
  animation: "wave",
};
