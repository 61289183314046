import { Typography, Link, Grid } from "@mui/material";
import { WSkeleton, WIconOutlinedButton, WIcon, WIconTypes } from "components";
import { LoaderContext } from "context/LoaderContext";
import React, { FC, useContext } from "react";
import { CvCard } from "./CvCard";
import { PROFILE_STEPS } from "views/Enums";
import { ModalContext } from "context/ModalContext";
import { removeUserCV } from "adapters";
import { LocaleContext } from "context/LocaleContext";
import { UserContext } from "context/UserContext";
import { NotificationContext } from "context/NotificationContext";
import { colors } from "styles/colors";

export const CvList: FC = () => {
  const { dispatchLoading, isLoading } = useContext(LoaderContext);
  const { showDialog, hideDialog } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { user, calculateProfileProgress, dispatch } = useContext(UserContext);
  const { addMessage } = useContext(NotificationContext);

  const showWarningDialog = (selectedCV: string) => {
    showDialog("CONFIRM_DIALOG", {
      title: localize("dialog.confirmDeleteCvTitle"),
      subtitle: localize("dialog.confirmDeleteCvSubtitle"),
      cancelAction: () => hideDialog(),
      confirmAction: () => handleRemoveCV(selectedCV),
    });
  };

  const handleRemoveCV = (selectedCV: string): void => {
    dispatchLoading({ type: "SET_LOADING", payload: "DELETE_CV" });
    removeUserCV(selectedCV)
      .then(() => {
        const updatedCvList = user.cv.filter((userCv) => userCv.id !== selectedCV);
        dispatch({ type: "UPDATE_USER", user: { ...user, cv: updatedCvList } });
        calculateProfileProgress(!!updatedCvList.length, PROFILE_STEPS.cv);
        addMessage({ content: "success.removingCV", type: "success", duration: 1000 });
        hideDialog();
      })
      .catch(() => {
        addMessage({ content: "error.removingCV", type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "DELETE_CV" });
      });
  };

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.grey}`,
      }}
    >
      {isLoading("GET_CVS") ? (
        <>
          <WSkeleton height="100px" />
        </>
      ) : !user.cv.length ? (
        <Typography margin="1rem">{localize("profileView.noCVs")}</Typography>
      ) : (
        user.cv.map((cv, index) => {
          return (
            <CvCard
              key={cv.id}
              isLast={index === user.cv.length - 1}
              cv={cv}
              icons={
                <Grid container gap="1rem" wrap="nowrap">
                  <Link href={cv.user_cv} target="_blank" download>
                    <WIconOutlinedButton>
                      <WIcon icon={WIconTypes.download} />
                    </WIconOutlinedButton>
                  </Link>
                  <WIconOutlinedButton onClick={() => showWarningDialog(cv.id)}>
                    <WIcon icon={WIconTypes.trash} />
                  </WIconOutlinedButton>
                </Grid>
              }
            />
          );
        })
      )}
    </Grid>
  );
};
