import React, { FC } from "react";
import { UserContext } from "context/UserContext";
import { Redirect, Route, RouteProps } from "react-router-dom";

export const PrivateRoute: FC<RouteProps> = ({ children, ...props }): JSX.Element => {
  const { isAuthenticated } = React.useContext(UserContext);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  return <Route {...props}>{children}</Route>;
};
