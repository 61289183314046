import { Grid, Typography } from "@mui/material";
import { resetPassword } from "adapters/AuthAdapter";
import { WIcon, WIconTypes, WLoadingButton, WTextField } from "components";
import { WCard } from "components/styledComponents/WCard";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import React, { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { validateEmail } from "./validators";

type ForgotPassState = {
  email: string;
};

interface ForgotPassProps {
  onSubmit?: () => void;
}

export const ForgotPasswordForm: FC<ForgotPassProps> = () => {
  const { localize } = useContext(LocaleContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { register, handleSubmit, formState } = useForm<ForgotPassState>();

  const { errors } = formState;

  const submitForgotPassForm = async (data: ForgotPassState) => {
    dispatchLoading({ type: "SET_LOADING", payload: "RESET_PASSWORD" });
    resetPassword(data.email)
      .then(() => {
        setIsSubmitted(true);
      })
      .catch(() => {
        addMessage({ type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "RESET_PASSWORD" });
      });
  };

  if (isSubmitted) {
    return (
      <Grid container padding="1rem">
        <WCard>
          <WIcon icon={WIconTypes.mail} size="large" color="success" />
          <Typography>{localize("form.forgotPasswordMailSent")}</Typography>
        </WCard>
      </Grid>
    );
  }

  return (
    <form onSubmit={handleSubmit(submitForgotPassForm)}>
      <Grid container gap="1rem" justifyContent="center">
        <WTextField
          fullWidth
          label={localize("form.emailField")}
          error={!!errors.email}
          helperText={errors?.email?.message}
          {...register("email", {
            required: localize("form.requiredFieldError"),
            validate: (value) => validateEmail(value, localize),
          })}
          type="email"
        />
        <WLoadingButton
          fullWidth
          displayLoading={isLoading("RESET_PASSWORD")}
          variant="contained"
          type="submit"
        >
          {localize("form.submitForgotPassword")}
        </WLoadingButton>
        <Typography color="primary" variant="body1" align="center">
          {localize("form.forgotPasswordHelpText")}
        </Typography>
      </Grid>
    </form>
  );
};
