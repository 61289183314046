import React, { FC } from "react";
import styled from "@emotion/styled";
import { WIcon, WIconTypes } from "components/WIcon";
import { WTypography } from "components/WTypography";
import { colors } from "styles/colors";
import { Box } from "@mui/system";
import { Button, IconButton } from "@mui/material";

export type TaskTagProp = "agent" | "jobs" | "job_applications" | "date" | "users";

export interface TaskTagInfo {
  type: TaskTagProp;
  text: string;
  onClick?: (e: React.MouseEvent) => void;
  color?: string;
  internalIndex?: number;
}

export interface TaskTagProps extends TaskTagInfo {
  isEditing?: boolean;
  onDelete?: () => void;
}
export const TaskTag: FC<TaskTagProps> = ({ type, text, onClick, color, isEditing, onDelete }) => {
  const content = (
    <ContentBox>
      {type !== "agent" ? (
        <WIcon icon={getIcon(type)} size="small" customColor={color ?? colors.buttonSelected} />
      ) : null}
      <WTypography
        variant="body2"
        fontSize="0.8rem"
        color={color ?? colors.darkGrey}
        lineClamps={1}
      >
        {text}
      </WTypography>
      {isEditing && onDelete ? (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <WIcon icon={WIconTypes.trash} />
        </IconButton>
      ) : null}
    </ContentBox>
  );

  if (!onClick || isEditing) {
    return content;
  }

  return (
    <CustomButton variant="text" size="small" onClick={onClick}>
      {content}
    </CustomButton>
  );
};

export const getIcon = (type: TaskTagProp): WIconTypes => {
  switch (type) {
    case "job_applications":
      return WIconTypes.application;
    case "date":
      return WIconTypes.calendar;
    case "users":
      return WIconTypes.person;
    case "jobs":
      return WIconTypes.job;

    default:
      return WIconTypes.job;
  }
};

const ContentBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  text-align: left;
  gap: 5px;

  p {
    word-break: break-all;
  }

  button {
    margin: -4px;
  }

  svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
`;
const CustomButton = styled(Button)`
  padding: 0px;

  :hover {
    box-shadow: 0 0 0 1px ${colors.darkGrey};
  }
`;
