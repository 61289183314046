import React, { FC } from "react";
import { Grid } from "@mui/material";
import { useDynamicPageContent } from "hooks/useDynamicPageContent";
import { DynamicViewContent } from "components";

export const FAQView: FC = () => {
  const { pageContent, pageHTMLContent } = useDynamicPageContent({ pageType: "faq" });

  return (
    <Grid container position="relative">
      <DynamicViewContent pageContent={pageContent} pageHTMLContent={pageHTMLContent} />
    </Grid>
  );
};
