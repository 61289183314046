import { AxiosResponse } from "axios";
import { API } from "./api";

export type ReportsActions =
  | "GET_MONTH_DATA"
  | "GET_AGENT_MONTH_DATA"
  | "GET_TOP_SKILLS"
  | "GET_AGENTS_DATA"
  | "GET_AGENTS_PERIOD"
  | "GET_AGENTS"
  | "GET_AGENTS_TARGET_VALUES"
  | "GET_AGENT_DETAILS"
  | "GET_USERS_DATA"
  | "GET_USERS_PERIOD"
  | "GET_APPLICATIONS_DATA";

export type TopSkill = {
  skill: string;
  percent: number;
  count: number;
  job_count: number;
  user_count: number;
  total: number;
};

export type TopSkills = {
  job_and_user_skills: TopSkill[];
};

export type MonthData = {
  user_created: number[];
  applications: number[];
  applications_unverified: number[];
};

export type AgentsData = {
  active: number;
};

export type AgentsPeriod = {
  total_messages_sent: number;
  total_notes_posted: number;
  total_rating_changed: number;
};

export type Agent = {
  id: string;
  full_name: string;
  profile_picture: string;
  email: string;
  last_login: string;
};

export type AgentsTargetValues = {
  logged_in: number;
  notes_posted: number;
  messages_sent: number;
  rating_changed: number;
};

export type AgentDetails = {
  last_login: string;
  logged_in: number;
  messages_sent: number;
  notes_posted: number;
  ratings_changed: number;
  logged_in_period: number[];
  messages_sent_period: number[];
  notes_posted_period: number[];
  ratings_changed_period: number[];
};

export type AgentMonthData = {
  logged_in: number;
  logged_in_period: number[];
  messages_sent_period: number[];
  notes_posted_period: number[];
  ratings_changed_period: number[];
};

export type UsersData = {
  total: number;
  active: number;
  inactive: number;
  set_up_skills: number;
  new_job_subscribe: number;
};

export type UsersPeriod = {
  created: number;
  logged_in: number;
  created_period: number[];
  logged_in_period: number[];
  logged_in_time_period: number[];
};

type ApplicationPeriod = {
  anonymous: number;
  not_anonymous: number;
  verified: number;
  anonymous_verified_period: number[];
  not_anonymous_period: number[];
  verified_period: number[];
};

export type ApplicationDetails = {
  gig: ApplicationPeriod;
  consult: ApplicationPeriod;
};

export const getTopSkills = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<TopSkills>> => {
  return API.get("/reports/skills-list/", { params: params });
};

export const getMonthData = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<MonthData>> => {
  return API.get("/reports/month-data/", { params: params });
};

export const getAgentMonthData = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<AgentMonthData>> => {
  return API.get("/reports/agent-details-month-data/", { params: params });
};

export const getAgentsData = async (): Promise<AxiosResponse<AgentsData>> => {
  return API.get("/reports/agents-data/");
};

export const getAgentsPeriod = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<AgentsPeriod>> => {
  return API.get("/reports/agents-period/", { params: params });
};

export const getAgents = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<Agent[]>> => {
  return API.get("/reports/agents/", { params });
};

export const getAgentsTargetValues = async (): Promise<AxiosResponse<AgentsTargetValues>> => {
  return API.get("/reports/agents-target-values/");
};

export const getAgentDetails = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<AgentDetails>> => {
  return API.get("/reports/agent-details/", { params: params });
};

export const getUsersData = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<UsersData>> => {
  return API.get("/reports/users-data/", { params: params });
};

export const getUsersPeriod = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<UsersPeriod>> => {
  return API.get("/reports/users-period/", { params: params });
};

export const getApplicationsData = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<ApplicationDetails>> => {
  return API.get("/reports/applications-details/", { params: params });
};
