import { Menu, MenuProps } from "@mui/material";
import React, { FC } from "react";
import { defaultStyles } from "styles/colors";

interface Props {
  fullwidth: boolean;
}

export const WMenu: FC<Props & MenuProps> = (props) => {
  const { children, fullwidth, ...restProps } = props;

  return (
    <Menu
      {...restProps}
      sx={{
        "& ul": {
          backgroundColor: "white",
          padding: 0,
          minHeight: fullwidth ? "100%" : "auto",
        },
        "& .MuiPaper-root": fullwidth
          ? {
              boxShadow: "none",
              width: "100%",
              maxWidth: "unset",
              backgroundColor: "white",
              height: "100%",
              left: "0 !important",
            }
          : {
              minWidth: "220px",
              boxShadow: defaultStyles.dropDownShadow,
              borderRadius: defaultStyles.borderRadius,
            },
      }}
    >
      {children}
    </Menu>
  );
};
