import { NotificationInitialState, NotificationContextState } from "context/NotificationContext";

export type NotificationMessage = {
  content?: string;
  isResponse?: boolean;
  type: "error" | "success";
  duration?: number;
};

export type NotificationActionType =
  | { type: "ADD_NOTIFICATION"; message: NotificationMessage }
  | { type: "REMOVE_NOTIFICATION" };

export function NotificationReducer(
  state: NotificationContextState = NotificationInitialState,
  action: NotificationActionType
): NotificationContextState {
  switch (action.type) {
    case "ADD_NOTIFICATION":
      return {
        ...state,
        messages: action.message,
      };
    case "REMOVE_NOTIFICATION":
      return NotificationInitialState;
    default:
      return state;
  }
}
