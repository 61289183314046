import React, { useContext, FC, useState, useEffect } from "react";
import { ProfileCompletenessBar, WIcon, WIconTypes, WImageBanner, WRedButton } from "components";
import { UserContext } from "context/UserContext";
import { PROFILE_STEPS } from "views/Enums";
import { ProfileSections } from "./ProfileSections";
import { ModalContext } from "context/ModalContext";
import { LocaleContext } from "context/LocaleContext";
import { logout } from "adapters";
import { Divider, Grid, Typography } from "@mui/material";
import { BreakpointsContext } from "context/BreakpointContext";
import { Box } from "@mui/system";
import { NewsContext } from "context/NewsContext";

export type StepsAndActions = {
  step: string;
  action?: () => void;
};

export const MyProfileTab: FC = () => {
  const { dispatch } = React.useContext(UserContext);
  const { profileCompleteness } = useContext(UserContext);
  const { localize } = useContext(LocaleContext);
  const [stepsAndActions, setStepsAndActions] = useState<StepsAndActions[]>([]);
  const { showModal } = useContext(ModalContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { latestProfileNews } = useContext(NewsContext);

  useEffect(() => {
    if (profileCompleteness.steps.length) {
      buildStepActions();
    }
  }, [profileCompleteness.percentage]);

  const handleLogout = () => {
    logout().finally(() => dispatch({ type: "LOGOUT" }));
  };

  const buildStepActions = () => {
    const tempStepsAndActions: StepsAndActions[] = [];
    profileCompleteness.steps.forEach((step) => {
      switch (step) {
        case PROFILE_STEPS.availability:
          tempStepsAndActions.push({
            step: step,
            action: () => showModal("PROFILE_AVAILABILITY_MODAL"),
          });
          break;
        case PROFILE_STEPS.cv:
          tempStepsAndActions.push({
            step: step,
            action: () => showModal("PROFILE_CV_MODAL"),
          });
          break;
        case PROFILE_STEPS.jobApplication:
          tempStepsAndActions.push({ step: step });
          break;
        case PROFILE_STEPS.skill:
          tempStepsAndActions.push({
            step: step,
            action: () => showModal("PROFILE_SKILLS_MODAL"),
          });
          break;
      }
      setStepsAndActions(tempStepsAndActions);
    });
  };

  return (
    <>
      <Grid container className="contentWrapper" wrap="nowrap" gap="1rem">
        <Grid container item md={4} direction="column" wrap="nowrap" paddingLeft=".5rem">
          {isDesktop && (
            <Typography paddingLeft="1rem" color="primary" variant="h4" fontWeight="bold">
              {localize("profileView.headerMessage")}
            </Typography>
          )}
          <Grid container item gap=".5rem" margin="auto" direction="column">
            <Box paddingLeft="1rem">
              <ProfileCompletenessBar
                percentComplete={profileCompleteness.percentage}
                steps={stepsAndActions}
              />
            </Box>
          </Grid>
        </Grid>
        {isDesktop && latestProfileNews && (
          <Grid container item sm={8} margin="auto">
            <WImageBanner
              size="xs"
              title={latestProfileNews.title}
              textContent={latestProfileNews.body}
              imageSrc={latestProfileNews.news_photo}
              subTitle={latestProfileNews.sub_title}
            />
          </Grid>
        )}
      </Grid>
      {isDesktop && <Divider style={{ width: "100%" }} />}
      <Grid
        container
        wrap="nowrap"
        direction="column"
        gap="1.5rem"
        className={isDesktop ? "contentWrapper" : ""}
      >
        {isDesktop && (
          <Typography paddingLeft="1rem" color="primary" variant="h4" fontWeight="bold">
            {localize("profileSections.profileTitleSettings")}
          </Typography>
        )}
        <Box paddingLeft={isDesktop ? "1rem" : "0"}>
          <ProfileSections />
        </Box>
      </Grid>
      {!isDesktop && (
        <WRedButton
          variant="outlined"
          sx={{ margin: "1rem", width: "90%" }}
          onClick={handleLogout}
          startIcon={<WIcon icon={WIconTypes.logout} color="error" />}
        >
          {localize("navigationDialog.logout")}
        </WRedButton>
      )}
    </>
  );
};
