import { Avatar, Grid, IconButton, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useContext } from "react";
import { AvatarColors } from "styles/colors";
import { CircularPercentage } from "./JobMatchesView/CircularPercentage";
import { UserContext } from "context/UserContext";
import { ConditionalWrapper } from "utils/ConditionalWrapper";
import { WIcon, WIconTypes, WTypography } from "components";

export type AvatarItemSizes = "large" | "small" | "medium" | "xsmall" | "tiny" | "xtiny";

const SizeMapping = {
  xtiny: "23px",
  tiny: "2.5rem",
  xsmall: "4rem",
  small: "5.12rem",
  medium: "8rem",
  large: "15rem",
};
interface AvatarItemProps {
  imageSrc?: string;
  firstName?: string;
  lastName?: string;
  showName?: boolean;
  email?: string;
  showProgress?: boolean;
  direction?: "column" | "row";
  edit?: boolean;
  size?: AvatarItemSizes;
}
export const AvatarItem: FC<AvatarItemProps> = ({
  imageSrc,
  firstName = "",
  lastName = "",
  direction = "row",
  email = "",
  showName = false,
  edit = false,
  size = "small",
  showProgress,
}) => {
  const { profileCompleteness } = useContext(UserContext);
  let avatarColor = "";

  const initials = firstName?.charAt(0) + lastName?.charAt(0);
  if (initials && !imageSrc) {
    const colorString = initials
      .split("")
      .map((char) => char.charCodeAt(0))
      .join("");
    const colorIndex = parseInt(colorString, 10) % AvatarColors.length;
    avatarColor = AvatarColors[colorIndex];
  }

  return (
    <Grid
      container
      item
      flexWrap="nowrap"
      direction={direction}
      position="relative"
      {...(direction === "column" ? { alignItems: "center" } : {})}
      xs="auto"
      gap="0.5rem"
    >
      <ConditionalWrapper
        condition={!!showProgress}
        wrapper={(children) => (
          <CircularPercentage percentage={profileCompleteness.percentage} size={88}>
            {children}
          </CircularPercentage>
        )}
      >
        <Avatar
          alt="Profile"
          src={imageSrc}
          sx={{
            height: SizeMapping[size],
            width: SizeMapping[size],
            backgroundColor: !imageSrc ? avatarColor : "initial",
            boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
            fontSize: size === "tiny" || size === "xtiny" ? "12px" : "20px",
          }}
        >
          {initials && !imageSrc && initials}
        </Avatar>
      </ConditionalWrapper>
      {showName && (
        <Box
          textAlign={direction === "column" ? "center" : "left"}
          marginY={!email ? "auto" : undefined}
        >
          <WTypography
            fontWeight="bold"
            lineHeight="1rem"
            variant="subtitle1"
            color="primary.dark"
            sx={{ wordBreak: "break-all" }}
          >
            {`${firstName} ${lastName}`}
          </WTypography>
        </Box>
      )}
      {email && (
        <Box textAlign={direction === "column" ? "center" : "left"}>
          <Typography
            lineHeight="1rem"
            fontWeight="bold"
            sx={{ lineBreak: "anywhere" }}
            variant="subtitle1"
            color="primary.dark"
          >
            <Link href={`mailto:${email}`} fontWeight="bold">
              {email}
            </Link>
          </Typography>
        </Box>
      )}
      {edit && (
        <IconButton
          size="small"
          disabled
          sx={{
            position: "absolute",
            height: "1.5rem",
            width: "1.5rem",
            bottom: 0,
            right: 0,
            "&.Mui-disabled": {
              backgroundColor: "white",
            },
            svg: {
              height: "1rem",
              width: "1rem",
            },
          }}
        >
          <WIcon icon={WIconTypes.camera} />
        </IconButton>
      )}
    </Grid>
  );
};
