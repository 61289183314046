import React, { FC, useContext, useEffect, useState } from "react";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { WDatePicker } from "components";
import { updateProfile } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { WDateFormats } from "utils/PrettifyDate";
import { UserContext } from "context/UserContext";
import { parseISO, format } from "date-fns";
import { PROFILE_STEPS } from "views/Enums";
import { Dialog, Grid } from "@mui/material";
import { ConditionalWrapper } from "utils/ConditionalWrapper";
import { BreakpointsContext } from "context/BreakpointContext";
import { WButton } from "components/styledComponents/WButton";
import { WIcon, WIconTypes } from "components/WIcon";

export const ProfileAvailabilityModal: FC = () => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { hideModal } = useContext(ModalContext);
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { dispatch, user, calculateProfileProgress } = useContext(UserContext);
  const [availableDate, setAvailableDate] = useState<Date>();
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (user.availability_date) {
      setAvailableDate(parseISO(user.availability_date));
    }
  }, []);

  const updateAvailableDate = (changedDate: Date | null): void => {
    setAvailableDate(changedDate ?? undefined);
  };

  const handleSave = (value: Date | null | undefined) => {
    dispatchLoading({ type: "SET_LOADING", payload: "UPDATE_USER" });
    let formatedDate: string | undefined = undefined;
    if (value) {
      formatedDate = format(value, WDateFormats.date);
    }
    if (user.availability_date !== formatedDate) {
      updateProfile({ availability_date: formatedDate }, user.id)
        .then(() => {
          if (!formatedDate) {
            addMessage({ content: "success.availabilityDateRemoved", type: "success" });
          } else {
            addMessage({ content: "success.availabilityDate", type: "success" });
          }
          dispatch({
            type: "UPDATE_USER",
            user: {
              ...user,
              availability_date: formatedDate,
            },
          });
          calculateProfileProgress(!!formatedDate, PROFILE_STEPS.availability);

          hideModal();
        })
        .catch(() => {
          addMessage({ content: "error.availabilityDate", type: "error" });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "UPDATE_USER" });
        });
    }
  };

  return (
    <Dialog open={true} onClose={hideModal}>
      <ConditionalWrapper
        condition={isDesktop}
        wrapper={(children) => (
          <Grid container padding="1rem" gap="1rem">
            {children}
          </Grid>
        )}
      >
        <WDatePicker
          open={true}
          onError={(reason) => setError(!!reason)}
          value={availableDate || null}
          onChange={(date) => updateAvailableDate(date)}
          label={localize("form.availabilityDate")}
          onAccept={(value) => handleSave(value)}
          onClose={() => hideModal()}
        />
        {isDesktop && (
          <Grid item md={4} container>
            <WButton
              fullWidth
              variant="outlined"
              startIcon={<WIcon icon={WIconTypes.check} />}
              onClick={() => handleSave(availableDate)}
              disabled={hasError || !availableDate}
            >
              {localize("form.save")}
            </WButton>
          </Grid>
        )}
      </ConditionalWrapper>
    </Dialog>
  );
};
