import { useMediaQuery, useTheme } from "@mui/material";
import React, { createContext } from "react";

export const BreakpointsContext = createContext<{
  isDesktop: boolean;
}>({
  isDesktop: false,
});

const BreakpointsProvider: React.FC = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <BreakpointsContext.Provider value={{ isDesktop }}>{children}</BreakpointsContext.Provider>
  );
};

export default BreakpointsProvider;
