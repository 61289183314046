import { Dialog, Grid, MenuItem } from "@mui/material";
import { DialogHeader, WSkeleton, WButton } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext, useEffect, useState } from "react";
import {
  getDepartments,
  Department,
  addUserDepartment,
  deleteUserDepartment,
  RoleWithDepartment,
} from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { JobFilterItem, JobFilterItemWithParent } from "components/dialogs/FiltersDialog";
import { ModalContext } from "context/ModalContext";
import { UserContext } from "context/UserContext";
import { DepartmentExpandableList } from "components/ProfileView/DepartmentExpandableList";
import { usePagination } from "hooks/usePagination";
import { WIcon, WIconTypes } from "components/WIcon";

const FILTER_ITEMS_TO_SHOW = 4;

type DepartmentFilters = {
  departments: JobFilterItem[];
  roles: JobFilterItemWithParent[];
};

const defaultFilterState: DepartmentFilters = {
  departments: [],
  roles: [],
};

export const ProfileRoleModal: FC = () => {
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { hideModal } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);
  const { user, dispatch } = useContext(UserContext);

  const [filters, setFilters] = useState<DepartmentFilters>(defaultFilterState);
  const [currentUserRoles, setCurrentUserRoles] = useState<string[]>();
  const [showMore, setShowMore] = useState<{
    departments: boolean;
    roles: boolean;
  }>({
    departments: false,
    roles: false,
  });

  const { results, setLastElementRef } = usePagination<Department>(
    getDepartments,
    "error.fetchingDepartments",
    "GET_DEPARTMENTS"
  );

  useEffect(() => {
    initUserRoles();
  }, []);

  const initUserRoles = () => {
    const tempFilterItemsWithParent: JobFilterItemWithParent[] =
      user.departments.map((role) => {
        return {
          label: role.name,
          value: role.id,
          parent: { label: role.department.name, value: role.department.id },
        };
      }) || [];
    setCurrentUserRoles(user.departments.map((role) => role.id));

    setFilters({
      ...filters,
      roles: [...filters["roles"], ...tempFilterItemsWithParent],
    });
  };

  const handleSaveChanges = () => {
    if (currentUserRoles) {
      const additions: JobFilterItemWithParent[] =
        filters.roles.filter((role) => !currentUserRoles.includes(role.value)) || []; // if there's any currently selected roles in filter that does not exist in the currentUserRoles it's an addition

      const deletions: string[] =
        currentUserRoles.filter(
          (userRole) => !filters.roles.some((role) => role.value === userRole)
        ) || []; // if savedUserRoles contains a role that does not exist in the filters it's a deletion

      if (additions.length + deletions.length === 0) {
        return;
      }

      if (additions.length && deletions.length) {
        Promise.all([handleAdditions(additions, false), handleDeletions(deletions, false)])
          .then(() => {
            addMessage({ content: "success.updateSuccessful", type: "success" });
          })
          .catch(() => {
            addMessage({ content: "error.deleteOrAddDepartments", type: "error" });
          })
          .finally(() => {
            // Additions as role object
            const additionsAsRole: RoleWithDepartment[] = additions.map((addition) => {
              return {
                id: addition.value,
                name: addition.label,
                department: {
                  id: addition.parent.value,
                  name: addition.parent.label,
                },
              };
            });

            // Updated deparments with removed items
            const updatedDepartments = user.departments.filter(
              (userDep) => !deletions.includes(userDep.id)
            );

            dispatch({
              type: "UPDATE_USER",
              user: { departments: [...updatedDepartments, ...additionsAsRole] },
            });
            hideModal();
          });
      } else {
        if (additions.length) {
          handleAdditions(additions, true);
        } else {
          handleDeletions(deletions, true);
        }
      }
    }
  };

  const handleAdditions = (additions: JobFilterItemWithParent[], shouldCloseAndSave: boolean) => {
    // Avoid duplicate requests
    if (isLoading("ADD_USER_DEPARTMENT") || additions.length < 1) {
      return;
    }

    dispatchLoading({ type: "SET_LOADING", payload: "ADD_USER_DEPARTMENT" });
    addUserDepartment({
      roles: additions.map((roles) => roles.value),
    })
      .then(() => {
        if (shouldCloseAndSave) {
          // Update departments in user context
          const additionsAsRole: RoleWithDepartment[] = additions.map((addition) => {
            return {
              id: addition.value,
              name: addition.label,
              department: {
                id: addition.parent.value,
                name: addition.parent.label,
              },
            };
          });
          dispatch({
            type: "UPDATE_USER",
            user: { departments: [...user.departments, ...additionsAsRole] },
          });
          hideModal();
          addMessage({ content: "success.updateSuccessful", type: "success" });
        }
      })
      .catch(() => {
        if (shouldCloseAndSave) {
          addMessage({ content: "error.addingDepartmentRole", type: "error" });
        }
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "ADD_USER_DEPARTMENT" });
      });
  };

  const handleDeletions = (deletions: string[], shouldCloseAndSave: boolean) => {
    // Avoid duplicate requests
    if (isLoading("DELETE_USER_DEPARTMENT") || deletions.length < 1) {
      return;
    }

    deleteUserDepartment({ roles: deletions })
      .then(() => {
        if (shouldCloseAndSave) {
          // Update departments in user context
          const updatedDepartments = user.departments.filter(
            (userDep) => !deletions.includes(userDep.id)
          );
          dispatch({ type: "UPDATE_USER", user: { departments: updatedDepartments } });
          hideModal();
          addMessage({ content: "success.updateSuccessful", type: "success" });
        }
      })
      .catch(() => {
        if (shouldCloseAndSave) {
          addMessage({ content: "error.removingDepartmentRole", type: "error" });
        }
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "DELETE_USER_DEPARTMENT" });
      });
  };

  const handleRemoveItem = (key: string, item: JobFilterItemWithParent | JobFilterItem) => {
    if (key === "departments") {
      const tempFilter = filters[key].filter(
        (prevValue: JobFilterItem) => prevValue.value !== item.value
      );

      setFilters({
        ...filters,
        [key]: [...tempFilter],
        roles: filters["roles"].filter((prevValue: JobFilterItemWithParent) => {
          return prevValue.parent.value !== item.value;
        }),
      });
    } else {
      setFilters({
        ...filters,
        [key]: filters[key].filter((prevValue: JobFilterItem) => prevValue.value !== item.value),
      });
    }
  };

  const handleAddItem = (key: string, item: JobFilterItemWithParent | JobFilterItem) => {
    if (key === "departments") {
      const selectedDepartment = results.find((res) => res.id === item.value);
      if (selectedDepartment) {
        const parsedDepartment = {
          label: selectedDepartment.name,
          value: selectedDepartment.id,
        };
        const allRoles = selectedDepartment.roles.map((role) => {
          return {
            label: role.name,
            value: role.id,
            parent: parsedDepartment,
          };
        });
        setFilters({
          ...filters,
          departments: [...filters.departments, parsedDepartment],
          roles: [...filters.roles, ...allRoles],
        });
      }
    } else {
      setFilters({ ...filters, [key]: [...filters[key], item] });
    }
  };

  const handleItemClick = (
    key: keyof DepartmentFilters,
    item: JobFilterItemWithParent | JobFilterItem
  ) => {
    if (filters[key].some((filter) => filter.value === item.value)) {
      handleRemoveItem(key, item);
    } else {
      handleAddItem(key, item);
    }
  };

  return (
    <Dialog fullScreen open={true} onClose={() => hideModal()}>
      <DialogHeader
        returnText={localize("profileView.headerMessage")}
        handleClose={() => hideModal()}
        title={`${localize("common.select")} ${localize(
          "profileSections.profileTitleRoleTwo"
        ).toLowerCase()}`}
      />
      <Grid container direction="column" gap="1rem" height="max-content">
        {isLoading("GET_USER_DEPARTMENTS") && (
          <WSkeleton width="100%">
            <MenuItem />
          </WSkeleton>
        )}
        <DepartmentExpandableList
          expand={!showMore.departments}
          itemsToShow={FILTER_ITEMS_TO_SHOW}
          allResults={results}
          filters={filters}
          handleItemClick={handleItemClick}
          handleExpandClick={() => setShowMore({ ...showMore, departments: true })}
          lastElementRef={setLastElementRef}
        />
      </Grid>
      <WButton
        variant="outlined"
        startIcon={<WIcon icon={WIconTypes.uploadCloud} />}
        sx={{ margin: "1rem" }}
        onClick={handleSaveChanges}
      >
        {localize("form.save")}
      </WButton>
    </Dialog>
  );
};
