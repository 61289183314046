import { Grid, Typography } from "@mui/material";
import { Message } from "adapters";
import { AvatarItem } from "components";
import { LocaleContext } from "context/LocaleContext";
import { isToday, isValid, parseISO } from "date-fns";
import React, { FC, useContext } from "react";
import { prettifyDate, WDateFormats } from "utils/PrettifyDate";
import { SocketMessage } from "./ConversationModal";
import { colors, defaultStyles } from "styles/colors";
import { BreakpointsContext } from "context/BreakpointContext";
import { WTypography } from "components/WTypography";
import { User } from "reducers/UserReducer";

interface MessageComponentProps {
  message: Message | SocketMessage;
  sender: User | undefined;
  isCurrentUser: boolean;
}

export const MessageComponent: FC<MessageComponentProps> = ({ message, sender, isCurrentUser }) => {
  const { isDesktop } = useContext(BreakpointsContext);
  const { localize } = useContext(LocaleContext);

  const renderDateStamp = (timestamp: string) => {
    const parsedTimestamp = parseISO(timestamp);
    if (!isValid(parsedTimestamp)) {
      return null;
    }
    return isToday(parsedTimestamp) ? localize("common.today") : prettifyDate(timestamp);
  };

  if (isDesktop) {
    return (
      <Grid
        container
        key={message.message}
        gap="1rem"
        wrap="nowrap"
        paddingLeft={isCurrentUser ? "7.5rem" : "0"}
        bgcolor={colors.white}
        alignItems="center"
      >
        <Grid>
          {sender ? (
            <AvatarItem
              imageSrc={sender.profile_picture}
              firstName={sender.first_name}
              lastName={sender.last_name}
              size="tiny"
            />
          ) : (
            <AvatarItem
              imageSrc={`${process.env.PUBLIC_URL}/assets/logo/icon_64.png`}
              size="tiny"
            />
          )}
        </Grid>
        <Grid
          container
          item
          xs={10}
          direction="column"
          wrap="nowrap"
          gap=".5rem"
          maxWidth={isCurrentUser ? "100%" : "70%"}
          bgcolor={isCurrentUser ? colors.mainDark : colors.lightGrey}
          padding=" .2rem 1rem .5rem 1rem"
          sx={{ color: isCurrentUser ? colors.siteBackground : colors.mainDark }}
          borderRadius={defaultStyles.borderRadius}
        >
          <Grid container item xs={10} alignItems="center" wrap="nowrap" gap=".5rem">
            <WTypography
              variant="h6"
              fontWeight="bold"
              lineClamps={1}
              flexWrap="wrap"
              maxWidth="max-content"
            >
              {sender
                ? sender.first_name
                  ? `${sender.first_name} ${sender.last_name}`
                  : sender.email
                : `Wise Support`}
            </WTypography>
            <Typography
              fontSize=".8rem"
              fontWeight="bold"
              color={isCurrentUser ? colors.grey : colors.darkGrey}
            >
              {renderDateStamp(message.sent)}{" "}
              {prettifyDate(message.sent, {
                dateFormat: WDateFormats.timestamp,
              })}
            </Typography>
          </Grid>

          <Typography
            fontSize="1rem"
            key={message.message}
            lineHeight="1.2rem"
            width="75%"
            sx={{
              overflowWrap: "break-word",
            }}
          >
            {message.message}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      key={message.message}
      gap="1rem"
      wrap="nowrap"
      direction={isCurrentUser ? "row-reverse" : "row"}
      paddingLeft={isCurrentUser ? "7.5rem" : "0"}
      alignItems="center"
      bgcolor={colors.white}
    >
      <Grid>
        {sender ? (
          <AvatarItem
            imageSrc={sender.profile_picture}
            firstName={sender.first_name}
            lastName={sender.last_name}
            size="tiny"
          />
        ) : (
          <AvatarItem imageSrc={`${process.env.PUBLIC_URL}/assets/logo/icon_64.png`} size="tiny" />
        )}
      </Grid>
      <Grid
        container
        item
        xs={10}
        direction="column"
        wrap="nowrap"
        gap=".5rem"
        maxWidth={isCurrentUser ? "100%" : "70%"}
        bgcolor={isCurrentUser ? colors.mainDark : colors.lightGrey}
        padding=" .2rem 1rem .5rem 1rem"
        sx={{ color: isCurrentUser ? colors.siteBackground : colors.mainDark }}
        borderRadius={defaultStyles.borderRadius}
      >
        <Typography
          fontSize=".8rem"
          fontWeight="bold"
          width="75%"
          color={isCurrentUser ? colors.grey : colors.darkGrey}
        >
          {renderDateStamp(message.sent)}{" "}
          {prettifyDate(message.sent, {
            dateFormat: WDateFormats.timestamp,
          })}
        </Typography>

        <Typography
          fontSize="1rem"
          key={message.message}
          lineHeight="1.2rem"
          width="75%"
          sx={{
            overflowWrap: "break-word",
          }}
        >
          {message.message}
        </Typography>
      </Grid>
    </Grid>
  );
};
