import { Box, Button, Dialog, Divider, Grid } from "@mui/material";
import { changeProfilePicture } from "adapters";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext } from "react";
import { defaultStyles } from "styles/colors";

export const ProfileImageOptionsDialog: FC = () => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { hideModal, showModal } = useContext(ModalContext);
  const { user, dispatch } = useContext(UserContext);
  const { addMessage } = useContext(NotificationContext);

  const updateSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      showModal("PROFILE_IMAGE_MODAL", { imageData: event.target.files[0] });
    }
  };

  const removeProfilePicture = async () => {
    dispatchLoading({ type: "SET_LOADING", payload: "UPDATE_PROFILE_PICTURE" });
    changeProfilePicture(undefined, user.id)
      .then((res) => {
        addMessage({ content: "success.profileImageRemoved", type: "success" });
        dispatch({ type: "UPDATE_USER", user: { ...user, profile_picture: res.data.file } });
      })
      .catch(() => {
        addMessage({ content: "error.removeProfileImage", type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "UPDATE_PROFILE_PICTURE" });
      });
  };

  return (
    <Dialog
      open={true}
      onClose={() => hideModal()}
      PaperProps={{
        sx: {
          borderRadius: defaultStyles.borderRadiusLarge,
          position: "absolute",
          margin: "auto",
          width: "60%",
        },
      }}
    >
      <Grid>
        <Box>
          <Button fullWidth size="large" component="label" sx={{ color: "text.primary" }}>
            {localize("form.uploadImage")}
            <input
              style={{ display: "none" }}
              accept="image/png, image/jpeg, image/jpg"
              type="file"
              onChange={(e) => updateSelectedFile(e)}
            />
          </Button>
          <Divider sx={{ width: "100%" }} />

          <Button
            fullWidth
            disabled={!user.profile_picture && user.profile_picture !== ""}
            size="large"
            variant="text"
            sx={{ color: "text.primary" }}
            onClick={() => {
              removeProfilePicture();
            }}
          >
            {localize("form.removeProfileImage")}
          </Button>
          <Divider sx={{ width: "100%" }} />
        </Box>
        <Button
          fullWidth
          size="large"
          variant="text"
          sx={{ color: "text.primary" }}
          onClick={() => hideModal()}
        >
          {localize("common.close")}
        </Button>
      </Grid>
    </Dialog>
  );
};
