import { Dialog, Grid, InputAdornment, Typography } from "@mui/material";
import { shareJob } from "adapters/JobAdapter";
import { WButton, WTextField, WIcon, WIconTypes } from "components";
import { validateEmail } from "components/forms/validators";
import { GTMContext } from "context/GTMContext";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext } from "react";
import { useForm } from "react-hook-form";

interface ShareJobDialogProps {
  jobId: string;
  title: string;
}

export type ShareJobState = {
  email: string;
};

export const ShareJobDialog: FC<ShareJobDialogProps> = ({ jobId, title }) => {
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { isAuthenticated } = useContext(UserContext);
  const { hideDialog } = useContext(ModalContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ShareJobState>({
    defaultValues: {
      email: "",
    },
  });

  const submitAndSend = (data: ShareJobState) => {
    shareJob(jobId, data.email).catch(() => {
      addMessage({ type: "error", content: "error.shareJob" });
    });
    gtmAddToDataLayer({ action: title }, "application_shared");

    addMessage({ type: "success", content: "success.shareJobSent" });
    hideDialog();
  };

  return (
    <>
      {isAuthenticated && (
        <Dialog
          open
          onClose={hideDialog}
          fullWidth
          PaperProps={{
            style: { borderRadius: "15px" },
          }}
        >
          <form onSubmit={handleSubmit(submitAndSend)} style={{ padding: "1rem", width: "100%" }}>
            <Grid container direction="column" gap="1.5rem">
              <Grid container direction="column" wrap="nowrap" gap=".1rem">
                <Typography variant="h6">{localize("common.shareJob")}</Typography>
                <Grid container marginBottom="1rem">
                  <Typography>{title}</Typography>
                </Grid>
                <WTextField
                  variant="outlined"
                  type="email"
                  label={localize("shareJobDialog.sendLabel")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WIcon icon={WIconTypes.mail} />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  {...register("email", {
                    required: localize("form.requiredFieldError"),
                    validate: (value) => validateEmail(value, localize),
                  })}
                />
              </Grid>
              <Grid container justifyContent="space-between" wrap="nowrap">
                <WButton
                  startIcon={<WIcon icon={WIconTypes.send} color="secondary" />}
                  type="submit"
                  variant="contained"
                >
                  {localize("shareJobDialog.sendButton")}
                </WButton>
                <WButton onClick={hideDialog} variant="outlined">
                  {localize("common.close")}
                </WButton>
              </Grid>
            </Grid>
          </form>
        </Dialog>
      )}
    </>
  );
};
