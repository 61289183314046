import React, { FC, useContext, useMemo } from "react";
import { Grid, Tooltip } from "@mui/material";
import { Group } from "adapters/GroupsAdapter";
import { WTypography } from "components/WTypography";
import { LocaleContext } from "context/LocaleContext";
import { colors, defaultStyles } from "styles/colors";
import { User } from "reducers/UserReducer";
import { WIconButton } from "components/styledComponents/WIconButton";
import { WIcon, WIconTypes } from "components/WIcon";

export const GroupCardHeight = "100px";

interface Props {
  group: Group;
  isSelected: boolean;
  isInSelectedGroups: boolean;
  isAllGroups: boolean;
  onClick: () => void;
  onToggleSelectedGroups: (groupId: string) => void;
}
export const GroupCard: FC<Props> = ({
  group,
  isSelected,
  isInSelectedGroups,
  isAllGroups,
  onClick,
  onToggleSelectedGroups,
}) => {
  const { localize, currentLanguage } = useContext(LocaleContext);

  const name = currentLanguage === "en" ? group.name_en : group.name_sv;
  const users = useMemo(
    (): User[] => (group.users as User[]).slice().sort((a, b) => a.email.localeCompare(b.email)),
    [group.users]
  );

  const handleToggleSelectedGroups = (groupId: string) => {
    onToggleSelectedGroups(groupId);
  };

  return (
    <Grid
      sx={{
        border: "none !important",
        height: GroupCardHeight,
        padding: "20px",
        backgroundColor: isSelected ? `${colors.main} !important` : colors.white,
        color: isSelected ? colors.white : undefined,
        boxShadow: defaultStyles.cardShadow,
        borderRadius: defaultStyles.borderRadius,
        ":hover": {
          backgroundColor: isSelected ? colors.main : colors.lightGrey,
          cursor: "pointer",
        },
      }}
      onClick={onClick}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        height="100%"
        gap="5px"
        alignItems="flex-start"
      >
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <WTypography variant="h2" fontWeight="bold" fontSize="1.2rem" textAlign="left">
              {name}
            </WTypography>
          </Grid>
          {(isAllGroups || isInSelectedGroups) && (
            <Tooltip
              title={
                isInSelectedGroups
                  ? localize("groups.removeToSelectedGroups")
                  : localize("groups.addToSelectedGroups")
              }
              placement="top-end"
              arrow
              disableInteractive
            >
              <WIconButton
                size="small"
                sx={{ minWidth: "35px" }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleToggleSelectedGroups(group.id);
                }}
              >
                <WIcon
                  icon={WIconTypes.heart}
                  size="small"
                  color="success"
                  fill={isInSelectedGroups}
                />
              </WIconButton>
            </Tooltip>
          )}
        </Grid>

        <Grid item>
          <WTypography variant="body2">
            {localize("common.candidates")}: {users.length}
          </WTypography>
        </Grid>
      </Grid>
    </Grid>
  );
};
