import { Grid, Typography } from "@mui/material";
import { postLogin } from "adapters";
import { AuthMethods, getSocialLoginOptions, postSocialLogin } from "adapters/AuthAdapter";
import { WButton, WIcon, WIconTypes, WLoadingButton, WTextField } from "components";
import { GTMContext } from "context/GTMContext";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { User } from "reducers/UserReducer";

type LoginState = {
  username: string;
  password: string;
};

interface SocialButtons extends AuthMethods {
  image?: string;
}

interface LoginFormProps {
  registerButton?: boolean;
  prefilledData?: Partial<LoginState>;
  onSubmit: (data: User) => void;
}

export const LoginForm: FC<LoginFormProps> = ({ registerButton, prefilledData, onSubmit }) => {
  const { localize } = useContext(LocaleContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { login } = useContext(UserContext);
  const { addMessage } = useContext(NotificationContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);

  const [socialButtons, setSocialButtons] = useState<SocialButtons[]>([]);

  const history = useHistory();
  const { register, handleSubmit, setValue, formState } = useForm<LoginState>();

  const { errors } = formState;

  useEffect(() => {
    if (prefilledData) {
      setValue("username", prefilledData.username || "");
      setValue("password", prefilledData.password || "");
    }
  }, []);

  useEffect(() => {
    fetchSocialLoginOptions();
  }, []);

  const handleSocialLogin = (path: string) => {
    postSocialLogin(path)
      .then((res) => {
        if (res.url) {
          window.location.href = res.url;
        }
      })
      .catch(() => {
        addMessage({ content: "form.socialLoginFailed", type: "error" });
      });
  };

  const parseSocialOptionsData = (option: AuthMethods) => {
    const button: SocialButtons = { ...option };
    if (button.name) {
      if (button.name.includes("google")) {
        button.name = "Google";
        button.image = `${process.env.PUBLIC_URL}/assets/logo/google_logo.png`;
        return button;
      } else if (button.name.includes("linkedin")) {
        button.name = "LinkedIn";
        button.image = `${process.env.PUBLIC_URL}/assets/logo/linkedin_logo.png`;

        return button;
      }
    }
    return button;
  };

  const fetchSocialLoginOptions = () => {
    getSocialLoginOptions()
      .then((res) => {
        setSocialButtons(res.data.map((option) => parseSocialOptionsData(option)));
      })
      .catch(() => {
        addMessage({ content: "form.socialLoginFailed", type: "error" });
      });
  };
  const submitLoginForm = async (data: LoginState) => {
    dispatchLoading({ type: "SET_LOADING", payload: "POST_LOGIN" });

    postLogin(data)
      .then((res) => {
        login();
        gtmAddToDataLayer({ user_id: res.data.id }, "log_in");
        onSubmit(res.data);
      })
      .catch(() => {
        addMessage({ content: "error.loginError", type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "POST_LOGIN" });
      });
  };

  const handleRegisterClick = () => {
    history.push("/register");
    gtmAddToDataLayer({ action: "create_account" }, "cta_click");
  };

  return (
    <Grid item xs={10} md={6}>
      <form onSubmit={handleSubmit(submitLoginForm)}>
        <Grid gap="1rem" container justifyContent="center">
          <WTextField
            autoComplete="email"
            fullWidth
            label={localize("form.emailField")}
            error={!!errors.username}
            helperText={errors?.username?.message}
            {...register("username", { required: localize("form.requiredFieldError") })}
          />
          <Grid container direction="column" alignItems="flex-end">
            <WTextField
              fullWidth
              autoComplete="current-password"
              label={localize("form.passwordField")}
              error={!!errors.password}
              helperText={errors?.password?.message}
              {...register("password", {
                required: localize("form.requiredFieldError"),
                minLength: {
                  value: 8,
                  message: localize("form.fieldMinLengthError", { length: "8" }),
                },
                maxLength: {
                  value: 128,
                  message: localize("form.fieldMaxLengthError", { length: "128" }),
                },
              })}
              type="password"
            />
          </Grid>
          <WLoadingButton
            fullWidth
            displayLoading={isLoading("POST_LOGIN")}
            variant="contained"
            type="submit"
            startIcon={<WIcon color="secondary" icon={WIconTypes.login} />}
          >
            {localize("form.login")}
          </WLoadingButton>
          <WButton variant="text" onClick={() => history.push("/forgotPassword")}>
            {localize("form.forgotPassword")}
          </WButton>
          {socialButtons.map((option) => {
            return (
              <WLoadingButton
                key={`social_login_Option_${option.name}`}
                fullWidth
                variant="contained"
                color="secondary"
                displayLoading={isLoading("POST_LOGIN")}
                onClick={() => {
                  handleSocialLogin(option.url);
                }}
              >
                {option.image && (
                  <img
                    src={option.image}
                    alt={`${option.name} logo`}
                    width="24px"
                    style={{
                      objectFit: "contain",
                      objectPosition: "top",
                      position: "absolute",
                      left: "1rem",
                      top: "6px",
                    }}
                  />
                )}
                {localize("form.socialLoginButton")}
                {option.name}
              </WLoadingButton>
            );
          })}
          {registerButton && (
            <Grid container direction="column" marginTop="auto">
              <Typography variant="body2">
                {localize("form.registerHelpText")}
                <WButton variant="text" onClick={handleRegisterClick}>
                  {localize("form.registerAndVerify")}
                </WButton>
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </Grid>
  );
};
