import { Grid, Typography, Input, FormHelperText } from "@mui/material";
import React, { FC, useContext, FormEvent } from "react";
import { LocaleContext } from "context/LocaleContext";
import { validateFieldLength } from "components/forms/validators";
import { defaultStyles, colors } from "styles/colors";
import { WIcon, WIconTypes, WButton } from "components";
import { BreakpointsContext } from "context/BreakpointContext";

export type ChatState = {
  chatInput: string;
  error: boolean;
};

interface MessageInputProps {
  conversationName?: string;
  onSubmit: (event: FormEvent) => void;
  state: ChatState;
  setState: (state: ChatState) => void;
}

export type SocketActions = "SOCKET_CONNECTING";

export const MessageInput: FC<MessageInputProps> = ({
  conversationName,
  onSubmit,
  state,
  setState,
}) => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const handleChatInput = (message: string) => {
    setState({
      ...state,
      error: !validateFieldLength(message, 256),
      chatInput: message,
    });
  };

  return (
    <Grid container direction="column" gap="1rem">
      {conversationName && <Typography variant="h6">{conversationName}</Typography>}
      <Grid
        container
        sx={{
          outline: state.error ? "1px solid #d32f2f" : undefined,
          backgroundColor: "secondary.main",
          border: `solid 1px ${colors.darkGrey}`,
          borderRadius: defaultStyles.borderRadius,
          position: "sticky",
          bottom: 0,
        }}
        padding="0 1rem"
      >
        <Grid container wrap="nowrap" gap="1rem" padding=".5rem 0">
          <Input
            fullWidth
            placeholder={localize("messagesView.writeMessagePlaceholder")}
            onChange={(event) => handleChatInput(event.target.value)}
            value={state.chatInput}
            disableUnderline
            type="text"
            error={state.error}
            sx={{
              "& input": {
                padding: 0,
              },
            }}
          />
          {!isDesktop && (
            <WButton onClick={onSubmit} type="submit">
              <WIcon
                icon={WIconTypes.send}
                color={state.error || !state.chatInput.length ? "disabled" : "primary"}
              />
            </WButton>
          )}
        </Grid>
        {state.error && (
          <FormHelperText error>
            {localize("form.fieldMaxLengthError", { length: "256" })}
          </FormHelperText>
        )}
      </Grid>
      {isDesktop && (
        <WButton
          variant="outlined"
          sx={{ maxWidth: "max-content", marginLeft: "auto" }}
          disabled={state.error || !state.chatInput.length}
          startIcon={
            <WIcon
              icon={WIconTypes.send}
              color={state.error || !state.chatInput.length ? "disabled" : "primary"}
            />
          }
          onClick={onSubmit}
          type="submit"
        >
          {localize("common.sendMessage")}
        </WButton>
      )}
    </Grid>
  );
};
