import React, { FC, useContext } from "react";
import { Grid } from "@mui/material";
import {
  ApplyAssignmentSVG,
  CompleteProfileSVG,
  CreateProfileTwoSVG,
  ManageProcessSVG,
  MatchingProposalsSVG,
  SearchManyAssignmentsTwoSVG,
} from "components/assets";
import { LocaleContext } from "context/LocaleContext";
import { useDynamicPageContent } from "hooks/useDynamicPageContent";
import { DynamicViewContent, WButton } from "components";
import { UserContext } from "context/UserContext";
import { useHistory } from "react-router-dom";
import { BreakpointsContext } from "context/BreakpointContext";
import { MOBILE_ACTION_BAR_SIZE } from "components/layout/ActionNavbar/DefaultActionNavbar";
import { ExplanationCard } from "components/ExplanationSVGCard";

export const HowItWorksView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const { isAuthenticated } = useContext(UserContext);

  const history = useHistory();

  const explanationCards: ExplanationCard[] = [
    {
      svg: <ApplyAssignmentSVG />,
      title: `1. ${localize("howItWorks.applyTitle")}`,
      subTitle: localize("howItWorks.applySubTitle"),
    },
    {
      svg: <CreateProfileTwoSVG />,
      title: `2. ${localize("howItWorks.createProfileTitle")}`,
      subTitle: localize("howItWorks.createProfileSubTitle"),
    },
    {
      svg: <CompleteProfileSVG />,
      title: `3. ${localize("howItWorks.completeProfileTitle")}`,
      subTitle: localize("howItWorks.completeProfileSubTitle"),
    },
    {
      svg: <MatchingProposalsSVG />,
      title: `4. ${localize("howItWorks.matchingTitle")}`,
      subTitle: localize("howItWorks.matchingSubTitle"),
    },
    {
      svg: <SearchManyAssignmentsTwoSVG />,
      title: `5. ${localize("howItWorks.searchAssignmentsTitle")}`,
      subTitle: localize("howItWorks.searchAssignmentsSubTitle"),
    },
    {
      svg: <ManageProcessSVG />,
      title: `6. ${localize("howItWorks.processTitle")}`,
      subTitle: localize("howItWorks.processSubTitle"),
    },
  ];

  const { pageContent, pageHTMLContent } = useDynamicPageContent({ pageType: "how_it_works" });

  return (
    <Grid container position="relative" paddingTop={isDesktop ? 0 : MOBILE_ACTION_BAR_SIZE}>
      <DynamicViewContent
        pageContent={pageContent}
        explanationCards={explanationCards}
        pageHTMLContent={pageHTMLContent}
        explanationCardsVariant="horizontal"
      />
      {!isAuthenticated && (
        <Grid container justifyContent="center">
          <WButton variant="contained" onClick={() => history.push("/login")}>
            {localize("dynamicViewsCTA.getStarted")}
          </WButton>
        </Grid>
      )}
    </Grid>
  );
};
