export const validateEmail = (
  value: string,
  localize: (text: string) => string
): string | boolean => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(value) || localize("form.emailFieldError");
};

export const validateFieldLength = (value: string, maxLength: number): boolean => {
  return value.trim().length <= maxLength;
};

export const validateLinkedInLink = (
  value: string,
  localize: (text: string) => string
): string | boolean => {
  const regex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm;
  return regex.test(value) || localize("form.emailLinkError");
};
