import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";
import { Job, KanbanJob } from "./JobAdapter";
import { Referral } from "./ReferralAdapter";
import { Skill } from "./SkillsAdapter";
import { CV } from "./UserCvAdapter";
import { RoleWithDepartment } from "./DepartmentRoleAdapter";

export type StatusStage = {
  status: string;
  active: boolean;
};
export type JobApplication = {
  id: string;
  created: string;
  updated: string;
  first_name: string;
  last_name: string;
  email: string;
  cv?: CV;
  cover_letter: string;
  is_canceled: boolean;
  phone: string;
  job: Job;
  skills: Skill[];
  referrals: Referral[];
  is_draft: boolean;
  availability_date?: string;
  price: string;
  status?: string;
  status_progression: StatusStage[];
  kanban_job_application_stage_order_no?: number;
  stage?: string;
  rejection_date: string;
  is_proposal_sent?: boolean;
  user: {
    id: string;
    profile_picture?: string;
    first_name: string;
    last_name: string;
    email: string;
    rating?: number;
    departments: RoleWithDepartment[];
  };
};

export type KanbanJobApplication = {
  id: string;
  job: KanbanJob;
  kanban_job_application_stage_order_no?: number;
  stage?: string;

  user: {
    id: string;
    profile_picture?: string;
    first_name: string;
    last_name: string;
    email: string;
    rating?: number;
    departments: RoleWithDepartment[];
  };
};

export type JobApplicationActions =
  | "GET_JOB_APPLICATION_LIST"
  | "GET_JOB_APPLICATION"
  | "CREATE_JOB_APPLICATION"
  | "VERIFY_JOB_APPLICATION";

export const getJobApplicationList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<JobApplication>>> => {
  return API.get("/job-applications/", { params: params });
};

export const getKanbanJobApplicationList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<KanbanJobApplication>>> => {
  return API.get("/job-applications/kanban-job-applications/", { params: params });
};

export const getJobApplication = async (
  applicationId: string
): Promise<AxiosResponse<JobApplication>> => {
  return API.get(`/job-applications/${applicationId}/`);
};

export const deleteJobApplication = async (applicationId: string): Promise<void> => {
  return API.delete(`/job-applications/${applicationId}/`);
};

export type CreateJobApplicationForm = {
  email: string;
  cv?: string | File;
  cv_alias?: string;
  cover_letter?: string;
  phone?: string;
  job: string;
  skills?: (Skill | string)[];
  referrals?: (Referral | string)[];
  first_name?: string;
  last_name?: string;
  availability_date?: string;
  price?: string;
  is_draft: boolean;
  language?: string;
  kanban_job_application_stage_order_no?: number;
  stage?: string;
};

type CreateJobApplicationFormResponse = {
  id: string;
};

const parseAsFormData = (data: Partial<CreateJobApplicationForm>) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].map((value: string, index: number) => {
        formData.append(
          `${key}[${index}]`,
          typeof value === "object" ? JSON.stringify(value) : value
        );
      });
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

export const createJobApplication = async (
  data: CreateJobApplicationForm
): Promise<{ data: CreateJobApplicationFormResponse }> => {
  return API.post(`/job-applications/`, parseAsFormData(data), {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const updateJobApplication = async (
  applId: string,
  data: Partial<CreateJobApplicationForm>
): Promise<AxiosResponse<JobApplication>> => {
  return API.patch(`/job-applications/${applId}/`, parseAsFormData(data), {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getUserApplications = async (
  userId: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<JobApplication>>> => {
  return API.get(`/job-applications/user/${userId}/`, { params });
};

export const verifyJobApplication = async (jobId: string): Promise<void> => {
  return API.post(`/job-applications/${jobId}/verify/`);
};

export const updateKanbanJobApplication = async (
  applId: string,
  data: Partial<CreateJobApplicationForm>
): Promise<AxiosResponse<JobApplication>> => {
  return API.patch(`/job-applications/${applId}/kanban/`, parseAsFormData(data), {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
