import AgentsList from "components/StatisticView/AgentsList";
import { AgentSpecificCards } from "components/StatisticView/AgentSpecificCards";
import { AgentTrendOverview } from "components/StatisticView/AgentTrendOverview";
import { WIconTypes } from "components/WIcon";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { LoaderContext } from "context/LoaderContext";
import { Agent, AgentDetails, getAgentDetails } from "adapters";
import { NotificationContext } from "context/NotificationContext";
import { prettifyDate } from "utils/PrettifyDate";
import { getDateFromCurrent } from "utils/TimeUnitToDate";
import { StatisticsFilterContext } from "context/StatisticsFilterContext";
import { colors, defaultStyles } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";

export const AgentsTab: FC = () => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { period } = useContext(StatisticsFilterContext);
  const { localize } = useContext(LocaleContext);

  const [agentDetails, setAgentDetails] = useState<AgentDetails>(defaultAgentDetails);
  const [previousAgentDetails, setPreviousAgentDetails] =
    useState<AgentDetails>(defaultAgentDetails);

  const [selectedAgent, setSelectedAgent] = useState<Agent>();
  const [selectedCard, setSelectedCard] = useState("messages_sent_period");

  const periodLength = period === "week" ? 7 : 30;

  const getData = useCallback(async () => {
    if (!selectedAgent) {
      return;
    }

    dispatchLoading({ type: "SET_LOADING", payload: "GET_AGENT_DETAILS" });
    await Promise.all([
      getAgentDetails({
        agent_id: selectedAgent.id,
        from_date: prettifyDate(getDateFromCurrent(-periodLength + 1).toISOString()),
        to_date: prettifyDate(new Date().toISOString()),
      })
        .then((res) => {
          setAgentDetails(res.data);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.fetchingAgentDetails", duration: 2000 });
        }),
      getAgentDetails({
        agent_id: selectedAgent.id,
        from_date: prettifyDate(getDateFromCurrent(-periodLength * 2 + 1).toISOString()),
        to_date: prettifyDate(getDateFromCurrent(-periodLength).toISOString()),
      })
        .then((res) => {
          setPreviousAgentDetails(res.data);
        })
        .catch(() => {
          addMessage({
            type: "error",
            content: "error.fetchingAgentDetails",
            duration: 2000,
          });
        }),
    ]);
    dispatchLoading({ type: "STOP_LOADING", payload: "GET_AGENT_DETAILS" });
  }, [selectedAgent, period]);

  useEffect(() => {
    getData();
  }, [getData]);

  const cards = useMemo(
    () => [
      {
        total: agentDetails.messages_sent,
        previous: previousAgentDetails.messages_sent,
        name: localize("statisticView.sentMessages"),
        icon: WIconTypes.message,
        monthLabel: "messages_sent_period",
      },
      {
        total: agentDetails.ratings_changed,
        previous: previousAgentDetails.ratings_changed,
        name: localize("statisticView.changedRatings"),
        icon: WIconTypes.star,
        monthLabel: "ratings_changed_period",
      },
      {
        total: agentDetails.notes_posted,
        previous: previousAgentDetails.notes_posted,
        name: localize("statisticView.createdNotes"),
        icon: WIconTypes.fileText,
        monthLabel: "notes_posted_period",
      },
      {
        total: agentDetails.logged_in,
        previous: previousAgentDetails.logged_in,
        name: localize("statisticView.numberOfLogins"),
        icon: WIconTypes.login,
        monthLabel: "logged_in_period",
      },
    ],
    [agentDetails, previousAgentDetails]
  );

  return (
    <Grid container width="100%" wrap="nowrap" gap="30px">
      <Grid container xs={6} gap="30px">
        <AgentTrendOverview />
        <Grid
          item
          flexDirection="column"
          width="100%"
          minWidth="350px"
          sx={{
            padding: "10px",
            backgroundColor: colors.white,
            borderRadius: defaultStyles.borderRadius,
            boxShadow: defaultStyles.cardShadow,
          }}
        >
          <AgentsList
            setSelectedAgent={(agent: Agent) => {
              setSelectedAgent(agent);
            }}
            selectedAgent={selectedAgent}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={10}
        sx={{
          padding: "20px",
          backgroundColor: colors.white,
          borderRadius: defaultStyles.borderRadius,
          boxShadow: defaultStyles.cardShadow,
        }}
      >
        <Grid container>
          {selectedAgent ? (
            <>
              <Grid item alignItems="center" margin="auto" marginBottom="30px">
                <Typography variant="h5" fontWeight="bold" alignItems="center">
                  {selectedAgent.full_name}
                </Typography>
              </Grid>

              <AgentSpecificCards
                cards={cards}
                selectedCard={selectedCard}
                setSelectedCard={(name: string) => {
                  setSelectedCard(name);
                }}
                selectedAgentID={selectedAgent.id}
              />
            </>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

const defaultAgentDetails: AgentDetails = {
  last_login: "",
  logged_in: 0,
  messages_sent: 0,
  notes_posted: 0,
  ratings_changed: 0,
  logged_in_period: [],
  messages_sent_period: [],
  notes_posted_period: [],
  ratings_changed_period: [],
};
