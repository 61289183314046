import React, { FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Box, Grid, IconButton, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { DropArea } from "components/DragAndDrop/DropArea";
import { colors, defaultStyles } from "styles/colors";
import { WIcon, WIconTypes } from "components/WIcon";
import { WMenu } from "components/styledComponents/WMenu";
import { LocaleContext } from "context/LocaleContext";

interface Props {
  title: string | ReactNode;
  group: string;
  id: string;
  children?: ReactNode;
}

export const KanbanColumnWidth = "280px";
export const KanbanItemWidth = `calc(${KanbanColumnWidth} - 16px * 2)`;

export const KanbanColumn: FC<Props> = ({ title, group, id, children }) => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const hidden = localStorage.getItem(`column_hidden_${id}`);
    setIsHidden(hidden === "true");
  }, []);

  const onToggleVisible = useCallback(() => {
    localStorage.setItem(`column_hidden_${id}`, !isHidden ? "true" : "false");
    setIsHidden((hidden) => !hidden);
  }, [isHidden]);

  if (isHidden) {
    return (
      <Box
        padding="2px"
        paddingTop="8px"
        borderRadius="10px"
        bgcolor={colors.white}
        boxShadow={defaultStyles.bigCardShadow}
      >
        <MenuButton onClick={onToggleVisible} isHidden={isHidden} />
        <div
          style={{
            display: "flex",
            margin: "10px auto",
            writingMode: "vertical-lr",
            textOrientation: "upright",
          }}
        >
          {title}
        </div>
      </Box>
    );
  }

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      minHeight="100vh"
      width={KanbanColumnWidth}
      borderRadius={defaultStyles.borderRadius}
      bgcolor={colors.white}
      boxShadow={defaultStyles.bigCardShadow}
    >
      <Box
        position="sticky"
        top={0}
        bgcolor={colors.white}
        zIndex={5}
        borderRadius={defaultStyles.borderRadius}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="16px"
        paddingTop="8px"
      >
        {title}
        <MenuButton onClick={onToggleVisible} isHidden={isHidden} />
      </Box>
      <DropArea
        group={group}
        id={id}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          height: "100%",
          padding: 16,
        }}
      >
        {children}
      </DropArea>
    </Grid>
  );
};

interface MenuButtonProps {
  onClick: () => void;
  isHidden: boolean;
}
const MenuButton: FC<MenuButtonProps> = ({ onClick, isHidden }) => {
  const { localize } = useContext(LocaleContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  return (
    <>
      <IconButton
        size="small"
        sx={{
          "& svg": {
            stroke: colors.main,
          },
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <WIcon icon={WIconTypes.moreVertical} />
      </IconButton>
      <WMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        fullwidth={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            minWidth: "150px",
            maxWidth: "150px",
          },
        }}
      >
        {isHidden ? (
          <MenuItem onClick={onClick}>
            <ListItemIcon>
              <WIcon icon={WIconTypes.show} size="small" />
            </ListItemIcon>
            <ListItemText>{localize("kanbanView.show")}</ListItemText>
          </MenuItem>
        ) : (
          <MenuItem onClick={onClick}>
            <ListItemIcon>
              <WIcon icon={WIconTypes.hide} size="small" />
            </ListItemIcon>
            <ListItemText>{localize("kanbanView.hide")}</ListItemText>
          </MenuItem>
        )}
      </WMenu>
    </>
  );
};
