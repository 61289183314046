import React, { FC, useContext } from "react";
import { Grid } from "@mui/material";
import { colors } from "styles/colors";
import { WTypography } from "components";
import { Line } from "react-chartjs-2";
import { getLastDays } from "utils/TimeUnitToDate";
import { LocaleContext } from "context/LocaleContext";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

interface LineChartCardProps {
  title: string;
  total: number;
  data: number[];
  previousData?: number[];
  target?: number;
  tooltipLabels?: string[];
}

export const LineChartCard: FC<LineChartCardProps> = ({
  title,
  total,
  data,
  previousData,
  target,
  tooltipLabels,
}) => {
  const { localize } = useContext(LocaleContext);
  const periodLength = data.length;
  const xAxisLabels = getLastDays(periodLength);
  const commonSettings = {
    fill: false,
    tension: 0.3,
  };

  const datasets = [
    {
      label:
        tooltipLabels?.[0] || localize("statisticView.lastDays", { days: periodLength.toString() }),
      data: data,
      backgroundColor: colors.mainLight,
      borderColor: colors.mainLight,
      pointBackgroundColor: colors.main,
      pointBorderColor: colors.main,
      pointRadius: 4,
      pointHitRadius: 5,
      pointHoverRadius: 5,
      ...commonSettings,
    },
  ];

  if (previousData) {
    datasets.push({
      label: tooltipLabels?.[1] || localize("statisticView.lastDays", { days: "30" }),
      data: previousData,
      backgroundColor: colors.mainDarkRed,
      borderColor: colors.mainDarkRed,
      pointBackgroundColor: colors.mainDarkRed,
      pointBorderColor: colors.mainDarkRed,
      pointRadius: 4,
      pointHitRadius: 5,
      pointHoverRadius: 5,
      ...commonSettings,
    });
  }

  return (
    <Grid container flexDirection="column">
      <Grid container alignItems="center">
        <Grid item>
          <WTypography variant="h5" fontWeight="bold">
            {total}
          </WTypography>
        </Grid>
        {target && target !== -1 ? (
          <>
            <Grid item ml="25px">
              <WTypography fontSize="body1" color={colors.darkGrey}>
                {localize("statisticView.target")}:
              </WTypography>
            </Grid>
            <Grid item ml="3px">
              <WTypography>{target}</WTypography>
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid item height="18rem">
        <Line
          data={{
            datasets,
            labels: xAxisLabels,
          }}
          options={{
            scales: {
              xAxes: {
                grid: {
                  display: false,
                },
              },
              yAxes: {
                beginAtZero: true,
                suggestedMax: 10,

                grid: {
                  lineWidth: 0.4,
                },
              },
            },
            plugins: {
              legend: { display: false },
              tooltip: {
                intersect: false,
                mode: "nearest",
                axis: "x",
                callbacks: {
                  title: () => {
                    return localize(`statisticView.${title}`);
                  },
                },
              },
            },
            maintainAspectRatio: false,
          }}
        />
      </Grid>
    </Grid>
  );
};
