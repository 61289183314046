import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { usePagination } from "hooks/usePagination";
import { Dialog, Grid, IconButton } from "@mui/material";
import {
  AgentNote,
  Job,
  JobApplication,
  UserDocument,
  createAgentNote,
  getAgentNotes,
  getUser,
  getUserApplications,
} from "adapters";
import { CandidateDetails } from "components/modals/CandidateModal/CandidateDetails";
import { CandidateDocuments } from "components/modals/CandidateModal/CandidateDocuments";
import { CandidateJobApplications } from "components/modals/CandidateModal/CandidateJobApplications";
import { CandidateNotes } from "components/modals/CandidateModal/CandidateNotes";
import { CandidateReferences } from "components/modals/CandidateModal/CandidateReferences";
import { WIcon, WIconTypes } from "components/WIcon";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { UserContext } from "context/UserContext";
import { colors, defaultStyles } from "styles/colors";
import { GeneralInformation } from "./GeneralInformation";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { User } from "reducers/UserReducer";
import { TasksColumn } from "components/Tasks/TasksColumn";
import { NotificationContext } from "context/NotificationContext";
import {
  InternalJobApplication,
  getInternalJobApplicationList,
} from "adapters/InternalJobApplicationAdapter";

interface props {
  candidateId: string;
  selectedApplication?: {
    id?: string;
    job?: Job;
    isInternal?: boolean;
    created?: string;
  };
  onUpdateInternalApplication?: (action: "delete" | "send_proposal") => void;
}

export const CandidateModal: FC<props> = ({
  candidateId,
  selectedApplication,
  onUpdateInternalApplication,
}) => {
  const { localize } = useContext(LocaleContext);
  const { user, isAgent, users, dispatch } = useContext(UserContext);
  const { addMessage } = useContext(NotificationContext);
  const { hideModal } = useContext(ModalContext);

  const [storedSelectedApplication, setStoredSelectedApplication] = useState<
    | {
        id?: string;
        job?: Job;
        isInternal?: boolean;
        created?: string;
      }
    | undefined
  >(undefined);
  const [candidate, setCandidate] = useState<User | null>(null);
  const [notes, setNotes] = useState<AgentNote[]>([]);

  const {
    results: applications,
    setLastElementRef: setApplicationsLastElementRef,
    count,
  } = usePagination<JobApplication>(
    (params?: Record<string, unknown>) => getUserApplications(candidateId, params),
    "error.fetchingJobApplications",
    "GET_JOB_APPLICATION_LIST",
    { page_size: 10 }
  );
  const { results: internalApplications, getInitial: fetchAllInternalApplications } =
    usePagination<InternalJobApplication>(
      getInternalJobApplicationList,
      "error.fetchingInternalJobApplications",
      "GET_INTERNAL_JOB_APPLICATION_LIST",
      { page_size: 100, user: candidateId }
    );

  const { pageResults: notePageResults, setLastElementRef: setAgentNotesLastElementRef } =
    usePagination<AgentNote>(getAgentNotes, "error.fetchAgentNoteList", "GET_AGENT_NOTES", {
      page_size: 7,
      user: candidateId,
    });

  const { count: notesCount, getInitial: fetchNewNotesCount } = usePagination<AgentNote>(
    getAgentNotes,
    "error.fetchAgentNoteList",
    "GET_AGENT_NOTES",
    {
      page_size: 0,
      user: candidateId,
    }
  );

  useEffect(() => {
    setStoredSelectedApplication(selectedApplication);
  }, [selectedApplication]);

  useEffect(() => {
    setNotes([...notes, ...notePageResults]);
  }, [notePageResults]);

  useEffect(() => {
    if (candidate) {
      return;
    }
    const u = users.find((u) => u.id === candidateId);
    if (u) {
      setCandidate(u);
      return;
    }
    (async () => {
      const fetchedUser = await getUser(candidateId);
      setCandidate(fetchedUser.data);
    })();
  }, [users]);

  const sideTabNames = [localize("candidateModal.tasks"), localize("candidateModal.notes")];
  const [sideTabPage, setSideTabPage] = useState(sideTabNames[0]);

  const updateReferences = (referralId: string, newComment: string) => {
    if (!candidate) {
      return;
    }

    const candidateReferral = candidate.referrals.find((ref) => ref.id === referralId);

    if (candidateReferral) {
      candidateReferral.agent_comment = newComment;
    }
  };

  const nbr_cvs = candidate?.cv.length ?? 0;
  const nbr_documents = candidate?.user_documents.length ?? 0;

  const tabLabels = [
    {
      value: localize("candidateModal.details"),
      label: localize("candidateModal.details"),
    },
    {
      value: localize("candidateModal.documents"),
      label: `${localize("candidateModal.documents")} (${nbr_cvs + nbr_documents})`,
    },
    {
      value: localize("candidateModal.jobApplications"),
      label: `${localize("candidateModal.jobApplications")} (${
        count + internalApplications.length
      })`,
    },
    {
      value: localize("candidateModal.references"),
      label: `${localize("candidateModal.references")} (${candidate?.referrals.length ?? 0})`,
    },
  ];

  const [tabPage, setTabPage] = useState(
    selectedApplication ? tabLabels[2].value : tabLabels[0].value
  );

  const onUpdateRating = useCallback(
    async (rating: number): Promise<void> => {
      if (!candidate) {
        return;
      }
      setCandidate({ ...candidate, rating });
      const date = new Date().toISOString();
      const newNote: AgentNote = {
        id: date,
        author: user,
        note: `Rating: ${rating} stars`,
        created: date,
        updated: date,
        user: candidateId,
        job: null,
      };
      setNotes((notes) => [newNote, ...notes]);
      addMessage({ type: "success", content: "success.ratingChanged" });
      await createAgentNote({ ...newNote, author: user.id });
      fetchNewNotesCount();
    },
    [candidate, setCandidate, setNotes, addMessage, fetchNewNotesCount]
  );

  const addDocument = (newDoc: UserDocument) => {
    if (!candidate) {
      return;
    }
    const updatedDocumentList = [...candidate.user_documents, newDoc];
    setCandidate({ ...candidate, user_documents: updatedDocumentList });

    const index = users.findIndex((u) => u.id === candidate.id);
    users[index].user_documents = updatedDocumentList;
    dispatch({ type: "SET_USER_LIST", users });
  };

  const removeDocument = (documentId: string) => {
    if (candidate) {
      const updatedDocumentList = candidate.user_documents.filter(
        (userDocument) => userDocument.id !== documentId
      );
      setCandidate({ ...candidate, user_documents: updatedDocumentList });

      const index = users.findIndex((u) => u.id === candidate.id);
      users[index].user_documents = updatedDocumentList;
      dispatch({ type: "SET_USER_LIST", users });
    }
  };

  const tabContent = useMemo(() => {
    if (!candidate) {
      return [];
    }
    return [
      {
        value: localize("candidateModal.details"),
        content: <CandidateDetails candidate={candidate} />,
      },
      {
        value: localize("candidateModal.documents"),

        content: (
          <CandidateDocuments
            cvs={candidate.cv}
            userDocuments={candidate.user_documents}
            candidateId={candidateId}
            onAddDocument={(newDocument: UserDocument) => addDocument(newDocument)}
            onRemoveDocument={(documentId: string) => removeDocument(documentId)}
          />
        ),
      },
      {
        value: localize("candidateModal.jobApplications"),
        content: (
          <CandidateJobApplications
            applications={[
              ...(internalApplications as unknown as JobApplication[]),
              ...applications,
            ]}
            selectedApplication={storedSelectedApplication}
            setLastElementRef={setApplicationsLastElementRef}
            onUpdateInternalApplication={(action) => {
              onUpdateInternalApplication?.(action);
              fetchAllInternalApplications();
              if (action === "delete") {
                setStoredSelectedApplication(undefined);
              }
            }}
          />
        ),
      },
      {
        value: localize("candidateModal.references"),
        content: (
          <CandidateReferences
            references={candidate.referrals}
            updateReferences={updateReferences}
          />
        ),
      },
    ];
  }, [
    candidate,
    applications,
    internalApplications,
    selectedApplication,
    storedSelectedApplication,
  ]);

  if (!candidate || !isAgent) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={hideModal}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: defaultStyles.borderRadius,
          height: "100%",
          backgroundColor: colors.white,
        },
      }}
    >
      <Grid item alignSelf="end" position="relative" marginTop="12px">
        <IconButton
          aria-label="close"
          onClick={hideModal}
          size="small"
          sx={{
            position: "absolute",
            top: "-11px",
            left: "-31px",
            padding: "3px",
          }}
        >
          <WIcon icon={WIconTypes.clear} />
        </IconButton>
      </Grid>

      <Grid container wrap="nowrap" gap="1rem" padding="1rem" overflow="hidden" height="100%">
        <Grid item xs={8} overflow="auto">
          <GeneralInformation
            candidate={candidate}
            onUpdateRating={onUpdateRating}
            applications={applications}
            internalApplications={internalApplications}
            onCreateInternal={fetchAllInternalApplications}
          />
          <TabContext value={tabPage}>
            <TabList
              sx={{
                marginTop: "16px",
              }}
              value={tabPage}
              onChange={(_, tabPage: string) => setTabPage(tabPage)}
            >
              {tabLabels.map(({ value, label }) => (
                <Tab key={label} label={label} value={value} sx={{ fontWeight: "bold" }} />
              ))}
            </TabList>

            {tabContent.map(({ value, content }) => (
              <TabPanel key={value} value={value} sx={{ padding: "15px" }}>
                {content}
              </TabPanel>
            ))}
          </TabContext>
        </Grid>

        <Grid
          item
          xs
          bgcolor={colors.mainWithOpacity}
          borderRadius={defaultStyles.borderRadius}
          sx={{ overflowX: "hidden", overflowY: "auto" }}
        >
          <TabContext value={sideTabPage}>
            <TabList
              value={sideTabPage}
              onChange={(event, sideTabPage: string) => setSideTabPage(sideTabPage)}
            >
              {sideTabNames.map((name) => (
                <Tab key={name} label={name} value={name} sx={{ fontWeight: "bold" }} />
              ))}
            </TabList>

            <TabPanel value={sideTabNames[0]} sx={{ padding: 0, overflow: "hidden" }}>
              <TasksColumn filterUserId={candidate.id} dragGroupName="candidateTasks" />
            </TabPanel>
            <TabPanel value={sideTabNames[1]} sx={{ padding: 0 }}>
              <CandidateNotes
                candidate={candidate}
                notes={notes}
                setNotes={(newNotes) => {
                  setNotes(newNotes);
                  fetchNewNotesCount();
                }}
                count={notesCount}
                setLastElementRef={setAgentNotesLastElementRef}
              />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Dialog>
  );
};
