import { Grid, Box, Tooltip } from "@mui/material";
import { CV } from "adapters";
import { WIcon, WIconTypes, WTypography } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, ReactNode, useContext } from "react";
import { colors, defaultStyles } from "styles/colors";
import { prettifyFilename } from "utils/generalUtils";
import { prettifyDate } from "utils/PrettifyDate";

interface CvCardProps {
  icons?: ReactNode;
  isLast?: boolean;
  cv: CV;
  css?: {
    backgroundColor?: string;
    iconSize?: string;
    textColor?: string;
    titleSize?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body1" | "body2";
    subtitleSize?: "body1" | "body2" | "caption";
    border?: string;
    padding?: string;
  };
}

export const CvCard: FC<CvCardProps> = ({ icons, cv, isLast, css }) => {
  const fileExtensionString = cv.user_cv.substring(cv.user_cv.lastIndexOf(".") + 1);
  let fileExtension = "";
  if (fileExtensionString.indexOf("docx") > -1) {
    fileExtension = "docx";
  } else if (fileExtensionString.indexOf("doc") > -1) {
    fileExtension = "doc";
  } else {
    fileExtension = "pdf";
  }

  const iconSize = css?.iconSize ?? "60px";
  const textColor = css?.textColor ?? colors.textDark;
  const { localize } = useContext(LocaleContext);

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        backgroundColor: css?.backgroundColor ?? colors.white,
        borderRadius: isLast ? defaultStyles.borderRadiusBottom : "0",
      }}
      padding={css?.padding ?? "1rem"}
      borderBottom={css?.border ?? `1px solid ${colors.grey}`}
      flexWrap="nowrap"
      gap="1rem"
    >
      <img
        height={iconSize}
        width={iconSize}
        alt="CV Icon"
        src={
          fileExtension === "pdf"
            ? `${process.env.PUBLIC_URL}/assets/images/pdfIcon.png`
            : `${process.env.PUBLIC_URL}/assets/images/wordIcon.png`
        }
      />

      <Box width="65%" textAlign="left">
        <WTypography fontWeight="bold" variant={css?.titleSize ?? "body1"}>
          {prettifyFilename(cv.alias)}
        </WTypography>
        <WTypography variant={css?.subtitleSize ?? "body2"} color={textColor}>{`${prettifyDate(
          cv.created
        )} · ${fileExtension}`}</WTypography>
      </Box>

      {cv.is_cv_warning && (
        <Tooltip title={localize("candidateModal.cv_warning")} placement="top-end">
          <Grid container justifyContent="flex-end">
            <WIcon icon={WIconTypes.alertCircle} size="medium" color="draft" />
          </Grid>
        </Tooltip>
      )}

      {icons && <Grid marginLeft="auto">{icons}</Grid>}
    </Grid>
  );
};
