import React, { FC, useContext } from "react";
import { Grid, Skeleton } from "@mui/material";
import { colors, defaultStyles } from "styles/colors";
import { WTypography } from "components";
import { Bar } from "react-chartjs-2";
import { getLastMonths } from "utils/TimeUnitToDate";
import { LocaleContext } from "context/LocaleContext";
import { Chart, BarController, BarElement, CategoryScale, LinearScale } from "chart.js";

Chart.register(BarController, BarElement, CategoryScale, LinearScale);

interface MonthBarChartCardProps {
  title?: string;
  dataSets: { label: string; data: number[]; color?: string }[];
}

export const MonthBarChartCard: FC<MonthBarChartCardProps> = ({ title, dataSets }) => {
  const { localize } = useContext(LocaleContext);

  const labels = getLastMonths(dataSets[0].data.length).map(
    (index) => localize("statisticView.months").split(",")[index]
  );

  const sets = dataSets.map((set) => {
    return {
      data: set.data,
      label: localize(`statisticView.${set.label}`),
      backgroundColor: [colors.mainLight, colors.main],
      hoverBackgroundColor: [colors.main, colors.mainLight],
      borderRadius: 50,
      borderSkipped: false,
      barPercentage: 0.6,
    };
  });

  return (
    <>
      <Grid container flexDirection="column">
        <WTypography fontSize="0.9rem">{localize(`statisticView.${title}`)}</WTypography>
        <Grid item sx={{ maxWidth: "100%" }} mt="15px">
          {labels.length === 0 ? (
            <Skeleton
              height="250px"
              variant="rectangular"
              width="100%"
              sx={{
                borderRadius: defaultStyles.borderRadius,
              }}
            />
          ) : (
            <Bar
              data={{
                datasets: sets,
                labels,
              }}
              options={{
                scales: {
                  xAxes: {
                    grid: {
                      display: false,
                    },
                    stacked: true,
                  },
                  yAxes: {
                    beginAtZero: true,
                    grid: {
                      lineWidth: 0.4,
                    },
                    stacked: true,
                  },
                },
                plugins: {
                  legend: { display: false },
                  tooltip: {
                    intersect: false,
                    mode: "nearest",
                    axis: "x",
                  },
                },
                maintainAspectRatio: true,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
