import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "./UserContext";
import english from "locales/en.json";
import swedish from "locales/sv.json";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import sv from "date-fns/locale/sv";
import enUS from "date-fns/locale/en-US";

export enum languages {
  "sv" = "sv",
  "sv-SE" = "sv",
  "en" = "en",
  "en-US" = "en",
}

export type LocaleState = {
  currentLanguage: languages;
  locale: JSON;
};

type LocaleProviderType = {
  localize: (textTag: string, replace?: Record<string, string>) => string;
  currentLanguage: languages;
};

export const LocaleContext = createContext<LocaleProviderType>({} as LocaleProviderType);

const LocaleProvider: React.FC = ({ children }) => {
  const { user } = useContext(UserContext);
  const [currentLanguage, setCurrentLanguage] = useState<languages>(
    user.language ? languages[user.language] : languages.sv
  );
  const [locale, setLocale] = useState<JSON>(JSON.parse(JSON.stringify(swedish)));

  useEffect(() => {
    let tempCurrentLanguage = "";
    // Try user.language then localStorage, then finally browser settings, if all else fails, swedish.
    const localStorageLang = localStorage.getItem("lang");
    let browserDefault = navigator.language;

    if (browserDefault.startsWith("en-")) {
      browserDefault = languages.en;
    }
    if (!languages[browserDefault]) {
      browserDefault = "";
    }
    if (user.language) {
      tempCurrentLanguage = user.language;
    } else if (localStorageLang) {
      tempCurrentLanguage = languages[localStorageLang];
    } else if (browserDefault) {
      tempCurrentLanguage = languages[browserDefault];
    } else {
      tempCurrentLanguage = languages.en;
    }

    if (!languages[tempCurrentLanguage]) {
      tempCurrentLanguage = languages.sv;
    }
    setCurrentLanguage(languages[tempCurrentLanguage]);
    localStorage.setItem("lang", tempCurrentLanguage);
    if (tempCurrentLanguage === languages.en) {
      setLocale(JSON.parse(JSON.stringify(english)));
    } else {
      setLocale(JSON.parse(JSON.stringify(swedish)));
    }
  }, [user]);

  const localize = useCallback(
    (textTag: string, replace?: Record<string, string>): string => {
      const paths = textTag.split(".");

      const result = paths.reduce(
        (obj: JSON, key: string) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
        locale
      );

      const text = result ? String(result) : "";

      if (replace && text) {
        return text.replace(
          new RegExp("#" + Object.keys(replace).join("|#"), "g"),
          (match: string): string => {
            return replace[match.substring(1)];
          }
        );
      }

      return text;
    },
    [locale]
  );

  const value = useMemo(() => {
    return { currentLanguage: currentLanguage || "sv", localize };
  }, [currentLanguage, localize]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={currentLanguage === "en" ? enUS : sv}
    >
      <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
    </LocalizationProvider>
  );
};

export default LocaleProvider;
