import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";
import { Location, Skill } from "adapters";
import { JobFilters } from "components/dialogs/FiltersDialog";
import { RoleWithDepartment } from "./DepartmentRoleAdapter";
import { StatusStage } from "./JobApplicationAdapter";

export interface Picture {
  original: string;
  standard: string;
  thumb: string;
}

export interface ActiveJobApplication {
  id: string;
  is_draft: boolean;
  status?: string;
  status_progression: StatusStage[];
}

export const defaultActiveJobApplication: ActiveJobApplication = {
  id: "",
  is_draft: false,
  status: undefined,
  status_progression: [],
};

export interface Recruiter {
  email: string;
  first_name: string;
  last_name: string;
  phone?: string;
  profile_picture?: string;
}

export interface Job {
  id: string;
  title: string;
  locations: Location[];
  pitch?: string;
  skills: Skill[];
  remote_status: string;
  pictures: Picture[];
  created: string;
  start_date: string;
  end_date: string;
  price?: string;
  role?: RoleWithDepartment;
  is_saved: boolean;
  job_status: string;
  body: string;
  recruiter: string | Recruiter;
  job_application?: ActiveJobApplication;
  kanban_job_stage?: string;
  kanban_job_stage_order_no?: number;
  kanban_job_applications_process: {
    job_application_counts: KanbanApplicationStage[];
    total: number;
  };
}

export interface KanbanJob {
  id: string;
  title: string;
  locations: Location[];
  job_status: string;
  recruiter: string | Recruiter;
  pictures: Picture[];
  kanban_job_stage?: string;
  kanban_job_stage_order_no?: number;
  kanban_job_applications_process: {
    job_application_counts: KanbanApplicationStage[];
    total: number;
  };
}

export interface JobStatusChoices {
  job_status_choices: string[];
}

export interface KanbanJobStage {
  id: string;
  name_sv: string;
  name_en: string;
  order_no: number;
}

export interface KanbanApplicationStage {
  id: string;
  name: string;
  count: number;
}

export type JobQueryParams = JobFilters & {
  search: string;
};

export type JobActions =
  | "GET_JOB_LIST"
  | "GET_JOB_LIST_STARTPAGE"
  | "GET_JOB"
  | "GET_JOB_STATUS_CHANGES"
  | "GET_KANBAN_STAGE_LIST";

export const getJobList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<Job>>> => {
  return API.get("/jobs/", { params });
};

export const getKanbanJobList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<KanbanJob>>> => {
  return API.get("/jobs/kanban-jobs/", { params });
};

export const getJob = async (jobId: string): Promise<AxiosResponse<Job>> => {
  return API.get(`/jobs/${jobId}/`);
};

export const getKanbanJob = async (jobId: string): Promise<AxiosResponse<KanbanJob>> => {
  return API.get(`/jobs/${jobId}/kanban-job/`);
};

export const getJobFromExtRef = async (jobId: string): Promise<AxiosResponse<Job>> => {
  return API.get(`/jobs/ext-ref/${jobId}/`);
};

export const saveJob = async (jobId: string): Promise<void> => {
  return API.post(`jobs/${jobId}/save/`);
};

export const shareJob = async (jobId: string, email: string): Promise<void> => {
  return API.post(`jobs/${jobId}/share/`, { email: email });
};

export const removeSavedJob = async (jobId: string): Promise<void> => {
  return API.delete(`jobs/${jobId}/remove-save/`);
};

export const getJobStatusChoices = async (): Promise<AxiosResponse<JobStatusChoices>> => {
  return API.get(`/jobs/job-status-choices/`);
};

export const startConversation = async (
  jobId: string
): Promise<AxiosResponse<{ conv_id: string }>> => {
  return API.post(`/jobs/${jobId}/contact/`);
};

export const getKanbanJobStageList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<KanbanJobStage>>> => {
  return API.get("/jobs/kanban-job-stage/", { params });
};

export const updateJobKanbanOrder = async (
  id: string,
  data: { kanban_job_stage: string; kanban_job_stage_order_no: number }
): Promise<AxiosResponse<void>> => {
  return API.patch(`/jobs/${id}/`, data);
};
