import { Chip } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { WIcon, WIconTypes } from "components";
import { colors } from "styles/colors";

interface DataChipProps {
  chipLabel: string;
  chipType?: "referral" | "cv" | "departmentRole" | "location";
  endIcon?: "edit" | "delete" | "view";
  endIconClickAction?: (data?: Record<string, unknown>) => void;
  color?: "primary" | "secondary";
  isLink?: boolean;
}

const ShortEllipseText: FC = ({ children }): JSX.Element => {
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-all",
        maxWidth: "100%",
      }}
    >
      {children}
    </div>
  );
};

export const DataChip: FC<DataChipProps> = ({
  chipLabel,
  chipType,
  endIcon,
  endIconClickAction,
  color = "primary",
  isLink = false,
}): JSX.Element => {
  const selectedStartIcon = (): ReactElement | undefined => {
    switch (chipType) {
      case "referral":
        return <WIcon icon={WIconTypes.calendar} size="small" />;
      case "cv":
        return <WIcon icon={WIconTypes.fileText} size="small" />;
      case "departmentRole":
        return <WIcon icon={WIconTypes.work} size="small" />;
      case "location":
        return <WIcon icon={WIconTypes.mapPin} size="small" />;
      default:
        return undefined;
    }
  };
  const selectedEndIcon = (): ReactElement | undefined => {
    switch (endIcon) {
      case "edit":
        return <WIcon icon={WIconTypes.edit} size="small" />;
      case "view":
        return <WIcon icon={WIconTypes.arrowDown} size="small" />;
      case "delete":
        return <WIcon icon={WIconTypes.trash} size="small" />;
      default:
        return undefined;
    }
  };

  return (
    <Chip
      icon={selectedStartIcon()}
      label={<ShortEllipseText>{chipLabel}</ShortEllipseText>}
      variant="filled"
      color={color}
      size="small"
      sx={{
        maxWidth: "100%",
        cursor: isLink ? "pointer" : "auto",
        marginX: "4px",
        backgroundColor: colors.mainWithOpacity,
        color: colors.main,
        ":hover": {
          backgroundColor: isLink ? colors.mainLight : "none",
          color: isLink ? colors.white : colors.main,
        },
      }}
      onDelete={endIconClickAction ? () => endIconClickAction() : undefined}
      deleteIcon={selectedEndIcon()}
    />
  );
};
