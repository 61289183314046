import { Grid } from "@mui/material";
import { WTab } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { PushNotificationContext, PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { LoaderActions } from "reducers/LoaderReducer";
import { colors, defaultStyles } from "styles/colors";
import {
  DASHBOARD_ALL_JOBS_TAB,
  DASHBOARD_APPLICATIONS_TAB,
  DASHBOARD_MATCHES_TAB,
} from "views/Dashboardview";

interface DashboardTabListProps {
  activeTab: number;
  hideTabs?: boolean;
  tabCallback?: (tab: number) => void;
}
export const DashboardTabList: FC<DashboardTabListProps> = ({
  activeTab,
  tabCallback,
  hideTabs = false,
}) => {
  const { isDesktop } = useContext(BreakpointsContext);
  const { dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { isAuthenticated } = useContext(UserContext);
  const { hasNotification } = useContext(PushNotificationContext);

  const history = useHistory();

  const redirectToLogin = () => {
    history.push("/login");
  };

  const isAuthenticatedTabChange = (action: LoaderActions) => {
    dispatchLoading({ type: "SET_LOADING", payload: action });

    if (!tabCallback) {
      return;
    }

    switch (action) {
      case "GET_JOB_MATCHES":
        tabCallback(DASHBOARD_MATCHES_TAB);
        return;
      case "GET_JOB_LIST_STARTPAGE":
        tabCallback(DASHBOARD_ALL_JOBS_TAB);
        return;
      case "GET_JOB_APPLICATION_LIST":
        tabCallback(DASHBOARD_APPLICATIONS_TAB);
        return;
      default:
        return;
    }
  };

  if (isDesktop) {
    return (
      <Grid
        container
        wrap="nowrap"
        gap="1rem"
        visibility={hideTabs ? "hidden" : "visible"}
        borderRadius={defaultStyles.borderRadius}
      >
        <WTab
          active={activeTab === DASHBOARD_ALL_JOBS_TAB}
          label={localize("dashboardView.latestJobs")}
          onTabChange={() => isAuthenticatedTabChange("GET_JOB_LIST_STARTPAGE")}
        />
        <WTab
          active={activeTab === DASHBOARD_MATCHES_TAB}
          label={localize("dashboardView.matchingJobs")}
          onTabChange={() =>
            isAuthenticated ? isAuthenticatedTabChange("GET_JOB_MATCHES") : redirectToLogin()
          }
          hasNotification={isAuthenticated && hasNotification(PUSH_NOTIFICATION_TYPES.jobMatch)}
        />
        <WTab
          active={activeTab === DASHBOARD_APPLICATIONS_TAB}
          label={localize("profileView.tabApplications")}
          onTabChange={() =>
            isAuthenticated
              ? isAuthenticatedTabChange("GET_JOB_APPLICATION_LIST")
              : redirectToLogin()
          }
          hasNotification={
            isAuthenticated && hasNotification(PUSH_NOTIFICATION_TYPES.jobApplication)
          }
        />
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        bgcolor={colors.grey}
        borderRadius={defaultStyles.borderRadiusFullyRounded}
        sx={{
          color: "primary.main",
        }}
        alignItems="center"
        padding="4px"
        gap="4px"
        wrap="nowrap"
      >
        <WTab
          active={activeTab === DASHBOARD_ALL_JOBS_TAB}
          label={localize("dashboardView.latestJobs")}
          onTabChange={() => isAuthenticatedTabChange("GET_JOB_LIST_STARTPAGE")}
        />
        <WTab
          active={activeTab === DASHBOARD_MATCHES_TAB}
          label={localize("dashboardView.matchingJobs")}
          onTabChange={() =>
            isAuthenticated ? isAuthenticatedTabChange("GET_JOB_MATCHES") : redirectToLogin()
          }
          hasNotification={isAuthenticated && hasNotification(PUSH_NOTIFICATION_TYPES.jobMatch)}
        />
        <WTab
          active={activeTab === DASHBOARD_APPLICATIONS_TAB}
          label={localize("profileView.tabApplications")}
          onTabChange={() =>
            isAuthenticated
              ? isAuthenticatedTabChange("GET_JOB_APPLICATION_LIST")
              : redirectToLogin()
          }
          hasNotification={
            isAuthenticated && hasNotification(PUSH_NOTIFICATION_TYPES.jobApplication)
          }
        />
      </Grid>
    );
  }
};
