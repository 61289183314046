import dev from "configs/config-dev.json";
import localDevelopment from "configs/config-local-dev.json";
import local from "configs/config-local.json";
import systemTest from "configs/config-system-test.json"
import production from "configs/config-prod.json";

export interface IConfig {
  apiURL: string;
  webSocketURL: string;
}

let config: IConfig = dev;
switch (process.env.REACT_APP_CONFIG) {
  case "development":
    config = dev;
    break;
  case "localDevelopment":
    config = localDevelopment;
    break;
  case "systemTest":
    config = systemTest;
    break;
  case "production":
    config = production;
    break;
  case "local":
  default:
    config = local;
    break;
}
export default config;
