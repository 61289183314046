import React, { FC, useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { LoginForm } from "components/forms/LoginForm";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { verifyMail, VerifyMailResponse } from "adapters/AuthAdapter";
import { LoaderContext } from "context/LoaderContext";
import { SetPasswordForm, SetPasswordState } from "components/forms/SetPasswordForm";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import { WCard } from "components/styledComponents/WCard";
import { JobApplicationVerifyCard } from "components/LoginMailVerifyView/JobApplicationVerifyCard";
import { WIcon, WIconTypes } from "components";
import { GTMContext } from "context/GTMContext";

export const LoginMailVerifyView: FC = () => {
  const { dispatchLoading, isLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { localize } = useContext(LocaleContext);
  const { gtmAddToDataLayer } = useContext(GTMContext);

  const history = useHistory();

  const [verificationMethod, setVerificationMethod] = useState<Partial<VerifyMailResponse>>({
    has_password: true,
    email: "",
  });
  const [mailInvUuid, setMailInvUid] = useState("");
  const [responseType, setResponseType] = useState("");

  const [state, setState] = useState<{
    error: boolean;
    showLogin: boolean;
  }>({
    error: false,
    showLogin: false,
  });

  useEffect(() => {
    const path = window.location.pathname;

    if (path.includes("login/verify")) {
      setResponseType("verify");
      setMailInvUid(path.substring(path.indexOf("=") + 1));
    } else if (path.includes("login/login")) {
      setResponseType("login");
      setMailInvUid(path.substring(path.indexOf("=") + 1));
    } else {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    if (mailInvUuid !== "") {
      dispatchLoading({ type: "SET_LOADING", payload: "VERIFY_MAIL_INV" });
      verifyMail(mailInvUuid, { language: localStorage.getItem("lang") || "en" })
        .then((res) => {
          if (res.data.new_ver_type === "create-user")
            gtmAddToDataLayer({ user_id: res.data.user }, "create_account");

          setVerificationMethod(res.data);
          // timeout for showing the animation of jobapplication checkbox
          setTimeout(() => {
            setState({
              ...state,
            });
          }, 100);
        })
        .catch(() => {
          if (responseType === "login") {
            history.push("/login");
            return;
          }

          setState({
            ...state,
            error: true,
          });
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "VERIFY_MAIL_INV" });
        });
    }
  }, [mailInvUuid]);

  const submitSetPasswordForm = (data: SetPasswordState) => {
    verifyMail(mailInvUuid, data)
      .then((res) => {
        if (res.data.new_ver_type === "set-password")
          gtmAddToDataLayer({ user_id: res.data.user }, "create_account");

        addMessage({ type: "success", content: "success.updateSuccessful" });
        setState({
          ...state,
          showLogin: true,
        });
      })
      .catch(() => {
        addMessage({ type: "error" });
      });
  };

  const renderBody = () => {
    if (isLoading("VERIFY_MAIL_INV")) {
      return <CircularProgress />;
    }

    if (state.error) {
      return (
        <WCard>
          <WIcon icon={WIconTypes.alertCircle} color="error" size="large" />
          <Typography color="error">{localize("error.mailVerification")}</Typography>
          <Typography color="error">
            <Link to="/login"> {localize("common.loginRedirect")}</Link>
          </Typography>
        </WCard>
      );
    }
    // Display job application verification card
    if (verificationMethod.org_ver_type === "validate-job-application" && !state.showLogin) {
      return (
        <JobApplicationVerifyCard
          hasPassword={!!verificationMethod.has_password}
          onSubmit={submitSetPasswordForm}
          onLoginRedirect={() =>
            setState({
              ...state,
              showLogin: true,
            })
          }
        />
      );
    }

    // Display setPassword form
    if (!verificationMethod.has_password && !state.showLogin) {
      return (
        <>
          <Typography variant="h6">{localize("form.setPassword")}</Typography>
          <Typography variant="body1">{localize("form.setPasswordSubTitle")}</Typography>
          <SetPasswordForm onSubmit={submitSetPasswordForm} />
        </>
      );
    }

    // Default: Display Login form
    return (
      <>
        <Typography width="100%" variant="h5" color="primary" fontWeight="bold">
          {localize("navbar.loginText")}
        </Typography>
        <LoginForm
          prefilledData={
            verificationMethod.email
              ? { username: verificationMethod.email, password: "" }
              : undefined
          }
          registerButton={true}
          onSubmit={() => history.push("/")}
        />
      </>
    );
  };

  return (
    <Grid
      container
      className="contentWrapper"
      alignItems="center"
      position="relative"
      sx={{
        flex: "1 1 auto",
      }}
      direction="column"
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        gap="2rem"
        zIndex="2"
        maxWidth="400px"
        minHeight="550px"
        margin="auto"
      >
        {renderBody()}
      </Grid>
    </Grid>
  );
};
