import { Radio, Divider, FormControlLabel, Grid, RadioGroup } from "@mui/material";
import React, { FC, useContext, useState } from "react";

import { OptionsDialog } from "components/dialogs/OptionsDialog";
import { ModalContext } from "context/ModalContext";
import { languages, LocaleContext } from "context/LocaleContext";
import { updateProfile } from "adapters";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";

export const ProfileLanguageModal: FC = () => {
  const { user, dispatch } = useContext(UserContext);
  const { hideModal } = useContext(ModalContext);
  const { localize, currentLanguage } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const [selectedLang, setSelectedLang] = useState<languages>(
    languages[currentLanguage] || languages.sv
  );
  const handleSave = (): void => {
    if (currentLanguage !== selectedLang) {
      updateProfile({ language: selectedLang }, user.id)
        .then(() => {
          dispatch({ type: "UPDATE_USER", user: { ...user, language: languages[selectedLang] } });
          addMessage({ content: "success.language", type: "success", duration: 1500 });
          hideModal();
        })
        .catch(() => {
          addMessage({ content: "error.language", type: "error" });
        });
    } else {
      hideModal();
    }
  };

  return (
    <OptionsDialog
      content={
        <Grid container direction="column">
          <RadioGroup
            sx={{ alignItems: "center" }}
            value={selectedLang}
            onChange={(event) =>
              setSelectedLang(languages[event.currentTarget.value] || languages.sv)
            }
          >
            <Divider sx={{ width: "100%" }} />
            <FormControlLabel
              sx={{ color: "primary.main", padding: "0rem 1rem" }}
              control={<Radio />}
              label="Svenska"
              value={languages.sv}
            />
            <Divider sx={{ width: "100%" }} />
            <FormControlLabel
              sx={{ color: "primary.main", padding: "0rem 1rem" }}
              control={<Radio />}
              label="English"
              value={languages.en}
            />
            <Divider sx={{ width: "100%" }} />
          </RadioGroup>
        </Grid>
      }
      handleClose={() => handleSave()}
      title={localize("profileSections.languages")}
      closeText={localize("form.save")}
    />
  );
};
