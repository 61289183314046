import React, { FC, ReactNode, useContext, useState } from "react";
import { IconButton, MenuItem, ListItemIcon, ListItemText, styled } from "@mui/material";

import { WIcon, WIconTypes } from "components/WIcon";
import { WMenu } from "components/styledComponents/WMenu";
import { LocaleContext } from "context/LocaleContext";
import { colors, defaultStyles } from "styles/colors";

interface Props {
  onDelete: () => void;
  onSendJobProposal: () => void;
}
export const InternalJobAppMenuButton: FC<Props> = ({ onDelete, onSendJobProposal }) => {
  const { localize } = useContext(LocaleContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  let items: ReactNode[] = [];
  if (isConfirmingDelete) {
    items = [
      <RedMenuItem
        key="confirmDelete"
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
          setIsConfirmingDelete(false);
          setAnchorEl(null);
        }}
      >
        <ListItemIcon>
          <WIcon icon={WIconTypes.trash} size="small" />
        </ListItemIcon>
        <ListItemText>{localize("tasks.confirmDelete")}</ListItemText>
      </RedMenuItem>,
      <MenuItem
        key="cancelDelete"
        onClick={(e) => {
          e.stopPropagation();
          setIsConfirmingDelete(false);
        }}
      >
        <ListItemIcon>
          <WIcon icon={WIconTypes.clear} size="small" />
        </ListItemIcon>
        <ListItemText>{localize("common.cancel")}</ListItemText>
      </MenuItem>,
    ];
  } else {
    items = [
      <MenuItem
        key="sendJobProposal"
        onClick={(e) => {
          e.stopPropagation();
          onSendJobProposal();
          setAnchorEl(null);
        }}
      >
        <ListItemIcon>
          <WIcon icon={WIconTypes.mail} size="small" />
        </ListItemIcon>
        <ListItemText>{localize("kanbanView.sendJobProposal")}</ListItemText>
      </MenuItem>,
      <RedMenuItem
        key="delete"
        color={colors.error}
        onClick={(e) => {
          e.stopPropagation();
          setIsConfirmingDelete(true);
        }}
      >
        <ListItemIcon>
          <WIcon icon={WIconTypes.trash} size="small" />
        </ListItemIcon>
        <ListItemText>{localize("common.delete")}</ListItemText>
      </RedMenuItem>,
    ];
  }

  return (
    <>
      <IconButton
        size="small"
        sx={{
          position: "absolute",
          right: "10px",
          top: "15px",

          "& svg": {
            stroke: colors.main,
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <WIcon icon={WIconTypes.moreVertical} />
      </IconButton>
      <WMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        fullwidth={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            minWidth: "200px",
            maxWidth: "0px",
            borderRadius: defaultStyles.borderRadius,
            boxShadow: defaultStyles.bigCardShadow,
          },
        }}
      >
        {items}
      </WMenu>
    </>
  );
};

const RedMenuItem = styled(MenuItem)`
  color: ${colors.error};

  svg {
    stroke: ${colors.error};
  }
`;
