import React, { FC, useContext, useEffect, useState } from "react";
import { Job, getJob } from "adapters";
import { Navbar, WButton, WIcon, WIconTypes } from "components";
import { LoaderContext } from "context/LoaderContext";
import { NotificationContext } from "context/NotificationContext";
import { useHistory, useParams } from "react-router-dom";
import { colors } from "styles/colors";
import { LocaleContext } from "context/LocaleContext";

import { DetailedJobApplicationContentDT } from "components/modals/DetailedJobApplicationModal/DetailedJobApplicationContentDT";

interface ProfileViewProps {
  jobId?: string;
}

const JobApplicationViewDT: FC = () => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const history = useHistory();
  const { jobId } = useParams<ProfileViewProps>();

  const [currJob, setCurrJob] = useState<Job | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (jobId) {
      dispatchLoading({ type: "SET_LOADING", payload: "GET_JOB" });
      getJob(jobId)
        .then((res) => {
          setCurrJob(res.data);
        })
        .catch(() => {
          addMessage({ type: "error", content: "error.fetchingJob", duration: 2000 });
          history.push("/");
        })
        .finally(() => {
          dispatchLoading({ type: "STOP_LOADING", payload: "GET_JOB" });
        });
    }
  }, []);

  return (
    <>
      <Navbar
        title={localize("form.applyForJob")}
        subTitle={currJob?.title}
        centerTitle
        sx={{ backgroundColor: colors.grey, padding: "1rem" }}
        leftSideitem={
          <WButton
            variant="outlined"
            onClick={() => history.push("/")}
            startIcon={<WIcon icon={WIconTypes.arrowLeft} />}
          >
            {localize("common.goBack")}
          </WButton>
        }
      />
      {currJob ? <DetailedJobApplicationContentDT jobId={currJob.id} job={currJob} /> : null}
    </>
  );
};
export default JobApplicationViewDT;
