import { Grid, styled } from "@mui/material";
import { defaultStyles } from "styles/colors";

export const WCard = styled(Grid)(
  ({ theme }) => `
  background-color: ${theme.palette.secondary.main};
  border-radius: ${defaultStyles.borderRadiusLarge};
`
);

WCard.defaultProps = {
  container: true,
  alignItems: "center",
  textAlign: "center",
  padding: "1rem",
  gap: "1rem",
  direction: "column",
};
