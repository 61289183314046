import { getJobList, Job } from "adapters";
import { defaultFilterState, FiltersDialog, JobFilters } from "components/dialogs/FiltersDialog";
import { FixedJobList } from "components/FixedJobList";
import { JobList } from "components/JobList";
import Meta from "components/Meta";
import { LoaderContext } from "context/LoaderContext";
import { usePagination, DEFAULT_PAGINATION_SIZE } from "hooks/usePagination";
import { useUrlSharedData } from "hooks/useUrlSharedData";
import React, { FC, useContext, useEffect, useState } from "react";
import { parseParamsAndSearch } from "utils/JobUtils";

interface JobListTabProps {
  currentSearch: string;
  filtersDialog: {
    isOpen: boolean;
    setOpen: (condition: boolean) => void;
  };
  isSearchView?: boolean;
}
export const JobListTab: FC<JobListTabProps> = ({ currentSearch, filtersDialog, isSearchView }) => {
  const { results, handleSearch, setLastElementRef, getInitial } = usePagination<Job>(
    getJobList,
    "error.fetchJobList",
    "GET_JOB_LIST_STARTPAGE",
    { status: "active", search: currentSearch || undefined, page_size: DEFAULT_PAGINATION_SIZE },
    true
  );

  const { isLoading } = useContext(LoaderContext);
  const [filters, setFilters] = useState<JobFilters>(defaultFilterState);
  // Detect if tab is mounted, to avoid duplicate request intially
  const [hasMounted, setMounted] = useState(false);
  const { jobsFromUrl, clearJobsFromUrl } = useUrlSharedData(setFilters, handleSearch, getInitial);

  const handleFilterCallback = (value: JobFilters) => {
    setFilters(value);
    const parsedFilters = parseParamsAndSearch({ search: currentSearch, ...value });
    handleSearch(parsedFilters);
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (hasMounted) {
      const parsedFilters = parseParamsAndSearch({ search: currentSearch, ...filters });
      handleSearch({ ...parsedFilters, search: currentSearch });
    }
  }, [currentSearch]);

  return (
    <>
      <Meta
        title="Available assignments on GetWiser"
        desc="Find available assignments using advanced but easy search and filter systems"
      />
      {jobsFromUrl ? (
        <FixedJobList jobs={jobsFromUrl} onShowAll={clearJobsFromUrl} />
      ) : (
        <JobList
          lastElementRef={setLastElementRef}
          jobList={results}
          listIsLoading={isLoading("GET_JOB_LIST_STARTPAGE")}
          filters={filters}
          setFilters={handleFilterCallback}
          showImageBanner={!isSearchView}
        />
      )}
      <FiltersDialog
        open={filtersDialog.isOpen}
        handleClose={() => filtersDialog.setOpen(false)}
        filterCallback={handleFilterCallback}
        currentFilters={filters}
      />
    </>
  );
};
