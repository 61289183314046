import React, { useContext } from "react";
import { ActionNavbar, Footer } from "components";
import { CircularProgress, Grid } from "@mui/material";
import { LoaderContext } from "context/LoaderContext";
import {
  DESKTOP_ACTION_BAR_SIZE,
  MOBILE_ACTION_BAR_SIZE,
} from "components/layout/ActionNavbar/ActionNavbar";
import { BreakpointsContext } from "context/BreakpointContext";
import { UserContext } from "context/UserContext";
import { DefaultActionNavbar } from "components/layout/ActionNavbar/DefaultActionNavbar";

const Layout: React.FC = ({ children }) => {
  const { isLoading } = useContext(LoaderContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const { isAuthenticated, isAgent } = useContext(UserContext);

  if (isLoading("GET_CLIENT_CONFIG")) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Grid>
    );
  }

  if (!isAuthenticated) {
    return (
      <Grid
        container
        direction="column"
        {...(isDesktop
          ? {
              width: "100%",
              minHeight: "100%",
            }
          : {
              minHeight: `calc(100%)`,
            })}
      >
        <DefaultActionNavbar />

        {children}
        <Footer />
      </Grid>
    );
  }

  return (
    <>
      {/** Outer wrapping to set global styling to layout based on viewport */}
      <Grid
        container
        direction="column"
        {...(isDesktop
          ? {
              width: `calc(100% - ${DESKTOP_ACTION_BAR_SIZE})`,
              marginLeft: DESKTOP_ACTION_BAR_SIZE,
              minHeight: "100%",
            }
          : {
              minHeight: `calc(100% - ${MOBILE_ACTION_BAR_SIZE})`,
            })}
      >
        {children}
        {!isAgent ? <Footer /> : null}
      </Grid>
      <ActionNavbar />
    </>
  );
};

export default Layout;
