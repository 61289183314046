import { Dialog, Radio, FormControlLabel, RadioGroup, Button } from "@mui/material";
import React, { FC, useContext, useState } from "react";
import { LocaleContext, languages } from "context/LocaleContext";
import { DialogHeader } from "components";
import { UserContext } from "context/UserContext";

type LanguageSelectProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export const LanguageSelectDialog: FC<LanguageSelectProps> = ({ isOpen, handleClose }) => {
  const { currentLanguage, localize } = useContext(LocaleContext);
  const { user, dispatch } = useContext(UserContext);
  const [selectedLang, setSelectedLang] = useState<languages>(
    languages[currentLanguage] || languages.sv
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLang(languages[event.currentTarget.value]);
  };

  const handleSubmit = () => {
    dispatch({ type: "UPDATE_USER", user: { ...user, language: languages[selectedLang] } });
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogHeader handleClose={handleClose} title={localize("navigationDialog.selectLanguage")} />
      <RadioGroup
        value={selectedLang}
        onChange={handleChange}
        sx={{
          padding: "2rem",
        }}
      >
        <FormControlLabel
          control={<Radio sx={{ color: "primary.main" }} />}
          label="Svenska"
          value={languages.sv}
        />
        <FormControlLabel
          control={<Radio sx={{ color: "primary.main" }} />}
          label="English"
          value={languages.en}
        />
        <Button
          type="submit"
          variant="contained"
          color="success"
          onClick={handleSubmit}
          sx={{
            marginTop: "1rem",
          }}
        >
          {localize("form.save")}
        </Button>
      </RadioGroup>
    </Dialog>
  );
};
