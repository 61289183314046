import { CvSection } from "components/modals/Profile/ProfileCV/CvSection";
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { LocaleContext } from "context/LocaleContext";
import { Box, Collapse, Grid } from "@mui/material";
import { ModalContext, ModalTypes } from "context/ModalContext";
import { WIcon, WIconTypes } from "components";
import { BreakpointsContext } from "context/BreakpointContext";
import { ProfileSectionCard } from "./ProfileSectionCard";
import { ReferralSection } from "components/ProfileView/Referrals/ReferralSection";
import { DepartmentRoleList } from "./Roles/DepartmentRoleList";
import { SkillsList } from "./Skills/SkillsList";
import { AvailabilityDateSection } from "./ProfileSections/AvailabilityDateSection";
import { RegionSection } from "./RegionSection";

export type ProfileTemplate = {
  title: string;
  subTitle: string;
  modalName: ModalTypes;
  expandableContent?: ReactNode;
};

export const ProfileSections: FC = (): JSX.Element => {
  const { localize } = useContext(LocaleContext);
  const { showModal } = useContext(ModalContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const [sections, setSections] = useState<ProfileTemplate[]>([]);
  const [expandedSection, setExpandedSection] = useState<number>(-1);

  useEffect(() => {
    setSections([
      {
        title: "profileTitleSkill",
        subTitle: "profileSubTitleSkill",
        modalName: "PROFILE_SKILLS_MODAL",
        expandableContent: <SkillsList />,
      },
      {
        title: "profileTitleAvailability",
        subTitle: "profileSubTitleAvailability",
        modalName: "PROFILE_AVAILABILITY_MODAL",
        expandableContent: <AvailabilityDateSection />,
      },
      {
        title: "profileTitleRole",
        subTitle: "profileSubTitleRole",
        modalName: "PROFILE_ROLES_MODAL",
        expandableContent: <DepartmentRoleList />,
      },
      {
        title: "profileTitleRegion",
        subTitle: "profileSubTitleRegion",
        modalName: "PROFILE_REGION_MODAL",
        expandableContent: <RegionSection />,
      },
    ]);
  }, []);

  const handleExpandClick = (index: number) => {
    setExpandedSection(index === expandedSection ? -1 : index);
  };

  return (
    <>
      <Grid container direction="column" gap={isDesktop ? "4px" : 0}>
        <CvSection />
        {sections.map((section, index) => {
          if (isDesktop) {
            const isExpanded = expandedSection === index;

            return (
              <Box key={`${section}${index}`}>
                <ProfileSectionCard
                  isExpanded={isExpanded}
                  title={section.title}
                  subTitle={localize(`profileSections.${section.subTitle}`)}
                  onClick={() => handleExpandClick(index)}
                  icons={<WIcon icon={isExpanded ? WIconTypes.arrowUp : WIconTypes.arrowDown} />}
                />
                {isDesktop && section.expandableContent && (
                  <Collapse in={isExpanded} mountOnEnter>
                    {section.expandableContent}
                  </Collapse>
                )}
              </Box>
            );
          } else {
            return (
              <ProfileSectionCard
                key={`${section}${index}`}
                title={section.title}
                subTitle={localize(`profileSections.${section.subTitle}`)}
                onClick={() => showModal(section.modalName)}
              />
            );
          }
        })}
        <ReferralSection />
      </Grid>
    </>
  );
};
