import { Job, getJob } from "adapters";
import { JobFilters, defaultFilterState } from "components/dialogs/FiltersDialog";
import { LoaderContext } from "context/LoaderContext";
import { UserContext } from "context/UserContext";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { parseParamsAndSearch } from "utils/JobUtils";

export const useUrlSharedData = (
  setFilters: (newFilters: JobFilters) => void,
  handleSearch: (params: Record<string, unknown>) => void,
  getInitial: () => void
): {
  jobsFromUrl: Job[] | undefined;
  clearJobsFromUrl: () => void;
} => {
  const [jobsFromUrl, setJobsFromUrl] = useState<Job[] | undefined>(undefined);
  const history = useHistory();
  const { isAuthenticated } = useContext(UserContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);

  const pathName = useMemo(() => {
    if (isAuthenticated) {
      return "/jobs";
    }
    return "/";
  }, [isAuthenticated]);

  const loadJobs = async (ids: string[]) => {
    const jobs: Job[] = [];
    dispatchLoading({ type: "SET_LOADING", payload: "GET_JOB" });
    setJobsFromUrl([]);
    await Promise.all(
      ids.map(async (jobId) => {
        const job = await getJob(jobId);
        jobs.push(job.data);
      })
    );
    setJobsFromUrl(jobs);
    dispatchLoading({ type: "STOP_LOADING", payload: "GET_JOB" });
    history.replace(pathName);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.pathname === "/jobs" && !isLoading("GET_CLIENT_CONFIG") && !isAuthenticated) {
      history.replace(`/?${url.searchParams}`);
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const jobsParam = params.get("jobs");
    const jobFiltersParam = params.get("jobFilters");
    if (jobsParam) {
      const jobIdsFromUrl = jobsParam.split(",");
      if (jobIdsFromUrl.length === 0) {
        return;
      }
      loadJobs(jobIdsFromUrl);
    } else if (jobFiltersParam) {
      let newFilters: JobFilters;
      try {
        newFilters = { ...defaultFilterState, ...JSON.parse(jobFiltersParam) };
      } catch {
        history.replace(pathName);
        return;
      }

      setFilters(newFilters);
      const parsedFilters = parseParamsAndSearch(newFilters);
      handleSearch(parsedFilters);
      history.replace(pathName);
    } else {
      getInitial();
    }
  }, [isLoading("GET_CLIENT_CONFIG")]);

  const clearJobsFromUrl = useCallback(() => {
    setJobsFromUrl(undefined);
    getInitial();
  }, [setJobsFromUrl, getInitial]);

  return { jobsFromUrl, clearJobsFromUrl };
};
