import React, { useContext } from "react";
import { UserContext } from "context/UserContext";
import { AgentStartPage } from "./AgentViews/AgentStartPage";
import Dashboardview from "./Dashboardview";
import { DefaultStartPage } from "./DefaultStartPage";

const StartPageView: React.FC = () => {
  const { isAuthenticated, isAgent } = useContext(UserContext);

  if (isAgent) {
    return <AgentStartPage />;
  } else if (isAuthenticated) {
    return <Dashboardview />;
  } else {
    return <DefaultStartPage />;
  }
};
export default StartPageView;
