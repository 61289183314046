import { Button, Dialog, Grid, List, TextField, Typography } from "@mui/material";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { DialogHeader, WButton } from "components";
import { ConfirmDialog } from "./ConfirmDialog";
import { ModalContext } from "context/ModalContext";
import {
  EmailTemplate,
  EmailTemplateHubspot,
  getEmailTemplates,
  getHubspotEmailTemplates,
  sendEmail,
  sendHubspotEmail,
} from "adapters/EmailTemplatesAdapter";
import { usePagination } from "hooks/usePagination";
import { colors, defaultStyles } from "styles/colors";
import { Editor } from "@tinymce/tinymce-react";
import { BreakpointsContext } from "context/BreakpointContext";
import styled from "@emotion/styled";
import { NotificationContext } from "context/NotificationContext";
import { LoaderContext } from "context/LoaderContext";

interface Recipient {
  email: string;
}

interface SendEmailDialogProps {
  users: Recipient[];
}

type TType = "GetWiser" | "HubSpot" | "";

export const SendEmailModal: FC<SendEmailDialogProps> = ({ users }) => {
  const { hideModal } = useContext(ModalContext);
  const { addMessage } = useContext(NotificationContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const [type, setType] = useState<TType>("");
  const [selectedTemplate, setSelectedTemplate] = useState<
    EmailTemplate | EmailTemplateHubspot | undefined
  >(undefined);
  const [showConfirm, setShowConfirm] = useState(false);
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");

  const onContinue = useCallback(
    async (type: string, template: EmailTemplate | EmailTemplateHubspot) => {
      setType(type as TType);
      setSelectedTemplate(template as EmailTemplate);
      if (type === "HubSpot") {
        setShowConfirm(true);
      }
    },
    []
  );

  const onSend = useCallback(async () => {
    if (!selectedTemplate) {
      return;
    }
    dispatchLoading({ type: "SET_LOADING", payload: "SEND_EMAIL" });

    const recipients = users.map((u) => u.email);
    if (type === "GetWiser") {
      await sendEmail(recipients, subject, body);
    } else {
      await sendHubspotEmail(recipients, (selectedTemplate as EmailTemplateHubspot).template_id);
    }

    addMessage({ content: "success.email", type: "success" });
    dispatchLoading({ type: "STOP_LOADING", payload: "SEND_EMAIL" });
    hideModal();
  }, [selectedTemplate, type, subject, body]);

  return (
    <Dialog
      open={true}
      aria-labelledby="send-emails"
      fullScreen={!isDesktop}
      sx={{ overflow: "visible" }}
      PaperProps={{
        sx: { maxWidth: "800px" },
      }}
    >
      <Grid
        container
        direction="column"
        flexWrap="nowrap"
        sx={{
          width: isDesktop ? "800px" : "100%",
          height: isDesktop ? "calc(100vh - 100px)" : "100vh",
        }}
      >
        <div
          style={{
            display: type === "GetWiser" && selectedTemplate ? "none" : "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            height: "100%",
          }}
        >
          <SelectTemplate users={users} onContinue={onContinue} />
        </div>
        {type === "GetWiser" && selectedTemplate ? (
          <PreviewTemplate
            users={users}
            template={selectedTemplate as EmailTemplate}
            onBack={() => {
              setSelectedTemplate(undefined);
            }}
            onContinue={(subject, body) => {
              setSubject(subject);
              setBody(body);
              setShowConfirm(true);
            }}
          />
        ) : null}
      </Grid>

      {showConfirm ? (
        <ConfirmDialog
          title={localize("dialog.confirmSendEmail")}
          subtitle={localize("dialog.confirmSendEmailText", {
            count: users.length.toString(),
            subject:
              type === "GetWiser" ? `(${localize("emailTemplates.subject")}: ${subject})` : "",
          })}
          cancelAction={() => {
            setSubject("");
            setBody("");
            setShowConfirm(false);
          }}
          confirmAction={onSend}
          displayLoading={isLoading("SEND_EMAIL")}
        />
      ) : null}
    </Dialog>
  );
};

interface SelectTemplateProps {
  users: Recipient[];
  onContinue: (type: string, template: EmailTemplate | EmailTemplateHubspot) => void;
}

const SelectTemplate: FC<SelectTemplateProps> = ({ users, onContinue }) => {
  const { localize } = useContext(LocaleContext);
  const { hideModal } = useContext(ModalContext);
  const { isDesktop } = useContext(BreakpointsContext);
  const [selectedType, setSelectedType] = useState("GetWiser");
  const [selectedTemplate, setSelectedTemplate] = useState<
    EmailTemplate | EmailTemplateHubspot | undefined
  >(undefined);

  const { results: emailTemplates, setLastElementRef: setEmailTemplateRef } =
    usePagination<EmailTemplate>(getEmailTemplates, "error.generalError", "GET_EMAIL_TEMPLATES");

  const { results: hubspotTemplates, setLastElementRef: setHubspotTemplateRef } =
    usePagination<EmailTemplateHubspot>(
      getHubspotEmailTemplates,
      "error.generalError",
      "GET_EMAIL_TEMPLATES_HUBSPOT"
    );

  return (
    <>
      <Grid item>
        <DialogHeader
          title={localize("emailTemplates.sendEmailToXUsers", { count: users.length.toString() })}
          titleVariant="h4"
          handleClose={() => hideModal()}
          returnText={localize("common.close")}
        />
      </Grid>
      <Grid container item gap="5px" padding="10px">
        {["GetWiser", "HubSpot"].map((type) => {
          const selected = type === selectedType;
          return (
            <Grid item key={type}>
              <WButton
                variant={selected ? "contained" : "outlined"}
                onClick={() => {
                  setSelectedType(type);
                  setSelectedTemplate(undefined);
                }}
              >
                {type}
              </WButton>
            </Grid>
          );
        })}
      </Grid>

      <Grid item paddingLeft="10px">
        <Typography marginBottom="10px">
          {localize(
            selectedType === "GetWiser"
              ? "emailTemplates.explanationRegular"
              : "emailTemplates.explanationHubspot"
          )}
        </Typography>
        <Typography fontWeight="bold">{localize("emailTemplates.selectTemplate")}</Typography>
      </Grid>

      <Grid item xs={12} width={isDesktop ? "100%" : "100vw"} overflow="auto" flexGrow={1}>
        {selectedType === "GetWiser" ? (
          <TemplatesList
            templates={emailTemplates}
            setLastElementRef={setEmailTemplateRef}
            selectedTemplate={selectedTemplate}
            onSelect={(template) => setSelectedTemplate(template)}
          />
        ) : (
          <TemplatesList
            templates={hubspotTemplates}
            setLastElementRef={setHubspotTemplateRef}
            selectedTemplate={selectedTemplate}
            onSelect={(template) => setSelectedTemplate(template)}
            isHubspotTemplates={true}
          />
        )}
      </Grid>

      <Grid
        item
        flexBasis="0"
        bgcolor={colors.white}
        boxShadow="0 0 0 1px #00000006"
        padding="10px"
        display="flex"
        justifyContent="flex-end"
      >
        <WButton
          variant="contained"
          disabled={!selectedTemplate}
          onClick={() => {
            if (!selectedTemplate) {
              return;
            }
            onContinue(selectedType, selectedTemplate);
          }}
        >
          {localize("emailTemplates.continue")}
        </WButton>
      </Grid>
    </>
  );
};

interface TemplatesListProps {
  templates: EmailTemplate[] | EmailTemplateHubspot[];
  setLastElementRef: (node: Element | null) => void;
  selectedTemplate: EmailTemplate | EmailTemplateHubspot | undefined;
  onSelect: (template: EmailTemplate | EmailTemplateHubspot) => void;
  isHubspotTemplates?: boolean;
}

const TemplatesList: FC<TemplatesListProps> = ({
  templates,
  setLastElementRef,
  selectedTemplate,
  onSelect,
  isHubspotTemplates,
}) => {
  const { localize } = useContext(LocaleContext);

  return (
    <List>
      {templates.map((tem, index) => {
        const selected = selectedTemplate?.id === tem.id;
        return (
          <div key={tem.id} style={{ padding: "0 10px", margin: "4px" }}>
            <Button
              variant={selected ? "contained" : "text"}
              fullWidth
              onClick={() => onSelect(tem)}
              sx={{
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                boxShadow: defaultStyles.cardShadow,
                padding: "6px 12px !important",
                backgroundColor: selected ? "" : colors.white,
              }}
            >
              <OneLineText>{tem.name}</OneLineText>
              {!isHubspotTemplates ? (
                <span>
                  {localize(
                    (tem as EmailTemplate).language === "sv"
                      ? "emailTemplates.swedish"
                      : "emailTemplates.english"
                  )}
                  <OneLineText style={{ marginLeft: "40px" }}>
                    {localize("emailTemplates.subject")}: {(tem as EmailTemplate).subject}{" "}
                  </OneLineText>
                </span>
              ) : null}
            </Button>
            {index === templates.length - 1 ? <div ref={setLastElementRef} /> : null}
          </div>
        );
      })}
    </List>
  );
};

const OneLineText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface PreviewTemplateProps {
  users: Recipient[];
  template: EmailTemplate;
  onBack: () => void;
  onContinue: (subject: string, body: string) => void;
}

const PreviewTemplate: FC<PreviewTemplateProps> = ({ users, template, onBack, onContinue }) => {
  const { localize } = useContext(LocaleContext);
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [subject, setSubject] = useState(template.subject);
  const [body, setBody] = useState(template.body);

  const fields = useMemo(() => {
    return template.dynamic_fields.split(/\n/g).filter((f) => f.length > 0);
  }, []);

  return (
    <>
      {!isEditing ? (
        <Grid item>
          <DialogHeader
            title={localize("emailTemplates.sendEmailToXUsers", { count: users.length.toString() })}
            titleVariant="h4"
            handleClose={() => {
              if (
                subject !== template.subject ||
                // Can't just compare body straight up, seemingly due to issues with newlines.
                body.replace(/[\r\n]/g, "") !== template.body.replace(/[\r\n]/g, "")
              ) {
                setShowConfirmBack(true);
                return;
              }

              onBack();
            }}
            returnText={localize("common.goBack")}
          />
        </Grid>
      ) : null}
      <Grid item padding="10px" bgcolor={colors.white}>
        <TextField
          label={localize("emailTemplates.subject")}
          value={subject}
          disabled={!isEditing}
          onChange={(e) => setSubject(e.target.value)}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item flexGrow={1}>
        <Editor
          value={body}
          tinymceScriptSrc="assets/tinymce/tinymce.min.js"
          init={{
            height: "100%",
            menubar: true,
            plugins:
              "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
            toolbar:
              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment code",
            resize: false,
          }}
          disabled={!isEditing}
          onEditorChange={(text) => {
            setBody(text);
          }}
        />
      </Grid>
      <Grid
        item
        flexBasis="0"
        bgcolor={colors.white}
        boxShadow="0 0 0 1px #00000006"
        padding="10px"
        sx={{ display: "flex", flexWrap: "wrap" }}
      >
        <Typography fontSize="0.8rem" sx={{ width: "100%" }}>
          {localize("emailTemplates.variables")}
          {fields.map((field) => (
            <React.Fragment key={field}>
              <br />
              {field}
            </React.Fragment>
          ))}
        </Typography>
        <Typography fontSize="0.8rem" fontStyle="italic" sx={{ width: "100%", padding: "10px 0" }}>
          {localize("emailTemplates.footerInfo")}
        </Typography>
        <WButton
          variant={isEditing ? "contained" : "outlined"}
          onClick={() => setIsEditing(!isEditing)}
        >
          {localize(isEditing ? "emailTemplates.finishEdit" : "common.edit")}
        </WButton>
        <WButton
          variant="contained"
          disabled={isEditing}
          onClick={() => onContinue(subject, body)}
          sx={{ marginLeft: "auto" }}
        >
          {localize("emailTemplates.continue")}
        </WButton>
      </Grid>

      {showConfirmBack ? (
        <ConfirmDialog
          title={localize("dialog.confirmBackAfterEdit")}
          subtitle={localize("dialog.confirmBackAfterEditText")}
          subtitleColor={colors.error}
          cancelAction={() => setShowConfirmBack(false)}
          confirmAction={onBack}
          cancelText={localize("common.cancel")}
        />
      ) : null}
    </>
  );
};
