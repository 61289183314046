import { Dialog } from "@mui/material";
import { DialogHeader } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { ModalContext } from "context/ModalContext";

import { ProfileContactDetailsBody } from "./ProfileContactDetailsBody";

export const ProfileContactDetailsModal: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { hideModal } = useContext(ModalContext);
  return (
    <Dialog fullScreen open={true}>
      <DialogHeader
        handleClose={() => hideModal()}
        title={localize("common.contactDetails")}
        returnText={localize("profileSections.myDetails")}
      />
      <ProfileContactDetailsBody onSubmit={() => hideModal()} />
    </Dialog>
  );
};
