import { AxiosResponse } from "axios";
import { API, PaginatedResponse } from "./api";
import { EditContactDialogState } from "components/dialogs/EditContactDialog";
import { User } from "reducers/UserReducer";

export type AgentNote = {
  id: string;
  created: string;
  updated: string;
  title?: string;
  note: string;
  author: string | User;
  user: string;
  job: string | null;
};

export type GDPRUserData = {
  tt_user?: GdprTTuser;
  wise_candidates?: wise_candidate[];
  wise_user?: wise_user;
};

export type wise_user = {
  email: string;
  first_name: string;
  last_name: string;
  id: string;
  phone: string;
};

export type GdprTTuser = {
  total: number;
  ids: string[];
  email: string;
  first_name: string;
  last_name: string;
  id: string;
  phone: string;
};

export type wise_candidate = {
  candidate_email: string;
  ext_ref_candidate: string;
  id: string;
};

export const getUser = async (id: string): Promise<AxiosResponse<User>> => {
  return API.get(`/users/${id}/`);
};

export const getUserList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<User>>> => {
  return API.get(`/users/`, { params: params });
};

export const createUser = async (data: Record<string, unknown>): Promise<AxiosResponse> => {
  return API.post("/users/create-user/", data);
};

export const updateProfile = async (
  data: Partial<EditContactDialogState>,
  id: string
): Promise<AxiosResponse<User>> => {
  return API.patch(`/users/${id}/`, data);
};

export const changeProfilePicture = async (
  data: File | undefined,
  id: string
): Promise<AxiosResponse> => {
  const formData = new FormData();

  formData.append("file", !data ? "" : data);

  return API.post(`/users/${id}/change-profile-picture/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getProfile = async (): Promise<AxiosResponse<User>> => {
  return API.get("/users/me");
};

export const getAgentNotes = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<AgentNote>>> => {
  return API.get("/user-notes/", { params: { expand: "author", ...params } });
};

export const createAgentNote = async (
  data: Partial<AgentNote>
): Promise<AxiosResponse<AgentNote>> => {
  return API.post("/user-notes/", data);
};
export const deleteAgentNote = async (id: string): Promise<AxiosResponse<AgentNote>> => {
  return API.delete(`/user-notes/${id}/`);
};

export const updateAgentNote = async (
  id: string,
  data: Partial<AgentNote>
): Promise<AxiosResponse<AgentNote>> => {
  return API.patch(`/user-notes/${id}/`, data);
};

export const updateAgentUserRating = async (
  id: string,
  data: Partial<User>
): Promise<AxiosResponse<Partial<User>>> => {
  return API.put(`/users/${id}/rating/`, data);
};

export const getGDPRUserByEmail = async (
  params: Record<string, unknown>
): Promise<AxiosResponse<GDPRUserData>> => {
  return API.get("users/gdpr/email-search", { params: params });
};

export const getGDPR = async (params: Record<string, unknown>): Promise<AxiosResponse<string>> => {
  return API.get("users/gdpr/download", { params: params });
};

export const deleteUser = async (
  params: Record<string, unknown>
): Promise<AxiosResponse<string>> => {
  return API.delete("users/gdpr/delete", { params: params });
};

export const downloadGdpr = async (
  params: Record<string, unknown>
): Promise<AxiosResponse<Blob>> => {
  return API.get("users/gdpr/download", { responseType: "blob", params: params });
};
