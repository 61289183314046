import { getJobApplicationList, JobApplication } from "adapters";
import { ApplicationsTabContent } from "components/ApplicationsTabContent";
import { ApplicationTabDT } from "components/ApplicationTabDT";
import { BreakpointsContext } from "context/BreakpointContext";
import { PushNotificationContext, PUSH_NOTIFICATION_TYPES } from "context/PushNotificationContext";
import { usePagination, DEFAULT_PAGINATION_SIZE } from "hooks/usePagination";
import React, { FC, useContext, useEffect } from "react";

export const JobApplicationsTab: FC = () => {
  const { updateNotification } = useContext(PushNotificationContext);
  const { isDesktop } = useContext(BreakpointsContext);

  const {
    results,
    updatePage,
    setLastElementRef: setLastJobApplicationRef,
  } = usePagination<JobApplication>(
    getJobApplicationList, // will need to expand department later
    "error.fetchJobList",
    "GET_JOB_APPLICATION_LIST",
    { page_size: DEFAULT_PAGINATION_SIZE }
  );

  useEffect(() => {
    updateNotification(PUSH_NOTIFICATION_TYPES.jobApplication);
  }, []);

  if (isDesktop) {
    return <ApplicationTabDT paginationOpts={{ results, setLastJobApplicationRef, updatePage }} />;
  }

  return (
    <ApplicationsTabContent paginationOpts={{ results, setLastJobApplicationRef, updatePage }} />
  );
};
