import { ListItem, IconButton, ListItemButton, Checkbox } from "@mui/material";
import React, { FC } from "react";
import { JobFilterItem } from "./dialogs/FiltersDialog";
import { WIcon, WIconTypes } from "components";
interface WListItemProps {
  value: string;
  label: string;
  isChecked: boolean;
  handleClick: (item: JobFilterItem) => void;
  showExpand?: boolean;
  handleExpandClick?: (isExpanded: boolean) => void;
  expand?: boolean;
  sx?: Record<string, unknown>;
  disabled?: boolean;
}

export const WListItem: FC<WListItemProps> = ({
  value,
  label,
  isChecked,
  handleClick,
  showExpand = false,
  disabled = false,
  expand = false,
  handleExpandClick,
  sx,
}) => {
  return (
    <ListItem
      sx={{ ...sx }}
      value={value}
      secondaryAction={
        showExpand ? (
          <IconButton
            sx={{ marginRight: "1rem" }}
            edge="end"
            onClick={() => handleExpandClick && handleExpandClick(!expand)}
          >
            <WIcon icon={expand ? WIconTypes.arrowUp : WIconTypes.arrowDown} />
          </IconButton>
        ) : undefined
      }
      disablePadding
      disableGutters
    >
      <ListItemButton
        disabled={disabled}
        onClick={() => handleClick({ label, value })}
        dense
        sx={{ padding: !disabled ? "0" : ".5rem", fontWeight: "bold" }}
      >
        {!disabled && (
          <Checkbox
            edge="start"
            checked={isChecked}
            sx={{
              color: "text.primary",
              opacity: isChecked ? 1 : 0.5,
            }}
          />
        )}
        {label}
      </ListItemButton>
    </ListItem>
  );
};
