import { Grid } from "@mui/material";
import {
  addUserDepartment,
  deleteUserDepartment,
  Department,
  getDepartments,
  RoleWithDepartment,
} from "adapters";
import { WButton, WIcon, WIconTypes, WSkeleton } from "components";
import { JobFilterItem, JobFilterItemWithParent } from "components/dialogs/FiltersDialog";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import { usePagination } from "hooks/usePagination";
import React, { FC, useState, useContext, useEffect } from "react";
import { colors, defaultStyles } from "styles/colors";
import { DepartmentExpandableList } from "../DepartmentExpandableList";

export const DepartmentRoleList: FC = () => {
  const { dispatchLoading, isLoading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const { localize } = useContext(LocaleContext);
  const { user, dispatch } = useContext(UserContext);

  const [initialRoles, setInitialRoles] = useState<JobFilterItemWithParent[]>([]);
  const [initialDepartments, setInitialDepartments] = useState<JobFilterItem[]>([]);
  const [state, setState] = useState<{
    departments: JobFilterItem[];
    roles: JobFilterItemWithParent[];
  }>({
    departments: initialDepartments || [],
    roles: initialRoles || [],
  });

  useEffect(() => {
    const initialDepartmentNamesSet = new Set<string>();
    const initialDepartmentValuesSet = new Set<string>();

    const tempRoles = user.departments.map((role) => {
      initialDepartmentNamesSet.add(role.department.name);
      initialDepartmentValuesSet.add(role.department.id);

      return {
        label: role.name,
        value: role.id,
        parent: { label: role.department.name, value: role.department.id },
      };
    });

    const initialDepartmentNames: string[] = [...initialDepartmentNamesSet];
    const initialDepartmentValues: string[] = [...initialDepartmentValuesSet];

    const tempDepartments: JobFilterItem[] = initialDepartmentNames.map((name, index) => {
      return {
        label: name,
        value: initialDepartmentValues[index],
      };
    });

    setInitialRoles(tempRoles);
    setInitialDepartments(tempDepartments);
  }, []);

  useEffect(() => {
    setState({
      departments: initialDepartments || [],
      roles: initialRoles || [],
    });
  }, [initialRoles]);

  const { results, setLastElementRef } = usePagination<Department>(
    getDepartments,
    "error.fetchingDepartments",
    "GET_DEPARTMENTS"
  );

  const handleAdditions = (additions: JobFilterItemWithParent[], shouldCloseAndSave: boolean) => {
    // Avoid duplicate requests
    if (isLoading("ADD_USER_DEPARTMENT") || additions.length < 1) {
      return;
    }

    dispatchLoading({ type: "SET_LOADING", payload: "ADD_USER_DEPARTMENT" });
    addUserDepartment({
      roles: additions.map((roles) => roles.value),
    })
      .then(() => {
        if (shouldCloseAndSave) {
          addMessage({ content: "success.updateSuccessful", type: "success" });
          // Update departments in user context
          const additionsAsRole: RoleWithDepartment[] = additions.map((addition) => {
            return {
              id: addition.value,
              name: addition.label,
              department: {
                id: addition.parent.value,
                name: addition.parent.label,
              },
            };
          });
          dispatch({
            type: "UPDATE_USER",
            user: { departments: [...user.departments, ...additionsAsRole] },
          });
        }
      })
      .catch(() => {
        if (shouldCloseAndSave) {
          addMessage({ content: "error.addingDepartmentRole", type: "error" });
        }
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "ADD_USER_DEPARTMENT" });
      });
  };

  const handleDeletions = (deletions: string[], shouldCloseAndSave: boolean) => {
    // Avoid duplicate requests
    if (isLoading("DELETE_USER_DEPARTMENT") || deletions.length < 1) {
      return;
    }

    deleteUserDepartment({ roles: deletions })
      .then(() => {
        if (shouldCloseAndSave) {
          addMessage({ content: "success.updateSuccessful", type: "success" });
          // Update departments in user context
          const updatedDepartments = user.departments.filter(
            (userDep) => !deletions.includes(userDep.id)
          );
          dispatch({ type: "UPDATE_USER", user: { departments: updatedDepartments } });
        }
      })
      .catch(() => {
        if (shouldCloseAndSave) {
          addMessage({ content: "error.removingDepartmentRole", type: "error" });
        }
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "DELETE_USER_DEPARTMENT" });
      });
  };

  const handleRemoveItem = (key: string, item: JobFilterItemWithParent | JobFilterItem) => {
    if (key === "departments") {
      const tempFilter = state[key].filter(
        (prevValue: JobFilterItem) => prevValue.value !== item.value
      );

      setState({
        ...state,
        [key]: [...tempFilter],
        roles: state["roles"].filter((prevValue: JobFilterItemWithParent) => {
          return prevValue.parent.value !== item.value;
        }),
      });
    } else {
      setState({
        ...state,
        [key]: state[key].filter((prevValue: JobFilterItem) => prevValue.value !== item.value),
      });
    }
  };

  const handleAddItem = (key: string, item: JobFilterItemWithParent | JobFilterItem) => {
    if (key === "departments") {
      // Add all roles existing to specific department
      const selectedDepartment = results.find((res) => res.id === item.value);
      if (selectedDepartment) {
        const parsedDepartment = {
          label: selectedDepartment.name,
          value: selectedDepartment.id,
        };
        const allRoles = selectedDepartment.roles.map((role) => {
          return {
            label: role.name,
            value: role.id,
            parent: parsedDepartment,
          };
        });
        setState({
          ...state,
          departments: [...state.departments, parsedDepartment],
          roles: [...state.roles, ...allRoles],
        });
      }
    } else {
      setState({ ...state, [key]: [...state[key], item] });
    }
  };

  const handleItemClick = (
    key: "departments" | "roles",
    item: JobFilterItemWithParent | JobFilterItem
  ) => {
    if (state[key].some((filter) => filter.value === item.value)) {
      handleRemoveItem(key, item);
    } else {
      handleAddItem(key, item);
    }
  };

  const handleSaveChanges = () => {
    const currentUserRoles = user.departments.map((role) => role.id);
    if (currentUserRoles) {
      const additions: JobFilterItemWithParent[] =
        state.roles.filter((role) => !currentUserRoles.includes(role.value)) || []; // if there's any currently selected roles in filter that does not exist in the currentUserRoles it's an addition

      const deletions: string[] =
        currentUserRoles.filter(
          (userRole) => !state.roles.some((role) => role.value === userRole)
        ) || []; // if savedUserRoles contains a role that does not exist in the state it's a deletion

      if (additions.length + deletions.length === 0) {
        return;
      }

      if (additions.length && deletions.length) {
        Promise.all([handleAdditions(additions, false), handleDeletions(deletions, false)])
          .then(() => {
            addMessage({ content: "success.updateSuccessful", type: "success" });
          })
          .catch(() => {
            addMessage({ content: "error.deleteOrAddDepartments", type: "error" });
          })
          .finally(() => {
            // Additions as role object
            const additionsAsRole: RoleWithDepartment[] = additions.map((addition) => {
              return {
                id: addition.value,
                name: addition.label,
                department: {
                  id: addition.parent.value,
                  name: addition.parent.label,
                },
              };
            });

            // Updated deparments with removed items
            const updatedDepartments = user.departments.filter(
              (userDep) => !deletions.includes(userDep.id)
            );

            dispatch({
              type: "UPDATE_USER",
              user: { departments: [...updatedDepartments, ...additionsAsRole] },
            });
          });
      } else {
        if (additions.length) {
          handleAdditions(additions, true);
        } else {
          handleDeletions(deletions, true);
        }
      }
    }
  };

  if (isLoading("GET_DEPARTMENTS")) {
    return (
      <>
        <WSkeleton height="75px" />
        <WSkeleton height="75px" />
        <WSkeleton height="75px" />
      </>
    );
  }

  return (
    <Grid
      container
      direction="column"
      padding="1rem"
      gap="1rem"
      sx={{
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadiusBottom,
        border: `1px solid ${colors.grey}`,
      }}
    >
      <DepartmentExpandableList
        expand={true}
        itemsToShow={4}
        allResults={results}
        filters={state}
        handleItemClick={handleItemClick}
        lastElementRef={setLastElementRef}
      />
      <Grid container justifyContent="flex-end">
        <Grid item xs={12} md={2}>
          <WButton
            fullWidth
            variant="outlined"
            startIcon={<WIcon icon={WIconTypes.check} />}
            onClick={handleSaveChanges}
          >
            {localize("form.save")}
          </WButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
