import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  Grid,
  Pagination,
  Skeleton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Group,
  GroupUser,
  getGroup,
  getGroupList,
  updateGroup,
  deleteGroup,
} from "adapters/GroupsAdapter";
import { WButton, WIcon, WIconTypes, WTypography } from "components";
import { GroupCard, GroupCardHeight } from "components/Groups/GroupCard";
import { LocaleContext } from "context/LocaleContext";
import { DEFAULT_PAGINATION_SIZE, usePagination } from "hooks/usePagination";
import { useHistory, useParams } from "react-router-dom";
import { LoaderContext } from "context/LoaderContext";
import { defaultStyles } from "styles/colors";
import { GroupDrawer, GroupDrawerWidth } from "components/Groups/GroupDrawer";
import styled from "@emotion/styled";
import { ModalContext } from "context/ModalContext";
import { Searchbar } from "components/Searchbar";
import { parseParamsAndSearch } from "utils/JobUtils";

export type GroupFilterObject = {
  search: string;
};

const defaultGroupObject = {
  search: "",
};

export const AllGroups: FC = () => {
  const history = useHistory();
  const { localize, currentLanguage } = useContext(LocaleContext);
  const { isLoading, dispatchLoading } = useContext(LoaderContext);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(undefined);
  const { showDialog, hideDialog } = useContext(ModalContext);
  const [sortByCreated, setSortByCreated] = useState(false);
  const [visibleGroups, setVisibleGroups] = useState<number | null>(DEFAULT_PAGINATION_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [groupFilterObject, setGroupFilterObject] = useState<GroupFilterObject>(defaultGroupObject);
  const PAGE_SIZE_OPTIONS = [10, 20, 30, 100];
  const { groupId } = useParams<{ groupId: string }>();

  const { pageResults, handleSearch, count, getPage, updatePage } = usePagination<Group>(
    getGroupList,
    "error.fetchingGroupList",
    "GET_GROUP_LIST",
    {
      page_size: DEFAULT_PAGINATION_SIZE,
      ordering: currentLanguage === "en" ? "name_en" : "name_sv",
    }
  );

  useEffect(() => {
    setSelectedGroups(JSON.parse(localStorage.getItem("selectedGroups") || "[]"));
    setGroups(pageResults);
  }, [pageResults]);

  useEffect(() => {
    if (!groupId) {
      return;
    }

    const group = groups.find((g) => g.id === groupId);
    if (group) {
      setSelectedGroup(group);
    } else if (groups.length > 0 && !isLoading("GET_GROUP")) {
      (async () => {
        dispatchLoading({ payload: "GET_GROUP", type: "SET_LOADING" });
        const g = await getGroup(groupId);
        dispatchLoading({ payload: "GET_GROUP", type: "STOP_LOADING" });
        if (!g) {
          return;
        }
        setGroups((list) => [g.data, ...list]);
      })();
    }
  }, [groupId, groups]);

  const onChangeUsers = useCallback(
    (newUsers: GroupUser[]) => {
      const index = groups.findIndex((g) => g.id === groupId);
      const newGroups = [
        ...groups.slice(0, index),
        { ...groups[index], users: newUsers },
        ...groups.slice(index + 1),
      ];
      setGroups(newGroups);
      updateGroup(groupId, {
        users: newUsers.map((u) => u.id),
      });
    },
    [groups, setGroups, groupId]
  );

  const getCurrentLanguageName = useCallback(() => {
    return currentLanguage === "en" ? "name_en" : "name_sv";
  }, [currentLanguage]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      if (page === currentPage) {
        return;
      }
      setCurrentPage(page);
      getPage(page, parseParamsAndSearch(groupFilterObject));
    },
    [currentPage, setCurrentPage, getPage, groupFilterObject]
  );

  const handleChangeVisibleGroups = useCallback(
    (_, newVisibleGroups: number | null) => {
      setVisibleGroups(newVisibleGroups);
      setCurrentPage(1);

      const parsedFilters = {
        ...groupFilterObject,
        ordering: sortByCreated ? "-created" : getCurrentLanguageName(),
      };
      updatePage(parseParamsAndSearch(parsedFilters), visibleGroups ?? DEFAULT_PAGINATION_SIZE);
    },
    [groupFilterObject, sortByCreated, getCurrentLanguageName, updatePage, visibleGroups]
  );

  const handleChangeSortByCreated = useCallback(() => {
    setSortByCreated(!sortByCreated);
    const parsedFilters = {
      ...groupFilterObject,
      ordering: !sortByCreated ? "-created" : getCurrentLanguageName(),
    };
    handleSearch(parsedFilters);
  }, [groupFilterObject, sortByCreated, getCurrentLanguageName, handleSearch]);

  const handleAddGroup = useCallback(() => {
    const parsedFilters = {
      ...groupFilterObject,
      ordering: sortByCreated ? "-created" : getCurrentLanguageName(),
    };
    updatePage(parseParamsAndSearch(parsedFilters), visibleGroups ?? DEFAULT_PAGINATION_SIZE);
  }, [getCurrentLanguageName, groupFilterObject, sortByCreated, updatePage, visibleGroups]);

  const handleRemoveGroup = useCallback(
    async (removedGroupId: string): Promise<void> => {
      await deleteGroup(removedGroupId);

      hideDialog();
      updatePage(parseParamsAndSearch(groupFilterObject), visibleGroups ?? DEFAULT_PAGINATION_SIZE);

      if (groupId === removedGroupId) {
        history.push("/groups/");
      }
    },
    [groupFilterObject, groupId, hideDialog, history, updatePage, visibleGroups]
  );

  const handleToggleSelectedGroups = useCallback(
    (groupId: string) => {
      if (selectedGroups.includes(groupId)) {
        const index = selectedGroups.findIndex((g) => g === groupId);
        const newSelectedGroups = [
          ...selectedGroups.slice(0, index),
          ...selectedGroups.slice(index + 1),
        ];
        setSelectedGroups(newSelectedGroups);
        localStorage.setItem("selectedGroups", JSON.stringify(newSelectedGroups));
      } else {
        const newSelectedGroups = [...selectedGroups, groupId];
        setSelectedGroups(newSelectedGroups);
        localStorage.setItem("selectedGroups", JSON.stringify(newSelectedGroups));
      }
    },
    [selectedGroups]
  );

  const handleInputChange = useCallback(
    (value: string) => {
      const inputValue = {
        ...groupFilterObject,
        search: value,
        ordering: sortByCreated ? "-created" : getCurrentLanguageName(),
      };
      setGroupFilterObject(inputValue);
      handleSearch(inputValue);
    },
    [groupFilterObject, sortByCreated, getCurrentLanguageName, handleSearch]
  );

  return (
    <>
      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        gap="20px"
        padding="30px"
        width={`calc(100% - ${groupId ? GroupDrawerWidth : 0}) !important`}
        overflow="auto"
      >
        <Grid item>
          <WTypography variant="h1" fontSize="2rem" lineClamps={1}>
            {localize("groups.groups")} ({count})
          </WTypography>
        </Grid>
        <Grid item>
          <Searchbar setInputValue={handleInputChange} placeholder={localize("form.search")} />
        </Grid>
        <Grid container direction="row" gap="30px">
          <Tooltip title={localize("groups.addGroup")} placement="top-start" arrow>
            <WButton
              variant="outlined"
              sx={{ margin: "20px 0 0" }}
              onClick={() => {
                showDialog("CREATE_GROUP_DIALOG", {
                  onAddGroup: handleAddGroup,
                });
              }}
            >
              <WIcon icon={WIconTypes.add} />
            </WButton>
          </Tooltip>
          <div>
            <Grid item container alignItems="center" marginTop="20px">
              <WTypography variant="body2" marginRight="30px">
                {localize("groups.sorting")}:{" "}
              </WTypography>
              <Grid item>
                <WTypography variant="body2">{localize("groups.sortByName")}</WTypography>
              </Grid>
              <Grid item>
                <Switch checked={sortByCreated} onChange={() => handleChangeSortByCreated()} />
              </Grid>
              <Grid item>
                <WTypography variant="body2">{localize("groups.sortByCreated")}</WTypography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <CustomGrid>
          {groups.map((group) => (
            <Grid key={group.id} item>
              <GroupCard
                isSelected={groupId === group.id}
                isInSelectedGroups={selectedGroups.includes(group.id)}
                isAllGroups={true}
                group={group}
                onClick={() => {
                  if (groupId === group.id) {
                    history.push("/groups/");
                  } else {
                    history.push(`/groups/${group.id}`);
                  }
                }}
                onToggleSelectedGroups={handleToggleSelectedGroups}
              />
            </Grid>
          ))}

          {isLoading("GET_GROUP_LIST")
            ? Array(10)
                .fill(1)
                .map((_, index) => (
                  <Grid key={`group_skeleton_${index}`} item>
                    <Skeleton
                      key={`group_skeleton_${index}`}
                      variant="rectangular"
                      width="100%"
                      height={GroupCardHeight}
                      sx={{ borderRadius: defaultStyles.borderRadius }}
                    />
                  </Grid>
                ))
            : null}
        </CustomGrid>
      </Grid>
      <GroupDrawer
        isOpen={!!groupId}
        group={selectedGroup}
        onChangeUsers={onChangeUsers}
        onRemoveGroup={handleRemoveGroup}
      />
      <Grid container justifyContent="center" marginTop=".5rem">
        <Pagination
          color="primary"
          count={Math.ceil(count / (visibleGroups ?? DEFAULT_PAGINATION_SIZE))}
          page={currentPage}
          siblingCount={0}
          onChange={handleChangePage}
        />

        <Grid container gap="5px" alignItems="center" width="max-content" marginLeft="auto">
          <Typography variant="body2"> {localize("groups.groupsPerPage")}</Typography>
          <ToggleButtonGroup value={visibleGroups} exclusive onChange={handleChangeVisibleGroups}>
            {PAGE_SIZE_OPTIONS.map((size, index) => {
              return (
                <ToggleButton
                  key={`size_${size}`}
                  sx={{ paddingTop: "4px", paddingBottom: "4px" }}
                  value={size}
                  disabled={count <= PAGE_SIZE_OPTIONS[index - 1]}
                >
                  {size}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </>
  );
};

const CustomGrid = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  align-content: start;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;
