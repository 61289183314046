import { Grid, Box, Dialog } from "@mui/material";
import React, { FC } from "react";
import { ModalContext } from "context/ModalContext";
import { AvatarItem, DialogHeader } from "components";
import { NavigationList } from "./NavigationList";
import { UserContext } from "context/UserContext";

export const NavigationModal: FC = () => {
  const { hideModal } = React.useContext(ModalContext);
  const { user, isAgent } = React.useContext(UserContext);

  return (
    <Dialog
      aria-labelledby="navigation"
      open={true}
      fullWidth={true}
      fullScreen={isAgent ? false : true}
    >
      <DialogHeader title="Wise" titleVariant="h4" handleClose={hideModal} iconSize="large" />
      <Box
        sx={{
          height: "100%",
          padding: "1rem",
        }}
      >
        <Grid container justifyContent="center" marginTop="2rem">
          <AvatarItem
            firstName={user.first_name}
            lastName={user.last_name}
            imageSrc={user.profile_picture}
            showName={true}
            direction="column"
          />
        </Grid>
        <Grid item xs={12} marginTop="1rem">
          <NavigationList />
        </Grid>
      </Box>
    </Dialog>
  );
};
