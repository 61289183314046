import React, { createContext } from "react";
import { LoaderActions, LoaderActionType, LoaderReducer } from "reducers/LoaderReducer";

// Set Client config fetch to be intially loading 
export const LoaderInitialState = {
  actions: ["GET_CLIENT_CONFIG" as LoaderActions],
};

export type LoaderContextState = {
  actions: LoaderActions[];
};

export const LoaderContext = createContext<{
  isLoading: (action: LoaderActions) => boolean;
  dispatchLoading: React.Dispatch<LoaderActionType>;
}>({
  isLoading: () => false,
  dispatchLoading: () => ({} as React.Dispatch<LoaderActionType>),
});

const LoaderProvider: React.FC = ({ children }) => {
  const [state, dispatchLoading] = React.useReducer(LoaderReducer, LoaderInitialState);

  const isLoading = (action: LoaderActions) => {
    return state.actions.includes(action);
  };

  return (
    <LoaderContext.Provider value={{ isLoading, dispatchLoading }}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
