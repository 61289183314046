import { Box, Dialog, Typography } from "@mui/material";
import { DialogHeader, WButton, WIconTypes, WIcon, WIconButton } from "components";
import { LocaleContext } from "context/LocaleContext";
import React, { FC, useContext } from "react";
import { Referral } from "adapters";
import { ModalContext } from "context/ModalContext";
import { UserContext } from "context/UserContext";
import { ReferralCard } from "../../ProfileView/Referrals/ReferralCard";

export const ProfileReferrals: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { hideModal, showDialog, hideDialog } = useContext(ModalContext);
  const { user } = useContext(UserContext);

  const selectReferral = (referral: Referral) => {
    showDialog("REFERRAL_DIALOG_EDIT", {
      handleClose: () => hideDialog(),
      handleSuccessUpdate: () => hideDialog(),
      referral: referral,
    });
  };

  return (
    <Dialog fullScreen open={true} sx={{ height: "100%" }}>
      <DialogHeader
        handleClose={() => hideModal()}
        title={localize("profileSections.profileTitleReferral")}
        returnText={localize("profileView.headerMessage")}
        infoToolTip={localize("profileSections.referenceDialogInfo")}
      />
      <Box marginTop=".5rem" textAlign="center">
        {user.referrals.length > 0 ? (
          user.referrals.map((referral) => {
            return (
              <ReferralCard
                key={`referral${referral.id}`}
                referral={referral}
                onClick={() => selectReferral(referral)}
                icons={
                  <WIconButton>
                    <WIcon icon={WIconTypes.arrowRight} />
                  </WIconButton>
                }
              />
            );
          })
        ) : (
          <Typography>{localize("profileView.noReferrals")}</Typography>
        )}
      </Box>
      <Box
        marginTop="auto"
        width="100%"
        padding="0 1rem"
        sx={{ position: "sticky", bottom: 0, backgroundColor: "background.default" }}
      >
        <WButton
          fullWidth
          variant="outlined"
          startIcon={<WIcon icon={WIconTypes.addUser} />}
          sx={{ margin: "1rem 0" }}
          onClick={() =>
            showDialog("REFERRAL_DIALOG_ADD", {
              handleClose: () => hideDialog(),
              handleSuccessUpdate: () => hideDialog(),
            })
          }
        >
          {localize("profileView.addReferral")}
        </WButton>
      </Box>
    </Dialog>
  );
};
