import React, { ComponentProps, FC, createContext, useMemo, useReducer } from "react";
import { DetailedJobModal } from "components/modals/DetailedJobModal/DetailedJobModal";
import { NavigationModal } from "components/modals/NavigationModal/NavigationModal";
import { JobApplicationFormModal } from "components/modals/JobApplicationFormModal";
import { DetailedJobApplicationModal } from "components/modals/DetailedJobApplicationModal/DetailedJobApplicationModal";
import { ModalReducer } from "reducers/ModalReducer";
import { ConversationModal } from "components/modals/Messages/ConversationModal";
import { OptionsDialog } from "components/dialogs/OptionsDialog";
import { ProfileCvsModal } from "components/modals/Profile/ProfileCV/ProfileCvsModal";
import { ProfileReferrals } from "components/modals/Profile/ProfileReferralsModal";
import { ProfileSkillsModal } from "components/modals/Profile/ProfileSkillsModal";
import { UnderConstructionModal } from "components/modals/UnderConstructionModal";
import { ProfileContactDetailsModal } from "components/modals/Profile/ProfileContactDetails/ProfileContactDetailsModal";
import { ProfileAboutModal } from "components/modals/Profile/ProfileAboutModal";
import { ProfileAvailabilityModal } from "components/modals/Profile/ProfileAvailabilityModal";
import { ProfileLanguageModal } from "components/modals/Profile/ProfileLanguageModal";
import { ProfileImageModal } from "components/modals/Profile/ProfileImageModal";
import { ProfileImageOptionsDialog } from "components/dialogs/ProfileImageOptionsDialog";
import { ProfileRegionModal } from "components/modals/Profile/ProfileRegionModal";
import { ProfileNotificationsModal } from "components/modals/Profile/ProfileSubscriptionsModal";
import { ProfileRoleModal } from "components/modals/Profile/ProfileRoleModal";
import { ProfileCompanyDetailsModal } from "components/modals/Profile/ProfileCompanyDetails/ProfileCompanyDetailsModal";
import { ShareJobDialog } from "components/dialogs/ShareJobDialog";
import { NoticeDialog } from "components/dialogs/NoticeDialog";
import { LoginDialog } from "components/dialogs/LoginDialog";
import { ConfirmDialog } from "components/dialogs/ConfirmDialog";
import { ProfileReferralsAdd } from "components/dialogs/ProfileReferralsAdd";
import { ProfileReferralsEdit } from "components/dialogs/ProfileReferralsEdit";
import { CookieConsent } from "components/dialogs/CookieConsent";
import { ProfileSocialModal } from "components/modals/Profile/ProfileSocial/ProfileSocialModal";
import { CandidateModal } from "../components/modals/CandidateModal/CandidateModal";
import { ReferralInfoDialog } from "components/dialogs/ReferralInfoDialog";
import { GDPRDialog } from "components/dialogs/GDPRDialog";
import { SendEmailModal } from "components/dialogs/SendEmailModal";
import { CreateInternalApplicationDialog } from "components/dialogs/CreateInternalApplicationDialog";
import { CreateGroupDialog } from "components/dialogs/CreateGroupDialog";

export type ModalTypes = keyof typeof ModalComponents;

const ModalComponents = {
  NAVIGATION_MODAL: NavigationModal,
  DETAILED_JOB_MODAL: DetailedJobModal,
  DETAILED_JOB_APPLICATION_MODAL: DetailedJobApplicationModal,
  APPLICATION_MODAL: JobApplicationFormModal,
  MESSAGE_MODAL: ConversationModal,
  PROFILE_CV_MODAL: ProfileCvsModal,
  PROFILE_REFERRALS_MODAL: ProfileReferrals,
  PROFILE_ROLES_MODAL: ProfileRoleModal,
  PROFILE_SKILLS_MODAL: ProfileSkillsModal,
  PROFILE_AVAILABILITY_MODAL: ProfileAvailabilityModal,
  PROFILE_UPLOADCV: UnderConstructionModal,
  PROFILE_CONTACTDETAILS: ProfileContactDetailsModal,
  PROFILE_SOCIAL: ProfileSocialModal,
  PROFILE_COMPANY: ProfileCompanyDetailsModal,
  PROFILE_PREFERREDRATE: UnderConstructionModal,
  PROFILE_LANGUAGE: ProfileLanguageModal,
  PROFILE_REGION_MODAL: ProfileRegionModal,
  PROFILE_IMAGE_MODAL: ProfileImageModal,
  PROFILE_IMAGE_OPTIONS_MODAL: ProfileImageOptionsDialog,
  PROFILE_SETTINGS_MODAL: UnderConstructionModal,
  PROFILE_GDPR_MODAL: UnderConstructionModal,
  PROFILE_ABOUT: ProfileAboutModal,
  PROFILE_SUBSCRIPTIONS: ProfileNotificationsModal,
  COOKIE_CONSENT: CookieConsent,
  SEND_EMAIL_MODAL: SendEmailModal,
  CANDIDATE_MODAL: CandidateModal,
};

export type DialogTypes = keyof typeof DialogComponents;

const DialogComponents = {
  SHARE_JOB: ShareJobDialog,
  NOTICE_DIALOG: NoticeDialog,
  OPTIONS_DIALOG: OptionsDialog,
  LOGIN_DIALOG: LoginDialog,
  CONFIRM_DIALOG: ConfirmDialog,
  REFERRAL_DIALOG_ADD: ProfileReferralsAdd,
  REFERRAL_DIALOG_EDIT: ProfileReferralsEdit,
  GDPR_DIALOG: GDPRDialog,
  REFERRAL_DIALOG_READ: ReferralInfoDialog,
  CREATE_INTERNAL_APPLICATION: CreateInternalApplicationDialog,
  CREATE_GROUP_DIALOG: CreateGroupDialog,
};

export interface ModalContextState {
  dialogType?: DialogTypes;
  dialogProps?: Record<string, unknown>;
  stack: ModalContextStackState[];
}

interface ModalContextStackState {
  modalType: ModalTypes;
  modalProps?: Record<string, unknown>;
}

export const ModalInitialState: ModalContextState = { stack: [] };

export const ModalContext = createContext<{
  showModal: <T extends ModalTypes>(
    modalType: T,
    modalProps?: ComponentProps<typeof ModalComponents[T]>
  ) => void;
  hideModal: () => void;
  popModal: () => void;
  pushModal: <T extends ModalTypes>(
    modalType: T,
    modalProps?: ComponentProps<typeof ModalComponents[T]>
  ) => void;
  showDialog: <T extends DialogTypes>(
    dialogType: DialogTypes,
    dialogProps?: ComponentProps<typeof DialogComponents[T]>
  ) => void;
  hideDialog: () => void;
  state: typeof ModalInitialState;
}>({
  showModal: () => false,
  hideModal: () => false,
  popModal: () => false,
  pushModal: () => false,
  showDialog: () => false,
  hideDialog: () => false,
  state: { stack: [] },
});

const ModalProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(ModalReducer, ModalInitialState);

  const showModal = <T extends ModalTypes>(
    modalType: T,
    modalProps?: ComponentProps<typeof ModalComponents[T]>
  ): void => {
    dispatch({
      type: "SHOW_MODAL",
      modalType: modalType,
      modalProps: modalProps as Record<string, unknown>,
    });
  };

  const hideModal = () => {
    dispatch({ type: "HIDE_MODAL" });
  };

  const popModal = () => {
    dispatch({
      type: "POP_MODAL",
    });
  };

  const pushModal = <T extends ModalTypes>(
    modalType: T,
    modalProps?: ComponentProps<typeof ModalComponents[T]>
  ): void => {
    dispatch({
      type: "PUSH_MODAL",
      modalType: modalType,
      modalProps: modalProps as Record<string, unknown>,
    });
  };

  const showDialog = <T extends DialogTypes>(
    dialogType: T,
    dialogProps?: ComponentProps<typeof DialogComponents[T]>
  ) => {
    dispatch({
      type: "SHOW_DIALOG",
      dialogType: dialogType,
      dialogProps: dialogProps as Record<string, unknown>,
    });
  };

  const hideDialog = () => {
    dispatch({ type: "HIDE_DIALOG" });
  };

  const renderModal = () => {
    if (!state.stack.length) {
      return null;
    }

    const latestModalState = state.stack[state.stack.length - 1];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ModalComponent = ModalComponents[latestModalState.modalType] as any;

    if (!latestModalState.modalType || !ModalComponent) {
      return null;
    }
    return <ModalComponent {...latestModalState.modalProps} />;
  };

  const renderDialog = () => {
    const DialogComponent = DialogComponents[state.dialogType ?? ""];
    if (!state.dialogType || !DialogComponent) {
      return null;
    }
    return <DialogComponent {...state.dialogProps} />;
  };

  const value = useMemo(() => {
    return { showModal, hideModal, showDialog, hideDialog, pushModal, popModal, state };
  }, [showModal, hideModal, showDialog, hideDialog, pushModal, popModal, state]);

  return (
    <ModalContext.Provider value={value}>
      {renderDialog()}
      {renderModal()}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
