import { Grid, Typography } from "@mui/material";
import { StatusStage } from "adapters/JobApplicationAdapter";

import { LocaleContext } from "context/LocaleContext";

import React, { FC, useContext, useEffect, useState } from "react";
import { WIcon, WIconTypes } from "./WIcon";

// type StatusInfo = {
//   status: string;
//   progressionPercentage: number | null;
// };

// const defaulStatusInfo: StatusInfo = {
//   status: "",
//   progressionPercentage: null,
// };

interface ApplicationStatusComponentProps {
  isDraft: boolean;
  applicationStatus?: string | null;
  jobStatus?: string | null;
  isCancelled?: boolean;
  statusProgression?: StatusStage[] | null;
}

export const ApplicationStatusComponent: FC<ApplicationStatusComponentProps> = ({
  isDraft,
  applicationStatus,
  jobStatus,
  isCancelled = false,
  statusProgression,
}) => {
  const { localize } = useContext(LocaleContext);

  const [fillPercentage, setFillPercentage] = useState<number>(0);
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const getActiveStatus = () => {
    if (jobStatus === "archived" || isCancelled) {
      setIsInactive(true);
    }

    //toDo if is_canceled set status canceled and keep progressionPercentage.
  };

  useEffect(() => {
    if (statusProgression) {
      getActiveStatus();

      for (let i = 0; i < statusProgression.length; i++) {
        if (statusProgression[i].active === false) {
          setFillPercentage((i / statusProgression.length) * 100);
          return;
        }
        setFillPercentage(100);
      }
    } else {
      setFillPercentage(0);
      getActiveStatus();
    }
  }, [jobStatus]);

  const text = isDraft ? "draft" : "sent";

  const color = isDraft ? "#B07400" : "#48d286";

  //Work in progress
  return (
    <Grid container wrap="nowrap" gap=".3rem" alignItems="center" width="max-content">
      {isInactive ? (
        <WIcon icon={WIconTypes.lock} color="secondaryDark" />
      ) : (
        <svg height="30" width="30" viewBox="0 0 30 30" transform="scale(1.1)">
          <g transform="translate(7,7)">
            <defs>
              {/* Used to clip the outer border, the only way to create an illusion of inner border */}
              <clipPath id="insideCircleOnly">
                <circle r="10" cx="10" cy="10" />
              </clipPath>
            </defs>
            <circle
              r="10"
              cx="10"
              cy="10"
              fill="white"
              fillOpacity="0.0"
              strokeWidth="3"
              clipPath="url(#insideCircleOnly)"
              strokeDasharray={isDraft ? "4" : "0"}
              stroke={`${color}`}
            />
            <circle
              r="5"
              cx="10"
              cy="10"
              fill="transparent"
              stroke={`${color}`}
              strokeWidth="10"
              strokeDasharray={`calc(${fillPercentage - 1} * 31.42 / 100) 31.42`}
              transform="rotate(-90) translate(-20)"
            />
          </g>
        </svg>
      )}

      <Typography
        fontWeight="bold"
        paddingTop=".3rem" // awuful but necessary fix to align the text due to svg being villainous
        color={isInactive ? "secondary.dark" : color}
        sx={{
          overflowWrap: "break-word",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          WebkitLineClamp: 2,
          textTransform: "capitalize",
        }}
      >
        {isInactive
          ? localize("jobCard.archived")
          : applicationStatus
          ? applicationStatus
          : localize(`common.${text}`)}
      </Typography>
    </Grid>
  );
};
