import React, { FC } from "react";
import { colors } from "styles/colors";
import { WGridSVGWrapper, WGridSVGWrapperProps } from "components/WCustomSVGGrid";

export const QuickReplySVG: FC<WGridSVGWrapperProps> = (props) => {
  return (
    <WGridSVGWrapper {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 256 260"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="255" height="260" transform="translate(0.5)" fill="white" />
        <mask
          id="mask0_6_872"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="256"
          height="260"
        >
          <rect x="0.5" width="255" height="260" fill="white" />
        </mask>
        <g mask="url(#mask0_6_872)">
          <rect opacity="0.02" x="0.5" width="255" height="260" fill="white" />
          <g opacity="0.06">
            <path d="M0.5 0L255.5 260" stroke="white" />
            <path d="M10.4609 -10.1562L265.461 249.844" stroke="white" />
            <path d="M20.4219 -20.3125L275.422 239.688" stroke="white" />
            <path d="M30.3828 -30.4688L285.383 229.531" stroke="white" />
            <path d="M40.3438 -40.625L295.344 219.375" stroke="white" />
            <path d="M50.3047 -50.7812L305.305 209.219" stroke="white" />
            <path d="M60.2656 -60.9375L315.266 199.062" stroke="white" />
            <path d="M70.2266 -71.0938L325.227 188.906" stroke="white" />
            <path d="M80.1875 -81.25L335.188 178.75" stroke="white" />
            <path d="M90.1484 -91.4062L345.148 168.594" stroke="white" />
            <path d="M100.109 -101.562L355.109 158.438" stroke="white" />
            <path d="M110.07 -111.719L365.07 148.281" stroke="white" />
            <path d="M120.031 -121.875L375.031 138.125" stroke="white" />
            <path d="M-9.46094 10.1562L245.539 270.156" stroke="white" />
            <path d="M-19.4219 20.3125L235.578 280.312" stroke="white" />
            <path d="M-29.3828 30.4688L225.617 290.469" stroke="white" />
            <path d="M-39.3438 40.625L215.656 300.625" stroke="white" />
            <path d="M-49.3047 50.7812L205.695 310.781" stroke="white" />
            <path d="M-59.2656 60.9375L195.734 320.938" stroke="white" />
            <path d="M-69.2266 71.0938L185.773 331.094" stroke="white" />
            <path d="M-79.1875 81.25L175.812 341.25" stroke="white" />
            <path d="M-89.1484 91.4062L165.852 351.406" stroke="white" />
            <path d="M-99.1094 101.562L155.891 361.562" stroke="white" />
            <path d="M-109.07 111.719L145.93 371.719" stroke="white" />
            <path d="M-119.031 121.875L135.969 381.875" stroke="white" />
            <path d="M-128.992 132.031L126.008 392.031" stroke="white" />
          </g>
          <g opacity="0.06">
            <path d="M5.48047 -5.07812L260.48 254.922" stroke="white" />
            <path d="M15.4414 -15.2344L270.441 244.766" stroke="white" />
            <path d="M25.4023 -25.3906L280.402 234.609" stroke="white" />
            <path d="M35.3633 -35.5469L290.363 224.453" stroke="white" />
            <path d="M45.3242 -45.7031L300.324 214.297" stroke="white" />
            <path d="M55.2852 -55.8594L310.285 204.141" stroke="white" />
            <path d="M65.2461 -66.0156L320.246 193.984" stroke="white" />
            <path d="M75.207 -76.1719L330.207 183.828" stroke="white" />
            <path d="M85.168 -86.3281L340.168 173.672" stroke="white" />
            <path d="M95.1289 -96.4844L350.129 163.516" stroke="white" />
            <path d="M105.09 -106.641L360.09 153.359" stroke="white" />
            <path d="M115.051 -116.797L370.051 143.203" stroke="white" />
            <path d="M125.012 -126.953L380.012 133.047" stroke="white" />
            <path d="M-4.48047 5.07812L250.52 265.078" stroke="white" />
            <path d="M-14.4414 15.2344L240.559 275.234" stroke="white" />
            <path d="M-24.4023 25.3906L230.598 285.391" stroke="white" />
            <path d="M-34.3633 35.5469L220.637 295.547" stroke="white" />
            <path d="M-44.3242 45.7031L210.676 305.703" stroke="white" />
            <path d="M-54.2852 55.8594L200.715 315.859" stroke="white" />
            <path d="M-64.2461 66.0156L190.754 326.016" stroke="white" />
            <path d="M-74.207 76.1719L180.793 336.172" stroke="white" />
            <path d="M-84.168 86.3281L170.832 346.328" stroke="white" />
            <path d="M-94.1289 96.4844L160.871 356.484" stroke="white" />
            <path d="M-104.09 106.641L150.91 366.641" stroke="white" />
            <path d="M-114.051 116.797L140.949 376.797" stroke="white" />
            <path d="M-124.012 126.953L130.988 386.953" stroke="white" />
          </g>
        </g>
        <g opacity="0.5">
          <path
            d="M140.167 115H116.833C114.992 115 113.5 116.492 113.5 118.333V141.667C113.5 143.508 114.992 145 116.833 145H140.167C142.008 145 143.5 143.508 143.5 141.667V118.333C143.5 116.492 142.008 115 140.167 115Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M122.667 126.667C124.047 126.667 125.167 125.547 125.167 124.167C125.167 122.786 124.047 121.667 122.667 121.667C121.286 121.667 120.167 122.786 120.167 124.167C120.167 125.547 121.286 126.667 122.667 126.667Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M143.5 135L135.167 126.667L116.833 145"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g clipPath="url(#clip0_6_872)">
          <rect width="241" height="209" transform="translate(7.5 25)" fill="white" />
          <path
            d="M108.223 191.329C108.237 193.094 108.95 194.78 110.205 196.019C111.46 197.258 113.155 197.947 114.918 197.936L228.982 197.118C230.744 197.104 232.429 196.391 233.666 195.134C234.903 193.877 235.59 192.18 235.578 190.416C235.565 188.651 234.853 186.964 233.599 185.725C232.344 184.486 230.649 183.797 228.887 183.809L114.822 184.627C113.06 184.641 111.375 185.355 110.138 186.612C108.901 187.868 108.212 189.565 108.223 191.329Z"
            fill="#F2F2F2"
          />
          <path
            d="M121.906 189.738C121.905 189.626 121.95 189.519 122.028 189.439C122.107 189.36 122.214 189.315 122.326 189.314L151.788 189.209C151.843 189.209 151.898 189.219 151.949 189.24C152 189.261 152.047 189.292 152.086 189.331C152.126 189.37 152.157 189.417 152.178 189.468C152.2 189.519 152.211 189.574 152.211 189.63C152.211 189.685 152.2 189.74 152.179 189.791C152.158 189.843 152.127 189.889 152.088 189.929C152.049 189.968 152.003 189.999 151.952 190.021C151.901 190.042 151.846 190.053 151.791 190.053L122.329 190.159C122.217 190.159 122.11 190.115 122.03 190.036C121.951 189.957 121.906 189.85 121.906 189.738Z"
            fill="#CCCCCC"
          />
          <path
            d="M121.94 193.609C121.94 193.497 121.984 193.39 122.062 193.31C122.141 193.231 122.248 193.186 122.36 193.186L198.215 192.735C198.27 192.735 198.325 192.745 198.377 192.766C198.428 192.787 198.475 192.818 198.514 192.857C198.553 192.896 198.585 192.942 198.606 192.993C198.628 193.045 198.639 193.099 198.639 193.155C198.639 193.21 198.629 193.265 198.608 193.317C198.587 193.368 198.556 193.415 198.517 193.454C198.478 193.494 198.432 193.525 198.381 193.547C198.33 193.568 198.275 193.579 198.22 193.58H198.218L122.363 194.03C122.251 194.03 122.144 193.986 122.065 193.907C121.985 193.828 121.94 193.721 121.94 193.609Z"
            fill="#CCCCCC"
          />
          <path
            d="M108.223 165.999C108.237 167.763 108.95 169.45 110.205 170.689C111.46 171.927 113.155 172.617 114.918 172.606L228.982 171.787C230.744 171.774 232.429 171.06 233.666 169.803C234.903 168.546 235.59 166.849 235.578 165.085C235.565 163.321 234.853 161.634 233.599 160.395C232.344 159.156 230.649 158.467 228.887 158.478L114.822 159.297C113.06 159.311 111.375 160.025 110.138 161.281C108.901 162.538 108.212 164.235 108.223 165.999Z"
            fill="#F2F2F2"
          />
          <path
            d="M120.653 168.279C120.653 168.167 120.697 168.059 120.776 167.98C120.855 167.901 120.962 167.856 121.074 167.855L196.928 167.405C196.984 167.404 197.039 167.415 197.09 167.436C197.141 167.457 197.188 167.488 197.227 167.527C197.266 167.566 197.298 167.613 197.319 167.664C197.34 167.715 197.352 167.77 197.352 167.825C197.352 167.881 197.341 167.936 197.32 167.987C197.299 168.038 197.268 168.085 197.229 168.124C197.19 168.164 197.144 168.195 197.093 168.216C197.042 168.238 196.987 168.249 196.931 168.249L121.077 168.7C120.965 168.7 120.857 168.656 120.778 168.577C120.699 168.498 120.654 168.391 120.653 168.279Z"
            fill="#CCCCCC"
          />
          <path
            d="M120.653 163.986C120.653 163.874 120.697 163.766 120.776 163.687C120.855 163.607 120.962 163.562 121.074 163.562L196.928 163.111C196.984 163.111 197.039 163.122 197.09 163.143C197.141 163.164 197.188 163.195 197.227 163.234C197.266 163.273 197.298 163.319 197.319 163.371C197.34 163.422 197.352 163.477 197.352 163.532C197.352 163.588 197.341 163.642 197.32 163.694C197.299 163.745 197.268 163.792 197.229 163.831C197.19 163.87 197.144 163.902 197.093 163.923C197.042 163.945 196.987 163.956 196.931 163.956L121.077 164.406C120.965 164.407 120.857 164.363 120.778 164.284C120.699 164.205 120.654 164.098 120.653 163.986Z"
            fill="#CCCCCC"
          />
          <path
            d="M141.607 220.691L27.5419 219.872C25.7797 219.861 24.0849 220.55 22.8302 221.789C21.5756 223.028 20.8636 224.715 20.851 226.479C20.8384 228.244 21.5261 229.941 22.7629 231.197C23.9997 232.454 25.6844 233.168 27.4466 233.181L141.511 234C143.274 234.012 144.968 233.322 146.223 232.083C147.478 230.844 148.19 229.157 148.202 227.393C148.215 225.629 147.527 223.932 146.29 222.675C145.054 221.418 143.369 220.704 141.607 220.691Z"
            fill="#F2F2F2"
          />
          <path
            d="M63.3435 226.223L33.8819 226.117C33.8265 226.117 33.7717 226.106 33.7205 226.084C33.6694 226.063 33.6231 226.032 33.584 225.992C33.545 225.953 33.5141 225.906 33.4931 225.855C33.4721 225.804 33.4614 225.749 33.4616 225.693C33.4617 225.638 33.4728 225.583 33.4942 225.532C33.5156 225.481 33.5468 225.434 33.5861 225.395C33.6255 225.356 33.6721 225.325 33.7233 225.304C33.7746 225.283 33.8295 225.272 33.8849 225.272L63.3465 225.378C63.402 225.378 63.4569 225.389 63.5082 225.41C63.5594 225.432 63.6059 225.463 63.6451 225.502C63.6842 225.542 63.7153 225.588 63.7363 225.64C63.7574 225.691 63.7682 225.746 63.768 225.802C63.7678 225.857 63.7566 225.912 63.7352 225.963C63.7137 226.015 63.6824 226.061 63.643 226.1C63.6035 226.139 63.5568 226.17 63.5054 226.191C63.454 226.212 63.399 226.223 63.3435 226.223Z"
            fill="#CCCCCC"
          />
          <path
            d="M110.051 230.094L34.1966 229.643C34.1412 229.643 34.0863 229.632 34.0352 229.611C33.9841 229.589 33.9378 229.558 33.8987 229.519C33.8597 229.479 33.8288 229.433 33.8078 229.381C33.7868 229.33 33.7761 229.275 33.7762 229.22C33.7764 229.164 33.7875 229.109 33.8089 229.058C33.8303 229.007 33.8615 228.96 33.9008 228.921C33.9402 228.882 33.9868 228.851 34.038 228.83C34.0893 228.809 34.1442 228.798 34.1996 228.799L110.054 229.249C110.11 229.249 110.165 229.261 110.216 229.282C110.267 229.303 110.313 229.335 110.352 229.374C110.391 229.413 110.422 229.46 110.443 229.511C110.464 229.563 110.475 229.618 110.475 229.673C110.475 229.729 110.463 229.783 110.442 229.835C110.421 229.886 110.389 229.932 110.35 229.971C110.311 230.01 110.264 230.041 110.213 230.062C110.162 230.083 110.107 230.094 110.051 230.094Z"
            fill="#CCCCCC"
          />
          <path
            d="M245.498 168.466C247.156 168.466 248.5 167.121 248.5 165.461C248.5 163.801 247.156 162.456 245.498 162.456C243.84 162.456 242.496 163.801 242.496 165.461C242.496 167.121 243.84 168.466 245.498 168.466Z"
            fill="#F2F2F2"
          />
          <path
            d="M245.498 193.797C247.156 193.797 248.5 192.451 248.5 190.791C248.5 189.132 247.156 187.786 245.498 187.786C243.84 187.786 242.496 189.132 242.496 190.791C242.496 192.451 243.84 193.797 245.498 193.797Z"
            fill="#F2F2F2"
          />
          <path
            d="M110.73 209.818L28.2044 222.195C27.5319 222.296 26.8461 222.264 26.1861 222.1C25.5261 221.936 24.9048 221.643 24.3579 221.239C23.8109 220.834 23.349 220.325 22.9984 219.742C22.6478 219.159 22.4156 218.512 22.3148 217.838C22.2141 217.165 22.2468 216.478 22.4112 215.818C22.5757 215.157 22.8685 214.535 23.273 213.988C23.6775 213.441 24.1858 212.979 24.7688 212.628C25.3518 212.277 25.9981 212.045 26.6707 211.945L109.196 199.568C109.869 199.467 110.555 199.499 111.215 199.663C111.875 199.827 112.496 200.12 113.043 200.525C113.59 200.929 114.052 201.438 114.402 202.021C114.753 202.605 114.985 203.251 115.086 203.925C115.187 204.598 115.154 205.285 114.99 205.945C114.825 206.606 114.532 207.228 114.128 207.775C113.723 208.322 113.215 208.785 112.632 209.135C112.049 209.486 111.403 209.718 110.73 209.818L110.73 209.818Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M53.3108 212.774L32.0074 216.048C31.9265 216.06 31.844 216.04 31.778 215.991C31.712 215.943 31.668 215.87 31.6556 215.789C31.6431 215.708 31.6634 215.625 31.7118 215.559C31.7602 215.493 31.8329 215.449 31.9138 215.437L53.2172 212.163C53.2981 212.151 53.3806 212.171 53.4466 212.22C53.5126 212.268 53.5566 212.341 53.569 212.422C53.5815 212.503 53.5612 212.586 53.5128 212.652C53.4644 212.718 53.3917 212.762 53.3108 212.774Z"
            fill="white"
          />
          <path
            d="M87.5051 210.262L32.6348 218.56C32.5539 218.573 32.4714 218.552 32.4054 218.504C32.3394 218.455 32.2954 218.383 32.283 218.302C32.2706 218.221 32.2908 218.138 32.3392 218.072C32.3876 218.006 32.4603 217.962 32.5412 217.949L87.4115 209.651C87.4516 209.645 87.4925 209.646 87.532 209.656C87.5714 209.666 87.6086 209.683 87.6413 209.707C87.6741 209.731 87.7018 209.761 87.7229 209.796C87.7439 209.831 87.7579 209.869 87.7641 209.909C87.7702 209.949 87.7684 209.99 87.7587 210.03C87.749 210.069 87.7317 210.106 87.7076 210.139C87.6836 210.172 87.6533 210.2 87.6185 210.221C87.5838 210.242 87.5453 210.256 87.5051 210.262V210.262Z"
            fill="white"
          />
          <path
            d="M91.5595 203.589C91.2394 203.57 90.9272 203.482 90.6444 203.331C90.3617 203.18 90.1151 202.969 89.9218 202.713C89.7285 202.457 89.593 202.162 89.5248 201.848C89.4565 201.534 89.4572 201.21 89.5266 200.896L80.5569 194.465L81.7237 191.543L92.3214 199.26C92.8396 199.416 93.2825 199.758 93.5662 200.219C93.8499 200.68 93.9548 201.23 93.8608 201.763C93.7669 202.297 93.4807 202.777 93.0566 203.114C92.6324 203.45 92.0997 203.619 91.5595 203.589Z"
            fill="#A0616A"
          />
          <path
            d="M67.4622 167.629L62.0723 169.604C61.8354 169.691 61.618 169.824 61.4323 169.994C61.2467 170.165 61.0965 170.371 60.9903 170.6C60.8841 170.829 60.824 171.077 60.8135 171.329C60.8029 171.582 60.8421 171.833 60.9287 172.071C60.9499 172.129 60.9738 172.185 61.0005 172.241L68.3492 187.589L86.0557 200.366L88.7697 196.037L75.3568 183.809L67.4622 167.629Z"
            fill="#3F3D56"
          />
          <path
            d="M65.2459 199.371C59.1035 198.799 53.0865 197.379 46.9347 196.902L49.4036 190.611L62.021 192.492L65.2459 199.371Z"
            fill="#A0616A"
          />
          <path
            d="M44.4063 200.85L44.3529 201.321L43.9137 205.106L43.3992 205.137L43.371 209.877C43.5328 210.346 43.5319 210.855 43.3686 211.324C43.2052 211.792 42.8889 212.192 42.4707 212.458C42.4013 212.503 42.329 212.543 42.2542 212.578C41.9414 210.118 42.0357 207.623 42.5334 205.194C42.7332 204.29 43.0276 203.41 43.4118 202.568C43.6054 202.151 43.8254 201.747 44.0706 201.359C44.1741 201.186 44.287 201.016 44.4063 200.85Z"
            fill="#2F2E41"
          />
          <path
            d="M44.5034 200.033L44.4062 200.847V200.85L44.3529 201.321C44.2148 201.34 44.1176 201.352 44.0705 201.359C44.0591 201.361 44.0476 201.362 44.036 201.362L44.5034 200.033Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M66.8395 184.436L65.7792 190.611C65.7792 190.611 68.3548 196.421 66.5823 197.869C66.3655 198.064 66.1987 198.308 66.0961 198.581C65.9934 198.854 65.9579 199.147 65.9925 199.437C65.7416 199.415 65.4937 199.393 65.2459 199.371C59.1035 198.799 53.0865 197.379 46.9347 196.902C46.4987 196.867 46.0657 196.836 45.6297 196.814L47.5245 191.418L46.2446 186.622L44.9176 181.647L44.6321 173.456C44.6253 173.301 44.6263 173.146 44.6352 172.991C44.6352 172.91 44.6384 172.831 44.6447 172.749C44.7136 171.715 45.0132 170.709 45.5213 169.806C46.0294 168.903 46.7332 168.125 47.581 167.529C47.5834 167.529 47.5856 167.528 47.5873 167.526C47.8763 167.323 48.1805 167.143 48.497 166.986C48.837 166.817 49.1907 166.678 49.5542 166.568L49.9181 166.458L51.8882 163.267L54.172 163.029L55.019 162.941L60.5371 162.366L61.7261 163.911L61.9425 164.194L62.7801 165.281L65.3902 166.672C65.9607 166.975 66.4821 167.362 66.9368 167.822C68.004 168.89 68.6662 170.297 68.8096 171.801C68.8442 172.147 68.8505 172.496 68.8284 172.844L66.8395 184.436Z"
            fill="#3F3D56"
          />
          <path
            d="M39.9791 156.628C39.9224 159.069 40.8336 161.432 42.1426 163.508C42.5799 162.303 43.1922 161.168 43.9599 160.142C43.5923 162.416 43.9838 164.749 45.0739 166.779C45.1123 166.806 45.1521 166.832 45.1931 166.855C45.7164 167.195 46.3103 167.411 46.9295 167.487C47.5487 167.563 48.1771 167.497 48.767 167.294C47.6017 165.973 46.9029 164.306 46.7784 162.548C46.6539 160.791 47.1106 159.041 48.0781 157.569C48.6441 156.75 49.3763 156.026 49.7269 155.093C50.2804 153.606 49.7483 151.968 49.4126 150.418C49.0987 148.974 49.0438 147.257 50.0109 146.199C49.9076 146.051 49.7669 145.934 49.6032 145.858C49.4395 145.783 49.2588 145.752 49.0794 145.77C48.7879 145.844 48.5178 145.985 48.2908 146.183C48.0639 146.38 47.8864 146.629 47.7726 146.907C47.5496 147.466 47.2666 147.998 46.9286 148.496C46.2152 149.36 45.0002 149.564 43.9763 150.019C41.4967 151.125 40.0385 153.911 39.9791 156.628Z"
            fill="#2F2E41"
          />
          <path
            d="M60.8515 155.232C60.7098 154.735 61.6865 157.771 61.2152 157.825C59.0687 158.072 56.122 159.355 54.627 159.954C54.5597 159.946 54.4923 159.938 54.427 159.929C54.2666 159.911 54.1102 159.886 53.9557 159.856C50.4745 159.197 49.1359 153.843 49.1359 153.794C49.1359 153.553 49.7128 146.943 50.6217 146.845C50.8605 146.819 51.1004 146.806 51.3405 146.806H53.596C54.0627 146.705 54.5357 146.637 55.0118 146.601H55.0158C59.5563 146.254 63.1365 149.025 62.8019 152.397C62.7999 152.4 62.7959 152.404 62.7939 152.408C62.6969 152.553 62.6118 152.69 62.5365 152.821C62.4632 152.944 62.4018 153.062 62.3484 153.173C61.9345 154.034 62.9983 155.118 62.2929 155.32C61.3547 155.589 64.7604 164.429 61.0469 164.429C62.8533 164.943 61.2424 156.602 60.8515 155.232Z"
            fill="#2F2E41"
          />
          <path
            d="M56.7983 160.419C59.7475 160.419 62.1383 158.025 62.1383 155.072C62.1383 152.12 59.7475 149.726 56.7983 149.726C53.8491 149.726 51.4583 152.12 51.4583 155.072C51.4583 158.025 53.8491 160.419 56.7983 160.419Z"
            fill="#A0616A"
          />
          <path
            d="M62.8019 152.688C62.7128 152.735 62.6256 152.778 62.5365 152.821C62.4435 152.866 62.3504 152.911 62.2573 152.952C60.4692 153.764 59.0138 153.854 58.1306 152.688C58.107 152.074 57.9693 151.471 57.7247 150.907C57.7679 151.537 57.6022 152.163 57.2534 152.688H55.4752C53.6435 153.905 52.7029 154.032 53.9742 158.028C54.3287 159.142 55.738 165.62 55.1836 166.581C55.0232 166.564 53.3168 159.467 53.1623 159.438C49.6812 158.778 47.4693 153.036 47.7525 152.712C47.7511 151.788 47.9632 150.876 48.3723 150.048C48.8898 148.999 49.6965 148.121 50.697 147.516C51.2934 147.227 51.9152 146.993 52.5544 146.818C52.5703 146.814 52.5841 146.81 52.6 146.806C52.796 146.753 52.996 146.704 53.196 146.663C53.6092 146.576 54.027 146.513 54.4475 146.473C54.4772 146.471 54.5069 146.47 54.5346 146.47C54.7025 146.47 54.8672 146.515 55.0118 146.601C55.0138 146.601 55.0138 146.603 55.0158 146.603C55.1191 146.663 55.2093 146.744 55.2811 146.839C55.4076 147.001 55.476 147.2 55.4752 147.405H57.4554C57.5286 147.405 57.6019 147.407 57.6752 147.411C58.9968 147.456 60.254 147.993 61.2007 148.918C62.1475 149.842 62.7156 151.087 62.794 152.408C62.7999 152.502 62.8019 152.594 62.8019 152.688Z"
            fill="#2F2E41"
          />
          <path
            d="M51.1263 150.979C53.0693 150.979 54.6443 149.402 54.6443 147.457C54.6443 145.512 53.0693 143.935 51.1263 143.935C49.1834 143.935 47.6083 145.512 47.6083 147.457C47.6083 149.402 49.1834 150.979 51.1263 150.979Z"
            fill="#2F2E41"
          />
          <path
            d="M42.4706 212.458C42.4012 212.503 42.3289 212.543 42.2541 212.578C41.9939 212.706 41.711 212.782 41.4215 212.801C41.132 212.82 40.8417 212.781 40.567 212.688C40.2923 212.594 40.0387 212.448 39.8207 212.256C39.6026 212.064 39.4244 211.832 39.2962 211.571C39.168 211.311 39.0923 211.027 39.0734 210.738C39.0545 210.448 39.0929 210.157 39.1862 209.882C39.2795 209.607 39.4261 209.353 39.6174 209.135C39.8088 208.917 40.0412 208.738 40.3014 208.61C40.339 208.591 40.3772 208.574 40.4158 208.557L40.4095 205.326L40.4032 201.522L40.397 197.511L43.4462 196.757L43.4117 202.568L43.3991 205.137L43.3709 209.876C43.5327 210.345 43.5319 210.855 43.3685 211.324C43.2051 211.792 42.8888 212.192 42.4706 212.458Z"
            fill="#A0616A"
          />
          <path
            d="M54.5234 171.207L54.2065 171.801L46.2446 186.622L46.0061 187.065L44.8799 196.78L44.5035 200.033L44.4062 200.847V200.85L44.3529 201.321L43.9137 205.106L43.3992 205.137L42.5334 205.194L40.4096 205.326L38.8191 205.426L38.8222 202.879L38.8504 183.576L44.6352 172.991L44.7764 172.731L47.01 168.644C47.2553 168.196 47.6683 167.864 48.1583 167.721C48.6482 167.578 49.1748 167.635 49.6224 167.881C49.6754 167.91 49.7269 167.941 49.7769 167.975L54.5234 171.207Z"
            fill="#3F3D56"
          />
          <path
            d="M10.5018 228.143C12.1596 228.143 13.5036 226.797 13.5036 225.138C13.5036 223.478 12.1596 222.132 10.5018 222.132C8.84394 222.132 7.5 223.478 7.5 225.138C7.5 226.797 8.84394 228.143 10.5018 228.143Z"
            fill="#F2F2F2"
          />
          <path
            d="M216.307 116.212C216.307 116.212 215.219 127.748 214.616 136.287V136.294C214.569 136.982 214.525 137.646 214.485 138.288L214.165 138.316C213.945 138.338 213.729 138.364 213.509 138.386C210.002 138.759 206.529 139.378 203.044 139.915C202.708 139.965 202.372 140.016 202.037 140.066C198.56 140.634 195.044 140.92 191.521 140.92H191.455C191.484 140.48 191.527 139.906 191.578 139.246C191.873 135.512 192.469 129.039 192.469 129.039L193.024 125.292L194.84 113.018L194.853 112.923L194.868 112.817L194.956 112.795L198.771 111.802L200.823 111.271L202.015 109.625L203.602 107.433L210.203 108.247L210.686 110.049L211.58 113.372L211.608 113.479L215.071 115.492L216.307 116.212Z"
            fill="#E6E6E6"
          />
          <path
            d="M206.886 106.723C210.749 106.723 213.881 103.588 213.881 99.7201C213.881 95.8523 210.749 92.7169 206.886 92.7169C203.022 92.7169 199.891 95.8523 199.891 99.7201C199.891 103.588 203.022 106.723 206.886 106.723Z"
            fill="#FFB6B6"
          />
          <path
            d="M199.556 95.4818C200.17 95.9787 200.03 98.3954 200.714 98.0015C201.398 97.6076 201.541 96.9022 202.329 96.8948C202.971 96.9303 203.577 97.2049 204.028 97.6647C204.112 97.7406 204.192 97.8202 204.269 97.8988C204.34 97.8739 204.41 97.8514 204.484 97.8329C205.037 97.6978 205.62 97.78 206.114 98.0627C205.658 98.0953 205.223 98.2707 204.872 98.5639C205.023 98.7327 205.175 98.9024 205.336 99.0593C206.676 100.374 209.422 98.9739 211.269 98.6581C209.731 100.34 210.034 101.278 210.417 103.526L210.418 103.649C210.497 103.13 212.155 103.156 211.882 103.844C211.539 104.524 211.109 105.156 210.604 105.725C212.863 104.158 214.687 101.998 215.09 99.3888C215.238 98.8539 215.268 98.2933 215.178 97.7456C215.112 97.4752 214.958 97.2343 214.74 97.0607C214.523 96.8871 214.254 96.7905 213.976 96.7861C214.228 95.9857 214.258 95.132 214.064 94.3158C213.87 93.4995 213.458 92.7512 212.873 92.1504C212.288 91.5496 211.551 91.1188 210.741 90.9037C209.931 90.6886 209.078 90.6974 208.272 90.929C207.537 91.1904 206.821 91.5002 206.127 91.8561C205.426 92.2135 204.633 92.3479 203.854 92.2415C203.369 92.1411 202.877 91.8895 202.402 92.032C201.851 92.1975 201.614 92.7803 201.393 93.3429C201.807 93.4945 202.168 93.7664 202.427 94.124C201.998 93.9656 201.531 93.9466 201.091 94.0696C201.083 94.071 201.075 94.0732 201.067 94.0761C200.568 94.9714 200.539 95.1909 199.556 95.4818Z"
            fill="#2F2E41"
          />
          <path
            d="M203.044 139.915C202.708 139.965 202.372 140.016 202.037 140.066C198.56 140.634 195.044 140.92 191.521 140.92C191.546 140.364 191.562 139.805 191.578 139.246C191.6 138.464 191.615 137.679 191.631 136.894C191.703 133.335 191.775 129.778 191.847 126.221C191.85 126.052 191.854 125.879 191.857 125.709C191.929 122.113 192.026 118.398 193.516 115.122C193.857 114.376 194.281 113.671 194.78 113.021C194.806 112.986 194.828 112.955 194.853 112.923C194.887 112.879 194.922 112.835 194.956 112.794C195.356 112.295 195.802 111.833 196.286 111.416C197.035 110.725 197.914 110.191 198.872 109.844C199.83 109.497 200.847 109.345 201.864 109.396L202.015 109.625L202.215 109.936C202.084 110.919 201.949 111.906 201.823 112.892C201.281 117.166 200.892 121.466 201.328 125.744C201.698 129.34 202.648 132.861 202.949 136.46C202.962 136.58 202.971 136.702 202.981 136.821C203.055 137.851 203.076 138.883 203.044 139.915Z"
            fill="#3F3D56"
          />
          <path
            d="M214.164 138.314C214.318 137.645 214.464 136.971 214.618 136.292V136.288C215.215 133.609 215.812 130.93 216.411 128.251C217.196 124.739 217.98 121.107 217.307 117.573C217.239 117.21 217.151 116.85 217.046 116.496C217.011 116.38 216.973 116.264 216.934 116.148C216.664 115.334 216.297 114.556 215.841 113.83C214.648 111.923 212.865 110.442 210.686 110.052C210.646 110.039 210.604 110.031 210.562 110.026L210.086 110.468C209.987 111.382 209.893 112.306 209.79 113.224C209.301 117.573 208.662 121.918 207.2 126.031C205.948 129.552 204.112 132.845 202.967 136.4C202.963 136.421 202.954 136.442 202.95 136.46C202.572 137.642 202.267 138.846 202.037 140.066C202.371 140.014 202.71 139.967 203.044 139.916C206.531 139.379 210 138.761 213.508 138.387L214.164 138.314Z"
            fill="#3F3D56"
          />
          <path
            d="M220.978 98.2466L220.777 96.3181L220.73 95.8721L220.658 95.156L219.108 80.2719C219.236 79.8425 219.219 79.3827 219.058 78.9645C218.897 78.5462 218.603 78.1931 218.22 77.9603C217.778 77.6965 217.25 77.6191 216.751 77.7452C216.252 77.8712 215.824 78.1903 215.56 78.6324C215.551 78.6481 215.544 78.6638 215.535 78.6795C215.271 79.1395 215.198 79.6842 215.329 80.1979C215.46 80.7116 215.786 81.1539 216.238 81.4308C216.331 81.4845 216.429 81.5307 216.529 81.569L216.517 81.9868L216.479 83.2965L216.451 84.2858L216.391 86.2551L216.369 87.0183L216.297 89.4932L216.228 91.8802L216.144 94.7729L216.078 96.9965L216.878 97.2007L220.978 98.2466Z"
            fill="#FFB6B6"
          />
          <path
            d="M222.528 101.416C222.494 101.157 222.432 100.903 222.342 100.659C221.938 99.9551 221.69 100.938 221.913 99.0537C222.092 97.5242 221.019 97.7158 221.404 97.3891C222.041 96.8489 221.163 96.6102 220.777 96.3181C220.605 96.1893 220.532 96.0511 220.73 95.8721C220.823 95.7912 220.926 95.7236 221.037 95.6711C221.458 95.4513 221.216 95.2848 220.658 95.156C219.402 94.9465 218.132 94.8279 216.859 94.8011C216.551 94.7854 216.3 94.7791 216.144 94.7729C216.028 94.7697 215.962 94.7697 215.962 94.7697C215.743 94.9776 215.606 95.2574 215.576 95.558C215.483 96.1964 215.532 96.8474 215.72 97.4645C215.849 97.8929 216.02 98.3075 216.231 98.702C216.312 98.8498 216.349 99.0176 216.338 99.1857V99.1888C216.316 99.288 216.272 99.3811 216.209 99.4615C216.147 99.5418 216.068 99.6074 215.977 99.6536C215.554 99.9363 215.789 100.53 216.118 101.089C216.398 101.464 216.636 101.868 216.827 102.295C216.827 102.681 214.217 110.891 214.217 110.891L215.177 123.288C215.889 123.838 216.788 124.087 217.681 123.982C218.426 123.857 219.114 123.506 219.654 122.977C220.413 122.234 221.031 121.358 221.477 120.392C224.011 115.295 223.067 104.424 222.528 101.416Z"
            fill="#3F3D56"
          />
          <path
            d="M174.93 80.9716C175.406 81.204 175.773 81.6131 175.952 82.1119C176.132 82.6108 176.109 83.16 175.89 83.6427C175.844 83.7401 175.79 83.8336 175.729 83.922L184.755 96.4324L181.399 100.222L172.866 84.2996C172.52 84.016 172.276 83.6255 172.175 83.189C172.073 82.7525 172.118 82.2945 172.304 81.8866C172.521 81.4198 172.915 81.0587 173.399 80.8828C173.883 80.7068 174.416 80.7304 174.882 80.9484C174.898 80.9559 174.914 80.9636 174.93 80.9716Z"
            fill="#FFB6B6"
          />
          <path
            d="M200.232 117.653C200.232 117.653 199.026 121.974 193.394 118.799C187.763 115.625 181.673 103.77 181.612 102.958C181.551 102.145 182.308 102.821 181.068 101.387C180.06 100.222 181.055 99.7771 180.554 99.7231C179.23 99.581 181.528 97.9015 179.894 98.0942C178.26 98.2869 183.588 94.4952 183.588 94.4952C183.588 94.4952 184.559 94.5186 185.3 96.5908C185.716 97.7547 185.323 98.5511 186.315 98.5465C187.307 98.542 186.883 100.9 187.092 101.212C187.302 101.523 194.072 106.856 194.072 106.856L200.232 117.653Z"
            fill="#3F3D56"
          />
          <path
            d="M192.74 90.3282C210.759 90.3282 225.366 75.7039 225.366 57.6641C225.366 39.6242 210.759 25 192.74 25C174.722 25 160.115 39.6242 160.115 57.6641C160.115 75.7039 174.722 90.3282 192.74 90.3282Z"
            fill={colors.mainSVGColor}
          />
          <path
            d="M176.162 50.3952C176.502 51.0421 176.761 51.728 176.934 52.4378C177.377 54.304 177.066 55.9976 176.048 56.7919C175.947 56.8744 175.837 56.9466 175.721 57.0072C174.448 57.6738 172.691 56.9703 171.284 55.3963C170.8 54.8489 170.385 54.2444 170.048 53.5964C169.711 52.9567 169.454 52.2777 169.283 51.5748C168.787 49.5137 169.209 47.6548 170.486 46.9863C172.177 46.1009 174.718 47.6293 176.162 50.3952Z"
            fill="white"
          />
          <path
            d="M175.622 53.5851C176.139 54.5698 176.29 55.7061 176.048 56.7919C175.947 56.8744 175.837 56.9465 175.721 57.0072C174.448 57.6737 172.691 56.9703 171.283 55.3963C170.8 54.8489 170.385 54.2444 170.048 53.5964C169.711 52.9567 169.454 52.2777 169.282 51.5748C170.39 51.0028 171.679 50.8932 172.867 51.27C174.055 51.6467 175.045 52.4791 175.622 53.5851Z"
            fill="#3F3D56"
          />
          <path
            d="M188.828 43.7638C190.215 46.4193 190.088 49.2522 188.581 50.2591C188.519 50.3018 188.453 50.3408 188.386 50.3759C186.699 51.2593 184.158 49.7309 182.714 46.9651C182.377 46.3266 182.12 45.6489 181.95 44.9473C181.452 42.8824 181.875 41.0235 183.152 40.355C184.842 39.4696 187.383 40.998 188.828 43.7638Z"
            fill="white"
          />
          <path
            d="M188.162 47.0194C188.684 48.0144 188.833 49.1637 188.581 50.2591C188.519 50.3018 188.453 50.3408 188.386 50.3759C186.699 51.2593 184.158 49.7309 182.714 46.9651C182.377 46.3266 182.12 45.6489 181.95 44.9473C183.052 44.4195 184.315 44.3406 185.474 44.7272C186.633 45.1137 187.596 45.9355 188.162 47.0194Z"
            fill="#3F3D56"
          />
          <path
            d="M174.562 69.1591C178.404 76.5132 185.856 80.2033 191.208 77.4013C196.559 74.5993 197.784 66.3662 193.942 59.0122C189.723 65.1719 183.332 68.6401 174.562 69.1591Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M221.691 42.5755C218.586 36.6323 213.73 31.7895 207.782 28.7045C201.833 25.6196 195.081 24.442 188.441 25.3315C196.281 26.3644 203.479 30.2126 208.698 36.1606C213.917 42.1085 216.8 49.7515 216.813 57.6684C216.825 65.5852 213.965 73.2371 208.765 79.2014C203.565 85.1656 196.379 89.0363 188.542 90.0937C194.463 90.8726 200.484 90.0088 205.949 87.5967C211.414 85.1846 216.112 81.3166 219.532 76.4146C222.952 71.5126 224.961 65.7646 225.342 59.7975C225.723 53.8304 224.46 47.8732 221.691 42.5755Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_6_872">
            <rect width="241" height="209" fill="white" transform="translate(7.5 25)" />
          </clipPath>
        </defs>
      </svg>
    </WGridSVGWrapper>
  );
};
