import { useTheme } from "@mui/material";
import React, { FC } from "react";
import {
  Heart,
  Share,
  Sliders,
  Frown,
  MoreHorizontal,
  ChevronUp,
  Trash2,
  FileText,
  Edit2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  MapPin,
  Calendar,
  Briefcase,
  Camera,
  Mail,
  Phone,
  Search,
  Plus,
  Headphones,
  Check,
  UserMinus,
  Send,
  X,
  UploadCloud,
  Settings,
  MessageSquare,
  Eye,
  Home,
  User,
  HelpCircle,
  AlertCircle,
  LogOut,
  UserPlus,
  FolderPlus,
  Download,
  Globe,
  LogIn,
  Circle,
  Info,
  Award,
  Edit,
  Star,
  Lock,
  Menu,
  Linkedin,
  Clipboard,
  BarChart2,
  ArrowUpCircle,
  ArrowDownCircle,
  ArrowUpRight,
  ArrowDownRight,
  ArrowRight,
  Square,
  CheckSquare,
  TrendingDown,
  TrendingUp,
  Trello,
  ExternalLink,
  MoreVertical,
  EyeOff,
  Users,
  Grid,
} from "react-feather";
import { colors } from "styles/colors";

export enum WIconTypes {
  "share" = "share",
  "heart" = "heart",
  "sliders" = "sliders",
  "frown" = "frown",
  "moreHorizontal" = "moreHorizontal",
  "moreVertical" = "moreVertical",
  "arrowUp" = "arrowUp",
  "arrowLeft" = "arrowLeft",
  "arrowRight" = "arrowRight",
  "trash" = "trash",
  "fileText" = "fileText",
  "edit" = "edit",
  "arrowDown" = "arrowDown",
  "mapPin" = "mapPin",
  "calendar" = "calendar",
  "work" = "work",
  "camera" = "camera",
  "mail" = "mail",
  "phone" = "phone",
  "search" = "search",
  "add" = "add",
  "headset" = "headset",
  "check" = "check",
  "removePerson" = "removePerson",
  "send" = "send",
  "clear" = "clear",
  "uploadCloud" = "uploadCloud",
  "settings" = "settings",
  "message" = "message",
  "visibility" = "visibility",
  "home" = "home",
  "person" = "person",
  "helpCircle" = "helpCircle",
  "alertCircle" = "alertCircle",
  "logout" = "logout",
  "addUser" = "addUser",
  "addToGroup" = "addToGroup",
  "download" = "download",
  "globe" = "globe",
  "login" = "login",
  "circle" = "circle",
  "info" = "info",
  "award" = "award",
  "write" = "write",
  "star" = "star",
  "lock" = "lock",
  "menu" = "menu",
  "linkedIn" = "linkedIn",
  "clipBoard" = "clipBoard",
  "barChart2" = "barChart2",
  "arrowUpCircle" = "arrowUpCircle",
  "arrowDownCircle" = "arrowDownCircle",
  "arrowUpRight" = "arrowUpRight",
  "arrowDownRight" = "arrowDownRight",
  "arrowLineRight" = "arrowLineRight",
  "square" = "square",
  "checkSquare" = "checkSquare",
  "kanban" = "kanban",
  "externalLink" = "externalLink",
  "hide" = "hide",
  "show" = "show",
  "trendingDown" = "trendingDown",
  "trendingUp" = "trendingUp",
  "agent" = "agent",
  "job" = "job",
  "application" = "application",
  "candidates" = "candidates",
  "groups" = "groups",
}

const WIconComponents = {
  [WIconTypes.heart]: Heart,
  [WIconTypes.share]: Share,
  [WIconTypes.sliders]: Sliders,
  [WIconTypes.frown]: Frown,
  [WIconTypes.moreHorizontal]: MoreHorizontal,
  [WIconTypes.moreVertical]: MoreVertical,
  [WIconTypes.arrowUp]: ChevronUp,
  [WIconTypes.arrowDown]: ChevronDown,
  [WIconTypes.arrowLeft]: ChevronLeft,
  [WIconTypes.arrowRight]: ChevronRight,
  [WIconTypes.arrowLineRight]: ArrowRight,
  [WIconTypes.trash]: Trash2,
  [WIconTypes.fileText]: FileText,
  [WIconTypes.edit]: Edit2,
  [WIconTypes.write]: Edit,
  [WIconTypes.mapPin]: MapPin,
  [WIconTypes.calendar]: Calendar,
  [WIconTypes.work]: Briefcase,
  [WIconTypes.camera]: Camera,
  [WIconTypes.mail]: Mail,
  [WIconTypes.phone]: Phone,
  [WIconTypes.search]: Search,
  [WIconTypes.add]: Plus,
  [WIconTypes.headset]: Headphones,
  [WIconTypes.check]: Check,
  [WIconTypes.removePerson]: UserMinus,
  [WIconTypes.send]: Send,
  [WIconTypes.clear]: X,
  [WIconTypes.uploadCloud]: UploadCloud,
  [WIconTypes.settings]: Settings,
  [WIconTypes.message]: MessageSquare,
  [WIconTypes.visibility]: Eye,
  [WIconTypes.home]: Home,
  [WIconTypes.person]: User,
  [WIconTypes.helpCircle]: HelpCircle,
  [WIconTypes.alertCircle]: AlertCircle,
  [WIconTypes.logout]: LogOut,
  [WIconTypes.addUser]: UserPlus,
  [WIconTypes.addToGroup]: FolderPlus,
  [WIconTypes.download]: Download,
  [WIconTypes.globe]: Globe,
  [WIconTypes.login]: LogIn,
  [WIconTypes.circle]: Circle,
  [WIconTypes.info]: Info,
  [WIconTypes.award]: Award,
  [WIconTypes.star]: Star,
  [WIconTypes.lock]: Lock,
  [WIconTypes.menu]: Menu,
  [WIconTypes.linkedIn]: Linkedin,
  [WIconTypes.clipBoard]: Clipboard,
  [WIconTypes.barChart2]: BarChart2,
  [WIconTypes.arrowUpCircle]: ArrowUpCircle,
  [WIconTypes.arrowDownCircle]: ArrowDownCircle,
  [WIconTypes.arrowUpRight]: ArrowUpRight,
  [WIconTypes.arrowDownRight]: ArrowDownRight,
  [WIconTypes.square]: Square,
  [WIconTypes.checkSquare]: CheckSquare,
  [WIconTypes.kanban]: Trello,
  [WIconTypes.externalLink]: ExternalLink,
  [WIconTypes.hide]: EyeOff,
  [WIconTypes.show]: Eye,
  [WIconTypes.trendingDown]: TrendingDown,
  [WIconTypes.trendingUp]: TrendingUp,
  [WIconTypes.job]: Briefcase,
  [WIconTypes.application]: Send,
  [WIconTypes.candidates]: Users,
  [WIconTypes.groups]: Grid,
};

interface WIconProps {
  icon: WIconTypes;
  customColor?: string;
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "success"
    | "secondaryDark"
    | "disabled"
    | "draft"
    | "mainDarkRed"
    | "star";
  size?: "tiny" | "small" | "medium" | "large" | "extraLarge";
  fill?: boolean;
  rotationDegrees?: string;
}
export const WIcon: FC<WIconProps> = ({
  icon,
  customColor,
  color = "primary",
  size = "medium",
  fill = false,
  rotationDegrees = 0,
}) => {
  const iconSizes = {
    tiny: "13px",
    small: "18px",
    medium: "24px",
    large: "36px",
    extraLarge: "50px",
  };
  const theme = useTheme();

  const IconColors = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    error: theme.palette.error.main,
    success: theme.palette.success.main,
    secondaryDark: theme.palette.secondary.dark,
    disabled: colors.grey,
    draft: colors.draftColor,
    mainDarkRed: colors.mainDarkRed,
    star: colors.star,
  };

  const WIconComponent = WIconComponents[icon];
  return (
    <WIconComponent
      color={customColor ?? IconColors[color]}
      size={iconSizes[size]}
      transform={`rotate(${rotationDegrees})`}
      {...(fill ? { fill: customColor ?? IconColors[color] } : {})}
    />
  );
};
