import { Grid } from "@mui/material";
import { Referral, updateReferral } from "adapters";
import { WButton, WIcon, WIconTypes, WRedButton, WTextField } from "components";
import { LoaderContext } from "context/LoaderContext";
import { LocaleContext } from "context/LocaleContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, useContext } from "react";
import { useForm } from "react-hook-form";

interface ReferralEditFormProps {
  handleClose: () => void;
  handleSuccessUpdate: () => void;
  referral: Referral;
  handleDelete?: () => void;
  opts?: {
    stickyButtons: boolean;
  };
}

export const ReferralEditForm: FC<ReferralEditFormProps> = ({
  referral,
  handleClose,
  handleSuccessUpdate,
  handleDelete,
  opts = { stickyButtons: false },
}) => {
  const { dispatchLoading } = useContext(LoaderContext);
  const { localize } = useContext(LocaleContext);
  const { addMessage } = useContext(NotificationContext);
  const { user, dispatch } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<Referral>({
    defaultValues: {
      id: referral.id || "",
      first_name: referral.first_name || "",
      last_name: referral.last_name || "",
      phone: referral.phone || "",
      email: referral.email || "",
      comment: referral.comment || "",
    },
  });

  const dirtyValues = (
    dirtyFields: Record<string, unknown> | boolean,
    allValues: Referral
  ): Partial<Referral> => {
    if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
    );
  };

  const submitAndClose = (data: Referral) => {
    const changedFields = dirtyValues(dirtyFields, data);
    if (Object.keys(changedFields).length) {
      handleReferralSave(changedFields, data.id);
    } else {
      //  no fields changed, just close the dialog.
      handleClose();
    }
  };

  const handleReferralSave = (changedData: Partial<Referral>, referralId: string): void => {
    dispatchLoading({ type: "SET_LOADING", payload: "UPDATE_REFERRAL" });
    updateReferral(changedData, referralId)
      .then((res) => {
        addMessage({ content: "success.updateSuccessful", type: "success", duration: 1500 });

        // Update user current referrals
        const updatedReferralList = user.referrals.map((userRef) =>
          userRef.id === res.data.id ? res.data : userRef
        );
        dispatch({
          type: "UPDATE_USER",
          user: { referrals: updatedReferralList },
        });

        handleClose();
        handleSuccessUpdate();
      })
      .catch(() => {
        addMessage({ type: "error" });
      })
      .finally(() => {
        dispatchLoading({ type: "STOP_LOADING", payload: "UPDATE_REFERRAL" });
      });
  };

  return (
    <Grid container direction="column" gap="1rem" padding="2rem 1rem">
      <WTextField
        fullWidth
        label={localize("form.firstnameField")}
        error={!!errors.first_name}
        helperText={errors.first_name?.message}
        {...register("first_name", {
          required: localize("form.requiredFieldError"),
        })}
      />
      <WTextField fullWidth label={localize("form.lastnameField")} {...register("last_name")} />
      <WTextField
        fullWidth
        type="tel"
        label={localize("form.phoneNumberField")}
        {...register("phone")}
      />
      <WTextField
        fullWidth
        type="email"
        error={!!errors.email}
        helperText={errors.email?.message}
        label={localize("form.emailField")}
        {...register("email", {
          required: localize("form.requiredFieldError"),
        })}
      />
      <WTextField
        multiline
        minRows={4}
        fullWidth
        label={localize("form.commentField")}
        {...register("comment")}
      />
      <Grid
        container
        gap="1rem"
        sx={
          opts.stickyButtons ? { position: "sticky", bottom: 0, zIndex: 2, marginTop: "auto" } : {}
        }
      >
        {handleDelete && (
          <WRedButton
            fullWidth
            color="error"
            type="button"
            startIcon={<WIcon icon={WIconTypes.removePerson} />}
            onClick={() => handleDelete()}
            variant="outlined"
          >
            {localize("common.delete")} {localize("common.referral")}
          </WRedButton>
        )}
        <Grid item xs={12} md={4}>
          <WButton
            fullWidth
            type="submit"
            startIcon={<WIcon icon={WIconTypes.check} />}
            onClick={handleSubmit(submitAndClose)}
            variant="outlined"
            sx={{ minWidth: "max-content" }}
          >
            {localize("form.save")}
          </WButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
