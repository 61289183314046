import React, { FC, useContext } from "react";
import { Grid } from "@mui/material";
import {
  CreateProfileSVG,
  ManageProcessSVG,
  QuickReplySVG,
  SearchManyAssignmentsSVG,
} from "components/assets";
import { LocaleContext } from "context/LocaleContext";
import { useDynamicPageContent } from "hooks/useDynamicPageContent";
import { DynamicViewContent, WButton } from "components";
import { UserContext } from "context/UserContext";
import { useHistory } from "react-router-dom";
import { ExplanationCard } from "components/ExplanationSVGCard";

export const ItConsultantView: FC = () => {
  const { localize } = useContext(LocaleContext);
  const { isAuthenticated } = useContext(UserContext);

  const history = useHistory();

  const explanationCards: ExplanationCard[] = [
    {
      svg: <CreateProfileSVG />,
      title: localize("itConsulting.createProfileTitle"),
      subTitle: localize("itConsulting.createProfileSubTitle"),
    },
    {
      svg: <SearchManyAssignmentsSVG />,
      title: localize("itConsulting.searchAssignmentsTitle"),
      subTitle: localize("itConsulting.searchAssignmentsSubTitle"),
    },
    {
      svg: <QuickReplySVG />,
      title: localize("itConsulting.quickReplyTitle"),
      subTitle: localize("itConsulting.quickReplySubTitle"),
    },
    {
      svg: <ManageProcessSVG />,
      title: localize("itConsulting.processTitle"),
      subTitle: localize("itConsulting.processSubTitle"),
    },
  ];

  const { pageContent, pageHTMLContent } = useDynamicPageContent({ pageType: "IT_consultant" });

  return (
    <Grid container position="relative">
      <DynamicViewContent
        pageContent={pageContent}
        forceSuperHeader
        explanationCards={explanationCards}
        pageHTMLContent={pageHTMLContent}
      />
      {!isAuthenticated && (
        <Grid container justifyContent="center">
          <WButton variant="contained" onClick={() => history.push("/login")}>
            {localize("dynamicViewsCTA.createProfile")}
          </WButton>
        </Grid>
      )}
    </Grid>
  );
};
