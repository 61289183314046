import React, { createContext, useState } from "react";

export type FilterPeriod = "week" | "month";

export const StatisticsFilterContext = createContext<{
  period: FilterPeriod;
  setPeriod: (newPeriod: FilterPeriod) => void;
}>({
  period: "week",
  setPeriod: () => false,
});

const StatisticsFilterProvider: React.FC = ({ children }) => {
  const [period, setPeriod] = useState<FilterPeriod>("week");

  return (
    <StatisticsFilterContext.Provider value={{ period, setPeriod }}>
      {children}
    </StatisticsFilterContext.Provider>
  );
};

export default StatisticsFilterProvider;
