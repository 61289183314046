import { Box, Button, Divider, Grid } from "@mui/material";
import { Job, removeSavedJob, saveJob } from "adapters";
import { WIcon, WIconButton, WIconTypes } from "components";
import { ShareJobButton } from "components/ShareJobButton";
import { LocaleContext } from "context/LocaleContext";
import { ModalContext } from "context/ModalContext";
import { NotificationContext } from "context/NotificationContext";
import { UserContext } from "context/UserContext";
import React, { FC, SyntheticEvent, useContext } from "react";
import { useHistory } from "react-router-dom";

type ApplicationOpts = {
  handleOpenApplicationForm: () => void;
  onRemoveApplication: (id: string) => void;
  applicationID: string;
};

interface JobCardTopButtonsProps {
  job: Job;
  iconVariant?: "options" | "expanded" | "none";
  handleButtonPress?: JSX.Element;
  setSaveJob: (state: boolean) => void;
  applicationOpts?: ApplicationOpts;
  isSaved: boolean;
}

export const JobCardTopButtons: FC<JobCardTopButtonsProps> = ({
  job,
  iconVariant = "options",
  handleButtonPress,
  setSaveJob,
  isSaved,
  applicationOpts,
}) => {
  const { localize } = useContext(LocaleContext);
  const { hideModal, showDialog, hideDialog } = useContext(ModalContext);
  const { isAuthenticated } = useContext(UserContext);
  const { addMessage } = useContext(NotificationContext);

  const { id = "", title = "" } = job;
  const history = useHistory();

  const generateMailURL = (id: string): string => {
    const queryString = window.location.origin;

    return encodeURIComponent(`${queryString}/jobs/${id}`);
  };

  const handleShareJobClick = () => {
    if (isAuthenticated) {
      showDialog("SHARE_JOB", { jobId: id, title: title });
    } else {
      // Send email popup if user is not logged in
      window.location.href = `mailto:${""}?subject=${localize(
        "shareJobDialog.mailSubject"
      )}: ${title}&body=${encodeURIComponent(
        localize("shareJobDialog.mailBody")
      )} ${generateMailURL(id)}`;
    }
  };

  const handleSaveJob = () => {
    saveJob(id)
      .then(() => {
        setSaveJob(true);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.saveJob" });
      });
  };

  const handleRemoveJob = () => {
    removeSavedJob(id)
      .then(() => {
        setSaveJob(false);
      })
      .catch(() => {
        addMessage({ type: "error", content: "error.removeSavedJob" });
      });
  };

  const handleButtonClick = (event: SyntheticEvent) => {
    // Handle so that the parent click event wont trigger
    event.stopPropagation();
    showDialog("OPTIONS_DIALOG", {
      content: (
        <Box>
          {isAuthenticated && handleButtonPress}
          <Divider sx={{ width: "100%" }} />
          <Button
            fullWidth
            size="large"
            variant="text"
            sx={{ color: "text.primary" }}
            onClick={() => {
              handleShareJobClick();
              hideModal();
            }}
          >
            {localize("dialog.recommendJob")}
          </Button>
          <Divider sx={{ width: "100%" }} />
        </Box>
      ),
      handleClose: () => hideModal(),
    });
  };

  const handleRemoveApplication = () => {
    if (applicationOpts) {
      applicationOpts.onRemoveApplication(applicationOpts.applicationID);
      hideModal();
    }
    hideDialog();
  };

  const handleRemoveConfirmation = () => {
    showDialog("CONFIRM_DIALOG", {
      subtitle: localize("dialog.confirmDeleteApplication"),
      cancelAction: hideDialog,
      confirmAction: handleRemoveApplication,
    });
  };

  if (iconVariant === "options") {
    return (
      <WIconButton
        onClick={(event) => {
          handleButtonClick(event);
        }}
        sx={{ marginLeft: "auto", marginBottom: "auto" }}
      >
        <WIcon icon={WIconTypes.moreHorizontal} />
      </WIconButton>
    );
  } else if (iconVariant === "expanded") {
    return (
      <Grid item xs="auto">
        {applicationOpts ? (
          <>
            <WIconButton
              disabled={!isAuthenticated}
              onClick={applicationOpts.handleOpenApplicationForm}
            >
              <WIcon icon={WIconTypes.edit} color={!isAuthenticated ? "disabled" : "primary"} />
            </WIconButton>
            <WIconButton onClick={handleRemoveConfirmation}>
              <WIcon icon={WIconTypes.trash} color={!isAuthenticated ? "disabled" : "primary"} />
            </WIconButton>
          </>
        ) : (
          <>
            <ShareJobButton job={job} isIconButton={true} />
            <WIconButton
              onClick={(event) => {
                event.stopPropagation();
                if (isAuthenticated) {
                  isSaved ? handleRemoveJob() : handleSaveJob();
                  return;
                }
                hideModal();
                history.push("/login");
              }}
            >
              <WIcon icon={WIconTypes.heart} fill={isSaved} color="primary" />
            </WIconButton>
          </>
        )}
      </Grid>
    );
  }

  return null;
};
